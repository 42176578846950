import { makeStyles, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CustomContainerDevices from "../../../../Components/CustomContainerDevices";
import { Divider } from "@material-ui/core";
import CustomActionBar from "../../../../Components/CustomActionBar";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import { useContext } from "react";
import Environments from "../../../Environments";
import CustomTab, { CustomTabPanel } from "../../../../Components/CustomTab";
import DevicesFavorited from "./DevicesFavorited";
import HomePageContext from "../../Context/HomePageContext";
import AutomationsFavorited from "./AutomationsFavorited";

const useStyles = makeStyles(() =>
  createStyles({
    paddingGrid: {
      marginTop: "10px",
      display: "flex",
      justifyContent: "center",
      overflow: "auto",
      height: "calc(100vh - 210px)",
    },
    notHome: {
      display: "flex",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
      justifyItems: "center",
      flexDirection: "column",
    },
    button: {
      textTransform: "none",
    },
  })
);

const Home: React.FunctionComponent = () => {
  const classes = useStyles();

  const { homeOperator, devices } = useContext(GlobalContex);
  const {
    loadingDashboardConfiguration,
    statusAutomationsShortcut,
    statusDeviceInPlaceShortcut,
    statusDevicesShortcut,
    dashboardConfigurationDevices,
    dashboardTabValue,
    setDashboardTabValue,
  } = useContext(HomePageContext);

  const handleChange = (newValue: number) => {
    setDashboardTabValue(newValue);
  };

  return (
    <div>
      {homeOperator && (
        <>
          <CustomTab
            labels={["Dashboard", "Dispositivos"]}
            ariaLabel={"menu em tabs para a página de espaço selecionado"}
            id="tab-selected-location"
            value={dashboardTabValue}
            key={"tab-selected-location"}
            changeTab={(value: number) => handleChange(value)}
          />
          <Divider />
          <CustomTabPanel
            value={dashboardTabValue}
            index={0}
            id={"painel-all-devices-in-environment"}
          >
            {!loadingDashboardConfiguration && (
              <>
                <CustomActionBar />
                <Divider />
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justifyContent="center"
                  className={classes.paddingGrid}
                >
                  <Grid item xs={statusAutomationsShortcut ? 8 : 12}>
                    {statusDevicesShortcut && (
                      <DevicesFavorited
                        devicesFavorited={dashboardConfigurationDevices}
                        devices={devices}
                      />
                    )}
                    {statusDeviceInPlaceShortcut && <CustomContainerDevices />}
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{
                      display: statusAutomationsShortcut ? "block" : "none",
                    }}
                  >
                    <AutomationsFavorited />
                  </Grid>
                </Grid>
              </>
            )}
          </CustomTabPanel>
          <CustomTabPanel
            value={dashboardTabValue}
            index={1}
            id={"painel-device-in-environment"}
          >
            <Environments />
          </CustomTabPanel>
        </>
      )}
      {!homeOperator && (
        <div className={classes.notHome}>
          <img src="assets/imgs/notdevices.png" alt="" />
          <h1>Não há locais disponíveis para o seu usuário</h1>
        </div>
      )}
    </div>
  );
};

export default Home;
