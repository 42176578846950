import { AxiosResponse } from "axios";
import { NotificationsPaginatedModel } from "../Models/NotificationModel";
import api from "./Api";
import { getCurrentUser } from "./AuthService";
import authHeader, { getHomeIDSelect } from "./UserService";

export const getUserNotifications = (): Promise<
  AxiosResponse<NotificationsPaginatedModel>
> => {
  return api.get(
    `/homes/${getHomeIDSelect()}/users/${
      getCurrentUser().userInfo.id
    }/notification`,
    {
      headers: authHeader(),
    }
  );
};

export const getUserNotificationsPaginated = (
  next_row_key: string
): Promise<AxiosResponse<NotificationsPaginatedModel>> => {
  const params = {
    headers: authHeader(),
    start_row_key: next_row_key,
  };
  return api.get(
    `/homes/${getHomeIDSelect()}/users/${
      getCurrentUser().userInfo.id
    }/notification`,
    { params }
  );
};
