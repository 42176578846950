import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    btnRemove: {
      width: "80px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      border: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
      "&:active": {
        "& #custom-reactivate-icon-container #custom-reactivate-icon": {
          background: "url('assets/icons/reactivate_icon_active.svg')",
          width: "36px",
          height: "36px",
        },
        "& #custom-reactivate-label": {
          color: "#3E5055",
        },
      },
      "&:hover": {
        "& #custom-reactivate-icon-container #custom-reactivate-icon": {
          background: "url('assets/icons/reactivate_icon_hover.svg')",
          width: "36px",
          height: "36px",
        },
        "& #custom-reactivate-label": {
          color: "#677884",
        },
      },
    },
    iconBtnContainer: {
      width: "35px",
      height: "35px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      marginBottom: "4px",
    },
    iconBtn: {
      width: "28px",
      height: "33px",
      background: "url('assets/icons/reactivate_icon.svg')",
    },
    iconLabel: {
      marginBottom: "auto",
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "20px",
      color: "#8B979F",
    },
  })
);

interface Props {
  id: string;
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const CustomReactivateIcon: React.FunctionComponent<Props> = ({
  id,
  onClick,
  disabled = false,
}: Props) => {
  const classes = useStyles();
  return (
    <button
      id={id}
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={classes.btnRemove}
    >
      <div
        className={classes.iconBtnContainer}
        id="custom-reactivate-icon-container"
      >
        <div className={classes.iconBtn} id="custom-reactivate-icon"></div>
      </div>
      <div id={"custom-reactivate-label"} className={classes.iconLabel}>
        Reativar
      </div>
    </button>
  );
};

export default CustomReactivateIcon;
