const defaultOptions = {
  fromCents: false,
  precision: 2,
};
export function toREAL(
  value: number | string | undefined,
  options?: { fromCents?: boolean; precision?: number }
): string {
  if (!value) value = 0;
  if (typeof value == "string") {
    try {
      value = parseFloat(value);
    } catch (e) {
      value = 0;
    }
  }
  const opt = { ...defaultOptions, ...options };
  if (opt.fromCents && opt.precision > 0) {
    value = value / 10 ** opt.precision;
  }
  return value.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: opt.precision,
  });
}
