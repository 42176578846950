import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => {
  return {
    ItemIconImage: {
      backgroundColor: "#009B8B",
      color: "#FFF",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 10,
      marginRight: "10px",
    },
  };
});

interface Props {
  color: string;
  className: string;
  width: string;
  height: string;
}

const ItemIcon: React.FunctionComponent<Props> = ({
  color,
  className,
  width,
  height,
}: Props) => {
  const classes = useStyles();
  return (
    <div className={"ItemIcon--root"}>
      <div
        className={classes.ItemIconImage}
        style={{ backgroundColor: color, width: width, height: height }}
      >
        <img src="assets/icons/automacoes.svg" className={className} />
      </div>
    </div>
  );
};
export default ItemIcon;
