import styled, { css } from "styled-components";

type ColorProps = {
  color: string;
};

export const TablePlans = styled.table`
  font-family: "Open Sans";
  font-style: normal;
  border-collapse: collapse;

  thead {
  }

  tbody {
    background: #ffffff;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
    border-radius: 40px 0px 0px 40px;

    tr {
      &:nth-child(even) {
        background-color: #ebeeee;
      }
    }

    .boderLeftTop {
      border-radius: 40px 0px 0px 0px;
    }

    .boderLeftBottom {
      border-radius: 0px 0px 0px 40px;
    }
  }

  tr {
    border-collapse: collapse;
    .bigTd {
      font-weight: 700;
      font-size: 21px;
      line-height: 29px;
      text-align: center;
      color: #3e5055;
    }

    td {
      border-collapse: collapse;
      padding: 25px 15px;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: #3e5055;
      border-right: 1px solid #d1dae0;

      div {
        span {
          color: #8b979f;
        }
      }

      .smallDescription {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
      }
    }
  }
`;

export const Button = styled.button<ColorProps>`
  border: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  border-radius: 3px;
  color: #ffffff;
  padding: 14px 0px;
  text-align: center;
  background-color: ${(props) => props.color};
  width: 100%;
  margin: 40px 0px;

  &:hover {
    cursor: pointer;
  }
`;

export const ValueText = styled.span<ColorProps>`
  color: ${(props) => props.color} !important;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 61px;
  margin-left: 3px;
`;

type RecomendedProps = {
  color: string;
  recomended?: boolean;
};

export const ThCustom = styled.th<RecomendedProps>`
  background-color: ${(props) => props.color} !important;
  color: white;
  padding: 20px 0px;
  text-align: center;
  align-items: center;
  min-width: 165px;
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;

  &:nth-child(1) {
    min-width: 263px;
  }

  &:nth-child(2) {
    border-radius: 20px 0px 0px 0px;
  }

  ${({ recomended, color }) =>
    recomended
      ? css`
          transform: translateY(-10px);
          min-width: 200px;
          display: flex;
          flex-direction: column;
          border: none;
          font-weight: 800;
          span {
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            color: #fff;
          }
          &::before {
            content: "";
            position: absolute;
            height: 115px;
            width: 100%;
            background-color: black;
            transform: translateY(-40px);
            background-color: ${color} !important;
            z-index: -1;
            border-radius: 20px 20px 0px 0px;
            left: 0;
          }
        `
      : ""}

  &:last-child {
    border-radius: 0px 20px 0px 0px;
  }
`;
