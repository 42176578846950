import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    width: "99%",
    marginBottom: "1em",
    "& td": {
      border: "1px solid",
    },
  },
});

const StripeTermsText: React.FunctionComponent = () => {
  const classes = useStyles();

  const tablePlansValue = [
    { name: "Smart", interval: "Mensal", value: "R$150,00" },
    { name: "Advance", interval: "Mensal", value: "R$260,00" },
    { name: "Premium", interval: "Mensal ", value: "R$315,00" },
    { name: "Infinity", interval: "Mensal ", value: "R$405,00" },
    { name: "Smart", interval: "Mensal", value: "R$1500,00" },
    { name: "Advance", interval: "Anual ", value: "R$2600,00" },
    { name: "Premium", interval: "Anual ", value: "R$3150,00" },
    { name: "Infinity", interval: "Anual ", value: "R$4050,00" },
  ];

  return (
    <>
      <p>
        <strong>Termos de Serviços do Izy Manager</strong>
      </p>

      <p>
        Estes Termos de Serviço da plataforma Izy Manager (“Termos”) são um
        complemento ao Contrato de Registro do Izy Manager.
      </p>

      <p>
        Os Termos são um contrato válido firmado entre a Intelbras S.A.
        (“Intelbras”) e todos os indivíduos (incluindo, sem limitação, pessoas e
        equipes) (“Usuários”, “você”, “seu”) que usam o serviço da plataforma
        Izy Manager e serviços relevantes. Ao firmar, clicar em uma página da
        web para confirmar ou optar por aceitar esses termos de serviço, você e
        a Intelbras celebraram os Termos e concordam em aceitar todo o conteúdo
        acordado destes termos de serviço. Os Termos são aplicáveis a qualquer
        Serviço do Izy Manager, incluindo atualizações e melhorias de vários
        serviços do software usados pelos Usuários durante a vigência dos
        Termos.
      </p>

      <p>
        <strong>1. Conteúdo do serviço e instruções de uso</strong>
      </p>

      <p>
        1.1. O Izy Manager é uma plataforma de serviço web que fornece aos
        usuários espaço para gerenciamento de dispositivos da linha Intelbras
        Izy. O Serviço não carrega ou fornece conteúdo diretamente por si só e
        não faz nenhuma modificação ou edição em qualquer conteúdo. Para mais
        informações por favor consulte o Manual do Produto.
      </p>

      <p>
        1.2. Os Usuários entendem que o Serviço da plataforma Izy Manager inclui
        apenas serviços online relevantes, como gerenciamento de dispositivos e,
        com exceção disso, relacionados a serviços de rede relevantes (como
        computadores pessoais, telefones celulares e outros dispositivos
        relacionados ao acesso à Internet ou à rede móvel) e as despesas
        necessárias (como a taxa telefônica e a taxa de acesso à Internet paga
        pelo acesso à Internet e a taxa de dados paga pelo uso da rede móvel)
        serão custeadas pelos próprios Usuários. A Intelbras se reserva o
        direito de limitar o número de contas que podem ser criadas a partir de
        um dispositivo e o número de dispositivos associados a uma conta. A
        versão mais recente do software necessário pode ser necessária para
        certas funções ou recursos. Você concorda que o cumprimento desses
        requisitos é de sua responsabilidade.
      </p>

      <p>
        1.4. Caso o uso do Serviço do Izy Manager pelos Usuários não esteja em
        conformidade com os Termos, a Intelbras tem o direito de supervisionar o
        uso do Serviço pelos Usuários e se, por verificação, notificação,
        relatório ou outras formas, a Intelbras descobrir que Usuários violam
        qualquer disposição dos Termos ao usar o Serviço do Izy Manager, a
        Intelbras tem o direito de exigir que os Usuários façam correções ou
        tomem diretamente todas as medidas que a Intelbras julgar necessárias
        (incluindo, sem limitação, a exclusão do Conteúdo Fornecido pelos
        Usuários carregado pelo Usuários, alterando a autoridade dos Usuários ou
        suspendendo ou encerrando o direito dos Usuários de usar o Serviço da
        plataforma Izy Manager) para reduzir o impacto causado pela má conduta
        dos Usuários.
      </p>

      <p>
        1.5. Se o uso do Serviço da plataforma Izy Manager pelos Usuários não
        estiver em conformidade com as leis e regulamentos aplicáveis, ao
        constatar tal não conformidade por meios como notificação ou relatório,
        a Intelbras tem o direito de fazer um julgamento independente e parar
        imediatamente de fornecer os Usuários com parte ou todo o Serviço sem
        notificar os Usuários com antecedência. Caso o uso do Serviço viole
        quaisquer leis e regulamentos aplicáveis, quando as autoridades
        relevantes consultarem ou recuperarem os dados comerciais dos Usuários,
        a Intelbras é obrigada a fornecer cooperação de acordo com os requisitos
        das leis e regulamentos ou documentos de políticas relevantes e fazer
        divulgações a terceiros ou autoridades, como autoridades administrativas
        ou judiciais; se os atos dos usuários e o uso do Serviço da plataforma
        Izy Manager resultarem em qualquer reivindicação de terceiros ou
        qualquer dano ou perda for assim causado, os Usuários deverão assumir
        todas as responsabilidades e indenizar e isentar a Intelbras contra
        qualquer dano ou perda.
      </p>

      <p>
        <strong>2. Propriedade</strong>
      </p>

      <p>
        A Intelbras e seus fornecedores parceiros reservam a propriedade
        intelectual total e indivisível dos seguintes conteúdos e informações:
      </p>

      <p>
        2.1. Pelo Serviço da plataforma Izy Manager e por todos os seus
        elementos, incluindo, sem limitação, conteúdos, dados, tecnologias,
        software, códigos, interfaces de usuário e quaisquer trabalhos derivados
        relacionados a ele, exceto pelo Conteúdo Fornecido pelo Usuário.
      </p>

      <p>
        2.2. Sem o consentimento da Intelbras, quaisquer informações e feedbacks
        fornecidos pelos Usuários ao Serviço Izy Manager e relacionados aos
        serviços desta plataforma não podem ser direta ou indiretamente
        divulgados ou transmitidos em qualquer mídia, nem reescritos ou
        reeditados com a finalidade de transmitir ou liberar ou ser usado para
        qualquer outra finalidade comercial. Os dados acima ou qualquer parte
        deles só podem ser armazenados nos computadores dos Usuários para fins
        particulares. O Serviço Izy Manager não arcará com nenhuma
        responsabilidade legal ou econômica com os Usuários ou terceiros de
        qualquer forma por qualquer atraso, imprecisão, erro ou omissão
        decorrente dos dados acima ou decorrente durante a transmissão ou envio
        de todos ou parte dos dados acima ou quaisquer danos resultantes ou
        decorrentes dos mesmos.
      </p>

      <p>
        2.3. Todos os direitos de qualquer software (incluindo, sem limitação,
        imagens, fotos, animações, gravações de vídeo ou áudio, música, textos e
        programas adicionais ou materiais de ajuda anexados contidos nesse
        software) usados pelo Serviço da plataforma Izy Manager para fornecer
        serviços de rede devem pertencer ao proprietário dos direitos autorais
        de tal software. Os Usuários não cometerão nenhum ato que supostamente
        viole os direitos autorais. Sem a permissão do proprietário dos direitos
        autorais de tal software, os Usuários não podem fazer engenharia
        reversa, descompilar ou desmontar esse software ou descobrir seus
        códigos originais.
      </p>

      <p>
        <strong>3. Declarações e Garantias</strong>
      </p>

      <p>
        3.1. Os Usuários garantem que, ao usar o Serviço Izy Manager, eles não
        podem, direta ou indiretamente:
      </p>

      <p>
        1) excluir, ocultar ou alterar qualquer patente, direito autoral, marca
        comercial ou outra declaração de propriedade mostrada ou contida no
        Serviço Izy Manager;
      </p>

      <p>
        2) interromper ou tentar interromper, de qualquer forma, o funcionamento
        normal de qualquer parte ou função do Serviço Izy Manager ou do site da
        Intelbras;
      </p>

      <p>
        3) burlar ou tentar burlar ou alegar que eles podem burlar qualquer
        mecanismo de proteção de conteúdo ou qualquer ferramenta do Serviço Izy
        Manager;
      </p>

      <p>
        4) sem o consentimento prévio por escrito da Intelbras, usar quaisquer
        obras, marcas de serviço, logotipos, URLs ou outras marcas registradas
        ou não registradas originárias da Intelbras em formas escritas ou
        gráficas;
      </p>

      <p>
        5) usar qualquer marca, incluindo, sem limitação, o uso de qualquer
        marca da Intelbras de maneira que prejudique ou danifique os direitos do
        proprietário dessa marca, ou imponha ou pretenda impor qualquer
        obrigação ou conceder qualquer direito ou autoridade para si ou a
        qualquer outra pessoa de maneira que viole os Termos; exceto se de outra
        forma designado pela Intelbras por escrito, os Usuários não poderão
        exportar nenhuma informação do usuário e deverão parar de usar e excluir
        qualquer informação do usuário ou outro conteúdo do Serviço Izy Manager
        dentro de 24 horas após a obtenção.
      </p>

      <p>
        6) mostrar ou fornecer qualquer informação do usuário a usuários não
        originais sem o consentimento prévio dos usuários originais;
      </p>

      <p>
        7) solicitar, coletar ou obter de qualquer usuário qualquer direito de
        acesso aos números de conta, senhas ou outras credenciais de
        autenticação da Intelbras;
      </p>

      <p>
        8) representar credenciais de autenticação para qualquer usuário fazer
        login automaticamente em qualquer conta da Intelbras;
      </p>

      <p>
        9) fornecer funções de rastreamento, incluindo, entre outras, a
        identificação de visualizações ou operações de outros usuários em
        páginas pessoais;
      </p>

      <p>
        10) sem o consentimento expresso de um Usuário, permitir que qualquer
        outra pessoa identifique esse Usuário pessoalmente.
      </p>

      <p>
        Caso algum usuário viole qualquer uma das garantias acima, a Intelbras
        tem o direito de dar um aviso a esse Usuário, bloquear esse Usuário, até
        cancelar a qualificação desse Usuário, dependendo das circunstâncias.
      </p>

      <p>
        Caso algum Usuário viole qualquer uma das garantias acima e, assim,
        cause qualquer perda ao Serviço da plataforma web Izy Manager qualquer
        outro Usuário do Serviço de Nuvem da MiboCam ou qualquer parceiro da
        Intelbras e suas afiliadas, esse Usuário assumirá todas as
        responsabilidades legais e compensará essa perda por ele mesmo.
      </p>

      <p>3.2. Compromissos dos Usuários:</p>

      <p>
        1) Eles obtiveram autorização válida dos detentores dos direitos (se
        houver) do Conteúdo Fornecido pelos Usuários carregado, postado,
        transmitido ou disseminado de outra forma usando os serviços de rede
        fornecidos pelo Serviço Izy Manager, e chegaram a acordos com os
        detentores dos direitos supracitados sobre distribuição de direitos e
        interesses e garante que eles possuem propriedade ou propriedade
        intelectual plena, completa e sem falhas antes de enviar ou fazer upload
        do Conteúdo Fornecido pelos Usuários relevante para o Serviço Izy
        Manager;
      </p>

      <p>
        2) O Conteúdo Fornecido pelos Usuários carregado, postado, transmitido
        ou disseminado pelos Usuários usando os serviços de rede fornecidos pelo
        Serviço Izy Manager não deve conter nenhuma informação que viole as
        leis, regulamentos ou políticas do Estado.
      </p>

      <p>
        3) Os Usuários não podem usar este serviço para qualquer finalidade
        ilegal; não podem usar os serviços de rede da Intelbras de forma alguma
        para infringir os interesses comerciais da Intelbras, incluindo, sem
        limitação, a publicação de anúncios comerciais que não foram permitidos
        pela Intelbras; e não podem usar o sistema de serviço de rede do Serviço
        Izy Manager para cometer qualquer ato que possa afetar adversamente a
        operação normal da Internet ou da rede móvel.
      </p>

      <p>
        4) Os Usuários não podem se envolver nas seguintes atividades usando o
        Serviço Izy Manager:
      </p>

      <p>
        i. sem permissão, entrar em qualquer rede de informações do computador
        ou usar quaisquer recursos da rede de informações do computador;
      </p>

      <p>
        ii. sem permissão, excluir, modificar ou adicionar qualquer função da
        rede de informações do computador;
      </p>

      <p>
        iii. sem permissão, excluir, modificar ou adicionar quaisquer dados ou
        aplicativos armazenados, processados ou transmitidos em qualquer rede de
        informações de computadores;
      </p>

      <p>
        iv. cometer qualquer ato que comprometa ou tente prejudicar a segurança
        da rede (incluindo, sem limitação, <em>phishing</em>, hackers, fraudes
        na Internet, contendo ou supostamente se espalhando em sites ou espaço:
        vírus, cavalos de Troia e códigos maliciosos, e supostamente atacando
        outros sites ou servidores por servidores virtuai , como{" "}
        <em>scanning</em>, <em>sniffing</em>, <em>ARP deception</em> e DOS); e
      </p>

      <p>
        v. Outros atos que comprometam a segurança da rede de informações do
        computador.
      </p>

      <p>
        5) Caso algum Conteúdo Fornecido pelo Usuário carregado, postado,
        transmitido ou disseminado pelos Usuários usando os serviços de rede
        fornecidos pelo Serviço da plataforma web Izy Manager tenha qualquer
        falha ou infrinja os direitos e interesses legítimos de terceiros
        (incluindo, sem limitação, patentes, marcas registradas, direitos
        autorais e direitos autorais relacionados, direitos de retratos,
        direitos de privacidade e direitos de reputação) e, portanto, a
        Intelbras ou qualquer outra entidade que coopera com a Intelbras
        enfrentar qualquer reivindicação, denúncia, investigação, reclamação ou
        litígio; ou a Intelbras ou qualquer outra entidade que coopera com a
        Intelbras, portanto, sofrer qualquer perda de honra, reputação ou
        propriedade, os Usuários tomarão ativamente todas as medidas possíveis
        para garantir que a Intelbras e a outra entidade que coopera com a
        Intelbras não sejam afetadas por tal reivindicação ou litígio. Enquanto
        isso, os Usuários assumem todas as responsabilidades pela compensação
        por perdas econômicas diretas e indiretas, assim sofridas pela Intelbras
        e por outras entidades que cooperam com a Intelbras.
      </p>

      <p>
        <strong>4. Proteção de Propriedade Intelectual</strong>
      </p>

      <p>
        Se outros usuários puderem baixar, visualizar, ouvir ou acessar ou
        distribuir o Conteúdo Fornecido pelos Usuários, os Usuários garantirão
        que a publicação desse Conteúdo Fornecido pelos Usuários e os atos
        relevantes estejam em conformidade com as políticas de direitos autorais
        relevantes nas leis e nos regulamentos de propriedade intelectual
        relevantes, incluindo, sem limitações:
      </p>

      <p>
        4.1. Ao receber uma notificação de violação enviada pela Intelbras ou
        por qualquer outro terceiro, os Usuários excluirão ou proibirão
        imediatamente o acesso ao conteúdo infrator declarado e entrarão em
        contato com a pessoa responsável pelo envio para entender informações
        detalhadas ou enviar provas contrárias dentro de cinco dias úteis,
        incluindo, sem limitações: (1) as informações da parte contra a qual a
        reclamação é feita e os materiais relevantes; (2) o ponto de vista da
        parte contra a qual a reclamação é feita em relação à reclamação; (3)
        evidência preliminar de nenhuma infração; e (4) garantias feitas pela
        parte contra a qual a reclamação é feita.
      </p>

      <p>
        4.2. Os Usuários reconhecem e concordam que a Intelbras administrará, de
        acordo com as leis e os regulamentos relevantes, notificações de
        violação qualificadas enviadas por terceiros e, de acordo com os
        requisitos, excluirá ou proibirá o acesso ao conteúdo infrator declarado
        e adotará e implementará políticas apropriadas na tentativa de evitar
        infrações repetidas sob condições correspondentes.
      </p>

      <p>
        <strong>5. Proteção da Privacidade</strong>
      </p>

      <p>
        A Intelbras respeita integralmente a proteção das informações pessoais
        dos Usuários, e proteger suas informações pessoais é um princípio básico
        da Intelbras.
      </p>

      <p>
        5.1. A Intelbras não tornará público ou fornecerá a terceiros conteúdo
        não público que os Usuários armazenam no Serviço Izy Manager, salvo sob
        as seguintes circunstâncias:
      </p>

      <p>
        1) Especificação por leis e regulamentos relevantes ou por procedimentos
        legais de serviço do Serviço Izy Manager;
      </p>

      <p>
        2) Com o objetivo de proteger os direitos e interesses dos Usuários e do
        público em caso de emergência;
      </p>

      <p>
        3) Com o objetivo de proteger a marca registrada, a patente e quaisquer
        outros direitos e interesses legítimos da Intelbras; e
      </p>

      <p>
        4) Qualquer outra circunstância sob a qual as informações pessoais sejam
        divulgadas, processadas ou divulgadas de acordo com a lei.
      </p>

      <p>
        <strong>6. Assinatura</strong>
      </p>

      <p>
        6.1 O Usuário pode acessar aos Serviço Izy Manager escolhendo uma das
        seguintes opções: 1) Assinar um Plano de vigência mensal, com renovação
        automática; 2) Assinar um Plano de vigência Anual, mediante pagamento
        único antecipado, conforme definido no ato do pagamento.
      </p>

      <p>
        6.2 Você pode ativar uma versão de Teste Grátis clicando em
        “Experimentar Grátis” no Izy Manager app. Você pode fazer o teste
        gratuito uma vez para cada conta cadastrada.
      </p>

      <p>
        6.3 Durante o teste gratuito, se você pagar pelo plano de assinatura
        regular (“Assinatura Paga”), ele entrará em vigor imediatamente. A
        versão de Teste Grátis será suspensa.
      </p>

      <p>
        6.4 As taxas de assinatura são faturadas ou cobradas no primeiro dia do
        período de assinatura aplicável. A assinatura é exclusiva por
        dispositivo. Caso o Usuário possua mais de uma conta ou um
        empreendimento, deverá efetuar mais de uma assinatura. Caso qualquer
        pagamento seja devolvido não efetuado ou qualquer cartão de crédito ou
        transação semelhante seja rejeitado ou negado, a Intelbras reserva o
        direito de cancelar sua assinatura e cobrar qualquer taxa de rejeição ou
        fundos insuficientes e processar qualquer pagamento pelo método de
        pagamento que você nos enviou.
      </p>

      <p>
        6.5 Durante a sua Assinatura Paga, se você comprar outro plano de
        assinatura, o prazo do novo plano de assinatura será adicionado à
        Assinatura Paga existente. Quando a Assinatura Paga existente expirar, o
        novo plano de assinatura começará imediatamente, ou seja,{" "}
        <u>a assinatura é renovada automaticamente</u>. Caso o Usuário não
        deseje a renovação, proceder conforme previsto no item 6.10.
      </p>

      <p>6.6 Tipo de Assinatura Paga</p>

      <table className={classes.table}>
        <thead>
          <tr>
            <td>
              <strong>Plano</strong>
            </td>
            <td>
              <strong>Ciclo de Pagamento</strong>
            </td>
            <td>
              <strong>Taxa de Assinatura por Ciclo</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          {tablePlansValue.map((row, index) => (
            <tr key={"table-row-" + index}>
              <td>{row.name}</td>
              <td>{row.interval}</td>
              <td>{row.value}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <p>
        6.7 Para ativar uma assinatura, você pode abrir o Izy Manager, clicar em
        dispositivos e no logotipo do serviço na nuvem e escolher a assinatura
        para compra. Se você tiver um cartão pré-pago para o Serviço Izy
        Manager, poderá iniciar a assinatura digitando o código.
      </p>

      <p>
        6.8 Se você desativar sua assinatura existente, seu dispositivo
        interromperá o upload de vídeos para a nuvem; no entanto, seu termo de
        assinatura existente não será estendido e expirará no fim do prazo
        original. Você não será elegível para qualquer reembolso proporcional de
        qualquer parte da taxa de assinatura paga por esse plano de assinatura.
      </p>

      <p>
        6.9 Caso o Usuário tenha interesse em cancelar o Plano de assinatura,
        deverá fazer a solicitação direto no Aplicativo, opção Cancelar
        Assinatura. Os cancelamentos devem ser realizados até 3 (três) dias
        antes do final do Mês, sob pena de considerar o Mês seguinte como ativo
        e gerar a respectiva cobrança. Caso o Usuário tenha assinado um Plano
        Anual, o cancelamento antecipado gerará uma restituição ao Usuário do
        valor já pago, descontados os meses utilizados (inclusive o mês do
        cancelamento) além do desconto da multa rescisória correspondente a 30%
        (trinta por cento) do valor total. No Plano Mensal, não há valor a ser
        restituído. Em caso de qualquer problema no cancelamento via Aplicativo,
        o Usuário poderá enviar e-mail para suporte@intelbras.com.br ou telefone
        (48) 2106-0006.
      </p>

      <p>
        6.10 Caso o Usuário tenha interesse em cancelar o Plano de assinatura,
        deverá fazer a solicitação direto no Aplicativo, opção Cancelar
        Assinatura. Os cancelamentos devem ser realizados até 3(três) dias antes
        do final do Mês, sob pena de considerar o Mês seguinte como ativo e
        gerar a respectiva cobrança. Caso o Usuário tenha assinado um Plano
        Anual, o cancelamento antecipado gerará uma restituição ao Usuário do
        valor já pago, descontados os meses utilizados (inclusive o mês do
        cancelamento) além do desconto da multa rescisória correspondente a
        30%(trinta por cento) do valor total. No Plano Mensal, não há valor a
        ser restituído. Em caso de qualquer problema no cancelamento via
        Aplicativo, o Usuário poderá enviar e-mail para suporte@intelbras.com.br
        ou telefone (48) 2106-0006.
      </p>

      <p>
        <strong>7. Declaração de Isenção de Responsabilidade</strong>
      </p>

      <p>
        7.1 Considerando a natureza específica dos serviços de rede, os Usuários
        concordam que o Serviço d Izy Manager tem o direito de alterar,
        interromper ou encerrar parte de ou todos os serviços de rede a qualquer
        momento. Se os serviços de rede alterados, interrompidos ou encerrados
        forem gratuitos, o Serviço Izy Manager não precisará notificar os
        Usuários separadamente nem arcará com qualquer responsabilidade com
        relação a qualquer Usuário ou a terceiros.
      </p>

      <p>
        7.2 Os Usuários entendem que o Serviço Izy Manager precisa inspecionar e
        reparar, regular ou irregularmente, ou manter a plataforma que fornece
        serviços de rede ou equipamento relevante, e se, em tais circunstâncias,
        qualquer serviço com base em tarifas for interrompido dentro de um
        período razoável, o Serviço Izy Manager não precisa se responsabilizar
        por tal interrupção, desde que, no entanto, avise previamente na medida
        do possível.
      </p>

      <p>
        7.3 O Serviço Izy Manager pode alterar este Serviço ou excluir parte de
        suas funções a qualquer momento, por qualquer motivo. O Serviço Izy
        Manager pode cancelar ou encerrar serviços aos Usuários a qualquer
        momento. A decisão do Serviço de cancelar ou encerrar serviços não
        requer motivo ou notificação aos Usuários. Depois que os serviços são
        cancelados, os direitos dos Usuários de usar este Serviço terminam
        imediatamente. Depois que este Serviço for cancelado ou encerrado,
        qualquer informação armazenada pelos Usuários neste Serviço poderá ser
        irrecuperável.
      </p>

      <p>
        7.4 O Serviço Izy Manager não garante (incluindo, mas sem limitações):
      </p>

      <p>
        1) O Serviço Izy Manager é adequado para os requisitos de uso dos
        Usuários;
      </p>

      <p>
        2) O Serviço Izy Manager é uma taxa imperturbável, oportuna, segura,
        confiável ou de erro; e qualquer produto, serviço ou outro material que
        os Usuários obtenham pelo Serviço Izy Manager atenda às expectativas dos
        Usuários;
      </p>

      <p>
        3) Os Usuários serão responsáveis pelos riscos do uso de quaisquer dados
        baixados ou obtidos pelo Serviço Izy Manager; e se, devido a esse uso,
        os sistemas de computadores dos Usuários estiverem danificados ou seus
        dados forem perdidos, os Usuários serão os únicos responsáveis por tais
        danos ou perdas.
      </p>

      <p>
        4) Uma vez excluídos, os dados do usuário são irrecuperáveis; os
        Usuários arcarão com as consequências e responsabilidades causadas por
        essa exclusão de dados, e o Serviço Izy Manager não tem qualquer
        obrigação de continuar retendo, exportando ou retornando os dados
        comerciais dos Usuários.
      </p>

      <p>
        7.5 Com relação a qualquer perda de lucro, ágio ou dados ou qualquer
        outro dano tangível ou intangível, direto, indireto, especial, emergente
        ou exemplar resultante dos seguintes motivos, o Serviço Izy Manager não
        será responsável por qualquer compensação direta ou indireta:
      </p>

      <p>1) O uso ou a indisponibilidade do Serviço Izy Manager;</p>

      <p>
        2) Quaisquer produtos, dados ou serviços adquiridos ou obtidos pelo
        Serviço Izy Manager;
      </p>

      <p>3) Qualquer alteração feita no Serviço;</p>

      <p>
        4) exclusão, corrupção ou falha de armazenamento e/ou envio ou
        recebimento de sua transmissão ou dados em ou por meio do Serviço; e
      </p>

      <p>
        5) Uso ou modificação não autorizada de dados do usuário e qualquer
        outro assunto relacionado ao Serviço Izy Manager.
      </p>

      <p>
        7.6. Como usuário registrado do Serviço, você deve estabelecer uma
        Conta. Não revele as informações da sua Conta para mais ninguém. Você é
        o único responsável por manter a confidencialidade e segurança de sua
        Conta e por todas as atividades que ocorram em sua Conta ou por meio
        dela e concorda em notificar imediatamente a Intelbras sobre qualquer
        violação de segurança em sua Conta. Você também reconhece e concorda que
        o Serviço foi projetado e destinado ao uso pessoal em uma base
        individual e não deve compartilhar os detalhes da sua Conta e/ou senha
        com qualquer outra parte. Desde que a Intelbras tenha exercido
        habilidades razoáveis e o devido cuidado, a Intelbras não será
        responsável por quaisquer perdas decorrentes do uso não autorizado de
        sua Conta que sejam resultado do seu descumprimento destas regras.
      </p>

      <p>
        <strong>8. Extinção do Vínculo Contratual</strong>
      </p>

      <p>
        8.1 Se você deseja parar de usar o Serviço no seu dispositivo, você pode
        excluir seu usuário ou seu dispositivo. Para encerrar sua conta e
        excluir seu usuário, você pode entrar em contato com o Atendimento ao
        Cliente em suporte@intelbras.com.br. Ao encerrar sua conta e excluir seu
        usuário, você não terá acesso aos Serviços com esse usuário. Esta ação
        pode ser irreversível. Quaisquer taxas pagas por você antes do
        encerramento não são reembolsáveis (exceto quando expressamente
        permitido de outra forma pelos Termos), incluindo quaisquer taxas pagas
        antecipadamente pelo ano de cobrança durante o qual você encerra seu
        vínculo. A extinção da sua conta não isentará você de qualquer obrigação
        de pagar quaisquer taxas ou encargos acumulados.
      </p>

      <p>
        8.2 A Intelbras pode, a qualquer momento, sob certas circunstâncias e
        sem notificação prévia, extinguir ou suspender imediatamente toda ou
        parte da sua conta e/ou acesso ao serviço. A causa de tal extinção
        incluirá: (a) violações dos Termos ou quaisquer outras políticas ou
        diretrizes aqui referidas e/ou publicadas no Serviço; (b) uma
        solicitação sua para cancelar ou encerrar sua Conta; (c) uma solicitação
        e/ou ordem de órgãos de cumprimento da lei, de um órgão judicial ou de
        outra agência governamental; (d) quando a prestação do Serviço a você é
        ou pode tornar-se ilegal; (e) questões ou problemas técnicos ou de
        segurança inesperados; (f) sua participação em atividades fraudulentas
        ou ilegais; ou (g) falha no pagamento de quaisquer taxas devidas por
        você em relação ao Serviço, desde que, no caso de violação não
        substancial, a Intelbras tenha permissão para extinguir somente após
        notificação prévia de 30 dias e somente se você não tiver sanado a
        violação no prazo de 30 dias. Qualquer extinção ou suspensão será feita
        pela Intelbras a seu exclusivo critério e a Intelbras não será
        responsável perante você ou terceiros por quaisquer danos que possam
        resultar ou decorram de tal extinção ou suspensão de sua Conta e/ou seu
        acesso ao Serviço. Além disso, a Intelbras pode encerrar sua conta
        mediante notificação prévia de 30 dias por e-mail para o endereço
        associado à sua Conta se (a) sua Conta estiver inativa por 1 (um) ano;
        ou (b) houver uma descontinuação geral do Serviço ou de qualquer parte
        dele. A notificação de descontinuação geral do serviço será fornecida
        conforme estabelecido neste documento a menos que não seja razoável
        fazê-lo devido a circunstâncias decorrentes de ação legal, regulatória
        ou governamental; para abordar questões de segurança, privacidade do
        usuário ou integridade técnica do usuário; evitar interrupções no
        serviço para outros usuários; ou devido a um desastre natural, um evento
        catastrófico, guerra ou outra ocorrência semelhante fora do controle
        razoável da Intelbras. No caso de tal extinção, a Intelbras fornecerá a
        você um reembolso <em>pro rata</em> de qualquer pré-pagamento referente
        ao seu prazo pago atual.
      </p>

      <p>
        <strong>9. Disposições Diversas</strong>
      </p>

      <p>
        9.1 O direito final de interpretar os Termos estará investido na
        Intelbras.
      </p>

      <p>
        9.2 A Intelbras reserva o direito de aditar os Termos a qualquer momento
        e tais aditamentos serão publicados no site do Serviço Izy Manager. Se
        algum Usuário discordar de qualquer aditamento feito pelo Serviço Izy
        Manager a qualquer disposição dos Termos, tal Usuário poderá deixar de
        usar o Serviço Izy Manager. Se esse Usuário continuar usando o Serviço
        Izy Manager, será considerado que ele aceitou o aditamento feito pela
        Intelbras à disposição relevante dos Termos.
      </p>

      <p>
        9.3 Todas as notificações aos Usuários do Serviço Izy Manager aqui
        mencionadas podem ser dadas por anúncios de páginas da web, e-mails ou
        correspondências regulares; considera-se que essas notificações foram
        entregues ao destinatário no dia em que foram enviadas.
      </p>

      <p>
        9.4 Se qualquer disposição dos Termos for inválida ou inexequível,
        inteira ou parcialmente, por qualquer motivo, as demais disposições dos
        Termos permanecerão válidas e exequíveis.
      </p>

      <p>
        9.5 Caso qualquer disposição dos Termos sobreviva à extinção dos Termos
        por sua natureza ou de outra forma, tal disposição será considerada como
        sobrevivente à extinção dos Termos, incluindo, entre outros, garantias,
        cláusulas de confidencialidade, cláusulas de propriedade intelectual e
        legislação regente e cláusulas de resolução de disputas.
      </p>

      <p>
        9.6 Determinados Conteúdo, componentes ou recursos do Serviço podem
        incluir materiais de terceiros e/ou links para outros sites, recursos ou
        Conteúdo. Como a Intelbras pode não ter controle sobre esses sites e/ou
        materiais de terceiros, você reconhece e concorda que a Intelbras não é
        responsável pela disponibilidade desses sites ou recursos, e não endossa
        ou garante a precisão de tais sites ou recursos, e não será de forma
        alguma responsável por qualquer conteúdo, publicidade, quaisquer
        produtos ou materiais contidos ou disponíveis em tais sites ou recursos.
        Você também reconhece e concorda que a Intelbras não será responsável de
        maneira alguma por quaisquer danos que você incorra ou alegue ter
        ocorrido, direta ou indiretamente, como resultado de seu uso de e/ou sua
        confiança em qualquer Conteúdo, publicidade, produtos ou materiais ou
        disponíveis a partir desses sites ou recursos.
      </p>

      <p>
        9.7 Controle de Exportação. O uso do Serviço e Software, incluindo
        transferência, publicação ou upload de dados, software ou outro Conteúdo
        por meio do Serviço, pode estar sujeito às leis de exportação e
        importação dos Estados Unidos e de outros países. Você concorda em
        cumprir todos os regulamentos e leis de controle de exportação e
        importação aplicáveis. Em particular, mas sem limitação, o Software não
        pode ser exportado ou reexportado (a) para qualquer país embargado pelos
        EUA ou (b) para qualquer pessoa na lista de Cidadãos Especialmente
        Designados do Departamento do Tesouro dos EUA ou na Lista de Entidades
        ou Lista de Pessoas Negadas do Departamento de Comércio dos EUA. Ao usar
        o Serviço, você declara e garante que não está localizado em nenhum
        desses países ou em nenhuma dessas listas. Você também concorda que não
        usará o Serviço para quaisquer fins proibidos por qualquer lei
        aplicável, incluindo, sem limitação, o desenvolvimento, o projeto, a
        fabricação ou a produção de mísseis, armas nucleares, químicas ou
        biológicas. Você também concorda em não enviar para sua conta quaisquer
        dados ou softwares que estejam: (a) sujeitos ao Regulamento
        Internacional de Tráfico de Armas; ou (b) que não possam ser exportados
        sem autorização prévia por escrito do governo, incluindo, mas não se
        limitando a, certos tipos de software de criptografia e código-fonte,
        sem primeiro obter essa autorização. Esta garantia e compromisso
        sobreviverão à extinção do vínculo dos Termos.
      </p>

      <p>
        9.8 Entre em contato com o Atendimento ao Cliente em{" "}
        <u>suporte@intelbras.com.br</u> caso tenha alguma dúvida.
      </p>
    </>
  );
};

export default StripeTermsText;
