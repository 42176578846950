import { Plans } from "../../Models/Plans";
import { Button, TablePlans, ThCustom, ValueText } from "./styles";
import Skeleton from "@material-ui/lab/Skeleton";
import * as React from "react";

interface PropsTableButton {
  callback: (plan: Plans) => void;
  loading: boolean;
  plans: Plans[];
}

const RowSkeleton: React.FunctionComponent<{
  height?: string | number;
  width?: string | number;
  style?: any;
  header?: boolean;
}> = ({ height, width, style, header }) => {
  const attrs = {
    height: height || "1.5rem",
    width: width || "100%",
  };

  const content = () => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Skeleton variant="rect" {...attrs} />
    </div>
  );

  return (
    <>
      {[1, 2, 3, 4, 5].map((idx) => {
        if (header) {
          return (
            <ThCustom key={idx} color={"lightgray"} style={style}>
              {content()}
            </ThCustom>
          );
        }
        return (
          <td key={idx} style={style}>
            {content()}
          </td>
        );
      })}
    </>
  );
};

const CustomTablePlans: React.FunctionComponent<PropsTableButton> = ({
  callback,
  plans,
  loading,
}) => {
  return (
    <div
      style={{
        display: "flex",
        width: " 100%",
        alignItems: "center",
        justifyContent: "center",
        padding: "100px",
      }}
    >
      <TablePlans>
        <thead>
          <tr>
            <ThCustom color="transparent"></ThCustom>
            {!loading &&
              plans.map((plan, index) => (
                <ThCustom
                  color={plan.color_plan}
                  key={"plan" + index}
                  recomended={plan.recommend}
                >
                  {plan.name}
                  {plan.recommend && <span>(Recomendado)</span>}
                </ThCustom>
              ))}

            {loading && <RowSkeleton header height={"1.8rem"} width={"70%"} />}
          </tr>
        </thead>
        <tbody>
          <tr className="boderLeftTop">
            <td className="bigTd boderLeftTop">Tabela de preços</td>
            {!loading &&
              plans.map((plan, index) => {
                const pricePlanDay = plan.prices.filter(
                  (p) => p.interval === "day"
                )[0];
                const pricePlanMonth = plan.prices.filter(
                  (p) => p.interval === "month"
                )[0];
                const pricePlanYear = plan.prices.filter(
                  (p) => p.interval === "year"
                )[0];

                return (
                  <td key={"linha" + index}>
                    <div>
                      <span>R$</span>
                      {pricePlanDay && pricePlanDay?.interval_count >= 1 && (
                        <ValueText color={plan.color_plan}>
                          {pricePlanDay.price}
                        </ValueText>
                      )}
                      {!pricePlanDay && (
                        <ValueText color={plan.color_plan}>
                          {pricePlanMonth.price}
                        </ValueText>
                      )}
                    </div>

                    {pricePlanDay && pricePlanDay?.interval_count >= 1 && (
                      <div className="smallDescription">
                        {pricePlanDay?.interval_count} dias{" "}
                        <strong>gratuitos</strong>
                      </div>
                    )}

                    {!pricePlanDay && (
                      <div className="smallDescription">
                        mensais ou
                        <strong> R$ {pricePlanYear.price}</strong> anual
                      </div>
                    )}
                  </td>
                );
              })}
            {loading && <RowSkeleton />}
          </tr>
          <tr>
            <td>
              Quantidade de <br />
              usuários
            </td>
            {!loading &&
              plans.map((plan, index) => (
                <td key={"userPlan" + index}>{plan.max_user}</td>
              ))}
            {loading && <RowSkeleton />}
          </tr>
          <tr>
            <td>
              Quantidade de <br />
              dispositivos
            </td>
            {!loading &&
              plans.map((plan, index) => (
                <td key={"userDev" + index}>{plan.max_device}</td>
              ))}
            {loading && <RowSkeleton />}
          </tr>
          <tr>
            <td>
              Quantidade de <br />
              automações
            </td>
            {!loading &&
              plans.map((plan, index) => (
                <td key={"userAut" + index}>{plan.max_automation}</td>
              ))}
            {loading && <RowSkeleton />}
          </tr>
          <tr className="boderLeftBottom">
            <td className="boderLeftBottom"></td>
            {!loading &&
              plans.map((plan, index) => (
                <td key={"buttonPlan" + index}>
                  <Button
                    onClick={() => callback(plan)}
                    color={plan.color_plan}
                  >
                    Adquirir
                  </Button>
                </td>
              ))}
            {loading && (
              <RowSkeleton
                height={"2.5rem"}
                width={100}
                style={{ padding: "55px 10px" }}
              />
            )}
          </tr>
        </tbody>
      </TablePlans>
    </div>
  );
};

export default CustomTablePlans;
