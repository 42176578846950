import { useCallback, useContext, useEffect, useState } from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { Button, Card as MuiCard } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import { getEnterpriseById } from "../../Services/EnterpriseService";
import { ErrorMessage } from "../../Utils/ErrorMessage";
import GlobalContext from "../../GlobalContext/GlobalContext";
import {
  EnterprisePlanModel,
  SchedulePlan,
} from "../../Models/EnterpriseModel";
import { dateDiffInDays } from "../../Utils/Functions";
import history from "../../Services/history";
import MuiCardContent from "@material-ui/core/CardContent";
import { addMinutes, compareAsc } from "date-fns";

const Card = withStyles(() => ({
  root: {
    borderRadius: "15px 15px 0px 0px",
    width: "450px",
    marginBottom: 0,
    position: "fixed",
    bottom: 0,
    right: 20,
    boxShadow: "0px 0px 14px 3px rgba(0, 0, 0, 0.25)",
    zIndex: 9999,
  },
}))(MuiCard);

const DialogTitle = withStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #e9e9e9",
    padding: "12px 12px 12px 28px",
    "& .title": {
      fontWeight: "bold",
      margin: "0",
      fontSize: "16px",
    },
  },
}))(MuiDialogTitle);

const CardContent = withStyles(() => ({
  root: {
    padding: "16px 28px 28px 28px",

    "& table": {
      border: "1px solid #e9e9e9",
      width: "60%",
      borderCollapse: "collapse",
      marginBottom: 12,

      "& td, th": {
        border: "1px solid #e9e9e9",
        padding: "4px",
      },
    },
  },
}))(MuiCardContent);

const CloseButton = withStyles(() => ({
  root: {
    textTransform: "initial",
    color: "#8B979F",
  },
}))(Button);

function RemainDays({ date }: { date: string | undefined }) {
  const [text, setText] = useState<string>("dias restantes");

  useEffect(() => {
    return () => {
      setText("");
    };
  }, []);

  const remaingDays = useCallback(() => {
    if (!date) return "";
    return dateDiffInDays(new Date(), new Date(date));
  }, [date]);

  useEffect(() => {
    remaingDays() > 0 ? setText("dias restantes") : setText("dia restante");
  }, [date]);

  return (
    <strong>
      {remaingDays()} {text}
    </strong>
  );
}

function ValuesCompared({
  current,
  next,
}: {
  current?: number;
  next?: number;
}) {
  if (current && next && current > next)
    return (
      <>
        <strong style={{ color: "red" }}>{current}</strong> / {next}
      </>
    );
  return (
    <>
      {current} / {next}
    </>
  );
}

const SHOW_LIMIT_EXCEEDED_AT = "nextCheckPlanLimitAt";

const CustomModalDowngradePlan: React.FunctionComponent = () => {
  const [open, setOpen] = useState<boolean>(false);
  const DISPLAY_EVERY_MINUTES = 60;

  const [currentPlan, setCurrentPlan] = useState<EnterprisePlanModel>();
  const [schedulePlan, setSchedulePlan] = useState<SchedulePlan>();

  const [isMounted, setIsMounted] = useState<boolean>(true);

  const { home } = useContext(GlobalContext);

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
      setOpen(false);
      setCurrentPlan(undefined);
      setSchedulePlan(undefined);
    };
  }, []);

  const checkLimitExceeded = (
    currentPlan: EnterprisePlanModel,
    schedulePlan: SchedulePlan | undefined
  ) => {
    if (!currentPlan || !schedulePlan) return false;

    return (
      currentPlan.qtn_user > schedulePlan.plan.max_user ||
      currentPlan.qtn_automation > schedulePlan.plan.max_automation ||
      currentPlan.qtn_device > schedulePlan.plan.max_device
    );
  };

  const checkTime = () => {
    const nextShow = localStorage.getItem(SHOW_LIMIT_EXCEEDED_AT);
    if (!nextShow) return true; // se ainda não tem configurado próxima exibição, já exibe.
    return compareAsc(new Date(), new Date(nextShow!)) >= 0; // Exibe se ultrapassou o tempo da proxima exibição
  };

  const loadData = async () => {
    try {
      const { data } = await getEnterpriseById(home.home_id);
      if (isMounted) {
        setCurrentPlan(data.plan);
        setSchedulePlan(data.schedule_plan);
        if (checkLimitExceeded(data.plan, data.schedule_plan)) {
          startTimer(true);
        }
      }
    } catch (e) {
      const msg = ErrorMessage(e, "Não foi possível carregar dados do plano");
      console.log(msg);
    }
  };

  useEffect(() => {
    if (home.home_id) loadData();
  }, [home]);

  const startTimer = (immediately = false) => {
    const routine = (timer: NodeJS.Timeout | null) => {
      if (checkTime()) {
        if (timer) clearInterval(timer);
        setOpen(true);
      }
    };
    if (immediately) routine(null); // executa imediatamente
    const timer = setInterval(() => {
      routine(timer);
    }, 60 * 1000);
  };

  const goToMyEnterprises = () => {
    history.push("/my-enterprises");
  };

  const handleCloseButton = () => {
    setOpen(false);
    startTimer();
    const nextShowAt = addMinutes(new Date(), DISPLAY_EVERY_MINUTES);
    localStorage.setItem(SHOW_LIMIT_EXCEEDED_AT, nextShowAt.toISOString());
  };

  return (
    <>
      {open && (
        <Card id="dialog-exceeded-plan">
          <DialogTitle disableTypography={true}>
            <h4 className="title">Limite excedente de plano</h4>
            <CloseButton onClick={handleCloseButton} startIcon={<CloseIcon />}>
              Fechar
            </CloseButton>
          </DialogTitle>
          <CardContent>
            <p>
              Você solicitou a troca de plano e seu novo plano está em
              processamento. Porém, você superou o limite do novo plano.
            </p>
            <p>
              Caso não realize a exclusão, o excedente será bloqueado quando o
              novo plano entrar em vigor.
            </p>

            <div style={{ display: "flex" }}>
              <table>
                <tr>
                  <td>Usuários</td>
                  <td>
                    <ValuesCompared
                      current={currentPlan?.qtn_user}
                      next={schedulePlan?.plan.max_user}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Dispositivos</td>
                  <td>
                    <ValuesCompared
                      current={currentPlan?.qtn_device}
                      next={schedulePlan?.plan.max_device}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Automações</td>
                  <td>
                    <ValuesCompared
                      current={currentPlan?.qtn_automation}
                      next={schedulePlan?.plan.max_automation}
                    />
                  </td>
                </tr>
              </table>
              <div style={{ width: "40%", paddingLeft: 16 }}>
                <img src="assets/imgs/pessoa_suporte.png" height="100" />
              </div>
            </div>
            <p>
              A troca de plano será efetuada em:
              <br />
              <RemainDays date={schedulePlan?.start_at} />
            </p>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => goToMyEnterprises()}
            >
              Ver empreendimentos
            </Button>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default CustomModalDowngradePlan;
