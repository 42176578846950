import React, { useContext } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DrawerProps from "../../../../Types/DrawerProps";
import UsersPageContext from "../../Context/UsersPageContext";
import WithRoleAuthorization from "../../../../Components/WithRoleAuthorization";
import CustomFilter from "../../../../Components/CustomFilter";
import { IconButton } from "@material-ui/core";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      backgroundColor: "#FFF",
      display: "flex",
      justifyContent: "space-between",
      padding: "0px 3rem 10px 5.5rem",
    },
    button: {
      textTransform: "none",
      width: "230px",
      height: "55px",
      fontSize: "15px",
      fontWeight: 700,
      lineHeight: "20px",
    },
    filterInput: {
      width: "35rem",
    },
  })
);

const Filters: React.FunctionComponent = () => {
  const classes = useStyles();
  const anchor: DrawerProps = "right";
  const {
    setIsToggleOpen,
    isToggleOpen,
    setIsLoadingUsers,
    getUsersByFilterValue,
  } = useContext(UsersPageContext);

  const { setExpandSubHeader } = useContext(GlobalContex);

  const clickExpand = () => {
    setExpandSubHeader(false);
  };

  return (
    <div className={classes.container}>
      <CustomFilter
        placeHolder={"Procurar por nome de usuários"}
        id={"search-for-users"}
        isFilterInFront={false}
        className={classes.filterInput}
        setStateLoading={setIsLoadingUsers}
        setLoadingFunction={getUsersByFilterValue}
      />
      <div>
        <WithRoleAuthorization permitedRoles={["Owner", "Administrador"]}>
          <React.Fragment key={anchor}>
            <Button
              onClick={() => {
                setIsToggleOpen(true);
              }}
              className={classes.button}
              startIcon={<AddIcon />}
              variant="contained"
              color="secondary"
              disabled={isToggleOpen}
            >
              Novo usuário
            </Button>
          </React.Fragment>
        </WithRoleAuthorization>
        <IconButton onClick={clickExpand}>
          <img src="assets/icons/close-header-icon.svg" />
        </IconButton>
      </div>
    </div>
  );
};

export default Filters;
