import { createTheme, Theme } from "@material-ui/core/styles";
// import { blue, green, grey, orange, red } from "@material-ui/core/colors";
// import { PaletteType } from "@material-ui/core";

const theme: Theme = createTheme({
  palette: {
    type: "dark",
  },
});

export default theme;
