import { makeStyles, createStyles } from "@material-ui/core";
import { Resume } from "./styles";
import { Plans, Price } from "./../../../../Models/Plans";
import ItemIcon from "../../../EnterpriseCreatePage/components/ItemIcon";
import { toREAL } from "../../../../Utils/CurrencyUtils";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    containerColumn: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    info: {
      color: "#00A335",
    },
    p: {
      margin: "0px",
    },
  })
);

interface Props {
  plan: Plans;
  price: Price;
}
const ResumePlan: React.FunctionComponent<Props> = ({ plan, price }: Props) => {
  const classes = useStyles();
  return (
    <Resume>
      <div
        className={classes.container}
        style={{
          borderRight: "1px solid #EBEEEE",
        }}
      >
        <ItemIcon color={plan.color_plan} />
        <p
          className={classes.p}
          style={{
            marginLeft: "20px",
          }}
        >
          <strong>{plan.name}</strong>
        </p>
      </div>
      <div
        className={classes.containerColumn}
        style={{
          display: "flex",
          justifyContent: "start !important",
          paddingLeft: "40px",
        }}
      >
        <p className={classes.p}>Valor total</p>
      </div>
      <div
        className={classes.containerColumn}
        style={{
          display: "flex",
          justifyContent: "end !important",
        }}
      >
        <p className={classes.p}>
          <strong>{toREAL(price.price)}</strong>
        </p>
      </div>
    </Resume>
  );
};

export default ResumePlan;
