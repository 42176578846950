import {
  createStyles,
  IconButton,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { useContext, useEffect, useState } from "react";
import HomePageContext from "../../Pages/HomePage/Context/HomePageContext";
import history from "../../Services/history";
import GlobalContex from "../../GlobalContext/GlobalContext";
import { SyncOutlined } from "@material-ui/icons";
import { cacheInfo, purgeCache } from "../../Services/DevicesService";
import { getPlaceNameSelect } from "../../Services/UserService";
import { formatDistanceToNow, parseJSON } from "date-fns";
import ptBr from "date-fns/locale/pt-BR";

const useStyles = makeStyles(() =>
  createStyles({
    customToolbar: {
      backgroundColor: "#FFF",
      maxWidth: "100%",
      overflowX: "auto",
    },
    button: {
      textTransform: "none",
      padding: "10px 30px",
      marginRight: "10px",
    },
    content: {
      display: "flex",
      flexDirection: "row",
      flexGrow: 1,
      justifyContent: "start",
      alignItems: "center",
    },
    scenarios: {
      display: "flex",
      flexDirection: "row",
      flexGrow: 1,
      justifyContent: "start",
      alignItems: "center",
    },
    scenarioPrincipal: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginRight: "30px",
      color: "#3E5055",
      fontSize: "32px",
      fontWeight: "bold",
    },
  })
);

const CustomActionBar: React.FunctionComponent = () => {
  const { getEnvironments, setOpenShortcutsDrawer } =
    useContext(HomePageContext);
  const { toggleWaitIndicator, room } = useContext(GlobalContex);
  const [lastUpdateCacheTime, setLastUpdateCacheTime] = useState<
    string | undefined
  >(undefined);

  const classes = useStyles();

  useEffect(() => {
    cacheInfo().then((result) => {
      setLastUpdateCacheTime(result.data);
    });

    return () => {
      setLastUpdateCacheTime(undefined);
    };
  }, []);

  useEffect(() => {
    if (room !== null && room !== undefined) {
      getEnvironments(room.room_id);
    }
  }, [room]);

  useEffect(() => {
    return () => {
      toggleWaitIndicator("CustomAxctionBar:forceSync", false);
    };
  }, []);

  function forceSync() {
    const placeNameSelect = getPlaceNameSelect();
    toggleWaitIndicator("CustomAxctionBar:forceSync", true);
    purgeCache()
      .then(() => {
        localStorage.setItem("roomSelected", JSON.stringify(room));
        localStorage.setItem("placeSelectName", placeNameSelect);
        localStorage.setItem("syncBtnCheck", "true");
        document.location.reload();
      })
      .finally(() => {
        toggleWaitIndicator("CustomAxctionBar:forceSync", false);
      });
  }

  const backButtonClick = () => {
    history.push("/manage-room");
  };

  return (
    <Toolbar className={classes.customToolbar}>
      <div className={classes.content}>
        <div className={classes.scenarios}>
          <div className={classes.scenarioPrincipal}>
            <IconButton onClick={backButtonClick}>
              <img src="assets/icons/icon_return.svg" />
            </IconButton>{" "}
            Painel de Controle
          </div>
        </div>
        <div>
          <Tooltip
            title={
              <>
                Sincronizar com o IzySmart
                <br />
                {lastUpdateCacheTime
                  ? `Última sincronização há ${formatDistanceToNow(
                      parseJSON(lastUpdateCacheTime),
                      { locale: ptBr }
                    )}`
                  : ""}
              </>
            }
            placement="top"
          >
            <Button
              className={classes.button}
              startIcon={<SyncOutlined />}
              variant="outlined"
              color="secondary"
              onClick={() => {
                forceSync();
              }}
            >
              Sincronizar
            </Button>
          </Tooltip>
          <Button
            className={classes.button}
            startIcon={
              <img src={"assets/icons/icon_edit_note.svg"} width={25} />
            }
            variant="contained"
            color="secondary"
            onClick={() => {
              setOpenShortcutsDrawer(true);
            }}
          >
            Editar painel
          </Button>
        </div>
      </div>
    </Toolbar>
  );
};

export default CustomActionBar;
