import EnvironmentModel, {
  EnvironmentRequestModel,
} from "../Models/EnvironmentModel";
import api from "./Api";
import authHeader, { getHomeIDSelect } from "./UserService";
import { AxiosResponse } from "axios";
import DeviceModel from "../Models/DeviceModel";

export const listEnvironments = (
  room_id: string
): Promise<AxiosResponse<EnvironmentModel[]>> => {
  return api.get(`/homes/${getHomeIDSelect()}/rooms/${room_id}/environments`, {
    headers: authHeader(),
  });
};

export const createEnvironments = (
  room_id: string,
  environment: EnvironmentRequestModel
): Promise<AxiosResponse<number>> => {
  return api.post(
    `/homes/${getHomeIDSelect()}/rooms/${room_id}/environments`,
    environment,
    {
      headers: authHeader(),
    }
  );
};

export const removeEnvironment = (
  room_id: string,
  environmentID: number
): Promise<AxiosResponse<boolean>> => {
  return api.delete(
    `/homes/${getHomeIDSelect()}/rooms/${room_id}/environments/${environmentID}`,
    {
      headers: authHeader(),
    }
  );
};

export const editEnvironment = (
  room_id: string,
  environmentID: number,
  environment: EnvironmentRequestModel
): Promise<AxiosResponse<boolean>> => {
  return api.put(
    `/homes/${getHomeIDSelect()}/rooms/${room_id}/environments/${environmentID}`,
    environment,
    {
      headers: authHeader(),
    }
  );
};

export const listEnvironmentsDevices = (
  room_id: string,
  environment_id: number
): Promise<AxiosResponse<EnvironmentModel>> => {
  return api.get(
    `/homes/${getHomeIDSelect()}/rooms/${room_id}/environments/${environment_id}`,
    {
      headers: authHeader(),
    }
  );
};

export const ListDevicesNotAssociate = (
  room_id: string,
  environment_id: number
): Promise<AxiosResponse<DeviceModel[]>> => {
  return api.get(
    `/homes/${getHomeIDSelect()}/rooms/${room_id}/environment/${environment_id}/new-devices/`,
    {
      headers: authHeader(),
    }
  );
};

export const associateDevicesEnvironment = (
  room_id: string,
  environment_id: number,
  environment: EnvironmentRequestModel
): Promise<AxiosResponse<boolean>> => {
  return api.put(
    `/homes/${getHomeIDSelect()}/rooms/${room_id}/environments/${environment_id}`,
    environment,
    {
      headers: authHeader(),
    }
  );
};
