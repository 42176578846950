import { makeStyles } from "@material-ui/core";
import { FunctionComponent } from "react";
import DeviceModel, { DeviceFunctionsModel } from "../../../Models/DeviceModel";
import StatusFunction from "../../../Models/StatusFunctionModel";
import CustomBlockResource from "../../CustomBlockResource";
import CustomOfflineDeviceComponent from "../../CustomOfflineDeviceComponent";

interface CustomComponentProps {
  device: DeviceModel;
  status: StatusFunction[];
  specificationsDevice: DeviceFunctionsModel;
}

interface Props {
  device: DeviceModel;
  CustomComponent: FunctionComponent<CustomComponentProps>;
  specificationsDevice: DeviceFunctionsModel;
}

const DeviceTabContent: React.FunctionComponent<Props> = ({
  device,
  CustomComponent,
  specificationsDevice,
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.deviceTabContent}>
      {device.block && (
        <CustomBlockResource
          icon={"icon_padlock"}
          title={"Dispositivo bloqueado"}
          direction={"column"}
          subtitle={
            "Você atingiu o limite de dispositivos do seu plano. Exclua dispositivos ou contrate um novo plano"
          }
        />
      )}

      {!device.online ? (
        <CustomOfflineDeviceComponent />
      ) : (
        <CustomComponent
          status={device.status}
          device={device}
          specificationsDevice={specificationsDevice}
        />
      )}
    </div>
  );
};

export default DeviceTabContent;

const useStyles = makeStyles(() => ({
  deviceTabContent: {
    position: "relative",
    minHeight: "100%",
    height: "100%",
  },
}));
