import React, { MouseEventHandler } from "react";
import { Button, ButtonProps, CircularProgress } from "@material-ui/core";

type ButtonWithLoadingProps = {
  buttonProps?: ButtonProps;
  loading: boolean;
  text: string | React.ReactNode;
  textLoading?: string | React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
};

const ButtonWithLoading: React.FunctionComponent<ButtonWithLoadingProps> = (
  props: ButtonWithLoadingProps
) => {
  return (
    <Button
      color={"secondary"}
      variant={"contained"}
      disabled={props.disabled || props.loading}
      onClick={props.onClick}
      {...props.buttonProps}
      style={{
        minWidth: "215px",
        height: "55px",
        textTransform: "capitalize",
        fontWeight: 700,
        fontSize: "15px",
        lineHeight: "20px",
      }}
    >
      {props.loading && (
        <CircularProgress
          size={18}
          style={{ marginRight: 10, color: "inherit" }}
        />
      )}{" "}
      {props.loading && props.textLoading ? props.textLoading : props.text}
    </Button>
  );
};

ButtonWithLoading.defaultProps = {
  disabled: false,
  buttonProps: {},
};

export default ButtonWithLoading;
