import styled from "styled-components";

export const PasswordSlotsContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-content: center;

  padding: 10px 0;
`;

export const PasswordSlots = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 1rem;
  color: #3e5055;
  text-align: center;

  background-color: #ebeeee;
  border-radius: 5px;
  width: 2.9rem;
  height: 2.9rem;

  &.hide span {
    margin-bottom: -10px; // Necessário para ajustar a posição do * (asterisco)
  }

  & + & {
    margin-left: 10px;
  }
`;
