import { createStyles, makeStyles } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import CustomSearch from "../CustomSearch";

const useStyles = makeStyles(() =>
  createStyles({
    customToolbar: {
      backgroundColor: "#FFF",
      height: "75px",
      padding: "0px 3rem",
    },
  })
);

const CustomGroupsOperatorsHeadBar: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Toolbar className={classes.customToolbar} variant="dense">
      <CustomSearch />
    </Toolbar>
  );
};

export default CustomGroupsOperatorsHeadBar;
