interface DateValue {
  initial: string;
  value: string;
}

export const days: DateValue[] = [
  { initial: "D", value: "Dom" },
  { initial: "S", value: "Seg" },
  { initial: "T", value: "Ter" },
  { initial: "Q", value: "Qua" },
  { initial: "Q", value: "Qui" },
  { initial: "S", value: "Sex" },
  { initial: "S", value: "Sab" },
];
