import { createStyles, makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import DrawerProps from "../../../../Types/DrawerProps";
import { useContext, useEffect, useState } from "react";
import ManagePlaceContext from "../../Context/ManagePlaceContext";
import { Button, Divider, IconButton, TextField } from "@material-ui/core";

const anchor: DrawerProps = "right";

const CustomDrawerCreatePlace: React.FunctionComponent = () => {
  const classes = useStyles();

  const {
    openPlaceDrawer,
    isToEditPlace,
    placeToEdit,
    isSavingPlace,
    togglePlaceDrawer,
    savePlace,
  } = useContext(ManagePlaceContext);

  const [placeName, setPlaceName] = useState<string>("");

  useEffect(() => {
    if (openPlaceDrawer) {
      setPlaceName("");

      if (isToEditPlace && placeToEdit) {
        setPlaceName(placeToEdit.name);
      }
    }
  }, [openPlaceDrawer]);

  return (
    <Drawer
      anchor={anchor}
      open={openPlaceDrawer}
      onClose={() => {
        togglePlaceDrawer(false);
      }}
    >
      <div className={classes.container}>
        <div
          className={classes.btnBackContainer}
          onClick={() => {
            togglePlaceDrawer(false);
          }}
        >
          <IconButton
            aria-label="delete"
            size="small"
            style={{
              fontSize: "15px",
              color: "#8B979F",
            }}
          >
            <img src={"assets/icons/icon_back.svg"} />
          </IconButton>
          <p
            style={{
              margin: "0px",
              fontSize: "15px",
              color: "#8B979F",
            }}
          >
            Voltar
          </p>
        </div>
        <Divider />
        <div className={classes.contentContainer}>
          <div className={classes.contentTextLabel}>
            Crie um local e depois atribua espaços a ele, posteriormente você
            pode criar ambientes para inserir e dividir os dispositivos
          </div>
          <div className={classes.contentTitleLabel}>Nome do local</div>
          <div className={classes.containerInput}>
            <TextField
              label="Local"
              id="create-place-name"
              variant="outlined"
              fullWidth
              required
              InputProps={{ className: classes.inputPlace }}
              inputProps={{ maxLength: 50 }}
              value={placeName}
              onChange={(e) => {
                setPlaceName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className={classes.btnContainer}>
          <Button
            className={classes.btnCancel}
            variant="outlined"
            onClick={() => {
              togglePlaceDrawer(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            className={classes.btnConfirm}
            variant="contained"
            disabled={isSavingPlace}
            onClick={() => savePlace(placeName)}
          >
            Salvar
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default CustomDrawerCreatePlace;

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: "700px",
      backgroundColor: "#FFF",
      height: "100%",
      display: "flex",
      flexFlow: "column",
      overflow: "auto",
    },
    btnBackContainer: {
      display: "flex",
      padding: "25px",
      justifyContent: "start",
      alignItems: "center",
      cursor: "pointer",
    },
    contentContainer: {
      padding: "0px 50px",
      flex: "1 1 auto",
    },
    contentTextLabel: {
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "20px",
      color: "#8B979F",
      marginTop: "30px",
      marginBottom: "15px",
    },
    contentTitleLabel: {
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "25px",
      color: "#3E5055",
      marginBottom: "10px",
    },
    containerInput: {
      marginBottom: "10px",
    },
    inputPlace: {
      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#6DCC9A",
      },
      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#00A335",
      },
    },
    btnContainer: {
      height: "75px",
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
    },
    btnConfirm: {
      height: "50px",
      width: "150px",
      backgroundColor: "#00A335",
      color: "#FFF",
      marginRight: "25px",
      textTransform: "capitalize",
      fontWeight: 700,
      fontSize: "15px",
      lineHeight: "20px",
      "&:hover": {
        backgroundColor: "#00A335",
      },
    },
    btnCancel: {
      height: "50px",
      width: "130px",
      marginRight: "15px",
      textTransform: "capitalize",
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "20px",
      color: "#8B979F",
    },
  })
);
