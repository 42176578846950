import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import OperatorsGroupCreatePageContext from "../../Context/OperatorsGroupCreatePageContext";
import CustomAccessControlModal from "../CustomAcessControlModal";
import {
  Button,
  Container,
  Divider,
  TextField,
  FormGroup,
  CircularProgress,
} from "@material-ui/core";
import UsersGroup from "../UsersGroup";
import CustomAutomationSelect from "../CustomAutomationSelect";
import CustomInfo from "../CustomInfo";
import RoomsAndPlaces from "../RoomsAndPlaces";
import { FormOperatorsGroupType } from "../../../../Models/UserGroupsModel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    paddingPapper: {
      minHeight: "70vh",
      marginTop: "30px",
      marginBottom: "10vh",
    },
    containerInput: {
      display: "flex",
      flexDirection: "column",
      padding: "30px 90px 10px",
      justifyContent: "space-between",
      marginBottom: "50px",
    },
    containerListGroups: {},
    emptyDiv: {
      textAlign: "center",
    },
    emptyImage: {
      opacity: 0.3,
      height: "27vh",
    },
  })
);

export type SuccessFunc = (duplicate: boolean) => void;
export type SubmitFunc = (data: FormOperatorsGroupType) => Promise<boolean>;

type FormOperatorsGroupProps = {
  onSubmit: SubmitFunc;
  onSuccess: SuccessFunc;
  onCancel: () => void;
  initialValue: FormOperatorsGroupType;
};

const FormOperatorsGroup = forwardRef((props: FormOperatorsGroupProps, ref) => {
  const classes = useStyles();

  const {
    isEdit,
    setNameOperatorsGroupCreate,
    nameOperatorsGroupCreate,
    saveOperatorsGroup,
    resetFormCreateGroup,
    setInitialValue,
    isSubmit,
  } = useContext(OperatorsGroupCreatePageContext);

  useEffect(() => {
    setInitialValue(props.initialValue);
  }, []);

  const inputGroupNameRef = useRef<HTMLInputElement | null>(null);

  useImperativeHandle(ref, () => ({
    reset: resetFormCreateGroup,
    resetName: () => setNameOperatorsGroupCreate(""),
    nameGroupFocus: () => {
      inputGroupNameRef?.current?.focus();
    },
  }));

  return (
    <Container maxWidth="lg">
      <div className={classes.paddingPapper}>
        <div className={classes.containerInput}>
          <div>
            <FormGroup style={{ width: "50%" }}>
              <label htmlFor="operators-name">
                <strong>Insira o nome do grupo</strong>
              </label>
              <TextField
                inputRef={inputGroupNameRef}
                label="Nome do grupo"
                id="operators-name"
                variant="filled"
                required
                inputProps={{ maxLength: 50 }}
                value={nameOperatorsGroupCreate}
                onChange={(e: any) => {
                  setNameOperatorsGroupCreate(e.target.value);
                }}
              />
            </FormGroup>
          </div>
        </div>
        <Divider />

        <div className={classes.containerInput}>
          <CustomInfo
            text={"Selecione os usuários do grupo"}
            number={1}
            subtext={
              "Selecione os usuários que terão acesso aos espaços e automações que serão definidos"
            }
          />
          <UsersGroup />
        </div>
        <Divider />

        <div className={classes.containerInput}>
          <CustomInfo
            text={"Selecione os espaços que os usuários devem ter acesso"}
            subtext={
              "Pode selecionar um local todo ou os espaços dentro deles, é possível selecionar espaços de diversos locais"
            }
            number={2}
          />
          <RoomsAndPlaces />
        </div>
        <Divider />
        <div className={classes.containerInput}>
          <CustomInfo
            text={"Indique as automações que os usuário do grupo tem acesso"}
            subtext={
              "É possivel definir quais são as automações que os usuários terão acesso"
            }
            number={3}
          />
          <CustomAutomationSelect />
        </div>
        <Divider />
        <div
          style={{
            bottom: "0",
            display: "flex",
            flexDirection: "row-reverse",
            padding: "40px",
          }}
        >
          <Button
            id="btn-save-operators-group"
            variant="contained"
            color="secondary"
            style={{
              display: "flex",
              flexDirection: "row",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "30px",
              textTransform: "none",
              padding: "0px 60px",
            }}
            onClick={() =>
              saveOperatorsGroup(props.onSubmit, () => props.onSuccess(false))
            }
            disabled={isSubmit}
          >
            Salvar{" "}
            {isSubmit && (
              <CircularProgress
                style={{ marginLeft: "10px" }}
                color="secondary"
                size={25}
              />
            )}
          </Button>
          {!isEdit && (
            <Button
              id="btn-save-duplicate-operators-group"
              variant="text"
              style={{
                color: "#8B979F",
                border: "1px solid #D1DAE0",
                marginLeft: "30px",
                textTransform: "none",
              }}
              onClick={() =>
                saveOperatorsGroup(props.onSubmit, () => props.onSuccess(true))
              }
              disabled={isSubmit}
            >
              Salvar e Duplicar
            </Button>
          )}

          <Button
            id="btn-cancel-operators-group"
            variant="text"
            style={{ color: "#8B979F", textTransform: "none" }}
            onClick={() => {
              props.onCancel();
            }}
            disabled={isSubmit}
          >
            Cancelar
          </Button>
        </div>
      </div>
      <CustomAccessControlModal />
    </Container>
  );
});
FormOperatorsGroup.displayName = "FormOperatorsGroup";
export default FormOperatorsGroup;
