import { makeStyles, createStyles } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";

interface Props {
  text: string;
  icon: string;
  checked: boolean;
  changeStatus: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    shortcut: {
      width: "100%",
      height: "76px",
      background: "#FFFFFF",
      border: "1px solid #D1DAE0",
      borderRadius: "5px",
      marginBottom: "10px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 30px",
    },
    text: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "20px",
      color: "#3E5055",
      marginLeft: "15px",
    },
  })
);

const ShortcutOption: React.FunctionComponent<Props> = ({
  icon,
  text,
  checked,
  changeStatus,
}: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.shortcut}>
      <div>
        <img src={`assets/icons/${icon}`} alt="Ícone de talhao de painel" />
        <span className={classes.text}>{text}</span>
      </div>
      <div>
        <Switch checked={checked} onChange={changeStatus} color="secondary" />
      </div>
    </div>
  );
};

export default ShortcutOption;
