import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  ListItem,
  ListItemText,
  Collapse,
  Button,
  ListItemAvatar,
  Avatar,
  ListItemIcon,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import OperatorsGroupCreatePageContext from "../../Context/OperatorsGroupCreatePageContext";
import PlaceModel from "../../../../Models/PlaceModel";
import RoomModel from "../../../../Models/RoomModel";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import { cloneDeep, debounce } from "lodash";
import InputSearch from "../InputSearch";
import EmptyState from "../EmptyState";

const useStyles = makeStyles(() => ({
  header: {
    margin: "12px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "end",

    "& .MuiFormControlLabel-label": {
      fontWeight: 600,
      fontSize: "15px",
    },

    "& .MuiCheckbox-root ": {
      padding: "0px 20px",
    },
  },
  containerSelectGroups: {
    maxHeight: "400px",
    overflowY: "auto",
    width: "100%",
    "& .MuiCheckbox-colorSecondary:not(.Mui-checked)": {
      color: "#8B979F",
    },
  },
}));

const RoomssAndPlaces: React.FunctionComponent = () => {
  const {
    handleOpenAccessModalControl,
    isCheckGrupo,
    isCheckAllPlaces,
    setIsCheckAllPlaces,
    setIsCheckGrupo,
    isCheckRoom,
    setIsCheckRoom,
  } = useContext(OperatorsGroupCreatePageContext);

  const { places } = useContext(GlobalContex);

  const classes = useStyles();

  const [allPlaces, setAllPlaces] = useState<PlaceModel[]>([]);
  const [search, setSearch] = useState<string>("");
  const [filteredPlaces, setFilteredPlaces] = useState<PlaceModel[]>([
    ...allPlaces,
  ]);
  const [openMenuGrupoId, setOpenMenuGrupoId] = useState<string>();
  const [expandedAll, setExpandedAll] = useState(false);

  const openMenuGrupo = (id: string) => {
    setExpandedAll(false);
    if (id == openMenuGrupoId) setOpenMenuGrupoId("");
    else setOpenMenuGrupoId(id);
  };

  const handleClick = (e: ChangeEvent<HTMLInputElement>, place: PlaceModel) => {
    const { checked } = e.target;

    if (checked) {
      setOpenMenuGrupoId(place.place_id);
    }

    setIsCheckGrupo([...isCheckGrupo, place.place_id]);

    if (!checked) {
      place.rooms.forEach(function (room) {
        for (let i = 0; i < isCheckRoom.length; i++) {
          if (isCheckRoom[i] == room.room_id) {
            setIsCheckRoom(isCheckRoom.splice(i, 1));
          }
        }
      });

      setIsCheckRoom([...isCheckRoom]);

      setIsCheckGrupo(isCheckGrupo.filter((item) => item !== place.place_id));
    } else {
      place.rooms.forEach(function (item) {
        if (!isCheckRoom.includes(item.room_id)) {
          isCheckRoom.push(item.room_id);
        }
      });
    }
  };

  const handleClickRoom = (
    e: ChangeEvent<HTMLInputElement>,
    room: RoomModel
  ) => {
    e.target.disabled = true; // previne o duplo click
    const { checked } = e.target;
    const { room_id } = room;
    let checkedRooms = [...isCheckRoom];

    if (checked) {
      checkedRooms = [...checkedRooms, room_id];
    } else {
      checkedRooms = checkedRooms.filter((roomId) => roomId !== room_id);
    }
    setIsCheckRoom(checkedRooms);

    // atualiza checkbox do local do espaço em questão caso estejam todos os demais espaços marcados
    const place = allPlaces.find((place) =>
      place.rooms.some((room) => room.room_id === room_id)
    );
    if (place) {
      const isPlaceFullRoomsChecked = place?.rooms.every((room) =>
        checkedRooms.includes(room.room_id)
      );
      if (isPlaceFullRoomsChecked) {
        setIsCheckGrupo([...isCheckGrupo, place.place_id]);
      } else {
        setIsCheckGrupo(
          isCheckGrupo.filter((placeId) => placeId !== place.place_id)
        );
      }
    }
    e.target.disabled = false; // habilita novamente o checkbox
  };

  const handleSelectAll = () => {
    const isChecked = !isCheckAllPlaces;
    setIsCheckAllPlaces(isChecked);

    if (!isChecked) {
      setIsCheckGrupo([]);
      setIsCheckRoom([]);
      return;
    }

    allPlaces.forEach((place) => {
      isCheckGrupo.push(place.place_id);
      place.rooms.forEach(function (room) {
        isCheckRoom.push(room.room_id);
      });
    });
  };

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    return setSearch(event.target.value);
  };

  useEffect(() => {
    setAllPlaces(places);
  }, [places]);

  useEffect(() => {
    searchTerm(search, allPlaces);
    setExpandedAll(search.trim() != "");
  }, [allPlaces, search]);

  const searchTerm = useCallback(
    debounce((search, allPlaces) => {
      const clonedPlaces = cloneDeep(allPlaces);
      let filteredPlaces = clonedPlaces;
      if (search.trim() != "") {
        const term = search.toUpperCase();
        filteredPlaces = clonedPlaces.filter((place: PlaceModel) => {
          const roomsFiltered = place.rooms?.filter((room: RoomModel) =>
            room.name.toUpperCase().includes(term)
          );
          place.rooms = roomsFiltered;
          return (
            place.name.toUpperCase().includes(term) || roomsFiltered.length > 0
          );
        });
      }
      setFilteredPlaces(filteredPlaces);
    }, 300),
    []
  );

  const isResultSearchEmpty = useCallback(() => {
    return search.trim() != "" && filteredPlaces.length === 0;
  }, [search, filteredPlaces]);

  return (
    <>
      <div className={classes.header}>
        <FormControlLabel
          control={
            <Checkbox
              key={"selectAllPlaces"}
              checked={isCheckAllPlaces}
              onChange={handleSelectAll}
              name="checkedAll"
              color="secondary"
            />
          }
          label="Selecionar tudo"
        />

        <InputSearch
          id="filterAutomation"
          onChange={handleChangeSearch}
          placeholder="Pesquisar ambiente"
        ></InputSearch>
      </div>

      <Divider style={{ marginBottom: 16 }} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div className={classes.containerSelectGroups}>
          <Grid container spacing={0} style={{ border: "1px solid #D1DAE0" }}>
            {filteredPlaces.map((place: PlaceModel, i: number) => {
              return (
                <Grid item xs={12} key={"place-" + i}>
                  <Paper
                    elevation={0}
                    style={{
                      height: "100%",
                    }}
                  >
                    <ListItem
                      color={"secondary"}
                      style={{
                        paddingLeft: "12px",
                        backgroundColor: i % 2 ? "#F2F2F2" : "#FFF",
                      }}
                    >
                      <Checkbox
                        key={`${place.place_id}-selected`}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          handleClick(event, place);
                        }}
                        name={`${place.place_id}-user`}
                        color="secondary"
                        checked={isCheckGrupo.includes(place.place_id)}
                      />
                      <ListItem
                        button
                        key="groups"
                        onClick={() => {
                          openMenuGrupo(place.place_id);
                        }}
                        style={{
                          paddingLeft: "10px",
                        }}
                      >
                        <ListItemText primary={place.name} />
                        <ListItemIcon style={{ paddingLeft: "5px" }}>
                          <ExpandMoreIcon />
                        </ListItemIcon>
                      </ListItem>
                    </ListItem>

                    <Collapse
                      key={`${place.place_id}-selected`}
                      in={openMenuGrupoId == place.place_id || expandedAll}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Divider />
                      {place.rooms.map((item, indexRoom) => (
                        <ListItem
                          key={"room-" + indexRoom}
                          style={{
                            paddingLeft: "12px",
                            borderBottom: "1px solid #f2f2f2",
                          }}
                        >
                          <Checkbox
                            key={`${item.room_id}-selected`}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) => {
                              handleClickRoom(event, item);
                            }}
                            name={`${item.room_id}-user`}
                            color="secondary"
                            checked={isCheckRoom.includes(item.room_id)}
                          />
                          <ListItemAvatar
                            style={{
                              marginLeft: "25px",
                            }}
                          >
                            <Avatar src={item.image} />
                          </ListItemAvatar>
                          <ListItemText primary={item.name} />
                          <div
                            style={{
                              marginLeft: "30px",
                              textAlign: "end",
                            }}
                          >
                            <Button
                              id={`${item.room_id}-conf`}
                              disabled={!isCheckRoom.includes(item.room_id)}
                              onClick={() => {
                                handleOpenAccessModalControl(item.room_id);
                              }}
                              startIcon={
                                !isCheckRoom.includes(item.room_id) ? (
                                  <img
                                    width={25}
                                    src="assets/icons/conf2.svg"
                                  />
                                ) : (
                                  <img width={25} src="assets/icons/conf.svg" />
                                )
                              }
                              color="secondary"
                              style={{
                                textTransform: "none",
                              }}
                            >
                              Conf
                            </Button>
                          </div>
                        </ListItem>
                      ))}

                      {place.rooms.length === 0 && search.trim() === "" && (
                        <p
                          style={{
                            textAlign: "center",
                            padding: "20px 10px",
                            color: "#8B979F",
                          }}
                        >
                          <em>Nenhum espaço neste local.</em>
                        </p>
                      )}
                    </Collapse>
                  </Paper>
                </Grid>
              );
            })}

            {(allPlaces.length === 0 || isResultSearchEmpty()) && (
              <EmptyState
                msg={`Nenhum espaço ou local ${
                  allPlaces.length === 0 ? "cadastrado" : "encontrado"
                }`}
              />
            )}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default RoomssAndPlaces;
