import { Button, FormControl } from "@material-ui/core";

interface Props {
  command: any;
  value: any;
  onSubmit: any;
}

const StringInput: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <FormControl variant="outlined">
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          props.onSubmit(props.command.values);
        }}
      >
        {props.command.code}
      </Button>
    </FormControl>
  );
};

export default StringInput;
