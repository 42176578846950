import { Popover } from "@material-ui/core";
import {
  Body,
  BodyContent,
  BodyInfos,
  BodyTitle,
  BoxStyled,
  BtnSendHelp,
  ContainerFooterButton,
  Footer,
  FooterButtonContent,
  FooterTitle,
  Header,
  SubtitleSendHelp,
} from "./styled";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import EmailIcon from "@material-ui/icons/Email";
import SendIcon from "@material-ui/icons/Send";

interface HelpCenterProps {
  open: boolean;
  anchor: HTMLDivElement | null;
  onClose: () => void;
}

const HelpCenter: React.FunctionComponent<HelpCenterProps> = ({
  open,
  anchor,
  onClose,
}: HelpCenterProps) => {
  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <BoxStyled p={0}>
        <Header>Centro de ajuda</Header>
        <Body>
          <BodyTitle>Olá,</BodyTitle>
          <BodyContent>
            Para que possamos auxiliar de maneira adequada, solicitamos que
            entre em contato com nossos canais abaixo, nossa equipe estará
            pronta para atendê-lo. 😊
          </BodyContent>
          <BodyInfos>
            <WhatsAppIcon
              fontSize="medium"
              color="primary"
              style={{ marginRight: "10px" }}
            />{" "}
            (48)-2106-0006
          </BodyInfos>
          <BodyInfos
            style={{
              marginBottom: "0px",
            }}
          >
            <EmailIcon
              fontSize="medium"
              color="primary"
              style={{ marginRight: "10px" }}
            />{" "}
            suporte@intelbras.com.br
          </BodyInfos>
        </Body>
        <Footer>
          <FooterTitle>Iniciar uma conversa</FooterTitle>
          <ContainerFooterButton>
            <FooterButtonContent>
              <BtnSendHelp
                href="https://chat.intelbras.com.br/"
                target="_blank"
                rel="noreferrer"
              >
                <SendIcon
                  fontSize="small"
                  color="primary"
                  style={{ marginRight: "10px" }}
                />{" "}
                Enviar mensagem
              </BtnSendHelp>
              <SubtitleSendHelp>
                Tempo médio de resposta 15 min
              </SubtitleSendHelp>
            </FooterButtonContent>
            <div>
              <img src="assets/icons/SmartER v2/AvatarHelpIzi.png" alt="" />
            </div>
          </ContainerFooterButton>
        </Footer>
      </BoxStyled>
    </Popover>
  );
};

export default HelpCenter;
