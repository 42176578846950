import { useContext, useState } from "react";
import { IconButton, ListItem } from "@material-ui/core";
import CustomItemDevice from "../CustomItemDevice";
import HomePageContext from "../../Pages/HomePage/Context/HomePageContext";
import Grid from "@material-ui/core/Grid";
import EnvironmentModel from "../../Models/EnvironmentModel";
import { styled, alpha } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import GlobalContex from "../../GlobalContext/GlobalContext";
import EmptyState from "../EmptyState";
import CustomTab, { CustomTabPanel } from "../CustomTab";
import CustomRelocateModal from "../CustomRelocateModal";
import DeviceModel from "../../Models/DeviceModel";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: "5px 15px",
    borderRadius: 6,
    borderColor: "#aaa",
    marginTop: "35px",
    minWidth: 250,
    maxHeight: 400,
    overflow: "auto",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
}));

const CustomContainerDevices: React.FunctionComponent = () => {
  const {
    environments,
    updateRelocateDevices,
    statusDevicesShortcut,
    setDashboardTabValue,
  } = useContext(HomePageContext);

  const { devices } = useContext(GlobalContex);

  const allDevicesTab: EnvironmentModel[] = [
    {
      id: 0,
      name: "Dispositivos do espaço",
      devices: devices,
    },
  ];

  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuClick = (num: number) => {
    setValueTab(num);
    handleClose();
  };

  const [valueTab, setValueTab] = useState<number>(0);

  const handleChangeTab = (newValue: number) => {
    setValueTab(newValue);
  };

  const confirmAssociation = () => {
    return;
  };

  return (
    <div
      style={{
        height: "100%",
        maxHeight: statusDevicesShortcut ? "470px" : "690px",
        maxWidth: "90vw",
      }}
    >
      <CustomTab
        backgroundColor={"#EBEEEE"}
        roundedTop="25px"
        labels={allDevicesTab
          .concat(environments)
          .map((environment: EnvironmentModel) => environment.name)}
        ariaLabel={"menu em tabs para a página de dashboard"}
        id="tab-dashboard"
        value={valueTab}
        key={"tab-dashboard"}
        changeTab={(valueTab: number) => handleChangeTab(valueTab)}
      >
        <IconButton id={"demo-customized-button"} onClick={handleClick}>
          <div
            style={{
              height: "25px",
              width: "25px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "3px",
              backgroundColor: !open ? "" : "#D1DAE0",
            }}
          >
            <img
              src={"assets/icons/container-devices-dropdown-menu-icon.svg"}
            />
          </div>
        </IconButton>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <div
            style={{
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "18px",
              color: "#3E5055",
              margin: "10px 0px",
            }}
          >
            Todos os ambientes:
          </div>
          <div style={{ maxHeight: "250px", overflow: "auto" }}>
            {allDevicesTab
              .concat(environments)
              .map((environment: EnvironmentModel, i: number) => (
                <MenuItem
                  key={i}
                  onClick={() => {
                    menuClick(i);
                  }}
                  disableRipple
                  style={{ padding: "5px 0px" }}
                >
                  <ListItem
                    button
                    style={{
                      fontWeight: 400,
                      fontSize: "15px",
                      lineHeight: "20px",
                      backgroundColor: "#EBEEEE",
                      color: "#8B979F",
                      borderRadius: "5px",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {environment.name}
                  </ListItem>
                </MenuItem>
              ))}
          </div>
          <div
            onClick={() => {
              setDashboardTabValue(1);
            }}
            style={{
              fontWeight: 600,
              fontSize: "13px",
              lineHeight: "18px",
              color: "#00A335",
              marginLeft: "-10px",
              cursor: "pointer",
            }}
          >
            <IconButton>
              <img src="assets/icons/menu-icon-button-devices.svg" />
            </IconButton>
            Ver dispositivos
          </div>
        </StyledMenu>
      </CustomTab>
      {allDevicesTab
        .concat(environments)
        .map((environment: EnvironmentModel, i: number) => {
          return (
            <CustomTabPanel
              key={i}
              value={valueTab}
              index={i}
              backgroundColor={"#EBEEEE"}
              roundedBottom="25px"
              height={"calc(100% - 48px)"}
            >
              {environment.devices?.length ? (
                <Grid
                  container
                  style={{
                    overflow: "auto",
                    maxHeight: statusDevicesShortcut ? "400px" : "615px",
                  }}
                >
                  {devices.map((device: DeviceModel, i: number) => {
                    const index = environment?.devices?.findIndex(
                      (dev: DeviceModel) => {
                        return dev.id === device.id;
                      }
                    );
                    if (index !== undefined && index > -1) {
                      return (
                        <CustomItemDevice
                          device={device}
                          key={"device-item-" + i}
                        />
                      );
                    }
                  })}
                </Grid>
              ) : (
                <div style={{ height: "100%", overflow: "auto" }}>
                  <EmptyState
                    title={"Nenhum dispositivo encontrado"}
                    subtitle="Verifique se existem dispositivos cadastrados e estão sincronizados através do App Izy Smart, se não, contate um administrador."
                    image={"assets/imgs/empty-state-imgs/empty-state-img-1.png"}
                  />
                </div>
              )}
            </CustomTabPanel>
          );
        })}
      <CustomRelocateModal
        updateRelocateDevicesFunction={updateRelocateDevices}
        confirmRelocateDevicesFunction={confirmAssociation}
      />
    </div>
  );
};

export default CustomContainerDevices;
