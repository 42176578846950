import Grid from "@material-ui/core/Grid";
import { Automations } from "../../../../../Models/Automation";
import AutomationsItem from "../AutomationsItem";

interface Props {
  col: Automations[];
}

const AutomationsColumns: React.FunctionComponent<Props> = ({ col }: Props) => {
  return (
    <Grid
      item
      xs={4}
      container
      spacing={1}
      alignContent={"flex-start"}
      style={{ height: "100%" }}
    >
      {col &&
        col.map((automation, index) => (
          <AutomationsItem
            automation={automation}
            key={"automation-column-item-" + index}
          />
        ))}
    </Grid>
  );
};

export default AutomationsColumns;
