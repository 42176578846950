import { useEffect, useState } from "react";
import { makeStyles, createStyles, Grid, Slider } from "@material-ui/core";
import React, { useContext } from "react";
import GlobalContex from "../../../../../../GlobalContext/GlobalContext";
import DeviceModel from "../../../../../../Models/DeviceModel";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      padding: "60px",
      alignItems: "center",
    },
    button: {
      flexGrow: 1,
      padding: 10,
    },
    title: {
      fontSize: "23px",
      color: "#3E5055",
      fontWeight: 600,
      marginBottom: "0px",
    },
    subtitle: {
      fontSize: "15px",
      color: "#8B979F",
    },
    buttons: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    buttonColor: {
      borderRadius: "0px 25% 25% 0%",
    },
    percent: {
      color: "#8B979F",
      fontSize: "15px",
    },
    optionTitle: {
      color: "#3E5055",
      fontSize: "15px",
      fontWeight: 600,
    },
    slider: {},
    onOff: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    textButtonOnOF: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#8B979F",
      fontSize: "23px",
    },
    colors: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "30px",
      marginBottom: "17px",
    },
    pill: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "20px",
      height: "40px",
      borderRadius: "55px",
      backgroundColor: "red",
      margin: "10px",
      cursor: "pointer",
    },
    pointer: {
      cursor: "pointer",
    },
  })
);

interface Status {
  code: string;
  value: any;
}

interface Props {
  device: DeviceModel;
  status: Status[];
}

const EWS409_WHITE: React.FunctionComponent<Props> = ({
  device,
  status,
}: Props) => {
  const { handleChangeCommand } = useContext(GlobalContex);

  const classes = useStyles();

  const toggleDeviceStatus = () => {
    handleChangeCommand(!status[0].value, device.id, status[0].code);
  };

  const [valueBright, setValueBright] = React.useState<number>(
    status[2].value / 10
  );

  const bright_value_v2: Status = status.find(
    (s: Status) => s.code === "bright_value_v2"
  ) || { code: "", value: "" };

  const temp_value_v2: Status = status.find(
    (s: Status) => s.code === "temp_value_v2"
  ) || { code: "", value: "" };

  const value: number = JSON.parse(bright_value_v2.value);
  const temperatureValue: number = JSON.parse(temp_value_v2.value);

  const [changedValueBright, setChangedValueBright] = useState<number>();

  useEffect(() => {
    setValueBright(Math.round(value / 10));
    if (valueBright === changedValueBright) setChangedValueBright(undefined);
  }, [value]);

  const handleBrightChange = (event: any, newValue: number | number[]) => {
    const value: number = (newValue as number) * 10;
    handleChangeCommand(value, device.id, status[2].code);
    setValueBright(newValue as number);
  };

  const changeColor = (value: number) => {
    handleChangeCommand(value, device.id, status[3].code);
  };

  return (
    <div
      style={{
        width: "100%",
        marginTop: !status[0].value ? "110px" : "0px",
      }}
    >
      {status[0].value && (
        <div className={classes.colors}>
          <div
            className={classes.pill}
            onClick={() => changeColor(100)}
            style={{
              backgroundColor: "#FCA025",
              top: "178px",
              right: "-70px",
              position: "relative",
              transform: "rotate(-94deg)",
              border: temperatureValue === 100 ? "4px solid green" : "",
            }}
          ></div>
          <div
            className={classes.pill}
            onClick={() => changeColor(200)}
            style={{
              backgroundColor: "#FDCF17",
              top: "128px",
              right: "-20px",
              position: "relative",
              transform: "rotate(-84deg)",
              border: temperatureValue === 200 ? "4px solid green" : "",
            }}
          ></div>
          <div
            className={classes.pill}
            onClick={() => changeColor(300)}
            style={{
              backgroundColor: "#F5EA4F",
              top: "78px",
              right: "10px",
              position: "relative",
              transform: "rotate(-64deg)",
              border: temperatureValue === 300 ? "4px solid green" : "",
            }}
          ></div>
          <div
            className={classes.pill}
            onClick={() => changeColor(400)}
            style={{
              backgroundColor: "#FBF2A5",
              top: "35px",
              right: "20px",
              position: "relative",
              transform: "rotate(-54deg)",
              border: temperatureValue === 400 ? "4px solid green" : "",
            }}
          ></div>
          <div
            className={classes.pill}
            onClick={() => changeColor(500)}
            style={{
              backgroundColor: "#FEF9D1",
              top: "10px",
              right: "10px",
              position: "relative",
              transform: "rotate(-20deg)",
              border: temperatureValue === 500 ? "4px solid green" : "",
            }}
          ></div>
          <div
            className={classes.pill}
            onClick={() => changeColor(600)}
            style={{
              backgroundColor: "#FFFFFF",
              top: "10px",
              border:
                temperatureValue === 600
                  ? "4px solid green"
                  : "1px solid #D1DAE0",
              right: "-10px",
              position: "relative",
              transform: "rotate(20deg)",
            }}
          ></div>
          <div
            className={classes.pill}
            onClick={() => changeColor(700)}
            style={{
              backgroundColor: "#E8F3F7",
              top: "35px",
              right: "-20px",
              position: "relative",
              border: temperatureValue === 700 ? "4px solid green" : "",
              transform: "rotate(54deg)",
            }}
          ></div>
          <div
            className={classes.pill}
            onClick={() => changeColor(800)}
            style={{
              backgroundColor: "#CBE6F7",
              top: "78px",
              right: "-10px",
              position: "relative",
              transform: "rotate(64deg)",
              border: temperatureValue === 800 ? "4px solid green" : "",
            }}
          ></div>
          <div
            className={classes.pill}
            onClick={() => changeColor(900)}
            style={{
              backgroundColor: "#B1DFF6",
              top: "128px",
              right: "20px",
              position: "relative",
              transform: "rotate(84deg)",
              border: temperatureValue === 900 ? "4px solid green" : "",
            }}
          ></div>
          <div
            className={classes.pill}
            onClick={() => changeColor(1000)}
            style={{
              backgroundColor: "#61C9EE",
              top: "178px",
              right: "70px",
              position: "relative",
              transform: "rotate(94deg)",
              border: temperatureValue === 1000 ? "4px solid green" : "",
            }}
          ></div>
        </div>
      )}
      <p onClick={toggleDeviceStatus} className={classes.onOff}>
        {status[0].value && (
          <img
            src="assets/imgs/ewson.png"
            width={170}
            className={classes.pointer}
          />
        )}
        {!status[0].value && (
          <img
            src="assets/imgs/ewsoff.png"
            width={170}
            className={classes.pointer}
          />
        )}
      </p>
      <p className={classes.textButtonOnOF}>
        {status[0].value ? "Ligado" : "Desligado"}
      </p>
      {status[0].value && <p className={classes.optionTitle}>Intensidade</p>}
      {status[0].value && (
        <Grid
          container
          spacing={2}
          style={{
            marginBottom: "15px",
          }}
        >
          <Grid item>
            <img src="assets/icons/sun 1.svg" />
          </Grid>
          <Grid item xs>
            <Slider
              value={changedValueBright || valueBright}
              min={1}
              onChange={(evt, value) => {
                setChangedValueBright(value as number);
              }}
              onChangeCommitted={handleBrightChange}
              color="secondary"
              className={classes.slider}
            />
          </Grid>
          <Grid item className={classes.percent}>
            {changedValueBright || valueBright}%
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default EWS409_WHITE;
