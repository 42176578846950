import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
  value: number;
  valuePercent: number;
  iconLeft: string;
  message: string;
  color: string;
}

const useStyles = makeStyles(() => ({
  paragrafo: {
    margin: "0px",
    color: "#8B979F",
    fontSize: "12px",
  },
}));

const Infos: React.FunctionComponent<Props> = ({
  value,
  iconLeft,
  message,
  color,
  valuePercent,
}: Props) => {
  const classes = useStyles();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        minHeight: "100px",
        justifyContent: "space-evenly",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <img src={iconLeft} />
          <span
            style={{
              fontWeight: "bold",
              fontSize: "32px",
              color: "#3E5055",
              marginLeft: "10px",
            }}
          >
            {value}
          </span>
        </div>
        <div>
          <p className={classes.paragrafo}>{message}</p>
        </div>
      </div>
      <div>
        <CircularProgress
          variant="determinate"
          value={valuePercent}
          style={{ color: color }}
          thickness={8}
          size={39}
        />
      </div>
    </div>
  );
};

export default Infos;
