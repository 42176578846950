import { CircularProgress } from "@material-ui/core";
import { Box, Button, IconBox } from "./styles";

interface CustomIconButtonProps {
  id: string;
  icon: JSX.Element;
  children?: string;
  onClick<T>(arg?: T): void;
  disabled?: boolean;
  width: string;
  height: string;
  flexDirection?: "row" | "column";
  textFontSize?: string;
  isLoading?: boolean;
}

export const CustomIconButton: React.FunctionComponent<
  CustomIconButtonProps
> = ({
  id,
  icon,
  children,
  flexDirection,
  textFontSize,
  onClick,
  disabled = false,
  width,
  height,
  isLoading = false,
}: CustomIconButtonProps) => {
  return (
    <Box>
      {isLoading && (
        <div
          style={{
            padding: flexDirection === "row" ? "0px 10px" : "0px",
          }}
        >
          <CircularProgress color="secondary" size={30} />
        </div>
      )}

      {!isLoading && (
        <Button
          id={id}
          type="button"
          onClick={disabled ? undefined : onClick}
          disabled={disabled}
          flexDirection={flexDirection}
          textFontSize={textFontSize}
          style={{
            display: "flex",
            flex: 1,
          }}
        >
          <IconBox width={width} height={height}>
            {icon}
          </IconBox>
          <div
            style={{
              padding: flexDirection === "row" ? "0px 5px" : "0px",
            }}
          >
            {children && (
              <p
                style={{
                  marginBottom: "auto",
                  fontWeight: 600,
                  fontSize: "15px",
                  lineHeight: "20px",
                  color: "#8B979F",
                }}
              >
                {children}
              </p>
            )}
          </div>
        </Button>
      )}
    </Box>
  );
};
