import { ReactNode, useContext, useEffect, useState } from "react";
import { getHomesById } from "../../../Services/UserService";
import SelectHomePageContext from "./SelectHomePageContext";
import TokenService from "../../../Services/TokenService";
import GlobalContex from "../../../GlobalContext/GlobalContext";
import { HomeUserModel } from "../../../Models/HomeUserModel";

interface Props {
  children: ReactNode;
}

const SelectHomePageContextProvider = ({ children }: Props): JSX.Element => {
  const { showAlert } = useContext(GlobalContex);

  const [homesUser, sethomesUser] = useState<HomeUserModel[]>([]);
  const [isLoadingEnterprise, setIsLoadingEnterprise] = useState<boolean>(true);

  const getDataHomes = async () => {
    try {
      setIsLoadingEnterprise(true);
      const response = await getHomesById(TokenService.getUser().userInfo.id);
      sethomesUser(response.data);
    } catch (error) {
      showAlert("Erro ao carregar os empreendimentos!", "error");
    } finally {
      setIsLoadingEnterprise(false);
    }
  };

  useEffect(() => {
    getDataHomes();

    return () => {
      sethomesUser([]);
      setIsLoadingEnterprise(false);
    };
  }, []);

  return (
    <SelectHomePageContext.Provider
      value={{
        homesUser,
        isLoadingEnterprise,
      }}
    >
      {children}
    </SelectHomePageContext.Provider>
  );
};

export default SelectHomePageContextProvider;
