import { makeStyles, createStyles } from "@material-ui/core";
import { useContext } from "react";
import DeviceDrawerComposite from "../../../../../../Components/CustomDevices/DeviceDrawerComposite";
import GlobalContex from "../../../../../../GlobalContext/GlobalContext";
import DeviceModel from "../../../../../../Models/DeviceModel";

interface SubDeviceListItemProps {
  device: DeviceModel;
}

const SubDeviceListItem: React.FunctionComponent<SubDeviceListItemProps> = ({
  device,
}: SubDeviceListItemProps) => {
  const classes = useStyles();

  const { goForwardDrawerApplication } = useContext(GlobalContex);

  const clickItemSubDevice = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    device: DeviceModel
  ) => {
    goForwardDrawerApplication(
      <DeviceDrawerComposite status={device.status} device={device} />
    );
  };

  return (
    <div className={classes.containerCardDevice}>
      <div
        className={classes.cardDevice}
        onClick={(event) => {
          clickItemSubDevice(event, device);
        }}
      >
        <div>
          <img src={device.icon} alt={device.name + "_device"} height={60} />
        </div>
        <div className={classes.cardDeviceContainerName}>
          <h2 className={classes.cardDeviceName}>{device.name}</h2>
          {device.online ? (
            <h3 className={classes.cardDeviceStatusDeviceOn}>
              dispositivo online
            </h3>
          ) : (
            <h3 className={classes.cardDeviceStatusDeviceOff}>
              dispositivo offline
            </h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubDeviceListItem;

const useStyles = makeStyles(() =>
  createStyles({
    containerCardDevice: {
      marginBottom: "15px",
      display: "flex",
      justifyContent: "center",
    },
    cardDevice: {
      display: "flex",
      boxShadow: "1px 1px 10px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "10px",
      alignItems: "center",
      width: "95%",
      padding: "20px 25px",
      cursor: "pointer",
    },
    cardDeviceName: {
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "25px",
      color: "#3E5055",
    },
    cardDeviceStatusDeviceOn: {
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "20px",
      color: "#6DCC9A",
    },
    cardDeviceStatusDeviceOff: {
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "20px",
      color: "#EF847E",
    },
    cardDeviceContainerName: {
      marginLeft: "30px",
    },
  })
);
