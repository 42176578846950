import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import PlaceModel from "../../../../../Models/PlaceModel";
import RoomModel from "../../../../../Models/RoomModel";
import GlobalContext from "../../../../../GlobalContext/GlobalContext";
import AutomationContext from "../../../Context/AutomationPageContext";
import StarIcon from "@material-ui/icons/Star";
import { setAutomationFavorited } from "../../../../../Services/AutomationService";

const FavoritedAutomationModal: React.FunctionComponent = () => {
  const { places, showAlert } = useContext(GlobalContext);
  const {
    openModalFavoritedAutomation,
    setOpenModalFavoritedAutomation,
    automationsToEdit,
  } = useContext(AutomationContext);

  const classes = useStyles();

  const [value, setValue] = useState<string>("");
  const [valueRoom, setValueRoom] = useState<string>("");
  const [showRooms, setShowRooms] = useState<boolean>(false);
  const [rooms, setRooms] = useState<RoomModel[]>([]);
  const [favoritingAutomation, setFavoritingAutomation] =
    useState<boolean>(false);
  const [favoritedStatusRequest, setFavoritedStatusRequest] =
    useState<number>(0);

  useEffect(() => {
    setFavoritingAutomation(false);
    setFavoritedStatusRequest(0);

    return () => {
      setFavoritedStatusRequest(0);
      setFavoritingAutomation(false);
    };
  }, []);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const valueChanged: string = event.target.value as string;
    const index = places.findIndex(
      (place: PlaceModel) => place.place_id === valueChanged
    );
    setValue(valueChanged);
    if (valueChanged) {
      if (index >= 0) {
        setRooms(places[index].rooms);
        setShowRooms(true);
      }
    } else {
      setShowRooms(false);
    }
  };

  const handleChangeRoom = (event: React.ChangeEvent<{ value: unknown }>) => {
    const valueChanged: string = event.target.value as string;
    setValueRoom(valueChanged);
  };

  const handleClose = () => {
    setOpenModalFavoritedAutomation(false);
    setValue("");
    setValueRoom("");
    setShowRooms(false);
    setRooms([]);
  };

  const confirmFavorited = () => {
    handleClose();
    setFavoritedStatusRequest(0);
  };

  const setAutomationToFavorite = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setFavoritingAutomation(true);
    try {
      const response = await setAutomationFavorited(
        valueRoom,
        automationsToEdit,
        true
      );
      if (response.status === 200) {
        setFavoritedStatusRequest(200);
      }
    } catch (error) {
      showAlert(
        "Houve um problema ao criar o atalho da automação no espaço desejado!",
        "error"
      );
    } finally {
      setFavoritingAutomation(false);
    }
  };

  const form = () => {
    return (
      <>
        <form className={classes.form} onSubmit={setAutomationToFavorite}>
          <DialogTitle className={classes.title}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <StarIcon fontSize="small" style={{ marginRight: "10px" }} />
              <div>Criar atalho no Dashboard</div>
            </div>
          </DialogTitle>
          <DialogContentText className={classes.subtitle}>
            Adicione o atalho da automação dentro do dashboard de um espaço
            criado
          </DialogContentText>
          <FormControl variant="outlined" className={classes.formControl}>
            <DialogContentText>Escolher local</DialogContentText>
            <Select required fullWidth value={value} onChange={handleChange}>
              <MenuItem value="">
                <em>Selecione um local</em>
              </MenuItem>
              {places.map((place: PlaceModel) => (
                <MenuItem key={place.place_id} value={place.place_id}>
                  {place.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {showRooms && (
            <FormControl variant="outlined" className={classes.formControl}>
              <DialogContentText>Selecionar espaço</DialogContentText>
              <Select
                required
                fullWidth
                value={valueRoom}
                onChange={handleChangeRoom}
              >
                <MenuItem value="">
                  <em>Selecione um espaço</em>
                </MenuItem>

                {rooms.map((room: RoomModel) => (
                  <MenuItem key={room.room_id} value={room.room_id}>
                    {room.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <DialogActions className={classes.actions}>
            <Button
              color="secondary"
              type="submit"
              variant="contained"
              className={classes.confirmButton}
            >
              Criar atalho
              {favoritingAutomation && (
                <CircularProgress
                  size={15}
                  color="primary"
                  style={{ marginLeft: "10px" }}
                />
              )}
            </Button>
            <Button
              onClick={handleClose}
              color="secondary"
              autoFocus
              className={classes.cancelButton}
            >
              Cancelar
            </Button>
          </DialogActions>
        </form>
      </>
    );
  };

  const successForm = () => {
    return (
      <div className={classes.body}>
        <div
          style={{
            padding: "0px 20px",
          }}
        >
          <p
            className={classes.successFormTitle}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            Operação concluída
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src="assets/icons/check (2) 1.svg"
              width={100}
              className={classes.img}
              alt="icon check"
            />
          </div>
          <p
            className={classes.successText}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            O atalho foi criado no devido espaço com sucesso
          </p>
        </div>
        <Button
          color="secondary"
          variant="contained"
          className={classes.confirmButtonSuccess}
          onClick={confirmFavorited}
        >
          Confirmar
        </Button>
      </div>
    );
  };

  return (
    <Dialog
      open={openModalFavoritedAutomation}
      onClose={handleClose}
      classes={{ paper: classes.modal }}
    >
      {favoritedStatusRequest === 200 ? successForm() : form()}
    </Dialog>
  );
};

export default FavoritedAutomationModal;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
    },
    form: {
      display: "flex",
      flexDirection: "column",
    },
    cancelButton: {
      color: "#EF847E",
      textTransform: "none",
      backgroundColor: "transparent",
      padding: "10px 65px",
    },
    confirmButton: {
      color: "#FFF",
      textTransform: "none",
      backgroundColor: "#00A335",
      padding: "10px 65px",
    },
    confirmButtonSuccess: {
      color: "#FFF",
      textTransform: "none",
      backgroundColor: "#00A335",
      padding: "10px 65px",
      marginTop: "20px",
    },
    modal: {
      minWidth: "500px",
      padding: "30px",
      borderRadius: "25px",
    },
    actions: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "5px 20px",
    },
    title: {
      paddingLeft: "8px",
      fontWeight: "bold",
      fontSize: "15px",
      paddingBottom: "0px",
      paddingTop: "0px",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    successFormTitle: {
      fontSize: "25px",
      fontWeight: "bold",
      color: "#3E5055",
    },
    successText: {
      color: "#3E5055",
      marginTop: "36px",
    },
    img: {
      marginTop: "20px",
    },
    subtitle: {
      paddingLeft: "8px",
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: "18px",
      color: "#8B979F",
    },
  })
);
