import styled from "styled-components";

interface ButtonProps {
  flexDirection?: "row" | "column";
  textFontSize?: string;
}

interface IconBoxType {
  width: string;
  height: string;
}

export const Box = styled.div``;

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  flex-direction: ${(props) => props.flexDirection ?? "row"};

  border: none;
  border-radius: none;
  cursor: pointer;

  font-size: ${(props) => props.textFontSize ?? "14px"};
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;

  color: #6b7e87;
  background-color: transparent;

  &:hover div {
    filter: opacity(70%);
  }

  &:focus div svg {
    filter: brightness(80%);
  }

  &:disabled {
    cursor: auto;
  }
`;

export const IconBox = styled.div<IconBoxType>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  margin-bottom: 4px;
`;
