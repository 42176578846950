import HomePageContextProvider from "./Context/HomePageContextProvider";
import Home from "./Components/Home";
import MiniDrawer from "../../Components/CustomMiniDrawer";
import {
  getHomeNameSelect,
  getPlaceNameSelect,
  getRoomNameSelect,
} from "../../Services/UserService";
import ShortcutsDrawer from "./Components/ShortcutsDrawer";
import AutomationsFavoritedDrawer from "./Components/AutomationsFavoritedDrawer";

const HomePage: React.FunctionComponent = () => {
  return (
    <>
      <MiniDrawer
        breadcrumb={[
          { name: getHomeNameSelect(), to: "/manage-place" },
          { name: getPlaceNameSelect(), to: "/manage-room" },
          { name: getRoomNameSelect(), to: "" },
        ]}
      >
        <HomePageContextProvider>
          <Home />
          <ShortcutsDrawer />
          <AutomationsFavoritedDrawer />
        </HomePageContextProvider>
      </MiniDrawer>
    </>
  );
};

export default HomePage;
