import { makeStyles, createStyles, Divider } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { useContext } from "react";
import AllDevicesContext from "../../Context/AllDevicesContext";
import DevicesToAssociate from "../DevicesToAssociate";
import Header from "../Header";

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      margin: "30px 40px 30px 40px",
      height: "calc(100% - 60px)",
    },
  })
);

const CustomPaper: React.FunctionComponent = () => {
  const classes = useStyles();

  const { devices, relocate } = useContext(AllDevicesContext);

  return (
    <Paper className={classes.paper} elevation={0}>
      {devices.length !== 0 && (
        <>
          {relocate && (
            <>
              <Header />
              <Divider />
            </>
          )}
        </>
      )}
      <DevicesToAssociate />
    </Paper>
  );
};

export default CustomPaper;
