import { AxiosResponse } from "axios";
import api from "./Api";
import { getCurrentUser } from "./AuthService";
import authHeader from "./UserService";

export const activePrivacyTermUser = (): Promise<AxiosResponse<boolean>> => {
  return api.post(`/users/${getCurrentUser().userInfo.id}/privacy_term/`, {
    headers: authHeader(),
  });
};
