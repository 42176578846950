import { IconButton, makeStyles } from "@material-ui/core";
import { useContext } from "react";
import history from "../../../../Services/history";
import ConfirmCodeContext from "../../Context/ConfirmCodeContext";

const HeaderCodePage: React.FunctionComponent = () => {
  const classes = useStyles();

  const { redirectTo, notReceived, setNotReceived } =
    useContext(ConfirmCodeContext);

  const backTo = () => {
    if (notReceived) {
      setNotReceived(false);
    } else {
      history.push(redirectTo);
    }
  };

  return (
    <div className={classes.header}>
      <img src="assets/imgs/intelbras-logo.png" width={150} />
      <div id={"btn-back"} onClick={backTo}>
        <IconButton
          size="small"
          style={{
            marginRight: "10px",
          }}
        >
          <img src="assets/icons/icon_back.svg" />
        </IconButton>
        <span className={classes.back}>voltar</span>
      </div>
    </div>
  );
};

export default HeaderCodePage;

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    paddingLeft: "60px",
  },
  back: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "20px",
    color: "#8B979F",
    cursor: "pointer",
  },
}));
