import styled from "styled-components";

export const Resume = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  width: 874px;
  height: 103px;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  margin-top: 30px;
`;
