import api from "./Api";
import cryptoJS from "crypto-js";
import history from "./history";
import { UserLogin, UserLoginPayload } from "../Models/UserLogin";
import { AxiosResponse } from "axios";

export const login = async (
  username: string,
  password: string,
  country_code: string
): Promise<AxiosResponse<UserLogin>> => {
  const passwordLogin = cryptoJS.MD5(password).toString();
  const user: UserLoginPayload = {
    country_code,
    username,
    password: passwordLogin,
  };
  return api.post<UserLogin>("/auth/login", user);
};

export const logout = (): void => {
  localStorage.clear();
  history.push("/login");
};

export const getCurrentUser = (): UserLogin => {
  return JSON.parse(localStorage.getItem("user") || "{}");
};

export const getPrivacyTerWasAccepted = (): boolean => {
  const term = localStorage.getItem("privacy_term");

  if (term === "true") return true;
  return false;
};
