import { makeStyles, Theme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import { useContext } from "react";
import GlobalContex from "../../GlobalContext/GlobalContext";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid",
    borderBlockColor: "#36B35F",
    justifyContent: "space-between",
    paddingLeft: "20px",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  logo: {
    transition: "0.5s ease",
    height: 22,
  },
  icon: {
    textAlign: "center",
  },
  imageIcon: {
    display: "flex",
    height: "inherit",
    width: "inherit",
  },
}));

function Logo() {
  const classes = useStyles();
  return (
    <img
      src="assets/imgs/intelbras-logo-branco.png"
      alt="Logo Intelbras"
      className={classes.logo}
    />
  );
}

const DrawerHeader: React.FunctionComponent = () => {
  const classes = useStyles();

  const { openSideBar, setOpenSideBar } = useContext(GlobalContex);

  const toogleSideBar = () => {
    setOpenSideBar(!openSideBar);
  };

  const logo = openSideBar ? <Logo /> : "";
  const sourceImageIcon = openSideBar
    ? "/assets/icons/arrow-menu-left.svg"
    : "/assets/icons/arrow-menu-right.svg";
  const titleButton = openSideBar ? "Recolher menu" : "Expandir menu";

  return (
    <div className={classes.header}>
      {logo}
      <Tooltip
        title={titleButton}
        placement="right"
        aria-label={`${titleButton} tootltip`}
      >
        <IconButton
          onClick={toogleSideBar}
          style={openSideBar ? {} : { transform: "translateX(-8px)" }} // ajusta posicionamento ícone
        >
          <Icon classes={{ root: classes.icon }}>
            <img className={classes.imageIcon} src={sourceImageIcon} alt="" />
          </Icon>
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default DrawerHeader;
