import { ReactElement, useEffect, useRef, useState } from "react";
import { ReactNode } from "react";
import GlobalContext from "./GlobalContext";
import DeviceModel from "../Models/DeviceModel";
import LightTheme from "./../Themes/LightTheme";
import DarkTheme from "./../Themes/DarkTheme";
import {
  ThemeProvider,
  CssBaseline,
  createStyles,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import Severity from "../Types/Severity";
import "../geral.css";
import { removeDevices, setCommandDevice } from "../Services/DevicesService";
import { getAllPlace } from "../Services/PlaceService";
import PlaceModel, { PlaceOptionsModel } from "../Models/PlaceModel";
import { getRoomsDevices } from "../Services/RoomService";
import RoomModel from "../Models/RoomModel";
import authHeader, { getHomeIDSelect } from "../Services/UserService";
import Emitter from "../Utils/Emitter";
import TokenService from "../Services/TokenService";
import { getUserNotificationsPaginated } from "../Services/NotificationService";
import DevicesToRelocate from "../Models/DevicesToRelocate";
import { getAllDevicesService } from "../Services/AllDevicesService";
import { HomeModel } from "../Models/HomeUserModel";
import { getUserHomeInfoLocal } from "../Services/UserHomeInfoLocalService";
import { useSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import { PIDS_ALARM } from "../Utils/PidsAlarm";
import {
  NotificationEventStreamModel,
  NotificationModel,
  AutomationNotificationModel,
} from "../Models/NotificationModel";

declare let EventSourcePolyfill: any;

const themes = {
  LightTheme,
  DarkTheme,
};

interface Props {
  children: ReactNode;
}

const useStyles = makeStyles(() =>
  createStyles({
    snackbarContent: {
      backgroundColor: "#4E646A",
      minHeight: "80px",
      width: "395px",
      border: "1px solid #4E646A",
      borderRadius: "5px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "15px",
    },
    icon: {
      fontSize: 20,
    },
    title1: {
      color: "#D1DAE0",
      margin: "0px",
      padding: "0px 15px",
      fontSize: "13px",
    },
    title2: {
      color: "#D1DAE0",
      margin: "0px",
      padding: "0px 15px",
      fontSize: "15px",
    },
    content: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "start",
      flex: 1,
    },
    close: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      color: "#BDBDBD",
      height: "100%",
      width: "35px",
    },
    closeButton: {
      transform: "translate(-20px, 40)",
    },
  })
);

const GlobalContextProvider = ({ children }: Props): JSX.Element => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [typeTheme, setTypeTheme] = useState<boolean>(true);
  const logoImage = typeTheme
    ? `/assets/imgs/html.png`
    : `/assets/imgs/css.png`;

  const [openDrawerApplication, setOpenDrawerApplication] =
    useState<boolean>(false);
  const [drawerApplicationComponent, setDrawerApplicationComponent] = useState<
    ReactElement | undefined
  >();
  const [drawerApplicationHistory, setDrawerApplicationHistory] = useState<
    {
      component: ReactElement;
      context?: any;
    }[]
  >([]);
  const [
    drawerApplicationContextComponent,
    setDrawerApplicationContextComponent,
  ] = useState<any>();

  const [open, setOpen] = useState<boolean>(false);
  const [snackAlert, setSnackAlert] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [severity, setSeverity] = useState<Severity>();
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const [waitingActions, setWaitingActions] = useState<string[]>([]);
  const [homeOperator, setHomeOperator] = useState<boolean>(true);
  const [places, setPlaces] = useState<PlaceModel[]>([]);
  const [rooms, setRooms] = useState<RoomModel[]>([]);
  const [placeSelected, setPlaceSelected] = useState<PlaceModel>({
    place_id: "0",
    name: "",
    rooms: [],
    home_id: "",
  });
  const [placeSelectedTrigger, setPlaceSelectedTrigger] =
    useState<boolean>(false);
  const [selectHomeTrigger, setSelectHomeTrigger] = useState<boolean>(false);
  const [room, setRoom] = useState<RoomModel>({} as RoomModel);
  const [devices, setDevices] = useState<DeviceModel[]>([]);
  const refDevices = useRef<DeviceModel[]>([]);
  refDevices.current = devices;
  const [allDevicesSelected, setAllDevicesSelected] = useState<boolean>(true);
  const [openSideBar, setOpenSideBar] = useState(false);
  const [isSubDeviceOpen, setIsSubDeviceOpen] = useState<boolean>(false);
  const [pathnameUrl, setPathnameUrl] = useState<string>("");
  const [nameEnterpriseSelected, setNameEnterpriseSelected] =
    useState<string>("");
  const [originalRoomList, setOriginalRoomList] = useState<RoomModel[]>([]);
  const [openNotificationDrawer, setOpenNotificationDrawer] =
    useState<boolean>(false);
  const [isHomeSelected, setIsHomeSelected] = useState<boolean>(false);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [showAlarmsHistory, setshowAlarmsHistory] = useState<boolean>(false);
  const [showConfigurations, setShowConfigurations] = useState<boolean>(false);
  const [newNotificationAlert, setNewNotificationAlert] =
    useState<boolean>(false);
  const [notifications, setNotifications] = useState<
    (NotificationModel | AutomationNotificationModel)[]
  >([]);
  const [hasNextNotification, setHasNextNotification] =
    useState<boolean>(false);
  const [nextRowKeyNotification, setNextRowKeyNotificiation] =
    useState<string>("");
  const [openModalRelocate, setOpenModalRelocate] = useState<boolean>(false);
  const [relocatingDevices, setRelocatingDevices] = useState<boolean>(false);
  const [idRoomToRelocate, setIdRoomToRelocate] = useState<string>("");
  const [idEnvironmentToRelocate, setIdEnvironmentToRelocate] =
    useState<string>("");
  const [devicesToRelocate, setDevicesToRelocate] = useState<
    DevicesToRelocate[]
  >([]);
  const [relocateStatusRequest, setRelocateStatusRequest] = useState<number>(0);
  const [sideMenuExpanded, setSideMenuExpanded] = useState<string[]>([]);
  const [expandSubHeader, setExpandSubHeader] = useState<boolean>(true);
  const [isLoadingRooms, setIsLoadingRooms] = useState<boolean>(false);
  const [roomsToShow, setRoomsToShow] = useState<RoomModel[]>([]);
  const [home, setHome] = useState<HomeModel>({} as HomeModel);

  useEffect(() => {
    const home = getUserHomeInfoLocal();
    if (home) {
      setHome(home);
    }

    return () => {
      setPlaces([]);
      setHasNextNotification(false);
      setNextRowKeyNotificiation("");
      setNotifications([]);
      setDevices([]);
      setAllDevicesSelected(true);
      toggleWaitIndicator("GlobalContextProvider:setRoomIdR", false);
    };
  }, []);

  useEffect(() => {
    loadPlaceData();
  }, [selectHomeTrigger]);

  useEffect(() => {
    return startEventStream();
  }, [isLogged, selectHomeTrigger]);

  useEffect(() => {
    return startEventStreamNotification();
  }, [isLogged, selectHomeTrigger]);

  useEffect(() => {
    setRoomsToShow(rooms);
  }, [rooms]);

  useEffect(() => {
    setIsLoadingRooms(false);
  }, [roomsToShow]);

  const showAlert = (incomingMessage: string, incomingSeverity: Severity) => {
    setMessage(incomingMessage);
    setSeverity(incomingSeverity);
    setSnackAlert(true);
  };

  const copyPrivacyTermToClipboard = (idText: string) => {
    const text: any = document?.getElementById(idText)?.innerText;
    navigator.clipboard.writeText(text);
    showAlert("Termo de privacidade copiado", "success");
  };

  const updateDevicesList = (devices: DeviceModel[]): void => {
    setDevices(devices);
  };

  const getRealTimeDevices = (devices_ids: string[]): any[] => {
    return devices.filter((d: any) => {
      return devices_ids.findIndex((id) => id === d.id) > -1;
    });
  };

  const loadData = async () => {
    try {
      const options: PlaceOptionsModel = {
        sortBy: "name",
        sortDir: "ASC",
      };
      const responsePlace = await getAllPlace(0, -1, options);
      if (responsePlace.status === 200) {
        setPlaces(responsePlace.data.content);
      } else {
        showAlert("Houve um problema ao carregar os Locais!", "warning");
      }
    } catch (error) {
      showAlert("Houve um problema ao carregar os Locais!", "error");
    }
  };

  const loadPlaceData = () => {
    setPathnameUrl(window.location.pathname);
    if (getHomeIDSelect()) {
      loadData();
    }
  };

  const toggleWaitIndicator = (action: string, value: boolean) => {
    setWaitingActions((prev): string[] => {
      const index = prev.findIndex((v) => {
        return v == action;
      });

      if (index < 0 && value === true) {
        return [...prev, action];
      } else if (index >= 0 && value === false) {
        const copy = [...prev];
        copy.splice(index, 1);
        return copy;
      } else {
        return [...prev];
      }
    });
  };

  const updateDeviceStatus = (event: any) => {
    const status: any =
      typeof event.data == "string" ? JSON.parse(event.data) : event.data;

    const newDevices = [...refDevices.current];

    const index = newDevices.findIndex(
      (elemento: any) => elemento.id === status.devId
    );

    for (let i = 0; i < status.status.length; i++) {
      if (index > -1) {
        const indexStatus = newDevices[index]?.status?.findIndex(
          (elemento: any) => elemento.code === status.status[i].code
        );
        if (indexStatus > -1) {
          newDevices[index].status[indexStatus].value = status.status[i].value;
          newDevices[index].status[indexStatus].t = status.status[i].t;
        } else {
          let indexStatus = 0;

          if (newDevices[index].status)
            indexStatus = newDevices[index].status.length;

          if (!newDevices[index].status) newDevices[index].status = [];

          newDevices[index].status[indexStatus] = status.status[i];
        }
      }
    }

    setDevices(newDevices);
    Emitter.emit("UPDATE_STATUS_DEVICE_" + status.devId, status);
  };

  const startEventStream = () => {
    if (getHomeIDSelect() && authHeader().Authorization !== undefined) {
      const evt = new EventSourcePolyfill(
        `${
          process.env.REACT_APP_API_URL
        }devices/stream/home/${getHomeIDSelect()}`,
        {
          headers: authHeader(),
        }
      );

      const updatePowerStatus = (event: any) => {
        const status: any = JSON.parse(event.data);
        const newDevices = [...refDevices.current];
        const index = newDevices.findIndex(
          (elemento: any) => elemento.id === status.devId
        );
        if (index > -1) {
          newDevices[index].online = event.type === "DEVICE_ONLINE";
        }

        setDevices(newDevices);

        Emitter.emit("UPDATE_POWER_STATUS_DEVICE_" + status.devId, status);
      };

      evt.addEventListener("DEVICE_ONLINE", updatePowerStatus);
      evt.addEventListener("DEVICE_OFFLINE", updatePowerStatus);
      evt.addEventListener("DEVICE_STATUS_REPORT", updateDeviceStatus);

      // evt.onmessage = (e: any) => {};

      evt.onerror = () => {
        evt.close();
        startEventStream();
      };

      return () => {
        evt.close();
      };
    }
  };

  const iconAlarm = (value: number): string => {
    const ASSETS_FOLDER = "assets/icons";
    switch (value) {
      case 1:
        return `${ASSETS_FOLDER}/exclamation-alarm.svg`;
      case 2:
        return `${ASSETS_FOLDER}/fire-alarm.svg`;
      case 3:
        return `${ASSETS_FOLDER}/smoke-alarm.svg`;
      case 4:
        return `${ASSETS_FOLDER}/moviment-alarm.svg`;
      default:
        return "";
    }
  };

  const startEventStreamNotification = () => {
    if (getHomeIDSelect() && authHeader().Authorization !== undefined) {
      const evt = new EventSourcePolyfill(
        `${process.env.REACT_APP_API_URL}notifications/stream/user/${
          TokenService.getUser().userInfo.id
        }/home/${getHomeIDSelect()}`,
        {
          headers: authHeader(),
        }
      );

      const getNewDeviceNotification = (
        event: NotificationEventStreamModel
      ) => {
        const newNotification: NotificationModel = JSON.parse(event.data);

        setNewNotificationAlert(true);
        localStorage.setItem("newNotification", "true");
        setNotifications((notifications) => [
          newNotification,
          ...notifications,
        ]);

        const { device } = newNotification;
        const pidsFilter = PIDS_ALARM.filter(
          (pid) =>
            pid.pid === device.product_id &&
            pid.code === device.status[0].code &&
            pid.value === device.status[0].value
        );

        if (pidsFilter.length > 0) {
          enqueueSnackbar("", {
            autoHideDuration: 99999,
            variant: "default",
            anchorOrigin: { horizontal: "right", vertical: "top" },
            content: (key) => (
              <div className={classes.snackbarContent}>
                <div>
                  <img src={iconAlarm(pidsFilter[0].type)} />
                </div>
                <div className={classes.content}>
                  <p className={classes.title1}>Alarme</p>
                  <p className={classes.title2}>
                    <strong>Alerta: </strong>
                    {`${pidsFilter[0].message} `}{" "}
                    <strong>{`${device.name}`}</strong>
                  </p>
                </div>
                <div className={classes.close}>
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    className={classes.closeButton}
                    onClick={() => {
                      closeSnackbar(key);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            ),
          });
        }
      };

      const getNewExecuteAutomationNotification = (
        event: NotificationEventStreamModel
      ) => {
        const eventAutomationNotification: AutomationNotificationModel =
          JSON.parse(event.data);

        if (eventAutomationNotification.scene_automation.actions) {
          const failAutomations =
            eventAutomationNotification.scene_automation.actions.find(
              (item) => item.execStatus === 0
            );

          if (failAutomations) {
            setNewNotificationAlert(true);
            localStorage.setItem("newNotification", "true");
            setNotifications((notifications) => [
              JSON.parse(event.data),
              ...notifications,
            ]);
            showAlert("Falha ao executar automação!", "error");
          }
        }
      };

      evt.addEventListener("DEVICE_ONLINE", getNewDeviceNotification);
      evt.addEventListener("DEVICE_OFFLINE", getNewDeviceNotification);
      evt.addEventListener("DEVICE_STATUS_REPORT", getNewDeviceNotification);
      evt.addEventListener(
        "EXECUTE_SCENE",
        getNewExecuteAutomationNotification
      );

      evt.onerror = () => {
        evt.close();
        startEventStreamNotification();
      };

      return () => {
        evt.close();
      };
    }
  };

  const getMoreNotifications = async () => {
    try {
      const response = await getUserNotificationsPaginated(
        nextRowKeyNotification
      );

      setHasNextNotification(response.data.has_next);
      if (response.data.has_next) {
        setNextRowKeyNotificiation(response.data.next_row_key);
      }
      const oldNotificationsArray = response.data.data;
      oldNotificationsArray.forEach((element) => {
        setNotifications((notifications) => [
          ...notifications,
          JSON.parse(element.message),
        ]);
      });
    } catch (error) {
      showAlert("Não foi possível carregar mais notificações!", "error");
    }
  };

  const setRoomIdR = (an: RoomModel) => {
    toggleWaitIndicator("GlobalContextProvider:setRoomIdR", true);

    setRoom(an);

    try {
      if (an) {
        getRoomsDevices(an.room_id).then((response) => {
          if (response) {
            setDevices(response.data);
            setAllDevicesSelected(true);
          } else {
            showAlert(
              "Usuário sem permissão nessa home, acesse novamente o sistema! 1",
              "error"
            );
          }
          toggleWaitIndicator("GlobalContextProvider:setRoomIdR", false);
        });
      } else {
        setRoom({} as RoomModel);
        setDevices([]);
        setRooms([]);
        toggleWaitIndicator("GlobalContextProvider:setRoomIdR", false);
      }
    } catch (error) {
      toggleWaitIndicator("GlobalContextProvider:setRoomIdR", false);
      showAlert(
        "Usuário sem permissão nessa home, acesse novamente o sistema! 2 ",
        "error"
      );
    }
  };

  const handlSetTypeTheme = (typeTheme: boolean) => {
    setTypeTheme(typeTheme);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeCommand = async (
    value: any,
    id: string,
    command?: string,
    fallback?: (error: unknown) => void
  ) => {
    const cmd = {
      commands: [
        {
          code: command,
          value: value,
        },
      ],
    };

    if (value !== null) {
      handleChangeCommands(id, cmd.commands, fallback);
    } else {
      const msg = "Falha ao enviar comando. Parâmetro inválido";
      if (fallback) fallback(msg);
      showAlert(msg, "warning");
    }
  };

  const handleChangeCommands = async (
    id: string,
    commands: any[],
    fallback?: (error: unknown) => void
  ) => {
    const cmd = {
      commands: commands,
    };
    try {
      const data = await setCommandDevice(cmd, id);
      if (data.status === 200) {
        updateDeviceStatus({
          data: { isMockMessage: true, devId: id, status: cmd.commands },
        });
        showAlert("Comando enviado com sucesso!", "success");
      }
    } catch (error) {
      const objError: any = error;

      if (
        objError.message === "Unauthorized" &&
        objError.name === "UserUnauthorizedException"
      ) {
        showAlert(
          "Suas permissões foram alteradas. Acesse o sistema novamente!",
          "error"
        );
      } else if (objError.message.includes("500")) {
        showAlert("Erro ao enviar o comando incompleto", "error");
      } else if (objError.message.includes("400")) {
        showAlert("Erro ao enviar o comando incompleto", "error");
      }
      if (fallback) fallback(error);
    }
  };

  const toggleNotificationDrawer = (open: boolean) => {
    localStorage.removeItem("newNotification");
    setNewNotificationAlert(false);
    setOpenNotificationDrawer(open);
  };

  const toggleOpenDrawerApplication = (component: ReactElement) => {
    const h = drawerApplicationHistory;
    h.push({ component });
    setDrawerApplicationHistory(h);
    setDrawerApplicationComponent(component);
    setOpenDrawerApplication(true);
  };

  const toggleCloseDrawerApplication = () => {
    setDrawerApplicationContextComponent(undefined);
    setDrawerApplicationHistory([]);
    setDrawerApplicationComponent(undefined);
    setOpenDrawerApplication(false);
  };

  const goForwardDrawerApplication = (
    component: ReactElement,
    context?: any
  ) => {
    const h = drawerApplicationHistory;
    h.push({ component, context });
    setDrawerApplicationHistory(h);
    setDrawerApplicationComponent(component);
  };

  const goBackDrawerApplication = () => {
    const h = drawerApplicationHistory;
    const last = h.pop();
    setDrawerApplicationContextComponent(last?.context);
    setDrawerApplicationComponent(h[h.length - 1].component);
    setDrawerApplicationHistory(h);
  };

  const onEditChangeDevice = (id: string, name: string) => {
    const updatedDevice = devices;
    const deviceIndex = updatedDevice.findIndex(
      (device: DeviceModel) => device.id === id
    );
    updatedDevice[deviceIndex].name = name;
    setDevices(updatedDevice);
  };

  const getAllDevices = async () => {
    const { data } = await getAllDevicesService();
    updateDevicesList(data);
  };

  const confirmRemoveDevice = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    closeFunction: () => void
  ) => {
    try {
      const home_id = getHomeIDSelect() || "";
      const response = await removeDevices(devicesToRelocate, home_id);
      if (response.status === 200) {
        toggleCloseDrawerApplication();
        closeFunction();
        getAllDevices();
        showAlert("Dispositivo removido com sucesso!", "success");
      }
    } catch (error) {
      showAlert("Erro ao remover dispositivo(s)!", "error");
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        logoImage,
        openDrawerApplication,
        drawerApplicationComponent,
        drawerApplicationHistory,
        drawerApplicationContextComponent,
        open,
        snackAlert,
        message,
        severity,
        isLogged,
        waitingActions,
        homeOperator,
        places,
        rooms: rooms,
        placeSelected,
        placeSelectedTrigger,
        selectHomeTrigger,
        room,
        devices,
        allDevicesSelected,
        openSideBar,
        isSubDeviceOpen,
        pathnameUrl,
        nameEnterpriseSelected,
        originalRoomList,
        openNotificationDrawer,
        isHomeSelected,
        showHistory,
        showConfigurations,
        showAlarmsHistory,
        newNotificationAlert,
        notifications,
        hasNextNotification,
        nextRowKeyNotification,
        openModalRelocate,
        relocatingDevices,
        idRoomToRelocate,
        idEnvironmentToRelocate,
        devicesToRelocate,
        relocateStatusRequest,
        sideMenuExpanded,
        expandSubHeader,
        isLoadingRooms,
        roomsToShow,
        home,
        setSnackAlert,
        setIsLogged,
        setHomeOperator,
        setPlaces,
        setRooms: setRooms,
        setPlaceSelected,
        setPlaceSelectedTrigger,
        setSelectHomeTrigger,
        setDevices,
        setAllDevicesSelected,
        setOpenSideBar,
        setIsSubDeviceOpen,
        setPathnameUrl,
        setNameEnterpriseSelected,
        setOriginalRoomList,
        setIsHomeSelected,
        setShowHistory,
        setShowConfigurations,
        setshowAlarmsHistory,
        setNotifications,
        setHasNextNotification,
        setNextRowKeyNotificiation,
        setOpenModalRelocate,
        setRelocatingDevices,
        setIdRoomToRelocate,
        setIdEnvironmentToRelocate,
        setDevicesToRelocate,
        setRelocateStatusRequest,
        setSideMenuExpanded,
        setExpandSubHeader,
        setIsLoadingRooms,
        setRoomsToShow,
        setHome,
        showAlert,
        copyPrivacyTermToClipboard,
        updateDevicesList,
        getRealTimeDevices,
        toggleWaitIndicator,
        getMoreNotifications,
        setRoomIdR,
        handlSetTypeTheme,
        handleClickOpen,
        handleClose,
        handleChangeCommand,
        handleChangeCommands,
        toggleNotificationDrawer,
        toggleOpenDrawerApplication,
        toggleCloseDrawerApplication,
        goForwardDrawerApplication,
        goBackDrawerApplication,
        onEditChangeDevice,
        confirmRemoveDevice,
      }}
    >
      <ThemeProvider theme={typeTheme ? themes.LightTheme : themes.DarkTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
