import React, { useContext } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { Divider } from "@material-ui/core";
import GlobalContext from "../../GlobalContext/GlobalContext";
import DrawerHeader from "./DrawerHeader";

const useStyles = makeStyles(() =>
  createStyles({
    customDrawerContent: {
      width: "700px",
      backgroundColor: "#FFF !important",
      height: "100vh",
      overflow: "auto",
    },
    componentContainer: {
      height: "calc(100% - 76px)",
      width: "100%",
      overflow: "auto",
    },
  })
);

const CustomDrawerApplication: React.FunctionComponent = () => {
  const classes = useStyles();
  const {
    openDrawerApplication,
    drawerApplicationComponent,
    toggleCloseDrawerApplication,
  } = useContext(GlobalContext);

  return (
    <React.Fragment key={"drawer-application-right"}>
      <Drawer
        anchor={"right"}
        open={openDrawerApplication}
        onClose={() => toggleCloseDrawerApplication()}
      >
        <div className={classes.customDrawerContent}>
          <DrawerHeader />
          <Divider />
          <div className={classes.componentContainer}>
            {drawerApplicationComponent}
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default CustomDrawerApplication;
