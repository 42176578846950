import { AxiosResponse } from "axios";
import InvoiceModel from "../Models/InvoiceModel";
import { PlansCancelOptions } from "../Models/PlaceModel";
import {
  Plans,
  PreviewPlanResponse,
  SelectPlanResponse,
} from "../Models/Plans";
import api from "./Api";
import authHeader from "./UserService";

export const getAllPans = (): Promise<AxiosResponse<Plans[]>> => {
  return api.get(`/homes/plans`);
};

export const postPlanForPayment = (
  enterpriseId: string,
  priceId: string
): Promise<AxiosResponse<SelectPlanResponse>> => {
  return api.post(`/homes/${enterpriseId}/plans/select-plan`, {
    home_id: enterpriseId,
    price_id: priceId,
  });
};

export const getInvoicesPlan = (
  enterprise_id: string
): Promise<AxiosResponse<InvoiceModel[]>> => {
  return api.get(`/homes/${enterprise_id}/invoice`, {
    headers: authHeader(),
  });
};

export const getCancelOptions = (): Promise<
  AxiosResponse<PlansCancelOptions[]>
> => {
  return api.get(`/homes/plans/cancel_options`);
};

export const postCancelSignaturePlan = (
  enterpriseId: string,
  password: string,
  calcelOptionId: string,
  cancelDetail: string
): Promise<AxiosResponse<boolean>> => {
  return api.post(`/homes/${enterpriseId}/plans/cancel`, {
    password,
    cancelOption: {
      id: calcelOptionId,
    },
    details: cancelDetail,
  });
};

export const postReactiveCanceledSignaturePlan = (
  enterpriseId: string
): Promise<AxiosResponse<boolean>> => {
  return api.post(`/homes/${enterpriseId}/plans/reactive_cancel_plan`);
};

export const newPricePlanChanged = (
  home_id: string,
  price_id: string
): Promise<AxiosResponse<PreviewPlanResponse>> => {
  return api.get(`/homes/${home_id}/plans/preview-plan/${price_id}`, {
    headers: authHeader(),
  });
};
