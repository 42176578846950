import { makeStyles, createStyles } from "@material-ui/core";
import DeviceModel from "../../../../../Models/DeviceModel";
import { useState } from "react";
import ListEnvironmentsFromDevices from "../../../../../Components/ListEnvironmentsFromDevices";
import TV_Control from "../Components/TV_Control";
import TV_ControlMore from "../Components/TV_ControlMore";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      padding: "0px 45px",
      alignItems: "center",
      marginBottom: "100px",
    },
    title: {
      fontSize: "23px",
      color: "#3E5055",
      fontWeight: 600,
      marginBottom: "0px",
    },
    subtitle: {
      fontSize: "15px",
      color: "#8B979F",
    },
    controlTVContainer: {
      height: "653px",
      width: "423px",
      borderRadius: "10px",
      background: "#F7F7F7",
      border: "1px solid #D1DAE0",
      boxShadow: "1px 1px 10px 4px #0000001A",
      padding: "50px 45px",
      color: "#8B979F",
    },
  })
);

interface Props {
  device: DeviceModel;
}

const Device_TV: React.FunctionComponent<Props> = ({ device }: Props) => {
  const classes = useStyles();
  const [more, setMore] = useState(false);

  return (
    <div className={classes.container}>
      <p className={classes.title}>{device.name}</p>
      <div className={classes.subtitle}>
        {device.environments && (
          <ListEnvironmentsFromDevices environments={device.environments} />
        )}
      </div>
      <div className={classes.controlTVContainer}>
        {!more ? (
          <TV_Control device={device} />
        ) : (
          <TV_ControlMore setMore={setMore} />
        )}
      </div>
    </div>
  );
};

export default Device_TV;
