import { AxiosResponse } from "axios";
import DeviceModel from "../Models/DeviceModel";
import RoomModel, {
  ConfigurationDashboardModel,
  RoomsModel,
  UpdateConfigurationDashboardPayload,
} from "../Models/RoomModel";
import api from "./Api";
import authHeader, { getHomeIDSelect } from "./UserService";

export const removeRoom = (
  enviornmentId: string
): Promise<AxiosResponse<boolean>> => {
  return api.delete(`/homes/${getHomeIDSelect()}/rooms/${enviornmentId}`, {
    headers: authHeader(),
  });
};

export const getAllRooms = (): Promise<AxiosResponse<RoomsModel>> => {
  return api.get(`/homes/${getHomeIDSelect()}/rooms/`, {
    headers: authHeader(),
  });
};

export const getRoomsDevices = (
  id: string
): Promise<AxiosResponse<DeviceModel[]>> => {
  return api.get(`/homes/${getHomeIDSelect()}/rooms/${id}/devices`, {
    headers: authHeader(),
  });
};

export const createRoom = (
  formData: FormData
): Promise<AxiosResponse<RoomModel>> => {
  return api.post(`/homes/${getHomeIDSelect()}/rooms/`, formData, {
    headers: authHeader(),
  });
};

export const editRoom = (
  roomToEdit: RoomModel,
  formData: FormData
): Promise<AxiosResponse<RoomModel>> => {
  return api.put(
    `/homes/${getHomeIDSelect()}/rooms/${roomToEdit.room_id}`,
    formData,
    {
      headers: authHeader(),
    }
  );
};

export const removeImgRoom = (
  room_id: string
): Promise<AxiosResponse<boolean>> => {
  return api.delete(`/homes/${getHomeIDSelect()}/rooms/${room_id}/image`, {
    headers: authHeader(),
  });
};

export const favoriteDevice = (
  favorite: boolean,
  device: DeviceModel,
  room_id: string
): Promise<AxiosResponse<boolean>> => {
  const payload = {
    device: device,
    favorite: favorite,
  };

  return api.put(
    `/homes/${getHomeIDSelect()}/room/${room_id}/favorite-device`,
    payload,
    {
      headers: authHeader(),
    }
  );
};

export const getConfigurationsDashboardService = (
  room_id: string
): Promise<AxiosResponse<ConfigurationDashboardModel>> => {
  return api.get(`/homes/${getHomeIDSelect()}/rooms/${room_id}/dashboard`, {
    headers: authHeader(),
  });
};

export const updateConfigurationDashboard = (
  room_id: string,
  payload: UpdateConfigurationDashboardPayload
): Promise<AxiosResponse<void>> => {
  return api.put(
    `/homes/${getHomeIDSelect()}/rooms/${room_id}/dashboard`,
    payload,
    {
      headers: authHeader(),
    }
  );
};
