import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
`;

export const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;

  background-color: #ebeeee;

  &[hidden] {
    visibility: hidden;
    display: none;
  }
`;

export const PainelContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  &.empty {
    justify-items: center;
    justify-content: center;
  }
`;

export const PainelFooter = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  justify-items: center;
`;

export const CenteredContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #8b979f;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  padding: 20px;
`;
