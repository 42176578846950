import {
  makeStyles,
  createStyles,
  Radio,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import {
  RegisterEnterpriseInvalidValuesModel,
  RegisterEnterpriseModel,
} from "../../../Models/EnterpriseModel";
import SearchIcon from "@material-ui/icons/Search";
import { useContext } from "react";
import EnterpriseContext from "../../../Pages/EnterpriseCreatePage/context/EnterpriseContext";
import InputMask from "react-input-mask";

interface Props {
  radioButtonValue: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  registerEnterprise: RegisterEnterpriseModel;
  handleFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
  registerEnterpriseInvalidValues: RegisterEnterpriseInvalidValuesModel;
  repeatEmailValue: string;
}

const RegisterEnterpriseComponent: React.FunctionComponent<Props> = ({
  radioButtonValue,
  handleChange,
  registerEnterprise,
  handleFocus,
  registerEnterpriseInvalidValues,
  repeatEmailValue,
}: Props) => {
  const classes = useStyles();

  const { getCepInfo } = useContext(EnterpriseContext);

  return (
    <div className={classes.componentContent}>
      <div
        style={{
          width: "50%",
          borderRight: "1px solid #BDBDBD",
          paddingRight: "20px",
        }}
      >
        <div className={classes.contentTitle}>Informações pessoais</div>
        <div>
          <div className={classes.radioBtnContainer}>
            <Radio
              id="radio-button-cpf"
              checked={radioButtonValue === "cpf"}
              onChange={handleChange}
              value="cpf"
              name="radio_button_cpf"
            />
            <InputMask
              mask="999.999.999-99"
              value={registerEnterprise.cpf}
              disabled={radioButtonValue !== "cpf"}
              onChange={handleChange}
              onBlur={handleFocus}
            >
              {() => (
                <TextField
                  name="cpf"
                  id="cpf-input"
                  label="CPF *"
                  variant="filled"
                  fullWidth
                  disabled={radioButtonValue !== "cpf"}
                  error={registerEnterpriseInvalidValues.cpf}
                  helperText={
                    registerEnterpriseInvalidValues.cpf ? "CPF inválido" : ""
                  }
                />
              )}
            </InputMask>
          </div>
          <div className={classes.radioBtnContainer}>
            <Radio
              id="radio-button-cnpj"
              checked={radioButtonValue === "cnpj"}
              onChange={handleChange}
              value="cnpj"
              name="radio_button_cnpj"
            />
            <InputMask
              mask="99.999.999/9999-99"
              value={registerEnterprise.cnpj}
              disabled={radioButtonValue !== "cnpj"}
              onChange={handleChange}
              onBlur={handleFocus}
            >
              {() => (
                <TextField
                  name="cnpj"
                  id="cnpj-input"
                  label="CNPJ *"
                  variant="filled"
                  fullWidth
                  disabled={radioButtonValue !== "cnpj"}
                  error={registerEnterpriseInvalidValues.cnpj}
                  helperText={
                    registerEnterpriseInvalidValues.cnpj ? "CNPJ inválido" : ""
                  }
                />
              )}
            </InputMask>
          </div>
        </div>
        <TextField
          className={classes.textfield}
          value={registerEnterprise.responsible}
          name="responsible"
          id="responsible-name-input"
          label="Responsável (Nome completo)"
          variant="filled"
          fullWidth
          required
          inputProps={{
            maxLength: 150,
          }}
          onChange={handleChange}
        />
        <TextField
          className={classes.textfield}
          value={registerEnterprise.email}
          name="email"
          type="email"
          id="email-input"
          label="E-mail"
          variant="filled"
          fullWidth
          required
          error={registerEnterpriseInvalidValues.email}
          helperText={
            registerEnterpriseInvalidValues.email ? "E-mail inválido" : ""
          }
          inputProps={{
            maxLength: 120,
          }}
          onChange={handleChange}
          onBlur={handleFocus}
        />

        <TextField
          className={classes.textfield}
          value={repeatEmailValue}
          type="email"
          name="repeat_email"
          id="repeat-email-input"
          label="Repita o e-mail"
          variant="filled"
          fullWidth
          required
          error={registerEnterpriseInvalidValues.repeat_email}
          helperText={
            registerEnterpriseInvalidValues.repeat_email
              ? "Os e-mails devem ser iguais!"
              : ""
          }
          inputProps={{
            maxLength: 120,
          }}
          onChange={handleChange}
          onBlur={handleFocus}
        />
        <InputMask
          mask="(99) 9 9999-9999"
          value={registerEnterprise.telephone}
          onChange={handleChange}
          disabled={false}
        >
          {() => (
            <TextField
              className={classes.textfield}
              label="Telefone *"
              variant="filled"
              fullWidth
              name="telephone"
              id="telephone-input"
            />
          )}
        </InputMask>
      </div>
      <div
        style={{
          width: "50%",
          borderLeft: "1px solid #BDBDBD",
          paddingLeft: "20px",
        }}
      >
        <div className={classes.contentTitle}>Endereço do empreendimento</div>
        <div className={classes.containerInputs}>
          <InputMask
            mask="99999-999"
            value={registerEnterprise.postal_code}
            disabled={false}
            onChange={handleChange}
          >
            {() => (
              <TextField
                name="postal_code"
                id="postal-code-input"
                label="CEP *"
                variant="filled"
                fullWidth
                error={registerEnterpriseInvalidValues.postal_code}
                helperText={
                  registerEnterpriseInvalidValues.postal_code
                    ? "CEP inválido"
                    : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        id={"find-postal-code-info"}
                        onClick={() =>
                          getCepInfo(registerEnterprise.postal_code)
                        }
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                style={{ marginRight: "20px" }}
              />
            )}
          </InputMask>

          <TextField
            value={registerEnterprise.state}
            name="state"
            id="state-input"
            label="Estado"
            variant="filled"
            fullWidth
            required
            onChange={handleChange}
            inputProps={{
              maxLength: 100,
            }}
            style={{ maxWidth: "220px" }}
          />
        </div>
        <div className={classes.containerInputs}>
          <TextField
            value={registerEnterprise.neighborhood}
            name="neighborhood"
            id="neighborhood-input"
            label="Bairro"
            variant="filled"
            fullWidth
            required
            onChange={handleChange}
            inputProps={{
              maxLength: 100,
            }}
            style={{ marginRight: "20px" }}
          />
          <TextField
            value={registerEnterprise.city}
            name="city"
            id="city-input"
            label="Cidade"
            variant="filled"
            fullWidth
            required
            inputProps={{
              maxLength: 100,
            }}
            onChange={handleChange}
          />
        </div>
        <div className={classes.containerInputs}>
          <TextField
            value={registerEnterprise.address}
            name="address"
            id="address-input"
            label="Endereço"
            variant="filled"
            fullWidth
            required
            onChange={handleChange}
            inputProps={{
              maxLength: 150,
            }}
            style={{ marginRight: "20px" }}
          />
          <TextField
            value={registerEnterprise.number}
            name="number"
            id="number-input"
            label="Número *"
            variant="filled"
            fullWidth
            onChange={handleChange}
            style={{ maxWidth: "175px" }}
            inputProps={{
              maxLength: 50,
            }}
          />
        </div>
        <div className={classes.containerInputs}>
          <TextField
            value={registerEnterprise.address2}
            name="address2"
            id="address2-input"
            label="Complemento"
            variant="filled"
            fullWidth
            onChange={handleChange}
            inputProps={{
              maxLength: 150,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RegisterEnterpriseComponent;

const useStyles = makeStyles(() =>
  createStyles({
    componentContent: {
      width: "100%",
      padding: "15px 50px",
      background: "#ffffff",
      boxShadow: "0px 0px 4px 4px rgba(0, 0, 0, 0.03)",
      borderRadius: "5px",
      marginBottom: "20px",
      display: "flex",
      justifyContent: "space-between",
    },
    contentTitle: {
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "20px",
      color: "#8B979F",
      marginBottom: "15px",
    },
    radioBtnContainer: {
      display: "flex",
      marginBottom: "20px",
    },
    textfield: {
      marginBottom: "20px",
    },
    horizontalDivider: {
      backgroundColor: "#BDBDBD",
      width: "2px",
    },
    containerInputs: {
      marginBottom: "20px",
      display: "flex",
      justifyContent: "space-between",
    },
  })
);
