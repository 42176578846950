import { makeStyles, createStyles, Grid } from "@material-ui/core";
import SubHeader from "../../../../Components/SubHeader";
import ConfigurationValues from "../ConfigurationValues";
import history from "../../../../Services/history";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      backgroundColor: "#FFF",
      width: "99%",
      height: "100%",
    },
    item: { borderRadius: "30px" },
    grid: {
      border: "1px solid #D1DAE0",
    },
  })
);

const ContainerPrincipal: React.FunctionComponent = () => {
  const classes = useStyles();

  const backPress = () => {
    history.push("manage-place");
  };

  return (
    <div className={classes.container}>
      <SubHeader
        text="Ajustes de Notificação"
        subText={
          "Personalize quais notificações você deseja receber do sistema"
        }
        backPress={backPress}
      />
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={8}>
          <ConfigurationValues />
        </Grid>
      </Grid>
    </div>
  );
};

export default ContainerPrincipal;
