import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import EnterpriseContext from "../../../context/EnterpriseContext";
import { planPriceInterval } from "../../../../../Models/Plans";
import GlobalContex from "../../../../../GlobalContext/GlobalContext";

const CheckoutForm = forwardRef(
  (props: { priceInterval: planPriceInterval }, ref) => {
    const stripe = useStripe();
    const elements = useElements();

    const { enterpriseId, selectedPlan } = useContext(EnterpriseContext);
    const { showAlert } = useContext(GlobalContex);

    const [message, setMessage] = useState<string | undefined>();

    const handleSubmit = async (e: React.SyntheticEvent) => {
      e.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${window.location.origin}/enterprise/success-payment?enterpriseId=${enterpriseId}&interval=${props.priceInterval}&plan=${selectedPlan.id}`,
        },
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        showAlert(error.message || "Dados do cartão inválido.", "error");
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
        showAlert("Não foi possível realizar o pagamento.", "error");
      }
    };

    const form = useRef<HTMLFormElement | null>(null);

    useImperativeHandle(ref, () => ({
      submit: () => {
        form.current?.requestSubmit();
      },
    }));

    return (
      <form ref={form} id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    );
  }
);

CheckoutForm.displayName = "CheckoutForm";
export default CheckoutForm;
