import { useEffect, useRef, useState } from "react";
import {
  CodefieldContainerStyled,
  CodefieldLabel,
  InputCode,
  InputsContainer,
  RandomizerButton,
} from "./style";

interface CodefiledProps {
  codeSize: number;
  showLabel?: boolean;
  showRandomizer?: boolean;
  onlyNumbers?: boolean;
  callbackOnChange?: (value: string) => void;
}

type InputValuesProps = {
  value: string;
  ref: React.MutableRefObject<HTMLInputElement | null>;
};

const Codefield: React.FunctionComponent<CodefiledProps> = ({
  codeSize,
  showLabel = true,
  showRandomizer = true,
  onlyNumbers = true,
  callbackOnChange,
}: CodefiledProps) => {
  const values: InputValuesProps[] = [];
  for (let index = 0; index < codeSize; index++) {
    values.push({ value: "", ref: useRef<HTMLInputElement | null>(null) });
  }

  const [inputsValues, setInputsValues] = useState<InputValuesProps[]>(values);

  useEffect(() => {
    if (!callbackOnChange) {
      return;
    }
    let callback = "";
    for (let index = 0; index < codeSize; index++) {
      callback += inputsValues[index].value;
    }
    callbackOnChange(callback);
  }, [inputsValues]);

  const validateInput = (value: string) => {
    if (isNaN(parseInt(value))) {
      return false;
    } else {
      return true;
    }
  };

  const handleChangeValues = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;
    if (onlyNumbers && !validateInput(value) && value !== "") {
      return;
    }
    const values: InputValuesProps[] = [...inputsValues];
    values[index].value = value;
    setInputsValues(values);
  };

  const onKeyPressInputValue = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = (event.target as HTMLInputElement).value;
    if (event.key === "Backspace" && value === "" && index > 0) {
      inputsValues[index - 1].ref.current?.focus();
    }
  };

  const onKeyUpPressInputvalue = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = (event.target as HTMLInputElement).value;
    if (onlyNumbers && !validateInput(value) && value !== "") {
      return;
    }
    if (value === inputsValues[index].value && value !== "") {
      if (index + 1 < inputsValues.length) {
        inputsValues[index + 1].ref.current?.focus();
        if (values[index + 1].ref.current?.value !== "") {
          values[index + 1].ref.current?.select();
        }
      }
    }
  };

  const randomizerValues = () => {
    const values: InputValuesProps[] = [...inputsValues];
    for (let index = 0; index < codeSize; index++) {
      values[index].value = Math.floor(Math.random() * 10).toString();
    }
    setInputsValues(values);
  };

  return (
    <CodefieldContainerStyled>
      {showLabel && (
        <CodefieldLabel>Inserir número de {codeSize} dígitos</CodefieldLabel>
      )}
      <InputsContainer>
        {inputsValues.map((input, index) => {
          return (
            <InputCode
              value={input.value}
              ref={input.ref}
              id={"input-codefield-value-" + index}
              key={"input-codefield-value-" + index}
              maxLength={1}
              onChange={(e) => {
                handleChangeValues(e, index);
              }}
              onKeyDown={(e) => {
                onKeyPressInputValue(e, index);
              }}
              onKeyUp={(e) => {
                onKeyUpPressInputvalue(e, index);
              }}
            />
          );
        })}
        {showRandomizer && (
          <RandomizerButton
            id={"randomizer-codefield-btn"}
            onClick={randomizerValues}
          >
            Aletória
          </RandomizerButton>
        )}
      </InputsContainer>
    </CodefieldContainerStyled>
  );
};

export default Codefield;
