import styled from "styled-components";

export const DisplayContainer = styled.div`
  display: flex;
  width: 500px;

  background: #ffffff;
  box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 12px;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #239c8d;
`;

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 2.5rem;
  height: 2.5rem;

  background: #ebeeee;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex-grow: 1;
  text-align: center;

  .label {
    font-style: normal;
    font-weight: 600;
    font-size: 0.95rem;
    line-height: 20px;

    color: #8b979f;
  }

  .alarm {
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 31px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px 20px;
    max-width: 360px;

    color: #677884;
  }
`;

export const Action = styled.div``;
