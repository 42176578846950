import Drawer from "@material-ui/core/Drawer";
import React from "react";
import { useContext } from "react";
import HomePageContext from "../../Context/HomePageContext";
import clsx from "clsx";
import {
  makeStyles,
  Theme,
  createStyles,
  Divider,
  IconButton,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ShortcutOption from "./ShortcutOption";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addShortcutDevice: {
      backgroundColor: "#EBEEEE",
      display: "flex",
      padding: "19px",
      margin: "5px",
      height: "190px",
      width: "190px",
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",
      justifyContent: "center",
    },
    shortcuts: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: "20px",
      maxWidth: "100%",
      overflow: "auto",
    },
    list: {
      width: 750,
      backgroundColor: "#FFF",
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
    fullList: {
      width: "auto",
    },
    row: {
      display: "flex",
      padding: "25px",
      justifyContent: "start",
      alignItems: "center",
      maxHeight: "90px",
    },
    margin: {
      margin: theme.spacing(1),
    },
  })
);

const ShortcutsDrawer: React.FunctionComponent = () => {
  const {
    openShortcutsDrawer,
    toggleShortcutsDrawer,
    changeStatusAutomationsShortcut,
    changeStatusDeviceInPlaceShortcut,
    changeStatusDevicesShortcut,
    statusAutomationsShortcut,
    statusDeviceInPlaceShortcut,
    statusDevicesShortcut,
  } = useContext(HomePageContext);

  const classes = useStyles();

  return (
    <div>
      <React.Fragment key={"right"}>
        <Drawer
          anchor={"right"}
          open={openShortcutsDrawer}
          onClose={toggleShortcutsDrawer(false)}
        >
          <div
            className={clsx(classes.list)}
            onKeyDown={toggleShortcutsDrawer(false)}
          >
            <div className={classes.row}>
              <IconButton
                aria-label="delete"
                className={classes.margin}
                size="small"
                onClick={toggleShortcutsDrawer(false)}
              >
                <ArrowBackIosIcon fontSize="inherit" />
              </IconButton>
              Voltar
            </div>
            <Divider />
            <div
              style={{
                padding: "20px 67px",
              }}
            >
              <p
                style={{
                  fontWeight: 600,
                  fontSize: "18px",
                  lineHeight: "20px",
                  color: "#3E5055",
                  marginBottom: "5px",
                }}
              >
                Componentes
              </p>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "15px",
                  lineHeight: "20px",
                  color: "#8B979F",
                }}
              >
                Adicione componentes no dashboard ou escolha um template para
                otimizar seu dia-dia
              </p>

              <ShortcutOption
                icon={"icon_automation_shortcut.svg"}
                text={"Atalho de automações"}
                checked={statusAutomationsShortcut}
                changeStatus={() =>
                  changeStatusAutomationsShortcut(statusAutomationsShortcut)
                }
                key={"automation-shotcut"}
              />
              <ShortcutOption
                icon={"icon_device_environment_shortcut.svg"}
                text={"Atalho de dispositivos no espaço"}
                changeStatus={() =>
                  changeStatusDeviceInPlaceShortcut(statusDeviceInPlaceShortcut)
                }
                checked={statusDeviceInPlaceShortcut}
                key={"automation-shotcut"}
              />
              <ShortcutOption
                icon={"icon_device_shortcut.svg"}
                text={"Atalho de dispositivo individual"}
                checked={statusDevicesShortcut}
                changeStatus={() =>
                  changeStatusDevicesShortcut(statusDevicesShortcut)
                }
                key={"automation-shotcut"}
              />
            </div>
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default ShortcutsDrawer;
