import { useContext, useEffect, useState } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  TextField,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { days } from "../../../../../Utils/DaysSchedule";
import AutomationPageContext from "../../../Context/AutomationPageContext";
import CustomInputDate from "../../../../../Components/CustomInputDate";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    normalButton: {
      backgroundColor: "#EBEEEE",
      width: "40px",
      height: "40px",
      marginRight: "13px",
      fontSize: "18px",
    },
    selectedButton: {
      backgroundColor: "#00A335",
      width: "40px",
      height: "40px",
      marginRight: "13px",
      fontSize: "18px",
      color: "#FFFFFF",
    },
    text: {
      color: "#3E5055",
      fontWeight: "bold",
      fontSize: "18px",
    },
    textGeneral: {
      color: "#3E5055",
      fontWeight: "bold",
      fontSize: "15px",
      marginTop: "15px",
    },
    row: {
      display: "flex",
      flexDirection: "row",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      color: "#3E5055",
      fontWeight: "bold",
      fontSize: "15px",
    },
    schedule: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "space-between",
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: theme.palette.primary,
      },
    },
    ButtonEditSave: {
      margin: "5px",
      textTransform: "none",
      paddingLeft: "60px",
      paddingRight: "60px",
    },
    containerTime: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
    },
  })
);

interface DateValue {
  initial: string;
  value: string;
}

const ScheduleConditions: React.FunctionComponent = () => {
  const classes = useStyles();

  const {
    setSelectedDate,
    setTimeSchedule,
    selectedDate,
    repetitionSchedule,
    changeRepeat,
    createNewScheduleCondition,
    setScheduleDate,
    setRepetitionSchedule,
  } = useContext(AutomationPageContext);

  useEffect(() => {
    setRepetitionSchedule([0, 0, 0, 0, 0, 0, 0]);
  }, []);

  const [selectedDateLocal, handleDateChangeLocal] = useState<string>(
    selectedDate || "00:00"
  );

  const [checkAllDays, setCheckAllDays] = useState<boolean>(false);

  useEffect(() => {
    repetitionSchedule.includes(0)
      ? setCheckAllDays(false)
      : setCheckAllDays(true);
  }, [repetitionSchedule]);

  function handleDate(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    handleDateChangeLocal(e.target.value);
    setSelectedDate(e.target.value);
    setTimeSchedule(e.target.value);
  }

  const handleDateInput = (e: Date) => {
    setScheduleDate(new Date(e));
  };

  const checkAllRepeat = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckAllDays(event.target.checked);
    event.target.checked
      ? setRepetitionSchedule([1, 1, 1, 1, 1, 1, 1])
      : setRepetitionSchedule([0, 0, 0, 0, 0, 0, 0]);
  };

  const checkDay = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => {
    changeRepeat(1, index);
  };

  return (
    <div className={classes.schedule}>
      <p
        className={classes.text}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        Adicionar agendamento
      </p>
      <CustomInputDate callback={handleDateInput} />

      <div className={classes.containerTime}>
        <div style={{ minWidth: "50%" }}>
          <p className={classes.textGeneral}>Horário</p>
          <TextField
            type="time"
            value={selectedDateLocal}
            onChange={handleDate}
          />
        </div>
        <div style={{ minWidth: "50%" }}>
          <p className={classes.textGeneral}>Fuso</p>
          <span>IGMT-03:00 Brasília</span>
        </div>
      </div>

      <p className={classes.textGeneral}>Repetição</p>

      <div
        style={{
          display: "flex",
          flexFlow: "row",
          justifyContent: "space-between",
        }}
      >
        {days.map((day: DateValue, i: number) => (
          <IconButton
            key={i}
            onClick={(event) => {
              checkDay(event, i);
            }}
            className={
              repetitionSchedule[i] === 1
                ? classes.selectedButton
                : classes.normalButton
            }
          >
            {day.initial}
          </IconButton>
        ))}

        <FormControlLabel
          control={
            <Checkbox
              checked={checkAllDays}
              onChange={checkAllRepeat}
              name="checkedA"
            />
          }
          label="Todos os dias"
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "80px",
        }}
      >
        <Button
          className={classes.ButtonEditSave}
          color="secondary"
          variant="contained"
          onClick={createNewScheduleCondition}
        >
          {"Salvar"}
        </Button>
      </div>
    </div>
  );
};
export default ScheduleConditions;
