import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import { ChangeEventHandler } from "react";
import { FormControl, OutlinedInput } from "@material-ui/core";

const useStyles = makeStyles(() => {
  const borderColor = "#FFF";
  return {
    searchBox: {
      padding: "12px 16px",
    },
    root: {
      "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
        borderColor,
        "@media (hover: none)": {
          borderColor,
        },
      },
    },
    input: {
      color: "#FFF",
      width: "100%",
      padding: 12,
    },
    notchedOutline: {
      borderColor,
    },
  };
});

type SearchInputProps = {
  value: string;
  onChange: ChangeEventHandler;
};

const SearchInput: React.FunctionComponent<SearchInputProps> = (
  props: SearchInputProps
) => {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.searchBox}>
      <OutlinedInput
        type="search"
        placeholder="Pesquisar"
        value={props.value}
        onChange={props.onChange}
        aria-label="buscar no menu"
        classes={{
          root: classes.root,
          notchedOutline: classes.notchedOutline,
          input: classes.input,
        }}
        endAdornment={
          <InputAdornment position="end">
            <SearchIcon htmlColor="#FFF" />
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default SearchInput;
