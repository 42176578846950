import styled from "styled-components";

export const AlarmsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #ebeeee;
  padding: 10px;
`;

export const AlarmsContainerTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 10px;

  h2 {
    margin-left: 8px;
  }
`;

export const AlarmsContainerBody = styled.div`
  height: calc(100% - 55px);
  min-height: 100px;

  .infinite-scroll-component__outerdiv {
    height: 100%;
  }

  .infinite-scroll-component {
    padding: 10px 20px;
  }
`;

export const CardAlarmWrapper = styled.div`
  .event-log-date {
    font-sze: 15px;
    font-weight: 400;
    line-height: 20px;
    color: #bdbdbd;
    margin-bottom: 5px;

    span {
      font-size: 23px;
      font-weight: 600;
      line-height: 31px;
      color: #3e5055;
    }
  }
`;

export const CardAlarmContainer = styled.div`
  display: flex;
  flex-direction: row;

  background-color: #fff;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 1rem 1.5rem;
  margin-bottom: 20px;
  gap: 30px;
`;

export const CardContentImage = styled.div`
  width: auto;
  justify-items: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  gap: 5px;

  span {
    fontsize: 11px;
    color: #bdbdbd;
  }
`;

export const CardContentMain = styled.div`
  flex-grow: 1;

  .card-log-item-title {
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #3e5055;
  }

  .card-log-item-subtitle {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #8b979f;
  }
  .card-log-item-time {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #bdbdbd;
  }
`;

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 2.3rem;
  height: 2.3rem;

  border: 1px solid #bdbdbd;
  border-radius: 50%;

  color: #3e5055;
  font-size: 1rem;
`;
