import { makeStyles, Grid } from "@material-ui/core";
import RoomGridMenu from "../RoomGridMenu";
import { useContext } from "react";
import ManageRoomContext from "../../Context/ManageRoomContext";
import RoomModel from "../../../../Models/RoomModel";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import history from "../../../../Services/history";
import WithRoleAuthorization from "../../../../Components/WithRoleAuthorization";

interface Props {
  room: RoomModel;
}

const RoomGridItem: React.FunctionComponent<Props> = (props: Props) => {
  const classes = useStyles();

  const { modalRoomOpen } = useContext(ManageRoomContext);
  const { setRoomIdR } = useContext(GlobalContex);

  const setSelectedRoom = () => {
    setRoomIdR(props.room);
    localStorage.setItem("roomSelectName", props.room.name);
    history.push("/");
  };

  return (
    <Grid item xs={3}>
      <div className={classes.roomGridItem} data-name={"item-card-room-grid"}>
        <img
          className={classes.roomImgGridItem}
          src={
            props.room.image
              ? props.room.image
              : "assets/imgs/enterpriseDefaultImage.png"
          }
          alt={"Imagem do espaço " + props.room.name}
          onClick={setSelectedRoom}
        />
        <div className={classes.roomGridItemContent}>
          <div className={classes.roomGridItemTitle} onClick={setSelectedRoom}>
            {props.room.name}
          </div>
          <div
            className={classes.roomGridItemSubtitle}
            onClick={() => modalRoomOpen(props.room)}
          >
            <div>
              {props.room.environments
                ? props.room.environments.length + " Ambientes"
                : "0 Ambientes"}
            </div>
            <div>
              {props.room.devices
                ? props.room.devices.length + " Dispositivos"
                : "0 Dispositivos"}
            </div>
          </div>
        </div>

        <WithRoleAuthorization permitedRoles={["Owner", "Administrador"]}>
          <RoomGridMenu room={props.room} />
        </WithRoleAuthorization>
      </div>
    </Grid>
  );
};

export default RoomGridItem;

const useStyles = makeStyles(() => ({
  roomGridItem: {
    height: "125px",
    backgroundColor: "#EBEEEE",
    border: "1px solid #D1DAE0",
    boxSizing: "border-box",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.08)",
    borderRadius: "5px",
    padding: "8px 15px",
    display: "flex",
    position: "relative",
  },
  roomImgGridItem: {
    maxWidth: "100px",
    maxHeight: "112px",
    height: "auto",
    width: "auto",
    cursor: "pointer",
  },
  roomGridItemContent: {
    margin: "auto",
    marginLeft: "20px",
  },
  roomGridItemTitle: {
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "20px",
    color: "#677884",
    cursor: "pointer",
  },
  roomGridItemSubtitle: {
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#8B979F",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));
