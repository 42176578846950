import { InputProps } from "../InputProps";
import { InputStyled } from "./style";

const InputGeneric: React.FunctionComponent<InputProps> = ({
  id,
  name,
  backgroundColor,
  borderRadius,
  labelColor,
  inputColor,
  borderColorFocus,
  borderColorHover,
  borderColor,
  children,
  ...props
}: InputProps) => {
  return (
    <InputStyled
      id={id}
      name={name || id}
      backgroundcolor={backgroundColor}
      borderradius={borderRadius}
      labelcolor={labelColor}
      inputcolor={inputColor}
      bordercolorfocus={borderColorFocus}
      bordercolorhover={borderColorHover}
      bordercolor={borderColor}
      {...props}
    >
      {children}
    </InputStyled>
  );
};

export default InputGeneric;
