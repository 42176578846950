import { makeStyles, createStyles } from "@material-ui/core";
import {
  RegisterEnterpriseInvalidValuesModel,
  RegisterEnterpriseModel,
} from "../../Models/EnterpriseModel";
import CreateEnterpriseComponent from "./CreateEnterpriseComponent";
import RegisterEnterpriseComponent from "./RegisterEnterpriseComponent";

interface Props {
  selectedImage: File | string;
  handleChangeImage: (value: React.ChangeEvent<HTMLInputElement>) => void;
  removeImageEnterprise: () => void;
  enterpriseName: string;
  setEnterpriseName: (value: string) => void;
  radioButtonValue: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  registerEnterprise: RegisterEnterpriseModel;
  handleFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
  registerEnterpriseInvalidValues: RegisterEnterpriseInvalidValuesModel;
  repeatEmailValue: string;
}

const CustomFormEnterprise: React.FunctionComponent<Props> = ({
  selectedImage,
  handleChangeImage,
  removeImageEnterprise,
  enterpriseName,
  setEnterpriseName,
  radioButtonValue,
  handleChange,
  registerEnterprise,
  handleFocus,
  registerEnterpriseInvalidValues,
  repeatEmailValue,
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.componentContainer}>
      <CreateEnterpriseComponent
        selectedImage={selectedImage}
        handleChangeImage={handleChangeImage}
        removeImageEnterprise={removeImageEnterprise}
        enterpriseName={enterpriseName}
        setEnterpriseName={setEnterpriseName}
      />
      <RegisterEnterpriseComponent
        radioButtonValue={radioButtonValue}
        handleChange={handleChange}
        registerEnterprise={registerEnterprise}
        handleFocus={handleFocus}
        registerEnterpriseInvalidValues={registerEnterpriseInvalidValues}
        repeatEmailValue={repeatEmailValue}
      />
    </div>
  );
};

export default CustomFormEnterprise;

const useStyles = makeStyles(() =>
  createStyles({
    componentContainer: {
      width: "100%",
    },
  })
);
