import { ReactNode, useContext, useEffect, useState } from "react";
import ManagePlaceContext from "./ManagePlaceContext";
import {
  getAllPlace,
  createPlace,
  updatePlace,
  deletePlace,
} from "../../../Services/PlaceService";
import GlobalContex from "../../../GlobalContext/GlobalContext";
import PlaceModel, {
  NewPlaceCreate,
  PlaceOptionsModel,
} from "../../../Models/PlaceModel";
import { AxiosError } from "axios";
import Order from "../../../Types/Order";
import { ErrorMessage } from "../../../Utils/ErrorMessage";

interface Props {
  children: ReactNode;
}

const ManagePlaceProvider: React.FunctionComponent<Props> = (props: Props) => {
  const { children } = props;

  const { showAlert, setPathnameUrl, setPlaces } = useContext(GlobalContex);

  const [placeList, setPlaceList] = useState<PlaceModel[]>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isLoadingPlaces, setIsLoadingPlaces] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [totalPlaces, setTotalPlaces] = useState<number>(0);
  const [order, setOrder] = useState<Order>("asc");
  const [currentFilterValue, setCurrentFilterValue] = useState<string>("");
  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [isLoadingGlobalPlaces, setIsLoadingGlobalPlaces] =
    useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [openPlaceDrawer, setOpenPlaceDrawer] = useState<boolean>(false);
  const [isToEditPlace, setIsToEditPlace] = useState<boolean>(false);
  const [placeToEdit, setPlaceToEdit] = useState<PlaceModel | undefined>();
  const [placeToDelete, setPlaceToDelete] = useState<PlaceModel>(
    {} as PlaceModel
  );
  const [isSavingPlace, setIsSavingPlace] = useState<boolean>(false);

  useEffect(() => {
    setPathnameUrl(window.location.pathname);

    return () => {
      setPlaceList([]);
      setOpenDialog(false);
      setIsLoadingPlaces(true);
      setPage(0);
      setTotalPlaces(0);
      setIsFiltering(false);
      setIsLoadingGlobalPlaces(false);
      setLoadingDelete(false);
      setIsSavingPlace(false);
    };
  }, []);

  const getFilteredPlaces = async (value: string) => {
    setIsFiltering(true);
    try {
      const options: PlaceOptionsModel = {
        filter: value,
        sortBy: "name",
        sortDir: order.toUpperCase(),
      };
      const response = await getAllPlace(0, rowsPerPage, options);
      if (response.status === 200) {
        setPlaceList(response.data.content);
        setTotalPlaces(response.data.totalElements);
        setPage(response.data.pageNumber);
      } else {
        showAlert("Houve um problema ao carregar os locais!", "warning");
        setIsFiltering(false);
      }
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      showAlert(err.message, "error");
      setIsFiltering(false);
    } finally {
      if (page === 0) {
        setIsFiltering(false);
      }
      setIsLoadingPlaces(false);
    }
  };

  const getPlaces = async (filter?: string) => {
    setIsLoadingPlaces(true);
    try {
      const options: PlaceOptionsModel = {
        filter: filter,
        sortBy: "name",
        sortDir: order.toUpperCase(),
      };
      const response = await getAllPlace(page, rowsPerPage, options);
      if (response.status === 200) {
        setPlaceList(response.data.content);
        setTotalPlaces(response.data.totalElements);
        setPage(response.data.pageNumber);
      } else {
        showAlert("Houve um problema ao carregar os locais!", "warning");
      }
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      showAlert(err.message, "error");
    } finally {
      setIsLoadingPlaces(false);
    }
  };

  useEffect(() => {
    if (isFiltering) {
      setIsFiltering(false);
    } else {
      getPlaces(currentFilterValue);
    }
  }, [page, rowsPerPage, order]);

  const getPlacesByFilterValue = (value: string) => {
    setCurrentFilterValue(value);
    getFilteredPlaces(value);
  };

  const updateGlobalPlacesData = async () => {
    setIsLoadingGlobalPlaces(true);
    try {
      const options: PlaceOptionsModel = {
        sortBy: "name",
        sortDir: "ASC",
      };
      const responsePlace = await getAllPlace(0, -1, options);
      if (responsePlace.status === 200) {
        setPlaces(responsePlace.data.content);
      } else {
        showAlert("Houve um problema ao carregar os Locais!", "warning");
      }
    } catch (error) {
      showAlert("Houve um problema ao carregar os Locais!", "error");
    } finally {
      setIsLoadingGlobalPlaces(false);
    }
  };

  const togglePlaceDrawer = (value: boolean, place?: PlaceModel) => {
    setOpenPlaceDrawer(value);

    if (!value) {
      setIsToEditPlace(false);
      setPlaceToEdit(undefined);
      return;
    }

    if (place) {
      setIsToEditPlace(true);
      setPlaceToEdit(place);
    }
  };

  const savePlace = (placeName: string) => {
    if (
      placeName === "" ||
      placeName.trim() === "" ||
      placeName === undefined ||
      placeName === null
    ) {
      showAlert("Nome para Local não pode ser vazio!", "warning");
      return;
    }

    if (isToEditPlace && placeToEdit) {
      updatePlaceToEdit(placeName, placeToEdit);
    } else {
      createNewPlace(placeName);
    }
  };

  const createNewPlace = async (placeName: string) => {
    const newPlace: NewPlaceCreate = {
      name: placeName,
      rooms: [],
    };

    setIsSavingPlace(true);
    await createPlace(newPlace)
      .then(() => {
        showAlert("Local " + placeName + " criado com sucesso!", "success");
        updateGlobalPlacesData();
        getPlaces();
      })
      .catch((error) => {
        const message = ErrorMessage(
          error,
          "Houve um problema na criaçao do Local!"
        );
        showAlert(message, "error");
      })
      .finally(() => {
        setIsSavingPlace(false);
        togglePlaceDrawer(false);
      });
  };

  const updatePlaceToEdit = async (
    placeName: string,
    placeToUpdate: PlaceModel
  ) => {
    const updatedPlace: PlaceModel = {
      name: placeName,
      place_id: placeToUpdate.place_id,
      rooms: placeToUpdate.rooms,
      home_id: placeToUpdate.home_id,
    };

    setIsSavingPlace(true);
    await updatePlace(updatedPlace, placeToUpdate.place_id)
      .then(() => {
        showAlert("Local " + placeName + " editado com sucesso!", "success");
        updateGlobalPlacesData();
        getPlaces();
      })
      .catch((error) => {
        const message = ErrorMessage(
          error,
          "Houve um problema ao atualizar o Local!"
        );
        showAlert(message, "error");
      })
      .finally(() => {
        setIsSavingPlace(false);
        togglePlaceDrawer(false);
      });
  };

  const handleCloseModalDelete = () => {
    setOpenDialog(false);
    setPlaceToDelete({} as PlaceModel);
  };

  const handleOpenModalDelete = (place: PlaceModel) => {
    setOpenDialog(true);
    setPlaceToDelete(place);
  };

  const removePlace = async (place: PlaceModel) => {
    setLoadingDelete(true);
    await deletePlace(place.place_id)
      .then(() => {
        showAlert("Local " + place.name + " deletado com sucesso!", "success");
        updateGlobalPlacesData();
        getPlaces();
      })
      .catch((error) => {
        const message = ErrorMessage(
          error,
          "Houve um problema ao atualizar o Local!"
        );
        showAlert(message, "error");
      })
      .finally(() => {
        setOpenDialog(false);
        setLoadingDelete(false);
      });
  };

  return (
    <ManagePlaceContext.Provider
      value={{
        placeList,
        openDialog,
        isLoadingPlaces,
        page,
        rowsPerPage,
        totalPlaces,
        order,
        isLoadingGlobalPlaces,
        loadingDelete,
        openPlaceDrawer,
        isToEditPlace,
        placeToEdit,
        placeToDelete,
        isSavingPlace,
        setIsLoadingPlaces,
        setPage,
        setRowsPerPage,
        setOrder,
        getPlacesByFilterValue,
        togglePlaceDrawer,
        savePlace,
        handleCloseModalDelete,
        handleOpenModalDelete,
        removePlace,
      }}
    >
      <div
        style={{
          backgroundColor: "#FFF",
          width: "100%",
        }}
      >
        {children}
      </div>
    </ManagePlaceContext.Provider>
  );
};

export default ManagePlaceProvider;
