import {
  CircularProgress,
  createStyles,
  makeStyles,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Theme,
  WithStyles,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import UserGroupsModel from "../../../../Models/UserGroupsModel";
import OperatorsGroupPageContext from "../../Context/OperatorsGroupPageContext";
import CustomTableContainer from "../../../../Components/CustomTableContainer";
import CustomTable from "../../../../Components/CustomTable";
import CustomTableHeadOperatorsGroup from "../CustomTableHeadOperatorsGroup";
import CustomConfirmDialog from "../../../../Components/CustomConfirmDialog";
import Typography from "@material-ui/core/Typography";
import { TablePaginationContainer } from "../../../../Components/CustomTablePagination/styles";
import "./CustomPagination.css";
import WithRoleAuthorization from "../../../../Components/WithRoleAuthorization";
import EmptyState from "../../../../Components/EmptyState";
import history from "../../../../Services/history";
import CustomDeleteIcon from "../../../../Components/CustomIcons/CustomDeleteIcon";
import CustomEditIcon from "../../../../Components/CustomIcons/CustomEditIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      primary: "red",
      color: "white",
    },
    dialog: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    dialogContent: {
      height: "3000pc !important",
      backgroundColor: "#f4f6f8",
    },
    acoes: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    acoesClick: {
      display: "flex",
      flexDirection: "column",
    },
    tablePaginationContainer: {
      "& .MuiTablePagination-spacer": {
        display: "none",
      },
      "& .MuiToolbar-gutters": {
        paddingLeft: 0,
      },
    },
    row: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& > div": {
        paddingRight: "0.6rem",
      },
    },
    root: {
      "& .MuiTablePagination-displayedRows": {
        color: "#888888",
        fontSize: "14px",
        paddingTop: "5px",
      },
      "& .MuiIconButton-root": {
        background: "#D6DBE0",
        color: "#888888",
        padding: "3px",
        marginRight: "10px",
      },
      "& .MuiSelect-select.MuiSelect-select": {
        color: "#888888",
        fontSize: "14px",
      },
      "& .MuiTablePagination-selectRoot": {
        marginLeft: 0,
        marginRight: "50px",
      },
      "& .MuiToolbar-gutters": {
        paddingLeft: 0,
      },
    },
    displayedRows: {
      color: "#888888",
      fontSize: "14px",
    },
    labelRows: {
      marginRight: "8px",
      color: "#AFAFAF",
      fontSize: "14px",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    tableBody: {
      "& tr:last-of-type .MuiTableCell-root": {
        "&:first-child, &:last-child": {
          borderRadius: "0 !important",
        },
      },
    },
  })
);

const styles2 = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#f4f6f8",
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles2> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const OperatorsGroupList: React.FunctionComponent = () => {
  const classes = useStyles();
  const [orderBy, setOrderBy] = useState<keyof UserGroupsModel>("name");

  const {
    handleCloseModalDelete,
    openDialog,
    operatorsGroupList,
    operatorsGroupDelete,
    handleOpenModalDelete,
    order,
    setOrder,
    totalUserGroups,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    isLoadingUserGroups,
  } = useContext(OperatorsGroupPageContext);

  const [operatorsGroupName, setOperatorsGroupName] = useState<string>("");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof UserGroupsModel
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function responsiveHeight(isFilterOpen: boolean) {
    return !isFilterOpen ? "calc(100vh - 300px)" : "calc(100vhh - 240px)";
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const headCellsUsuarios: any[] = [
    { id: "userGroup", label: "Nome do grupo" },
  ];

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const defaultLabelDisplayedRows = (
    from: number,
    to: number,
    count: number
  ) => {
    return (
      <span className={classes.displayedRows}>
        {from}-{to} {"de"} {count}
      </span>
    );
  };

  const renderLabelRows = () => {
    return <span className={classes.labelRows}>{"Exibindo"}</span>;
  };

  const oprGroupList = () => {
    if (operatorsGroupList.length) {
      return (
        <>
          <CustomTableContainer
            maxHeight={responsiveHeight(false)}
            padding={"0px;"}
          >
            <CustomTable>
              <CustomTableHeadOperatorsGroup
                order={order}
                orderBy={orderBy}
                customHeaderItens={headCellsUsuarios}
                onRequestSort={handleRequestSort}
              />
              <TableBody classes={{ root: classes.tableBody }}>
                {operatorsGroupList.map(
                  (row: UserGroupsModel, index: number) => {
                    return (
                      <TableRow
                        role="checkbox"
                        tabIndex={-1}
                        key={"row-" + index}
                        hover
                        style={{
                          backgroundColor: index % 2 === 0 ? "fff" : "#fff",
                          width: "100%",
                        }}
                      >
                        <TableCell style={{ width: "30%" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Avatar src={row.name} />
                            <Typography
                              style={{ paddingLeft: "10px", color: "#8B979F" }}
                            >
                              {row.name}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          {row.users.length > 0 && (
                            <Typography
                              style={{ paddingLeft: "10px", color: "#8B979F" }}
                            >
                              {row.users.map((item) => item.name).join(", ")}.
                            </Typography>
                          )}
                        </TableCell>
                        <WithRoleAuthorization
                          permitedRoles={["Owner", "Administrador"]}
                        >
                          <TableCell
                            style={{ width: "10%", paddingLeft: "0px" }}
                            align="right"
                          >
                            <div className={classes.row}>
                              <CustomDeleteIcon
                                id="fab-delete-contact"
                                onClick={() => {
                                  setOperatorsGroupName(row.name);
                                  handleOpenModalDelete(
                                    row.user_group_id.toString()
                                  );
                                }}
                              />
                              <CustomEditIcon
                                id="fab-edit-contact"
                                onClick={() =>
                                  history.push(
                                    `/operators/edit/${row.user_group_id}`
                                  )
                                }
                              />
                            </div>
                          </TableCell>
                        </WithRoleAuthorization>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </CustomTable>
          </CustomTableContainer>
          <TablePaginationContainer>
            <div className="footer-record-container">
              <TablePagination
                component="div"
                className={classes.tablePaginationContainer}
                rowsPerPageOptions={[5, 10, 15]}
                count={totalUserGroups}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={renderLabelRows()}
                nextIconButtonText={"Próxima página"}
                backIconButtonText={"Página anterior"}
                onPageChange={handleChangePage}
                labelDisplayedRows={({ from, to, count }) =>
                  defaultLabelDisplayedRows(from, to, count)
                }
                onRowsPerPageChange={handleChangeRowsPerPage}
                style={{
                  textTransform: "none",
                  padding: "0px",
                  backgroundColor: "#f2f2f2",
                  paddingLeft: "3rem",
                }}
              />
            </div>
          </TablePaginationContainer>
        </>
      );
    } else {
      return (
        <EmptyState
          title="Nenhum grupo de operador encontrado"
          subtitle="Experimente criar um novo grupo clicando no botão "
          image={"assets/imgs/empty-state-imgs/empty-state-img-1.png"}
          buttonName="+ Novo Grupo"
        />
      );
    }
  };

  return (
    <div style={{ padding: "12px 94px 0px 86px" }}>
      {isLoadingUserGroups ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <CircularProgress color="secondary" size={96} />
        </div>
      ) : (
        <>
          {oprGroupList()}
          <CustomConfirmDialog
            onClose={handleCloseModalDelete}
            onCloseFocus={handleCloseModalDelete}
            openDialog={openDialog}
            onConfirmButton={(e: any) => {
              e.preventDefault();
              operatorsGroupDelete(operatorsGroupName);
            }}
            onCancelButton={handleCloseModalDelete}
            text={`Você realmente deseja remover o grupo `}
            roomName={operatorsGroupName}
          />
        </>
      )}
    </div>
  );
};

export default OperatorsGroupList;
