import { makeStyles, createStyles, Button } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { NotDisturbSchedulesModel } from "../../../../../Models/NotificationConfigModel";
import NotificationConfigContext from "../../../Context/NotificationConfigContext";
import DoNotDisturbInThePeriod from "./DoNotDisturbInThePeriod";
import SelectedDevicesToConfig from "./DoNotDisturbInThePeriod/SelectedDevicesToConfig";
import NotificationToggle from "./NotificationToggle";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: "15px 50px",
      height: "100%",
      width: "100%",
    },
    title: {
      fontWeight: 600,
      fontSize: "18px",
      color: "#3E5055",
      marginBottom: "0px",
    },
    subtitle: {
      fontSize: "13px",
      fontWeight: 400,
      color: "#3E5055",
    },
  })
);

const ConfigurateNotification: React.FunctionComponent = () => {
  const classes = useStyles();

  const {
    notificationConfig,
    changeNotificationsStatus,
    saveNotificationConfig,
    selectedDate,
    selectedDateUntil,
    changeDoNotDisturb,
    valueCheckBox,
    notificationsConfig,
  } = useContext(NotificationConfigContext);

  const [notification, setNotification] = useState<boolean>(false);
  const [doNotDisturb, setDoNotDisturb] = useState<boolean>(false);

  useEffect(() => {
    setNotification(notificationConfig.enabled);
    setDoNotDisturb(notificationConfig.not_disturb);
  }, [notificationConfig]);

  return (
    <div className={classes.container}>
      <NotificationToggle
        text="Ativar notificações"
        checked={notification}
        changeStatus={() => changeNotificationsStatus()}
      />
      <NotificationToggle
        text="Não perturbar no período"
        checked={doNotDisturb}
        changeStatus={() => {
          changeDoNotDisturb();
        }}
        subtitle="Desligue a notificação de alarme dos dispositivos selecionados"
      />

      {doNotDisturb && <DoNotDisturbInThePeriod />}

      {doNotDisturb &&
        notificationsConfig.length > 0 &&
        notificationsConfig.map(
          (notification: NotDisturbSchedulesModel, index: number) => (
            <SelectedDevicesToConfig
              notification={notification}
              key={"selected-device-" + index}
            />
          )
        )}

      {valueCheckBox === "allDevices" && (
        <Button
          onClick={() => saveNotificationConfig([])}
          variant="contained"
          color="secondary"
          id="save-notification-config-button"
          disabled={selectedDate === null || selectedDateUntil === null}
        >
          Salvar
        </Button>
      )}
    </div>
  );
};

export default ConfigurateNotification;
