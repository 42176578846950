import { createStyles, makeStyles } from "@material-ui/core";
import { useContext } from "react";
import history from "../../../../Services/history";
import ChangeToNewPlanSuccessContext from "../../Context/ChangeToNewPlanSuccessContext";
import ResumePlan from "../ResumePlan";
import SuccessMessage from "../SuccessMessage/SuccessMessage";

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      color: "#3E5055",
      fontWeit: 700,
      fontSize: "21px",
    },
    subtitle: {
      color: "#3E5055",
      fontWeit: 400,
      fontSize: "15px",
      paddingBottom: "100px",
    },
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      marginTop: "150px",
    },
    info: {
      color: "#00A335",
      fontWeight: 700,
      cursor: "pointer",
    },
  })
);

const PrincipalContainer: React.FunctionComponent = () => {
  const classes = useStyles();
  const { plan, price } = useContext(ChangeToNewPlanSuccessContext);
  const goToEnterprise = () => {
    history.push("/select-home");
  };
  return (
    <div className={classes.container}>
      <p className={classes.title}>Resumo da compra</p>
      <ResumePlan plan={plan} price={price} />
      <SuccessMessage />
      <img src="assets/imgs/change-plan-success.svg" width={400} />
      <p className={classes.title}>Agradeçemos pela sua confiança!</p>
      <p className={classes.subtitle}>
        Você sempre pode conferir seu plano atual indo em{" "}
        <span className={classes.info} onClick={goToEnterprise}>
          {" "}
          Empreendimento
        </span>
      </p>
    </div>
  );
};

export default PrincipalContainer;
