import ListDevicesRestrictAccess from "./ListDevicesRestrictAccess";
import Tooltip from "@material-ui/core/Tooltip";
import { useState } from "react";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const RestrictAccess: React.FunctionComponent = () => {
  const [open, setOpen] = useState(false);

  const handleTooltipClick = () => {
    setOpen(!open);
  };

  return (
    <div
      style={{
        color: "#3E5055",
        fontSize: "18px",
        fontWeight: "bold",
        marginTop: "25px",
      }}
    >
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        open={open}
        placement="top"
        disableFocusListener
        title="Você pode habilitar ou desabilitar a visualização dos usuários a um ou mais dispositivos"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <strong
            style={{
              marginRight: "10px",
              fontSize: "18px",
              color: "#3E5055",
            }}
          >
            Restringir acesso a Dispositivos
          </strong>
          <HelpOutlineIcon
            fontSize={"small"}
            onClick={handleTooltipClick}
          ></HelpOutlineIcon>
        </div>
      </Tooltip>

      <ListDevicesRestrictAccess />
    </div>
  );
};

export default RestrictAccess;
