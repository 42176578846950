import React from "react";
import {
  createStyles,
  makeStyles,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import UserGroupsModel from "../../../../Models/UserGroupsModel";
import CustomTableHeadOperatorsGroupProps from "./CustomTableHeadOperatorsGroupProps";
import WithRoleAuthorization from "../../../../Components/WithRoleAuthorization";

const useStyles = makeStyles(() =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    tableLabel: {
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "20px",
      color: "#8B979F",
    },
  })
);

const CustomTableHeadOperatorsGroup: React.FC<
  CustomTableHeadOperatorsGroupProps
> = (props) => {
  const { order, orderBy, onRequestSort, customHeaderItens } = props;
  const classes = useStyles();
  const createSortHandler =
    (property: keyof UserGroupsModel) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {customHeaderItens.map((headCell) => (
          <TableCell
            style={{ backgroundColor: "#F2F2F2" }}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={true}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              id={headCell.id}
              IconComponent={KeyboardArrowDownIcon}
            >
              <span className={classes.tableLabel}> {headCell.label} </span>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell style={{ backgroundColor: "#F2F2F2" }}>
          <span className={classes.tableLabel}> {"Membros"} </span>
        </TableCell>
        <WithRoleAuthorization permitedRoles={["Owner", "Administrador"]}>
          <TableCell style={{ backgroundColor: "#F2F2F2" }}>
            <span className={classes.tableLabel}> {"Ações"} </span>
          </TableCell>
        </WithRoleAuthorization>
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHeadOperatorsGroup;
