import { Slider } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    sliderStyle: {
      width: 100,
    },
  })
);

interface Props {
  command: any;
  marks?: any;
  value: any;
  onChange: any;
}

const IntegerInput: React.FunctionComponent<Props> = (props: Props) => {
  const classes = useStyles();

  let values = props.command.values;
  if (typeof values == "string") values = JSON.parse(values);

  const unit = values.unit;
  const marks = getMarks(props, values, unit);

  return (
    <Slider
      className={classes.sliderStyle}
      step={values.step}
      marks={marks}
      min={values.min}
      max={values.max}
      value={props.value || values.min}
      onChange={(e, v) => {
        props.onChange(v, false);
      }}
      onChangeCommitted={(e, f) => {
        props.onChange(f, true);
      }}
      color="secondary"
    />
  );
};

export default IntegerInput;
function getMarks(props: Props, values: Record<string, unknown>, unit: string) {
  let marks = props.marks;

  if (marks == null && values.unit === "%") {
    marks = [
      {
        value: 0,
        label: "0%",
      },
      {
        value: 50,
        label: "50%",
      },
      {
        value: 100,
        label: "100%",
      },
    ];
  }

  if (!marks) {
    marks = [
      {
        value: values.min,
        label: `${values.min}${unit || ""}`,
      },
      {
        value: values.max,
        label: `${values.max}${unit || ""}`,
      },
    ];
  }

  return marks;
}
