import { useEffect, useState, useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import RoomModel from "../../../../../../../../../Models/RoomModel";
import NotificationConfigContext from "../../../../../../../Context/NotificationConfigContext";
import GlobalContext from "../../../../../../../../../GlobalContext/GlobalContext";
import MenuItem from "@material-ui/core/MenuItem";
import { IconButton, InputAdornment, OutlinedInput } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: "40%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    input: {
      padding: "10px 14px",
    },
    outlinedInput: {
      padding: "8px 0px",
    },
  })
);

const NotificationConfgFilter: React.FunctionComponent = () => {
  const classes = useStyles();
  const { places } = useContext(GlobalContext);
  const {
    placeSelectedIndex,
    setSpaceFilterSelected,
    spaceFilterSelected,
    filterNameValue,
    setFilterNameValue,
  } = useContext(NotificationConfigContext);
  const [spaces, SetSpaces] = useState<RoomModel[]>([]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSpaceFilterSelected(parseInt(event.target.value as string));
  };

  const changeValueFilterName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilterNameValue(event.target.value);
  };

  useEffect(() => {
    SetSpaces(places[placeSelectedIndex]?.rooms);
  }, [placeSelectedIndex]);

  return (
    <>
      <div style={{ display: "flex" }}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="outlined-space">Filtrar por espaço</InputLabel>
          <Select
            value={spaceFilterSelected}
            onChange={handleChange}
            label="Filtrar por espaço"
            inputProps={{
              name: "Filtrar por espaço",
              id: "outlined-space",
            }}
          >
            <MenuItem value={0}></MenuItem>
            {spaces?.map((space: RoomModel, i: number) => (
              <MenuItem key={i} value={space.room_id}>
                {space.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <OutlinedInput
            margin="dense"
            placeholder="Pesquisar Dispositivo"
            id="search-for-device-name"
            value={filterNameValue}
            onChange={changeValueFilterName}
            classes={{ root: classes.outlinedInput }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
    </>
  );
};

export default NotificationConfgFilter;
