import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import PlaceModel from "../../Models/PlaceModel";
import RoomModel from "../../Models/RoomModel";
import EnvironmentModel from "../../Models/EnvironmentModel";
import GlobalContext from "../../GlobalContext/GlobalContext";
import { migrateDevices } from "../../Services/AllDevicesService";
import { AxiosError } from "axios";
import DevicesToRelocate from "../../Models/DevicesToRelocate";

interface Props {
  updateRelocateDevicesFunction: () => void;
  confirmRelocateDevicesFunction: () => void;
}

const CustomRelocateModal: React.FunctionComponent<Props> = ({
  updateRelocateDevicesFunction,
  confirmRelocateDevicesFunction,
}: Props) => {
  const {
    places,
    openModalRelocate,
    setOpenModalRelocate,
    idRoomToRelocate,
    setIdRoomToRelocate,
    idEnvironmentToRelocate,
    setIdEnvironmentToRelocate,
    relocatingDevices,
    setRelocatingDevices,
    devicesToRelocate,
    setDevicesToRelocate,
    relocateStatusRequest,
    setRelocateStatusRequest,
    showAlert,
    toggleCloseDrawerApplication,
  } = useContext(GlobalContext);

  const classes = useStyles();

  const [value, setValue] = useState<string>("");
  const [valueRoom, setValueRoom] = useState<string>("");
  const [showRooms, setShowRooms] = useState<boolean>(false);
  const [rooms, setRooms] = useState<RoomModel[]>([]);
  const [valueEnvironments, setValueEnvironments] = useState<string>("");
  const [showEnvironments, setShowEnvironments] = useState<boolean>(false);
  const [environments, setEnvironments] = useState<EnvironmentModel[]>([]);

  useEffect(() => {
    if (openModalRelocate) {
      setOpenModalRelocate(false);
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const valueChanged: string = event.target.value as string;
    const index = places.findIndex(
      (place: PlaceModel) => place.place_id === valueChanged
    );

    setValue(valueChanged);

    if (valueChanged) {
      if (index >= 0) {
        setRooms(places[index].rooms);
        setShowRooms(true);
      }
    } else {
      setShowRooms(false);
    }
  };

  const handleChangeRoom = (event: React.ChangeEvent<{ value: unknown }>) => {
    const valueChanged: string = event.target.value as string;
    const index = rooms.findIndex(
      (room: RoomModel) => room.room_id === valueChanged
    );

    setValueRoom(valueChanged);
    setIdRoomToRelocate(valueChanged);

    if (valueChanged) {
      setEnvironments(rooms[index].environments);
      setShowEnvironments(true);
    } else {
      setShowEnvironments(false);
    }
  };

  const handleChangeEnvironments = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const valueChanged: string = event.target.value as string;

    setValueEnvironments(valueChanged);
    setIdEnvironmentToRelocate(valueChanged);
  };

  const handleClose = () => {
    setOpenModalRelocate(false);
    setIdRoomToRelocate("");
    setRelocatingDevices(false);
    setRelocateStatusRequest(0);
    setValue("");
    setValueRoom("");
    setShowRooms(false);
    setRooms([]);
    setValueEnvironments("");
    setShowEnvironments(false);
    setEnvironments([]);
  };

  const confirmRelocate = () => {
    toggleCloseDrawerApplication();
    handleClose();
    setDevicesToRelocate([]);
    confirmRelocateDevicesFunction();
  };

  const relocateDevices = async (event: React.FormEvent<HTMLFormElement>) => {
    setRelocatingDevices(true);
    const devicesIds = devicesToRelocate.map(
      (device: DevicesToRelocate) => device.id
    );
    try {
      event.preventDefault();
      const response = await migrateDevices(
        idRoomToRelocate,
        devicesIds,
        idEnvironmentToRelocate
      );
      setRelocateStatusRequest(response.status);

      if (response.status === 200) {
        updateRelocateDevicesFunction();
      } else {
        showAlert(
          "Houve algum problema em realocar os dispositivos",
          "warning"
        );
      }
    } catch (e) {
      const err = e as AxiosError;
      const errorCode: number = err.response?.data?.status;
      showAlert(err.message, "error");
      setRelocateStatusRequest(errorCode);
    } finally {
      setRelocatingDevices(false);
    }
  };

  const form = () => {
    return (
      <form className={classes.form} onSubmit={relocateDevices}>
        <DialogTitle className={classes.title}>
          Realocar dispositivos
        </DialogTitle>
        <FormControl variant="outlined" className={classes.formControl}>
          <DialogContentText>Selecionar local</DialogContentText>
          <Select required fullWidth value={value} onChange={handleChange}>
            <MenuItem value="">
              <em>Selecione um local</em>
            </MenuItem>
            {places.map((place: PlaceModel) => (
              <MenuItem key={place.place_id} value={place.place_id}>
                {place.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {showRooms && (
          <FormControl variant="outlined" className={classes.formControl}>
            <DialogContentText>Selecionar espaço</DialogContentText>
            <Select
              required
              fullWidth
              value={valueRoom}
              onChange={handleChangeRoom}
            >
              <MenuItem value="">
                <em>Selecione um espaço</em>
              </MenuItem>

              {rooms.map((room: RoomModel) => (
                <MenuItem key={room.room_id} value={room.room_id}>
                  {room.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {showRooms && showEnvironments && (
          <FormControl variant="outlined" className={classes.formControl}>
            <DialogContentText>Selecionar ambiente</DialogContentText>
            <Select
              fullWidth
              value={valueEnvironments}
              onChange={handleChangeEnvironments}
            >
              <MenuItem value="">
                <em>Selecione um ambiente</em>
              </MenuItem>

              {environments &&
                environments.map((environment: EnvironmentModel) => (
                  <MenuItem key={environment.id} value={environment.id}>
                    {environment.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        <DialogActions className={classes.actions}>
          <Button
            color="secondary"
            type="submit"
            variant="contained"
            disabled={relocatingDevices}
            className={classes.confirmButton}
          >
            Mover
            {relocatingDevices && (
              <CircularProgress
                size={15}
                color="primary"
                style={{ marginLeft: "10px" }}
              />
            )}
          </Button>
          <Button
            onClick={handleClose}
            color="secondary"
            autoFocus
            className={classes.cancelButton}
          >
            Cancelar
          </Button>
        </DialogActions>
      </form>
    );
  };

  const successForm = () => {
    return (
      <div className={classes.body}>
        <div
          style={{
            padding: "0px 20px",
          }}
        >
          <p
            className={classes.successFormTitle}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            Operação concluída
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src="assets/icons/check (2) 1.svg"
              width={100}
              className={classes.img}
              alt="icon check"
            />
          </div>
          <p
            className={classes.successText}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {devicesToRelocate.length === 1 ? (
              <>
                <strong style={{ marginRight: 5 }}>
                  {devicesToRelocate[0].name}
                </strong>{" "}
                foi realocado para outro espaço com sucesso!
              </>
            ) : (
              <>
                <strong style={{ marginRight: 5 }}>
                  {devicesToRelocate.length} dispositivos
                </strong>{" "}
                foram realocados para outro espaço com sucesso!
              </>
            )}
          </p>
        </div>
        <Button
          color="secondary"
          variant="contained"
          className={classes.confirmButtonSuccess}
          onClick={confirmRelocate}
        >
          Confirmar
        </Button>
      </div>
    );
  };

  return (
    <Dialog
      open={openModalRelocate}
      onClose={handleClose}
      classes={{ paper: classes.modal }}
    >
      {relocateStatusRequest === 200 ? successForm() : form()}
    </Dialog>
  );
};

export default CustomRelocateModal;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    form: {
      display: "flex",
      flexDirection: "column",
    },
    buttonMmove: {
      textTransform: "none",
      padding: "10px 25px",
    },
    cancelButton: {
      color: "#EF847E",
      textTransform: "none",
      backgroundColor: "transparent",
      padding: "10px 65px",
    },
    confirmButton: {
      color: "#FFF",
      textTransform: "none",
      backgroundColor: "#00A335",
      padding: "10px 65px",
    },
    confirmButtonSuccess: {
      color: "#FFF",
      textTransform: "none",
      backgroundColor: "#00A335",
      padding: "10px 65px",
      marginTop: "20px",
    },
    modal: {
      minWidth: "500px",
      padding: "30px",
      borderRadius: "25px",
    },
    actions: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "5px 20px",
    },
    title: {
      paddingLeft: "8px",
      fontWeight: "bold",
      fontSize: "15px",
      paddingBottom: "0px",
      paddingTop: "0px",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    successFormTitle: {
      fontSize: "25px",
      fontWeight: "bold",
      color: "#3E5055",
    },
    successText: {
      color: "#3E5055",
      marginTop: "36px",
    },
    img: {
      marginTop: "20px",
    },
  })
);
