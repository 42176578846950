import * as React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import HomeIcon from "@material-ui/icons/Home";
import { Link } from "react-router-dom";
import GlobalContex from "../../GlobalContext/GlobalContext";
import { useContext } from "react";

export { default as BreadcrumbItem } from "./BreadcrumbItem";

type BreadcrumbProps = {
  children: React.ReactNode;
  paddingBottom?: number;
  className?: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    breadcrumb: {
      zIndex: 99,
      backgroundColor: "white",
      padding: "1.5rem 5rem",
      "& li:first-child": {
        fontSize: 0,
      },
      boxShadow: "0px 8px 26px rgba(0, 0, 0, 0.08)",
    },
    nextIcon: {
      color: "#afafaf",
    },
  })
);

const Breadcrumb: React.FunctionComponent<BreadcrumbProps> = ({
  children,
  className = "",
}: BreadcrumbProps) => {
  const classes = useStyles();

  const { setIsHomeSelected } = useContext(GlobalContex);

  return (
    <Breadcrumbs
      className={`${classes.breadcrumb} ${className}`}
      style={{ paddingBottom: "3rem", height: "50px" }}
      separator={
        <NavigateNextIcon className={classes.nextIcon} fontSize="small" />
      }
      aria-label="Migalha de Pão"
    >
      <Link
        to="/select-home"
        onClick={() => {
          setIsHomeSelected(false);
        }}
      >
        <HomeIcon style={{ color: "#00A335" }} />
      </Link>
      {children}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
