import CodePageContainer from "./Components/CodePageContainer";
import ConfirmCodeContextProvider from "./Context/ConfirmCodeContextProvider";

const ConfirmCodePage: React.FunctionComponent = () => {
  return (
    <ConfirmCodeContextProvider>
      <CodePageContainer />
    </ConfirmCodeContextProvider>
  );
};

export default ConfirmCodePage;
