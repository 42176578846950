import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { useContext, useState } from "react";
import AutomationPageContext from "../../../Context/AutomationPageContext";

interface Props {
  enabled: string | undefined;
  display: boolean;
  id: string;
}

const EnableDisableOption: React.FunctionComponent<Props> = ({
  enabled,
  display,
  id,
}: Props) => {
  const { editActionSmart } = useContext(AutomationPageContext);
  const [enable, setEnable] = useState(
    enabled === "ruleDisable" ? "disable" : "enable"
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnable(event.target.value);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "48px",
        backgroundColor: "#EBEEEE",
        display: display ? "block" : "none",
      }}
    >
      <FormControl
        component="fieldset"
        style={{
          fontSize: "15px",
          color: "#8B979F",
        }}
      >
        <RadioGroup
          row
          value={enable}
          style={{
            paddingLeft: "67px",
          }}
          onChange={(
            event: React.ChangeEvent<HTMLInputElement>,
            value: string
          ) => {
            handleChange(event);

            value === "enable"
              ? editActionSmart(id, true)
              : editActionSmart(id, false);
          }}
          name="habilitado"
        >
          <FormControlLabel
            value="enable"
            control={<Radio />}
            label="Habilitar"
          />
          <FormControlLabel
            value="disable"
            control={<Radio />}
            label="Desabilitar"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default EnableDisableOption;
