import axios, { AxiosResponse } from "axios";

interface CepModel {
  uf: string;
  bairro: string;
  localidade: string;
  logradouro: string;
  erro?: boolean;
}

export const getAddressByCep = (
  cep: string
): Promise<AxiosResponse<CepModel>> => {
  return axios.get(`https://viacep.com.br/ws/${cep}/json/`);
};
