import { Grid, Card } from "@material-ui/core";
import { useState } from "react";
import { HomeUserModel } from "../../../../../Models/HomeUserModel";
import EnterpriseActiveCard from "./EnterpriseActiveCard";
import EnterpriseBlockedCard from "./EnterpriseBlockedCard";
import EnterpriseStatus from "./EnterpriseStatus";

interface Props {
  index: number;
  homeUser: HomeUserModel;
}

const EnterpriseCard: React.FunctionComponent<Props> = ({
  homeUser,
  index,
}: Props) => {
  const [statusCardDisplay, setStatusCardDisplay] = useState<string>("none");

  return (
    <Grid
      item
      key={`enterprise-card-grid-item-${index}`}
      xs={12}
      sm={6}
      md={4}
      lg={3}
      xl={2}
    >
      <Card
        id={"card " + homeUser.name}
        onMouseEnter={() => setStatusCardDisplay("flex")}
        onMouseLeave={() => setStatusCardDisplay("none")}
      >
        {homeUser.role === "Administrador" && (
          <EnterpriseStatus
            homeUser={homeUser}
            showStatusCard={statusCardDisplay}
          />
        )}
        {homeUser.status === "COMPLETED" || homeUser.status === "TRIAL" ? (
          <EnterpriseActiveCard homeUser={homeUser} />
        ) : (
          <EnterpriseBlockedCard homeUser={homeUser} />
        )}
      </Card>
    </Grid>
  );
};

export default EnterpriseCard;
