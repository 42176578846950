import { ReactNode, useContext, useEffect, useState } from "react";
import EnvironmentsContext from "./EnvironmentsContext";
import {
  associateDevicesEnvironment,
  createEnvironments,
  editEnvironment,
  ListDevicesNotAssociate,
  listEnvironments,
  listEnvironmentsDevices,
  removeEnvironment,
} from "../../../Services/Environments";
import EnvironmentModel, {
  EnvironmentRequestModel,
} from "../../../Models/EnvironmentModel";
import GlobalContex from "../../../GlobalContext/GlobalContext";
import DeviceModel from "../../../Models/DeviceModel";
import { getRoomsDevices } from "../../../Services/RoomService";
import { AxiosError } from "axios";
import { migrateDevices } from "../../../Services/AllDevicesService";
import { purgeCache } from "../../../Services/DevicesService";
import { getAllPlace } from "../../../Services/PlaceService";
import { PlaceOptionsModel } from "../../../Models/PlaceModel";
import { useLocation } from "react-router-dom";
import history from "../../../Services/history";

interface Props {
  children: ReactNode;
}

const EnvironmentsProvider: React.FunctionComponent<Props> = (props: Props) => {
  const { children } = props;

  const {
    showAlert,
    setAllDevicesSelected,
    updateDevicesList,
    setPlaces,
    setDevices,
    toggleWaitIndicator,
  } = useContext(GlobalContex);

  const [selectDevices, setSelectDevices] = useState(false);
  const [renderBtnDevices, setRenderBtnDevices] = useState(false);
  const [hideOptions, setHideOptions] = useState<boolean>(false);
  const [allDevicesOptionSelected, setAllDevicesOptionSelected] =
    useState<boolean>(true);

  const [totalDevices, setTotalDevices] = useState<number>(0);
  const [environments, setEnvironments] = useState<EnvironmentModel[]>([]);
  const [roomId, setRoomId] = useState<string>("");
  const [roomName, setRoomName] = useState<string>("");
  const [envName, setEnvName] = useState<string>("");
  const [environmentId, setEnvironmentId] = useState<number>(0);

  const [envNameToolbar, setEnvNameToolbar] = useState<string>("");
  const [hideOptionsRemoveDevice, setHideOptionsRemoveDevice] =
    useState<boolean>(false);
  const [isToggleOpen, setIsToggleOpen] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isToEdit, setIsToEdit] = useState<boolean>(false);
  const [isDevicesAssociate, setIsDevicesAssociate] = useState<boolean>(false);
  const [devicesAssociateEnvironment, setDevicesAssociateEnvironment] =
    useState<DeviceModel[]>([]);
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const [hideOptionsAddDevice, setHideOptionsAddDevice] =
    useState<boolean>(false);

  const [devicesAssociateEnvironmentTemp, setDevicesAssociateEnvironmentTemp] =
    useState<DeviceModel[]>([]);

  const [cacheCleaned, setCacheCleaned] = useState<boolean>(false);
  const [devicesRealocar, setDevicesRealocar] = useState<string[]>([]);
  const [allDevices, setAllDevices] = useState<DeviceModel[]>([]);
  const [devicesAssociated, setDevicesAssociated] = useState<DeviceModel[]>([]);
  const [devicesNotAssociated, setDevicesNotAssociated] = useState<
    DeviceModel[]
  >([]);
  const [devicesToAdd, setDevicesToAdd] = useState<DeviceModel[]>([]);
  const [devicesToRemove, setDevicesToRemove] = useState<DeviceModel[]>([]);
  const [associatingDevices, setAssociatingDevices] = useState<boolean>(false);
  const [statusRequest, setStatusRequest] = useState<number>(0);

  const [idRoomSelected, setIdRoomSelected] = useState<string>("");

  const [environmentHasUpdate, setEnvironmentHasUpdate] =
    useState<boolean>(false);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const [filterValue, setFilterValue] = useState<string>("");

  const [loadingEnvironments, setLoadingEnvironments] =
    useState<boolean>(false);

  const [originalDevicesAssociated, setOriginalDevicesAssociated] = useState<
    DeviceModel[]
  >([]);
  const [originalDevicesNotAssociated, setOriginalDevicesNotAssociated] =
    useState<DeviceModel[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const dataRedirect = useLocation<{ environment: string; index: number }>();

  useEffect(() => {
    toggleWaitIndicator("EnvironmentsProvider:init", true);
    purgeCache().finally(() => {
      setCacheCleaned(true);
      toggleWaitIndicator("EnvironmentsProvider:init", false);
    });

    return () => {
      toggleWaitIndicator("EnvironmentsProvider:init", false);
      toggleWaitIndicator("EnvironmentsProvider:updateRelocateDevices", false);
      setCacheCleaned(false);
      setEnvName("");
      setLoadingEnvironments(false);
      setEnvironments([]);
      setSelectedIndex(0);
      setShowOptions(false);
      setAllDevices([]);
      setDevicesAssociated([]);
      setDevicesNotAssociated([]);
      setDevicesAssociateEnvironment([]);
      setHideOptionsAddDevice(false);
      setEnvironmentHasUpdate(false);
      setIsDevicesAssociate(false);
      setDevicesToAdd([]);
      setDevicesToRemove([]);
      setAssociatingDevices(false);
    };
  }, []);

  useEffect(() => {
    const redirect = async () => {
      if (
        dataRedirect.state &&
        dataRedirect.state.environment &&
        dataRedirect.state.index
      ) {
        const env = JSON.parse(dataRedirect.state.environment);
        setEnvironmentId(env.id);
        setEnvName(env.name);
        clickEnvironmentDevices(env.id);
        setAllDevicesSelected(false);
        setShowOptions(true);
        setRoomName(env.name);
        setDevicesToAdd([]);
        setDevicesToRemove([]);
        setSelectedIndex(dataRedirect.state.index);

        history.replace({ ...history.location, state: undefined });
      }
    };
    if (roomId && cacheCleaned) {
      Promise.all([getEnvironments(), setHideOptionsAddDevice(false)])
        .then(() => {
          redirect();
        })
        .finally(() => {
          toggleWaitIndicator("EnvironmentsProvider:init", false);
        });

      return () => {
        toggleWaitIndicator("EnvironmentsProvider:init", false);
      };
    }
  }, [roomId, environmentHasUpdate, cacheCleaned]);

  const fRenderBtnDevices = (devicesRealocar: number) => {
    if (devicesRealocar > 0) setRenderBtnDevices(true);
    else setRenderBtnDevices(false);
  };

  const getEnvironmentsList = (id: string) => {
    const index = allDevices.findIndex((device) => device.id === id);
    if (index > -1 && allDevices[index].environments) {
      return allDevices[index].environments;
    }
    return [];
  };

  const changeNameEnvirontment = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRoomName(event?.target.value);
  };

  const createEnvironment = async () => {
    if (!roomName.trim().length) {
      showAlert("Preencha o nome do ambiente", "warning");
      setRoomName("");
      return;
    }
    if (roomName.trim().length > 25) {
      showAlert(
        "Nome do ambiente deve conter, no máximo, 25 caracteres",
        "warning"
      );
      setRoomName("");
      return;
    }

    setLoadingEnvironments(true);

    const environment: EnvironmentRequestModel = {
      name: roomName.trim(),
      devices: [],
    };

    if (isToEdit) {
      try {
        const index: number = environments.findIndex(
          (s: EnvironmentModel) => s.id === environmentId
        );

        environment.devices = environments[index].devices;

        const result = await editEnvironment(
          roomId,
          environmentId,
          environment
        );

        const options: PlaceOptionsModel = {
          sortBy: "name",
          sortDir: "ASC",
        };
        const responsePlace = await getAllPlace(0, -1, options);

        if (result.status === 200 && responsePlace.status === 200) {
          setEnvName(roomName.trim());
          environments[index].name = roomName.trim();
          setPlaces(responsePlace.data.content);
          showAlert("Ambiente alterado com sucesso", "success");
        }
      } catch (e: any) {
        if (
          e.response.data.status === 400 &&
          e.response.data.errors[0].key === "same.name"
        ) {
          showAlert("Já existe um Ambiente com o mesmo nome", "warning");
        } else {
          showAlert(e.message, "error");
        }
      } finally {
        setLoadingEnvironments(false);
        resetForm();
      }
    } else {
      try {
        const result = await createEnvironments(roomId, environment);

        const options: PlaceOptionsModel = {
          sortBy: "name",
          sortDir: "ASC",
        };
        const responsePlace = await getAllPlace(0, -1, options);

        if (result.status === 200 && responsePlace.status === 200) {
          const newEnv: EnvironmentModel = {
            id: result.data,
            name: environment.name,
            devices: [],
          };
          setEnvironments([...environments, newEnv]);
          setPlaces(responsePlace.data.content);
          showAlert("Ambiente criado com sucesso", "success");
        }
      } catch (e: any) {
        if (
          e.response.data.status === 400 &&
          e.response.data.errors[0].key === "same.name"
        ) {
          showAlert("Já existe um Ambiente com o mesmo nome", "warning");
        } else {
          showAlert(e.message, "error");
        }
      } finally {
        setLoadingEnvironments(false);
        resetForm();
      }
    }
  };

  const resetForm = () => {
    setIsToggleOpen(false);
    setRoomName("");
    setOpenDialog(false);
    setIsToEdit(false);
  };

  const getEnvironments = async () => {
    const result = await listEnvironments(roomId);
    setEnvironments([...result.data]);
  };

  const handleCloseModalDelete = () => {
    setOpenDialog(false);
  };

  const removeEnvironmentContext = async () => {
    setLoadingEnvironments(true);
    try {
      const result = await removeEnvironment(roomId, environmentId);
      const options: PlaceOptionsModel = {
        sortBy: "name",
        sortDir: "ASC",
      };
      const responsePlace = await getAllPlace(0, -1, options);

      if (result.status === 200 && responsePlace.status === 200) {
        setPlaces(responsePlace.data.content);
        setEnvironments(
          environments.filter((s: EnvironmentModel) => s.id !== environmentId)
        );
        setSelectedIndex(0);
        devicesEnvironment(roomId);
        setAllDevicesSelected(true);
        setShowOptions(false);
        setEnvName(roomName);
        showAlert("Ambiente removido com sucesso", "success");
      } else {
        showAlert("Houve um problema", "warning");
      }
    } catch (e: any) {
      showAlert(e.message, "error");
    } finally {
      setLoadingEnvironments(false);
      resetForm();
    }
  };

  const toggleDrawer = (open: boolean) => () => {
    setIsToggleOpen(open);
  };

  const devicesEnvironment = (id: string) => {
    setLoadingEnvironments(true);
    try {
      getRoomsDevices(id)
        .then((response) => {
          setAllDevices(response.data);
          setDevices(response.data);
        })
        .finally(() => {
          setLoadingEnvironments(false);
        });
    } catch (error) {
      setLoadingEnvironments(false);
    }
  };

  const clickEnvironmentDevices = async (envId: number) => {
    setLoadingEnvironments(true);
    try {
      const response = await listEnvironmentsDevices(roomId, envId);
      setDevicesAssociated(response.data.devices);
      const associated: DeviceModel[] = response.data.devices;
      setDevicesNotAssociated(
        allDevices.filter((d1: DeviceModel) =>
          associated.every((d2: DeviceModel) => d1.id !== d2.id)
        )
      );
    } catch (error) {
      setLoadingEnvironments(false);
    } finally {
      setLoadingEnvironments(false);
    }
  };

  const devicesAssociate = async () => {
    const result = await ListDevicesNotAssociate(roomId, environmentId);
    setDevicesAssociateEnvironment(Array.from(new Set([...result.data])));
  };

  const updateEnvironment = async () => {
    const devices: DeviceModel[] = Array.from(
      new Set(
        devicesAssociated
          .concat(devicesToAdd)
          .filter((d1: DeviceModel) =>
            devicesToRemove.every((d2: DeviceModel) => d1.id !== d2.id)
          )
      )
    );

    const devicesToRmv: DeviceModel[] = Array.from(
      new Set(
        devicesNotAssociated
          .concat(devicesToRemove)
          .filter((d1: DeviceModel) =>
            devicesToAdd.every((d2: DeviceModel) => d1.id !== d2.id)
          )
      )
    );

    const environment: EnvironmentRequestModel = {
      name: roomName,
      devices: devices,
    };

    setLoadingEnvironments(true);

    try {
      const result = await associateDevicesEnvironment(
        roomId,
        environmentId,
        environment
      );

      if (result.status === 200) {
        setHideOptionsAddDevice(false);
        getEnvironments();
        showAlert("Dispositivos do ambiente atualizado com sucesso", "success");
        setEnvironmentHasUpdate(!environmentHasUpdate);
        setShowOptions(true);
        devicesEnvironment(roomId);
        setDevicesAssociated(devices);
        setDevicesNotAssociated(devicesToRmv);
        setIsDevicesAssociate(false);
        setDevicesToAdd([]);
        setDevicesToRemove([]);
      }
    } catch (e: any) {
      showAlert(e.message.toString(), "error");
    } finally {
      setHideOptions(false);
      setLoadingEnvironments(false);
    }
  };

  const updateRelocateDevices = () => {
    const updateDevices = () => {
      getRoomsDevices(roomId).then((response) => {
        setAllDevices(response.data);
        updateDevicesList(response.data);
      });
    };
    toggleWaitIndicator("EnvironmentsProvider:updateRelocateDevices", true);
    Promise.all([getEnvironments(), updateDevices()]).finally(() => {
      toggleWaitIndicator("EnvironmentsProvider:updateRelocateDevices", false);
    });
  };

  const moveDevicesToRoom = async (event: React.FormEvent<HTMLFormElement>) => {
    setAssociatingDevices(true);

    try {
      event.preventDefault();

      const response = await migrateDevices(idRoomSelected, devicesRealocar);
      setStatusRequest(response.status);

      if (response.status === 200) {
        getRoomsDevices(roomId).then((response) => {
          setAllDevices(response.data);
          updateDevicesList(response.data);
        });
      } else {
        showAlert(
          "Houve algum problema em realocar os dispositivos",
          "warning"
        );
      }
    } catch (e: any) {
      const err = e as AxiosError;
      // const errorCode: number = err.response?.data?.status;
      showAlert(err.message, "error");
      setStatusRequest(200);
    } finally {
      setAssociatingDevices(false);
    }
  };

  const confirmAssociation = () => {
    setOpenModal(false);
    setAssociatingDevices(false);
    setDevicesRealocar([]);
    setStatusRequest(0);
    setSelectDevices(false);
    setHideOptions(false);
  };

  const reallocateDevices = () => {
    setIsDevicesAssociate(false);
    setHideOptionsAddDevice(false);
    setHideOptions(false);

    setSelectDevices(true);
    setHideOptions(true);
  };

  const editEnvironmentDevices = () => {
    setSelectDevices(false);
    setIsDevicesAssociate(true);
    devicesAssociate();
    setDevicesAssociateEnvironmentTemp([]);
    setHideOptionsAddDevice(true);
    setHideOptions(true);
    setOriginalDevicesAssociated(devicesAssociated);
    setOriginalDevicesNotAssociated(devicesNotAssociated);
  };

  return (
    <EnvironmentsContext.Provider
      value={{
        setRoomId,
        changeNameEnvirontment,
        isToggleOpen,
        roomName,
        createEnvironment,
        toggleDrawer,
        setIsToggleOpen,
        environments,
        openDialog,
        setOpenDialog,
        handleCloseModalDelete,
        removeEnvironmentContext,
        setEnvironmentId,
        setRoomName,
        isToEdit,
        setIsToEdit,
        resetForm,
        totalDevices,
        setTotalDevices,
        allDevices,
        setAllDevices,
        devicesEnvironment,
        devicesAssociate,
        setIsDevicesAssociate,
        isDevicesAssociate,
        devicesAssociateEnvironment,
        setDevicesAssociateEnvironment,
        devicesAssociateEnvironmentTemp,
        setDevicesAssociateEnvironmentTemp,
        updateEnvironment,
        setHideOptionsAddDevice,
        hideOptionsAddDevice,
        roomId: roomId,
        clickEnvironmentDevices,
        hideOptionsRemoveDevice,
        setHideOptionsRemoveDevice,
        showOptions,
        getEnvironmentsList,
        setShowOptions,
        devicesAssociated,
        setDevicesAssociated,
        devicesNotAssociated,
        setDevicesNotAssociated,
        devicesToAdd,
        setDevicesToAdd,
        devicesToRemove,
        setDevicesToRemove,
        envName,
        setEnvName,
        envNameToolbar,
        setEnvNameToolbar,
        selectedIndex,
        setSelectedIndex,
        selectDevices,
        setSelectDevices,
        setDevicesRealocar,
        devicesRealocar,
        setRenderBtnDevices,
        renderBtnDevices,
        fRenderBtnDevices,
        allDevicesOptionSelected,
        setAllDevicesOptionSelected,
        hideOptions,
        setHideOptions,
        idRoomSelected,
        setIdRoomSelected,
        associatingDevices,
        openModal,
        setOpenModal,
        statusRequest,
        setStatusRequest,
        moveDevicesToRoom,
        confirmAssociation,
        reallocateDevices,
        editEnvironmentDevices,
        filterValue,
        setFilterValue,
        updateRelocateDevices,
        loadingEnvironments,
        setLoadingEnvironments,
        originalDevicesAssociated,
        setOriginalDevicesAssociated,
        originalDevicesNotAssociated,
        setOriginalDevicesNotAssociated,
      }}
    >
      <div
        style={{
          backgroundColor: "#FFF",
          width: "100%",
        }}
      >
        {children}
      </div>
    </EnvironmentsContext.Provider>
  );
};

export default EnvironmentsProvider;
