import React, { useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import {
  Button,
  IconButton,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import DrawerProps from "../../../../Types/DrawerProps";
import TextField from "@material-ui/core/TextField";
import EnvironmentsContext from "../../context/EnvironmentsContext";
import clsx from "clsx";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      backgroundColor: "#FFF !important",
      maxHeight: "100vh",
    },
    fullList: {
      width: "auto",
    },
    container: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "10px 123px",
    },
    row: {
      display: "flex",
      padding: "25px",
      justifyContent: "start",
      alignItems: "center",
    },
    automationRow: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
    },
    container2: {
      display: "flex",
      padding: "10px 0px",
      height: "90vh",
    },
    drawerPaper: {
      backgroundColor: "#FFF !important",
      width: "500px",
    },
    paper: {
      background: "blue",
      width: "700px",
    },
    root: {
      width: "100%",
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    button: {
      "&:hover": {
        backgroundColor: theme.palette.error.main,
        color: "#FFF",
      },
    },
    titulo: {
      fontSize: "18px",
      fontWeight: "bold",
      marginTop: "15px",
      marginBottom: "5px",
    },
    subtitulo: {
      fontSize: "15px",
      color: "#8B979F",
      marginTop: "5px",
      marginBottom: "15px",
    },
    paperGrid: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      fontSize: "12px",
    },
    buttonBottom: {
      textTransform: "none",
      fontSize: "15px",
      fontWeight: "bold",
    },
    options: {
      fontSize: "12px",
    },
    loadingEnv: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      minHeight: "100px",
    },
  })
);

const anchor: DrawerProps = "right";

const DrawerEnvironments: React.FunctionComponent = () => {
  const classes = useStyles();
  const {
    changeNameEnvirontment,
    isToggleOpen,
    roomName: roomName,
    createEnvironment,
    toggleDrawer,
    setIsToggleOpen,
    resetForm,
    loadingEnvironments,
  } = useContext(EnvironmentsContext);

  const transitionDuration = 700;

  const list = (anchor: DrawerProps) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
    >
      <div className={classes.row}>
        <IconButton
          aria-label="delete"
          size="small"
          style={{
            fontSize: "15px",
            color: "#8B979F",
          }}
          onClick={() => {
            setIsToggleOpen(false);
            resetForm();
          }}
          onKeyDown={toggleDrawer(false)}
          id={"btn-icon-back-drawer-environments"}
        >
          <ArrowBackIosIcon fontSize="inherit" />
        </IconButton>
        <p
          style={{
            margin: "0px",
            fontSize: "15px",
            color: "#8B979F",
          }}
        >
          Voltar
        </p>
      </div>
      <Divider />
    </div>
  );

  return (
    <React.Fragment key={anchor}>
      <Drawer
        transitionDuration={{
          enter: transitionDuration,
          exit: transitionDuration,
        }}
        classes={{
          paper: classes.drawerPaper,
        }}
        PaperProps={{ elevation: 9 }}
        anchor={anchor}
        open={isToggleOpen}
      >
        {list(anchor)}
        {loadingEnvironments ? (
          <div className={classes.loadingEnv}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <>
            <div
              style={{
                paddingLeft: "60px",
                paddingRight: "60px",
              }}
            >
              <div className={classes.titulo}>Nome do ambiente</div>
              <div className={classes.subtitulo}>
                Agrupe os dispositivos de acordo com sua necessidade.
              </div>
              <TextField
                required
                placeholder="Nome do Ambiente"
                variant="filled"
                value={roomName}
                fullWidth
                inputProps={{
                  maxLength: 25,
                }}
                onChange={changeNameEnvirontment}
                id={"input-name-drawer-environment"}
              />
            </div>
            <div
              style={{
                bottom: "auto",
                display: "flex",
                flex: 1,
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                  padding: "20px",
                }}
              >
                <Button
                  autoFocus
                  color="secondary"
                  variant="contained"
                  className={classes.buttonBottom}
                  onClick={createEnvironment}
                  id={"btn-save-drawer-environment"}
                >
                  Salvar
                </Button>
              </div>
            </div>
          </>
        )}
      </Drawer>
    </React.Fragment>
  );
};

export default DrawerEnvironments;
