import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  messageBox: {
    padding: "30px 40px",
    color: "#68ECA7",
  },
  message: {
    whiteSpace: "initial",
    textAlign: "center",
  },
}));

const EmptyResultMessage: React.FunctionComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.messageBox}>
      <p className={classes.message}>
        Nenhum resultado encontrado para sua busca...
      </p>
    </div>
  );
};

export default EmptyResultMessage;
