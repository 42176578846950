import React from "react";

import * as S from "./styled";
import { Tooltip } from "@material-ui/core";
import {
  binaryStringtoDecimal,
  decimalToBinaryString,
} from "../../Utils/Functions";

type DayInfo = {
  name: string;
  shortName: string;
};

type DayItemProps = {
  active: boolean;
  onChange: (actived: boolean) => void;
  day: DayInfo;
};

const DayItem: React.FunctionComponent<DayItemProps> = (props) => {
  return (
    <Tooltip title={props.day.name} arrow>
      <S.DayContainer
        tabIndex={0}
        aria-label={props.day.name}
        role={"checkbox"}
        aria-checked={props.active}
        onKeyPress={(e) => {
          if (e.code === "Enter" || e.code === "Space") {
            props.onChange(!props.active);
          }
        }}
        onClick={() => {
          props.onChange(!props.active);
        }}
      >
        <span>{props.day.shortName}</span>
      </S.DayContainer>
    </Tooltip>
  );
};

const daysOfWeek: DayInfo[] = [
  { name: "Domingo", shortName: "D" },
  { name: "Segunda-feira", shortName: "S" },
  { name: "Terça-feira", shortName: "T" },
  { name: "Quarta-feira", shortName: "Q" },
  { name: "Quinta-feira", shortName: "Q" },
  { name: "Sexta-feira", shortName: "S" },
  { name: "Sábado", shortName: "S" },
];

type DaysOfWeekPickerProps = {
  value: string | number;
  onChange: (value: string | number) => void;
};

const DaysOfWeekPicker: React.FunctionComponent<DaysOfWeekPickerProps> = (
  props
) => {
  const handleDayClick = (actived: boolean, index: number) => {
    const strBinary = Array.from(valuesArray).reverse();
    strBinary[index] = actived ? "1" : "0";
    valuesArray = strBinary.reverse().join("");
    props.onChange(
      props.value === "string"
        ? valuesArray
        : binaryStringtoDecimal(valuesArray)
    );
  };

  let valuesArray =
    typeof props.value === "string"
      ? props.value
      : decimalToBinaryString(props.value).padStart(7, "0");

  const isActive = (index: number) => {
    return Array.from(valuesArray).reverse()[index] === "1";
  };

  return (
    <S.DaysOfWeekContainer>
      {daysOfWeek.map((day, index) => {
        return (
          <DayItem
            key={`day-item-${index}`}
            day={day}
            active={isActive(index)}
            onChange={(actived) => handleDayClick(actived, index)}
          />
        );
      })}
    </S.DaysOfWeekContainer>
  );
};

export default DaysOfWeekPicker;
