import MiniDrawer from "../../Components/CustomMiniDrawer";
import SubHeader from "../../Components/SubHeader";
import { getHomeNameSelect } from "../../Services/UserService";
import DrawerUsersPage from "./Components/DrawerUsersPage";
import Filters from "./Components/Filters";
import UsersPageList from "./Components/UsersPageList";
import UsersPageProvider from "./Context/UsesrPageProvider";
import history from "../../Services/history";
import { useContext } from "react";
import GlobalContex from "../../GlobalContext/GlobalContext";
import { Divider } from "@material-ui/core";

const UsersPage: React.FunctionComponent = () => {
  const { expandSubHeader } = useContext(GlobalContex);

  const backPress = () => {
    history.push("manage-place");
  };

  return (
    <UsersPageProvider>
      <MiniDrawer
        breadcrumb={[
          { name: getHomeNameSelect(), to: "manage-place" },
          { name: "Usuários", to: "" },
        ]}
      >
        <div style={{ width: "100%", backgroundColor: "#FFF" }}>
          <SubHeader text={"Usuários"} subText={""} backPress={backPress} />
          {expandSubHeader && <Filters />}
          <Divider />
          <DrawerUsersPage />
          <UsersPageList />
        </div>
      </MiniDrawer>
    </UsersPageProvider>
  );
};

export default UsersPage;
