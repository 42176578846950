import {
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import GlobalContex from "../../../GlobalContext/GlobalContext";
import { Plans, Price } from "../../../Models/Plans";
import {
  createNewEnterpriseStepTwo,
  getEnterpriseById,
  removeImgEnterprise,
  updateEnterpriseStepOne,
  updateEnterpriseStepTwo,
} from "../../../Services/EnterpriseService";
import { getAllPans } from "../../../Services/PlansService";
import EnterpriseContext from "./EnterpriseContext";
import { Step } from "./EnterpriseProps";
import { AxiosError } from "axios";
import { createNewEnterpriseStepOne } from "../../../Services/EnterpriseService";
import {
  RegisterEnterpriseInvalidValuesModel,
  RegisterEnterpriseModel,
} from "../../../Models/EnterpriseModel";
import axios from "axios";
import { getAddressByCep } from "../../../Services/CepService";
import { ErrorMessage } from "../../../Utils/ErrorMessage";

function getSteps(): Step[] {
  return [
    {
      title: "Informações fiscais do empreendimento",
      shortTitle: "Informações fiscais",
      description:
        "Preencha com as informações do empreendimento e suas informações pessoais",
    },
    {
      title: "Escolha seu plano",
      shortTitle: "Planos",
      description:
        "Escolha o melhor plano para seu empreendimento ou faça um teste gratuíto da nossa plataforma",
    },
    {
      title: "Insira seus dados de pagamento",
      shortTitle: "Pagamento",
      description:
        "Preencha os campos abaixo com suas informações pessoais para efetuar o pagamento. Nós garantimos sigilo de todos seus dados.",
    },
  ];
}

const EnterpriseProvider: React.FunctionComponent = (
  props: PropsWithChildren<ReactNode>
) => {
  const { children } = props;
  const { showAlert } = useContext(GlobalContex);
  const emptyRegisterEnterprise: RegisterEnterpriseModel = {
    cpf: "",
    cnpj: "",
    responsible: "",
    email: "",
    telephone: "",
    country: "Brasil",
    postal_code: "",
    state: "",
    neighborhood: "",
    city: "",
    address: "",
    number: "",
    address2: "",
  };
  const emptyregisterEnterpriseInvalidValues: RegisterEnterpriseInvalidValuesModel =
    {
      cpf: false,
      cnpj: false,
      email: false,
      repeat_email: false,
      postal_code: false,
    };
  const emptyPrice: Price = {
    id: "",
    interval: "year",
    interval_count: 0,
    price: "",
    currency: "",
  };

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [currentStep, setCurrentStep] = useState(getSteps()[currentStepIndex]);
  const [selectedImage, setSelectedImage] = useState<File | string>("");
  const [enterpriseName, setEnterpriseName] = useState<string>("");
  const [savedEnterpriseName, setSavedEnterpriseName] = useState<string>("");
  const [saving, setSaving] = useState(false);
  const [radioButtonValue, setRadioButtonValue] = useState<string>("cpf");
  const [registerEnterprise, setRegisterEnterprise] =
    useState<RegisterEnterpriseModel>(emptyRegisterEnterprise);
  const [registerEnterpriseInvalidValues, setRegisterEnterpriseInvalidValues] =
    useState<RegisterEnterpriseInvalidValuesModel>(
      emptyregisterEnterpriseInvalidValues
    );
  const [repeatEmailValue, setRepeatEmailValue] = useState<string>("");
  const [selectedPlan, setSelectedPlan] = useState<Plans>({} as Plans);
  const [enterpriseId, setEnterpriseId] = useState<string>("");
  const [isEnterpriseRegistered, setIsEnterpriseRegistered] =
    useState<boolean>(false);
  const [priceSelected, setPriceSelected] = useState<Price>(emptyPrice);
  const [currentQtnUser, setCurrentQtnUser] = useState<number>(1);
  const [currentQtnDevice, setCurrentQtnDevice] = useState<number>(0);
  const [currentQtnAutomation, setCurrentQtnAutomation] = useState<number>(0);
  const [plans, setPlans] = useState<Plans[]>([]);
  const [loadingPlans, setLoadingPlans] = useState<boolean>(true);

  useEffect(() => {
    const redirectStep = localStorage.getItem("redirectStep");
    const redirectEnterprise = localStorage.getItem("redirectEnterprise");

    if (redirectStep && redirectEnterprise) {
      const stepNum = parseInt(redirectStep);
      if (stepNum > 0) {
        setIsEnterpriseRegistered(true);
      }

      loadDataEnterprise(redirectEnterprise);
      setEnterpriseId(redirectEnterprise);
      setCurrentStepIndex(stepNum);
      localStorage.removeItem("redirectStep");
      localStorage.removeItem("redirectEnterprise");
    }

    getPlans();

    return () => {
      setCurrentStepIndex(0);
      setSelectedImage("");
      setEnterpriseName("");
      setSavedEnterpriseName("");
      setSaving(false);
      setRadioButtonValue("cpf");
      setRegisterEnterprise(emptyRegisterEnterprise);
      setRegisterEnterpriseInvalidValues(emptyregisterEnterpriseInvalidValues);
      setSelectedPlan({} as Plans);
      setEnterpriseId("");
      setIsEnterpriseRegistered(false);
      setPriceSelected(emptyPrice);
      setCurrentQtnUser(1);
      setCurrentQtnDevice(0);
      setCurrentQtnAutomation(0);
      setPlans([]);
      setLoadingPlans(true);
    };
  }, []);

  useEffect(() => {
    setCurrentStep(getSteps()[currentStepIndex]);
  }, [currentStepIndex]);

  const getPlans = async () => {
    setLoadingPlans(true);
    try {
      const data = await getAllPans();
      setPlans(data.data);
    } catch (e) {
      const msg = ErrorMessage(e, "Não foi possível carregar os planos");
      showAlert(msg, "error");
    } finally {
      setLoadingPlans(false);
    }
  };

  const loadDataEnterprise = async (id: string) => {
    try {
      const response = await getEnterpriseById(id);
      if (response.status === 200) {
        if (response.data.cnpj) {
          setRadioButtonValue("cnpj");
        }

        if (response.data.cpf || response.data.cnpj) {
          setIsEnterpriseRegistered(true);
        }

        setEnterpriseId(response.data.id);
        setEnterpriseName(response.data.homeName ? response.data.homeName : "");
        setSavedEnterpriseName(
          response.data.homeName ? response.data.homeName : ""
        );
        setSelectedImage(response.data.image ? response.data.image : "");
        const enterpriseToEdit: RegisterEnterpriseModel = {
          cpf: response.data.cpf ? response.data.cpf : "",
          cnpj: response.data.cnpj ? response.data.cnpj : "",
          responsible: response.data.name ? response.data.name : "",
          email: response.data.email ? response.data.email : "",
          telephone: response.data.phone ? response.data.phone : "",
          country: "Brasil",
          postal_code: response.data.postal_code
            ? response.data.postal_code
            : "",
          state: response.data.state ? response.data.state : "",
          neighborhood: response.data.neighborhood
            ? response.data.neighborhood
            : "",
          city: response.data.city ? response.data.city : "",
          address: response.data.address ? response.data.address : "",
          number: response.data.number ? response.data.number : "",
          address2: response.data.address2 ? response.data.address2 : "",
        };
        setRegisterEnterprise(enterpriseToEdit);
        if (response.data.plan) {
          setSelectedPlan({
            id: response.data.plan.id,
            name: response.data.plan.name,
            color_plan: response.data.plan.color_plan,
            max_device: response.data.plan.max_device,
            max_user: response.data.plan.max_user,
            max_automation: response.data.plan.max_automation,
            prices: response.data.plan.price ? [response.data.plan.price] : [],
            recommend: false,
            trial: false,
          });
          setPriceSelected(response.data.plan.price);
          setCurrentQtnUser(response.data.plan.qtn_user);
          setCurrentQtnDevice(response.data.plan.qtn_device);
          setCurrentQtnAutomation(response.data.plan.qtn_automation);
        }
      }
    } catch (error) {
      showAlert(
        "Houve um problema ao carregar as informações do Empreendimento!",
        "error"
      );
    }
  };

  const prepareFormEnterpriseBasic = () => {
    const formData = new FormData();
    if (selectedImage instanceof File) {
      formData.append("image", selectedImage, selectedImage.name);
    }
    formData.append("name", enterpriseName);

    return formData;
  };

  const saveEnterpriseBasic = async () => {
    const formData = prepareFormEnterpriseBasic();
    if (enterpriseId === "") {
      return await createEnterpriseBasic(formData);
    }
    return await updateEnterpriseBasic(enterpriseId, formData);
  };

  const createEnterpriseBasic = async (formData: FormData) => {
    try {
      const response = await createNewEnterpriseStepOne(formData);
      if (response.status !== 200) {
        throw new Error(
          "Não foi possível criar o empreendimento. Verifique os dados."
        );
      }
      setEnterpriseId(response.data.id.toString());
      setSavedEnterpriseName(enterpriseName);
      return true;
    } catch (err) {
      const error = err as Error | AxiosError;
      const msg = axios.isAxiosError(error)
        ? "Houve um problema ao tentar criar empreendimento!"
        : error.message || "Não foi possível criar o Empreendimento";
      showAlert(msg, "error");
    }
    return false;
  };

  const updateEnterpriseBasic = async (
    enterpriseId: string,
    formData: FormData
  ) => {
    try {
      const response = await updateEnterpriseStepOne(enterpriseId, formData);
      if (response.status !== 200) {
        throw new Error(
          "Não foi possível atualizar o empreendimento. Verifique os dados."
        );
      }
      setSavedEnterpriseName(enterpriseName);
      showAlert("Empreendimento atualizado com sucesso!", "success");
      return true;
    } catch (err) {
      const error = err as Error | AxiosError;
      const msg = axios.isAxiosError(error)
        ? "Houve um problema ao tentar atualizar o empreendimento!"
        : error.message || "Não foi possível atualizar o Empreendimento";
      showAlert(msg, "error");
    }
    return false;
  };

  const updateEnterprise = () => {
    setSaving(true);
    const formBasicData = prepareFormEnterpriseBasic();
    const formPersonAndAddress = prepareFormEnterpriseDetail();
    return Promise.all([
      updateEnterpriseStepOne(enterpriseId, formBasicData),
      updateEnterpriseStepTwo(enterpriseId, formPersonAndAddress),
    ])
      .then(() => {
        showAlert(
          "As informações do empreendimento foram atualizadas!",
          "success"
        );
        return true;
      })
      .catch((err) => {
        const error = err as Error | AxiosError;
        const msg = axios.isAxiosError(error)
          ? error.response?.data.errors[0]?.message ||
            "Houve um problema ao tentar atualizar empreendimento!"
          : error.message || "Não foi possível atualizar o Empreendimento";
        showAlert(msg, "error");
        return false;
      })
      .finally(() => setSaving(false));
  };

  const prepareFormEnterpriseDetail = () => {
    const data = {} as RegisterEnterpriseModel;
    for (const key in registerEnterprise) {
      const input = registerEnterprise[key as keyof RegisterEnterpriseModel];
      if (input && input.trim() !== "") {
        data[key as keyof RegisterEnterpriseModel] = input;
      }
    }
    return data;
  };

  const fileTypes: string[] = [
    "image/apng",
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/pjpeg",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
    "image/x-icon",
  ];

  function validFileType(file: string) {
    return fileTypes.includes(file);
  }

  const handleChangeImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      showAlert("Tipo de arquivo inválido", "warning");
      setSelectedImage("");
      return;
    }
    if (e.target.files[0]) {
      if (e.target.files[0].size > 1048576) {
        showAlert("A imagem não pode ser maior que 1MB", "warning");
        setSelectedImage("");
        return;
      }
      if (!validFileType(e.target.files[0].type)) {
        showAlert("Tipo de arquivo inválido", "warning");
        setSelectedImage("");
        return;
      }
    } else {
      return;
    }
    setSelectedImage(e.target.files[0]);
  };

  const removeImageEnterprise = async () => {
    if (typeof selectedImage === "string" && selectedImage !== "") {
      await removeImgEnterprise(enterpriseId)
        .then((response) => {
          if (response.data) {
            setSelectedImage("");
            showAlert(
              "Imagem do empreendimento removida com sucesso!",
              "success"
            );
          }
        })
        .catch((error) => {
          const message = ErrorMessage(
            error,
            "Houve um problema ao remover imagem do empreendimento!"
          );
          showAlert(message, "error");
        });
    } else {
      setSelectedImage("");
    }
  };

  const getCepInfo = async (value: string) => {
    await getAddressByCep(value).then((response) => {
      if (response.data.erro) {
        setRegisterEnterpriseInvalidValues({
          ...registerEnterpriseInvalidValues,
          postal_code: true,
        });
        setRegisterEnterprise({
          ...registerEnterprise,
          postal_code: value,
          state: "",
          neighborhood: "",
          city: "",
          address: "",
        });
      } else {
        setRegisterEnterpriseInvalidValues({
          ...registerEnterpriseInvalidValues,
          postal_code: false,
        });
        setRegisterEnterprise({
          ...registerEnterprise,
          postal_code: value,
          state: response.data.uf,
          neighborhood: response.data.bairro,
          city: response.data.localidade,
          address: response.data.logradouro,
        });
      }
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    if (target.name === "repeat_email") {
      setRepeatEmailValue(event.target.value);
      return;
    }

    if (target.name === "postal_code") {
      setRegisterEnterprise({
        ...registerEnterprise,
        [target.name]: target.value,
      });

      if (!target.value.includes("_")) {
        getCepInfo(target.value);
      }

      return;
    }

    if (
      target.name === "radio_button_cpf" ||
      target.name === "radio_button_cnpj"
    ) {
      setRegisterEnterprise({ ...registerEnterprise, cpf: "", cnpj: "" });
      setRegisterEnterpriseInvalidValues({
        ...registerEnterpriseInvalidValues,
        cpf: false,
        cnpj: false,
      });
      setRadioButtonValue(target.value);
      return;
    }

    if (
      target.name === "cpf" ||
      target.name === "cnpj" ||
      target.name === "telephone" ||
      target.name === "number"
    ) {
      if (event.target.validity.valid) {
        setRegisterEnterprise({
          ...registerEnterprise,
          [target.name]: target.value,
        });
      }
      return;
    }
    setRegisterEnterprise({
      ...registerEnterprise,
      [target.name]: target.value,
    });
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const target = event.target;

    if (target.name === "email") {
      if (target.value === "") {
        setRegisterEnterpriseInvalidValues({
          ...registerEnterpriseInvalidValues,
          [target.name]: false,
        });
        return;
      }
      if (target.validity.valid) {
        setRegisterEnterpriseInvalidValues({
          ...registerEnterpriseInvalidValues,
          [target.name]: false,
        });
      } else {
        setRegisterEnterpriseInvalidValues({
          ...registerEnterpriseInvalidValues,
          [target.name]: true,
        });
      }
      return;
    }

    if (target.name === "repeat_email") {
      if (
        registerEnterprise.email.length > 0 &&
        repeatEmailValue.length > 0 &&
        registerEnterprise.email !== repeatEmailValue
      ) {
        setRegisterEnterpriseInvalidValues({
          ...registerEnterpriseInvalidValues,
          [target.name]: true,
        });
      } else {
        setRegisterEnterpriseInvalidValues({
          ...registerEnterpriseInvalidValues,
          [target.name]: false,
        });
      }
      return;
    }

    if (target.name === "postal_code") {
      if (target.value.length === 8) {
        setRegisterEnterpriseInvalidValues({
          ...registerEnterpriseInvalidValues,
          [target.name]: false,
        });
      } else {
        if (target.value === "") {
          setRegisterEnterpriseInvalidValues({
            ...registerEnterpriseInvalidValues,
            [target.name]: false,
          });
          return;
        }
        setRegisterEnterpriseInvalidValues({
          ...registerEnterpriseInvalidValues,
          [target.name]: true,
        });
      }
      return;
    }

    if (target.name === "cpf" || target.name === "cnpj") {
      const charSize = { cpf: 14, cnpj: 18 };
      if (target.value === "") {
        setRegisterEnterpriseInvalidValues({
          ...registerEnterpriseInvalidValues,
          [target.name]: false,
        });
        return;
      }

      if (target.value.includes("_")) {
        setRegisterEnterpriseInvalidValues({
          ...registerEnterpriseInvalidValues,
          [target.name]: true,
        });
        return;
      }

      if (target.value.length === charSize[target.name]) {
        setRegisterEnterpriseInvalidValues({
          ...registerEnterpriseInvalidValues,
          [target.name]: false,
        });
      } else {
        setRegisterEnterpriseInvalidValues({
          ...registerEnterpriseInvalidValues,
          [target.name]: true,
        });
      }
      return;
    }
  };

  const createEnterpriseStepTwo = async (enterprise_id: string) => {
    const objToSend = prepareFormEnterpriseDetail();
    await createNewEnterpriseStepTwo(enterprise_id, objToSend)
      .then((response) => {
        if (response.status === 200) {
          setIsEnterpriseRegistered(true);
          showAlert("Empreendimento cadastrado com sucesso!", "success");
          setCurrentStepIndex(currentStepIndex + 1);
        }
      })
      .catch((error) => {
        const message = ErrorMessage(
          error,
          "Houve um problema ao editar o dispositivo!"
        );

        if (message === "Cpf ou Cnpj inválido.") {
          let name = "";
          if (radioButtonValue === "cpf") {
            name = "cpf";
          } else {
            name = "cnpj";
          }

          setRegisterEnterpriseInvalidValues({
            ...registerEnterpriseInvalidValues,
            [name]: true,
          });
        }

        showAlert(message, "error");
      });
  };

  return (
    <EnterpriseContext.Provider
      value={{
        currentStep,
        currentStepIndex,
        hasNextStep: currentStepIndex + 1 <= getSteps().length,
        hasPreviousStep: currentStepIndex - 1 >= 0,
        stepsList: getSteps(),
        enterpriseId,
        selectedPlan,
        selectedImage,
        enterpriseName,
        plans,
        loadingPlans,
        radioButtonValue,
        registerEnterprise,
        registerEnterpriseInvalidValues,
        repeatEmailValue,
        isEnterpriseRegistered,
        saving,
        priceSelected,
        currentQtnUser,
        currentQtnDevice,
        currentQtnAutomation,
        savedEnterpriseName,
        nextStep: () => setCurrentStepIndex(currentStepIndex + 1),
        previousStep: () => setCurrentStepIndex(currentStepIndex - 1),
        setSelectedPlan,
        handleChangeImage,
        removeImageEnterprise,
        setEnterpriseName,
        handleChange,
        handleFocus,
        getCepInfo,
        createEnterpriseStepTwo,
        updateEnterprise,
        saveEnterpriseBasic,
        setPriceSelected,
      }}
    >
      {children}
    </EnterpriseContext.Provider>
  );
};

export default EnterpriseProvider;
