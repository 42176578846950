import { useContext } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { DisplayScheduleModel } from "../../../../../Models/Automation";
import AutomationPageContext from "../../../Context/AutomationPageContext";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Condition } from "../../../../../Models/Automation";
import { IconButton } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { days } from "../../../../../Utils/DaysSchedule";
import { formatHours } from "../../../../../Utils/FormatHours";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      backgroundColor: "#FFF !important",
      maxHeight: "100vh",
    },
    fullList: {
      width: "auto",
    },
    container: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "10px 123px",
    },
    row: {
      display: "flex",
      padding: "25px",
      justifyContent: "start",
      alignItems: "center",
    },
    automationRow: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
    },
    container2: {
      display: "flex",
      padding: "10px 0px",
      height: "90vh",
    },
    drawerPaper: {
      backgroundColor: "#FFF !important",
      width: "500px",
    },
    paper: {
      background: "blue",
      width: "700px",
    },
    root: {
      width: "100%",
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    button: {
      "&:hover": {
        backgroundColor: theme.palette.error.main,
        color: "#FFF",
      },
    },
  })
);

interface DateValue {
  initial: string;
  value: string;
}

const ShowConditions: React.FunctionComponent = () => {
  const classes = useStyles();
  const {
    setConditions,
    conditions,
    setActions,
    setAddTask,
    isToEdit,
    setWasAutomation,
    setIsAutomation,
    setIsScene,
    getDeviceName,
    getStatusConditionSub,
  } = useContext(AutomationPageContext);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      {conditions &&
        conditions.map((condition: Condition, index) => {
          switch (condition.entity_type) {
            case 1:
              return (
                <ListItem
                  key={"condition-automation-" + index}
                  style={{
                    backgroundColor: "#EBEEEE",
                    border: "1px solid #D1DAE0",
                    boxSizing: "border-box",
                    margin: "3px 0px",
                    borderRadius: "5px",
                    height: "76px",
                    paddingLeft: "28px",
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={"assets/icons/lamp (1) 1.svg"}
                      style={{
                        marginRight: "10px",
                      }}
                      alt={"Status do Dispositivo"}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    {" "}
                    {"Quando o status do " +
                      getDeviceName(condition.entity_id) +
                      " muda"}
                    <p
                      style={{
                        margin: "0px",
                        color: "rgb(139, 151, 159)",
                        display: "flex",
                      }}
                    >
                      {getStatusConditionSub(condition)}
                    </p>
                  </ListItemText>
                  <IconButton
                    aria-label="delete"
                    size="medium"
                    style={{
                      fontSize: "15px",
                      color: "#8B979F",
                    }}
                    onClick={() => {
                      if (conditions.length === 1) {
                        if (isToEdit) {
                          setWasAutomation(true);
                        }
                        setActions([]);
                        setAddTask(false);
                        setIsAutomation(false);
                        setIsScene(false);
                      }
                      setConditions(
                        conditions.filter((c: Condition) => c !== condition)
                      );
                    }}
                  >
                    {<DeleteOutlineIcon fontSize="inherit" />}
                  </IconButton>
                </ListItem>
              );
            case 3:
              return (
                <ListItem
                  key={"condition-automation-" + index}
                  style={{
                    backgroundColor: "#EBEEEE",
                    border: "1px solid #D1DAE0",
                    boxSizing: "border-box",
                    margin: "3px 0px",
                    borderRadius: "5px",
                    height: "76px",
                    paddingLeft: "28px",
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={"assets/icons/sun 2.svg"}
                      style={{
                        marginRight: "10px",
                      }}
                      alt={"Clima"}
                    />
                  </ListItemIcon>
                  <ListItemText primary={"Clima"} />
                  <IconButton
                    aria-label="delete"
                    size="medium"
                    style={{
                      fontSize: "15px",
                      color: "#8B979F",
                    }}
                    onClick={() => {
                      if (conditions.length === 1) {
                        if (isToEdit) {
                          setWasAutomation(true);
                        }
                        setActions([]);
                        setAddTask(false);
                        setIsAutomation(false);
                        setIsScene(false);
                      }
                      setConditions(
                        conditions.filter((c: Condition) => c !== condition)
                      );
                    }}
                  >
                    {<DeleteOutlineIcon fontSize="inherit" />}
                  </IconButton>
                </ListItem>
              );
            case 6:
              return (
                <ListItem
                  key={"condition-automation-" + index}
                  style={{
                    backgroundColor: "#EBEEEE",
                    border: "1px solid #D1DAE0",
                    boxSizing: "border-box",
                    margin: "3px 0px",
                    borderRadius: "5px",
                    height: "76px",
                    paddingLeft: "28px",
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={"assets/icons/clock (1) 1.svg"}
                      style={{
                        marginRight: "10px",
                      }}
                      alt={"Agenda"}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    {" "}
                    Agenda -{" "}
                    {formatHours(
                      (condition.display as DisplayScheduleModel).time
                    )}
                    <p
                      style={{
                        margin: "0px",
                        opacity: 0.5,
                      }}
                    >
                      {days
                        .map((d: DateValue, i: number) => {
                          return (condition.display as DisplayScheduleModel)
                            .loops[i] !== "0"
                            ? d.value
                            : "";
                        })
                        .filter((d) => d !== "")
                        .join(", ")}
                    </p>
                  </ListItemText>

                  <IconButton
                    aria-label="delete"
                    size="medium"
                    style={{
                      fontSize: "15px",
                      color: "#8B979F",
                    }}
                    onClick={() => {
                      if (conditions.length === 1) {
                        if (isToEdit) {
                          setWasAutomation(true);
                        }
                        setActions([]);
                        setAddTask(false);
                        setIsAutomation(false);
                        setIsScene(false);
                      }
                      setConditions(
                        conditions.filter((c: Condition) => c !== condition)
                      );
                    }}
                  >
                    {<DeleteOutlineIcon fontSize="inherit" />}
                  </IconButton>
                </ListItem>
              );
            case 15:
              return (
                <ListItem
                  key={"condition-automation-" + index}
                  style={{
                    backgroundColor: "#EBEEEE",
                    border: "1px solid #D1DAE0",
                    boxSizing: "border-box",
                    margin: "3px 0px",
                    borderRadius: "5px",
                    height: "76px",
                    paddingLeft: "28px",
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={"assets/icons/help.png"}
                      style={{
                        marginRight: "10px",
                      }}
                      alt={"Localização"}
                    />
                  </ListItemIcon>
                  <ListItemText primary={"Localização"} />
                  <IconButton
                    aria-label="delete"
                    size="medium"
                    style={{
                      fontSize: "15px",
                      color: "#8B979F",
                    }}
                    onClick={() => {
                      if (conditions.length === 1) {
                        if (isToEdit) {
                          setWasAutomation(true);
                        }
                        setActions([]);
                        setAddTask(false);
                        setIsAutomation(false);
                        setIsScene(false);
                      }
                      setConditions(
                        conditions.filter((c: Condition) => c !== condition)
                      );
                    }}
                  >
                    {<DeleteOutlineIcon fontSize="inherit" />}
                  </IconButton>
                </ListItem>
              );
          }
        })}
    </List>
  );
};
export default ShowConditions;
