import {
  Container,
  IconButton,
  makeStyles,
  createStyles,
  TextField,
  InputAdornment,
  MenuItem,
  Button,
} from "@material-ui/core";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import history from "../../../Services/history";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import DeleteIcon from "@material-ui/icons/Delete";
import CancelSignatureContext from "../Context/CancelSignatureContext";
import GlobalContex from "../../../GlobalContext/GlobalContext";
import { postCancelSignaturePlan } from "../../../Services/PlansService";
import cryptoJS from "crypto-js";
import axios, { AxiosError } from "axios";

const useStyles = makeStyles(() =>
  createStyles({
    containerTitle: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      padding: "15px 40px",
    },
    header: {
      fontWeight: 700,
      color: "#3E5055",
      fontSize: "21px",
      lineHeight: "29px",
    },
    imgForm: {
      width: "297.83px",
      height: "280px",
    },
    textGreen: {
      color: "#6DCC9A",
      fontWeight: 400,
      fontSize: "21px",
      lineHeight: "29px",
      marginBottom: "20px",
    },
    textLabel: {
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: "18px",
    },
    containerText: {
      display: "flex",
      flexDirection: "column",
    },
    rowForm: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "20px",
    },
    button: {
      marginTop: "20px",
      backgroundColor: "#EF847E",
      color: "white",
      "&:hover": {
        backgroundColor: "#cc4b44",
      },
    },
  })
);

const FormCancelSignature: React.FunctionComponent = () => {
  const classes = useStyles();

  const { categories, enterpriseId } = useContext(CancelSignatureContext);
  const { showAlert } = useContext(GlobalContex);

  const [formData, setFormData] = useState({
    category: "",
    detail: "",
    password: "",
    repeatPassword: "",
  });

  const [isWorking, setIsWorking] = useState(false);

  const [visibilityPassword, setVisibilityPassword] = useState(false);

  useEffect(() => {
    return () => {
      setIsWorking(false);
    };
  }, []);

  const validateForm = () => {
    if (formData.password !== formData.repeatPassword)
      throw new Error("As senhas não conferem");
    if (formData.category.length === 0)
      throw new Error("Informe uma categoria");
  };

  const submitForm = async () => {
    setIsWorking(true);
    try {
      validateForm();
      await postCancelSignaturePlan(
        enterpriseId,
        cryptoJS.MD5(formData.password).toString(),
        formData.category,
        formData.detail
      );
      showAlert("Assinatura encerrada com sucesso!", "success");
      history.push("/");
    } catch (err) {
      const error = err as Error | AxiosError;
      let msg = '"Não foi possível realizar o cancelamento da assinatura';
      msg = axios.isAxiosError(error)
        ? error.response?.data.errors[0]?.message || msg
        : error.message || msg;
      showAlert(msg, "error");
    } finally {
      setIsWorking(false);
    }
  };

  const handleChange = (
    attrName: string,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [attrName]: event.target.value });
  };

  const handleChangeSelect = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, ["category"]: event.target.value });
  };

  return (
    <Container>
      <div className={classes.containerTitle}>
        <IconButton
          onClick={() => {
            history.push("/select-home");
          }}
        >
          <img src="/assets/icons/icon_return.svg" />
        </IconButton>
        <div className={classes.header}>Encerrar assinatura</div>
      </div>
      <div className={classes.containerText}>
        <img
          className={classes.imgForm}
          src="/assets/imgs/CancelSignature.svg"
          alt=""
        />
        <span className={classes.textGreen}>
          Lamentamos que você deseja encerrar assinatura
        </span>
        <span className={classes.textLabel}>
          Por favor, confirme seus dados:
        </span>
      </div>
      <div style={{ width: "70%" }}>
        <div className={classes.rowForm}>
          <TextField
            required
            label="Senha"
            variant="filled"
            id="txt-user-password"
            name="password"
            value={formData.password}
            fullWidth
            // error={controlValidatePassword}
            inputProps={{
              maxLength: 50,
            }}
            type={visibilityPassword ? "text" : "password"}
            // disabled={controlEmailInUse}
            onChange={(event) => {
              handleChange("password", event);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setVisibilityPassword(!visibilityPassword)}
                  >
                    {visibilityPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            required
            label="Repetir Senha"
            variant="filled"
            id="txt-user-repeatPassword"
            name="repeatPassword"
            value={formData.repeatPassword}
            fullWidth
            inputProps={{
              maxLength: 50,
            }}
            type={"password"}
            onChange={(event) => {
              handleChange("repeatPassword", event);
            }}
          />
        </div>

        <p
          style={{
            marginTop: "20px",
            marginBottom: "5px",
            fontWeight: 700,
            fontSize: "15px",
            color: "#3E5055",
          }}
        >
          Por favor, nos ajude a melhorar o IzyManager, indique qual o motivo do
          cancelamento
        </p>

        <TextField
          id="outlined-select-currency"
          select
          label="Categoria"
          placeholder="Por favor, selecione uma categoria"
          value={formData.category}
          onChange={handleChangeSelect}
          variant="outlined"
          style={{ width: "50%", marginTop: "20px", marginBottom: "20px" }}
        >
          {categories.map((option, index) => (
            <MenuItem key={index} value={option.id}>
              {option.description}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          required
          label="Diga-nos mais detalhes"
          variant="filled"
          id="txt-user-detail"
          name="detail"
          value={formData.detail}
          fullWidth
          inputProps={{
            maxLength: 600,
          }}
          multiline
          rows={5}
          onChange={(event) => {
            handleChange("detail", event);
          }}
        />

        <Button
          variant="contained"
          className={classes.button}
          startIcon={<DeleteIcon />}
          onClick={submitForm}
          disabled={isWorking}
        >
          {isWorking ? "Aguarde..." : "Encerrar assinatura"}
        </Button>
      </div>
    </Container>
  );
};

export default FormCancelSignature;
