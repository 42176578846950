import MiniDrawer from "../../Components/CustomMiniDrawer";
import EnterpriseEditPageProvider from "./context/EnterpriseEditPageProvider";
import ContainerEnterpriseEditPage from "./ContainerEnterpriseEditPage";

const EnterpriseEditPage: React.FunctionComponent = () => {
  return (
    <MiniDrawer showSideMenu={false} withoutSideMenu={true} bgColor={"#F7F7F7"}>
      <EnterpriseEditPageProvider>
        <ContainerEnterpriseEditPage />
      </EnterpriseEditPageProvider>
    </MiniDrawer>
  );
};

export default EnterpriseEditPage;
