import { useContext, useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import DeviceModel from "../../../../../../../../Models/DeviceModel";
import GlobalContex from "../../../../../../../../GlobalContext/GlobalContext";
import RoomModel from "../../../../../../../../Models/RoomModel";
import NotificationConfigContext from "../../../../../../Context/NotificationConfigContext";
import NotificationConfgFilter from "./NotificationConfgFilter";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "auto",
    },
    paper: {
      width: "250px",
      height: "100%",
      overflow: "auto",
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
    buttons: {
      width: "50px",
      marginTop: "100px",
    },
  })
);

function not(a: DeviceModel[], b: DeviceModel[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: DeviceModel[], b: DeviceModel[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const SelectionDevicestoAssociate: React.FunctionComponent = () => {
  const classes = useStyles();

  const { places } = useContext(GlobalContex);
  const {
    placeSelectedIndex,
    setDevicesSelectedToConfigNotification,
    spaceFilterSelected,
    filterNameValue,
  } = useContext(NotificationConfigContext);

  const [checked, setChecked] = useState<DeviceModel[]>([]);
  const [left, setLeft] = useState<DeviceModel[]>([]);
  const [right, setRight] = useState<DeviceModel[]>([]);
  const [totalDevices, setTotalDevices] = useState<number>(0);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(() => {
    setLeft(
      places[placeSelectedIndex]?.rooms
        .flatMap((room: RoomModel) => room.devices)
        .filter((d) => !right.includes(d))
    );
    setTotalDevices(
      places[placeSelectedIndex]?.rooms.flatMap(
        (room: RoomModel) => room.devices
      ).length
    );
  }, [placeSelectedIndex]);

  useEffect(() => {
    if (spaceFilterSelected !== 0) {
      const filteredDevices = places[placeSelectedIndex].rooms.filter(
        (room: RoomModel) => {
          if (parseInt(room.room_id) === spaceFilterSelected) {
            return room.devices;
          }
        }
      );

      setLeft(filteredDevices[0].devices);
    }
  }, [spaceFilterSelected]);

  const handleToggle = (value: DeviceModel) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setDevicesSelectedToConfigNotification([]);
    setRight(right.concat(left));
    setDevicesSelectedToConfigNotification(right.concat(left));

    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setDevicesSelectedToConfigNotification(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    setDevicesSelectedToConfigNotification(not(right, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setDevicesSelectedToConfigNotification([]);
    setRight([]);
  };

  const customList = (items: DeviceModel[], side: string) => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "350px",
      }}
    >
      {side === "left" && <p>Dispositivos disponiveis</p>}
      {side === "right" && <p>Dispositivos selecionados</p>}

      <Paper className={classes.paper}>
        <List component="div" role="list" dense>
          {items.map((value: DeviceModel, index: number) => {
            const labelId = `transfer-list-item-${value}-label`;

            if (side === "right") {
              return (
                <ListItem
                  style={{
                    backgroundColor: index % 2 === 0 ? "#F2F2F2" : "white",
                  }}
                  key={index}
                  role="listitem"
                  button
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${value.name}`} />
                </ListItem>
              );
            } else {
              if (
                value.name.toLowerCase().includes(filterNameValue.toLowerCase())
              ) {
                return (
                  <ListItem
                    style={{
                      backgroundColor: index % 2 === 0 ? "#F2F2F2" : "white",
                    }}
                    key={index}
                    role="listitem"
                    button
                    onClick={handleToggle(value)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={checked.indexOf(value) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={`${value.name}`} />
                  </ListItem>
                );
              }
            }
          })}
          <ListItem />
        </List>
      </Paper>
    </div>
  );

  return (
    <>
      <NotificationConfgFilter />
      <Grid
        container
        spacing={3}
        justifyContent="flex-start"
        alignItems="flex-start"
        className={classes.root}
      >
        {totalDevices > 0 && (
          <>
            <Grid item>{customList(left, "left")}</Grid>
            <Grid item>
              <Grid
                container
                direction="column"
                alignItems="center"
                className={classes.buttons}
              >
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleAllRight}
                  disabled={left.length === 0}
                >
                  ≫
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                >
                  &gt;
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                >
                  &lt;
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleAllLeft}
                  disabled={right.length === 0}
                >
                  ≪
                </Button>
              </Grid>
            </Grid>
            <Grid item>{customList(right, "right")}</Grid>
          </>
        )}
        {totalDevices === 0 && <p>Nenhum dispositivo encontrado</p>}
      </Grid>
      <div></div>
    </>
  );
};

export default SelectionDevicestoAssociate;
