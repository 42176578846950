import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import DeviceModel, {
  DeviceFunctionsModel,
} from "../../../../Models/DeviceModel";
import CustomTab, { CustomTabPanel } from "../../../CustomTab";
import DeviceMenu from "../../DeviceMenu";
import DeviceTabContent from "../../DeviceTabContent";
import OpenTabContent from "../../OpenTabContent";
import Device from "./Device";

interface Props {
  device: DeviceModel;
  specificationsDevice: DeviceFunctionsModel;
}

const EWS_101_I: React.FunctionComponent<Props> = ({
  device,
  specificationsDevice,
}: Props) => {
  const classes = useStyles();

  const [tabValue, setTabValue] = useState<number>(0);

  const handleChange = (newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <div className={classes.deviceContainer}>
      <CustomTab
        labels={["Dispositivo"]}
        ariaLabel={"menu em tabs para a página de disposito"}
        id="tab-device-option"
        value={tabValue}
        key={"tab-device-option"}
        changeTab={(value: number) => handleChange(value)}
      >
        <DeviceMenu
          device={device}
          specificationsDevice={specificationsDevice}
        />
      </CustomTab>
      <CustomTabPanel
        value={tabValue}
        index={0}
        padding={"0px"}
        height={"calc(100% - 50px)"}
      >
        <DeviceTabContent
          device={device}
          CustomComponent={Device}
          specificationsDevice={specificationsDevice}
        />
      </CustomTabPanel>
      <CustomTabPanel
        value={tabValue}
        index={1}
        padding={"0px"}
        height={"calc(100% - 50px)"}
      >
        <OpenTabContent device={device} />
      </CustomTabPanel>
    </div>
  );
};

export default EWS_101_I;

const useStyles = makeStyles(() => ({
  deviceContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
}));
