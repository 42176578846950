import MiniDrawer from "../../Components/CustomMiniDrawer";
import ListHomeComponent from "./Components/ListHomeComponent";
import SelectHomePageContextProvider from "./Context/SelectHomePageContextProvider";

const SelectHomePage: React.FunctionComponent = () => {
  return (
    <MiniDrawer showSideMenu={false} withoutSideMenu={true}>
      <SelectHomePageContextProvider>
        <ListHomeComponent />
      </SelectHomePageContextProvider>
    </MiniDrawer>
  );
};

export default SelectHomePage;
