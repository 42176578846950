/**
 * Código adaptado de:
 * https://github.com/blobfysh/react-dragswitch/blob/master/src/DragSwitch.tsx
 */
import React, { useRef, useState, useEffect } from "react";
import styles from "./style.module.css";
import clsx from "clsx";
import SwitchThumb from "./components/SwitchThumb";

enum SwitchState {
  Open = "open",
  Locking = "locking",
  Closed = "closed",
  Unlocking = "unlocking",
  OffLine = "offline",
}

interface DragSwitchDoorProps {
  className?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  focusShadow?: string;
  disabled?: boolean;

  loading: boolean;
  online: boolean;
  labelLocking: string;
  labelUnlocking: string;
  labelOpen: string;
  labelClosed: string;
  labelOffline: string;
  switchOnClick?: boolean; // Se true, permite o switch apenas com click, caso contrário apenas arrastando

  [props: string]: any;
}

const START_POSITION = 0;
const END_POSITION = 190;
const POSITION_OFFSET = 60; // Precisão do movimento para considerar a mudança de valor. Quanto menor, maior proximidade com o ponto final.
const THUMB_SIZE = 90;

const DOOR_IMG = "/assets/icons/door-icon.png";
const KEY_IMG = "/assets/icons/key-icon.png";
const OFF_LINE_IMG = "/assets/icons/off-line-icon.png";

const DragSwitchDoor: React.FunctionComponent<DragSwitchDoorProps> = ({
  checked,
  onChange,
  focusShadow,
  disabled,
  loading,
  online,
  labelLocking,
  labelUnlocking,
  labelOpen,
  labelClosed,
  labelOffline,
  switchOnClick,
  ...labelProps
}: DragSwitchDoorProps) => {
  const switchRef = useRef<HTMLDivElement>(null);
  const [Xpos, setXPos] = useState(checked ? START_POSITION : END_POSITION);
  const [isMouseDown, setMouseDown] = useState(false);
  const [lastMouseUpTime, setMouseUpTime] = useState(START_POSITION);
  const [hasOutline, setOutline] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [dragEnd, setDragEnd] = useState(0);

  const [switchState, setSwitchState] = useState<SwitchState>();

  const setPositionOpened = () => {
    setXPos(START_POSITION);
  };
  const setPositionClosed = () => {
    setXPos(END_POSITION);
  };

  const handleChange = () => {
    // prevent setting checked if user was just dragging
    if (Date.now() - dragEnd > 25) {
      handleChecked(!checked);
    }
  };

  const handleChecked = (newChecked: boolean) => {
    if (newChecked === checked) return;

    if (newChecked) {
      setSwitchState(SwitchState.Open);
    } else {
      setSwitchState(SwitchState.Closed);
    }

    onChange(newChecked);
  };

  const startDrag = (e: React.MouseEvent) => {
    e.preventDefault();

    setMouseDown(true);
    setOutline(true);
  };

  const onMouseUp = (e: MouseEvent) => {
    setMouseDown(false);
    setOutline(false);
    setMouseUpTime(Date.now());

    if (switchRef.current && dragging) {
      const mouseRelativePos =
        e.clientX - switchRef.current.getBoundingClientRect().left;

      setDragEnd(Date.now());
      if (mouseRelativePos >= END_POSITION - POSITION_OFFSET + THUMB_SIZE) {
        handleChecked(false);
        setPositionClosed();
      } else if (
        mouseRelativePos <=
        START_POSITION + POSITION_OFFSET + THUMB_SIZE
      ) {
        handleChecked(true);
        setPositionOpened();
      } else {
        // volta a posição inicial
        checked ? setPositionOpened() : setPositionClosed();
      }
      setDragging(false);
    }
  };

  const onMouseMove = (e: MouseEvent) => {
    if (loading || !online) {
      e.stopPropagation();
      return;
    }
    if (isMouseDown && !dragging) {
      setDragging(true);
    }

    if (switchRef.current && dragging) {
      const halfHandle = 90;
      const newPos =
        e.clientX - switchRef.current.getBoundingClientRect().left - halfHandle;
      if (newPos <= START_POSITION) {
        setXPos(START_POSITION);
      } else if (newPos > END_POSITION) {
        setXPos(END_POSITION);
      } else {
        setXPos(newPos);
      }
    }
  };

  useEffect(() => {
    // onMouseMove function relies on dragging and isMouseDown state
    window.addEventListener("mousemove", onMouseMove);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, [dragging, isMouseDown]);

  useEffect(() => {
    if (!online) {
      setSwitchState(SwitchState.OffLine);
      return;
    }

    if (loading) {
      setSwitchState(checked ? SwitchState.Unlocking : SwitchState.Locking);
      return;
    }
    setSwitchState(checked ? SwitchState.Open : SwitchState.Closed);
  }, [checked, loading, online]);

  useEffect(() => {
    // onMouseUp function relies on dragging state when setting value
    window.addEventListener("mouseup", onMouseUp);

    return () => {
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, [dragging]);

  const [textState, setTextState] = useState("");
  const [srcIcon, setSrcIcon] = useState("");
  useEffect(() => {
    switch (switchState) {
      case SwitchState.OffLine:
        setTextState(labelOffline);
        setSrcIcon(OFF_LINE_IMG);
        break;
      case SwitchState.Locking:
        setTextState(labelLocking);
        setSrcIcon(KEY_IMG);
        break;
      case SwitchState.Unlocking:
        setTextState(labelUnlocking);
        setSrcIcon(KEY_IMG);
        break;
      case SwitchState.Closed:
        setPositionClosed();
        setTextState(labelClosed);
        setSrcIcon(DOOR_IMG);
        break;
      case SwitchState.Open:
        setPositionOpened();
        setTextState(labelOpen);
        setSrcIcon(DOOR_IMG);
        break;
      default:
        setSrcIcon(DOOR_IMG);
    }
  }, [switchState]);

  return (
    <div
      {...labelProps}
      className={clsx([
        styles.switch,
        checked ? styles.switchChecked : styles.switchUnchecked,
        loading ? styles.switchLoading : "",
        online ? "" : styles.switchOffline,
      ])}
      ref={switchRef}
    >
      <div className={styles.switchBg}>
        <span className={styles.switchText}>{textState}</span>
      </div>
      <div
        className={clsx([styles.switchHandle, checked ? styles.isChecked : ""])}
        style={{
          transform: `translateX(${Xpos}px)`,
          WebkitTransition: dragging ? undefined : "transform .2s",
          MozTransition: dragging ? undefined : "transform .2s",
          transition: dragging ? undefined : "transform .2s",
          boxShadow: hasOutline
            ? focusShadow || "rgba(0,0,0,0.5) 0px 0px 2px 3px"
            : undefined,
          cursor: disabled || !online || loading ? "not-allowed" : "pointer",
          filter: disabled || !online || loading ? "brightness(0.9)" : "unset",
        }}
        onClick={(e) => {
          e.preventDefault();
          if (
            switchState != SwitchState.Open &&
            switchState != SwitchState.Closed
          ) {
            e.stopPropagation();
            return;
          }
          setOutline(false);
          if (!disabled && switchOnClick) handleChange();
        }}
        onMouseDown={disabled || !online || loading ? undefined : startDrag}
      >
        <SwitchThumb>
          <img src={srcIcon} />
        </SwitchThumb>
      </div>
      <input
        role="switch"
        aria-checked={checked}
        type="checkbox"
        defaultChecked={checked}
        onChange={handleChange}
        onFocus={() => {
          // prevent focus after user clicked/dragged switch
          if (Date.now() - lastMouseUpTime > 25) setOutline(true);
        }}
        onBlur={() => {
          setOutline(false);
        }}
        disabled={disabled}
        className={styles.switchInput}
      />
    </div>
  );
};

export default DragSwitchDoor;
