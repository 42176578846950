import { CircularProgress, makeStyles } from "@material-ui/core";
import { useContext } from "react";
import ConfirmCodeContext from "../../Context/ConfirmCodeContext";

const ConfirmCodeContent: React.FunctionComponent = () => {
  const classes = useStyles();

  const {
    inputRefs,
    isConfirmingCode,
    countdown,
    setNotReceived,
    clickReSend,
    handleChangeCodeValue,
    onKeyPressInputValue,
  } = useContext(ConfirmCodeContext);

  return (
    <div className={classes.content}>
      <div className={classes.title}>Insira o código de autenticação</div>
      {isConfirmingCode ? (
        <div className={classes.loadingContainer}>
          <CircularProgress color="secondary" size={80} />
        </div>
      ) : (
        <div className={classes.codeContainer}>
          {inputRefs.map((ref, index) => {
            return (
              <div className={classes.codeInput} key={"code-input-" + index}>
                <input
                  id={"code-input-" + index}
                  ref={ref.input}
                  type="text"
                  maxLength={1}
                  onChange={() => {
                    handleChangeCodeValue(index);
                  }}
                  onKeyDown={(event) => {
                    onKeyPressInputValue(event, index);
                  }}
                  disabled={isConfirmingCode}
                />
              </div>
            );
          })}
        </div>
      )}
      <div className={classes.text}>
        O código de verificação foi enviado para seu endereço de e-mail:{" "}
        <strong>seuendereço@gmail.com</strong> -{" "}
        {countdown > 0 ? (
          "Reenviar (" + countdown + ")"
        ) : (
          <strong
            id={"resend-btn"}
            className={classes.enableResend}
            onClick={clickReSend}
          >
            Reenviar
          </strong>
        )}
      </div>
      <div
        id={"not-received-btn"}
        className={classes.notReceive}
        onClick={() => {
          setNotReceived(true);
        }}
      >
        Não recebeu um código?
      </div>
    </div>
  );
};

export default ConfirmCodeContent;

const useStyles = makeStyles(() => ({
  content: {
    padding: "150px",
  },
  title: {
    fontWeight: 600,
    fontSize: "28px",
    lineHeight: "38px",
    color: "#3E5055",
    marginBottom: "45px",
  },
  loadingContainer: {
    display: "flex",
    marginBottom: "100px",
    justifyContent: "center",
  },
  codeContainer: {
    display: "flex",
    marginBottom: "100px",
  },
  codeInput: {
    margin: "0px 5px",
    height: "100px",
    width: "100px",
    background: "#EBEEEE",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& input": {
      fontSize: "28px",
      height: "100px",
      width: "100px",
      border: "none",
      background: "transparent",
      textAlign: "center",
      "&:focus-visible": {
        outline: "none",
      },
    },
  },
  text: {
    fontWeight: 400,
    fontSize: "21px",
    lineHeight: "29px",
    color: "#677884",
    marginBottom: "20px",
  },
  enableResend: {
    color: "#00A335",
    cursor: "pointer",
  },
  notReceive: {
    fontWeight: 600,
    fontSize: "21px",
    lineHeight: "29px",
    textAlign: "center",
    color: "#00A335",
    cursor: "pointer",
  },
}));
