import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

type BreadCrumbItemProps = {
  isCurrentPage?: boolean;
  children: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    typography: {
      color: "#888",
    },
    activeItem: {
      fontWeight: "bold",
    },
  })
);

const BreadcrumbItem: React.FunctionComponent<BreadCrumbItemProps> = ({
  isCurrentPage = false,
  children,
}: BreadCrumbItemProps) => {
  const classes = useStyles();

  return (
    <Typography
      className={`${classes.typography} ${
        isCurrentPage ? classes.activeItem : ""
      }`}
    >
      {children}
    </Typography>
  );
};

export default BreadcrumbItem;
