import { MenuItem, FormControl, Select } from "@material-ui/core";
import { getCommandLabel } from "../../../Utils/DeviceConfiguration";

interface Props {
  command: any;
  value: any;
  onChange: any;
  device: any;
  values?: any;
}

const EnumInput: React.FunctionComponent<Props> = (props: Props) => {
  let values = props.values || props.command.values;
  if (typeof values == "string") values = JSON.parse(values);

  return (
    <FormControl variant="outlined">
      <Select
        value={props.value || ""}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
      >
        {values.range.map((t: string, index: number) => {
          return (
            <MenuItem value={t} key={index}>
              {getCommandLabel(props.device, t)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default EnumInput;
