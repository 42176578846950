import axios from "axios";
import { logout } from "./AuthService";
import TokenService from "./TokenService";
import jwt_decode from "jwt-decode";
import { UserLogin } from "../Models/UserLogin";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
  (config) => {
    try {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
    } catch (error) {
      console.log(error);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    const dataError = err.response;

    if (originalConfig.url !== "/auth/login" && err.response) {
      if (
        typeof dataError.data.errors === "object" &&
        dataError.data.errors[0].key === "user.not.permission.in.home"
      ) {
        logout();
        return;
      }

      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await api.post<UserLogin>("/auth/refreshToken", {
            refreshToken: TokenService.getLocalRefreshToken(),
          });

          const { token } = rs.data;

          const decoded = jwt_decode(token) as UserLogin;
          const oldUser = TokenService.getUser();
          oldUser.userInfo = decoded.userInfo;
          TokenService.setUser(oldUser);

          if (rs.status === 400) {
            return;
          } else {
            TokenService.updateLocalAccessToken(token);
            return api(originalConfig);
          }
        } catch (_error) {
          logout();
          return Promise.reject(_error);
        }
      } else if (err.response.status === 402) {
        logout();
      } else if (err.response.status === 403) {
        logout();
      }
    }

    return Promise.reject(err);
  }
);

export default api;
