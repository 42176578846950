import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 20px 100px;
  overflow: auto;
`;

type ContentRowProps = {
  display?: string | undefined;
  justifyContent?: string | undefined;
  marginBottom?: string | undefined;
};

export const ContentRow = styled.div<ContentRowProps>`
  display: ${(props) => (props.display ? props.display : "")};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : ""};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
`;
