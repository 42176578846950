import { useEffect, useState } from "react";
import StrengthMeter from "./StrengthMeter";
import StrengthBar from "./StrengthBar";

interface PasswordStrengthIndicatorProps {
  password: string;
  onChangeScore?: (score: number, validated: boolean) => void;
}

export interface PasswordStrengthRules {
  hasMinLength: boolean;
  hasNumber: boolean;
  hasLowcaseLetter: boolean;
  hasUppercaseLetter: boolean;
  hasSpecialChar: boolean;
}

const PasswordStrengthIndicator: React.FunctionComponent<
  PasswordStrengthIndicatorProps
> = ({ password, onChangeScore }: PasswordStrengthIndicatorProps) => {
  const [rules, setRules] = useState<PasswordStrengthRules>({
    hasMinLength: false,
    hasNumber: false,
    hasLowcaseLetter: false,
    hasUppercaseLetter: false,
    hasSpecialChar: false,
  });
  const [score, setScore] = useState(0);

  const MIN_LENGTH = 8;
  const MAX_SCORE = 4;

  const passwordStrength = (passwordValue: string) => {
    const passwordLength = passwordValue.length;
    const lowcaseRegExp = /[a-z]/;
    const uppercaseRegExp = /[A-Z]/;
    const numberRegExp = /[0-9]/;
    const strongRegExp = /[#?!@$%&*-]/;
    // const whitespaceRegExp = /^$|\s+/;

    setRules({
      hasMinLength: passwordLength >= MIN_LENGTH,
      hasLowcaseLetter: lowcaseRegExp.test(password),
      hasUppercaseLetter: uppercaseRegExp.test(password),
      hasNumber: numberRegExp.test(password),
      hasSpecialChar: strongRegExp.test(password),
    });
  };

  useEffect(() => {
    passwordStrength(password);
  }, [password]);

  useEffect(() => {
    let score = 0;
    Object.entries(rules).forEach(([, value]) => {
      if (value) {
        score = score + 1;
      }
    });
    if (score > 0) {
      const scoreTemp = (score * MAX_SCORE) / Object.entries(rules).length;
      score = Math.max(Math.trunc(scoreTemp), 1); // se alguma regra aplicada, score mínimo é 1
    }
    setScore(score);
  }, [rules]);

  useEffect(() => {
    if (onChangeScore) onChangeScore(score, score == MAX_SCORE);
  }, [score]);

  return (
    <>
      <StrengthBar score={score} />
      <StrengthMeter rules={rules} minLength={MIN_LENGTH} />
    </>
  );
};

export default PasswordStrengthIndicator;
