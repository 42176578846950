import { AppBar, makeStyles, Tab, Tabs } from "@material-ui/core";
import { ReactNode } from "react";
interface Props {
  labels: string[];
  ariaLabel: string;
  id: string;
  value: any;
  changeTab: any;
  elevation?: number;
  children?: ReactNode;
  roundedTop?: string;
  roundedBottom?: string;
  backgroundColor?: string;
  centered?: boolean;
  disabledLabels?: boolean[];
  variant?: "scrollable" | "standard" | "fullWidth" | undefined;
}

const useStyles = makeStyles(() => ({
  tab: {
    textTransform: "none",
    fontWeight: 600,
    fontSize: "15px",
    color: "#3E5055",
    "&.Mui-disabled": {
      opacity: 1,
      color: "#BDBDBD",
    },
  },
  appBar: {
    marginBottom: "1px",
  },
  notSelected: {
    color: "#8B979F",
    opacity: 1,
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  value: any;
  index: any;
  backgroundColor?: string;
  dir?: string;
  roundedTop?: string;
  roundedBottom?: string;
  padding?: string;
  centered?: boolean;
  id?: string;
  height?: string;
}

export const CustomTabPanel: React.FunctionComponent<TabPanelProps> = ({
  children,
  value,
  index,
  backgroundColor = "#FFF",
  roundedTop = "0px",
  roundedBottom = "0px",
  centered = false,
  id,
  padding = "10px",
  height,
  ...other
}: TabPanelProps) => {
  return (
    <div
      style={{
        backgroundColor: backgroundColor,
        borderRadius: `${roundedTop} ${roundedTop} ${roundedBottom} ${roundedBottom}`,
        padding: padding,
        display: centered ? "flex" : "",
        alignItems: centered ? "center" : "",
        justifyContent: centered ? "center" : "start",
        maxWidth: "100%",
        height: height ? height : "",
      }}
      role="tabpanel"
      hidden={value !== index}
      id={id ? id : `wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

const CustomTab: React.FunctionComponent<Props> = ({
  labels,
  ariaLabel,
  id,
  elevation = 0,
  value,
  changeTab,
  children,
  backgroundColor = "#FFF",
  roundedTop = "0px",
  roundedBottom = "0px",
  centered = false,
  disabledLabels,
  variant = "scrollable",
}: Props) => {
  const classes = useStyles();
  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    changeTab(newValue);
  };

  return (
    <AppBar
      position="static"
      id={id}
      elevation={elevation}
      className={classes.appBar}
      style={{
        backgroundColor: backgroundColor,
        borderRadius: `${roundedTop} ${roundedTop} ${roundedBottom} ${roundedBottom}`,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: centered ? "center" : "space-between",
        }}
      >
        <Tabs
          indicatorColor="secondary"
          value={value}
          onChange={handleChange}
          aria-label={ariaLabel}
          centered={centered}
          orientation={"horizontal"}
          scrollButtons={"auto"}
          textColor={"secondary"}
          variant={variant}
          TabIndicatorProps={{
            style: {
              height: "4px",
              background: "#00A335",
            },
          }}
        >
          {labels.map((label: string, index: number) => (
            <Tab
              label={label}
              wrapped
              id={`appbar-${id}-tab-${index}`}
              value={index}
              key={`appbar-${id}-tab-${index}`}
              disabled={disabledLabels ? disabledLabels[index] : false}
              className={
                classes.tab + " " + (index !== value ? classes.notSelected : "")
              }
            />
          ))}
        </Tabs>
        <div>{children}</div>
      </div>
    </AppBar>
  );
};

export default CustomTab;
