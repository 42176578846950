import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

const diasSemana = [
  "Domingo",
  "Segunda-feira",
  "Terça-feira",
  "Quarta-feira",
  "Quinta-feira",
  "Sexta-feira",
  "Sábado",
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Button = styled.button`
  background-color: #ebeeee;
  border: none;
  width: 70%;
  padding: 10px;
  border-radius: 3px;
`;

const Label = styled.label`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  /* Cinza tipografia */
  color: #3e5055;
`;

interface PropsComponent {
  callback: (e: Date) => void;
  label?: string;
}

const CustomInputDate: React.FunctionComponent<PropsComponent> = ({
  callback,
  label = "Dia",
}: PropsComponent) => {
  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container>
      <Label>{label}</Label>
      <Button
        className="example-custom-input"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {diasSemana[startDate.getDay()] +
          ", " +
          format(startDate, "dd") +
          " de " +
          format(startDate, "MMMM", { locale: ptBR })}
      </Button>
      {isOpen && (
        <DatePicker
          selected={startDate}
          onChange={(e: Date) => {
            callback(e);
            setIsOpen(!isOpen);
            setStartDate(e);
          }}
          inline
        />
      )}
    </Container>
  );
};

export default CustomInputDate;
