import { Divider } from "@material-ui/core";
import DrawerEnvironments from "./components/DrawerEnvironments";
import EnvironmentContainer from "./components/EnvironmentContainer";
import ToolbarEnvironment from "./components/ToolbarEnvironment";
import EnvironmentsProvider from "./context/EnvironmentsProvider";

const Environments: React.FunctionComponent = () => {
  return (
    <EnvironmentsProvider>
      <ToolbarEnvironment />
      <Divider />
      <DrawerEnvironments />
      <EnvironmentContainer />
    </EnvironmentsProvider>
  );
};

export default Environments;
