import React, { useContext, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import MuiTab from "@material-ui/core/Tab";

import DeviceModel from "../../../Models/DeviceModel";

import TabPasswords from "./components/TabPasswords";
import TabFamilyAccounts from "./components/TabFamilyAccounts";
import styled from "styled-components";

import * as S from "./styled";
import GlobalContext from "../../../GlobalContext/GlobalContext";

interface Props {
  device: DeviceModel;
}

export const Tab = styled(MuiTab)`
  text-transform: none;
  color: #3e5055;
  font-weight: 600;

  &.Mui-selected {
    border: 1px solid #d1dae0;
    border-bottom: none;
    border-radius: 5px 5px 0px 0px;
    background-color: #ebeeee;
  }
`;

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const OpenTabContent: React.FunctionComponent<Props> = ({ device }: Props) => {
  const { drawerApplicationContextComponent } = useContext(GlobalContext);

  const [tabValue, setTabValue] = useState<number>(
    drawerApplicationContextComponent?.tabFamilyOrDynamic || 0
  );

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <S.Container>
      <Tabs
        variant="fullWidth"
        value={tabValue}
        onChange={handleChange}
        aria-label="Abas de controle de abertura de fechaduras"
        TabIndicatorProps={{
          style: { display: "none" },
        }}
      >
        <Tab label="Contas familiares" {...a11yProps(0)} />
        <Tab label="Senhas dinâmicas" {...a11yProps(1)} />
      </Tabs>
      <TabFamilyAccounts index={0} value={tabValue} deviceId={device.id} />
      <TabPasswords index={1} value={tabValue} deviceId={device.id} />
    </S.Container>
  );
};

export default OpenTabContent;
