import React, { useContext, useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import {
  Button,
  IconButton,
  Divider,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  FormControl,
} from "@material-ui/core";
import DrawerProps from "../../../../Types/DrawerProps";
import TextField from "@material-ui/core/TextField";
import UsersPageContext from "../../Context/UsersPageContext";
import clsx from "clsx";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AddIcon from "@material-ui/icons/Add";
import UserGroupsModel from "../../../../Models/UserGroupsModel";
import DeleteIcon from "@material-ui/icons/Delete";
import history from "../../../../Services/history";
import CustomBlockResource from "../../../../Components/CustomBlockResource";
import { CircularProgress } from "@material-ui/core";
import { UserGroupPaginated } from "../../../../Models/UserModel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      backgroundColor: "#FFF !important",
      maxHeight: "100vh",
    },
    fullList: {
      width: "auto",
    },
    container: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "10px 123px",
    },
    row: {
      display: "flex",
      padding: "25px",
      justifyContent: "start",
      alignItems: "center",
    },
    automationRow: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
    },
    container2: {
      display: "flex",
      padding: "10px 0px",
      height: "90vh",
    },
    drawerPaper: {
      backgroundColor: "#FFF !important",
      width: "500px",
    },
    paper: {
      background: "blue",
      width: "700px",
    },
    root: {
      width: "100%",
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    button: {
      "&:hover": {
        backgroundColor: theme.palette.error.main,
        color: "#FFF",
      },
    },
    titulo: {
      fontSize: "18px",
      fontWeight: "bold",
      marginBottom: "0px",
    },
    subtitulo: {
      fontSize: "15px",
      color: "#8B979F",
      marginTop: "0px",
    },
    paperGrid: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      fontSize: "12px",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      padding: "15px",
      marginRight: "10px",
      marginBottom: "10px",
      color: "#8B979F",
      fontSize: "15px",
    },
    buttonBottom: {
      textTransform: "none",
      fontSize: "15px",
      fontWeight: "bold",
    },
    options: {
      fontSize: "12px",
    },
    margin: {
      margin: theme.spacing(1),
    },
    buttomAdd: {
      borderRadius: "50%",
      width: "29px !important",
      height: "29px !important",
      minWidth: "0px",
      backgroundColor: "#EBEEEE",
    },
    containerButtonAdd: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "25px",
    },
    operatorInfo: {
      color: "#8B979F",
      fontSize: "15px",
      marginTop: "20px",
    },
    operatorGroupInfo: {
      color: "green",
      "&:hover": {
        color: "#056608",
      },
    },
  })
);

const anchor: DrawerProps = "right";

const DrawerUsersPage: React.FunctionComponent = () => {
  const classes = useStyles();
  const {
    isToggleOpen,
    setIsToggleOpen,
    saveUser,
    isSaving,
    handleChange,
    handleChangeRepetEmail,
    handleChangeUserPassword,
    handleChangeRepetPassword,
    handleChangeUserGroups,
    resetForm,
    blurEmail,
    isEdit,
    userEdit,
    userPassword,
    repeatPassword,
    repeatEmail,
    mustShow,
    selectUserGroup,
    controlValidateEmail,
    controlValidateName,
    controlValidatePassword,
    controlEmailInUseOnIzyManager,
    controlEmailInUse,
    syncUser,
    userTypes,
    userGroups,
    setSelectUserGroup,
  } = useContext(UsersPageContext);

  const localHome = localStorage.getItem("homeID");

  const saveUserSelect = (e: React.MouseEvent<HTMLButtonElement>) => {
    userEdit.password = userPassword;
    e.preventDefault();
    if (controlEmailInUse) {
      syncUser();
    } else {
      saveUser(e);
    }
  };

  const transitionDuration = 700;

  const [optionSelected, setOptionSelected] = useState<string>("");
  const [option, setOption] = useState<UserGroupsModel>({} as UserGroupsModel);

  useEffect(() => {
    userGroups.forEach((u) => (u.users = []));
  }, [isToggleOpen]);

  const handleDelete = (ugSelected: UserGroupPaginated) => {
    setSelectUserGroup([
      ...selectUserGroup.filter(
        (ug) => ug.user_group_id !== ugSelected.user_group_id
      ),
    ]);
  };

  const goToOperatorsGroup = () => {
    history.push("/operators");
  };

  const handleChangeGroup = (event: React.ChangeEvent<{ value: unknown }>) => {
    const id = event.target.value as number;
    const selected_ug = userGroups.find((ug) => ug.user_group_id === id);

    if (selected_ug) {
      setOptionSelected(id.toString());
      setOption(selected_ug);
    }
  };

  const addGroup = () => {
    handleChangeUserGroups(option, true);
    setOptionSelected("");
  };

  const list = (anchor: DrawerProps) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
    >
      <div className={classes.row}>
        <IconButton
          id="btn-users-back"
          aria-label="delete"
          size="small"
          style={{
            fontSize: "15px",
            color: "#8B979F",
          }}
          onClick={() => {
            setIsToggleOpen(false);
            resetForm();
          }}
          onKeyDown={() => {
            setIsToggleOpen(false);
            resetForm();
          }}
        >
          <ArrowBackIosIcon fontSize="inherit" />
        </IconButton>
        <p
          style={{
            margin: "0px",
            fontSize: "15px",
            color: "#8B979F",
          }}
        >
          Voltar
        </p>
      </div>
      <Divider />
    </div>
  );

  const select = () => {
    return (
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="operator-group-label">Grupo de operador</InputLabel>
        <Select
          id="slt-operator-group"
          fullWidth
          labelId="operator-group-label"
          value={optionSelected}
          label="Grupo de operador"
          onChange={handleChangeGroup}
          disabled={
            !userGroups.filter((ug) => {
              return !selectUserGroup.some(
                (ug2) => ug2.user_group_id === ug.user_group_id
              );
            }).length
          }
        >
          {userGroups
            .filter((ug) => {
              return !selectUserGroup.some(
                (ug2) => ug2.user_group_id === ug.user_group_id
              );
            })
            .map((usergroup) => (
              <MenuItem
                key={usergroup.user_group_id}
                value={usergroup.user_group_id}
              >
                {usergroup.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <>
      <React.Fragment key={anchor}>
        <Drawer
          transitionDuration={{
            enter: transitionDuration,
            exit: transitionDuration,
          }}
          classes={{
            paper: classes.drawerPaper,
          }}
          PaperProps={{ elevation: 9 }}
          anchor={anchor}
          open={isToggleOpen}
        >
          <form autoComplete="off">
            {list(anchor)}
            <div
              style={{
                position: "relative",
                cursor: "pointer",
              }}
            >
              {userEdit.block && (
                <CustomBlockResource
                  icon={"icon_padlock"}
                  title={"Usuário bloqueado"}
                  direction={"column"}
                  subtitle="Você atingiu o limite de usuários
                  do seu plano. Exclua usuários ou
                  contrate um novo plano"
                />
              )}
              <div
                style={{
                  paddingLeft: "60px",
                  paddingRight: "60px",
                }}
              >
                <p className={classes.titulo}>
                  {isEdit ? "Editar usuário" : "Adicionar usuário"}
                </p>

                <p className={classes.subtitulo}>
                  Preencha as informações necessárias.
                </p>
                <br />

                {controlEmailInUse &&
                  !controlEmailInUseOnIzyManager &&
                  !isEdit && (
                    <>
                      {" "}
                      <InputLabel>
                        O email já possui cadastro, defina apenas uma permissão
                        para o usuário acessar com sua senha previamente
                        cadastrada.
                      </InputLabel>{" "}
                      <br />{" "}
                    </>
                  )}
                {controlEmailInUseOnIzyManager && (
                  <>
                    {" "}
                    <InputLabel>
                      O usuário já está vinculado a este domínio.
                    </InputLabel>{" "}
                    <br />{" "}
                  </>
                )}

                {!isEdit && (
                  <TextField
                    required
                    label="E-mail"
                    variant="filled"
                    type="email"
                    value={userEdit.email}
                    fullWidth
                    inputProps={{
                      maxLength: 50,
                    }}
                    onChange={(event) => {
                      handleChange("email", event);
                    }}
                    error={controlValidateEmail}
                    onBlur={blurEmail}
                  />
                )}

                {!isEdit && !controlEmailInUse && mustShow && (
                  <div>
                    <br />
                    <br />
                    <TextField
                      required
                      label="Repita o E-mail"
                      variant="filled"
                      type="email"
                      value={repeatEmail}
                      fullWidth
                      inputProps={{
                        maxLength: 50,
                      }}
                      onChange={(event) => {
                        handleChangeRepetEmail(event);
                      }}
                      error={controlValidateEmail}
                    />
                    <br />
                    <br />
                  </div>
                )}
                {(isEdit || (!controlEmailInUse && mustShow)) && (
                  <TextField
                    required
                    label="Nome de usuário"
                    variant="filled"
                    id="txt-user-name"
                    value={userEdit.name}
                    fullWidth
                    inputProps={{
                      maxLength: 50,
                    }}
                    onChange={(event) => {
                      handleChange("name", event);
                    }}
                    error={controlValidateName}
                  />
                )}

                {!isEdit && !controlEmailInUse && mustShow && (
                  <div>
                    <br />
                    <p
                      className={classes.titulo}
                      style={{
                        marginBottom: "15px",
                        marginTop: "30px",
                      }}
                    >
                      Informações de segurança
                    </p>
                    <br />
                    <TextField
                      required
                      id="txt-user-password"
                      label="Senha"
                      variant="filled"
                      value={userPassword}
                      fullWidth
                      error={controlValidatePassword}
                      inputProps={{
                        maxLength: 50,
                      }}
                      type="password"
                      disabled={controlEmailInUse}
                      onChange={(event) => {
                        handleChangeUserPassword(event);
                      }}
                    />

                    <br />
                    <br />
                    <TextField
                      required
                      label="Repita a senha"
                      variant="filled"
                      id="txt-user-repeat-password"
                      value={repeatPassword}
                      fullWidth
                      error={controlValidatePassword}
                      inputProps={{
                        maxLength: 50,
                      }}
                      type="password"
                      disabled={controlEmailInUse}
                      onChange={(event) => {
                        handleChangeRepetPassword(event);
                      }}
                    />
                  </div>
                )}

                {(isEdit ||
                  (controlEmailInUse && !controlEmailInUseOnIzyManager) ||
                  (!controlEmailInUse && mustShow)) && (
                  <div>
                    <p
                      className={classes.titulo}
                      style={{
                        marginBottom: "15px",
                        marginTop: "30px",
                      }}
                    >
                      Informações administrativas
                    </p>
                    <br />

                    <FormControl
                      variant="outlined"
                      fullWidth
                      style={{ backgroundColor: "white" }}
                    >
                      <InputLabel id="role-label">Perfil</InputLabel>
                      <Select
                        id="slt-administrative-informations"
                        labelId="role-label"
                        fullWidth
                        label="Perfil"
                        value={userEdit?.userType?.id}
                        required
                        onChange={(event) => {
                          if (event.target.value === 1) {
                            setSelectUserGroup([]);
                          }
                          handleChange("userType", event);
                        }}
                      >
                        {userTypes.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {userEdit?.userType?.id === 2 && (
                      <p className={classes.operatorInfo}>
                        Você pode atribuir o usuário a um grupo de operadores.
                        Você pode criar um grupo e gerenciá-lo na tela de{" "}
                        <a
                          onClick={goToOperatorsGroup}
                          className={classes.operatorGroupInfo}
                        >
                          Grupos de Operadores
                        </a>
                      </p>
                    )}

                    {userEdit?.userType?.id === 2 && (
                      <div
                        style={{
                          marginTop: "25px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        {select()}
                        <div className={classes.containerButtonAdd}>
                          <Button
                            id="btn-add-user"
                            disabled={
                              !userGroups.filter(
                                (usergroupFilter: UserGroupsModel) =>
                                  !selectUserGroup.includes(usergroupFilter)
                              ).length || optionSelected === ""
                            }
                            onClick={addGroup}
                            classes={{ root: classes.buttomAdd }}
                          >
                            <AddIcon
                              htmlColor={
                                optionSelected === "" ? "#8B979F" : "#6DCC9A"
                              }
                            />
                          </Button>
                          <p
                            style={{
                              margin: "0px",
                              color:
                                optionSelected === "" ? "#8B979F" : "#6DCC9A",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                          >
                            Adicionar
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div
                  style={{
                    margin: "25px 0px",
                  }}
                >
                  {(isEdit ||
                    (controlEmailInUse && !controlEmailInUseOnIzyManager) ||
                    (!controlEmailInUse && mustShow)) &&
                    selectUserGroup?.map((group) => {
                      if (group.home_id === localHome) {
                        return (
                          <Chip
                            key={group.user_group_id}
                            label={group.name}
                            className={classes.chip}
                            deleteIcon={<DeleteIcon />}
                            onDelete={() => {
                              handleDelete(group);
                              handleChangeUserGroups(group, false);
                            }}
                          />
                        );
                      }
                      return false;
                    })}
                </div>
              </div>
              <div
                style={{
                  bottom: "auto",
                  display: "flex",
                  flex: 1,
                  marginTop: "50px",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                    padding: "20px",
                  }}
                >
                  {(!controlEmailInUseOnIzyManager || isEdit) && (
                    <Button
                      id="btn-save-user"
                      autoFocus
                      color="secondary"
                      variant="contained"
                      disabled={
                        isSaving ||
                        (userEdit.userType.id !== 1 &&
                          userEdit.userType.id !== 2)
                      }
                      className={classes.buttonBottom}
                      onClick={saveUserSelect}
                      type="submit"
                    >
                      {isSaving && (
                        <CircularProgress size={24} color="primary" />
                      )}
                      Salvar
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </Drawer>
      </React.Fragment>
    </>
  );
};

export default DrawerUsersPage;
