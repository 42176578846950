import { ListItemIcon, ListItemText } from "@material-ui/core";
import styled from "styled-components";

export const ListItemTextStyled = styled(ListItemText)`
  text-align: right;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #8b979f;
`;

export const ListItemIconStyled = styled(ListItemIcon)`
  justify-content: center;
`;
