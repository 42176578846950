import React from "react";
import ButtonGeneric, { ButtonGenericProps } from "../ButtonGeneric";

const ButtonFilled: React.FunctionComponent<ButtonGenericProps> = ({
  children,
  ...props
}) => (
  <ButtonGeneric variant={"contained"} {...props}>
    {children}
  </ButtonGeneric>
);

export default ButtonFilled;
