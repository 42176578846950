import {
  createStyles,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
} from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    stepper: {
      maxWidth: "600px",
      width: "100%",
      backgroundColor: "transparent",

      "& .MuiStepIcon-active": {
        width: "49px",
        height: "49px",
        color: "#6ECCE0",
        "& .MuiStepIcon-text": {
          color: "#FFF",
          fill: "#FFF",
        },
        marginTop: "-12px",
      },

      "& .MuiStepConnector-active .MuiStepConnector-line": {
        borderColor: "#6ECCE0",
      },

      "& .MuiStep-completed": {
        "& .MuiStepIcon-root.MuiStepIcon-completed": {
          width: "36px",
          height: "36px",
          marginTop: "-5px",
          backgroundColor: "#6ECCE0",
          borderRadius: "50%",
          color: "#6ECCE0",
        },
        "& .MuiStepConnector-completed .MuiStepConnector-line": {
          borderColor: "#6ECCE0",
        },
      },

      "& .Mui-disabled": {
        "& .MuiStepIcon-root": {
          color: "#8B979F",
          width: "36px",
          height: "36px",
          marginTop: "-5px",

          "& .MuiStepIcon-text": {
            display: "none",
          },
        },
      },

      "& .MuiStepLabel-label": {
        color: "#BDBDBD",
        fontSize: "15px",
      },

      "& .MuiStepLabel-active": {
        color: "#677884",
        fontWeight: 700,
        fontSize: "15px",
      },
    },
  })
);

type CustomStepperProps = {
  steps: string[];
  activeStep: number;
};

const CustomStepper: React.FunctionComponent<CustomStepperProps> = ({
  steps,
  activeStep,
}: CustomStepperProps) => {
  const classes = useStyles();

  return (
    <Stepper
      className={classes.stepper}
      activeStep={activeStep}
      alternativeLabel
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default CustomStepper;
