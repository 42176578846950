import { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import EnvironmentsList from "../EnvironmentsList";
import EnvironmentsContext from "../../context/EnvironmentsContext";
import CustomConfirmDialog from "../../../../Components/CustomConfirmDialog";
import Infos from "../Infos";
import DeviceModel from "../../../../Models/DeviceModel";
import SubDevicesList from "../SubDevicesList";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  paper: {
    height: "100%",
  },
  paperNoDevice: {
    height: "100%",
    display: "flex",
  },
  paper2: {
    width: "100%",
    height: "100%",
    borderRadius: "30px",
    margin: "0px",
    backgroundColor: "#EBEEEE",
  },
  page: {
    paddin: "20px",
  },
  body: {
    minHeight: `calc(100vh - 127px) !important`,
  },
  root: {
    flexGrow: 1,
    maxWidth: "100% !important",
  },
  loadingEnv: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    minHeight: "100px",
  },
}));

const EnvironmentContainer: React.FunctionComponent = () => {
  const classes = useStyles();

  const {
    openDialog,
    handleCloseModalDelete,
    removeEnvironmentContext,
    allDevices,
    environments,
    devicesAssociated,
    loadingEnvironments,
  } = useContext(EnvironmentsContext);

  const { allDevicesSelected, devices } = useContext(GlobalContex);

  const [total, seTtotal] = useState<number>(0);
  const [totalOnline, setTotalOnline] = useState<number>(0);
  const [totalOffline, setTotalOffline] = useState<number>(0);
  const [totalDevices, seTtotalDevices] = useState<number>(0);
  const [totalOnlineDevices, setTotalOnlineDevices] = useState<number>(0);
  const [totalOfflineDevices, setTotalOfflineDevices] = useState<number>(0);

  useEffect(() => {
    const devicesList = allDevicesSelected ? devices : devicesAssociated;

    const total: number = devicesList.length;

    const totalOnline: number = devicesList.filter(
      (d: DeviceModel) => d.online
    ).length;
    const totalOffline: number = devicesList.filter(
      (d: DeviceModel) => !d.online
    ).length;

    let resulTotal = 0;
    let resultOnline = 0;
    let resultOffline = 0;

    if (total) {
      resulTotal = (total / total) * 100;
      resultOnline = (totalOnline / total) * 100;
      resultOffline = (totalOffline / total) * 100;
    }

    seTtotalDevices(total);
    setTotalOnlineDevices(totalOnline);
    setTotalOfflineDevices(totalOffline);
    seTtotal(resulTotal);
    setTotalOnline(resultOnline);
    setTotalOffline(resultOffline);
  }, [environments, allDevices, devicesAssociated]);

  return (
    <>
      <Grid container spacing={0} className={classes.body}>
        <Grid item xs={9} style={{ padding: "15px" }}>
          <Grid container spacing={2}>
            <Grid item xs={3} className={classes.root}>
              <Paper className={classes.paper2}>
                {loadingEnvironments ? (
                  <div className={classes.loadingEnv}>
                    <CircularProgress color="secondary" />
                  </div>
                ) : (
                  <>
                    <Infos
                      value={totalDevices}
                      iconLeft={"assets/icons/share 1.svg"}
                      message={"Total de dispositivos"}
                      valuePercent={total}
                      color={"#6d99cc"}
                    />
                  </>
                )}
              </Paper>
            </Grid>
            <Grid item xs={3} className={classes.root}>
              <Paper className={classes.paper2}>
                {loadingEnvironments ? (
                  <div className={classes.loadingEnv}>
                    <CircularProgress color="secondary" />
                  </div>
                ) : (
                  <>
                    <Infos
                      value={totalOnlineDevices}
                      valuePercent={totalOnline}
                      iconLeft={"assets/icons/wifi 1.svg"}
                      message={"Dispositivos online"}
                      color={"#6dcc9a"}
                    />
                  </>
                )}
              </Paper>
            </Grid>
            <Grid item xs={3} className={classes.root}>
              <Paper className={classes.paper2}>
                {loadingEnvironments ? (
                  <div className={classes.loadingEnv}>
                    <CircularProgress color="secondary" />
                  </div>
                ) : (
                  <>
                    <Infos
                      value={totalOfflineDevices}
                      valuePercent={totalOffline}
                      iconLeft={"assets/icons/no-signal 2.svg"}
                      message={"Dispositivos offline"}
                      color={"#ef847e"}
                    />
                  </>
                )}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper2}>
                <SubDevicesList />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper} square>
            <EnvironmentsList />
          </Paper>
        </Grid>
      </Grid>
      <CustomConfirmDialog
        onClose={handleCloseModalDelete}
        onCloseFocus={handleCloseModalDelete}
        openDialog={openDialog}
        onConfirmButton={removeEnvironmentContext}
        onCancelButton={handleCloseModalDelete}
        text={`Você realmente deseja remover este Ambiente`}
        roomName={""}
        isLoading={loadingEnvironments}
      />
    </>
  );
};

export default EnvironmentContainer;
