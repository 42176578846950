import { ReactNode, useEffect, useState, useContext } from "react";
import {
  SyncUserModel,
  UserGroupPaginated,
  UserPaginated,
  UserToEdit,
  UserType,
} from "../../../Models/UserModel";
import UsersPageContext from "./UsersPageContext";
import CryptoJS from "crypto-js";
import {
  getUsersActive,
  getUsersNonActive,
  activeUser,
  deleteUser,
  deactiveUser,
  getUserTypes,
  validateEmailUser,
  upadateUser,
  validateEmailUserIzyManager,
  synchronizeUser,
  getHomeNameSelect,
  getAllUsers,
  verifyEmail,
} from "../../../Services/UserService";
import { getAllUsersGroup } from "../../../Services/UserGroupsService";
import GlobalContex from "../../../GlobalContext/GlobalContext";
import UserGroupsModel from "../../../Models/UserGroupsModel";
import Order from "../../../Types/Order";
import { UserOptionsModel } from "../../../Models/UserModel";
import { AxiosError } from "axios";
import PlaceModel from "../../../Models/PlaceModel";
import { UserAccountModel } from "../../../Models/UserAccountModel";
import history from "../../../Services/history";
import { ErrorMessage } from "../../../Utils/ErrorMessage";
interface Props {
  children: ReactNode;
}

interface State {
  name: string;
  email: string;
  password: string;
  userType: {
    id: number;
    name: string;
  };
  userGroups: [];
}

const UsersPageProvider: React.FunctionComponent<Props> = (props: Props) => {
  const { children } = props;
  const [isToggleOpen, setIsToggleOpen] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isActiveUser, setIsActiveUser] = useState<boolean>(false);
  const [actionType, setActionType] = useState<string>("");
  const [controlUpdateTable, setControlUpdateTable] = useState<boolean>(false);
  const [controlValidateEmail, setControlValidateEmail] =
    useState<boolean>(false);
  const [controlValidatePassword, setControlValidatePassword] =
    useState<boolean>(false);
  const [controlValidateName, setControlValidateName] =
    useState<boolean>(false);
  const [controlEmailInUse, setControlEmailInUse] = useState<boolean>(false);
  const [controlEmailInUseOnIzyManager, setControlEmailInUseOnIzyManager] =
    useState<boolean>(false);
  const [mustShow, setMustShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [usersActive, setUserActive] = useState<UserPaginated[]>(
    [] as UserPaginated[]
  );
  const [usersDeactive, setUserDective] = useState<UserPaginated[]>(
    [] as UserPaginated[]
  );
  const [userId, setuserId] = useState<string>("" as string);
  const [repeatEmail, setRepeatEmail] = useState<string>("");
  const [userPassword, setUserPassword] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [userEdit, setuserEdit] = useState<UserToEdit>({
    name: "",
    email: "",
    userType: {} as UserType,
    userGroups: [],
    block: false,
  });
  const [useSelected, setUseSelected] = useState<UserPaginated>(
    {} as UserPaginated
  );
  const [userTypes, setUserTypes] = useState<UserType[]>([]);
  const [userGroups, setUserGroups] = useState<UserGroupsModel[]>([]);
  const [selectUserGroup, setSelectUserGroup] = useState<UserGroupPaginated[]>(
    []
  );
  const [allUsers, setAllUsers] = useState<number>(0);

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [order, setOrder] = useState<Order>("asc");
  const [currentFilterValue, setCurrentFilterValue] = useState<string>("");
  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(true);
  const [orderBy, setOrderBy] = useState<keyof PlaceModel>("name");

  const [tabValue, setTabValue] = useState<number>(0);

  const { showAlert, toggleWaitIndicator, setPathnameUrl } =
    useContext(GlobalContex);

  useEffect(() => {
    setPathnameUrl(window.location.pathname);

    getAllUsers().then((response) => {
      setAllUsers(response.data.totalElements);
    });

    const getAllUserTypes = async () => {
      const response = await getUserTypes();
      setUserTypes(response.data);
    };

    const getUserGroups = async () => {
      const response = await getAllUsersGroup(0, -1);
      setUserGroups(response.data.content);
    };

    toggleWaitIndicator("UsesrPageProvider:init", true);
    Promise.all([getAllUserTypes(), getUserGroups()]).finally(() => {
      toggleWaitIndicator("UsesrPageProvider:init", false);
    });

    return () => {
      setIsToggleOpen(false);
      setOpenDialog(false);
      setControlUpdateTable(false);
      setControlEmailInUse(false);
      setControlEmailInUseOnIzyManager(false);
      setIsSaving(false);
      setUserActive([] as UserPaginated[]);
      setUserDective([] as UserPaginated[]);
      setUserTypes([]);
      setUserGroups([]);
      setSelectUserGroup([]);
      setAllUsers(0);
      setPage(0);
      setTotalUsers(0);
      setIsFiltering(false);
      setIsLoadingUsers(true);
    };
  }, []);

  const getFilteredUserActive = async (value: string) => {
    setIsFiltering(true);
    try {
      const options: UserOptionsModel = {
        filter: value,
        sortBy: "name",
        sortDir: order.toUpperCase(),
      };
      const response = await getUsersActive(0, rowsPerPage, options);
      if (response.status === 200) {
        setUserActive(response.data.content);
        setTotalUsers(response.data.totalElements);
        setPage(response.data.pageNumber);
      } else {
        showAlert("Houve um problema ao carregar os usuarios!", "warning");
        setIsFiltering(false);
      }
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      showAlert(err.message, "error");
      setIsFiltering(false);
    } finally {
      if (page === 0) {
        setIsFiltering(false);
      }
      setIsLoadingUsers(false);
    }
  };

  const getFilteredUserNonActive = async (value: string) => {
    setIsFiltering(true);
    try {
      const options: UserOptionsModel = {
        filter: value,
        sortBy: "name",
        sortDir: order.toUpperCase(),
      };
      const response = await getUsersNonActive(0, rowsPerPage, options);
      if (response.status === 200) {
        setUserDective(response.data.content);
        setTotalUsers(response.data.totalElements);
        setPage(response.data.pageNumber);
      } else {
        showAlert("Houve um problema ao carregar os usuarios!", "warning");
        setIsFiltering(false);
      }
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      showAlert(err.message, "error");
      setIsFiltering(false);
    } finally {
      if (page === 0) {
        setIsFiltering(false);
      }
      setIsLoadingUsers(false);
    }
  };

  const getActiveUsers = async (filter?: string) => {
    setIsLoadingUsers(true);
    try {
      const options: UserOptionsModel = {
        filter: filter,
        sortBy: orderBy,
        sortDir: order.toUpperCase(),
      };
      const response = await getUsersActive(page, rowsPerPage, options);
      if (response.status === 200) {
        setUserActive(response.data.content);
        setTotalUsers(response.data.totalElements);
        setPage(response.data.pageNumber);
      } else {
        showAlert("Houve um problema ao carregar os usuarios!", "warning");
      }
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      showAlert(err.message, "error");
    } finally {
      setIsLoadingUsers(false);
    }
  };

  const getNonActiveUsers = async (filter?: string) => {
    setIsLoadingUsers(true);
    try {
      const options: UserOptionsModel = {
        filter: filter,
        sortBy: orderBy,
        sortDir: order.toUpperCase(),
      };
      const response = await getUsersNonActive(page, rowsPerPage, options);
      if (response.status === 200) {
        setUserDective(response.data.content);
        setTotalUsers(response.data.totalElements);
        setPage(response.data.pageNumber);
      } else {
        showAlert("Houve um problema ao carregar os usuarios!", "warning");
      }
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      showAlert(err.message, "error");
    } finally {
      setIsLoadingUsers(false);
    }
  };

  const getUsersByFilterValue = (value: string) => {
    setCurrentFilterValue(value);
    if (tabValue === 0) {
      getFilteredUserActive(value);
    } else {
      getFilteredUserNonActive(value);
    }
  };

  const updateTable = () => {
    if (tabValue === 0) {
      getActiveUsers(currentFilterValue);
    } else {
      getNonActiveUsers(currentFilterValue);
    }
  };

  useEffect(() => {
    if (isFiltering) {
      setIsFiltering(false);
    } else {
      updateTable();
    }
  }, [page, rowsPerPage, order, tabValue, orderBy]);

  useEffect(() => {
    updateTable();
  }, [controlUpdateTable]);

  const handleChange = (
    prop: keyof State,
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<{ value: unknown }>
  ) => {
    if (prop === "userType") {
      setuserEdit({
        ...userEdit,
        userType: { id: event.target.value as number, name: "" },
        userGroups: event.target.value === 1 ? [] : userEdit.userGroups,
      });
    } else {
      setuserEdit({ ...userEdit, [prop]: event.target.value });
      if (userEdit?.email.trim() === "") {
        setControlEmailInUseOnIzyManager(false);
        setMustShow(false);
      } else {
        setMustShow(true);
      }
    }
  };

  const handleChangeUserGroups = (
    setSelectUserGroupSelected: UserGroupPaginated,
    add: boolean
  ) => {
    if (add) {
      setSelectUserGroup([...selectUserGroup, setSelectUserGroupSelected]);
      setuserEdit({
        ...userEdit,
        userGroups: [...selectUserGroup, setSelectUserGroupSelected],
      });
    } else {
      setuserEdit({
        ...userEdit,
        userGroups: [
          ...selectUserGroup.filter(
            (ug) =>
              ug.user_group_id !== setSelectUserGroupSelected.user_group_id
          ),
        ],
      });
    }
  };

  const handleChangeRepetEmail = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRepeatEmail(event.target.value);
  };

  const handleChangeUserPassword = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setUserPassword(event.target.value);
  };

  const handleChangeRepetPassword = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRepeatPassword(event.target.value);
  };

  const resetForm = () => {
    setuserEdit({
      name: "",
      email: "",
      password: "",
      userType: {
        id: 0,
        name: "",
      },
      userGroups: [],
      block: false,
    });
    setIsEdit(false);
    setUserPassword("");
    setRepeatPassword("");
    setRepeatEmail("");
    setControlEmailInUse(false);
    setControlEmailInUseOnIzyManager(false);
    setControlValidateEmail(false);
    setControlValidateName(false);
    setControlValidatePassword(false);
    setSelectUserGroup([]);
    setMustShow(false);
  };

  const openToEdit = (user: UserPaginated) => {
    setSelectUserGroup([...user.userGroups]);
    setIsToggleOpen(true);
    setuserEdit(user);
    setIsEdit(true);
    setuserId(user.id);
  };

  const validateRegisterEmail = () => {
    const emailRegex = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i;
    if (
      userEdit.email === repeatEmail &&
      emailRegex.test(userEdit.email.trim())
    ) {
      return true;
    }
    return false;
  };

  const validateRegisterPassword = () => {
    if (isEdit) {
      return true;
    }
    return (
      userEdit?.password?.trim().length !== 0 &&
      userEdit.password === repeatPassword
    );
  };

  const validateEmail = async () => {
    try {
      const response = await validateEmailUser(userEdit.email);
      setControlEmailInUse(response.data);
      const response2 = await validateEmailUserIzyManager(userEdit.email);
      setControlEmailInUseOnIzyManager(response2.data);
    } catch (error) {
      showAlert("Erro ao validar email", "error");
      setControlEmailInUse(false);
      setControlEmailInUseOnIzyManager(false);
    }
  };

  const blurEmail = () => {
    if (userEdit.email.trim() === "") {
      setControlEmailInUse(false);
      setControlEmailInUseOnIzyManager(false);
      setIsEdit(false);
      return;
    }
    validateEmail();
  };

  const resetControlErrorForm = () => {
    setControlValidateEmail(false);
    setControlValidateName(false);
    setControlValidatePassword(false);
  };

  const syncUser = async () => {
    const sync: SyncUserModel = {
      email: userEdit.email,
      userType: userEdit.userType,
      userGroups: userEdit.userType.id === 2 ? userEdit.userGroups : [],
    };
    try {
      setIsSaving(true);
      const response = await synchronizeUser(sync);
      if (response.data) {
        showAlert("Usuário sincronizado com sucesso!", "success");
        resetForm();
        setControlUpdateTable(!controlUpdateTable);
      }
    } catch (error: any) {
      const err = error.response.data;
      if (
        err &&
        err.errors &&
        err.errors[0] &&
        err.errors[0].key === "max.limit.user"
      ) {
        showAlert(
          "O limite máximo de usuários no empreendimento " +
            getHomeNameSelect() +
            " foi alcançado",
          "error"
        );
      } else {
        showAlert("Erro ao sincronizar o usuário", "error");
      }
    } finally {
      setIsSaving(false);
    }
  };

  const filterUserGroupByHome = (group: UserGroupPaginated) => {
    const localHome = localStorage.getItem("homeID");
    if (group.home_id === localHome) {
      return true;
    }
    return false;
  };

  const validateForm = () => {
    if (!validateRegisterEmail() && !isEdit) {
      setControlValidateEmail(true);
      throw new Error("Por favor, verifique o campo e-mail");
    }

    if (userEdit?.name?.trim() === "") {
      setControlValidateName(true);
      throw new Error("Por favor, verifique o campo de nome");
    }

    if (!validateRegisterPassword() && !isEdit) {
      setControlValidatePassword(true);
      throw new Error("Verifique a senha");
    }
  };

  const saveUser = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setIsSaving(true);
    resetControlErrorForm();
    try {
      validateForm();
    } catch (e) {
      const msg = ErrorMessage(e, "Dados inválidos");
      showAlert(msg, "error");
      setIsSaving(false);
      return;
    }

    /// Tudo certo, cadastre o cara aqui
    if (userEdit.userType.id === 1) {
      userEdit.userGroups = [];
    }
    if (userEdit.userType.id === 2) {
      userEdit.userGroups = selectUserGroup.filter(filterUserGroupByHome);
    }

    if (isEdit) {
      try {
        const response = await upadateUser(userEdit, userId);
        if (response.data) {
          showAlert(
            "Usuário " + userEdit.name + " editado com sucesso!",
            "success"
          );
          resetForm();
          setControlUpdateTable(!controlUpdateTable);
          setIsToggleOpen(false);
        }
      } catch (error) {
        showAlert("Não foi possível alterar os dados do usuário", "error");
      }
    } else {
      await verifyEmail(userEdit.email)
        .then((response) => {
          if (response.data && userEdit.password) {
            const userAccount: UserAccountModel = {
              country_code: 55,
              nick_name: userEdit.name,
              username: userEdit.email,
              password: CryptoJS.MD5(userEdit.password).toString(),
              userType: userEdit.userType,
              userGroups: userEdit.userGroups,
            };

            history.push("/confirm-code", {
              userAccount: userAccount,
              redirectTo: "/usuarios",
            });
          }
        })
        .catch((error) => {
          const message = ErrorMessage(
            error,
            "Não foi possível criar o novo usuário! Contate o administrador do sistema."
          );
          showAlert(message, "error");
        });
    }
    setIsSaving(false);
  };

  const toggleActiveUser = (
    user: UserPaginated,
    active: boolean,
    actionType: string
  ) => {
    setIsActiveUser(active);
    setActionType(actionType);
    setOpenDialog(true);
    setuserId(user.id);
    setUseSelected(user);
  };

  const activeUserContext = async () => {
    setIsLoadingUsers(true);
    const response = await activeUser(userId);
    if (response.data) {
      showAlert(
        "Sucesso ao ativar usuário " + useSelected.name + "!",
        "success"
      );
      if (usersDeactive.length === 1 && page > 0) {
        const p = page;
        setPage(p - 1);
      } else {
        setControlUpdateTable(!controlUpdateTable);
      }
    } else {
      setIsLoadingUsers(false);
      showAlert("Erro ao ativar usuário", "error");
    }
    handleCloseModalDelete();
  };

  const deactiveUserContext = async () => {
    setIsLoadingUsers(true);
    const response = await deactiveUser(userId);
    if (response.data) {
      showAlert(
        "Sucesso ao desativar usuário " + useSelected.name + "!",
        "success"
      );
      if (usersActive.length === 1 && page > 0) {
        const p = page;
        setPage(p - 1);
      } else {
        setControlUpdateTable(!controlUpdateTable);
      }
    } else {
      setIsLoadingUsers(false);
      showAlert("Erro ao desativar usuário", "error");
    }
    handleCloseModalDelete();
  };

  const handleCloseModalDelete = () => {
    setOpenDialog(false);
    resetForm();
  };

  const removeUser = async () => {
    setIsLoadingUsers(true);
    try {
      const result = await deleteUser(userId);
      if (result.data) {
        showAlert(
          "Usuário " + useSelected.name + " removido com sucesso",
          "success"
        );
      } else {
        showAlert("Houve algum problema ai remover usuário", "warning");
      }
    } catch (error) {
      showAlert("Erro ao remover usuário", "error");
    } finally {
      getActiveUsers();
      getNonActiveUsers();
      setIsLoadingUsers(false);
      setOpenDialog(false);
    }
  };

  return (
    <UsersPageContext.Provider
      value={{
        isToggleOpen,
        openDialog,
        isActiveUser,
        usersActive,
        controlValidateName,
        controlValidateEmail,
        controlValidatePassword,
        controlEmailInUse,
        controlEmailInUseOnIzyManager,
        usersDeactive,
        isEdit,
        userEdit,
        repeatEmail,
        userTypes,
        userGroups,
        selectUserGroup,
        setSelectUserGroup,
        userPassword,
        repeatPassword,
        handleChange,
        blurEmail,
        setIsToggleOpen,
        handleChangeRepetEmail,
        handleChangeUserGroups,
        handleChangeUserPassword,
        handleChangeRepetPassword,
        handleCloseModalDelete,
        openToEdit,
        mustShow,
        saveUser,
        syncUser,
        toggleActiveUser,
        activeUserContext,
        deactiveUserContext,
        resetForm,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        totalUsers,
        order,
        setOrder,
        isLoadingUsers,
        tabValue,
        setTabValue,
        orderBy,
        setOrderBy,
        setIsLoadingUsers,
        getUsersByFilterValue,
        removeUser,
        actionType,
        setActionType,
        useSelected,
        setUseSelected,
        isSaving,
        allUsers,
      }}
    >
      {children}
    </UsersPageContext.Provider>
  );
};

export default UsersPageProvider;
