import List from "@material-ui/core/List";
import ConfigurationOptionItem from "./ConfigurationOptionItem";

interface Props {
  activeOptionId: number;
}

interface ConfigurationMenuItem {
  id: number;
  icon: string;
  active_icon: string;
  title: string;
  subtitle: string;
  goTo: string;
}

const ConfigurationOptionsMenu: React.FunctionComponent<Props> = ({
  activeOptionId,
}: Props) => {
  const menuItens: ConfigurationMenuItem[] = [
    // {
    //   id: 1,
    //   icon: "/assets/icons/profile-menu-icon.svg",
    //   active_icon: "/assets/icons/profile-active-menu-icon.svg",
    //   title: "Meu perfil",
    //   subtitle: "Modifique suas informações pessoais...",
    //   goTo: "/profile",
    // },
    {
      id: 2,
      icon: "/assets/icons/enterprises-menu-icon.svg",
      active_icon: "/assets/icons/enterprises-active-menu-icon.svg",
      title: "Meus empreendimentos",
      subtitle: "Ver seus empreendimentos criados",
      goTo: "/my-enterprises",
    },
  ];

  return (
    <List style={{ padding: "0px" }}>
      {menuItens.map((menuItem: ConfigurationMenuItem, index) => (
        <ConfigurationOptionItem
          src={menuItem.icon}
          activeSrc={menuItem.active_icon}
          title={menuItem.title}
          subtitle={menuItem.subtitle}
          active={menuItem.id === activeOptionId}
          link={menuItem.goTo}
          key={"configuration-option-menu-" + index}
        />
      ))}
    </List>
  );
};

export default ConfigurationOptionsMenu;
