import { ReactNode, useContext, useEffect, useState } from "react";
import GlobalContex from "../../../GlobalContext/GlobalContext";
import DeviceModel from "../../../Models/DeviceModel";
import {
  NotDisturbSchedulesModel,
  NotificationConfigModel,
} from "../../../Models/NotificationConfigModel";
import {
  getNotificationConfig,
  removeNotificationService,
  saveNotificationConfigService,
  saveNotificationService,
} from "../../../Services/NotificationConfigService";
import { ErrorMessage } from "../../../Utils/ErrorMessage";
import NotificationConfigContext from "./NotificationConfigContext";

interface Props {
  children: ReactNode;
}

const NotificationConfigProvider: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { children } = props;
  const { showAlert } = useContext(GlobalContex);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [placeSelectedIndex, setPlaceSelectedIndex] = useState<number>(0);
  const [spaceSelected, setSpaceSelected] = useState<number>(0);
  const [spaceFilterSelected, setSpaceFilterSelected] = useState<number>(0);
  const [
    devicesSelectedToConfigNotification,
    setDevicesSelectedToConfigNotification,
  ] = useState<DeviceModel[]>([]);
  const [repetitionSchedule, setRepetitionSchedule] = useState<number[]>([
    0, 0, 0, 0, 0, 0, 0,
  ]);

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedDateUntil, setSelectedDateUntil] = useState<Date | null>(null);
  const [timeSchedule, setTimeSchedule] = useState<string>("");
  const [timeScheduleUntil, setTimeScheduleUntil] = useState<string>("");

  const [notificationConfig, setNotificationConfig] =
    useState<NotificationConfigModel>({} as NotificationConfigModel);

  const [notificationsConfig, setNotificationsConfig] = useState<
    NotDisturbSchedulesModel[]
  >([]);

  const [filterNameValue, setFilterNameValue] = useState<string>("");

  const [valueCheckBox, setValueCheckBox] = useState<string>("allDevices");

  const [selectedDateLocalInitial, handleDateChangeLocalInitial] =
    useState<string>("00:00");

  const [selectedDateLocalFinal, handleDateChangeLocalFinal] =
    useState<string>("00:00");

  const openModalNotification = () => {
    setOpenModal(true);
  };

  const closeModalNotification = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    getCurrentNotificationConfig();

    return () => {
      setNotificationConfig({} as NotificationConfigModel);
      setNotificationsConfig([]);
    };
  }, []);

  const getCurrentNotificationConfig = async () => {
    await getNotificationConfig()
      .then((response) => {
        setNotificationsConfig(response.data.not_disturb_schedules);
        setNotificationConfig(response.data);
      })
      .catch((error) => {
        const message = ErrorMessage(
          error,
          "Houve um problema ao criar automação!"
        );
        showAlert(message, "error");
      });
  };

  const deleteConfigNotification = async (id: number) => {
    try {
      const result = await removeNotificationService(id);

      if (result.status) {
        showAlert("Configuração de notificação salva", "success");
        setNotificationsConfig(notificationsConfig.filter((n) => n.id !== id));
      } else {
        showAlert("Houve algum problema", "warning");
      }
    } catch (error: any) {
      showAlert(error.message, "error");
    }
  };

  const changeRepeat = (value: number, position: number) => {
    const newState = [...repetitionSchedule];

    newState[position] === 1
      ? (newState[position] = 0)
      : (newState[position] = value);

    setRepetitionSchedule(newState);
  };

  const changeNotificationsStatus = () => {
    const newNotification: NotificationConfigModel = {
      ...notificationConfig,
      enabled: notificationConfig.enabled ? false : true,
    };
    setNotificationConfig(newNotification);
    saveNotificationStatus(
      newNotification.enabled,
      true,
      newNotification.not_disturb
    );
  };

  const changeAlarmStatus = () => {
    const newNotification: NotificationConfigModel = {
      ...notificationConfig,
      alarm: notificationConfig.alarm ? false : true,
    };
    setNotificationConfig(newNotification);
    saveNotificationStatus(
      newNotification.enabled,
      true,
      newNotification.not_disturb
    );
  };

  const changeDoNotDisturb = () => {
    const newNotification: NotificationConfigModel = {
      ...notificationConfig,
      not_disturb: notificationConfig.not_disturb ? false : true,
    };
    setNotificationConfig(newNotification);
    saveNotificationStatus(
      newNotification.enabled,
      true,
      newNotification.not_disturb
    );
  };

  const resetForm = () => {
    setRepetitionSchedule([0, 0, 0, 0, 0, 0, 0]);
    setTimeSchedule("");
    setTimeScheduleUntil("");
    setDevicesSelectedToConfigNotification([]);
    setSelectedDate(null);
    setSelectedDateUntil(null);
    closeModalNotification();
    handleDateChangeLocalInitial("00:00");
    handleDateChangeLocalFinal("00:00");
  };

  const saveNotificationStatus = async (
    note: boolean,
    alarm: boolean,
    not_disturb: boolean
  ) => {
    try {
      const notification: NotificationConfigModel = {
        enabled: note,
        alarm: alarm,
        not_disturb: not_disturb,
        not_disturb_schedules: [...notificationsConfig],
      };

      const saveNotificationConfig = await saveNotificationConfigService(
        notification
      );

      if (saveNotificationConfig.status === 204) {
        showAlert("Configuração de notificação salva!", "success");
      } else {
        showAlert("Erro ao salvar configuração de notificação!", "error");
      }
    } catch (error: any) {
      showAlert(error.message, "error");
      throw error;
    }
  };

  const canSaveNotification = (): boolean => {
    if (
      repetitionSchedule
        .toString()
        .split(",")
        .some((valor) => valor === "1") &&
      timeScheduleUntil &&
      timeSchedule
    ) {
      return true;
    }
    return false;
  };

  const saveNotificationConfig = async (deviceids: string[]) => {
    if (canSaveNotification()) {
      try {
        const notification: NotDisturbSchedulesModel = {
          device_ids: deviceids,
          loops: repetitionSchedule.toString().replaceAll(",", ""),
          end_time: timeScheduleUntil,
          start_time: timeSchedule,
        };
        const saveNotificationConfig = await saveNotificationService(
          notification
        );
        if (saveNotificationConfig.status === 200) {
          setNotificationsConfig([saveNotificationConfig.data]);
          resetForm();
          showAlert("Configuração de notificação salva!", "success");
        } else
          showAlert("Erro ao salvar configuração de notificação!", "error");
      } catch (error: any) {
        showAlert(error.message, "error");
        throw error;
      }
    } else {
      showAlert("Verifique os campos de hora e dia selecionados", "warning");
    }
  };

  return (
    <NotificationConfigContext.Provider
      value={{
        openModal,
        openModalNotification,
        closeModalNotification,
        notificationConfig,
        changeNotificationsStatus,
        changeAlarmStatus,
        changeDoNotDisturb,
        saveNotificationConfig,
        placeSelectedIndex,
        setPlaceSelectedIndex,
        devicesSelectedToConfigNotification,
        setDevicesSelectedToConfigNotification,
        repetitionSchedule,
        setRepetitionSchedule,
        changeRepeat,
        selectedDate,
        setSelectedDate,
        timeSchedule,
        setTimeSchedule,
        timeScheduleUntil,
        setTimeScheduleUntil,
        selectedDateUntil,
        setSelectedDateUntil,
        resetForm,
        spaceSelected,
        setSpaceSelected,
        setSpaceFilterSelected,
        spaceFilterSelected,
        filterNameValue,
        setFilterNameValue,
        valueCheckBox,
        setValueCheckBox,
        notificationsConfig,
        setNotificationsConfig,
        selectedDateLocalInitial,
        handleDateChangeLocalInitial,
        selectedDateLocalFinal,
        handleDateChangeLocalFinal,
        deleteConfigNotification,
      }}
    >
      <div
        id="notification-principal-container"
        style={{
          backgroundColor: "#FFF",
          width: "100%",
          overflow: "auto",
        }}
      >
        {children}
      </div>
    </NotificationConfigContext.Provider>
  );
};

export default NotificationConfigProvider;
