import { Container, createStyles, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CustomStepper from "./components/Stepper";
import TitleSection from "./components/TitleSection";
import { useCallback, useContext } from "react";
import EnterpriseContext from "../context/EnterpriseContext";
import Step1 from "../components/Step1";
import Step2 from "../components/Step2";
import Step3 from "../components/Step3";
import history from "../../../Services/history";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      paddingTop: 10,
    },
  })
);

const ContainerEnterprisePage: React.FunctionComponent = () => {
  const classes = useStyles();

  const {
    stepsList,
    currentStep,
    currentStepIndex,
    previousStep,
    hasPreviousStep,
  } = useContext(EnterpriseContext);

  const steps = useCallback(() => {
    return stepsList.map((step) => step.shortTitle);
  }, [stepsList]);

  const handleBackButton = () => {
    if (hasPreviousStep) {
      previousStep();
      return;
    }
    history.push("/select-home");
  };

  return (
    <div className={classes.root}>
      <Container>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <CustomStepper steps={steps()} activeStep={currentStepIndex} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TitleSection
              title={currentStep.title}
              description={currentStep.description}
              onClickBackButton={handleBackButton}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {currentStepIndex === 0 && <Step1 />}
          {currentStepIndex === 1 && <Step2 />}
          {currentStepIndex === 2 && <Step3 />}
        </Grid>
      </Container>
    </div>
  );
};

export default ContainerEnterprisePage;
