import { useContext, useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Button } from "@material-ui/core";
import NotificationConfigContext from "../../../../Context/NotificationConfigContext";
import SelectDevicesModal from "./SelectDevicesModal";
import GlobalContex from "../../../../../../GlobalContext/GlobalContext";
import RoomModel from "../../../../../../Models/RoomModel";
import PlaceModel from "../../../../../../Models/PlaceModel";
import SelectedRepetition from "./SelectedRepetition";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      marginBottom: "10px",
    },
    title: {
      fontSize: "15px",
      fontWeight: 600,
      color: "#8B979F",
      padding: "0px 40px",
    },
    subtitle: {
      padding: "0px 40px",
      fontSize: "13px",
      color: "#8B979F",
    },
    summary: {
      padding: "0px",
      paddingRight: "40px",
      fontWeight: 400,
    },
    details: {
      backgroundColor: "#EBEEEE",
    },
    radio: {
      fontSize: "15px",
      color: "#3E5055",
      fontWeight: 600,
    },
    control: {
      padding: "20px 40px",
    },
    button: {
      margin: "20px 40px",
      padding: "20px",
      textTransform: "none",
      color: "#3E5055",
      fontSize: "15px",
      fontWeight: 600,
      border: "1px solid #D1DAE0",
      background: "#FFFFFF",
    },
    selectDevices: {
      boxSizing: "border-box",
      borderRadius: "5px",
      margin: "10px 40px",
      textTransform: "none",
      fontSize: "15px",
      fontWeight: 600,
    },
  })
);

const DoNotDisturbInThePeriod: React.FunctionComponent = () => {
  const classes = useStyles();
  const {
    openModalNotification,
    devicesSelectedToConfigNotification,
    setDevicesSelectedToConfigNotification,
    valueCheckBox,
    setValueCheckBox,
  } = useContext(NotificationConfigContext);

  const { places } = useContext(GlobalContex);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueCheckBox((event.target as HTMLInputElement).value);
    if (valueCheckBox !== "allDevices") {
      setDevicesSelectedToConfigNotification([]);
    }
  };

  useEffect(() => {
    if (devicesSelectedToConfigNotification.length === 0) {
      setDevicesSelectedToConfigNotification(
        places
          .flatMap((place: PlaceModel) => place.rooms)
          .flatMap((room: RoomModel) => room.devices)
      );
    } else {
      setValueCheckBox("selectedDevices");
    }
  }, []);

  return (
    <>
      <div className={classes.root}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            backgroundColor: "#EBEEEE",
          }}
        >
          <FormControl component="fieldset" className={classes.control}>
            <RadioGroup value={valueCheckBox} onChange={handleChange}>
              <FormControlLabel
                value="allDevices"
                control={<Radio />}
                label={
                  <Typography className={classes.radio}>
                    Todos os dispositivos
                  </Typography>
                }
              />
              {valueCheckBox === "allDevices" && <SelectedRepetition />}
              <FormControlLabel
                value="selectedDevices"
                control={<Radio />}
                label={
                  <Typography className={classes.radio}>
                    Dispositivos selecionados
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>

          {valueCheckBox === "selectedDevices" && (
            <div
              className={classes.selectDevices}
              onClick={openModalNotification}
            >
              <Button
                style={{
                  textTransform: "none",
                }}
                onClick={openModalNotification}
                color="secondary"
                variant="contained"
                startIcon={<AddIcon />}
              >
                Selecionar dispositivos
              </Button>
            </div>
          )}
        </div>
        <SelectDevicesModal />
      </div>
    </>
  );
};
export default DoNotDisturbInThePeriod;
