import {
  makeStyles,
  createStyles,
  IconButton,
  MuiThemeProvider,
  createTheme,
} from "@material-ui/core";
import { useContext } from "react";
import NotificationConfigContext from "../../../../../Context/NotificationConfigContext";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { days } from "../../../../../../../Utils/DaysSchedule";
import { TimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      padding: "10px 0px 10px 10px",
    },
    heading: {
      fontSize: "15px",
      fontWeight: 600,
      color: "#3E5055",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "10px",
    },
    contiainer: {
      width: "500px",
      height: "52px",
      display: "flex",
      flexDirection: "row",
      backgroundColor: "#FFF",
      alignItems: "center",
      justifyContent: "space-between",
      border: "1px solid #D1DAE0",
      borderRadius: "5px",
    },
    img: {
      margin: "10px",
    },
    totalDevices: {
      color: "#BDBDBD",
      marginRight: "30px",
    },
    normalButton: {
      backgroundColor: "#EBEEEE",
      width: "40px",
      height: "40px",
      marginRight: "13px",
      fontSize: "18px",
    },
    selectedButton: {
      backgroundColor: "#00A335",
      width: "40px",
      height: "40px",
      marginRight: "13px",
      fontSize: "18px",
      color: "#FFFFFF",
    },
    details: {
      display: "flex",
      flexDirection: "column",
      padding: "34px",
    },
    summary: {
      border: "1px solid #D1DAE0",
    },
    selectOptionsDate: {
      display: "flex",
      flexDirection: "row",
    },
    title: {
      color: "#3E5055",
      marginTop: "50px",
      marginBottom: "20px",
      fontWeight: 600,
      fontSize: "15px",
    },
    subtitle: {
      fontWeight: 600,
      color: "#8B979F",
      fontSize: "13px",
    },
    deviceContainer: {
      display: "flex",
      padding: "0px 40px",
      alignItems: "center",
      justifyContent: "space-between",
    },
  })
);

interface DateValue {
  initial: string;
  value: string;
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#00A335",
    },
    secondary: {
      main: "#4caf50",
    },
  },
});

const SelectedRepetition: React.FunctionComponent = () => {
  const classes = useStyles();

  const {
    changeRepeat,
    repetitionSchedule,
    selectedDate,
    setSelectedDate,
    setTimeSchedule,
    setTimeScheduleUntil,
    selectedDateUntil,
    setSelectedDateUntil,
  } = useContext(NotificationConfigContext);

  const checkTime = (time: any) => {
    if (time < 10) {
      time = "0" + time;
    }
    return time;
  };

  const formatHours = (time: string) => {
    if (time.charAt(2) === ":") {
      const date: Date = new Date(
        1,
        1,
        1,
        parseInt(time.substr(0, 2)),
        parseInt(time.substr(3, time.length))
      );
      let hour = date.getHours();
      let minutes = date.getMinutes();
      hour = checkTime(hour);
      minutes = checkTime(minutes);
      return hour + ":" + minutes;
    } else if (time.charAt(1) === ":") {
      const date: Date = new Date(
        1,
        1,
        1,
        parseInt(time.substr(0, 1)),
        parseInt(time.substr(2, time.length))
      );
      let hour = date.getHours();
      let minutes = date.getMinutes();
      hour = checkTime(hour);
      minutes = checkTime(minutes);
      return hour + ":" + minutes;
    }
    return time;
  };

  function handleDate(e: any) {
    setSelectedDate(e);
    setTimeSchedule(formatHours(`${e?.getHours()}:${e?.getMinutes()}`));
  }

  function handleDateUntil(e: any) {
    setSelectedDateUntil(e);
    setTimeScheduleUntil(formatHours(`${e?.getHours()}:${e?.getMinutes()}`));
  }

  const setFocusOnSave = () => {
    const container = document.getElementById(
      "notification-principal-container"
    );

    setTimeout(() => {
      if (container) {
        container.scrollBy(999, 999);
      }
    }, 200);
  };

  return (
    <div>
      <div>
        <div className={classes.root}>
          <Accordion onChange={setFocusOnSave}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel1a-header"
              className={classes.summary}
              onClick={setFocusOnSave}
            >
              <Typography className={classes.heading}>Repetir</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <div>
                {days.map((day: DateValue, i: number) => (
                  <IconButton
                    onClick={() => {
                      changeRepeat(1, i);
                    }}
                    className={
                      repetitionSchedule[i] === 1
                        ? classes.selectedButton
                        : classes.normalButton
                    }
                    id={day.value}
                    key={day.value}
                  >
                    {day.initial}
                  </IconButton>
                ))}
              </div>
              <div>
                <p className={classes.title}>Horário</p>
                <div className={classes.selectOptionsDate}>
                  <div
                    style={{
                      marginRight: "50px",
                    }}
                  >
                    <p className={classes.subtitle}>De</p>
                    <MuiThemeProvider theme={theme}>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={ptBR}
                      >
                        <TimePicker
                          showTodayButton
                          cancelLabel="Cancelar"
                          okLabel="Selecionar"
                          todayLabel="Agora"
                          ampm={false}
                          color="primary"
                          value={selectedDate}
                          onChange={handleDate}
                        />
                      </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                  </div>
                  <div>
                    <p className={classes.subtitle}>
                      Até -{" "}
                      {selectedDateUntil &&
                      selectedDate &&
                      selectedDateUntil < selectedDate
                        ? "próximo dia"
                        : "mesmo dia"}
                    </p>
                    <MuiThemeProvider theme={theme}>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={ptBR}
                      >
                        <TimePicker
                          showTodayButton
                          cancelLabel="Cancelar"
                          okLabel="Selecionar"
                          todayLabel="Agora"
                          ampm={false}
                          color="primary"
                          value={selectedDateUntil}
                          onChange={handleDateUntil}
                        />
                      </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default SelectedRepetition;
