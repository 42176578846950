import {
  makeStyles,
  createStyles,
  Button,
  IconButton,
} from "@material-ui/core";
import { useContext } from "react";
import ListEnvironmentsFromDevices from "../../../../../Components/ListEnvironmentsFromDevices";
import GlobalContex from "../../../../../GlobalContext/GlobalContext";
import DeviceModel, {
  DeviceFunctionsModel,
} from "../../../../../Models/DeviceModel";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      padding: "60px",
      alignItems: "center",
    },
    title: {
      fontSize: "23px",
      color: "#3E5055",
      fontWeight: 600,
      marginBottom: "0px",
    },
    subtitle: {
      fontSize: "15px",
      color: "#8B979F",
    },
    control: {
      width: "380px",
      height: "612px",
      backgroundColor: "#F7F7F7",
      boxShadow: "0 0 2em rgba(0, 0, 0, 0.1)",
    },
    img: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "60px 0px 30px 0px",
    },
    statusControl: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "70px",
    },
    status: {
      fontSize: "50px",
      color: "#3E5055",
      margin: "0px 30px",
    },
    statusSmall: {
      fontSize: "18px",
      color: "#3E5055",
      margin: "0px 30px",
    },
    options: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      marginTop: "5rem",
    },
    interruptor: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "4.5rem",
      martinTop: "230px",
    },
    interruptorButton: {
      padding: "10px 35px",
      borderRadius: "30px",
      textTransform: "none",
    },
    buttonText: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

interface Props {
  device: DeviceModel;
  specificationsDevice: DeviceFunctionsModel;
}

const DeviceAirConditioner: React.FunctionComponent<Props> = ({
  device,
  specificationsDevice,
}: Props) => {
  const { handleChangeCommand } = useContext(GlobalContex);

  const power =
    device.status.find((status) => status.code === "power")?.value === true;

  const m = device.status.find((status) => status.code === "mode")?.value;
  const mode = m ? parseInt(m as string) : undefined;

  const t = device.status.find((status) => status.code === "temp")?.value;
  const temp = t ? parseInt(t as string) : undefined;

  const w = device.status.find((status) => status.code === "wind")?.value;
  const wind = w ? parseInt(w as string) : undefined;

  const T_UP = specificationsDevice.functions.find(
    (f) => f.code === "T" || f.code === "T_UP"
  );
  const T_DOWN = specificationsDevice.functions.find(
    (f) => f.code === "T" || f.code === "T_DOWN"
  );
  let HAS_TEMP_INDICATOR = false;
  if (T_UP && T_DOWN) {
    try {
      HAS_TEMP_INDICATOR =
        T_UP &&
        JSON.parse(T_UP.values) !== undefined &&
        T_DOWN &&
        JSON.parse(T_DOWN.values) !== undefined;
    } catch (error) {
      HAS_TEMP_INDICATOR = false;
    }
  }

  const MODE = specificationsDevice.functions.find(
    (f) => f.code === "M" || f.code === "mode"
  );
  let HAS_MODE_VALUES = false;
  let MODE_ACCEPT_TEMP = false;
  if (MODE) {
    try {
      HAS_MODE_VALUES = JSON.parse(MODE.values) !== undefined;
    } catch (error) {
      HAS_MODE_VALUES = false;
    }
    try {
      MODE_ACCEPT_TEMP =
        !HAS_MODE_VALUES ||
        JSON.parse(MODE.values).blockTemp.indexOf(mode) == -1;
    } catch (error) {
      HAS_MODE_VALUES = false;
    }
  }

  const FAN = specificationsDevice.functions?.find((f) => f.code === "F");
  let HAS_FAN_VALUES = false;
  if (FAN) {
    try {
      HAS_FAN_VALUES = JSON.parse(FAN.values) !== undefined;
    } catch (error) {
      HAS_FAN_VALUES = false;
    }
  }

  const POWER_ON = specificationsDevice.functions.find(
    (f) => f.code === "PowerOn"
  );
  const POWER_OFF = specificationsDevice.functions.find(
    (f) => f.code === "PowerOff"
  );

  const powerSwitch = (s: boolean) => {
    if (!POWER_OFF || !POWER_ON) {
      return;
    }
    if (!s) {
      handleChangeCommand(POWER_OFF.values, device.id, POWER_OFF.code);
    } else {
      handleChangeCommand(POWER_ON.values, device.id, POWER_ON.code);
    }
  };

  const clickMinus = () => {
    if (T_DOWN) {
      if (!HAS_TEMP_INDICATOR) {
        handleChangeCommand(T_DOWN.values, device.id, T_DOWN.code);
      } else {
        const minT: number =
          T_DOWN && T_DOWN.values
            ? parseInt(JSON.parse(T_DOWN.values).min)
            : 30;
        if (temp) {
          if (temp > minT) {
            handleChangeCommand(temp - 1, device.id, T_DOWN.code);
          }
        } else {
          handleChangeCommand(minT, device.id, T_DOWN.code);
        }
      }
    }
  };

  const clickAdd = () => {
    if (T_UP) {
      if (!HAS_TEMP_INDICATOR) {
        handleChangeCommand(T_UP.values, device.id, T_UP.code);
      } else {
        const maxT: number =
          T_UP && T_UP.values ? parseInt(JSON.parse(T_UP.values).max) : 30;
        if (temp) {
          if (temp < maxT) {
            handleChangeCommand(temp + 1, device.id, T_UP.code);
          }
        } else {
          handleChangeCommand(maxT, device.id, T_UP.code);
        }
      }
    }
  };

  const modeList: string[] = [
    "Resfriar",
    "Aquecer",
    "Auto",
    "Ventilar",
    "Desumidificar",
  ];
  const modeWindList: string[] = ["Auto", "Baixa", "Média", "Alta"];

  const nextFunctionMode = () => {
    if (MODE) {
      if (!HAS_MODE_VALUES) {
        handleChangeCommand(MODE.values, device.id, MODE.code);
      } else {
        if (!mode) {
          return;
        }
        handleChangeCommand(mode < 4 ? mode + 1 : 0, device.id, MODE.code);
      }
    }
  };

  const nextFunctionModeVelocity = () => {
    if (FAN) {
      if (!HAS_FAN_VALUES) {
        handleChangeCommand(FAN.values, device.id, FAN.code);
      } else {
        if (!wind) {
          return;
        }
        handleChangeCommand(wind < 3 ? wind + 1 : 0, device.id, FAN.code);
      }
    }
  };

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <p className={classes.title}>{device.name}</p>
      <div className={classes.subtitle}>
        {device.environments && (
          <ListEnvironmentsFromDevices environments={device.environments} />
        )}
      </div>
      <div className={classes.control}>
        <div className={classes.img}>
          <img src="assets/icons/ar 1.png" />
        </div>
        {MODE_ACCEPT_TEMP && (
          <div className={classes.statusControl}>
            <IconButton
              style={{
                backgroundColor: "#00A335",
                color: "#FFF",
              }}
              onClick={clickMinus}
            >
              <RemoveIcon fontSize="small" />
            </IconButton>

            {temp && HAS_TEMP_INDICATOR && (
              <div>
                <span className={classes.status}>
                  {temp}
                  <sup>°C</sup>
                </span>
              </div>
            )}
            {(!temp || !HAS_TEMP_INDICATOR) && (
              <span
                className={
                  HAS_TEMP_INDICATOR ? classes.status : classes.statusSmall
                }
                style={{
                  margin: "0px 30px",
                }}
              >
                {HAS_TEMP_INDICATOR && "--"}
                {!HAS_TEMP_INDICATOR && "Temperatura"}
              </span>
            )}

            <IconButton
              color="secondary"
              style={{
                backgroundColor: "#00A335",
                color: "#FFF",
              }}
              onClick={clickAdd}
            >
              <AddIcon fontSize="small" />
            </IconButton>
          </div>
        )}
        <div className={classes.options}>
          {MODE && !HAS_MODE_VALUES && (
            <div className={classes.buttonText}>
              <IconButton
                style={{ backgroundColor: "#FFFFFF" }}
                size="medium"
                onClick={nextFunctionMode}
              >
                <img src={`assets/icons/air_conditioner/mode.svg`} width={30} />
              </IconButton>
              <span>Modo</span>
            </div>
          )}
          {MODE && HAS_MODE_VALUES && (
            <div className={classes.buttonText}>
              <IconButton
                style={{ backgroundColor: "#FFFFFF" }}
                size="medium"
                onClick={nextFunctionMode}
              >
                <img
                  src={`assets/icons/air_conditioner/mode_${mode}.svg`}
                  width={30}
                />
              </IconButton>
              <span>Modo: {mode && modeList[mode]}</span>
            </div>
          )}

          {FAN && !HAS_FAN_VALUES && (
            <div className={classes.buttonText}>
              <IconButton
                style={{ backgroundColor: "#FFFFFF" }}
                size="medium"
                onClick={nextFunctionModeVelocity}
              >
                <img src={`assets/icons/air_conditioner/wind.svg`} width={30} />
              </IconButton>
              <span>Velocidade</span>
            </div>
          )}
          {FAN && HAS_FAN_VALUES && (
            <div className={classes.buttonText}>
              <IconButton
                style={{ backgroundColor: "#FFFFFF" }}
                size="medium"
                onClick={nextFunctionModeVelocity}
              >
                <img
                  src={`assets/icons/air_conditioner/wind_${wind}.svg`}
                  width={30}
                />
              </IconButton>
              <span>Velocidade: {wind && modeWindList[wind]}</span>
            </div>
          )}
        </div>
        <div className={classes.interruptor}>
          {HAS_TEMP_INDICATOR && (
            <Button
              startIcon={<PowerSettingsNewIcon />}
              color="secondary"
              variant="contained"
              onClick={() => {
                powerSwitch(!power);
              }}
              className={classes.interruptorButton}
              style={{
                backgroundColor: power ? "green" : "#6DCC9A",
              }}
            >
              Interruptor
            </Button>
          )}
          {!HAS_TEMP_INDICATOR && (
            <>
              <Button
                startIcon={<PowerSettingsNewIcon />}
                color="secondary"
                variant="contained"
                onClick={() => {
                  powerSwitch(true);
                }}
                className={classes.interruptorButton}
                style={{
                  backgroundColor: "green",
                }}
              >
                Ligar
              </Button>
              &nbsp;
              <Button
                startIcon={<PowerSettingsNewIcon />}
                color="secondary"
                variant="contained"
                onClick={() => {
                  powerSwitch(false);
                }}
                className={classes.interruptorButton}
                style={{
                  backgroundColor: "red",
                }}
              >
                Desligar
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeviceAirConditioner;
