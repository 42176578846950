import MiniDrawer from "../../Components/CustomMiniDrawer";
import ChangeToNewPlanProvider from "./Context/ChangeToNewPlanProvider";
import ChangePlanContainer from "./components/ChangePlanContainer";

const ChangeToNewPlan: React.FunctionComponent = () => {
  return (
    <MiniDrawer showSideMenu={false} withoutSideMenu={true} bgColor={"#F7F7F7"}>
      <ChangeToNewPlanProvider>
        <ChangePlanContainer />
      </ChangeToNewPlanProvider>
    </MiniDrawer>
  );
};

export default ChangeToNewPlan;
