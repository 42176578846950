import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { Button, Divider, makeStyles, Popover } from "@material-ui/core";
import EnvironmentModel from "../../../../../Models/EnvironmentModel";
import { useContext } from "react";
import EnvironmentsContext from "../../../context/EnvironmentsContext";
import ReplyIcon from "@material-ui/icons/Reply";
import SettingsIcon from "@material-ui/icons/Settings";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import GlobalContex from "../../../../../GlobalContext/GlobalContext";

const useStyles = makeStyles(() => ({
  options: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  subTitle: {
    fontWeight: "normal",
    fontSize: "15px",
    color: "#8B979F",
    textTransform: "none",
    display: "flex",
    justifyContent: "flex-end",
  },
  icon: {
    webkitTransform: "scaleX(-1)",
    transform: "scaleX(-1)",
  },
}));
interface Props {
  environment: EnvironmentModel;
}

const EnvironmentsOptions: React.FunctionComponent<Props> = (props: Props) => {
  const classes = useStyles();
  const {
    setOpenDialog,
    setEnvironmentId,
    setRoomName,
    setIsToggleOpen,
    setIsToEdit,
    showOptions,
    selectedIndex,
    reallocateDevices,
    editEnvironmentDevices,
  } = useContext(EnvironmentsContext);

  const { setDevicesToRelocate } = useContext(GlobalContex);

  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <IconButton
            id="btn-open-options-environment"
            style={{
              marginRight: "15px",
            }}
            {...bindTrigger(popupState)}
          >
            <MoreVertIcon />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                padding: "15px",
              }}
            >
              <Button
                style={{
                  color: "#8B979F",
                }}
                id="btn-realocate-multiple-devices"
                className={classes.subTitle}
                endIcon={<ReplyIcon className={classes.icon} />}
                onClick={() => {
                  reallocateDevices();
                  setDevicesToRelocate([]);
                  popupState.close();
                }}
              >
                Realocar múltiplos dispositivos
              </Button>
              {showOptions && selectedIndex !== 0 && (
                <>
                  <Button
                    id="btn-edit-devices-in-environment"
                    style={{
                      color: "#8B979F",
                    }}
                    className={classes.subTitle}
                    endIcon={<SettingsIcon />}
                    onClick={() => {
                      editEnvironmentDevices();
                      popupState.close();
                    }}
                  >
                    Editar dispositivos no ambiente
                  </Button>
                  <Divider />
                  <Button
                    id="btn-edit-environment"
                    style={{
                      color: "#8B979F",
                    }}
                    className={classes.subTitle}
                    endIcon={
                      <img
                        width="15"
                        alt=""
                        src="assets/icons/edit (1) 2.svg"
                      />
                    }
                    onClick={() => {
                      setEnvironmentId(props.environment.id);
                      setRoomName(props.environment.name);
                      setIsToggleOpen(true);
                      setIsToEdit(true);
                      popupState.close();
                    }}
                  >
                    Editar ambiente
                  </Button>
                  <Button
                    id="btn-remove-environment"
                    style={{
                      color: "#EF847E",
                    }}
                    className={classes.subTitle}
                    endIcon={<DeleteOutlineIcon />}
                    onClick={() => {
                      setOpenDialog(true);
                      setEnvironmentId(props.environment.id);
                      popupState.close();
                    }}
                  >
                    Excluir ambiente
                  </Button>
                </>
              )}
            </div>
          </Popover>
        </div>
      )}
    </PopupState>
  );
};

export default EnvironmentsOptions;
