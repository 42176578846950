import { Button, createStyles, Dialog, makeStyles } from "@material-ui/core";

interface Props {
  open: boolean;
  close: (value: boolean) => void;
  confirm: (value: boolean) => void;
}

const CannotChangePlan: React.FunctionComponent<Props> = ({
  open,
  close,
  confirm,
}: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={() => {
        close(false);
      }}
      open={open}
      className={classes.dialog}
    >
      <div className={classes.container}>
        <div style={{ textAlign: "right", marginBottom: "30px" }}>
          <Button
            className={classes.btnCloseModal}
            variant="text"
            onClick={() => close(false)}
            startIcon={<img src="assets/icons/erro_linha.svg" />}
          >
            Fechar
          </Button>
        </div>
        <div className={classes.changeContainer}>
          <img src="assets/imgs/cannot-change-plan.svg" width={400} />
          <h1>
            <strong> Não foi possível trocar o plano</strong>
          </h1>
          <p className="text-center">
            Verifique se o número de usuários, automações e dispositivos estão
            de acordo com o plano selecionado.{" "}
            <strong>
              Caso ultrapasse o limite, remova o excedente ou escolha um novo
              plano.
            </strong>
          </p>
          <Button
            variant="contained"
            color="secondary"
            style={{
              textTransform: "none",
            }}
            onClick={() => {
              close(false);
              confirm(true);
            }}
          >
            Confirmar
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default CannotChangePlan;

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      "& .MuiDialog-paperWidthSm": {
        maxWidth: "50%",
      },
    },
    container: {
      width: "100%",
      maxHeight: "100%",
    },
    btnCloseModal: {
      color: "#8B979F",
      textTransform: "capitalize",
    },
    loadingContainer: {
      width: "200px",
      height: "200px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    invoicesContainer: {
      padding: "0px 50px 50px 50px",
    },
    title: {
      fontWeight: 600,
      fontSize: "21px",
      lineHeight: "29px",
      color: "#3E5055",
      marginBottom: "20px",
    },
    table: {
      "& thead tr": {
        borderBottom: "1px solid #D1DAE0",
      },
      "& thead tr td": {
        fontWeight: 600,
        fontSize: "15px",
        lineHeight: "20px",
        textAlign: "center",
        color: "#263238",
        minWidth: "200px",
        padding: "10px",
      },
      "& tbody tr": {
        borderBottom: "1px solid #D1DAE0",
      },
      "& tbody tr td": {
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "20px",
        textAlign: "center",
        color: "#263238",
        minWidth: "200px",
        padding: "5px",
      },
    },
    changeContainer: {
      flexDirection: "column",
      padding: "40px 80px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);
