import styled from "styled-components";

export const Indicator = styled.div`
  color: #bdbdbd;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  display: block;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: start;
  height: auto;
  width: auto;
  margin: 10px 0px;
`;
