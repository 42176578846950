import {
  Button,
  CircularProgress,
  FormControl,
  FormGroup,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Settings from "@material-ui/icons/Settings";
import React, { useContext, useEffect, useState } from "react";
import DeviceModel, { DeviceFunctionsModel } from "../../../Models/DeviceModel";
import WithRoleAuthorization from "../../WithRoleAuthorization";
import { editDevice, setCommandDevice } from "../../../Services/DevicesService";
import GlobalContex from "../../../GlobalContext/GlobalContext";
import { getHomeIDSelect } from "../../../Services/UserService";
import { ErrorMessage } from "../../../Utils/ErrorMessage";
import { getCommandLabel } from "../../../Utils/DeviceConfiguration";

const useStyles = makeStyles(() => ({
  configurationContainer: {
    height: "100%",
    width: "100%",
  },
  configutationsContent: {
    height: "calc(100% - 70px)",
    overflow: "auto",
    borderBottom: "1px solid #EBEEEE",
    padding: "20px 0px",
  },
  title: {
    fontWeight: 600,
    fontSize: "23px",
    lineHeight: "31px",
    color: "#3E5055",
    display: "flex",
    alignItems: "center",
    padding: "0px 50px",
  },
  subTitle: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "25px",
    color: "#3E5055",
    margin: "25px 0px",
    padding: "0px 50px",
  },
  containerEdit: {
    display: "flex",
    alignItems: "center",
    padding: "0px 50px",
  },
  containerDeviceImg: {
    width: "50%",
  },
  imgBorder: {
    border: "1px solid #D1DAE0",
    borderRadius: "50%",
    height: "200px",
    width: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imgDevice: {
    height: "150px",
    width: "150px",
  },
  containerInput: {
    width: "50%",
  },
  formInput: {
    width: "50%",
    marginLeft: "50px",
    "& #input-device-name-helper-text": {
      textAlign: "right",
      marginRight: "0px",
    },
  },
  labelInput: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "25px",
    color: "#3E5055",
  },
  containerInfo: {
    "& div": {
      marginBottom: "15px",
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "20px",
      color: "#8B979F",
      padding: "0px 50px",
      "& strong": {
        color: "#3E5055",
      },
    },
  },
  optionsContainer: {
    border: "1px solid #D1DAE0",
    padding: "30px 50px",
    display: "flex",
    justifyContent: "space-between",
  },
  optionsLabel: {
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "20px",
    color: "#8B979F",
    display: "flex",
    alignItems: "center",
  },
  selectInput: {
    color: "#8B979F",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D1DAE0",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D1DAE0",
    },
  },
  configurationsBtnContainer: {
    height: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    padding: "0px 20px",
  },
  btnSave: {
    height: "50px",
    width: "150px",
  },
}));

interface Props {
  device: DeviceModel;
  specificationsDevice: DeviceFunctionsModel;
}

const DeviceConfigurations: React.FunctionComponent<Props> = ({
  device,
  specificationsDevice,
}: Props) => {
  const KEY_TONE_CMD_LABEL = "key_tone";

  const classes = useStyles();
  const { showAlert, onEditChangeDevice } = useContext(GlobalContex);

  const [inputValue, setInputValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [keyToneRangeValues, setKeyToneRangeValues] = useState<
    string[] | undefined
  >();
  const [selectedKeyToneValue, setSelectedKeyToneValue] = useState<
    string | undefined
  >();
  const [oldKeyToneValue, setOldKeyToneValue] = useState<string | undefined>();
  const [updatingCommand, setUpdatingCommand] = useState<boolean>(false);

  useEffect(() => {
    if (device.status) {
      const key_tone_status = device.status.find(
        (s) => s.code === KEY_TONE_CMD_LABEL
      );
      const key_tone_function = specificationsDevice.status.find(
        (s) => s.code === KEY_TONE_CMD_LABEL
      );

      if (key_tone_status && key_tone_function) {
        setSelectedKeyToneValue(key_tone_status.value as string);
        setOldKeyToneValue(key_tone_status.value as string);
        setKeyToneRangeValues(JSON.parse(key_tone_function.values).range);
      }
    }
    return () => {
      setIsLoading(false);
      setSelectedKeyToneValue(undefined);
      setUpdatingCommand(false);
    };
  }, []);

  useEffect(() => {
    setInputValue(device.name);
  }, [device]);

  const onChangeInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
  };

  const changeKeyToneValue = (e: React.ChangeEvent<{ value: unknown }>) => {
    const value = e.target.value as string;
    setSelectedKeyToneValue(value);
    submitCommand(value, device.id, KEY_TONE_CMD_LABEL);
  };

  const submitCommand = async (value: string, id: string, code: string) => {
    setUpdatingCommand(true);
    const cmd = {
      commands: [
        {
          code: code,
          value: value,
        },
      ],
    };
    await setCommandDevice(cmd, id)
      .then((response) => {
        if (response.data) {
          setSelectedKeyToneValue(value);
          showAlert("Comando atualizado com sucesso!", "success");
        }
      })
      .catch((error) => {
        setSelectedKeyToneValue(oldKeyToneValue);
        const message = ErrorMessage(
          error,
          "Houve um problema ao enviar o comando do dispositivo!"
        );
        showAlert(message, "error");
      })
      .finally(() => {
        setUpdatingCommand(false);
      });
  };

  const submitDeviceName = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const home_id = getHomeIDSelect() || "";
    await editDevice(device.id, inputValue, home_id)
      .then((response) => {
        if (response.data) {
          onEditChangeDevice(device.id, inputValue);
          showAlert("Dispositivo editado com sucesso!", "success");
        }
      })
      .catch((error) => {
        const message = ErrorMessage(
          error,
          "Houve um problema ao editar o dispositivo!"
        );
        showAlert(message, "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <CircularProgress color="secondary" size={96} />
      </div>
    );
  }

  return (
    <form
      className={classes.configurationContainer}
      onSubmit={submitDeviceName}
    >
      <div className={classes.configutationsContent}>
        <div className={classes.title}>
          <Settings style={{ marginRight: "10px" }} /> Configurações
        </div>
        <div className={classes.subTitle}>Editar dispositivo</div>
        <div className={classes.containerEdit}>
          <div className={classes.imgBorder}>
            <img src={device.icon} className={classes.imgDevice} />
          </div>
          <WithRoleAuthorization
            permitedRoles={["Owner", "Administrador"]}
            alternative={
              <FormGroup className={classes.formInput}>
                <label
                  htmlFor="input-device-name"
                  className={classes.labelInput}
                >
                  Nome do dispositivo
                </label>
                <TextField
                  label="Nome"
                  id="input-device-name"
                  variant="filled"
                  value={device.name}
                  disabled
                />
              </FormGroup>
            }
          >
            <FormGroup className={classes.formInput}>
              <label htmlFor="input-device-name" className={classes.labelInput}>
                Nome do dispositivo
              </label>
              <TextField
                label="Nome"
                id="input-device-name"
                variant="filled"
                value={inputValue}
                helperText={" " + inputValue.length + "/50 Caracteres"}
                onChange={onChangeInputValue}
                inputProps={{ maxLength: 50 }}
              />
            </FormGroup>
          </WithRoleAuthorization>
        </div>
        <div className={classes.subTitle}>Informações do dispositivo</div>
        <div className={classes.containerInfo}>
          <div>
            <strong>Dispositivo:</strong> {device.product_name}
          </div>
          <div>
            <strong>Id virtual:</strong> {device.id}
          </div>
        </div>
        {selectedKeyToneValue && keyToneRangeValues && (
          <div className={classes.optionsContainer}>
            <div className={classes.optionsLabel}>
              {getCommandLabel(device, KEY_TONE_CMD_LABEL)}
            </div>
            <FormControl variant="outlined" style={{ minWidth: "150px" }}>
              <Select
                value={selectedKeyToneValue}
                onChange={changeKeyToneValue}
                className={classes.selectInput}
                disabled={updatingCommand}
              >
                {keyToneRangeValues.map((k, i) => {
                  return (
                    <MenuItem key={"menu-key-tone-" + i} value={k}>
                      {getCommandLabel(device, k, KEY_TONE_CMD_LABEL)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        )}
      </div>
      <div className={classes.configurationsBtnContainer}>
        <Button
          className={classes.btnSave}
          type="submit"
          color="secondary"
          variant="contained"
          disabled={inputValue === device.name || inputValue.trim() === ""}
        >
          {"Salvar"}
        </Button>
      </div>
    </form>
  );
};

export default DeviceConfigurations;
