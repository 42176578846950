export const ptBR = (text: string): string => {
  const words =
    require("../DevicesConfigurations/GENERIC.json").generic_translations;
  const finalWord: string = text.replaceAll('"', "");
  return words[finalWord] ? words[finalWord] : text;
};

export const parseDateString = (seconds: string | number): string => {
  try {
    const date = new Date(0);
    date.setSeconds(+seconds);
    return date.toISOString().substr(11, 8).toString();
  } catch (e) {
    return "00:00:00";
  }
};

export const parseDateSeconds = (hms: string): number => {
  hms += ":00";
  const a = hms.split(":");

  const seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];

  return seconds;
};

interface ColorV2 {
  h: number;
  s: number;
  v: number;
}

export const colorV2Value = (color: unknown): string => {
  let h = 0;
  let s = 0;
  let v = 0;

  if (typeof color === "string") {
    try {
      color = JSON.parse(color);
    } catch (error) {
      h = 0;
      s = 0;
      v = 0;
    }
  }

  if (color && typeof color === "object") {
    const c = color as ColorV2;
    h = c.h ? c.h : 0;
    s = c.s ? c.s / 10 : 0;
    v = c.v ? c.v / 10 : 0;
  }

  return "hsl(" + h + "deg, " + s + "%, " + v + "%)";
};

export const checkIsJSON = (value: string): boolean => {
  try {
    JSON.parse(value);
  } catch (error) {
    return false;
  }
  return true;
};

const _MS_PER_DAY = 1000 * 60 * 60 * 24;
export function dateDiffInDays(dateA: Date, dateB: Date): number {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(dateA.getFullYear(), dateA.getMonth(), dateA.getDate());
  const utc2 = Date.UTC(dateB.getFullYear(), dateB.getMonth(), dateB.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function randomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Retirado de:
 * https://stackoverflow.com/questions/21816595/how-to-generate-a-random-number-of-fixed-length-using-javascript
 * @param size
 */
export function generateStringOfNumbers(size: number): string {
  const add = 1;
  let max = 12 - add; // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.

  if (size > max) {
    return generateStringOfNumbers(max) + generateStringOfNumbers(size - max);
  }

  max = Math.pow(10, size + add);
  const min = max / 10; // Math.pow(10, n) basically
  const number = randomInt(min, max);

  return ("" + number).substring(add);
}

export function generateStringOfHex(size: number): string {
  return [...Array(size)]
    .map(() => Math.floor(Math.random() * 16).toString(16))
    .join("");
}

/**
 * Recebe um número e retorna o binário correspondente em string.
 *
 * Retirado de:
 * https://stackoverflow.com/questions/9939760/how-do-i-convert-an-integer-to-binary-in-javascript
 * @param value
 */
export function decimalToBinaryString(value: number): string {
  return (value >>> 0).toString(2);
}

/**
 * Recebe uma string no formato "010101" e retorna o valor decimal correspondente.
 * @param str
 */
export function binaryStringtoDecimal(str: string): number {
  const strBinary = Array.from<string>(str);
  let decimal = 0;
  for (let i = 0; i < strBinary.length; i++) {
    decimal = decimal * 2 + parseInt(strBinary[i]);
  }
  return decimal;
}
