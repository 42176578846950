import {
  makeStyles,
  Modal,
  Divider,
  IconButton,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui//icons/Close";
import { useContext } from "react";
import ManageRoomContext from "../../Context/ManageRoomContext";
import EnvironmentModel from "../../../../Models/EnvironmentModel";
import history from "../../../../Services/history";
import GlobalContex from "../../../../GlobalContext/GlobalContext";

const RoomModal: React.FunctionComponent = () => {
  const classes = useStyles();
  const { modalRoomClose, openModalRoom, selectedRoomModal } =
    useContext(ManageRoomContext);
  const { setRoomIdR } = useContext(GlobalContex);

  return (
    <div>
      <Modal open={openModalRoom} onClose={modalRoomClose}>
        <div className={classes.modalContainer}>
          <div className={classes.modalTitleContainer}>
            <div className={classes.modalTitleLabel}>Ambientes</div>
            <IconButton
              className={classes.modalCloseIcon}
              onClick={modalRoomClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Divider />

          <Grid container spacing={2} className={classes.modalContent}>
            {selectedRoomModal &&
            selectedRoomModal.environments !== undefined &&
            selectedRoomModal.environments !== null ? (
              <>
                {selectedRoomModal.environments.length > 0 ? (
                  selectedRoomModal.environments.map(
                    (env: EnvironmentModel, index: number) => {
                      return (
                        <Grid
                          item
                          xs={4}
                          key={env.id}
                          onClick={() => {
                            setRoomIdR(selectedRoomModal);
                            history.push("/", {
                              dashboardTabValue: 1,
                              environment: JSON.stringify(env),
                              index: index + 1,
                            });
                          }}
                        >
                          <div className={classes.modalContentCard}>
                            {env.name}
                          </div>
                        </Grid>
                      );
                    }
                  )
                ) : (
                  <Grid item xs={12}>
                    <div>Este Espaço não tem nenhum Ambiente</div>
                  </Grid>
                )}
              </>
            ) : (
              <Grid item xs={12}>
                <div>Este Espaço não tem nenhum Ambiente</div>
              </Grid>
            )}
          </Grid>
        </div>
      </Modal>
    </div>
  );
};

export default RoomModal;

const useStyles = makeStyles(() => ({
  modalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "400px",
    width: "70%",
    maxWidth: "900px",
    borderRadius: "25px",
    background: "rgba(255, 255, 255, 0.9)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  modalTitleContainer: {
    height: "60px",
    display: "flex",
  },
  modalTitleLabel: {
    margin: "auto 50px",
    fontSize: "21px",
    fontWeight: 700,
    lineHeight: "29px",
  },
  modalCloseIcon: {
    position: "absolute",
    right: "0px",
  },
  modalContent: {
    width: "90%",
    margin: "25px auto",
    overflow: "auto",
    maxHeight: "250px",
  },
  modalContentCard: {
    height: "65px",
    borderRadius: "5px",
    background: "#D1DAE0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "20px",
    cursor: "pointer",
  },
}));
