import React from "react";

import * as S from "./styled";

type PasswordSlotsViewerProps = {
  password: string;
  length?: number; // aplicado apenas se password vazio
  visible?: boolean;
};

export const PasswordSlotsViewer: React.FunctionComponent<
  PasswordSlotsViewerProps
> = ({ password, length = 6, visible = true }) => {
  const slots = password?.length > 0 ? password : " ".repeat(length);
  return (
    <S.PasswordSlotsContainer>
      {Array.from(slots).map((char, index) => (
        <S.PasswordSlots
          key={`password-slot-${index}`}
          className={`${visible ? "" : "hide"}`}
        >
          <span>{visible ? char : "*"}</span>
        </S.PasswordSlots>
      ))}
    </S.PasswordSlotsContainer>
  );
};
