import styled from "styled-components";
import { ButtonFilled } from "../../../../Components/CustomButton";

export const Container = styled.div`
  display: flex;
  padding: 10px 3rem;
  justify-content: space-between;
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  max-width: 800px;
  gap: 20px;
`;

export const ButtonFilledStyled = styled(ButtonFilled)`
  font-weight: normal;

  &.delete {
    background-color: #EF847E;
    &:hover {
      background-color: #EF847E;
    }
  },
`;

export const Actions = styled.div`
  display: flex;

  ${Filters} + & {
    margin-left: 20px;
  }

  ${ButtonFilledStyled} + ${ButtonFilledStyled} {
    margin-left: 10px;
  }

  .hidden {
    display: none;
    @media (min-width: 640px) {
      display: block;
    }
    @media (min-width: 980px) {
      display: none;
    }
    @media (min-width: 1280px) {
      display: block;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  @media (max-width: 980px) {
    flex-direction: column;

    ${Filters} {
      margin-bottom: 20px;

      + ${Actions} {
        margin-left: 0px;
      }
    }
  }
`;

export const CloseHeader = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;

  @media (max-width: 980px) {
    align-items: end;
  }
`;
