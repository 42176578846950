import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { Button, Checkbox, Divider, IconButton } from "@material-ui/core";
import DrawerProps from "../../../../Types/DrawerProps";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AutomationPageContext from "../../Context/AutomationPageContext";
import SmsIcon from "@material-ui/icons/Sms";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DeviceModel, {
  DeviceFunctionsModel,
} from "../../../../Models/DeviceModel";
import CustomDefaultDevice from "../../../../Components/CustomDevices/Devices/CustomDefaultDevice/Device";
import { Action } from "../../../../Models/SceneModel";
import { Condition } from "../../../../Models/Automation";
import EnableDisableOption from "./EnableDisableOption";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import CustomConfirmDialog from "../../../../Components/CustomConfirmDialog";
import ScheduleConditions from "./ScheduleConditions";
import ShowDevices from "./ShowDevices";
import {
  filterSpecifications,
  getCommandLabel,
} from "../../../../Utils/DeviceConfiguration";
import ShowConditions from "./ShowConditions";
import { getFetchSpecifications } from "../../../../Services/DevicesService";
import { colorV2Value, ptBR } from "../../../../Utils/Functions";
import CustomDrawerAutomationMenu from "./CustomDrawerAutomationMenu";
import FavoritedAutomationModal from "./FavoritedAutomationModal";
import CustomBlockResource from "../../../../Components/CustomBlockResource";
import {
  isUserAdmin,
  isUserOwner,
} from "../../../../Services/UserHomeInfoLocalService";
import { ErrorMessage } from "../../../../Utils/ErrorMessage";
import { AxiosError } from "axios";

export enum ActionMode {
  None = 0,
  AddTask = 1,
  AddCondition = 2,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      backgroundColor: "#FFF !important",
      maxHeight: "100vh",
      minHeight: "75px",
    },
    fullList: {
      width: "auto",
    },
    container: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "10px 123px",
    },
    row: {
      display: "flex",
      padding: "25px",
      justifyContent: "start",
      alignItems: "center",
    },
    automationRow: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
    },
    container2: {
      display: "flex",
      padding: "10px 0px",
      height: "90vh",
    },
    drawerPaper: {
      backgroundColor: "#FFF !important",
      width: "680px",
    },
    paper: {
      background: "blue",
      width: "700px",
    },
    root: {
      width: "100%",
      marginBottom: "5px",
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    button: {
      "&:hover": {
        backgroundColor: theme.palette.error.main,
        color: "#FFF",
      },
    },
    editContainer: {
      minHeight: "70px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: "60px",
    },
    editTitle: {
      fontWeight: 600,
      fontSize: "23px",
      lineHeight: "31px",
      color: "#3E5055",
    },
    inputName: {
      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#6DCC9A",
      },
      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#00A335",
      },
    },
    labelTitle: {
      color: "#3E5055",
      fontSize: "20px",
      marginBottom: "0px",
      fontWeight: 600,
      lineHeight: "25px",
    },
    labelSubTitle: {
      marginTop: "0px",
      color: "#8B979F",
      fontSize: "15px",
      fontWeight: 400,
      lineHeight: "20px",
    },
  })
);

interface CondicoesInterface {
  titulo: string;
  icone?: string;
  subtitulo: string;
}

interface TarefasInterface {
  titulo: string;
  icone?: string;
}

const tarefas: TarefasInterface[] = [
  {
    titulo: "Executar dispositivo",
    icone: "assets/icons/lamp (2) 1.svg",
  },
  {
    titulo: "Selecione Smart",
    icone: "assets/icons/wifi 2.svg",
  },
];

const condicoes: CondicoesInterface[] = [
  {
    titulo: "Clique para executar",
    subtitulo: "Exemplo: apagar as luzes e fechar a porta com um clique",
    icone: "assets/icons/tap 2.svg",
  },
  {
    titulo: "Programar agendamento",
    icone: "assets/icons/clock (1) 1.svg",
    subtitulo: "Exemplo: todos os dias às 7h as luzes se acendem",
  },
  {
    titulo: "Quando o status do dispositivo muda",
    icone: "assets/icons/lamp (1) 1.svg",
    subtitulo: "Exemplo: quando uma luz se apagar algo deve acontecer",
  },
];

const anchor: DrawerProps = "right";

const CustomDrawer: React.FunctionComponent = () => {
  const classes = useStyles();
  const {
    textFieldAutomacao,
    allDevices,
    allSceneDevices,
    changeNomeDaAutomacao,
    toggleDrawer,
    device,
    setActions,
    actions,
    isToggleOpen,
    createSceneContext,
    editSceneContext,
    editAutomationContext,
    automations,
    isToEdit,
    setWasScene,
    setWasAutomation,
    wasScene,
    wasAutomation,
    automationsToEdit,
    resetForm,
    isScene,
    setIsScene,
    conditions,
    setConditions,
    setIsAutomation,
    enableCreateAutomation,
    removeSceneContext,
    removeAutomationContext,
    addCondition,
    setAddCondition,
    addTask,
    setAddTask,
    isMustShowConditions,
    setIsMustShowConditions,
    actionsTemp,
    setActionsTemp,
    openDialog,
    setOpenDialog,
    caminho,
    openDialogAutomation,
    setOpenDialogAutomation,
    activeAutomation,
    setUpdateDashboardAutomations,
    updateDashboardAutomations,
    automationId,
    handleCloseModalDelete,
    goBack,
    goFoward,
    showOptions,
    showDevices,
    showDeviceComponent,
    showAutomations,
    showSchedule,
    profundidade,
    isAutomation,
    scenes,
    createAutomationContext,
    isBlockedAutomation,
  } = useContext(AutomationPageContext);

  const [devicesToShow, setDevicesToShow] = useState<DeviceModel[]>([]);
  const [actionMode, setActionMode] = useState<ActionMode>(ActionMode.None);

  const { showAlert, setShowHistory } = useContext(GlobalContex);

  const [nameAutomation, setNameAutomation] = useState("");

  const transitionDuration = 700;

  const [open, setOpen] = useState(false);

  const [specificationsDevice, setSpecificationsDevice] =
    useState<DeviceFunctionsModel>({ category: "", functions: [], status: [] });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setSpecificationsDevice({ category: "", functions: [], status: [] });
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    if (device.id) {
      setLoading(true);

      const fetchSpecifications = async () => {
        try {
          const { data } = await getFetchSpecifications(device.id);
          setSpecificationsDevice(filterSpecifications(device, data));
        } catch (error) {
          const e = error as AxiosError;
          if (e.isAxiosError && e.request.status !== 400) {
            console.error(
              ErrorMessage(e, "Falha ao carregar comandos do dispositivo")
            );
          }
        } finally {
          setLoading(false);
        }
      };

      fetchSpecifications();
    }
  }, [device]);

  const [valuesCheck, setValuesCheck] = useState<boolean[]>([]);
  const [changeDeviceCondition, setChangeDeviceCondition] =
    useState<Condition>();
  const [id, setId] = useState<string>("");

  const hasPermition: boolean = isUserAdmin() || isUserOwner();

  const [actionsTempDrawer, setActionsTempDrawer] = useState<Action[]>([]);

  useEffect(() => {
    setChangeDeviceCondition(undefined);
  }, [conditions]);

  const saveAutomation = () => {
    if (isToEdit && isScene && !wasAutomation && automationsToEdit.scene_id) {
      editSceneContext(automationsToEdit.scene_id);
    } else if (
      isToEdit &&
      isAutomation &&
      !wasScene &&
      automationsToEdit.automation_id
    ) {
      editAutomationContext(automationsToEdit.automation_id);
    } else if (
      isToEdit &&
      isScene &&
      wasAutomation &&
      automationsToEdit.automation_id
    ) {
      removeAutomationContext(
        automationsToEdit.automation_id,
        automationsToEdit.name
      );
      createSceneContext();
    } else if (
      isToEdit &&
      isAutomation &&
      wasScene &&
      automationsToEdit.scene_id
    ) {
      removeSceneContext(automationsToEdit.scene_id, automationsToEdit.name);
      createAutomationContext();
    } else if (!isToEdit && isScene) {
      createSceneContext();
    } else if (!isToEdit && isAutomation) {
      createAutomationContext();
    } else {
      showAlert("Verifique os campos do formulário", "warning");
    }
  };

  const editChangeDeviceCondition = (
    commandJson: any,
    entity_id: any,
    function_code: any,
    operator = "=="
  ) => {
    const condition: Condition = {
      display: {
        code: function_code,
        operator,
        value: commandJson,
      },
      entity_id: entity_id,
      entity_type: 1,
      order_num: 1,
    };
    setChangeDeviceCondition(condition);
  };

  const addAction = (commandJson: any, entity_id: any, function_code: any) => {
    if (function_code === "colour_data_v2") {
      if (!commandJson) {
        commandJson = {
          h: 0,
          s: 0,
          v: 0,
        };
      }
      if (typeof commandJson === "string" && commandJson !== "") {
        commandJson = JSON.parse(commandJson);
      }
      commandJson.h = commandJson.h || 0;
      commandJson.s = commandJson.s || 0;
      commandJson.v = commandJson.v || 0;
    }

    const action: Action = {
      action_executor: "dpIssue",
      executor_property: {
        [function_code]: commandJson,
      },
      entity_id,
    };

    setActionsTempDrawer([...actionsTempDrawer, action]);
  };

  const addActionSmartScene = (entity_id: any) => {
    const action_executor = "ruleTrigger";
    const action: Action = {
      action_executor,
      executor_property: {},
      entity_id,
    };
    setActionsTemp([...actionsTemp, action]);
  };

  const addActionSmart = (entity_id: any, enabled: boolean) => {
    const action_executor: string = enabled ? "ruleEnable" : "ruleDisable";
    const action: Action = {
      action_executor,
      executor_property: {},
      entity_id,
    };
    setActionsTemp([...actionsTemp, action]);
  };

  const removeActionSmart = (entity_id: any) => {
    setActionsTemp(
      actionsTempDrawer.filter((a) => {
        return a.entity_id !== entity_id;
      })
    );
  };

  const removeAction = (function_code: any) => {
    setActionsTempDrawer(
      actionsTempDrawer.filter((a) => {
        return Object.keys(a.executor_property)[0] !== function_code;
      })
    );
  };

  const editAction = (entity_id: any, commandJson: any, function_code: any) => {
    setActionsTempDrawer(
      actionsTempDrawer.map((a: any) => {
        if (
          Object.keys(a.executor_property)[0] === function_code &&
          a.entity_id === entity_id
        ) {
          a.executor_property[function_code] = commandJson;
        }

        return a;
      })
    );
  };

  const handleClick = (index: number) => {
    if (index === 0) {
      setOpen(!open);
    }
  };

  const ListaDeTarefas = () => (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      {addTask &&
        tarefas.map((tarefa: TarefasInterface, index: number) => (
          <ListItem
            key={"task-list-item" + index}
            autoFocus
            button
            onClick={() => {
              setAddTask(!addTask);
              handleClick(index);
              setActionMode(ActionMode.AddTask);
              if (index === 0) {
                setDevicesToShow(allSceneDevices);
                goFoward(profundidade, "dispositivos");
              } else if (index === 1) {
                goFoward(profundidade, "automacoes");
              }
            }}
            disabled={index > 0 ? false : false}
            style={{
              border: "1px solid #D1DAE0",
              boxSizing: "border-box",
              margin: "3px 0px",
              borderRadius: "5px",
              height: "76px",
              paddingLeft: "28px",
            }}
          >
            <ListItemIcon>
              <img src={tarefa.icone} alt={tarefa.titulo} />
            </ListItemIcon>
            <ListItemText primary={tarefa.titulo} />
            <ArrowForwardIosIcon fontSize="inherit" />
          </ListItem>
        ))}
    </List>
  );

  const ListaDeCondicoes = () => (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      {addCondition &&
        isMustShowConditions &&
        condicoes.map((condicao: CondicoesInterface, index: number) => (
          <ListItem
            key={"condition-list-item-" + index}
            button
            onClick={() => {
              handleClick(index);
              setActionMode(ActionMode.AddCondition);
              if (index === 0) {
                if (wasScene) {
                  setWasScene(false);
                }
                setIsScene(true);
                setAddCondition(!addCondition);
                setIsMustShowConditions(false);
              }
              if (index === 1) {
                if (wasAutomation) {
                  setWasAutomation(false);
                }
                setAddCondition(!addCondition);
                goFoward(profundidade, "agendamento");
              }
              if (index === 2) {
                if (wasAutomation) {
                  setWasAutomation(false);
                }
                setAddCondition(!addCondition);
                setDevicesToShow(allDevices);
                goFoward(profundidade, "mudanca");
              }
            }}
            disabled={index === 0 && isAutomation}
            style={{
              border: "1px solid #D1DAE0",
              boxSizing: "border-box",
              margin: "3px 0px",
              borderRadius: "5px",
              height: "76px",
              paddingLeft: "28px",
            }}
          >
            <ListItemIcon>
              <img src={condicao.icone} alt={condicao.titulo} />
            </ListItemIcon>
            <ListItemText primary={condicao.titulo} />
            <ArrowForwardIosIcon fontSize="inherit" />
          </ListItem>
        ))}
    </List>
  );

  const list = (anchor: DrawerProps) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
    >
      <div className={classes.row}>
        <IconButton
          aria-label="delete"
          size="small"
          style={{
            fontSize: "15px",
            color: "#8B979F",
          }}
          onClick={() => {
            setShowHistory(false);
            goBack(profundidade);
            setActionsTempDrawer([]);
          }}
          onKeyDown={() => toggleDrawer(false)}
        >
          <ArrowBackIosIcon fontSize="inherit" />
        </IconButton>
        <p
          style={{
            margin: "0px",
            fontSize: "15px",
            color: "#8B979F",
          }}
        >
          Voltar
        </p>
      </div>
      <Divider />
    </div>
  );

  return (
    <>
      <React.Fragment key={anchor}>
        <Drawer
          transitionDuration={{
            enter: transitionDuration,
            exit: transitionDuration,
          }}
          classes={{
            paper: classes.drawerPaper,
          }}
          PaperProps={{ elevation: 9 }}
          anchor={anchor}
          open={isToggleOpen}
        >
          {list(anchor)}

          <div
            style={{
              position: "relative",
            }}
          >
            {isBlockedAutomation && (
              <CustomBlockResource
                icon={"icon_padlock"}
                title={"Automação bloqueada"}
                direction={"column"}
                border={"10px"}
                subtitle={
                  "Você atingiu o limite de automações do seu plano. Exclua automações ou contrate um novo plano"
                }
              />
            )}

            {isToEdit && (
              <>
                <div className={classes.editContainer}>
                  <div className={classes.editTitle}>Editar automação</div>
                  {isScene && <CustomDrawerAutomationMenu />}
                </div>
                <Divider />
              </>
            )}
            {showOptions && (
              <div
                style={{
                  minHeight: isToEdit
                    ? "calc(100% - 150px)"
                    : "calc(100% - 75px)",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#FFF",
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    height: isToEdit
                      ? "calc(100% - 70px)"
                      : "calc(100% - 70px)",
                    overflow: "auto",
                  }}
                >
                  <div
                    style={{
                      margin: "20px 63px 40px 63px",
                    }}
                  >
                    <p className={classes.labelTitle}>Nome da Automação</p>
                    <p className={classes.labelSubTitle}>
                      Ex: Fechamento da sala
                    </p>
                    <TextField
                      onChange={changeNomeDaAutomacao}
                      value={textFieldAutomacao}
                      label=""
                      variant="outlined"
                      InputProps={{
                        readOnly: !hasPermition,
                        className: classes.inputName,
                      }}
                      inputProps={{
                        maxLength: 60,
                      }}
                    />
                  </div>
                  <Divider />
                  <div
                    style={{
                      margin: "20px 63px 0px 63px",
                    }}
                  >
                    <p className={classes.labelTitle}>Condição</p>
                    <p className={classes.labelSubTitle}>Se...</p>

                    {isScene && (
                      <>
                        <ListItem
                          style={{
                            border: "1px solid #D1DAE0",
                            boxSizing: "border-box",
                            margin: "6px 0px",
                            borderRadius: "5px",
                            height: "76px",
                            backgroundColor: "#EBEEEE",
                            paddingLeft: "28px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src="assets/icons/tap 2.svg"
                              alt="tocar para executar"
                              style={{
                                marginRight: "10px",
                              }}
                            />
                            Clique para executar
                          </div>
                          <IconButton
                            aria-label="delete"
                            size="medium"
                            style={{
                              fontSize: "15px",
                              color: "#8B979F",
                            }}
                            onClick={() => {
                              setIsMustShowConditions(true);
                              setActions([]);
                              setIsScene(false);
                              setAddTask(false);
                              if (isToEdit) {
                                setWasScene(true);
                              }
                            }}
                          >
                            <DeleteOutlineIcon fontSize="inherit" />
                          </IconButton>
                        </ListItem>
                      </>
                    )}

                    <ShowConditions />

                    <Button
                      startIcon={<AddIcon fontSize="small" />}
                      style={{
                        textTransform: "none",
                        width: "200px",
                        height: "54px",
                        marginBottom: !open ? "5px" : "0px",
                      }}
                      disabled={isScene || enableCreateAutomation}
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        setAddCondition(!addCondition);
                      }}
                    >
                      {"Adicionar condição"}
                    </Button>
                    {ListaDeCondicoes()}
                  </div>
                  <Divider />
                  <div
                    style={{
                      margin: "20px 63px 0px 63px",
                    }}
                  >
                    <p className={classes.labelTitle}>Tarefa</p>
                    <p className={classes.labelSubTitle}>Faça... </p>
                    <List
                      component="nav"
                      aria-labelledby="nested-list-subheader"
                      className={classes.root}
                      style={{ color: "#8B979F" }}
                    >
                      {actions &&
                        actions.map((action, i) => {
                          const key = Object.keys(
                            action?.executor_property ?? []
                          );
                          const deviceFind = allDevices.find(
                            (d: DeviceModel) => d.id === action.entity_id
                          );
                          const automationFind = automations.find(
                            (d) => d.automation_id === action.entity_id
                          );

                          const sceneFind = scenes.find(
                            (d) => d.scene_id === action.entity_id
                          );

                          const notificationFind: boolean =
                            action.action_executor === "appPushTrigger";

                          action.actionName =
                            deviceFind?.name ||
                            automationFind?.name ||
                            sceneFind?.name ||
                            "";

                          const item = (
                            <ListItem
                              key={i}
                              style={{
                                backgroundColor: "#EBEEEE",
                                border: "1px solid #D1DAE0",
                                boxSizing: "border-box",
                                margin: "3px 0px",
                                borderRadius: "5px",
                                height: "76px",
                                paddingLeft: "28px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  flex: 1,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flex: 1,
                                  }}
                                >
                                  <ListItemIcon
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {notificationFind && (
                                      <SmsIcon fontSize="large" color="error" />
                                    )}
                                    {!notificationFind && (
                                      <img
                                        src={
                                          deviceFind
                                            ? deviceFind?.icon
                                            : "assets/icons/smart-energy.svg"
                                        }
                                        width={40}
                                        alt={
                                          deviceFind ? deviceFind?.icon : "icon"
                                        }
                                      />
                                    )}
                                  </ListItemIcon>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "start",
                                      justifyContent: "start",
                                    }}
                                  >
                                    <p
                                      style={{
                                        color: "#000",
                                        fontWeight: "bold",
                                        fontSize: "15px",
                                        margin: "0px",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        width: "11rem",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {deviceFind && deviceFind?.name}
                                      {automationFind && automationFind?.name}
                                      {sceneFind && sceneFind?.name}
                                      {notificationFind &&
                                        "Centro de mensagens"}
                                    </p>
                                    <p
                                      style={{
                                        margin: "0px",
                                        display: "flex",
                                      }}
                                    >
                                      <span>
                                        {deviceFind &&
                                          getCommandLabel(
                                            action?.device?.productName,
                                            key[0]
                                          ) + ": "}
                                      </span>
                                      {deviceFind && (
                                        <span>
                                          {action.executor_property[key[0]] ===
                                          true ? (
                                            " ON"
                                          ) : action.executor_property[
                                              key[0]
                                            ] === false ? (
                                            " OFF"
                                          ) : key[0] === "colour_data_v2" ? (
                                            <div
                                              style={{
                                                border: "1px solid grey",
                                                marginLeft: "10px",
                                                backgroundColor: colorV2Value(
                                                  action.executor_property[
                                                    key[0]
                                                  ]
                                                ),
                                                height: "20px",
                                                width: "20px",
                                                borderRadius: "50%",
                                              }}
                                            ></div>
                                          ) : (
                                            ptBR(
                                              action.executor_property[
                                                key[0]
                                              ].toString()
                                            )
                                          )}
                                        </span>
                                      )}
                                      {automationFind &&
                                        (action.action_executor === "ruleEnable"
                                          ? "Habilitar"
                                          : "Desabilitar")}

                                      {sceneFind && "Trigger"}
                                      {notificationFind && "Ligado"}
                                    </p>
                                  </div>
                                </div>

                                <IconButton
                                  aria-label="delete"
                                  size="medium"
                                  style={{
                                    fontSize: "15px",
                                    color: "#8B979F",
                                  }}
                                  onClick={() => {
                                    setActions(
                                      actions.filter(
                                        (a: Action) => a !== action
                                      )
                                    );
                                  }}
                                >
                                  <DeleteOutlineIcon fontSize="inherit" />
                                </IconButton>
                              </div>
                            </ListItem>
                          );

                          if (deviceFind) return item;
                          return item;
                        })}
                    </List>
                    <Button
                      startIcon={<AddIcon fontSize="small" />}
                      style={{
                        textTransform: "none",
                        width: "200px",
                        height: "54px",
                      }}
                      color="secondary"
                      variant="contained"
                      disabled={conditions?.length === 0 && !isScene}
                      onClick={() => {
                        setAddTask(!addTask);
                        setActionsTemp([...actions]);
                      }}
                    >
                      {"Adicionar tarefa"}
                    </Button>
                    {ListaDeTarefas()}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    paddingRight: "15px",
                    paddingBottom: "15px",
                  }}
                >
                  {isToEdit && (
                    <Button
                      startIcon={<DeleteOutlineIcon fontSize="small" />}
                      style={{
                        margin: "5px",
                        textTransform: "none",
                        width: "168px",
                        height: "45px",
                      }}
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        const id = isScene
                          ? automationsToEdit.scene_id
                          : automationsToEdit.automation_id;

                        if (id) {
                          setId(id);
                        }
                        setNameAutomation(automationsToEdit.name);

                        setOpenDialog(true);
                      }}
                      className={classes.button}
                    >
                      {"Remover"}
                    </Button>
                  )}
                  {!isToEdit && (
                    <Button
                      style={{
                        margin: "5px",
                        textTransform: "none",
                        width: "168px",
                        height: "45px",
                      }}
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        resetForm();
                      }}
                      className={classes.button}
                    >
                      {"Resetar"}
                    </Button>
                  )}
                  <Button
                    style={{
                      margin: "5px",
                      textTransform: "none",
                      width: "168px",
                      height: "45px",
                    }}
                    color="secondary"
                    variant="contained"
                    disabled={!hasPermition}
                    onClick={() => {
                      saveAutomation();
                    }}
                  >
                    {"Salvar automação"}
                  </Button>
                </div>
              </div>
            )}
            {showDevices && <ShowDevices showDevices={devicesToShow} />}
            {showDeviceComponent && (
              <>
                <CustomDefaultDevice
                  isAutomationForm={true}
                  isOptional={caminho === "mudanca" ? true : false}
                  isSelectable={caminho === "dispositivos" ? true : false}
                  showConditionType={actionMode === ActionMode.AddCondition}
                  device={device}
                  status={device.status}
                  loading={loading}
                  specificationsDevice={specificationsDevice}
                  processChangeCommand={(
                    commandJson: any,
                    device_id: any,
                    function_code: any,
                    operator?: any
                  ) => {
                    caminho === "mudanca"
                      ? editChangeDeviceCondition(
                          commandJson,
                          device_id,
                          function_code,
                          operator
                        )
                      : editAction(device_id, commandJson, function_code);
                  }}
                  selectRowChange={(
                    code: any,
                    selected: any,
                    value: any,
                    operator: any
                  ) => {
                    if (!selected) {
                      removeAction(code);
                    } else {
                      caminho === "mudanca"
                        ? editChangeDeviceCondition(
                            value,
                            device.id,
                            code,
                            operator
                          )
                        : addAction(value, device.id, code);
                    }
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    margin: "14px 0px",
                    backgroundColor: "#FFF",
                  }}
                >
                  <Button
                    style={{
                      margin: "5px",
                      textTransform: "none",
                      width: "168px",
                      height: "45px",
                    }}
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      if (changeDeviceCondition) {
                        setIsAutomation(true);
                        setConditions([...conditions, changeDeviceCondition]);
                      }
                      setActions([...actions, ...actionsTempDrawer]);
                      setActionsTempDrawer([]);
                      goBack(1);
                    }}
                  >
                    {"Confirmar"}
                  </Button>
                </div>
              </>
            )}
            {showAutomations && (
              <>
                <div
                  style={{
                    backgroundColor: "#FFF",
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      margin: "0px 63px",
                    }}
                  >
                    <p
                      style={{
                        color: "#3E5055",
                        fontSize: "15px",
                        marginBottom: "0px",
                      }}
                    >
                      Automações{" "}
                    </p>
                    {((isAutomation &&
                      automations.length === 0 &&
                      scenes.length === 0) ||
                      (isScene && automations.length === 0)) && (
                      <>
                        <p
                          style={{
                            marginTop: "30px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          Não existem automações disponíveis
                        </p>
                      </>
                    )}
                    <List
                      component="nav"
                      aria-labelledby="nested-list-subheader"
                      className={classes.root}
                    >
                      {isAutomation &&
                        scenes?.map((scene, i) => {
                          const find = actionsTemp.find(
                            (actionsTemp) =>
                              actionsTemp.entity_id === scene.scene_id
                          );
                          valuesCheck[i] = find ? true : false;
                          let isCheck = valuesCheck[i] ?? false;
                          return (
                            <>
                              <div className={classes.automationRow}>
                                <ListItem
                                  style={{
                                    backgroundColor: "#EBEEEE",
                                    border: "1px solid #D1DAE0",
                                    boxSizing: "border-box",
                                    margin: "3px 0px",
                                    borderRadius: "5px",
                                    height: "76px",
                                    paddingLeft: "28px",
                                  }}
                                >
                                  <Checkbox
                                    checked={isCheck}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>,
                                      checked: boolean
                                    ) => {
                                      valuesCheck[i] = checked;
                                      isCheck = !isCheck;
                                      setValuesCheck([...valuesCheck]);
                                      if (checked)
                                        addActionSmartScene(scene.scene_id);
                                      else removeActionSmart(scene.scene_id);
                                    }}
                                  />
                                  <ListItemText
                                    primary={scene.name}
                                    style={{
                                      fontSize: "18px",
                                    }}
                                  />
                                </ListItem>{" "}
                              </div>
                            </>
                          );
                        })}

                      {automations?.map((automatation, i) => {
                        const find = actionsTemp.find(
                          (actionsTemp) =>
                            actionsTemp.entity_id === automatation.automation_id
                        );
                        valuesCheck[i] = find ? true : false;
                        let isCheck = valuesCheck[i] ?? false;
                        return (
                          <>
                            <div className={classes.automationRow}>
                              <ListItem
                                style={{
                                  backgroundColor: "#EBEEEE",
                                  border: "1px solid #D1DAE0",
                                  boxSizing: "border-box",
                                  margin: "3px 0px",
                                  borderRadius: "5px",
                                  height: "76px",
                                  paddingLeft: "28px",
                                }}
                              >
                                <Checkbox
                                  checked={isCheck}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                    checked: boolean
                                  ) => {
                                    valuesCheck[i] = checked;
                                    isCheck = !isCheck;
                                    setValuesCheck([...valuesCheck]);
                                    if (checked)
                                      addActionSmart(
                                        automatation.automation_id,
                                        true
                                      );
                                    else
                                      removeActionSmart(
                                        automatation.automation_id
                                      );
                                  }}
                                />
                                <ListItemText
                                  primary={automatation.name}
                                  style={{
                                    fontSize: "18px",
                                  }}
                                />
                              </ListItem>{" "}
                            </div>
                            <EnableDisableOption
                              enabled={find?.action_executor}
                              display={valuesCheck[i]}
                              id={automatation.automation_id ?? ""}
                            />
                          </>
                        );
                      })}
                    </List>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        margin: "14px 0px",
                        backgroundColor: "#FFF",
                      }}
                    >
                      <Button
                        style={{
                          margin: "5px",
                          textTransform: "none",
                          width: "168px",
                          height: "45px",
                        }}
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                          goBack(1);
                          setActions([...actionsTemp]);
                        }}
                      >
                        {"Confirmar"}
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {showSchedule && (
              <>
                <div
                  style={{
                    backgroundColor: "#FFF",
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      margin: "0px 63px",
                    }}
                  >
                    <div
                      style={{
                        color: "#3E5055",
                        fontSize: "15px",
                        marginBottom: "0px",
                      }}
                    >
                      <ScheduleConditions />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </Drawer>
      </React.Fragment>
      <CustomConfirmDialog
        onClose={handleCloseModalDelete}
        onCloseFocus={handleCloseModalDelete}
        openDialog={openDialog}
        onConfirmButton={() => {
          isAutomation
            ? removeAutomationContext(id.toString(), nameAutomation)
            : removeSceneContext(id.toString(), nameAutomation);
        }}
        onCancelButton={handleCloseModalDelete}
        text={`Você realmente deseja remover a automação `}
        roomName={nameAutomation}
      />
      <CustomConfirmDialog
        onClose={() => {
          handleCloseModalDelete();
        }}
        onCloseFocus={() => {
          handleCloseModalDelete();
        }}
        openDialog={openDialogAutomation}
        onConfirmButton={() => {
          setOpenDialogAutomation(false);
          activeAutomation(automationId, function () {
            setUpdateDashboardAutomations(!updateDashboardAutomations);
          });
        }}
        onCancelButton={() => {
          handleCloseModalDelete();
        }}
        canUndo={true}
        text={`Deseja começar a utilizar a nova automação`}
        roomName={""}
      />
      <FavoritedAutomationModal />
    </>
  );
};

export default CustomDrawer;
