import { makeStyles } from "@material-ui/core/styles";

interface Props {
  number: number;
  text: string;
  subtext?: string;
}

const useStyles = makeStyles(() => ({
  customInfoBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyItems: "start",

    "& .step": {
      borderRadius: "50%",
      width: "35px",
      height: "35px",
      minWidth: "35px",
      fontSize: "23px",
      color: "#FFF",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#6ECCE0",
    },

    "& .content": {
      padding: "5px 15px",
    },

    "& .title": {
      color: "#3E5055",
      fontSize: "18px",
      fontWeight: "bold",
      margin: "0px",
    },

    "& .subtitle": {
      color: "#8B979F",
      fontSize: "15px",
      fontWeight: 400,
      marginBottom: "0px",
    },
  },
}));

const CustomInfo: React.FunctionComponent<Props> = ({
  number,
  text,
  subtext,
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.customInfoBox}>
      <div className={"step"}>{number}</div>
      <div className={"content"}>
        <p className={"title"}>{text}</p>
        <span className={"subtitle"}>{subtext}</span>
      </div>
    </div>
  );
};

export default CustomInfo;
