import { useContext } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ManagePlaceContext from "../../Context/ManagePlaceContext";
import WithRoleAuthorization from "../../../../Components/WithRoleAuthorization";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import CustomFilter from "../../../../Components/CustomFilter";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      padding: "10px 0px 10px 2.5rem",
      justifyContent: "space-between",
      backgroundColor: "white",
    },
    button: {
      textTransform: "none",
      padding: "10px 30px",
    },
    filterInput: {
      width: "35rem",
    },
  })
);

const Filters: React.FunctionComponent = () => {
  const classes = useStyles();
  const { getPlacesByFilterValue, setIsLoadingPlaces, togglePlaceDrawer } =
    useContext(ManagePlaceContext);

  const { setExpandSubHeader } = useContext(GlobalContex);

  const clickExpand = () => {
    setExpandSubHeader(false);
  };

  return (
    <div className={classes.container}>
      <CustomFilter
        placeHolder={"Procurar por locais"}
        id={"search-for-places"}
        isFilterInFront={false}
        className={classes.filterInput}
        setStateLoading={setIsLoadingPlaces}
        setLoadingFunction={getPlacesByFilterValue}
      />
      <div>
        <WithRoleAuthorization permitedRoles={["Owner", "Administrador"]}>
          <Button
            id={"btn-new-place"}
            onClick={() => {
              togglePlaceDrawer(true);
            }}
            className={classes.button}
            startIcon={<AddIcon />}
            variant="contained"
            color="secondary"
          >
            Novo local
          </Button>
        </WithRoleAuthorization>
        <IconButton onClick={clickExpand}>
          <img src="assets/icons/close-header-icon.svg" />
        </IconButton>
      </div>
    </div>
  );
};

export default Filters;
