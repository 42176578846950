import { makeStyles, createStyles, Grid, IconButton } from "@material-ui/core";
import ConfigurationOptionsMenu from "../../../../Components/ConfigurationOptionsMenu";
import history from "../../../../Services/history";
import EnterprisesConfigList from "../EnterprisesConfigList";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      backgroundColor: "#F8F8F8",
      height: "100%",
    },
    containerTitle: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      padding: "15px 40px",
    },
    header: {
      fontWeight: 700,
      color: "#3E5055",
      fontSize: "21px",
      lineHeight: "29px",
    },
    grid: {
      height: "calc(100% - 70px)",
      width: "100%",
    },
    gridItem: {
      height: "100%",
    },
  })
);

const ContainerEnterprisesConfig: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.containerTitle}>
        <IconButton
          onClick={() => {
            history.push("/select-home");
          }}
        >
          <img src="/assets/icons/icon_return.svg" />
        </IconButton>
        <div className={classes.header}>Meus Empreendimentos</div>
      </div>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        className={classes.grid}
      >
        <Grid
          item
          xs={4}
          style={{
            padding: "0px",
          }}
          className={classes.gridItem}
        >
          <ConfigurationOptionsMenu activeOptionId={2} />
        </Grid>
        <Grid item xs={8} className={classes.gridItem}>
          <EnterprisesConfigList />
        </Grid>
      </Grid>
    </div>
  );
};

export default ContainerEnterprisesConfig;
