import { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AutomationPageContext from "../../Context/AutomationPageContext";
import { getCommandLabel } from "../../../../Utils/DeviceConfiguration";
import { Action, Automations } from "../../../../Models/Automation";
import WithRoleAuthorization from "../../../../Components/WithRoleAuthorization";
import { ptBR, colorV2Value } from "../../../../Utils/Functions";
import { Divider } from "@material-ui/core";
import CustomBlockResource from "../../../../Components/CustomBlockResource";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: "10px",
  },
  clickToExecute: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    cursor: "pointer",
    fontSize: "12px",
    alignItems: "center",
    backgroundColor: "whitesmoke",
    borderRadius: "30px",
    minWidth: "185px",
  },
  taskRow: {
    minWidth: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "15px 0px",
  },
  summaryContent: {
    height: "70px",
    "& .MuiAccordionSummary-content": {
      maxWidth: "90%",
    },
  },
  contentTitle: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titles: {
    minWidth: "50px",
  },
  titleLabel: {
    fontSize: "15px",
    fontWeight: 700,
    lineHeight: "20px",
    color: "#3E5055",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  subTitleLabel: {
    fontSize: "13px",
    fontWeight: 600,
    lineHeight: "18px",
    color: "#8B979F",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  clickToExecuteLabel: {
    margin: "auto",
    marginRight: "10px",
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#8B979F",
  },
  taskLabel: {
    fontSize: "13px",
    fontWeight: 700,
    lineHeight: "18px",
    color: "#3E5055",
  },
  clickToExecuteContainer: {
    display: "flex",
    alignItems: "center",
  },
  clickToExecuteLabel2: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "19px",
    color: "#8B979F",
    margin: "initial",
    cursor: "pointer",
  },
  taskRowLabel: {
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#8B979F",
    alignSelf: "center",
  },
  taskRowLabel2: {
    fontSize: "15px",
    fontWeight: 600,
    lineHeight: "20px",
    color: "#8B979F",
  },
  automationsIcons: {
    maxHeight: "35px",
    maxWidth: "35px",
    marginRight: "15px",
  },
}));

interface PropsScene {
  scene: Automations;
}

const CustomContainerScene: React.FunctionComponent<PropsScene> = (
  props: PropsScene
) => {
  const classes = useStyles();
  const {
    activeScene,
    setIsScene,
    setIsAutomation,
    setTextFieldAutomacao,
    setActions,
    setAutomationsToEdit,
    setConditions,
    setUpdateDashboardAutomations,
    updateDashboardAutomations,
    setIsToggleOpen,
    setIsToEdit,
    setActionsTemp,
    setIsBlockedAutomation,
  } = useContext(AutomationPageContext);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const expandAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  const needUpdateDashboard = () => {
    let bool = false;
    props.scene.actions.map((action: Action) => {
      if (
        action.action_executor === "ruleEnable" ||
        action.action_executor === "ruleDisable"
      ) {
        bool = true;
      }
    });
    return bool;
  };

  const openDrawerToEdit = () => {
    setIsToggleOpen(true);
    setIsToEdit(true);
    setConditions([]);
    setIsScene(true);
    setIsAutomation(false);
    setAutomationsToEdit(props?.scene);
    setTextFieldAutomacao(props?.scene?.name);
    setActions(props?.scene?.actions);
    setActionsTemp(props?.scene?.actions);
    setIsBlockedAutomation(props?.scene?.block);
  };

  return (
    <div className={classes.root}>
      <div
        style={{
          position: "relative",
        }}
      >
        {props.scene.block && (
          <CustomBlockResource
            icon={"icon_padlock"}
            title={"Automação bloqueada"}
            direction={"column"}
            border={"10px"}
            onClick={(e: any) => {
              openDrawerToEdit();
              e.stopPropagation();
            }}
          />
        )}

        <Accordion
          expanded={isExpanded}
          onChange={expandAccordion}
          style={{
            margin: "0px",
            borderRadius: isExpanded ? "10px" : "10px 10px 0px 0px",
            boxShadow: isExpanded
              ? "0px 10px 20px 0px #00A6561A"
              : "0px 5px 10px rgba(62, 80, 85, 0.1)",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.summaryContent}
          >
            <div className={classes.contentTitle}>
              <div className={classes.titles}>
                <WithRoleAuthorization
                  permitedRoles={["Owner", "Administrador"]}
                  alternative={
                    <Typography className={classes.titleLabel}>
                      {props.scene.name}
                    </Typography>
                  }
                >
                  <Typography
                    className={classes.titleLabel}
                    onClick={(e: any) => {
                      openDrawerToEdit();
                      e.stopPropagation();
                    }}
                  >
                    {props.scene.name}
                  </Typography>
                </WithRoleAuthorization>
                <Typography className={classes.subTitleLabel}>
                  {props.scene.actions.length > 1
                    ? props.scene.actions.length + " Tarefas"
                    : "1 Tarefa"}
                </Typography>
              </div>
              <WithRoleAuthorization permitedRoles={["Owner", "Administrador"]}>
                <div
                  className={classes.clickToExecute}
                  onClick={(e) => {
                    activeScene(props.scene, function () {
                      if (needUpdateDashboard()) {
                        setUpdateDashboardAutomations(
                          !updateDashboardAutomations
                        );
                      }
                    });
                    e.stopPropagation();
                  }}
                >
                  <IconButton color="primary">
                    <img src="assets/icons/tap-2.svg" />
                  </IconButton>{" "}
                  <p className={classes.clickToExecuteLabel}>
                    Clique para executar
                  </p>
                </div>
              </WithRoleAuthorization>
            </div>
          </AccordionSummary>
          <Divider />
          <AccordionDetails style={{ maxHeight: "250px", overflowY: "auto" }}>
            <div style={{ width: "100%" }}>
              <Typography className={classes.taskLabel}>Condição</Typography>

              <div className={classes.clickToExecuteContainer}>
                <IconButton
                  onClick={() => {
                    activeScene(props.scene, function () {
                      if (needUpdateDashboard()) {
                        setUpdateDashboardAutomations(
                          !updateDashboardAutomations
                        );
                      }
                    });
                  }}
                >
                  <img src="assets/icons/tap-2.svg" />
                </IconButton>
                <p
                  className={classes.clickToExecuteLabel2}
                  onClick={() => {
                    activeScene(props.scene, function () {
                      if (needUpdateDashboard()) {
                        setUpdateDashboardAutomations(
                          !updateDashboardAutomations
                        );
                      }
                    });
                  }}
                >
                  Clique para executar
                </p>
              </div>

              <Typography className={classes.taskLabel}>Tarefa</Typography>

              <div style={{ marginTop: "15px" }}>
                {props?.scene?.actions?.map((action: any, index: number) => {
                  const isActionDevice =
                    action.action_executor === "dpIssue" ? true : false;
                  const isActionScene =
                    action.action_executor === "ruleTrigger" ? true : false;
                  const isActionAutomation =
                    action.action_executor === "ruleEnable" ||
                    action.action_executor === "ruleDisable"
                      ? true
                      : false;
                  const key = Object.keys(action.executor_property);

                  if (isActionAutomation) {
                    return (
                      <div
                        className={classes.taskRow}
                        key={"automation-action-item-" + index}
                      >
                        <div style={{ display: "flex" }}>
                          <img
                            src="assets/icons/automation-temp-icon.svg"
                            className={classes.automationsIcons}
                          />
                          <Typography className={classes.taskRowLabel}>
                            {action.actionName}
                          </Typography>
                        </div>

                        <Typography
                          className={classes.taskRowLabel2}
                          style={{ alignSelf: "center" }}
                        >
                          {action.action_executor === "ruleEnable"
                            ? "Habilitar"
                            : "Desabilitar"}
                        </Typography>
                      </div>
                    );
                  } else if (isActionScene) {
                    return (
                      <div
                        className={classes.taskRow}
                        key={"automation-action-item-" + index}
                      >
                        <div style={{ display: "flex" }}>
                          <img
                            src="assets/icons/tap-2.svg"
                            className={classes.automationsIcons}
                            style={{ height: "30px", width: "30px" }}
                          />
                          <Typography className={classes.taskRowLabel}>
                            {action.actionName}
                          </Typography>
                        </div>

                        <Typography className={classes.taskRowLabel2}>
                          Executar
                        </Typography>
                      </div>
                    );
                  } else if (isActionDevice) {
                    return (
                      <div
                        className={classes.taskRow}
                        key={"automation-action-item-" + index}
                      >
                        <div style={{ display: "flex" }}>
                          <img
                            src={action.device.icon}
                            className={classes.automationsIcons}
                          />
                          <Typography className={classes.taskRowLabel}>
                            {action.actionName}
                          </Typography>
                        </div>

                        <Typography
                          component="div"
                          className={classes.taskRowLabel}
                          style={{
                            display: key[0] === "colour_data_v2" ? "flex" : "",
                          }}
                        >
                          {getCommandLabel(action.device.productName, key[0])}:
                          <span className={classes.taskRowLabel2}>
                            {action.executor_property[key[0]] === true ? (
                              " ON"
                            ) : action.executor_property[key[0]] === false ? (
                              " OFF"
                            ) : key[0] === "colour_data_v2" ? (
                              <div
                                style={{
                                  border: "1px solid grey",
                                  marginLeft: "10px",
                                  backgroundColor: colorV2Value(
                                    action.executor_property[key[0]]
                                  ),
                                  height: "20px",
                                  width: "20px",
                                  borderRadius: "50%",
                                }}
                              ></div>
                            ) : (
                              " " +
                              ptBR(action.executor_property[key[0]].toString())
                            )}
                          </span>
                        </Typography>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className={classes.taskRow}
                        key={"automation-action-item-" + index}
                      >
                        <Typography className={classes.taskRowLabel}>
                          Sem tarefas cadastradas
                        </Typography>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <div
          style={{
            height: isExpanded ? "0px" : "100px",
            backgroundColor: "#FFF",
            borderRadius: "0px 0px 10px 10px",
            padding: isExpanded ? "0px" : "15px",
            display: "flex",
            transition: "height .3s",
            overflow: "hidden",
          }}
        >
          <div style={{ maxWidth: "100px" }}>
            <Typography
              className={classes.taskLabel}
              style={{ textAlign: "center" }}
            >
              Condição
            </Typography>
            <div className={classes.clickToExecuteContainer}>
              <IconButton
                onClick={() => {
                  activeScene(props.scene, function () {
                    if (needUpdateDashboard()) {
                      setUpdateDashboardAutomations(
                        !updateDashboardAutomations
                      );
                    }
                  });
                }}
              >
                <img src="assets/icons/tap-2.svg" />
              </IconButton>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "50px",
            }}
          >
            <img src="assets/icons/arrow-automations.svg" />
          </div>
          <div style={{ width: "calc(100% - 120px)" }}>
            <Typography className={classes.taskLabel}>Tarefas</Typography>
            <div
              style={{ display: "flex", marginTop: "10px", overflowY: "auto" }}
            >
              {props?.scene?.actions.map((action: any, index: number) => {
                const isActionDevice = action.action_executor === "dpIssue";
                const isActionScene = action.action_executor === "ruleTrigger";
                const isActionAutomation =
                  action.action_executor === "ruleEnable" ||
                  action.action_executor === "ruleDisable";

                if (isActionAutomation) {
                  return (
                    <div key={"automation-action-item-card" + index}>
                      <div style={{ display: "flex" }}>
                        <img
                          src="assets/icons/automation-temp-icon.svg"
                          className={classes.automationsIcons}
                        />
                      </div>
                    </div>
                  );
                } else if (isActionScene) {
                  return (
                    <div key={"automation-action-item-card" + index}>
                      <img
                        src="assets/icons/tap-2.svg"
                        className={classes.automationsIcons}
                        style={{ height: "30px", width: "30px" }}
                      />
                    </div>
                  );
                } else if (isActionDevice) {
                  return (
                    <div key={"automation-action-item-card" + index}>
                      <div style={{ display: "flex" }}>
                        <img
                          src={action.device.icon}
                          className={classes.automationsIcons}
                        />
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div key={"automation-action-item-card" + index}>
                      <Typography className={classes.taskRowLabel}>
                        Sem tarefas cadastradas
                      </Typography>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomContainerScene;
