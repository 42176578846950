import * as S from "../../styled";
import React, { useEffect } from "react";

import TabPanel, { TabPanelProps } from "../TabPanel";

const EmptyFamilyAccount = () => {
  return (
    <S.CenteredContentContainer>
      <p>Nenhuma conta familiar cadastrada</p>
    </S.CenteredContentContainer>
  );
};

const TabFamilyAccounts: React.FunctionComponent<
  TabPanelProps & { deviceId: string }
> = ({ index, value, deviceId }) => {
  const fetchFamilyAccounts = () => {
    console.log(deviceId);
  };

  useEffect(() => {
    fetchFamilyAccounts();
  }, []);

  return (
    <TabPanel value={value} index={index}>
      <S.PainelContent className={"empty"}>
        <EmptyFamilyAccount />
      </S.PainelContent>
    </TabPanel>
  );
};

export default TabFamilyAccounts;
