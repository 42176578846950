import {
  FormControlLabel,
  Paper,
  ListItem,
  ListItemText,
  Grid,
  Checkbox,
  Divider,
  makeStyles,
  Theme,
  ListItemIcon,
} from "@material-ui/core";
import { useContext, useEffect } from "react";
import OperatorsGroupCreatePageContext from "../../../../Context/OperatorsGroupCreatePageContext";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import EnvironmentRestrictModel from "../../../../../../Models/EnvironmentRestrictModel";
import DeviceToRestrictModel from "../../../../../../Models/DeviceToRestrict";

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    width: "100%",
    margin: "12px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  containerSelectAutomation: {
    maxHeight: "300px",
    overflowY: "scroll",
  },
  itemAutomation: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(1),
    borderRadius: "0px !important",
    border: "1px #D1DAE0 solid",
  },
}));

const ListDevicesRestrictAccess: React.FunctionComponent = () => {
  const classes = useStyles();

  const {
    setAllDevicesWithoutEnvironment,
    allDevicesWithoutEnvironment,
    setAllDevices,
    allDevices,
    isCheckAllRestrictModal,
    setIsCheckAllRestrictModal,
    selectAllDevices,
    unselectAllDevices,
    environments,
  } = useContext(OperatorsGroupCreatePageContext);

  useEffect(() => {
    handleSelectAllOnChange();
  }, [allDevices]);

  const handleSelectAll = async () => {
    if (isCheckAllRestrictModal) {
      unselectAllDevices();
    } else {
      selectAllDevices();
    }
    setIsCheckAllRestrictModal(!isCheckAllRestrictModal);
  };

  const handleSelectAllOnChange = () => {
    let isSelectAll = true;
    allDevices
      .concat(allDevicesWithoutEnvironment)
      .forEach((device: DeviceToRestrictModel) => {
        if (!device.restrict) isSelectAll = false;
      });
    setIsCheckAllRestrictModal(isSelectAll);
  };

  return (
    <>
      <div className={classes.header}>
        <FormControlLabel
          control={
            <Checkbox
              id={"ck-select-all-restrict"}
              key={"selectAll"}
              checked={isCheckAllRestrictModal}
              onChange={handleSelectAll}
              color="secondary"
            />
          }
          label="Selecionar tudo"
        />
      </div>
      <Divider />

      <div className={classes.containerSelectAutomation}>
        <Grid container spacing={0}>
          {allDevices.map((device: DeviceToRestrictModel, i: number) => {
            const isSelected = environments.find(
              (env: EnvironmentRestrictModel) =>
                env.environment_id === device.environment_id
            )?.restrict;

            return (
              <Grid item xs={6} key={"enviroment-selected-" + i}>
                <Paper
                  className={classes.itemAutomation}
                  elevation={0}
                  style={{
                    padding: "0px",
                    backgroundColor:
                      isSelected || device.restrict ? "#F2F2F2" : "",
                  }}
                >
                  <ListItem key={device.environment_id}>
                    <ListItemIcon>
                      <img src={device.icon} width={40} />
                    </ListItemIcon>
                    <ListItemText
                      primary={device.device_name}
                      secondary={device.environment_name}
                    />
                  </ListItem>
                  <Checkbox
                    id={`${device.device_id}-dev`}
                    icon={<VisibilityIcon />}
                    checkedIcon={<VisibilityOffIcon />}
                    key={`${device.environment_id}-selected`}
                    checked={device.restrict}
                    disabled={
                      environments.find(
                        (env: EnvironmentRestrictModel) =>
                          env.environment_id === device.environment_id
                      )?.restrict
                    }
                    onChange={() => {
                      const newAllDevices = [...allDevices];
                      newAllDevices[i].restrict = !newAllDevices[i].restrict;
                      handleSelectAllOnChange();
                      setAllDevices(newAllDevices);
                    }}
                    name={`${device.environment_id}-user`}
                    color="secondary"
                  />
                </Paper>
              </Grid>
            );
          })}
          {allDevicesWithoutEnvironment.map(
            (device: DeviceToRestrictModel, i: number) => {
              const isSelected = device.restrict;

              return (
                <Grid item xs={6} key={"device-selected-" + i}>
                  <Paper
                    className={classes.itemAutomation}
                    elevation={0}
                    style={{
                      padding: "0px",
                      height: "100%",
                      backgroundColor:
                        isSelected || device.restrict ? "#F2F2F2" : "",
                    }}
                  >
                    <ListItem key={device.device_id}>
                      <ListItemIcon>
                        <img src={device.icon} width={40} />
                      </ListItemIcon>
                      <ListItemText
                        primary={device.device_name}
                        secondary={"Sem Ambiente"}
                      />
                    </ListItem>
                    <Checkbox
                      id={`${device.device_id}-dev`}
                      icon={<VisibilityIcon />}
                      checkedIcon={<VisibilityOffIcon />}
                      key={`${device.device_id}-selected`}
                      checked={device.restrict}
                      onChange={() => {
                        const newAllDevicesWithoutEnvironment = [
                          ...allDevicesWithoutEnvironment,
                        ];
                        newAllDevicesWithoutEnvironment[i].restrict =
                          !newAllDevicesWithoutEnvironment[i].restrict;
                        handleSelectAllOnChange();
                        setAllDevicesWithoutEnvironment(
                          newAllDevicesWithoutEnvironment
                        );
                      }}
                      name={`${device.device_id}-user`}
                      color="secondary"
                    />
                  </Paper>
                </Grid>
              );
            }
          )}
        </Grid>
      </div>
    </>
  );
};

export default ListDevicesRestrictAccess;
