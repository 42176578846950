import { useContext } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import EnvironmentsProvider from "../../context/EnvironmentsContext";
import { FormControl, InputAdornment, OutlinedInput } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      padding: "10px 2.5rem",
      justifyContent: "space-between",
      borderBottom: "1px solid #D1DAE0",
      backgroundColor: "white",
    },
    filterInput: {
      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#6DCC9A",
      },
      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#00A335",
      },
    },
    container2: {
      display: "flex",
      padding: "0px 94px",
      height: "90vh",
    },
    button: {
      textTransform: "none",
      padding: "10px 30px",
    },
  })
);

const Filters: React.FunctionComponent = () => {
  const classes = useStyles();
  const { filterValue, setFilterValue } = useContext(EnvironmentsProvider);

  const changeValueFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
  };
  return (
    <div className={classes.container}>
      <FormControl
        variant="outlined"
        style={{
          width: "35rem",
        }}
      >
        <OutlinedInput
          className={classes.filterInput}
          margin="dense"
          placeholder="Procurar por nome de dispositivo"
          id="search-for-devices"
          value={filterValue}
          onChange={changeValueFilter}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {/* <WithRoleAuthorization permitedRoles={["Owner", "Administrador"]}>
            <Button
                className={classes.button}
                startIcon={<AddIcon />}
                variant="contained"
                color="secondary"
                onClick={() => {
                    setIsToggleOpen(true);
                    setRoomName("");
                }}
                >
                    Novo ambiente
            </Button>
        </WithRoleAuthorization> */}
    </div>
  );
};

export default Filters;
