import * as React from "react";
import { Size } from "@material-ui/core/Table";

import * as S from "./styles";

type CustomTableProps = {
  children: React.ReactNode;
  size?: Size;
  className?: string;
};

const CustomTable: React.FunctionComponent<CustomTableProps> = ({
  children,
  size = "medium",
  className = "",
}: CustomTableProps) => {
  return (
    <S.Table className={className} size={size}>
      {children}
    </S.Table>
  );
};

export default CustomTable;
