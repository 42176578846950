import { Button, Container, createStyles, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TitleSection from "./TitleSection";
import history from "../../../Services/history";
import CustomFormEnterprise from "../../../Components/CustomFormEnterprise";
import { useContext } from "react";
import EnterpriseEditPageContext from "../context/EnterpriseEditPageContext";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      paddingTop: 80,
    },
    btnContainer: {
      textAlign: "right",
    },
    btnSave: {
      minWidth: "215px",
      height: "55px",
      textTransform: "capitalize",
      fontWeight: 700,
      fontSize: "15px",
      lineHeight: "20px",
    },
  })
);

const ContainerEnterpriseEditPage: React.FunctionComponent = () => {
  const classes = useStyles();
  const {
    selectedImage,
    enterpriseName,
    radioButtonValue,
    registerEnterprise,
    registerEnterpriseInvalidValues,
    repeatEmailValue,
    handleChangeImage,
    removeImageEnterprise,
    setEnterpriseName,
    handleChange,
    handleFocus,
    updateEnterprise,
  } = useContext(EnterpriseEditPageContext);

  const handleBackButton = () => {
    history.push("/select-home");
  };

  return (
    <div className={classes.root}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TitleSection
              title={"Editar informações fiscais do empreendimento"}
              description={
                "Preencha com as informações do empreendimento e suas informações pessoais"
              }
              onClickBackButton={handleBackButton}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomFormEnterprise
              selectedImage={selectedImage}
              enterpriseName={enterpriseName}
              radioButtonValue={radioButtonValue}
              registerEnterprise={registerEnterprise}
              registerEnterpriseInvalidValues={registerEnterpriseInvalidValues}
              repeatEmailValue={repeatEmailValue}
              handleChangeImage={handleChangeImage}
              removeImageEnterprise={removeImageEnterprise}
              setEnterpriseName={setEnterpriseName}
              handleChange={handleChange}
              handleFocus={handleFocus}
            />
          </Grid>
          <Grid item xs={12} className={classes.btnContainer}>
            <Button
              className={classes.btnSave}
              color={"secondary"}
              variant={"contained"}
              onClick={updateEnterprise}
              disabled={
                (radioButtonValue === "cpf" && registerEnterprise.cpf === "") ||
                (radioButtonValue === "cnpj" &&
                  registerEnterprise.cnpj === "") ||
                registerEnterprise.responsible === "" ||
                registerEnterprise.email === "" ||
                registerEnterprise.telephone === "" ||
                registerEnterprise.postal_code === "" ||
                registerEnterprise.state === "" ||
                registerEnterprise.neighborhood === "" ||
                registerEnterprise.city === "" ||
                registerEnterprise.address === "" ||
                registerEnterprise.number === "" ||
                repeatEmailValue === "" ||
                registerEnterpriseInvalidValues.cnpj ||
                registerEnterpriseInvalidValues.cpf ||
                registerEnterpriseInvalidValues.email ||
                registerEnterpriseInvalidValues.postal_code ||
                registerEnterpriseInvalidValues.repeat_email
              }
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ContainerEnterpriseEditPage;
