import MiniDrawer from "../../Components/CustomMiniDrawer";
import ManagePlaceProvider from "./Context/ManagePlaceProvider";
import TogglePlaceListOrRegister from "./Components/ToggleGroupListOrRegister";
import { getHomeNameSelect } from "../../Services/UserService";

const ManagePlace: React.FunctionComponent = () => {
  return (
    <MiniDrawer
      breadcrumb={[{ name: getHomeNameSelect(), to: "/manage-place" }]}
    >
      <ManagePlaceProvider>
        <TogglePlaceListOrRegister />
      </ManagePlaceProvider>
    </MiniDrawer>
  );
};

export default ManagePlace;
