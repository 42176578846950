import { Warning, Text } from "./styles";

interface Props {
  title: string;
  subtitle?: string;
  icon?: string;
  direction?: string;
  border?: string;
  small?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const CustomBlockResource: React.FunctionComponent<Props> = ({
  title,
  subtitle,
  icon,
  direction,
  border,
  small = false,
  onClick,
}: Props) => {
  return (
    <Warning
      direction={direction}
      border={border}
      onClick={onClick}
      style={{ cursor: onClick ? "pointer" : "" }}
    >
      <div>
        <img
          src={`assets/icons/${icon}.svg`}
          alt={title}
          width={subtitle ? "" : small ? "15px" : "29px"}
        />
      </div>
      <Text
        fontSize={subtitle ? "37px" : small ? "12px" : "13px"}
        marginTop={subtitle ? "30px" : "10px"}
        color={subtitle ? "#677884" : ""}
      >
        {title}
      </Text>
      <Text
        fontSize="21px"
        fontWeight={400}
        lineHeight="29px"
        color={subtitle ? "#8B979F" : "#677884"}
        marginTop={subtitle ? "22px" : "10px"}
      >
        {subtitle}
      </Text>
    </Warning>
  );
};

export default CustomBlockResource;
