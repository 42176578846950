import { useContext, useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AutomationPageContext from "../../Context/AutomationPageContext";
import EmptyState from "../../../../Components/EmptyState";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import { Automations } from "../../../../Models/Automation";
import AutomationsColumns from "./AutomationsColumns";
import { CircularProgress } from "@material-ui/core";
import CustomIndicator from "../../../../Components/CustomIndicator";

const useStyles = makeStyles(() =>
  createStyles({
    paddingGrid: {
      padding: "0px 40px",
      backgroundColor: "#EBEEEE",
      overflow: "auto",
      marginTop: "0px",
      justifyContent: "space-between",
      height: "calc(100% - 60px)",
    },
    indicator: {
      padding: "20px 40px 0px 40px",
      backgroundColor: "#EBEEEE",
      overflow: "auto",
      marginTop: "0px",
      justifyContent: "space-between",
    },
  })
);

const AutomationsContainer: React.FunctionComponent = () => {
  const classes = useStyles();

  const {
    automationsToShow,
    isLoadingAutomations,
    allAutomations,
    setIsLoadingAutomations,
  } = useContext(AutomationPageContext);

  const [col1, setCol1] = useState<Automations[]>([]);
  const [col2, setCol2] = useState<Automations[]>([]);
  const [col3, setCol3] = useState<Automations[]>([]);

  const { home } = useContext(GlobalContex);

  useEffect(() => {
    const c1: Automations[] = [];
    const c2: Automations[] = [];
    const c3: Automations[] = [];
    const lenght: number = automationsToShow.length;
    for (let i = 0; i < automationsToShow.length; i += 3) {
      if (i < lenght) {
        c1.push(automationsToShow[i]);
      }
      if (i + 1 < lenght) {
        c2.push(automationsToShow[i + 1]);
      }
      if (i + 2 < lenght) {
        c3.push(automationsToShow[i + 2]);
      }
    }
    setCol1(c1);
    setCol2(c2);
    setCol3(c3);
    setIsLoadingAutomations(false);
  }, [automationsToShow]);

  return (
    <>
      <div className={classes.indicator}>
        {home && (
          <CustomIndicator
            maximumNumberOfItens={home.max_automation}
            totalItens={allAutomations.length}
            tooltip={
              "Você atingiu o limite de automações do seu plano. Exclua automações ou contrate um novo plano"
            }
            title={"Automações"}
            key={home.max_automation}
            icon={"icon_exclamation"}
          />
        )}
      </div>

      <Grid container className={classes.paddingGrid}>
        {isLoadingAutomations ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={50} color="secondary" />
          </div>
        ) : (
          <>
            {automationsToShow.length > 0 ? (
              <>
                <AutomationsColumns col={col1} />
                <AutomationsColumns col={col2} />
                <AutomationsColumns col={col3} />
              </>
            ) : (
              <EmptyState
                title="Nenhuma automação disponível"
                subtitle="Experimente criar uma nova automação clicando no botão "
                image={"assets/imgs/empty-state-imgs/empty-state-img-3.png"}
              />
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default AutomationsContainer;
