import { Button, List, Grid } from "@material-ui/core";
import { useState } from "react";
import {
  getCommandLabel,
  getCommandConfiguration,
} from "../../../Utils/DeviceConfiguration";
import CommandRow from "../../CustomDevices/Devices/CustomDefaultDevice/Device/CommandRow";
import IntegerInput from "../../../Components/Commands/IntegerInput/IntegerInput";
import EnumInput from "./EnumInput";

interface Props {
  parentProps: any;
  command: any;
  value: any;
  onSubmit: any;
}

const getComponents = (
  children: any,
  subCommands: any,
  code?: any,
  parent?: any,
  commandConfiguration?: any
) => {
  const fields = Object.keys(children);
  fields.forEach((field: any) => {
    const type = getFieldType(field, children);

    let indexedBy = undefined;

    try {
      indexedBy = commandConfiguration[parent].indexded_by;
    } catch (error) {
      console.log(error);
    }

    if (type === "JSON") {
      subCommands.push({
        values: children[field],
        type: "Title",
        code: field,
        parent_code: parent,
        indexedBy: indexedBy,
      });
      getComponents(
        children[field],
        subCommands,
        code,
        field,
        commandConfiguration
      );
    } else {
      let command: any;

      if (code !== undefined) {
        command = {
          values: children[field],
          type: type,
          code: field,
          parent_code: parent,
          indexedBy: indexedBy,
        };
      } else {
        command = {
          values: children[field],
          type: type,
          code: field,
          indexedBy: indexedBy,
        };
      }

      subCommands.push(command);
    }
  });
};

const getFieldType = (field_name: any, object: any) => {
  const obj = object;

  if (obj[field_name].min || obj[field_name].max) {
    return "Integer";
  } else if (obj[field_name].range) {
    return "Enum";
  } else {
    return "JSON";
  }
};

const JsonInput: React.FunctionComponent<Props> = ({
  command,
  value,
  parentProps,
  onSubmit,
}: Props) => {
  if (value && typeof value === "string") value = JSON.parse(value);

  const [commandJson, setCommandJson] = useState<any>(value);

  const commandConfiguration = getCommandConfiguration(
    parentProps.device,
    command.code
  );

  const subCommands: any = [];
  getComponents(
    JSON.parse(command.values),
    subCommands,
    command.code,
    null,
    commandConfiguration
  );

  const changeCommandJson = (cmd: any, value: any, onSubmit: any) => {
    let commandValue = commandJson;

    if (!commandValue) {
      commandValue = {};
    }

    if (cmd.parent_code) {
      if (cmd.indexedBy) {
        let val =
          commandValue[cmd.parent_code][commandValue[cmd.indexedBy] - 1];
        if (!val) {
          val = {};
        }
        val[cmd.code] = value;
        commandValue[cmd.parent_code][commandValue[cmd.indexedBy] - 1] = val;
      } else {
        commandValue[cmd.parent_code][command.code] = value;
      }
    } else {
      commandValue[cmd.code] = value;
    }

    const c = { ...commandJson, ...commandValue };
    setCommandJson(c);

    if (onSubmit) {
      onSubmit(c);
    }
  };

  const getValue = (c: any, s: any) => {
    if (c.parent_code) {
      if (c.indexedBy) {
        let val;

        try {
          val = s[c.parent_code][s[c.indexedBy] - 1];
        } catch (err) {
          console.log(err);
        }

        if (!val) {
          val = {};
        }

        return val[c.code];
      } else if (s[c.parent_code]) {
        return s[c.parent_code][c.code];
      }
    } else {
      return s[c.code];
    }
  };

  if (value && typeof value == "string") value = JSON.parse(value);

  let s: any = {};

  try {
    s = value;
  } catch (error) {
    console.log(error);
  }

  s = { ...s, ...commandJson };
  return (
    <List style={{ width: "100%" }}>
      {subCommands.map((c: any, index: number) => {
        const val = getValue(c, s);

        if (c.type === "Title") {
          return (
            // <Typography key={index} variant="caption" style={{marginLeft: '20px'}}>
            //   {/* {index} */}
            //   {getCommandLabel(parentProps.device, c.code, c.parent_code)}
            // </Typography>
            <></>
          );
        } else if (c.type === "Integer") {
          return (
            <CommandRow
              key={index}
              subCommand={true}
              label={getCommandLabel(parentProps.device, c.code, c.parent_code)}
              component={
                <IntegerInput
                  command={c}
                  value={val}
                  onChange={(value: any) => {
                    changeCommandJson(
                      c,
                      value,
                      parentProps?.isSelectable ? onSubmit : null
                    );
                  }}
                />
              }
            />
          );
        } else if (c.type === "Enum") {
          return (
            <CommandRow
              key={index}
              subCommand={true}
              label={getCommandLabel(parentProps.device, c.code, c.parent_code)}
              component={
                <EnumInput
                  command={c}
                  value={val}
                  device={parentProps.device}
                  onChange={(value: any) => {
                    changeCommandJson(
                      c,
                      value,
                      parentProps?.isSelectable ? onSubmit : null
                    );
                  }}
                />
              }
            />
          );
        } else {
          return <></>;
        }
      })}
      {!parentProps?.isSelectable && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              onSubmit(commandJson);
            }}
          >
            Enviar {getCommandLabel(parentProps.device, command.code)}
          </Button>
        </Grid>
      )}
    </List>
  );
};

export default JsonInput;
