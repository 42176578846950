import { ReactNode, useEffect, useState } from "react";
import { PlansCancelOptions } from "../../../Models/PlaceModel";
import { getCancelOptions } from "../../../Services/PlansService";
import CancelSignatureContext from "./CancelSignatureContext";
import { useLocation } from "react-router-dom";

interface Props {
  children: ReactNode;
}

const CancelSignatureProvider: React.FunctionComponent<Props> = ({
  children,
}: Props) => {
  const [idSignature, setidSignature] = useState<string>("second");
  const [categories, setCategories] = useState<PlansCancelOptions[]>([]);

  const dataRedirect = useLocation<{ enterprise_id: string }>();
  const { enterprise_id } = dataRedirect.state;

  const loadOptions = async () => {
    const response = await getCancelOptions();
    setCategories(response.data);
  };

  useEffect(() => {
    loadOptions();

    return () => {
      setCategories([]);
    };
  }, []);

  return (
    <CancelSignatureContext.Provider
      value={{
        enterpriseId: enterprise_id,
        idSignature,
        setidSignature,
        categories,
      }}
    >
      {children}
    </CancelSignatureContext.Provider>
  );
};

export default CancelSignatureProvider;
