import React, { useContext } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { FormGroup, Select, Chip, Tooltip } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import OperatorsGroupCreatePageContext from "../../Context/OperatorsGroupCreatePageContext";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CustomInfo from "../CustomInfo";
import RestrictAccess from "./RestrictAccess";
import ButtonAdd from "./SaveOrCancelPermissions/ButtonAdd";
import EnvironmentRestrictModel from "../../../../Models/EnvironmentRestrictModel";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 460,
      backgroundColor: theme.palette.background.paper,
    },
    paddingPapper: {
      minHeight: "70vh",
      padding: "30px 90px 10px",
      marginTop: "30px",
    },
    containerInput: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginBottom: "50px",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    select: {
      width: "409px",
    },
  })
);

const EnvironmentsAccessControl: React.FunctionComponent = () => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClick = () => {
    setOpen(!open);
  };

  const {
    setEnvironments,
    restrictDevicesFromEnvironment,
    selectRestrictEnvironment,
    handleChangeSelectEnvironment,
    environments,
  } = useContext(OperatorsGroupCreatePageContext);

  const classes = useStyles();

  const selectEnvironment = () => {
    return (
      <Select
        id={"select-enviroment"}
        classes={{ root: classes.select }}
        value={selectRestrictEnvironment}
        onChange={(event) => {
          handleChangeSelectEnvironment(event);
        }}
      >
        {environments
          .filter((se: EnvironmentRestrictModel) => se.restrict === false)
          .map((environment: any) => (
            <MenuItem
              key={environment.environment_id}
              value={environment}
              id={`${environment.environment_id}-env`}
            >
              {environment.name}
            </MenuItem>
          ))}
      </Select>
    );
  };

  return (
    <div>
      <CustomInfo
        number={2.1}
        text={
          "Desabilite os dispositivos que você deseja que os usuários não tenham controle"
        }
      />
      <FormGroup>
        <label htmlFor="operators-name">
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            open={open}
            placement="top"
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Você pode habilitar ou desabilitar a visualização dos usuários a um ambiente e seus dispositivos associados"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "35px 0px",
              }}
            >
              <strong
                style={{
                  marginRight: "10px",
                  fontSize: "18px",
                  color: "#3E5055",
                }}
              >
                Restringir acesso a Ambientes
              </strong>
              <HelpOutlineIcon
                fontSize={"small"}
                onClick={handleTooltipClick}
              ></HelpOutlineIcon>
            </div>
          </Tooltip>
        </label>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "0px 0px 25px 0px",
          }}
        >
          {selectEnvironment()}
          <ButtonAdd />
        </div>
      </FormGroup>
      {environments
        .filter((se: EnvironmentRestrictModel) => se.restrict)
        .map((env: EnvironmentRestrictModel) => (
          <Chip
            key={env.environment_id}
            label={env.name}
            className={classes.chip}
            deleteIcon={<DeleteIcon />}
            onDelete={() => {
              const newEnvironments = [...environments];
              const i = newEnvironments.findIndex(
                (el: EnvironmentRestrictModel) =>
                  el.environment_id === env.environment_id
              );
              newEnvironments[i].restrict = false;
              setEnvironments(newEnvironments);
              restrictDevicesFromEnvironment(
                environments[i].environment_id,
                false
              );
            }}
          />
        ))}
      <RestrictAccess />
    </div>
  );
};

export default EnvironmentsAccessControl;
