import {
  KeyboardEvent,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import GlobalContex from "../../../GlobalContext/GlobalContext";
import { UserAccountModel } from "../../../Models/UserAccountModel";
import history from "../../../Services/history";
import {
  createUser,
  createUserAccount,
  verifyEmail,
} from "../../../Services/UserService";
import { ErrorMessage } from "../../../Utils/ErrorMessage";
import ConfirmCodeContext from "./ConfirmCodeContext";

interface Props {
  children: ReactNode;
}

const ConfirmCodeContextProvider: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { children } = props;
  const { showAlert } = useContext(GlobalContex);
  const userAccount = useLocation<{
    userAccount: UserAccountModel;
    redirectTo: string;
  }>();
  const initalCountdown = 60;
  const inputRefs = [
    { input: useRef<HTMLInputElement | null>(null) },
    { input: useRef<HTMLInputElement | null>(null) },
    { input: useRef<HTMLInputElement | null>(null) },
    { input: useRef<HTMLInputElement | null>(null) },
    { input: useRef<HTMLInputElement | null>(null) },
    { input: useRef<HTMLInputElement | null>(null) },
  ];

  const [redirectTo, setRedirectTo] = useState<string>("");
  const [notReceived, setNotReceived] = useState<boolean>(false);
  const [isConfirmingCode, setIsConfirmingCode] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(initalCountdown);
  const [countdownTimer, setCountdownTimer] = useState<
    NodeJS.Timeout | undefined
  >();

  useEffect(() => {
    if (
      !userAccount.state ||
      !userAccount.state.userAccount ||
      !userAccount.state.redirectTo
    ) {
      history.push("/login");
    }

    setRedirectTo(userAccount.state.redirectTo);
    startInterval(initalCountdown);

    return () => {
      history.replace({ ...history.location, state: undefined });

      setIsConfirmingCode(false);
    };
  }, []);

  useEffect(() => {
    if (countdown <= 0 && countdownTimer) {
      clearInterval(countdownTimer);
    }
  }, [countdown]);

  const startInterval = (interval: number) => {
    setCountdownTimer(
      setInterval(() => {
        interval = interval - 1;
        setCountdown(interval);
      }, 1000)
    );
  };

  const clickReSend = async () => {
    setCountdown(initalCountdown);
    startInterval(initalCountdown);
    await verifyEmail(userAccount.state.userAccount.username)
      .then((response) => {
        if (response.data) {
          showAlert("Código reenviado com sucesso!", "success");
        }
      })
      .catch((error) => {
        const message = ErrorMessage(
          error,
          "Houve um problema ao reenviar o código!"
        );
        showAlert(message, "error");
      });
  };

  const createNewUserAccountInHome = async (code: string) => {
    setIsConfirmingCode(true);
    await createUser(userAccount.state.userAccount, code)
      .then((response) => {
        if (response.data) {
          showAlert("Novo usuário criado com sucesso!", "success");
          history.push(userAccount.state.redirectTo);
        }
      })
      .catch((error) => {
        const message = ErrorMessage(
          error,
          "Houve um problema na validação do código!"
        );
        showAlert(message, "error");
      })
      .finally(() => {
        setIsConfirmingCode(false);
      });
  };

  const createNewUserAccount = async (code: string) => {
    setIsConfirmingCode(true);
    await createUserAccount(userAccount.state.userAccount, code)
      .then((response) => {
        if (response.data) {
          showAlert("Novo usuário criado com sucesso!", "success");
          history.push(userAccount.state.redirectTo);
        }
      })
      .catch((error) => {
        const message = ErrorMessage(
          error,
          "Houve um problema na validação do código!"
        );
        showAlert(message, "error");
      })
      .finally(() => {
        setIsConfirmingCode(false);
      });
  };

  const handleChangeCodeValue = (index: number) => {
    if (
      index + 1 < inputRefs.length &&
      inputRefs[index].input.current?.value !== ""
    ) {
      inputRefs[index + 1].input.current?.focus();
      if (inputRefs[index + 1].input.current?.value !== "") {
        inputRefs[index + 1].input.current?.select();
      }
    }

    let code = "";
    inputRefs.map((input) => (code = code + input.input.current?.value));

    if (code.length === inputRefs.length) {
      if (userAccount.state.redirectTo === "/login") {
        createNewUserAccount(code);
      }

      if (userAccount.state.redirectTo === "/usuarios") {
        createNewUserAccountInHome(code);
      }
    }
  };

  const onKeyPressInputValue = (
    event: KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = (event.target as HTMLInputElement).value;
    if (event.key === "Backspace" && value === "") {
      if (index > 0) {
        inputRefs[index - 1].input.current?.focus();
      }
    }
  };

  return (
    <ConfirmCodeContext.Provider
      value={{
        inputRefs,
        redirectTo,
        notReceived,
        isConfirmingCode,
        countdown,
        setNotReceived,
        clickReSend,
        handleChangeCodeValue,
        onKeyPressInputValue,
      }}
    >
      <div
        style={{
          backgroundColor: "#E5E5E5",
          width: "100%",
          height: "100vh",
          padding: "40px 200px",
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          overflow: "auto",
        }}
      >
        {children}
      </div>
    </ConfirmCodeContext.Provider>
  );
};

export default ConfirmCodeContextProvider;
