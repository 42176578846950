import { ReactNode, useContext, useEffect, useState } from "react";
import GlobalContex from "../../../GlobalContext/GlobalContext";
import { HomeUserModel } from "../../../Models/HomeUserModel";
import TokenService from "../../../Services/TokenService";
import { getHomesById } from "../../../Services/UserService";
import EnterprisesConfigContext from "./EnterprisesConfigContext";
import { getAllPans } from "../../../Services/PlansService";
import { Plans } from "../../../Models/Plans";

interface Props {
  children: ReactNode;
}

const EnterprisesConfigProvider: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { children } = props;
  const { showAlert } = useContext(GlobalContex);

  const [isLoadingEnterpriseData, setIsLoadingEnterpriseData] =
    useState<boolean>(false);
  const [enterprises, setEnterprises] = useState<HomeUserModel[]>([]);
  const [plans, setPlans] = useState<Plans[]>([]);

  const loadEnterprisesData = async () => {
    try {
      setIsLoadingEnterpriseData(true);
      const response = await getHomesById(TokenService.getUser().userInfo.id);
      setEnterprises(response.data);
    } catch (error) {
      showAlert("Houve um problema ao carregar os empreendimentos!", "error");
    } finally {
      setIsLoadingEnterpriseData(false);
    }
  };

  useEffect(() => {
    loadEnterprisesData();
    getAllPans().then((res) => {
      setPlans(res.data);
    });

    return () => {
      setIsLoadingEnterpriseData(false);
      setEnterprises([]);
      setPlans([]);
    };
  }, []);

  return (
    <EnterprisesConfigContext.Provider
      value={{
        isLoadingEnterpriseData,
        enterprises,
        plans,
      }}
    >
      <div
        id="my-enterprises-container"
        style={{
          backgroundColor: "#FFF",
          width: "100%",
          overflow: "auto",
        }}
      >
        {children}
      </div>
    </EnterprisesConfigContext.Provider>
  );
};

export default EnterprisesConfigProvider;
