import Skeleton from "@material-ui/lab/Skeleton";
import DeviceModel from "../../../../../../Models/DeviceModel";

import * as S from "./styled";
import { MouseEventHandler, useEffect, useState } from "react";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import { IconButton } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import { getLogDevices } from "../../../../../../Services/DevicesService";
import { getCommandLabel } from "../../../../../../Utils/DeviceConfiguration";

type DeviceLastAlarmDisplayProps = {
  device?: DeviceModel;
  onButtonClick?: MouseEventHandler<any> | undefined;
  [key: string]: any;
};

const DeviceLastAlarmDisplay: React.FunctionComponent<
  DeviceLastAlarmDisplayProps
> = ({ device, onButtonClick, ...props }: DeviceLastAlarmDisplayProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [lastAlarmText, setlastAlarmText] = useState<string>("");

  useEffect(() => {
    return () => {
      setlastAlarmText("");
      setLoading(false);
    };
  }, []);

  const fetchLastAlarm = async () => {
    setLoading(true);
    await getLogDevices(device!.id, { codes: "hijack,alarm_lock", size: 1 })
      .then((response) => {
        const { logs } = response.data;
        if (logs.length === 0) {
          setlastAlarmText("Sem alarme");
        } else {
          const { code, value } = logs[0];
          setlastAlarmText(getCommandLabel(device!, value, code));
        }
      })
      .catch(() => {
        setlastAlarmText("Não foi possível carregar último alarme");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (device) {
      fetchLastAlarm();
    }
  }, [device]);

  return (
    <>
      <S.DisplayContainer {...props}>
        <S.Icon>
          <S.Circle>
            <NotificationsNoneIcon />
          </S.Circle>
        </S.Icon>
        <S.Content>
          <div className={"label"}>Último alarme:</div>
          <div className={"alarm"}>
            {loading && <Skeleton variant="text" width={120} height={36} />}
            {!loading && <span title={lastAlarmText}>{lastAlarmText}</span>}
          </div>
        </S.Content>
        <S.Action>
          <IconButton onClick={onButtonClick}>
            <ChevronRight />
          </IconButton>
        </S.Action>
      </S.DisplayContainer>
    </>
  );
};

export default DeviceLastAlarmDisplay;
