import authHeader, { getHomeIDSelect } from "./UserService";
import api from "./Api";
import PlaceModel, {
  NewPlaceCreate,
  PlaceOptionsModel,
  PlacePaginatedModel,
} from "../Models/PlaceModel";
import { AxiosResponse } from "axios";

export const getAllPlace = (
  pageNumber: number,
  pageSize: number,
  options?: PlaceOptionsModel
): Promise<AxiosResponse<PlacePaginatedModel>> => {
  return api.get(
    `/homes/${getHomeIDSelect()}/places?pageNumber=${pageNumber}&pageSize=${pageSize}${
      options?.filter ? '&filter=name=="*' + options.filter + '*"' : ""
    }${options?.sortBy ? "&sortBy=" + options.sortBy : ""}${
      options?.sortDir ? "&sortDir=" + options.sortDir : ""
    }`,
    { headers: authHeader() }
  );
};

export const getPlaceById = (
  place_id: string
): Promise<AxiosResponse<PlaceModel>> => {
  return api.get(`/homes/${getHomeIDSelect()}/places/${place_id}`, {
    headers: authHeader(),
  });
};

export const createPlace = (
  place: NewPlaceCreate
): Promise<AxiosResponse<number>> => {
  return api.post(`/homes/${getHomeIDSelect()}/places`, place, {
    headers: authHeader(),
  });
};

export const updatePlace = (
  place: PlaceModel,
  place_id: string
): Promise<AxiosResponse<boolean>> => {
  return api.put(`/homes/${getHomeIDSelect()}/places/${place_id}`, place, {
    headers: authHeader(),
  });
};

export const deletePlace = (
  place_id: string
): Promise<AxiosResponse<boolean>> => {
  return api.delete(`/homes/${getHomeIDSelect()}/places/${place_id}`, {
    headers: authHeader(),
  });
};
