import {
  makeStyles,
  createStyles,
  IconButton,
  Button,
} from "@material-ui/core";
import { useContext, useState } from "react";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import { HomeUserModel } from "../../../../Models/HomeUserModel";
import history from "../../../../Services/history";
import ItemIcon from "../ItemIcon";
import DialogMyPlan from "../DialogMyPlan";
import EnterpriseStatus from "../EnterpriseStatus";
import DialogEnterpriseInvoices from "../DialogEnterpriseInvoices";
import CannotChangePlan from "../CannotChangePlan";
import ChooseTypePaymentDialog from "../../../EnterpriseCreatePage/components/Step2/components/ChooseTypePaymentDialog";
import { Plans } from "../../../../Models/Plans";
import { EnterpriseModel } from "../../../../Models/EnterpriseModel";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: "100%",
      display: "flex",
      cursor: "pointer",
      border: "1px solid #D1DAE0",
      borderRadius: "0px 3px 0px 0px",
    },
    img: {
      minWidth: "100px",
      minHeight: "100px",
      maxWidth: "100px",
      maxHeight: "100px",
    },
    enterpriseInfo: {
      padding: "0px 20px",
      width: "100%",
    },
    name: {
      fontWeight: 400,
      fontSize: "21px",
      lineHeight: "29px",
      color: " #8B979F",
      marginBottom: "10px",
      display: "flex",
    },
    containerPlanInfo: {
      display: "flex",
      alignItems: "center",
    },
    imgPlan: {
      width: "25px",
      height: "25px",
    },
    planName: {
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "25px",
      color: "#677884",
    },
    createdAt: {
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "20px",
      color: "#8B979F",
    },
    noPlanLabel: {
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "20px",
      color: "#8B979F",
    },
    containerIcon: {
      minWidth: "50px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    containerCard: {
      background: "#EBEEEE",
      border: "1px solid #D1DAE0",
      padding: "20px",
    },
    labelCard: {
      marginBottom: "15px",
    },
    containerButtons: {},
    buttonEdit: {
      textTransform: "capitalize",
      marginRight: "5px",
      height: "50px",
      width: "200px",
      borderRadius: "3px",
    },
    buttonLook: {
      textTransform: "capitalize",
      height: "50px",
      width: "150px",
      borderRadius: "3px",
      border: "1px solid #D1DAE0",
      color: "#8B979F",
      backgroundColor: "#FFF",
      "&:hover": {
        backgroundColor: "#FFF",
      },
    },
  })
);

interface Props {
  enterprise: HomeUserModel;
}

const EnterpriseListItem: React.FunctionComponent<Props> = ({
  enterprise,
}: Props) => {
  const {
    selectHomeTrigger,
    setSelectHomeTrigger,
    setNameEnterpriseSelected,
    setIsHomeSelected,
  } = useContext(GlobalContex);

  const classes = useStyles();
  const noImage = "assets/imgs/enterpriseDefaultImage.png";

  const [open, setOpen] = useState<boolean>(false);
  const [openMyPlan, setOpenMyPlan] = useState<boolean>(false);
  const [openChangePlan, setOpenChangePlan] = useState<boolean>(false);
  const [openChangePlanSuccess, setOpenChangePlanSuccess] =
    useState<boolean>(false);
  const [openInvoices, setOpenInvoices] = useState<boolean>(false);
  const [plan, setPlan] = useState<Plans>({} as Plans);

  const [enterpriseInfo, setEnterpriseInfo] = useState<EnterpriseModel>(
    {} as EnterpriseModel
  );

  const changeOpenState = () => {
    setOpen(!open);
  };

  const goToEditEnterprise = () => {
    localStorage.setItem("redirectStep", "2");
    localStorage.setItem("redirectEnterprise", enterprise.home_id);
    history.push("/enterprise/edit/" + enterprise.home_id);
  };

  const changeOpenMyPlanState = (value: boolean) => {
    setOpenMyPlan(value);
  };

  const changeCannotChanePlan = (value: boolean) => {
    setOpenChangePlan(value);
  };

  const changeChanePlan = (
    value: boolean,
    plan: Plans,
    enterpriseToChangePlan: EnterpriseModel
  ) => {
    setEnterpriseInfo(enterpriseToChangePlan);
    setOpenChangePlanSuccess(value);
    setPlan(plan);
  };

  const changeChanePlanClose = (value: boolean) => {
    setOpenChangePlanSuccess(value);
    changeOpenMyPlanState(true);
  };

  const clickOpenMyPlan = () => {
    changeOpenMyPlanState(true);
  };

  const changeOpenInvoicesState = (value: boolean) => {
    setOpenInvoices(value);
  };

  const clickOpenInvoices = () => {
    changeOpenInvoicesState(true);
  };

  const goToEnterprise = (event: any, status: string) => {
    event.stopPropagation();
    if (status === "COMPLETED" || status === "TRIAL") {
      localStorage.setItem("homeID", enterprise.home_id);
      localStorage.setItem("homeSelectName", enterprise.name);
      setSelectHomeTrigger(!selectHomeTrigger);
      setIsHomeSelected(true);
      history.push("/manage-place");
      setNameEnterpriseSelected(enterprise.name);
    }
  };

  return (
    <>
      <div className={classes.container} onClick={changeOpenState}>
        <img
          className={classes.img}
          src={enterprise.image ? enterprise.image : noImage}
          onClick={(event) => goToEnterprise(event, enterprise.status)}
        />
        <div className={classes.enterpriseInfo}>
          <div className={classes.name}>
            <div onClick={(event) => goToEnterprise(event, enterprise.status)}>
              {enterprise.name}
            </div>
          </div>
          {enterprise.userHomeInfo.plan_name ? (
            <div className={classes.containerPlanInfo}>
              <ItemIcon
                width="36px"
                height="36px"
                color={enterprise.userHomeInfo.color_plan}
                className={classes.imgPlan}
              />
              <div>
                <div className={classes.planName}>
                  Plano {enterprise.userHomeInfo.plan_name}
                </div>
                <div className={classes.createdAt}>
                  Criado em {enterprise.create_at}
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.noPlanLabel}>Sem plano</div>
          )}
        </div>
        <div className={classes.containerIcon}>
          <IconButton>
            <img
              src="assets/icons/icon_expand_more_24px.svg"
              style={{
                transform: open ? "" : "rotate(180deg)",
                transition: "transform .5s",
              }}
            />
          </IconButton>
        </div>
      </div>
      <div
        className={classes.containerCard}
        style={{ display: open ? "block" : "none" }}
      >
        <div className={classes.containerButtons}>
          <Button
            className={classes.buttonEdit}
            variant="contained"
            color="secondary"
            onClick={goToEditEnterprise}
          >
            Editar empreendimento
          </Button>
          {enterprise.status !== null &&
            enterprise.status !== "CREATED" &&
            enterprise.status !== "PENDING_REGISTRATION" &&
            enterprise.status !== "PENDING_SUBSCRIBE" && (
              <Button
                className={classes.buttonLook}
                variant="outlined"
                onClick={() => {
                  clickOpenMyPlan();
                }}
              >
                Ver plano
              </Button>
            )}
        </div>
      </div>
      {enterprise.role === "Administrador" && (
        <EnterpriseStatus enterprise={enterprise} />
      )}
      <DialogMyPlan
        enterprise_id={enterprise.home_id}
        open={openMyPlan}
        close={changeOpenMyPlanState}
        openInvoice={clickOpenInvoices}
        openChangePlan={changeCannotChanePlan}
        openChangePlanSuccess={changeChanePlan}
      />
      <DialogEnterpriseInvoices
        enterprise_id={enterprise.home_id}
        open={openInvoices}
        close={changeOpenInvoicesState}
      />
      <CannotChangePlan
        open={openChangePlan}
        close={changeCannotChanePlan}
        confirm={changeOpenMyPlanState}
      />
      {openChangePlanSuccess && (
        <ChooseTypePaymentDialog
          open={openChangePlanSuccess}
          description="A sua fatura atual será alterada pelo valor selecionado."
          userCurrentPriceId={enterpriseInfo?.plan?.price?.id}
          onClose={(price) => {
            if (!price) changeChanePlanClose(false);
            else {
              history.push({
                pathname: "/change-to-new-plan",
                state: {
                  price,
                  plan,
                  enterpriseInfo,
                },
              });
            }
          }}
          plan={plan}
        >
          <div style={{ margin: "12px 0" }}>
            <p style={{ fontSize: "14px", margin: 0 }}>
              Caso o valor do plano selecionado seja{" "}
              <strong>menor que o plano anterior</strong>, você terá crédito no
              IzyManager onde será descontado os faturamentos. Se o valor for{" "}
              <strong>maior que o plano anterior</strong>, será descontado
              apenas a diferença nas faturas.
            </p>
          </div>
        </ChooseTypePaymentDialog>
      )}
    </>
  );
};

export default EnterpriseListItem;
