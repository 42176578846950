export const PIDS_ALARM = [
  // IDG 620
  {
    type: 2,
    pid: "hnypn4ub",
    code: "gas_sensor_state",
    value: "1",
    message: "Alarme de gás disparou no ",
  },
  {
    type: 2,
    pid: "dcweoxja",
    code: "gas_sensor_state",
    value: "1",
    message: "Alarme de gás disparou no ",
  },

  // IDF 620
  {
    type: 3,
    pid: "3kuiblb6",
    code: "smoke_sensor_status",
    value: "alarm",
    message: "Alarme de fumaça disparou no ",
  },

  // ISA 1001
  {
    type: 1,
    pid: "nmj3laim",
    code: "doorcontact_state",
    value: true,
    message: "Alarme de abertura disparou no ",
  },

  // ISM 1001
  {
    type: 4,
    pid: "zgaafwtr",
    code: "pir",
    value: "pir",
    message: "Alarme de movimento disparou no ",
  },
];
