import React from "react";
import {
  createStyles,
  makeStyles,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import RoomModel from "../../../../Models/RoomModel";
import CustomTableHeadRoomProps from "./CustomTableHeadRoomProps";
import WithRoleAuthorization from "../../../../Components/WithRoleAuthorization";

const useStyles = makeStyles(() =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

const CustomTableHeadRoom: React.FC<CustomTableHeadRoomProps> = (props) => {
  const {
    order,
    orderBy,
    onRequestSort,
    customHeaderItens,
    customOptionalHeaderItens,
  } = props;
  const classes = useStyles();
  const createSortHandler =
    (property: keyof RoomModel) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {customHeaderItens.map((headCell) => (
          <TableCell
            style={{ backgroundColor: "#F2F2F2" }}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order === "asc" ? "asc" : "desc"}
              onClick={createSortHandler(headCell.id)}
              id={headCell.id}
              IconComponent={KeyboardArrowDownIcon}
            >
              <span
                style={{
                  color: "#8B979F",
                  fontWeight: 600,
                  fontSize: "15px",
                }}
              >
                {headCell.label}{" "}
              </span>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {customOptionalHeaderItens &&
          customOptionalHeaderItens.map((header) => (
            <TableCell
              style={{ backgroundColor: "#F2F2F2", paddingLeft: "26px" }}
              key={header.id}
            >
              <span
                style={{
                  color: "#8B979F",
                  fontWeight: 600,
                  fontSize: "15px",
                }}
              >
                {header.label}
              </span>
            </TableCell>
          ))}
        <WithRoleAuthorization permitedRoles={["Owner", "Administrador"]}>
          <TableCell style={{ backgroundColor: "#F2F2F2" }}>
            <span
              style={{
                color: "#8B979F",
                fontWeight: 600,
                fontSize: "15px",
              }}
            >
              {"Ações"}{" "}
            </span>
          </TableCell>
        </WithRoleAuthorization>
        <TableCell style={{ backgroundColor: "#F2F2F2" }}></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHeadRoom;
