import api from "./Api";
import { AxiosResponse } from "axios";
import authHeader from "./UserService";
import {
  DataPasswordType,
  DoorLockPasswordModel,
  DoorLockPasswordScheduleOption,
} from "../Models/DoorLockPasswordModel";

const PASSWORD_TYPE_UNIQUE_USE = 1;
const PASSWORD_TYPE_MANY_TIME_USE = 0;

const saveDoorLockPassword = (
  idDevice: string,
  name: string,
  password: string,
  type: 0 | 1,
  invalid_time: number,
  effective_time?: number,
  schedule_list?: DoorLockPasswordScheduleOption[]
): Promise<AxiosResponse> => {
  const now = new Date();

  return api.post(
    `devices/${idDevice}/door-lock/temp-password`,
    {
      name,
      password,
      type,
      effective_time: effective_time ? effective_time : now.getTime(),
      invalid_time,
      schedule_list,
    },
    {
      headers: authHeader(),
    }
  );
};

export const saveDoorLockPasswordUnique = (
  idDevice: string,
  dataPassword: DataPasswordType
): Promise<AxiosResponse> => {
  return saveDoorLockPassword(
    idDevice,
    dataPassword.name,
    dataPassword.password,
    PASSWORD_TYPE_UNIQUE_USE,
    dataPassword.expireAt
  );
};

export const saveDoorLockPasswordTemp = (
  idDevice: string,
  dataPassword: DataPasswordType
): Promise<AxiosResponse> => {
  return saveDoorLockPassword(
    idDevice,
    dataPassword.name,
    dataPassword.password,
    PASSWORD_TYPE_MANY_TIME_USE,
    dataPassword.expireAt
  );
};

export const saveDoorLockPasswordSchedule = (
  id: string,
  params: DoorLockPasswordModel
): Promise<AxiosResponse> => {
  return saveDoorLockPassword(
    id,
    params.name,
    params.password,
    PASSWORD_TYPE_MANY_TIME_USE,
    params.invalid_time,
    params.effective_time,
    params.schedule_list
  );
};

export const getDoorLockPassword = (
  idDevice: string
): Promise<AxiosResponse> => {
  return api.get(`devices/${idDevice}/door-lock/dynamic-password`, {
    headers: authHeader(),
  });
};

export const getDoorLockPasswords = (
  idDevice: string
): Promise<AxiosResponse> => {
  return api.get<DoorLockPasswordModel[]>(
    `devices/${idDevice}/door-lock/temp-passwords`,
    {
      headers: authHeader(),
    }
  );
};
