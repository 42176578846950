import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemText,
  Paper,
} from "@material-ui/core";
import OperatorsGroupPageCreateContext from "../../Context/OperatorsGroupCreatePageContext";
import { UserPaginated } from "../../../../Models/UserModel";
import InputSearch from "../InputSearch";
import { cloneDeep, debounce } from "lodash";
import EmptyState from "../EmptyState";

const useStyles = makeStyles(() => ({
  header: {
    margin: "12px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "end",

    "& .MuiFormControlLabel-label": {
      fontWeight: 600,
      fontSize: "15px",
    },

    "& .MuiCheckbox-root ": {
      padding: "0px 20px",
    },
  },
  containerSelectAutomation: {
    maxHeight: "300px",
    overflowY: "auto",
    "& .MuiListItemText-primary": {
      color: "#8B979F",
      fontWeight: "600",
      fontSize: "15px!important",
    },
    "& .MuiListItemText-secondary": {
      color: "#8B979F",
      fontSize: "15px!important",
    },
  },
  itemAutomation: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 12px",
    borderRadius: "0",
    border: "1px #e8e8e8 solid",

    "& .MuiCheckbox-colorSecondary:not(.Mui-checked)": {
      color: "#8B979F",
    },
  },
}));

const UsersGroup: React.FunctionComponent = () => {
  const {
    usersActive,
    isCheckAll,
    setIsCheckAll,
    isCheck,
    setIsCheck,
    isEdit,
    operatorsGroupListEdit,
    userDeactive,
  } = useContext(OperatorsGroupPageCreateContext);

  const classes = useStyles();

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(allUers.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const [allUers, setAllUsers] = useState<UserPaginated[]>([]);
  const [search, setSearch] = useState<string>("");
  const [filteredUsers, setFilteredUsers] = useState<UserPaginated[]>([]);

  const handleClick = (e: ChangeEvent<HTMLInputElement>, id: string) => {
    const { checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }

    if (isCheck.filter((item) => item !== id).length !== usersActive.length)
      setIsCheckAll(false);
  };

  useEffect(() => {
    setAllUsers(
      [...usersActive, ...userDeactive].filter(
        (user: UserPaginated) => user?.userType?.name !== "Administrador"
      )
    );
  }, [usersActive, userDeactive]);

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    return setSearch(event.target.value);
  };

  useEffect(() => {
    searchTerm(search, allUers);
  }, [allUers, search]);

  const searchTerm = useCallback(
    debounce((search, users: UserPaginated[]) => {
      const cloned = cloneDeep(users);
      let filtered = cloned;
      if (search.trim() != "") {
        const term = search.toUpperCase();
        filtered = cloned.filter((user) =>
          user.name.toUpperCase().includes(term)
        );
      }
      setFilteredUsers(filtered);
    }, 300),
    []
  );

  useEffect(() => {
    if (isEdit) {
      setIsCheck(operatorsGroupListEdit.map((o) => o.id));
    }
  }, [operatorsGroupListEdit, isEdit]);

  useEffect(() => {
    isCheck.length === allUers.length && allUers.length > 0
      ? setIsCheckAll(true)
      : setIsCheckAll(false);
  }, [isCheck]);

  const isResultSearchEmpty = useCallback(() => {
    return search.trim() != "" && filteredUsers.length === 0;
  }, [search, filteredUsers]);

  const isOddRow = (i: number) => {
    return i % 4 == 0 || i % 4 == 1;
  };

  return (
    <>
      <div className={classes.header}>
        <FormControlLabel
          control={
            <Checkbox
              key={"selectAll"}
              checked={isCheckAll}
              onChange={handleSelectAll}
              name="checkedB"
              color="secondary"
            />
          }
          label="Selecionar tudo"
        />

        <InputSearch
          id="filterUser"
          onChange={handleChangeSearch}
          placeholder="Pesquisar usuário"
        ></InputSearch>
      </div>

      <Divider style={{ marginBottom: 16 }} />

      <div className={classes.containerSelectAutomation}>
        <Grid container spacing={0}>
          {filteredUsers.map((user: UserPaginated, i: number) => {
            return (
              <Grid item xs={6} key={"user-" + i}>
                <Paper
                  className={classes.itemAutomation}
                  elevation={0}
                  style={{
                    height: "100%",
                    border: isOddRow(i) ? "none" : "1px solid #D1DAE0",
                    borderLeft: `${isOddRow(i) ? i % 2 : 1}px solid #D1DAE0`,
                    borderRight: `${
                      !isOddRow(i)
                        ? i % 2
                        : isOddRow(i) && filteredUsers.length !== i
                        ? 0
                        : 1
                    }px solid #D1DAE0`,
                    // borderLeftColor: "#D1DAE0 !important",
                    backgroundColor:
                      i % 4 == 0 || i % 4 == 1 ? "#F2F2F2" : "#FFF",
                  }}
                >
                  <Checkbox
                    key={`${user.id}-selected`}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      handleClick(event, user.id);
                    }}
                    name={`${user.id}-user`}
                    color="secondary"
                    checked={isCheck.includes(user.id)}
                  />
                  <ListItem style={{ paddingLeft: 10 }}>
                    <ListItemText primary={user.name} secondary={user.email} />
                  </ListItem>
                </Paper>
              </Grid>
            );
          })}
        </Grid>

        {(allUers.length === 0 || isResultSearchEmpty()) && (
          <EmptyState
            msg={`Nenhum usuário ${
              allUers.length === 0 ? "cadastrado" : "encontrado"
            }`}
          />
        )}
      </div>
    </>
  );
};

export default UsersGroup;
