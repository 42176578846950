import { useContext, useState } from "react";
import CustomTablePlans from "../../../../Components/CustomTablePlans";
import EnterpriseContext from "../../context/EnterpriseContext";
import ChooseTypePaymentDialog from "./components/ChooseTypePaymentDialog";
import { Price } from "../../../../Models/Plans";
import history from "../../../../Services/history";
import { postPlanForPayment } from "../../../../Services/PlansService";
import GlobalContex from "../../../../GlobalContext/GlobalContext";

const Step2: React.FunctionComponent = () => {
  const {
    nextStep,
    setSelectedPlan,
    plans,
    loadingPlans,
    selectedPlan,
    setPriceSelected,
    enterpriseId,
    currentQtnAutomation,
    currentQtnDevice,
    currentQtnUser,
  } = useContext(EnterpriseContext);

  const { showAlert } = useContext(GlobalContex);

  const [open, setOpen] = useState(false);

  const handleClose = async (price?: Price) => {
    setOpen(false);
    if (!price) return;
    if (selectedPlan.trial) {
      try {
        await postPlanForPayment(enterpriseId, price.id);
        history.push(`/enterprise/trial-plan?plan=${selectedPlan.id}`);
      } catch (error) {
        showAlert(
          "Usuário excedeu o limite de criação de empreendimento com o plano trial",
          "error"
        );
      }
      return;
    }
    setPriceSelected(price);
    nextStep();
  };

  const handleCreateClick = () => {
    setOpen(true);
  };

  return (
    <>
      <CustomTablePlans
        loading={loadingPlans}
        callback={(plan) => {
          if (
            currentQtnUser <= plan.max_user &&
            currentQtnDevice <= plan.max_device &&
            currentQtnAutomation <= plan.max_automation
          ) {
            setSelectedPlan(plan);
            handleCreateClick();
          } else {
            showAlert(
              "Plano insuficiente, selecionar um maior ou remova usuarios/dispositivos/automações excedentes!",
              "error"
            );
          }
        }}
        plans={plans}
      />

      {selectedPlan && (
        <ChooseTypePaymentDialog
          plan={selectedPlan}
          open={open}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default Step2;
