import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { HomeUserModel } from "../../../../Models/HomeUserModel";
import history from "../../../../Services/history";

const useStyles = makeStyles(() => {
  return {
    statusContainer: {
      marginBottom: "10px",
      textAlign: "center",
      fontWeight: 600,
      fontSize: "13px",
      color: "#FFFFFF",
      borderRadius: "0px 0px 5px 5px",
      lineHeight: "18px",
    },
    statusContainerCompleted: {
      marginBottom: "10px",
    },
  };
});

interface Props {
  enterprise: HomeUserModel;
}

const EnterpriseStatus: React.FunctionComponent<Props> = ({
  enterprise,
}: Props) => {
  const classes = useStyles();
  const imgArrow = "assets/icons/enterprise-status-arrow.svg";
  const imgAlert = "assets/icons/enterprise-alert-icon.svg";
  const status = enterprise.status;
  const id = enterprise.home_id;

  const IconArrow = () => {
    return (
      <IconButton size="small">
        <img src={imgArrow} />
      </IconButton>
    );
  };

  const IconAlert = () => {
    return (
      <IconButton size="small">
        <img src={imgAlert} />
      </IconButton>
    );
  };

  if (status === "PENDING_REGISTRATION" || status === null) {
    return (
      <div
        className={classes.statusContainer}
        onClick={(event) => {
          event.stopPropagation();
          localStorage.setItem("redirectStep", "0");
          localStorage.setItem("redirectEnterprise", id);
          history.push("/enterprise/create");
        }}
        style={{ backgroundColor: "#6DCC9A", cursor: "pointer" }}
      >
        Clique aqui para finalizar cadastro
        <IconArrow />
      </div>
    );
  }

  if (status === "PENDING_SUBSCRIBE") {
    return (
      <div
        className={classes.statusContainer}
        onClick={(event) => {
          event.stopPropagation();
          localStorage.setItem("redirectStep", "1");
          localStorage.setItem("redirectEnterprise", id);
          history.push("/enterprise/create");
        }}
        style={{ backgroundColor: "#6DCC9A", cursor: "pointer" }}
      >
        Escolha seu plano
        <IconArrow />
      </div>
    );
  }

  if (status === "CREATED") {
    return (
      <div
        className={classes.statusContainer}
        style={{ backgroundColor: "#6D99CC", cursor: "pointer" }}
        onClick={(event) => {
          event.stopPropagation();
          localStorage.setItem("redirectStep", "2");
          localStorage.setItem("redirectEnterprise", id);
          history.push("/enterprise/create");
        }}
      >
        Aguardando pagamento
        <IconArrow />
      </div>
    );
  }

  if (status === "ABORTED" || status === "PAYMENT_FAILED") {
    return (
      <div
        className={classes.statusContainer}
        onClick={(event) => {
          event.stopPropagation();
          localStorage.setItem("redirectStep", "1");
          localStorage.setItem("redirectEnterprise", id);
          history.push("/enterprise/create");
        }}
        style={{ backgroundColor: "#EF847E", cursor: "pointer" }}
      >
        <IconAlert />
        Falha no pagamento
        <IconArrow />
      </div>
    );
  }

  if (status === "EXPIRING") {
    return (
      <div
        className={classes.statusContainer}
        onClick={(event) => {
          event.stopPropagation();
          localStorage.setItem("redirectStep", "1");
          localStorage.setItem("redirectEnterprise", id);
          history.push("/enterprise/create");
        }}
        style={{ backgroundColor: "#EF847E", cursor: "pointer" }}
      >
        Plano expirado, renove seu plano
        <IconArrow />
      </div>
    );
  }

  if (status === "TRIAL") {
    return (
      <div
        className={classes.statusContainer}
        style={{ backgroundColor: "#6ECCE0" }}
      >
        Trial - {enterprise.userHomeInfo.trial_days}{" "}
        {enterprise.userHomeInfo.trial_days === 1
          ? "Dia restante"
          : "Dias restantes"}
      </div>
    );
  }

  if (status === "TRIAL_EXPIRED") {
    return (
      <div
        className={classes.statusContainer}
        onClick={(event) => {
          event.stopPropagation();
          localStorage.setItem("redirectStep", "1");
          localStorage.setItem("redirectEnterprise", id);
          history.push("/enterprise/create");
        }}
        style={{ backgroundColor: "#EF847E", cursor: "pointer" }}
      >
        Trial - período expirado, escolha seu plano <IconArrow />
      </div>
    );
  }

  if (status === "INSUFFICIENT") {
    return (
      <div
        className={classes.statusContainer}
        style={{ backgroundColor: "#EAB42A" }}
      >
        <IconAlert />
        Empreendimento acima do limite do plano
      </div>
    );
  }

  if (status === "CANCELED") {
    return (
      <div
        className={classes.statusContainer}
        onClick={(event) => {
          event.stopPropagation();
          localStorage.setItem("redirectStep", "2");
          localStorage.setItem("redirectEnterprise", id);
          history.push("/enterprise/create");
        }}
        style={{ backgroundColor: "#EF847E", cursor: "pointer" }}
      >
        Assinatura cancelada, faça a renovação
        <IconArrow />
      </div>
    );
  }

  return <div className={classes.statusContainerCompleted}></div>;
};
export default EnterpriseStatus;
