import React, { PropsWithChildren } from "react";
import { styled } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import * as S from "./styled";

type AccordionDevicePasswordDefinitionProps = {
  title: string;
  expanded: boolean;
  iconElement?: JSX.Element | React.Component;
  onChange: () => void;
};

const AccordionStyled = styled(Accordion)({
  border: "1px solid #D1DAE0",
  borderRadius: 5,
  marginBottom: 8,
  boxShadow: "none",

  "&:before": {
    display: "none",
  },

  "&.Mui-expanded": {
    marginTop: 0,
  },

  "& .MuiAccordionSummary-root, & .MuiAccordionDetails-root": {
    paddingLeft: "6rem",
    paddingRight: "6rem",
  },

  "& .MuiAccordionSummary-root.Mui-expanded": {
    borderBottom: "1px solid #D1DAE0",
  },

  "& .MuiAccordionSummary-content": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  "& .MuiAccordionDetails-root": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    padding: "30px 0",
    color: "#8B979F",
  },
});

const AccordionDevicePasswordDefinition: React.FunctionComponent<
  PropsWithChildren<AccordionDevicePasswordDefinitionProps>
> = ({ children, onChange, expanded, title, iconElement }) => {
  return (
    <AccordionStyled expanded={expanded} onChange={onChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {iconElement && <S.Icon>{iconElement}</S.Icon>}
        <S.Title>{title}</S.Title>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </AccordionStyled>
  );
};

export default AccordionDevicePasswordDefinition;
