import Grid from "@material-ui/core/Grid";
import CustomAutomation from "../../CustomAutomation";
import CustomContainerScene from "../../CustomScene";
import { Automations } from "../../../../../Models/Automation";

interface Props {
  automation: Automations;
}

const AutomationsItem: React.FunctionComponent<Props> = ({
  automation,
}: Props) => {
  if (automation.automation_id) {
    return (
      <Grid item xs={12} key={"automation-item-" + automation.automation_id}>
        <CustomAutomation automation={automation} />
      </Grid>
    );
  } else {
    return (
      <Grid item xs={12} key={"automation-item-" + automation.scene_id}>
        <CustomContainerScene scene={automation} />
      </Grid>
    );
  }
};

export default AutomationsItem;
