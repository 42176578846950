import styled from "styled-components";
import MuiTable from "@material-ui/core/Table";

export const Table = styled(MuiTable)`
  min-width: 750px;
  border-collapse: separate;

  & thead tr th {
    background: #e8f0f8;
    border: 0;
    line-height: 1;
    padding: 0.5rem;

    & .MuiTableSortLabel-root {
      border: 1px solid transparent;
      color: #404040;

      transition: border-color 200ms;
    }

    & .MuiTableSortLabel-active {
      border-color: #dcdcdc;
      border-radius: 3px;
      padding: 0.4rem;
    }

    & .MuiTableSortLabel-icon {
      opacity: 1;
    }
  }

  & thead tr th:first-child {
    background: #e8f0f8;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding-left: 3rem;
  }

  & thead tr th:last-child {
    background: #e8f0f8;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  & tbody::before {
    content: "@";
    display: block;
    line-height: 12px;
    text-indent: -99999px;
  }

  & tbody tr {
    & .MuiTableCell-sizeSmall {
      padding: 6px 24px 6px 7.5px;
    }

    & td:first-child {
      padding-left: 3rem;
    }

    &:first-of-type {
      & .MuiTableCell-root {
        border-top: 1px solid #e9e9e9;
      }

      & .MuiTableCell-root:first-child {
        border-top-left-radius: 8px;
      }

      & .MuiTableCell-root:last-child {
        border-top-right-radius: 8px;
      }
    }

    &:last-of-type {
      & .MuiTableCell-root:first-child {
        border-bottom-left-radius: 8px;
      }

      & .MuiTableCell-root:last-child {
        border-bottom-right-radius: 8px;
      }
    }

    & .MuiTableCell-root {
      background: #fff;
      border-bottom: 1px solid #e9e9e9;

      transition: background-color 200ms;

      color: #404040;
    }

    & .MuiTableCell-root:first-child {
      border-left: 1px solid #e9e9e9;
    }

    & .MuiTableCell-root:last-child {
      border-right: 1px solid #e9e9e9;
    }

    &.Mui-selected {
      & .MuiTableCell-root {
        background: #f2f6fa;
      }
    }
  }

  & .MuiCheckbox-root {
    color: #afafaf;
  }

  & .MuiCheckbox-colorPrimary.Mui-checked {
    color: #6b7e87;
  }
`;
