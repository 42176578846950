import api from "./Api";
import authHeader, { getHomeIDSelect } from "./UserService";
import DeviceModel from "../Models/DeviceModel";
import { AxiosResponse } from "axios";
import { Automation, Automations } from "../Models/Automation";
import { SceneModel, SceneToEditModel } from "../Models/SceneModel";

export const getAllAutomations = async (): Promise<
  AxiosResponse<Automations[]>
> => {
  return await api.get(`/homes/${getHomeIDSelect()}/all-automations`, {
    headers: authHeader(),
  });
};

export const createScene = async (
  scene: SceneModel
): Promise<AxiosResponse<string>> => {
  return await api.post(`/homes/${getHomeIDSelect()}/scenes`, scene, {
    headers: authHeader(),
  });
};

export const editScene = async (
  scene_id: string,
  scene: SceneToEditModel
): Promise<AxiosResponse<boolean>> => {
  return await api.put(
    `/homes/${getHomeIDSelect()}/scenes/${scene_id}`,
    scene,
    {
      headers: authHeader(),
    }
  );
};

export const removeScene = async (
  scene_id: string
): Promise<AxiosResponse<boolean>> => {
  return await api.delete(`/homes/${getHomeIDSelect()}/scenes/${scene_id}`, {
    headers: authHeader(),
  });
};

export const executeScene = async (
  scene_id: string
): Promise<AxiosResponse<boolean>> => {
  return await api.post(
    `/homes/${getHomeIDSelect()}/scenes/${scene_id}/trigger`,
    {
      headers: authHeader(),
    }
  );
};

export const createAutomation = async (
  automation: Automation
): Promise<AxiosResponse<string>> => {
  return await api.post(`/homes/${getHomeIDSelect()}/automations`, automation, {
    headers: authHeader(),
  });
};

export const editAutomation = async (
  automation_id: string,
  automation: Automation
): Promise<AxiosResponse<boolean>> => {
  return await api.put(
    `/homes/${getHomeIDSelect()}/automations/${automation_id}`,
    automation,
    { headers: authHeader() }
  );
};

export const removeAutomation = async (
  automation_id: string
): Promise<AxiosResponse<boolean>> => {
  return await api.delete(
    `/homes/${getHomeIDSelect()}/automations/${automation_id}`,
    {
      headers: authHeader(),
    }
  );
};

export const enableAutomation = async (
  automation_id: string
): Promise<AxiosResponse<boolean>> => {
  return await api.post(
    `/homes/${getHomeIDSelect()}/automations/${automation_id}/actions/enable`,
    { headers: authHeader() }
  );
};

export const disableAutomation = async (
  automation_id: string
): Promise<AxiosResponse<boolean>> => {
  return await api.post(
    `/homes/${getHomeIDSelect()}/automations/${automation_id}/actions/disable`,
    { headers: authHeader() }
  );
};

export const getAllSceneDevices = (): Promise<AxiosResponse<DeviceModel[]>> => {
  return api.get<DeviceModel[]>(`/homes/${getHomeIDSelect()}/scene/devices`, {
    headers: authHeader(),
  });
};

export const getAllAutomationDevices = (): Promise<
  AxiosResponse<DeviceModel[]>
> => {
  return api.get<DeviceModel[]>(
    `/homes/${getHomeIDSelect()}/automation/devices`,
    {
      headers: authHeader(),
    }
  );
};

export const setAutomationFavorited = (
  room: string,
  automation: Automations,
  favorite: boolean
): Promise<AxiosResponse<boolean>> => {
  return api.put(
    `/homes/${getHomeIDSelect()}/room/${room}/favorite-scene`,
    { scene: automation, favorite: favorite },
    { headers: authHeader() }
  );
};

export const setAutomationListFavorited = (
  room: string,
  list: Automations[]
): Promise<AxiosResponse<boolean>> => {
  const params = list;
  return api.put(
    `/homes/${getHomeIDSelect()}/room/${room}/favorite-scenes`,
    params,
    { headers: authHeader() }
  );
};
