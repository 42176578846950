export const removeTimeFromDate = (date: Date): Date => {
  return new Date(
    date.getFullYear() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0") +
      "T00:00:00"
  );
};
