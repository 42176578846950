import { makeStyles } from "@material-ui/core";
import { FunctionComponent } from "react";
import DeviceModel, {
  DeviceFunctionsModel,
} from "../../../../../Models/DeviceModel";
import StatusFunction from "../../../../../Models/StatusFunctionModel";
import CustomBlockResource from "../../../../CustomBlockResource";
import CustomOfflineDeviceComponent from "../../../../CustomOfflineDeviceComponent";

interface CustomComponentProps {
  device: DeviceModel;
  status?: StatusFunction[];
  loading?: boolean;
  specificationsDevice?: DeviceFunctionsModel;
  showConditionType: boolean;
}

interface Props {
  device: DeviceModel;
  CustomComponent: FunctionComponent<CustomComponentProps>;
  loading?: boolean;
  specificationsDevice?: DeviceFunctionsModel;
}

const DefaultDeviceTabContent: React.FunctionComponent<Props> = ({
  device,
  CustomComponent,
  loading,
  specificationsDevice,
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.deviceTabContent}>
      {device.block && (
        <CustomBlockResource
          icon={"icon_padlock"}
          title={"Dispositivo bloqueado"}
          direction={"column"}
          subtitle={
            "Você atingiu o limite de dispositivos do seu plano. Exclua dispositivos ou contrate um novo plano"
          }
        />
      )}

      {!device.online ? (
        <CustomOfflineDeviceComponent />
      ) : (
        <CustomComponent
          status={device.status}
          device={device}
          showConditionType={false}
          loading={loading}
          specificationsDevice={specificationsDevice}
        />
      )}
    </div>
  );
};

export default DefaultDeviceTabContent;

const useStyles = makeStyles(() => ({
  deviceTabContent: {
    position: "relative",
    height: "100%",
  },
}));
