import { makeStyles, createStyles, Grid, Slider } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import GlobalContex from "../../../../../../GlobalContext/GlobalContext";
import DeviceModel from "../../../../../../Models/DeviceModel";
import { checkIsJSON } from "../../../../../../Utils/Functions";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      padding: "60px",
      alignItems: "center",
    },
    button: {
      flexGrow: 1,
      padding: 10,
    },
    title: {
      fontSize: "23px",
      color: "#3E5055",
      fontWeight: 600,
      marginBottom: "0px",
    },
    subtitle: {
      fontSize: "15px",
      color: "#8B979F",
    },
    buttons: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    buttonColor: {
      borderRadius: "0px 25% 25% 0%",
    },
    percent: {
      color: "#8B979F",
      fontSize: "15px",
    },
    optionTitle: {
      color: "#3E5055",
      fontSize: "15px",
      fontWeight: 600,
    },
    slider: {},
    onOff: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    textButtonOnOF: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#8B979F",
      fontSize: "23px",
    },
    colors: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "30px",
      marginBottom: "17px",
    },
    pill: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "20px",
      height: "40px",
      borderRadius: "55px",
      backgroundColor: "red",
      margin: "10px",
      cursor: "pointer",
    },
    pointer: {
      cursor: "pointer",
    },
  })
);

interface Status {
  code: string;
  value: any;
}

interface Props {
  device: DeviceModel;
  status: Status[];
}

interface HSV {
  h: number;
  s: number;
  v: number;
}

const EWS409_COLOUR: React.FunctionComponent<Props> = ({
  device,
  status,
}: Props) => {
  const { handleChangeCommand } = useContext(GlobalContex);

  const classes = useStyles();

  const colourDataV2: Status = status.find(
    (s: Status) => s.code === "colour_data_v2"
  ) || { code: "", value: "" };

  const json: HSV = checkIsJSON(colourDataV2.value)
    ? JSON.parse(colourDataV2.value)
    : { h: 0, s: 0, v: 0 };
  const colorData: number = json.h;

  const toggleDeviceStatus = () => {
    handleChangeCommand(!status[0].value, device.id, status[0].code);
  };

  const [valueBright, setValueBright] = useState<number>(0);
  const [valueTemp, setValueTemp] = useState<number>(0);

  const [changedValueBright, setChangedValueBright] = useState<number>();
  const [changedValueTemp, setChangedValueTemp] = useState<number>();

  useEffect(() => {
    setValueBright(Math.round(json.v / 10));
    setValueTemp(Math.round(json.s / 10));

    if (valueBright === changedValueBright) {
      setChangedValueBright(undefined);
    }
    if (valueTemp === changedValueTemp) {
      setChangedValueTemp(undefined);
    }
  }, [json]);

  const handleBrightChange = (event: any, newValue: number | number[]) => {
    const value: number = (newValue as number) * 10;
    json.v = value;
    handleChangeCommand(JSON.stringify(json), device.id, status[4].code);
  };

  const handleTempChange = (event: any, newValue: number | number[]) => {
    const value: number = (newValue as number) * 10;
    json.s = value;
    handleChangeCommand(JSON.stringify(json), device.id, status[4].code);
  };

  const changeColor = (value: number) => {
    const colourDataV2: Status = status.find(
      (s: Status) => s.code === "colour_data_v2"
    ) || { code: "", value: "" };

    const json: HSV = checkIsJSON(colourDataV2.value)
      ? JSON.parse(colourDataV2.value)
      : { h: 0, s: 0, v: 0 };

    json.h = value;

    handleChangeCommand(JSON.stringify(json), device.id, status[4].code);
  };

  return (
    <div
      style={{
        width: "100%",
        marginTop: !status[0].value ? "110px" : "0px",
      }}
    >
      {status[0].value && (
        <div className={classes.colors}>
          <div
            className={classes.pill}
            onClick={() => changeColor(0)}
            style={{
              backgroundColor: "#FC2525",
              top: "178px",
              right: "-70px",
              position: "relative",
              transform: "rotate(-94deg)",
              border: colorData === 0 ? "4px solid green" : "",
            }}
          ></div>
          <div
            className={classes.pill}
            onClick={() => changeColor(37)}
            style={{
              backgroundColor: "#FC8C25",
              top: "128px",
              right: "-20px",
              position: "relative",
              transform: "rotate(-84deg)",
              border: colorData === 37 ? "4px solid green" : "",
            }}
          ></div>
          <div
            className={classes.pill}
            onClick={() => changeColor(58)}
            style={{
              backgroundColor: "#FCDA25",
              top: "78px",
              right: "10px",
              position: "relative",
              transform: "rotate(-64deg)",
              border: colorData === 58 ? "4px solid green" : "",
            }}
          ></div>
          <div
            className={classes.pill}
            onClick={() => changeColor(129)}
            style={{
              backgroundColor: "#77FC25",
              top: "35px",
              right: "20px",
              position: "relative",
              transform: "rotate(-54deg)",
              border: colorData === 129 ? "4px solid green" : "",
            }}
          ></div>
          <div
            className={classes.pill}
            onClick={() => changeColor(167)}
            style={{
              backgroundColor: "#25FCBB",
              top: "10px",
              right: "10px",
              position: "relative",
              transform: "rotate(-20deg)",
              border: colorData === 167 ? "4px solid green" : "",
            }}
          ></div>
          <div
            className={classes.pill}
            onClick={() => changeColor(188)}
            style={{
              backgroundColor: "#25D5FC",
              top: "10px",
              right: "-10px",
              position: "relative",
              transform: "rotate(20deg)",
              border: colorData === 188 ? "4px solid green" : "",
            }}
          ></div>
          <div
            className={classes.pill}
            onClick={() => changeColor(240)}
            style={{
              backgroundColor: "#2561FC",
              top: "35px",
              right: "-20px",
              position: "relative",
              transform: "rotate(54deg)",
              border: colorData === 240 ? "4px solid green" : "",
            }}
          ></div>
          <div
            className={classes.pill}
            onClick={() => changeColor(273)}
            style={{
              backgroundColor: "#9D25FC",
              top: "78px",
              right: "-10px",
              position: "relative",
              transform: "rotate(64deg)",
              border: colorData === 273 ? "4px solid green" : "",
            }}
          ></div>
          <div
            className={classes.pill}
            onClick={() => changeColor(300)}
            style={{
              backgroundColor: "#F825FC",
              top: "128px",
              right: "20px",
              position: "relative",
              transform: "rotate(84deg)",
              border: colorData === 300 ? "4px solid green" : "",
            }}
          ></div>
          <div
            className={classes.pill}
            onClick={() => changeColor(318)}
            style={{
              backgroundColor: "#FC258C",
              top: "178px",
              right: "70px",
              position: "relative",
              transform: "rotate(94deg)",
              border: colorData === 318 ? "4px solid green" : "",
            }}
          ></div>
        </div>
      )}
      <p onClick={toggleDeviceStatus} className={classes.onOff}>
        {status[0].value && (
          <img
            src="assets/imgs/ewson.png"
            width={170}
            className={classes.pointer}
          />
        )}
        {!status[0].value && (
          <img
            src="assets/imgs/ewsoff.png"
            width={170}
            className={classes.pointer}
          />
        )}
      </p>
      <p className={classes.textButtonOnOF}>
        {status[0].value ? "Ligado" : "Desligado"}
      </p>
      {status[0].value && (
        <div>
          <p className={classes.optionTitle}>Intensidade</p>
          <Grid
            container
            spacing={2}
            style={{
              marginBottom: "15px",
            }}
          >
            <Grid item>
              <img src="assets/icons/sun 1.svg" />
            </Grid>
            <Grid item xs>
              <Slider
                value={changedValueBright || valueBright}
                onChange={(evt, value) => {
                  setChangedValueBright(value as number);
                }}
                onChangeCommitted={handleBrightChange}
                color="secondary"
                className={classes.slider}
              />
            </Grid>
            <Grid item className={classes.percent}>
              {changedValueBright || valueBright}%
            </Grid>
          </Grid>

          <p className={classes.optionTitle}>Contraste</p>
          <Grid container spacing={2}>
            <Grid item>
              <img src="assets/icons/contrast 1.svg" />
            </Grid>
            <Grid item xs>
              <Slider
                value={changedValueTemp || valueTemp}
                onChange={(evt, value) => {
                  setChangedValueTemp(value as number);
                }}
                onChangeCommitted={handleTempChange}
                color="secondary"
              />
            </Grid>
            <Grid item className={classes.percent}>
              {changedValueTemp || valueTemp}%
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default EWS409_COLOUR;
