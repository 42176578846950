import InputGeneric from "../InputGeneric";
import { InputProps } from "../InputProps";

const InputOutlinedForm: React.FunctionComponent<InputProps> = ({
  id,
  name,
  backgroundColor = "#EBEEEE",
  borderRadius = "3px",
  labelColor = "#8B979F",
  inputColor = "#3E5055",
  borderColorFocus = "#00A335",
  borderColorHover = "#00A335",
  borderColor = "#EBEEEE",
  children,
  ...props
}: InputProps) => {
  return (
    <InputGeneric
      id={id}
      name={name}
      variant={"outlined"}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      labelColor={labelColor}
      inputColor={inputColor}
      borderColorFocus={borderColorFocus}
      borderColorHover={borderColorHover}
      borderColor={borderColor}
      {...props}
    >
      {children}
    </InputGeneric>
  );
};

export default InputOutlinedForm;
