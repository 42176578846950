import MiniDrawer from "../../Components/CustomMiniDrawer";
import { getHomeNameSelect } from "../../Services/UserService";
import ContainerPrincipal from "./Components/ContainerPrincipal";
import NotificationConfigProvider from "./Context/NotificationConfigProvider";

const NotificationConfigPage: React.FunctionComponent = () => {
  return (
    <MiniDrawer
      breadcrumb={[
        { name: getHomeNameSelect(), to: "manage-place" },
        { name: "Configurar notificações", to: "" },
      ]}
    >
      <NotificationConfigProvider>
        <ContainerPrincipal />
      </NotificationConfigProvider>
    </MiniDrawer>
  );
};

export default NotificationConfigPage;
