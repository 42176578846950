import AutomationPageContextProvider from "./Context/AutomationPageContextProvider";
import MiniDrawer from "../../Components/CustomMiniDrawer";
import AutomationsContainer from "./Components/Automations";
import CustomAutomationHeadBar from "./Components/AutomationHeadBar";
import CustomDrawer from "./Components/CustomDrawer";
import { getHomeNameSelect } from "../../Services/UserService";
import SubHeader from "../../Components/SubHeader";
import history from "../../Services/history";
import { useContext } from "react";
import GlobalContex from "../../GlobalContext/GlobalContext";
import { Divider } from "@material-ui/core";

const AutomationPage: React.FunctionComponent = () => {
  const { expandSubHeader } = useContext(GlobalContex);

  const backPress = () => {
    history.push("manage-place");
  };

  return (
    <MiniDrawer
      breadcrumb={[
        { name: getHomeNameSelect(), to: "/manage-place" },
        { name: "Automações", to: "" },
      ]}
    >
      <AutomationPageContextProvider>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexFlow: "column",
          }}
        >
          <div
            style={{
              flex: 0,
            }}
          >
            <SubHeader
              text={"Automações"}
              subText={
                "Aqui você pode automatizar os dispositivos para facilitar seu dia a dia. Experimente criar uma nova automação, você pode fazer com que as portas se fechem e as luzes desliguem de um ambiente com apenas um clique."
              }
              backPress={backPress}
            />
            {expandSubHeader && <CustomAutomationHeadBar />}
            <Divider />
          </div>
          <div
            style={{
              flex: 1,
              overflow: "auto",
            }}
          >
            <AutomationsContainer />
          </div>
          <CustomDrawer />
        </div>
      </AutomationPageContextProvider>
    </MiniDrawer>
  );
};

export default AutomationPage;
