import { ImageProps } from "./ImageProps";

const Image: React.FunctionComponent<ImageProps> = ({
  src,
  file_type = "png",
  ...props
}: ImageProps) => {
  const img_file_link = "assets/imgs/";
  return <img {...props} src={img_file_link + src + "." + file_type} />;
};

export default Image;
