import { ReactNode, useContext, useEffect, useState } from "react";
import GlobalContex from "../../../GlobalContext/GlobalContext";
import { activePrivacyTermUser } from "../../../Services/PrivacyTermService";
import PrivacyTermPageContext from "./PrivacyTermPageContext";
import history from "../../../Services/history";

interface Props {
  children: ReactNode;
}

const PrivacyTermPageContextProvider: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { children } = props;
  const { showAlert } = useContext(GlobalContex);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  const acceptPrivacyTerm = () => {
    const result: any = activePrivacyTermUser();
    setIsLoading(true);
    try {
      if (result) {
        showAlert("Termo de compromisso assinado!", "success");
        history.push("/select-home");
        localStorage.setItem("privacy_term", JSON.stringify(true));
      } else {
        showAlert(
          "Houve um problema ao assinar o termo de compromisso.",
          "warning"
        );
        localStorage.setItem("privacy_term", JSON.stringify(false));

        history.push("/login");
      }
    } catch (e: any) {
      showAlert("error", e.message);
      localStorage.setItem("privacy_term", JSON.stringify(false));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PrivacyTermPageContext.Provider
      value={{
        isLoading,
        acceptPrivacyTerm,
      }}
    >
      <div
        style={{
          backgroundColor: "#E5E5E5",
          width: "100%",
          height: "100vh",
          padding: "40px 200px",
        }}
      >
        {children}
      </div>
    </PrivacyTermPageContext.Provider>
  );
};

export default PrivacyTermPageContextProvider;
