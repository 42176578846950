import { makeStyles, createStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { useContext } from "react";
import GlobalContex from "../../../../GlobalContext/GlobalContext";

const useStyles = makeStyles(() =>
  createStyles({
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "20px",
    },
    term: {
      backgroundColor: "#EBEEEE",
      height: "15rem",
      width: "100%",
      overflowY: "scroll",
      borderRadius: "10px",

      padding: "20px",
    },
    copy: {
      fontSize: "15px",
      lineHeight: "20px",
      color: "#00A335",
    },
  })
);

const TextOfTerm: React.FunctionComponent = () => {
  const classes = useStyles();

  const { copyPrivacyTermToClipboard } = useContext(GlobalContex);

  return (
    <>
      <div className={classes.row}>
        <div>
          <p
            style={{
              marginBottom: "auto",
            }}
          >
            <strong>Termos de condição e uso</strong>
          </p>
          <p
            style={{
              marginBottom: "auto",
            }}
          >
            Por favor, leia o seguinte termo com atenção.
          </p>
        </div>
        <div className={classes.copy}>
          <IconButton
            onClick={() => copyPrivacyTermToClipboard("term")}
            size="small"
            style={{
              marginRight: "10px",
            }}
            data-copy="#term"
            data-done="section copied"
          >
            <img src="assets/icons/icon_copy.svg" />
          </IconButton>
          Copiar
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.term}>
          <p>Versão atualizada em 06.01.2020</p>
          <p>
            <strong>
              Para utilizar o Produto Intelbras, é indispensável a aceitação dos
              termos descritos a seguir.
            </strong>
          </p>
          <div id="term">
            <ol>
              <li>Informações Importantes</li>
              <p>
                No decorrer dessa política, adotamos os termos abaixo listado
                com os respectivos significados: Autoridade Nacional de Proteção
                de Dados (ANPD) - É o órgão que fiscalizará e orientará a
                aplicação da LGPD, bem como é responsável pela aplicação de
                sanções administrativas em caso de violação à lei.
              </p>
              <p>
                Base Legal para Tratamento – O tratamento de Dados Pessoais é
                permitido pela LGPD de acordo com as bases legais previstas,
                tais como o consentimento do Titular, o cumprimento de
                obrigações legais e/ou regulatórias pela Intelbras, a existência
                de contrato entre a Intelbras e o Titular, análise para proteção
                ao crédito, bem como legítimos interesses da Intelbras ou do
                Titular.
              </p>
              <p>
                Biometria - Uma característica física mensurável ou um traço
                pessoal comportamental pessoal usado para reconhecer ou
                verificar a identidade de uma pessoa. Imagens faciais,
                impressões digitais e amostras de íris são exemplos de
                biometria.{" "}
              </p>
              <p>
                Comissão de Privacidade - Significa o comitê de pessoas da
                Intelbras composto por representantes do Departamento Jurídico,
                TI e Qualidade, responsável pela representação da Intelbras
                perante os titulares e a ANPD, bem como será responsável pela
                orientação geral a respeito do tratamento de Dados Pessoais na
                Intelbras.
              </p>
              <p>
                {" "}
                Consentimento - É a manifestação livre, informada e inequívoca
                pela qual o Titular concorda com o tratamento de seus Dados
                Pessoais para uma finalidade determinada.{" "}
              </p>
              <p>
                Dado Pessoal - É qualquer dado relacionado à pessoa natural
                identificada ou identificável, tais como: IP, geolocalização,
                nome, RG, CPF, endereço, telefone, conta bancária, dados de
                veículo, dentre outros.
              </p>{" "}
              <p>
                Dado Pessoal Sensível - É o Dado Pessoal que apresente origem
                racial ou étnica, convicção religiosa, opinião política,
                filiação à sindicato, dado referente à saúde ou à vida sexual,
                dado genético ou biométrico.
              </p>{" "}
              <p>
                Finalidade - Trata-se da razão ou motivação para que seja
                realizado o tratamento de Dados Pessoais.
              </p>{" "}
              <p>
                Legítimo Interesse - Tratamento de dados realizado pelo
                Controlador, em razão de um interesse seu, de outras empresas ou
                da sociedade, sem que o tratamento afete os direitos e
                liberdades individuais do Titular.
              </p>
              <p>
                {" "}
                Lei Geral de Proteção de Dados (LGPD) - Lei nº 13.709, de 14 de
                agosto de 2018.
              </p>
              <p>
                {" "}
                Livre Acesso - Direito do Titular de ter acesso a todas as
                informações referentes ao tratamento de seus Dados Pessoais.
              </p>{" "}
              <p>
                Oposição - É o direito do Titular de não querer que seu dado
                seja tratado. Esse direito pode ser exercido em determinadas
                situações específicas.{" "}
              </p>
              <p>
                {" "}
                Segurança - Significa a utilização de medidas técnicas e
                administrativas aptas a proteger os Dados Pessoais de acessos
                não autorizados e de situações acidentais ou ilícitas de
                destruição, perda, alteração, comunicação ou difusão.
              </p>{" "}
              <p>
                Tratamento - É toda operação realizada com o Dado Pessoal, tais
                como: coleta, produção, recepção, classificação, utilização,
                acesso, reprodução, transmissão, distribuição, processamento,
                arquivamento, armazenamento, eliminação, avaliação, controle de
                informação, comunicação, transferência, difusão ou extração.{" "}
              </p>{" "}
              <p>
                Titular - Pessoa natural a que se referem os Dados Pessoais que
                são objeto de tratamento.
              </p>
              <p>
                {" "}
                Transparência - É a garantia, aos Titulares, de informações
                claras, precisas e facilmente acessíveis sobre a realização do
                tratamento e respectivos agentes de tratamento, observados os
                segredos comercial e industrial.
              </p>
              <li>Introdução</li>
              <p>
                Temos o compromisso de proteger a privacidade dos usuários de
                nossos Serviços.
              </p>{" "}
              <p>
                Esta política se aplica quando atuamos como um controlador de
                dados em relação aos dados pessoais de usuários de nossos
                Serviços.
              </p>
              <p>
                Nós usamos cookies em nosso site. Na medida em que esses cookies
                não sejam estritamente necessários para a navegação em nosso
                site e/ou o fornecimento de nossos Serviços, solicitaremos que
                você concorde com o uso de cookies quando visitar o nosso site
                pela primeira vez.
              </p>
              <p>
                {" "}
                Nossos sites, aplicativos e serviços de nuvem incorporam
                funcionalidades que lhe permitem configurar suas preferências
                sobre seu Dados Pessoais e sua privacidade, os chamados
                controles de privacidade. Ao usar os controles de privacidade,
                você pode especificar como deseja que a Intelbras lide com seus
                Dados Pessoais.
              </p>{" "}
              <p>Nesta política, “nós” e “nosso” se referem a Intelbras.</p>
              <li>Seus Dados Pessoais e como os Utilizamos</li>
              <p>
                Podemos tratar dados referentes ao uso dos nossos sites,
                produtos, serviços de nuvem e aplicativos e por meio da
                utilização destes (“Serviços”). O Uso de Dados pode incluir seu
                endereço de IP, localização geográfica, tipo e versão de
                navegador, sistema operacional, tempo da visita aos nossos sites
                (“Uso de Dados”), número de utilizações dos Serviços, data da
                visita, dentre outras. A fonte do Uso de Dados é Google
                Analytics. O Uso de Dados pode ser realizado para o propósito de
                análise de uso dos Serviços, produtos e serviços da Intelbras. A
                base legal para o tratamento de tais dados é o legítimo
                interesse da Intelbras, como meio de garantir a plena execução e
                funcionalidades dos Serviços.
              </p>{" "}
              <p>
                Podemos tratar os dados de seu cadastro nos Serviços (“Dados de
                Cadastro”). Os cadastros podem incluir nome, endereço de e-mail,
                endereço, CPF, telefone, imagem, foto, áudio, entre outros. Os
                Dados de Cadastro podem ser tratados para fins de operação dos
                Serviços, fornecimento de produtos e serviços, garantia da
                segurança de nossos Serviços, produtos e serviços, manutenção de
                backup de bases de dados e comunicação com nossos clientes,
                incluindo a disponibilização e acesso à todas as funcionalidades
                dos Serviços. A base legal para o tratamento de tais dados é o
                consentimento.{" "}
              </p>
              <p>
                Podemos tratar dados a partir de questionamentos que nos sejam
                enviados em relação a atendimento ao consumidor (“Dados de
                Relação do Consumidor”). Os Dados de Questionamentos do
                Consumidor podem incluir detalhes sobre suas perguntas e
                problemas com produtos e/ou serviços e podem ser tratados para
                fins de oferta de novos produtos e/ou serviços, marketing,
                atualização de processos de resposta e melhoria de atendimento
                ao consumidor. A base legal para o tratamento de tais dados é o
                legítimo interesse da Intelbras e de seus clientes e cumprimento
                de obrigação legal.
              </p>
              <p>
                Podemos tratar seus dados obtidos a partir de transações
                financeiras realizadas a partir de nossos Serviços, inclusive
                para aquisição de produtos e/ou serviços (“Dados de
                Transações”). Os Dados de Transações podem incluir seu endereço,
                CPF, dados financeiros, bancários e número de cartão de crédito,
                dentre outros, e são tratados para o fornecimento de produtos
                e/ou serviços, manutenção de arquivos, cumprimento de obrigações
                fiscais. A base legal para o tratamento de tais dados é o
                cumprimento de obrigação legal, especificamente legislação
                fiscal.{" "}
              </p>
              <p>
                Podemos tratar seus Dados Pessoais para o envio de anúncios,
                propagandas e marketing direto, relacionados aos interesses do
                cliente de acordo com suas preferências. A base legal para o
                tratamento de tais dados é o consentimento, bem como legítimo
                interesse do controlador.
              </p>{" "}
              <p>
                Em alguns casos, o Produto Intelbras poderá captar dados de
                terceiros do ambiente (“Dados de Terceiros”). Os dados podem
                incluir imagens faciais, gravações, áudio, que podem ser
                classificados como dados pessoais, protegidos por lei. A
                responsabilidade pela proteção dos dados de terceiros, captados
                a partir dos produtos da Intelbras, são de inteira
                responsabilidade do Usuário, cabendo a este, aplicar todos os
                meios necessários para a proteção efetiva dos dados,
                especialmente, a configuração dos produtos e de rede para que os
                padrões mínimos de segurança sejam garantidos. A Intelbras não é
                responsável pelo uso ilegal dos dados coletados por intermédio
                de seus produtos, pois inacessível a gestão dos mesmos.
              </p>
              <li>Transferência de Dados para Terceiros</li>
              <p>
                Podemos divulgar seus dados pessoais a qualquer membro de nosso
                grupo de empresas (isso significa nossas subsidiárias, nossos
                controladores e todas as suas controladas), na medida do
                razoavelmente necessário para as finalidades informadas e de
                acordo com a legislação aplicável, bem como observadas as bases
                legais de tratamento informadas nessa política. Podemos divulgar
                seus dados pessoais às nossas seguradoras e/ou consultores
                profissionais, na medida do razoavelmente necessário para os
                fins de obter ou manter cobertura de seguro, gerenciar riscos,
                obter aconselhamento profissional ou o ingresso, exercício ou
                defesa em ações administrativas, arbitrais e ou legais. Ademais,
                podemos divulgar seus dados para nossos fornecedores na medida
                do razoavelmente necessário para lhe fornecer nossos produtos
                e/ou serviços e assegurar a segurança na utilização de nossos
                Serviços e na utilização de seus dados pessoais, tais como
                fornecedores, serviços de marketing e atendimento ao consumidor,
                provedores de redes e serviços de nuvem. As transações
                financeiras relacionadas aos nossos Serviços e à aquisição de
                nossos produtos e/ou serviços são tratadas por nossos provedores
                de serviços de pagamento, sendo que compartilhamos os dados da
                transação com nossos prestadores de serviços de pagamento apenas
                na extensão necessária para os fins de processar seus
                pagamentos, reembolsar valores e lidar com reclamações e
                consultas relacionadas a essas questões. Além das divulgações
                específicas de dados pessoais estabelecidas nesta Cláusula 4,
                podemos divulgar seus dados pessoais sempre que tal divulgação
                for necessária para o cumprimento de uma obrigação legal e/ou
                regulatória a qual a Intelbras está sujeita, bem como para
                proteger seus interesses vitais ou os interesses vitais de outro
                titular de dados. A Intelbras e outras empresas de seu grupo
                possui escritórios e instalações em outros países, bem como
                possui fornecedores em outros países (hospedagem de sites,
                serviços de nuvem, gateways de pagamento, suporte técnico,
                desenvolvimento, modelagem, customização, entre outros). Dessa
                forma, podemos transmitir seus dados para fora do Brasil com
                vistas aos propósitos indicados acima. A Intelbras adota todas
                as medidas adequadas e celebra os contratos necessários com seus
                fornecedores e escritórios estrangeiros para garantir que o
                tratamento de dados pessoais fora do Brasil seja realizado de
                acordo com a LGPD e em observância às políticas da Intelbras.
                Você reconhece que os dados pessoais que você fornece à
                Intelbras por meio dos Serviços ou de nossos produtos e/ou
                serviços podem estar disponíveis para operadores em todo o
                mundo. Na medida em que esse acesso global envolva uma
                transferência de Dados pessoais para outros países, essas
                transferências estão sujeitas a política de transferência
                internacional de dados da Intelbras e a mecanismos adequados que
                fornecem um nível adequado de proteção em conformidade com a
                LGPD. Você reconhece que os dados pessoais que você fornece à
                Intelbras por meio dos Serviços ou de nossos produtos e/ou
                serviços podem estar disponíveis para operadores em todo o
                mundo.
              </p>
              <li>Armazenamento e Deleção de Dados Pessoais</li>
              <p>
                Os dados pessoais que tratamos para qualquer finalidade não
                devem ser mantidos por mais tempo do que o necessário para tais
                finalidades. Reteremos seus dados pessoais da seguinte maneira:
                (a) Eventuais dados pessoais tais como CPF, e-mail, endereço,
                telefone coletados por intermédio do Site serão mantidos por um
                período mínimo de 5(cinco) após a coleta do dado e por um
                período máximo de 10(dez) anos; (b) Em alguns casos, não é
                possível especificar com antecedência os períodos pelos quais
                seus dados pessoais serão retidos. Nesses casos, determinaremos
                o período de retenção com base nos seguintes critérios, sem
                prejuízo de outros: (i) existência de lei ou regulação
                específica exigindo prazo determinado para retenção de dados;
                (ii) políticas internas da Intelbras; (iii) existência de
                processos judiciais, administrativos ou arbitrais; e (iv)
                solicitações de informações realizadas por autoridades
                governamentais.
              </p>
              <li>Alterações a Política de Privacidade</li>
              <p>
                A Intelbras pode alterar essa política a qualquer tempo, sendo
                que tais atualizações são publicadas em nossos Serviços e podem
                ser verificadas a qualquer tempo. Recomendamos que você
                verifique periodicamente nossos Serviços para consultar
                eventuais alterações a essa Política de Privacidade. Em caso de
                alterações significativas a essa política de privacidade podemos
                lhe comunicar por meio de e-mail sobre tais alterações ou por
                meio de mensagens específicas em nossos Serviços.
              </p>
              <li>Seus Direitos</li>
              <p>
                Seus principais direitos sob a legislação de proteção de dados
                são: (c) direito de acesso; (a) direito de retificação; (b)
                direito de oposição ao tratamento; (c) direito a portabilidade
                de dados; (d) direito de pleito perante às autoridades
                competentes; e (e) direito de retirada do consentimento. Você
                tem o direito de confirmar se tratamos ou não seus dados
                pessoais e onde o fazemos, bem como tem direito a acessar tais
                dados pessoais, juntamente com certas informações adicionais.
                Essas informações adicionais incluem detalhes das finalidades de
                tratamento, das categorias de dados pessoais em questão e dos
                destinatários dos dados pessoais. Se os direitos e liberdades de
                terceiros não forem afetados, forneceremos a você uma cópia de
                seus dados pessoais. Você tem o direito de retificar e atualizar
                seus dados pessoais, mediante solicitação ao contato
                privacidade@intelbras.com.br Em algumas circunstâncias, você tem
                o direito de apagar seus dados pessoais sem demora
                injustificada. Essas circunstâncias incluem: (i) os dados
                pessoais não são mais necessários em relação aos fins para os
                quais foram coletados ou tratados; (ii) você retira o
                consentimento para o tratamento baseado em consentimento; (iii)
                você se opõe ao tratamento; (iv) o tratamento é para fins de
                marketing; e (v) os dados pessoais foram tratados ilegalmente.
                No entanto, existem exclusões ao seu direito de se opor ao
                tratamento, tais como quando este é necessário para exercer o
                direito à liberdade de expressão e informação, para o
                cumprimento de obrigações legais e/ou regulatórias, para
                exercício de direitos em processos, ou mesmo para o legítimo
                interesse da Intelbras. Você tem o direito de se opor ao
                tratamento de seus dados pessoais por motivos relacionados à sua
                situação específica, mas apenas na medida em que a base legal do
                tratamento for necessária para o desempenho de uma ação
                realizada em vistas ao interesse público ou no exercício de
                qualquer autoridade oficial investida à Intelbras, ou ainda em
                face a interesses legítimos perseguidos por nós ou por
                terceiros. Se você fizer tal objeção, deixaremos de tratar seus
                dados pessoais a menos que possamos demonstrar motivos legítimos
                e convincentes para o tratamento que possam substituir seus
                interesses, direitos e liberdades, ou o tratamento é para o
                estabelecimento, exercício ou defesa de reivindicações
                administrativas, legais ou arbitrais. Você tem o direito de se
                opor ao tratamento de seus dados pessoais para fins de marketing
                direto. Se você fizer tal objeção, deixaremos de tratar seus
                dados pessoais para esse fim. Se você considerar que o
                tratamento de suas informações pessoais viola a legislação de
                proteção de dados, você tem o direito de apresentar uma queixa à
                autoridade competente e solicitar que a Intelbras interrompa
                imediatamente o tratamento em questão. Na medida em que a base
                legal para o tratamento de seus dados pessoais for o
                consentimento, você tem o direito de revogar esse consentimento
                a qualquer momento. A retirada não afetará a legalidade do
                tratamento antes de tal retirada. De acordo com os Serviços que
                você tenha contratado com a Intelbras, você poderá, às suas
                expensas, auditar a conformidade da Intelbras com os termos
                desta Política de Privacidade, enviando à Intelbras uma
                solicitação por escrito, incluindo um plano de auditoria
                detalhado, pelo menos seis semanas antes da auditoria proposta.
                Você e a Intelbras trabalharão cooperativamente para chegar a um
                acordo final sobre o plano de auditoria. A auditoria deve ser
                realizada não mais que uma vez durante um período de doze meses,
                durante o horário comercial, sujeito às políticas e regulamentos
                no local da Intelbras, e não pode interferir nas atividades da
                Intelbras. Se você desejar usar um terceiro para conduzir a
                auditoria, tal terceiro deverá ser mutuamente aprovado por você
                e pela Intelbras, sujeito a celebração de acordo de
                confidencialidade aprovado pela Intelbras. Após a conclusão da
                auditoria, você fornecerá à Intelbras uma cópia do relatório de
                auditoria, que é classificada como informação confidencial nos
                termos de seu contrato com a Intelbras. Você pode exercer
                qualquer um dos seus direitos em relação aos seus dados pessoais
                por meio de notificação por escrito para o endereço da
                Intelbras, endereçada ao Comissão de Privacidade ou por meio de
                e-mail para privacidade@intelbras.com.br.
              </p>
              <li>
                Cookies, Identificadores, Rastreadores e Informações de
                Terceiros
              </li>
              <p>
                Um cookie é um arquivo que contém um identificador (uma
                sequência de letras e números) que é enviado por um servidor da
                web para um navegador da web e é armazenado pelo navegador. O
                identificador é então enviado de volta ao servidor toda vez que
                o navegador solicita uma página de tal servidor. Os cookies
                podem ser cookies “persistentes” ou cookies de “sessão”. Um
                cookie persistente será armazenado por um navegador da Web e
                permanecerá válido até a data de vencimento definida, a menos
                que seja excluído pelo usuário antes da data de vencimento. Um
                cookie de sessão, por outro lado, expirará no final da sessão do
                usuário, quando o navegador da web estiver fechado. Os cookies
                normalmente não contêm nenhuma informação que identifique
                pessoalmente um usuário, mas as informações pessoais que
                armazenamos sobre você podem estar vinculadas às informações
                armazenadas e obtidas a partir de cookies. Utilizamos cookies
                para os seguintes fins: a. autenticação - usamos cookies para
                identificá-lo quando você utiliza nossos Serviços; b. status -
                usamos cookies para nos ajudar a determinar se você está logado
                em nossos Serviços; c. personalização - usamos cookies para
                armazenar informações sobre suas preferências e personalizar
                Serviços para você; d. segurança - usamos cookies como um
                elemento das medidas de segurança usadas para proteger contas de
                usuários, incluindo a prevenção de uso fraudulento de
                credenciais de login e para proteger nossos Serviços e serviços
                em geral; e. publicidade - usamos cookies para nos ajudar a
                exibir anúncios que serão relevantes para você; f. análise -
                usamos cookies para nos ajudar a analisar o uso e desempenho de
                nosso site e serviços; e g. consentimento de cookies - usamos
                cookies para armazenar suas preferências em relação ao uso de
                cookies de maneira mais geral. Nossos provedores de serviços
                usam cookies e esses cookies podem ser armazenados em seu
                computador quando você utiliza Serviços. Usamos o Google
                Analytics para analisar o uso do nosso site. O Google Analytics
                reúne informações sobre o uso do site por meio de cookies. As
                informações coletadas relacionadas ao nosso site são usadas para
                criar relatórios sobre o uso do nosso site. A política de
                privacidade do Google está disponível em:
                https://policies.google.com/privacy Publicamos anúncios baseados
                em interesses do Google AdSense em nossos Serviços. Eles são
                personalizados pelo Google para refletir seus interesses. Para
                determinar seus interesses, o Google rastreará seu comportamento
                em nossos Serviços e em outros sites da Web usando cookies.
                Utilizamos Google Tag Manager em nossos Sites para criar e
                atualizar tags do Google. Você pode visualizar a política de
                privacidade deste provedor de serviços em
                https://policies.google.com/privacy A maioria dos navegadores
                permite que você se recuse a aceitar cookies e que possa excluir
                cookies. Os métodos para fazer isso variam de navegador para
                navegador e de suas versões. O bloqueio de todos os cookies terá
                um impacto negativo na usabilidade de muitos sites. Se você
                bloquear cookies, não poderá usar todos os recursos em nossos
                Serviços. Também utilizamos identificadores de dispositivos
                móveis que são armazenados no seu dispositivo móvel e rastreiam
                determinados dados e atividades que ocorrem no seu dispositivo
                ou por meio dele. Os identificadores de dispositivos móveis
                permitem a coleta de dados pessoais (como controles de acesso à
                mídia), além de informações não pessoalmente identificáveis
                (como dados de uso e tráfego). Além de identificadores, também
                utilizamos Web Beacons que nos ajudam a gerenciar melhor o
                conteúdo de nossos Sites, informando qual conteúdo é eficaz. Os
                Web Beacons estão incorporados ou associados a certos e-mails ou
                outras comunicações que você recebe de Nós ou de Nossos
                parceiros. Os Web Beacons nos ajudam a rastrear suas respostas e
                interesses e fornecer conteúdo e serviços relevantes para você.
                Por exemplo, eles podem nos informar quando você executa ações
                com base nos e-mails que enviamos. Os Web Beacons também nos
                permitem aprimorar nossa publicidade comportamental (definida
                abaixo). Algumas partes do nossos Serviços podem incluir
                recursos de mídia social, como o botão “curtir” do Facebook, e
                widgets, como o botão “compartilhar”. Esses recursos de mídia
                social são hospedados por terceiros ou diretamente em nossos
                Serviços. Quando você usa essas ferramentas, a parte que fornece
                a ferramenta, a terceira parte que opera os serviços de mídia
                social e/ou Nós podemos receber dados pessoais sobre você. Ao
                usar essas ferramentas, você reconhece que algumas informações,
                incluindo dados pessoais, de seus serviços de mídia social serão
                transmitidas para Nós e, portanto, essas informações são
                cobertas por esta Política de Privacidade, e também pelas
                políticas de privacidade dos terceiros. Podemos usar serviços de
                terceiros, como ferramentas de pesquisa abertas e redes sociais,
                para obter informações sobre você (como seu nome ou empresa) e
                enriquecer seus dados pessoais, obtendo informações disponíveis
                publicamente sobre você, como seu cargo, histórico de emprego e
                informações de contato.
              </p>
              <li>Publicidade Comportamental Online</li>
              <p>
                Algumas de nossas propagandas (“Publicidade Comportamental”)
                envolvem o uso de Ferramentas de Rastreamento para coletar
                informações sobre as atividades on-line de um usuário ao longo
                do tempo e em sites e aplicativos não afiliados, além de
                fornecer anúncios ao usuário com base nos interesses de tal
                usuário (conforme inferido pela atividade on-line do usuário) ou
                uso dos nossos Serviços. A publicidade comportamental pode
                aparecer em nossos Serviços. Trabalhamos com terceiros para
                fornecer publicidade comportamental, como redes de publicidade,
                trocas de dados, provedores de serviços de medição de tráfego,
                provedores de serviços de análise de marketing e outros
                provedores de serviços de terceiros. Tais provedores de serviços
                de publicidade executam serviços tais como facilitar a
                segmentação de anúncios e medir e analisar a eficácia da
                publicidade nos Serviços. Tais serviços de segmentação nos
                ajudam a exibir publicidade comportamental adaptada ao seu
                perfil e impedem a exibição de anúncios repetidos, além de
                permitir pesquisar a utilidade dos anúncios. Você tem a opção de
                desativar a publicidade comportamental. Entre em contato conosco
                em privacidade@intelbras.com.br.
              </p>
              <li>Incidentes</li>
              <p>
                A Intelbras avalia e responde prontamente à ocorrência de
                incidentes que possam comprometer seus Dados Pessoais. Caso a
                Intelbras tenha conhecimento de qualquer incidente envolvendo os
                Dados Pessoais de usuários dos Serviços, a Intelbras notificará
                você e as autoridades competentes.
              </p>
              <li>Nossos Dados</li>
              <p>
                Os Serviços são de propriedade e operados pela Intelbras.
                Intelbras está inscrita no CNPJ/MF sob o nº 82.901.000/0001-27,
                tendo sede na Cidade de São José, Estado de Santa Catarina, no
                endereço Rodovia BR 101, KM 210, Área Industrial, CEP 88104-800.
                Você pode entrar em contrato conosco pelo e-mail
                privacidade@intelbras.com.br.
              </p>
              <li>Comissão de Privacidade</li>
              <p>
                Você pode contatar nosso Comissão de Privacidade pelo e-mail
                privacidade@intelbras.com.br para esclarecimentos e
                questionamento sobre a presente Política de Privacidade.
              </p>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
};

export default TextOfTerm;
