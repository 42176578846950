import { useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { Button, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import StripeTermsText from "./StripeTermsText";
import globalContext from "../../GlobalContext/GlobalContext";

export interface StripeTermsDialogProps {
  open: boolean;
  onClose: () => void;
}

const CloseButton = withStyles(() => ({
  root: {
    textTransform: "initial",
    color: "#8B979F",
  },
}))(Button);

const CopyButton = withStyles(() => ({
  root: {
    textTransform: "initial",
    fontSize: "15px",
    color: "#00A335",
  },
}))(Button);

const useStyles = makeStyles({
  dialog: {
    "& .MuiDialog-paper": {
      padding: "20px",
      maxWidth: "850px",
      width: "700px",
    },
    "& .MuiDialogTitle-root": {
      paddingBottom: "5px",
      fontSize: "21px",
      "& .MuiTypography-root": {
        fontWeight: "600",
      },
    },
  },
  buttonClose: {
    position: "absolute",
    right: 10,
    top: 5,
  },
  textBox: {
    backgroundColor: "#EBEEEE",
    border: "1px solid #BDBDBD",
    borderRadius: "10px",
    padding: "20px 10px 20px 20px",

    "& .textBox__content": {
      overflowY: "scroll",
      maxHeight: 300,
    },
  },
});

const StripeTerms: React.FunctionComponent<StripeTermsDialogProps> = (
  props: StripeTermsDialogProps
) => {
  const classes = useStyles();
  const { onClose, open } = props;

  const { showAlert } = useContext(globalContext);

  const handleClose = () => {
    onClose();
  };

  const copyTermsToClipboard = (idElement: string) => {
    const text = document.getElementById(idElement)!.innerText;
    navigator.clipboard.writeText(text).then(() => {
      showAlert("Termos de condição e uso do stripe copiado", "success");
    });
  };

  return (
    <Dialog
      className={classes.dialog}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      open={open}
    >
      <CloseButton
        className={classes.buttonClose}
        onClick={handleClose}
        startIcon={<CloseIcon />}
      >
        Fechar
      </CloseButton>

      <div style={{ paddingLeft: 22 }}>
        <img src="assets/imgs/intelbras-logo.png" width={150} />
      </div>
      <DialogTitle id="dialog-title">
        Termos de condição e uso do stripe
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <p className={"m-0"}>
            Por favor, leia o seguinte contrato com atenção.
          </p>
          <CopyButton
            onClick={() => copyTermsToClipboard("terms-box")}
            size="small"
            startIcon={<img src="assets/icons/icon_copy.svg" />}
          >
            Copiar
          </CopyButton>
        </div>
        <div id="terms-box" className={classes.textBox}>
          <div className={"textBox__content custom-scroll"}>
            <StripeTermsText />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default StripeTerms;
