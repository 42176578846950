import React, { ReactNode, useContext } from "react";
import AutomationPageContext from "./AutomationPageContext";
import { useEffect, useState } from "react";
import {
  Action,
  SceneModel,
  SceneToEditModel,
} from "../../../Models/SceneModel";
import { getCommandLabel } from "../../../Utils/DeviceConfiguration";
import DeviceModel from "../../../Models/DeviceModel";
import GlobalContex from "../../../GlobalContext/GlobalContext";
import {
  createScene,
  executeScene,
  removeScene,
  editScene,
  disableAutomation,
  enableAutomation,
  createAutomation,
  removeAutomation,
  getAllAutomations,
  getAllSceneDevices,
  getAllAutomationDevices,
  editAutomation,
} from "../../../Services/AutomationService";
import {
  Automation,
  Automations,
  Condition,
  Display,
  DisplayScheduleModel,
} from "../../../Models/Automation";
import _ from "lodash";
import { ptBR, colorV2Value } from "../../../Utils/Functions";
import { AxiosError } from "axios";
import { ErrorMessage } from "../../../Utils/ErrorMessage";
interface Props {
  children: ReactNode;
}

const AutomationPageContextProvider: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { showAlert, setPathnameUrl } = useContext(GlobalContex);
  const { children } = props;
  const [automations, setAutomations] = useState<Automations[]>([]);
  const [actions, setActions] = useState<Action[]>([]);
  const [scenes, setScenes] = useState<Automations[]>([]);
  const [isMustShowConditions, setIsMustShowConditions] =
    useState<boolean>(true);
  const [isToEdit, setIsToEdit] = useState<boolean>(false);
  const [wasScene, setWasScene] = useState<boolean>(false);
  const [updateDashboardAutomations, setUpdateDashboardAutomations] =
    useState<boolean>(false);
  const [wasAutomation, setWasAutomation] = useState<boolean>(false);
  const [isScene, setIsScene] = useState<boolean>(false);
  const [conditions, setConditions] = useState<Condition[]>([]);
  const [state, setState] = useState<{
    top: boolean;
    left: boolean;
    bottom: boolean;
    right: boolean;
  }>({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [automationsToEdit, setAutomationsToEdit] = useState<Automations>(
    {} as Automations
  );
  const [isAutomation, setIsAutomation] = useState<boolean>(false);
  const [textFieldAutomacao, setTextFieldAutomacao] = useState<string>("");
  const [allDevices, setAllDevices] = useState<DeviceModel[]>([]);
  const [allSceneDevices, setAllSceneDevices] = useState<DeviceModel[]>([]);
  const [allAutomationDevices, setAllAutomationDevices] = useState<
    DeviceModel[]
  >([]);
  const [isToggleOpen, setIsToggleOpen] = useState<boolean>(false);
  const [enableCreateAutomation, setEnableCreateAutomation] =
    useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogAutomation, setOpenDialogAutomation] = useState(false);
  const [automationId, setAutomationId] = useState<string>("");
  const [device, setDevice] = useState<DeviceModel>({} as DeviceModel);
  const [addCondition, setAddCondition] = useState(false);
  const [addTask, setAddTask] = useState(false);
  const [actionsTemp, setActionsTemp] = useState<Action[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>(
    new Date().getHours().toString() + ":" + new Date().getMinutes().toString()
  );
  const [timeSchedule, setTimeSchedule] = useState<string>(
    new Date().getHours().toString() + ":" + new Date().getMinutes().toString()
  );
  const [repetitionSchedule, setRepetitionSchedule] = useState<number[]>([
    0, 0, 0, 0, 0, 0, 0,
  ]);
  const [openModalFavoritedAutomation, setOpenModalFavoritedAutomation] =
    useState<boolean>(false);

  const [showOptions, setShowOptions] = useState<boolean>(true);
  const [showDevices, setShowDevices] = useState<boolean>(false);
  const [showDeviceComponent, setShowDeviceComponent] =
    useState<boolean>(false);
  const [showAutomations, setShowAutomations] = useState<boolean>(false);
  const [showSchedule, setShowSchedule] = useState<boolean>(false);
  const [profundidade, setProfundidade] = useState<number>(0);
  const [caminho, setCaminho] = useState<string>("options");
  const [scheduleDate, setScheduleDate] = useState<Date>(new Date());

  const [allAutomations, setAllAutomations] = useState<Automations[]>([]);
  const [automationsToShow, setAutomationsToShow] = useState<Automations[]>([]);
  const [isLoadingAutomations, setIsLoadingAutomations] =
    useState<boolean>(false);

  const [isBlockedAutomation, setIsBlockedAutomation] =
    useState<boolean>(false);

  useEffect(() => {
    Promise.all([getAllSceneDevices(), getAllAutomationDevices()])
      .then(([responseAllSceneDevices, responseAllAutomationDevices]) => {
        setAllSceneDevices(responseAllSceneDevices.data);
        setAllAutomationDevices(responseAllAutomationDevices.data);
        setAllDevices(
          _.uniqBy(
            [
              ...responseAllAutomationDevices.data,
              ...responseAllSceneDevices.data,
            ],
            "id"
          )
        );
      })
      .catch((e) => {
        const msg = ErrorMessage(
          e,
          "Não foi possível carregar dados do disposivito/automação."
        );
        showAlert(msg, "error");
      });

    return () => {
      setIsLoadingAutomations(false);
      setIsToggleOpen(false);
      setOpenDialog(false);
      setAutomationId("");
      setOpenDialogAutomation(false);
      setAllSceneDevices([]);
      setAllAutomationDevices([]);
      setAllDevices([]);
      setAutomations([]);
      setScenes([]);
      setAllAutomations([]);
    };
  }, []);

  useEffect(() => {
    setPathnameUrl(window.location.pathname);
    listAutomations();
  }, [updateDashboardAutomations]);

  useEffect(() => {
    setAutomationsToShow(allAutomations);
  }, [allAutomations]);

  const changeNomeDaAutomacao = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextFieldAutomacao(e.target.value);
  };

  const editActionSmart = (entity_id: string, enabled: boolean) => {
    setActionsTemp(
      actionsTemp.map((a) => {
        if (a.entity_id === entity_id)
          a.action_executor = enabled ? "ruleEnable" : "ruleDisable";
        return a;
      })
    );
  };

  const getDeviceName = (deviceId: string) => {
    const index = allDevices.findIndex((device) => device.id === deviceId);
    let device = null;
    if (index > -1) {
      device = allDevices[index];
    }
    return device?.name ? device.name : "Dispositivo";
  };

  const translateOperator = (operator: string) => {
    switch (operator) {
      case "==":
        return "Igual a";
      case "<":
        return "Menor que";
      case ">":
        return "Maior que";
      default:
        return operator;
    }
  };

  const getStatusConditionSub = (condition: Condition) => {
    const index = allDevices.findIndex(
      (device) => device.id === condition.entity_id
    );
    let device = null;
    if (index > -1) {
      device = allDevices[index];
    }
    return (
      <>
        {device &&
          getCommandLabel(device, (condition.display as Display)?.code)}
        <span
          style={{
            fontSize: "15px",
            fontWeight: 600,
            lineHeight: "20px",
            color: "#8B979F",
          }}
        >
          {device && (condition.display as Display)?.value !== undefined
            ? ": "
            : ""}
          {device &&
          (condition.display as Display)?.value?.toString() === "true" ? (
            " ON"
          ) : (condition.display as Display)?.value?.toString() === "false" ? (
            " OFF"
          ) : (condition.display as Display)?.code === "colour_data_v2" ? (
            <div
              style={{
                border: "1px solid grey",
                marginLeft: "10px",
                backgroundColor: colorV2Value(
                  (condition.display as Display)?.value
                ),
                height: "20px",
                width: "20px",
                borderRadius: "50%",
              }}
            ></div>
          ) : (condition.display as Display)?.value !== undefined ? (
            " " +
            (" " +
              translateOperator((condition.display as Display)?.operator) +
              " " +
              ptBR(JSON.stringify((condition.display as Display)?.value)))
          ) : (
            " "
          )}
        </span>
      </>
    );
  };

  const createSceneContext = async () => {
    actions.map((action) => {
      if (
        action.action_executor === "ruleEnable" ||
        action.action_executor === "ruleDisable"
      ) {
        action.actionName = automations.find(
          (automation) => automation.automation_id === action.entity_id
        )?.name;
      } else if (action.action_executor === "ruleTrigger") {
        action.actionName = scenes.find(
          (scene) => scene.scene_id === action.entity_id
        )?.name;
      } else if (action.action_executor === "dpIssue") {
        action.actionName = allDevices.find(
          (device) => device.id === action.entity_id
        )?.name;
      }
    });

    const scene: SceneModel = {
      name: textFieldAutomacao,
      background: "https://images.tuyaus.com/smart/rule/cover/bedroom.png",
      actions,
    };

    if (!actions.length) {
      showAlert("Automação não preenchida", "warning");
      return;
    } else if (!textFieldAutomacao || !textFieldAutomacao.trim().length) {
      showAlert("Preencha o nome da automação", "warning");
      return;
    }

    setIsLoadingAutomations(true);
    await createScene(scene)
      .then(() => {
        listAutomations();
        showAlert("Automação " + scene.name + " criada com sucesso", "success");
      })
      .catch((error) => {
        const message = ErrorMessage(
          error,
          "Houve um problema ao criar automação!"
        );
        showAlert(message, "error");
      })
      .finally(() => {
        resetForm();
        setIsToggleOpen(false);
        setIsLoadingAutomations(false);
      });
  };

  const editSceneContext = async (id: string) => {
    const scene: SceneToEditModel = {
      name: textFieldAutomacao,
      actions,
    };

    if (!textFieldAutomacao.trim().length) {
      showAlert("O campo nome não pode ser vazio", "warning");
      return;
    } else if (!textFieldAutomacao) {
      showAlert("Preencha o nome da automação", "warning");
      return;
    } else if (!actions.length) {
      showAlert("Automação não preenchida", "warning");
      return;
    }

    try {
      setIsLoadingAutomations(true);
      const result = await editScene(id, scene);
      if (result.status === 200) {
        await listAutomations();
        showAlert(
          "Automação " + scene.name + " alterada com sucesso",
          "success"
        );
      } else {
        showAlert("Houve um problema", "warning");
      }
    } catch (e: any) {
      showAlert(e.message, "error");
      setIsLoadingAutomations(false);
    } finally {
      setIsToggleOpen(false);
      resetForm();
    }
  };

  const changeRepeat = (value: number, position: number) => {
    const newState = [...repetitionSchedule];

    newState[position] === 1
      ? (newState[position] = 0)
      : (newState[position] = value);

    setRepetitionSchedule(newState);
  };

  const removeSceneContext = async (id: string, name?: string) => {
    try {
      setIsLoadingAutomations(true);
      const result = await removeScene(id);
      if (result.status === 200) {
        setOpenDialog(false);
        await listAutomations();
        if (!wasScene) {
          showAlert("Automação " + name + " removida com sucesso", "success");
        }
      } else {
        showAlert("Houve um problema", "warning");
      }
    } catch (e: any) {
      showAlert(e.message, "error");
      setIsLoadingAutomations(false);
    } finally {
      setIsToggleOpen(false);
      resetForm();
    }
  };

  const removeAutomationContext = async (id: string, name?: string) => {
    try {
      setIsLoadingAutomations(true);
      const result = await removeAutomation(id);
      if (result.status === 200) {
        setOpenDialog(false);
        await listAutomations();
        if (!wasAutomation) {
          showAlert("Automação " + name + " removida com sucesso", "success");
        }
      } else showAlert("Houve um problema", "warning");
    } catch (e: any) {
      showAlert(e.message, "error");
      setIsLoadingAutomations(false);
    } finally {
      setIsToggleOpen(false);
      resetForm();
    }
  };

  const toggleDrawer = (open: boolean) => () => {
    setIsToggleOpen(open);
  };

  const createAutomationContext = async () => {
    actions.map((action) => {
      if (
        action.action_executor === "ruleEnable" ||
        action.action_executor === "ruleDisable"
      ) {
        action.actionName = automations.find(
          (automation) => automation.automation_id === action.entity_id
        )?.name;
      } else if (action.action_executor === "ruleTrigger") {
        action.actionName = scenes.find(
          (scene) => scene.scene_id === action.entity_id
        )?.name;
      } else if (action.action_executor === "dpIssue") {
        action.actionName = allDevices.find(
          (device) => device.id === action.entity_id
        )?.name;
      }
    });

    const automation: Automation = {
      name: textFieldAutomacao,
      actions,
      conditions,
      enabled: false,
      match_type: 1,
      background: "https://images.tuyaus.com/smart/rule/cover/bedroom.png",
    };

    if (!actions.length) {
      showAlert("Automação não preenchida", "warning");
      return;
    } else if (!textFieldAutomacao || !textFieldAutomacao.trim().length) {
      showAlert("Preencha o nome da automação", "warning");
      return;
    }

    setIsLoadingAutomations(true);
    await createAutomation(automation)
      .then((response) => {
        listAutomations();
        setAutomationId(response.data);
        showAlert(
          "Automação " + automation.name + " criada com sucesso",
          "success"
        );
        setOpenDialogAutomation(true);
      })
      .catch((error) => {
        const message = ErrorMessage(
          error,
          "Houve um problema ao criar automação!"
        );
        showAlert(message, "error");
      })
      .finally(() => {
        resetForm();
        setIsToggleOpen(false);
        setIsLoadingAutomations(false);
      });
  };

  const editAutomationContext = async (id: string) => {
    actions.map((action) => {
      if (
        action.action_executor === "ruleEnable" ||
        action.action_executor === "ruleDisable"
      ) {
        action.actionName = automations.find(
          (automation) => automation.automation_id === action.entity_id
        )?.name;
      } else if (action.action_executor === "ruleTrigger") {
        action.actionName = scenes.find(
          (scene) => scene.scene_id === action.entity_id
        )?.name;
      } else if (action.action_executor === "dpIssue") {
        action.actionName = allDevices.find(
          (device) => device.id === action.entity_id
        )?.name;
      }
    });
    const automation: Automation = {
      name: textFieldAutomacao,
      automation_id: id,
      actions,
      conditions,
      match_type: 1,
      background: "https://images.tuyaus.com/smart/rule/cover/bedroom.png",
    };

    if (!textFieldAutomacao.trim().length) {
      showAlert("O campo nome não pode ser vazio", "warning");
      return;
    } else if (!textFieldAutomacao) {
      showAlert("Preencha o nome da automação", "warning");
      return;
    } else if (!actions.length) {
      showAlert("Automação não preenchida", "warning");
      return;
    }

    try {
      setIsLoadingAutomations(true);
      const result = await editAutomation(id, automation);
      if (result.status === 200) {
        await listAutomations();
        showAlert(
          "Automação " + automation.name + " alterada com sucesso",
          "success"
        );
      } else {
        showAlert("Houve um problema", "warning");
      }
    } catch (e: any) {
      showAlert(e.message, "error");
      setIsLoadingAutomations(false);
    } finally {
      setIsToggleOpen(false);
      resetForm();
    }
  };

  const getDisplayDate = () => {
    const today = new Date();

    scheduleDate.setHours(
      parseInt(timeSchedule.substring(0, 2)),
      parseInt(timeSchedule.substring(3, 5))
    );
    if (today > scheduleDate) {
      scheduleDate.setDate(scheduleDate.getDate() + 1);
    }
    const mes = ("0" + (scheduleDate.getMonth() + 1)).slice(-2);
    const dia = ("0" + scheduleDate.getDate()).slice(-2);

    return scheduleDate.getFullYear() + "" + mes + "" + dia;
  };

  const createNewScheduleCondition = () => {
    if (!timeSchedule.includes("--") && timeSchedule.trim() !== "") {
      const display: DisplayScheduleModel = {
        date: getDisplayDate(),
        time: timeSchedule,
        loops: repetitionSchedule.toString().replaceAll(",", ""),
        timezone_id: "America/Sao_Paulo",
      };
      const condition: Condition = {
        entity_type: 6,
        order_num: 1,
        entity_id: "timer",
        display,
      };

      setIsAutomation(true);

      setConditions([...conditions, condition]);
      goBack(1);
    } else {
      showAlert("Preencha o campo Horário", "warning");
    }
  };

  const resetForm = () => {
    setActions([]);
    setTextFieldAutomacao("");
    setConditions([]);
    setIsScene(false);
    setAddTask(false);
    setEnableCreateAutomation(false);
    setAddCondition(false);
    setIsMustShowConditions(true);
    setIsAutomation(false);
    setWasScene(false);
    setWasAutomation(false);
  };

  const listAutomations = async () => {
    setIsLoadingAutomations(true);
    try {
      const response = await getAllAutomations();
      if (response.status === 200) {
        setAutomations(
          response.data.filter((automation) => automation.automation_id)
        );
        setScenes(response.data.filter((scene) => scene.scene_id));
        setAllAutomations(response.data);
      } else {
        showAlert("Houve um problema ao carregar as automações!", "warning");
      }
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      showAlert(err.message, "error");
    } finally {
      setIsLoadingAutomations(false);
    }
  };

  const activeScene = async (scene: Automations, callback: () => void) => {
    if (!scene.scene_id) {
      return;
    }
    const response = await executeScene(scene.scene_id);

    if (response.status === 200) {
      showAlert(
        'Comando "' + scene.name + '" executado com sucesso',
        "success"
      );
    } else showAlert("Houve um problema", "warning");
    callback();
  };

  const activeAutomation = async (automationId: string, callback: any) => {
    await enableAutomation(automationId)
      .then((response) => {
        if (response.data) {
          const newAutomations = automations;
          const index = newAutomations.findIndex(
            (automation) => automation.automation_id === automationId
          );
          if (index > -1) {
            const newAutomation = newAutomations[index];
            newAutomation.enabled = true;
            newAutomations[index] = newAutomation;
          }
          setAutomations(newAutomations);
        }
      })
      .finally(() => {
        callback();
      });
  };

  const setToggleAutomation = async (
    automation_id: string,
    status: boolean
  ) => {
    if (status) {
      await enableAutomation(automation_id);
    } else {
      await disableAutomation(automation_id);
    }
  };

  const handleCloseModalDelete = () => {
    setOpenDialog(false);
    setOpenDialogAutomation(false);
  };

  const goBack = (nivelDeProfundidade: number) => {
    setProfundidade(nivelDeProfundidade - 1);
    setSelectedDate(
      new Date().getHours().toString() +
        ":" +
        new Date().getMinutes().toString()
    );
    setTimeSchedule(
      new Date().getHours().toString() +
        ":" +
        new Date().getMinutes().toString()
    );

    if (nivelDeProfundidade === 0) {
      setProfundidade(0);
      setIsToggleOpen(false);
      setShowOptions(true);
      setShowDevices(false);
      setShowDeviceComponent(false);
      setShowSchedule(false);
    } else if (nivelDeProfundidade === 1) {
      setShowOptions(true);
      setShowDevices(false);
      setShowDeviceComponent(false);
      setShowAutomations(false);
      setShowSchedule(false);
    } else if (nivelDeProfundidade === 2 && caminho === "dispositivos") {
      setShowOptions(false);
      setShowDevices(true);
      setShowDeviceComponent(false);
      setShowSchedule(false);
    } else if (nivelDeProfundidade === 2 && caminho === "mudanca") {
      setShowOptions(false);
      setShowDevices(true);
      setShowDeviceComponent(false);
      setShowSchedule(false);
    } else if (nivelDeProfundidade === 3) {
      setShowOptions(false);
      setShowDevices(false);
      setShowDeviceComponent(false);
      setShowSchedule(false);
    }
  };

  const goFoward = (nivelDeProfundidade: number, caminho?: string) => {
    setProfundidade(profundidade + 1);

    if (nivelDeProfundidade === -1) {
      setIsToggleOpen(false);
    } else if (nivelDeProfundidade === 0 && caminho === "dispositivos") {
      setShowOptions(false);
      setShowDevices(true);
      setShowDeviceComponent(false);
      setShowAutomations(false);
      setShowSchedule(false);
      setCaminho("dispositivos");
    } else if (nivelDeProfundidade === 0 && caminho === "automacoes") {
      setShowOptions(false);
      setShowDevices(false);
      setShowDeviceComponent(false);
      setShowAutomations(true);
      setShowSchedule(false);
      setCaminho("automacoes");
    } else if (nivelDeProfundidade === 0 && caminho === "agendamento") {
      setShowOptions(false);
      setShowDevices(false);
      setShowDeviceComponent(false);
      setShowAutomations(false);
      setShowSchedule(true);
      setCaminho("agendamento");
    } else if (nivelDeProfundidade === 0 && caminho === "mudanca") {
      setShowOptions(false);
      setShowDevices(true);
      setShowDeviceComponent(false);
      setShowAutomations(false);
      setShowSchedule(false);
      setCaminho("mudanca");
    } else if (nivelDeProfundidade === 1 && caminho === "mudanca") {
      setShowOptions(false);
      setShowDevices(true);
      setShowDeviceComponent(false);
      setShowSchedule(false);
      setShowAutomations(false);
    } else if (nivelDeProfundidade === 1) {
      setShowOptions(false);
      setShowDevices(true);
      setShowDeviceComponent(false);
      setShowSchedule(false);
      setShowAutomations(false);
    } else if (nivelDeProfundidade === 2) {
      setShowOptions(false);
      setShowDevices(false);
      setShowDeviceComponent(false);
      setShowSchedule(false);
      setShowAutomations(false);
    } else if (nivelDeProfundidade === 3) {
      setShowOptions(false);
      setShowDevices(false);
      setShowDeviceComponent(false);
      setShowSchedule(false);
      setShowAutomations(false);
    }
  };

  return (
    <AutomationPageContext.Provider
      value={{
        scheduleDate,
        setScheduleDate,
        automations,
        scenes,
        state,
        setState,
        toggleDrawer,
        textFieldAutomacao,
        allDevices,
        allSceneDevices,
        allAutomationDevices,
        changeNomeDaAutomacao,
        setActions,
        createSceneContext,
        actions,
        isToggleOpen,
        setIsToggleOpen,
        setToggleAutomation,
        activeScene,
        isToEdit,
        setWasScene,
        setWasAutomation,
        wasScene,
        wasAutomation,
        resetForm,
        setIsToEdit,
        isScene,
        setIsScene,
        device,
        setDevice,
        isAutomation,
        setIsAutomation,
        setTextFieldAutomacao,
        automationsToEdit,
        setAutomationsToEdit,
        conditions,
        setConditions,
        enableCreateAutomation,
        removeSceneContext,
        removeAutomationContext,
        editSceneContext,
        editAutomationContext,
        addCondition,
        setAddCondition,
        getStatusConditionSub,
        addTask,
        setAddTask,
        isMustShowConditions,
        setIsMustShowConditions,
        editActionSmart,
        actionsTemp,
        setActionsTemp,
        openDialog,
        setOpenDialog,
        openDialogAutomation,
        setOpenDialogAutomation,
        handleCloseModalDelete,
        setSelectedDate,
        setTimeSchedule,
        getDeviceName,
        selectedDate,
        changeRepeat,
        repetitionSchedule,
        setRepetitionSchedule,
        createNewScheduleCondition,
        updateDashboardAutomations,
        setUpdateDashboardAutomations,
        goBack,
        activeAutomation,
        goFoward,
        showOptions,
        showDevices,
        showDeviceComponent,
        showAutomations,
        setAutomations,
        showSchedule,
        profundidade,
        caminho,
        setShowDeviceComponent,
        setShowDevices,
        setShowOptions,
        createAutomationContext,
        automationId,
        openModalFavoritedAutomation,
        setOpenModalFavoritedAutomation,
        allAutomations,
        automationsToShow,
        setAutomationsToShow,
        isLoadingAutomations,
        setIsLoadingAutomations,
        isBlockedAutomation,
        setIsBlockedAutomation,
      }}
    >
      {children}
    </AutomationPageContext.Provider>
  );
};

export default AutomationPageContextProvider;
