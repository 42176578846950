import Term from "./Components/Term";
import PrivacyTermPageContextProvider from "./Context/PrivacyTermPageContextProvider";

const PrivacyTermPage: React.FunctionComponent = () => {
  return (
    <PrivacyTermPageContextProvider>
      <Term />
    </PrivacyTermPageContextProvider>
  );
};

export default PrivacyTermPage;
