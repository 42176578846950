import { ReactNode, useContext, useEffect, useState } from "react";
import RoomModel from "../../../Models/RoomModel";
import {
  createRoom,
  editRoom,
  getAllRooms,
  removeImgRoom,
  removeRoom,
} from "../../../Services/RoomService";
import DrawerProps from "../../../Types/DrawerProps";
import ManageRoomContext from "./ManageRoomContext";
import GlobalContex from "../../../GlobalContext/GlobalContext";
import { getAllPlace } from "../../../Services/PlaceService";
import { getAllUsersGroup } from "../../../Services/UserGroupsService";
import UserGroupsModel from "../../../Models/UserGroupsModel";
import { useHistory } from "react-router-dom";
import { PlaceOptionsModel } from "../../../Models/PlaceModel";
import { ErrorMessage } from "../../../Utils/ErrorMessage";

interface Props {
  children: ReactNode;
}

const ManageRoomProvider: React.FunctionComponent<Props> = (props: Props) => {
  const { children } = props;

  const {
    placeSelected,
    rooms,
    setRooms,
    originalRoomList,
    setOriginalRoomList,
    toggleWaitIndicator,
    setHomeOperator,
    setPlaces,
    setPathnameUrl,
    showAlert,
  } = useContext(GlobalContex);

  const [isSavingRoom, setIsSavingRoom] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isToEditRoom, setIsToEditRoom] = useState<boolean>(false);
  const [roomId, setRoomId] = useState<string>("");
  const [roomName, setRoomName] = useState<string>("");
  const [state, setState] = useState<{
    top: boolean;
    left: boolean;
    bottom: boolean;
    right: boolean;
  }>({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [selectedFile, setSelectedFile] = useState<File | string>("");
  const [openModalRoom, setOpenModalRoom] = useState<boolean>(false);
  const [selectedRoomModal, setSelectedRoomModal] = useState<
    RoomModel | undefined
  >();
  const [userGroups, setUserGroups] = useState<UserGroupsModel[]>([]);
  const [selectUserGroup, setSelectUserGroup] = useState<UserGroupsModel[]>([]);
  const [roomToEdit, setRoomToEdit] = useState<RoomModel>({} as RoomModel);
  const history = useHistory();

  useEffect(() => {
    setPathnameUrl(window.location.pathname);

    return () => {
      setIsSavingRoom(false);
      setSelectedFile("");
      setUserGroups([]);
    };
  }, []);

  const fileTypes: string[] = [
    "image/apng",
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/pjpeg",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
    "image/x-icon",
  ];

  function validFileType(file: string) {
    return fileTypes.includes(file);
  }

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      showAlert("Tipo de arquivo inválido", "warning");
      setSelectedFile("");
      return;
    }
    if (e.target.files[0]) {
      if (e.target.files[0].size > 1048576) {
        showAlert("A imagem não pode ser maior que 1MB", "warning");
        setSelectedFile("");
        return;
      }
      if (!validFileType(e.target.files[0].type)) {
        showAlert("Tipo de arquivo inválido", "warning");
        setSelectedFile("");
        return;
      }
    } else {
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  const removeImageRoom = async () => {
    if (typeof selectedFile === "string" && selectedFile !== "") {
      await removeImgRoom(roomToEdit.room_id)
        .then((response) => {
          if (response.data) {
            setSelectedFile("");
            showAlert("Imagem do espaço removida com sucesso!", "success");
          }
        })
        .catch((error) => {
          const message = ErrorMessage(
            error,
            "Houve um problema ao remover imagem do espaço!"
          );
          showAlert(message, "error");
        });
    } else {
      setSelectedFile("");
    }
  };

  const loadRooms = (place: string) => {
    try {
      getAllRooms().then((response) => {
        const allRooms = [response.data][0].rooms;
        const placeRooms = allRooms.filter(
          (room: RoomModel) =>
            parseInt(room.place?.place_id) === parseInt(place)
        );

        setRooms(placeRooms);
        setOriginalRoomList(placeRooms);

        toggleWaitIndicator("ManageRoomProvider:loadData", false);
      });
    } catch (error) {
      toggleWaitIndicator("ManageRoomProvider:loadData", false);
      showAlert(
        "Não foi possível carregar os espaços, por favor acesse o local novamente!",
        "warning"
      );
      history.push("/manage-place");
    }
  };

  const loadData = () => {
    toggleWaitIndicator("ManageRoomProvider:loadData", true);

    if (placeSelected.place_id === "0") {
      if (
        localStorage.getItem("placeSelectedID") !== undefined &&
        localStorage.getItem("placeSelectedID") !== null
      ) {
        const getLocalPlace = localStorage.getItem("placeSelectedID");
        if (getLocalPlace) {
          loadRooms(getLocalPlace);
        } else {
          toggleWaitIndicator("ManageRoomProvider:loadData", false);
          history.push("/manage-place");
        }
      } else {
        toggleWaitIndicator("ManageRoomProvider:loadData", false);
        history.push("/manage-place");
      }
    } else {
      setHomeOperator(true);
      loadRooms(placeSelected.place_id);
    }
  };

  const getUserGroups = async () => {
    const response = await getAllUsersGroup(0, -1);
    setUserGroups(response.data.content);
  };

  useEffect(() => {
    loadData();
    getUserGroups();
  }, [placeSelected]);

  const handleChangeUserGroups = (
    setSelectUserGroupSelected: UserGroupsModel,
    add: boolean
  ) => {
    if (add) {
      setSelectUserGroup([...selectUserGroup, setSelectUserGroupSelected]);
      setRoomToEdit({
        ...roomToEdit,
        userGroups: [...selectUserGroup, setSelectUserGroupSelected],
      });
    } else {
      setRoomToEdit({
        ...roomToEdit,
        userGroups: [
          ...selectUserGroup.filter(
            (ug: UserGroupsModel) =>
              ug.user_group_id !== setSelectUserGroupSelected.user_group_id
          ),
        ],
      });
    }
  };

  const handleCloseModalDelete = () => {
    setOpenDialog(false);
    setRoomName("");
  };

  const handleChangeRoomName = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRoomName(event.target.value);
  };

  const handleOpenModalDelete = (id: string) => {
    setOpenDialog(true);
    setRoomId(id);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSavingRoom(true);
    setIsToEditRoom(!!Object.keys(roomToEdit).length);
    if (!roomName.trim().length) {
      showAlert("Nome não pode ser vazio ou somente espaços", "warning");
      setIsSavingRoom(false);
    } else {
      const formData = new FormData();

      formData.append("name", roomName);

      if (!!selectedFile && typeof selectedFile !== "string") {
        formData.append("image", selectedFile, selectedFile.name);
      }

      const getLocalPlaceID = localStorage.getItem("placeSelectedID");
      if (placeSelected.place_id === "0" && getLocalPlaceID) {
        formData.append("place_id", getLocalPlaceID);
      } else {
        formData.append("place_id", placeSelected.place_id);
      }

      let userGroupsIds = "";
      roomToEdit.userGroups?.map((userGroup: any) => {
        if (userGroupsIds === "") {
          userGroupsIds = userGroup.user_group_id;
        } else {
          userGroupsIds += "," + userGroup.user_group_id;
        }
      });
      formData.append("userGroups", userGroupsIds);

      if (isToEditRoom) {
        try {
          await editRoom(roomToEdit, formData).then(async (responseEdit) => {
            const options: PlaceOptionsModel = {
              sortBy: "name",
              sortDir: "ASC",
            };
            await getAllPlace(0, -1, options).then((responsePlace) => {
              setPlaces(responsePlace.data.content);
              setRooms([
                ...rooms.filter(
                  (e: RoomModel) => e.room_id !== responseEdit.data.room_id
                ),
                {
                  ...responseEdit.data,
                },
              ]);
              setOriginalRoomList([
                ...originalRoomList.filter(
                  (e: RoomModel) => e.room_id !== responseEdit.data.room_id
                ),
                {
                  ...responseEdit.data,
                },
              ]);
              resetForm(true);
            });
          });
        } catch (errors: any) {
          showAlert("Houve um problema am atualizar o Espçao.", "warning");
        }
      } else {
        try {
          await createRoom(formData).then(async (responseCreate) => {
            const options: PlaceOptionsModel = {
              sortBy: "name",
              sortDir: "ASC",
            };
            await getAllPlace(0, -1, options).then((responsePlace) => {
              setPlaces(responsePlace.data.content);
              originalRoomList.push(responseCreate.data);
              setRooms(originalRoomList);
              resetForm(true);
            });
          });
        } catch (errors: any) {
          showAlert("Já existe um Espaço com o mesmo nome.", "warning");
        }
      }
      const anchor: DrawerProps = "right";
      toggleDrawer(anchor, false)(event);
    }
  };

  const roomDelete = async () => {
    const response = await removeRoom(roomId);
    return response.data;
  };

  const openToggleDrawerToEdit = (
    anchor: DrawerProps,
    open: boolean,
    room?: RoomModel,
    isToEdit?: boolean
  ) => {
    if (!open) resetForm();
    if (room) {
      setRoomToEdit(room);
      setRoomName(room.name);
      setSelectedFile(room.image ? room.image : "");
      setIsToEditRoom(isToEdit ?? false);
      if (room.userGroups !== null && room.userGroups !== undefined) {
        if (room.userGroups.length > 0) {
          setSelectUserGroup([...room.userGroups]);
        } else {
          setSelectUserGroup([]);
        }
      } else {
        setSelectUserGroup([]);
      }
    }
    setState({ ...state, [anchor]: open });
  };

  const toggleDrawer =
    (
      anchor: DrawerProps,
      open: boolean,
      room?: RoomModel,
      isToEdit?: boolean
    ) =>
    (
      event:
        | React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<HTMLButtonElement>
        | React.FormEvent<HTMLFormElement>
    ) => {
      if (!open) resetForm();
      if (room) {
        setRoomToEdit(room);
        setRoomName(room.name);
        setSelectedFile(room.image);
        setIsToEditRoom(isToEdit ?? false);
      }

      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent<HTMLButtonElement>).key === "Tab" ||
          (event as React.KeyboardEvent<HTMLButtonElement>).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const resetForm = (isCloseToggle = false) => {
    setIsToEditRoom(false);
    if (isCloseToggle) {
      showAlert(
        `Espaço ${roomName} ${
          !!Object.keys(roomToEdit).length ? "atualizado" : " cadastrado"
        } com sucesso!`,
        "success"
      );
    }

    setRoomName("");
    setRoomToEdit({} as RoomModel);
    setSelectUserGroup([]);
    setIsSavingRoom(false);
    setSelectedFile("");
  };

  const modalRoomOpen = (room: RoomModel) => {
    setSelectedRoomModal(room);
    setOpenModalRoom(true);
  };

  const modalRoomClose = () => {
    setOpenModalRoom(false);
  };

  return (
    <ManageRoomContext.Provider
      value={{
        openDialog,
        roomName,
        isSavingRoom,
        state,
        setIsSavingRoom,
        handleCloseModalDelete,
        handleOpenModalDelete,
        roomDelete,
        handleChangeRoomName,
        handleSubmit,
        toggleDrawer,
        setState,
        selectedFile,
        setSelectedFile,
        handleChange,
        setRoomName,
        openToggleDrawerToEdit,
        modalRoomOpen,
        modalRoomClose,
        openModalRoom,
        selectedRoomModal,
        roomToEdit,
        userGroups,
        handleChangeUserGroups,
        selectUserGroup,
        setSelectUserGroup,
        roomId,
        setOpenDialog,
        removeImageRoom,
      }}
    >
      <div
        style={{
          backgroundColor: "#FFF",
          width: "100%",
        }}
      >
        {children}
      </div>
    </ManageRoomContext.Provider>
  );
};

export default ManageRoomProvider;
