import GlobalContextProvider from "./GlobalContext/GlobalContextProvider";
import CustomSnackbar from "./Components/CustomSnackbar";
import { Routes } from "./Routes";
import CustomDrawerApplication from "./Components/CustomDrawerApplication";

function App(): JSX.Element {
  return (
    <GlobalContextProvider>
      <Routes />
      <CustomDrawerApplication />
      <CustomSnackbar />
    </GlobalContextProvider>
  );
}

export default App;
