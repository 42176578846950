import { makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    head: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: "#e9695c",
      color: "#fff",
    },
    typoHead: {
      fontSize: "18px",
      fontWeight: "bold",
    },
    center: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      marginBottom: "2rem",
      flexDirection: "column",
    },
    body: {
      width: "100%",
    },
    bodyText: {
      maxWidth: "450px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "0 auto 0",
    },
    typoAlert: {
      fontSize: "14px",
      textAlign: "center",
      color: "#404040",
      letterSpacing: "0px",
    },
    footer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      width: "70%",
      margin: "30px auto 40px",
    },
    confirmWrapper: {
      position: "relative",

      "& .MuiCircularProgress-root": {
        position: "absolute",
        left: "-36px",
        top: "6px",
      },
    },
    confirmButton: {
      borderRadius: "5px",
      width: "9rem",
      fontWeight: "bold",
      textTransform: "none",
      boxShadow: "none",
      fontSize: "14px",
    },
    labelWarning: {
      fontWeight: 700,
      fontSize: "25px",
      lineHeight: "34px",
      color: "#3E5055",
      marginTop: "10px",
    },
  })
);

export { useStyles };
