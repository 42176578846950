import { Button } from "@material-ui/core";
import { ReactNode } from "react";

import history from "../../Services/history";

interface Props {
  title: string;
  subtitle: string | ReactNode;
  image: string;
  buttonName?: string;
  goBackTo?: string;
}

export default function MiniDrawer({
  title,
  subtitle,
  image,
  buttonName,
  goBackTo,
}: Props): JSX.Element {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        flexDirection: "column",
      }}
    >
      <img src={image} style={{ width: "100%", maxWidth: "500px" }} alt="" />
      <p
        style={{
          color: "#3E5055",
          fontSize: "23px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {title}
      </p>
      <p
        style={{
          color: "#3E5055",
          padding: "0px 15px",
          fontSize: "15px",
          textAlign: "center",
        }}
      >
        {subtitle}
        {buttonName && (
          <span>
            <strong style={{ color: "green" }}>{buttonName}</strong> ou
            verifique com um administrador se você tem permissão para acessar
            essa página
          </span>
        )}
      </p>
      {goBackTo && (
        <Button
          variant="contained"
          onClick={() => {
            history.push(goBackTo);
          }}
        >
          {"Voltar"}
        </Button>
      )}
    </div>
  );
}
