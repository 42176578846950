import styles from "./style.module.css";
import clsx from "clsx";
import { Fragment } from "react";

type StrengthBarProps = {
  score: number;
};

const StrengthBar: React.FunctionComponent<StrengthBarProps> = ({
  score,
}: StrengthBarProps) => {
  const description = ["Fraca", "Média", "Média", "Forte"];

  return (
    <div style={{ display: "flex" }}>
      <ul className={styles.BarGroup}>
        {[1, 2, 3, 4].map((el: number) => (
          <Fragment key={`password-strength-bar-item-${el}`}>
            <li
              className={clsx(
                styles.BarGroup__item,
                score >= el ? styles.Success : styles.Empty
              )}
            ></li>
          </Fragment>
        ))}
      </ul>
      <span style={{ paddingLeft: "5px", textAlign: "right", width: 55 }}>
        {description[score - 1]}
      </span>
    </div>
  );
};
export default StrengthBar;
