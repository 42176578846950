import MiniDrawer from "../../Components/CustomMiniDrawer";
import EnterpriseProvider from "./context/EntepriseProvider";
import ContainerEnterprisePage from "./ContainerEnterprisePage";

const EnterpriseCreatePage: React.FunctionComponent = () => {
  return (
    <MiniDrawer showSideMenu={false} withoutSideMenu={true} bgColor={"#F7F7F7"}>
      <EnterpriseProvider>
        <ContainerEnterprisePage />
      </EnterpriseProvider>
    </MiniDrawer>
  );
};

export default EnterpriseCreatePage;
