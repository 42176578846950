import { createStyles, IconButton, makeStyles } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import { useContext, useEffect } from "react";
import EnvironmentsContext from "../../context/EnvironmentsContext";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import history from "../../../../Services/history";
import Filters from "../Filters";

const useStyles = makeStyles(() =>
  createStyles({
    customToolbar: {
      backgroundColor: "#FFF",
      maxWidth: "100%",
    },
    content: {
      display: "flex",
      flexDirection: "row",
      flexGrow: 1,
      justifyContent: "start",
      alignItems: "center",
    },
    scenarios: {
      display: "flex",
      flexDirection: "row",
      flexGrow: 1,
      justifyContent: "start",
      alignItems: "center",
    },
    scenario: {
      display: "flex",
      flexDirection: "row",
      marginRight: "20px",
      color: "#E0E0E0",
      fontSize: "18px",
      fontWeight: "bold",
    },
    scenarioPrincipal: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginRight: "30px",
      color: "#3E5055",
      fontSize: "32px",
      fontWeight: "bold",
    },
  })
);

const ToolbarEnvironment: React.FunctionComponent = () => {
  const classes = useStyles();
  const { setRoomId } = useContext(EnvironmentsContext);

  const { room } = useContext(GlobalContex);

  useEffect(() => {
    if (room !== null && room !== undefined) {
      setRoomId(room.room_id);
    }
  }, [room]);

  const backButtonClick = () => {
    history.push("/manage-room");
  };

  return (
    <>
      <Toolbar className={classes.customToolbar}>
        <div className={classes.content}>
          <div className={classes.scenarios}>
            <div className={classes.scenarioPrincipal}>
              <IconButton onClick={backButtonClick}>
                <img src="assets/icons/icon_return.svg" />
              </IconButton>{" "}
              Dispositivos
            </div>
          </div>
        </div>
      </Toolbar>
      <div>
        <div
          style={{
            padding: "0px 2.5rem",
          }}
        >
          <Filters />
        </div>
      </div>
    </>
  );
};

export default ToolbarEnvironment;
