import Switch from "@material-ui/core/Switch";
import { useContext } from "react";
import GlobalContex from "../../GlobalContext/GlobalContext";
import StatusFunction from "../../Models/StatusFunctionModel";

interface Props {
  key?: number;
  status: StatusFunction[];
  id: string;
}

const CustomSwitchCaseBoolean: React.FunctionComponent<Props> = ({
  id,
  status,
  key,
}: Props) => {
  const { handleChangeCommands } = useContext(GlobalContex);

  let isChecked = false;

  status.forEach((el) => {
    if (el.value === true) isChecked = true;
  });

  return (
    <Switch
      id={id}
      key={`switch-${key}`}
      checked={isChecked}
      name={`switch-${key}`}
      color="secondary"
      onClick={() => {
        const aux = (status || []).filter((s) => s.code !== 'switch_inching');
        const commands = aux.map((s) => {
          return {
            code: s.code,
            value: !isChecked,
          };
        });
        handleChangeCommands(id, commands);
      }}
    />
  );
};

export default CustomSwitchCaseBoolean;
