import styles from "../style.module.css";
import React, { PropsWithChildren } from "react";

const SwitchThumb: React.FunctionComponent = (
  props: PropsWithChildren<any>
) => {
  return (
    <div className={styles.thumbCircleExternal}>
      <div className={styles.thumbCircleInternal}>{props.children}</div>
    </div>
  );
};

export default SwitchThumb;
