import { makeStyles, createStyles } from "@material-ui/core/styles";
import DeviceModel from "../../../../../Models/DeviceModel";
import { CircularProgress } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import GlobalContex from "../../../../../GlobalContext/GlobalContext";
import ListEnvironmentsFromDevices from "../../../../../Components/ListEnvironmentsFromDevices";
import { getSubDevices } from "../../../../../Services/DevicesService";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DeviceDrawerComposite from "../../../../../Components/CustomDevices/DeviceDrawerComposite";
import Image from "../../../../Image";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      padding: "60px",
      alignItems: "center",
      height: "100%",
    },
    title: {
      fontSize: "23px",
      color: "#3E5055",
      fontWeight: 600,
      marginBottom: "0px",
    },
    subtitle: {
      fontSize: "15px",
      color: "#8B979F",
    },
    listItem: {
      height: "104px",
      backgroundColor: "#FFF",
      margim: "17px !important",
      boxShadow: "1px 1px 10px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "10px",
    },
  })
);

interface Props {
  device: DeviceModel;
}

const Device_IZY_Connect: React.FunctionComponent<Props> = ({
  device,
}: Props) => {
  const { goForwardDrawerApplication } = useContext(GlobalContex);

  const [subDevices, setSubdevices] = useState<DeviceModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const classes = useStyles();

  useEffect(() => {
    const subdevices = async () => {
      const response = await getSubDevices(device.id).finally(() =>
        setIsLoading(false)
      );
      setSubdevices(response.data);
    };
    subdevices();

    return () => {
      setIsLoading(false);
      setSubdevices([]);
    };
  }, []);

  const clickItemIzyConnect = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    device: DeviceModel
  ) => {
    goForwardDrawerApplication(
      <DeviceDrawerComposite status={device.status} device={device} />
    );
  };

  return (
    <div className={classes.container}>
      <div style={{ marginBottom: "20px" }}>
        <p className={classes.title}>{device.name}</p>
        <div className={classes.subtitle}>
          {device.environments && (
            <ListEnvironmentsFromDevices environments={device.environments} />
          )}
        </div>
      </div>
      {isLoading && <CircularProgress color="secondary" />}
      {!isLoading && (
        <>
          {subDevices.length < 1 && (
            <div style={{ textAlign: "center" }}>
              <Image
                src="izy_connect_no_devices"
                style={{ marginBottom: "50px" }}
              />
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "21px",
                  lineHeight: "29px",
                  textAlign: "center",
                  color: "#8B979F",
                }}
              >
                Nenhum dispositivo de controle remoto infravermelho
              </div>
            </div>
          )}
          {subDevices.length > 0 && (
            <List
              style={{
                width: "85%",
              }}
            >
              {subDevices.map((device: DeviceModel, index) => (
                <div
                  key={"subdevice-list-item-" + index}
                  onClick={(event) => {
                    clickItemIzyConnect(event, device);
                  }}
                  style={{
                    marginBottom: "17px",
                  }}
                >
                  <ListItem
                    key={`item-${device.id}`}
                    className={classes.listItem}
                    button
                  >
                    <ListItemAvatar>
                      <img src={device.icon} width={50} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          style={{ fontWeight: "bold", fontSize: "18px" }}
                        >
                          {device.product_name}
                        </Typography>
                      }
                      secondary={device.name}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end">
                        <ArrowForwardIosIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </div>
              ))}
            </List>
          )}
        </>
      )}
    </div>
  );
};

export default Device_IZY_Connect;
