import MiniDrawer from "../../Components/CustomMiniDrawer";
import OperatorsGroupPageContextProvider from "./Context/OperatorsGroupPageContextProvider";
import GroupsOperatorsHeadBar from "./Components/GroupsOperatorsHeadBar";
import { getHomeNameSelect } from "../../Services/UserService";
import { Divider } from "@material-ui/core";
import SubHeader from "../../Components/SubHeader";
import history from "../../Services/history";
import { useContext, useEffect } from "react";
import GlobalContex from "../../GlobalContext/GlobalContext";
import GroupsOperatorsList from "./Components/GroupsOperatorsList";
import { useLocation } from "react-router-dom";

const OperatorsGroupPage: React.FunctionComponent = () => {
  const { expandSubHeader, setPathnameUrl } = useContext(GlobalContex);

  const pathname = useLocation().pathname;

  useEffect(() => {
    setPathnameUrl(pathname); // Atualiza com a url da rota — evita setar sempre que redirecionar para cá
  });

  const backPress = () => {
    history.push("manage-place");
  };

  return (
    <MiniDrawer
      breadcrumb={[
        { name: getHomeNameSelect(), to: "manage-place" },
        { name: "Grupo de operadores", to: "" },
      ]}
    >
      <OperatorsGroupPageContextProvider>
        <div style={{ width: "100%", height: "calc(100% - 10px)" }}>
          <SubHeader
            text={"Grupo de operadores"}
            subText={""}
            backPress={backPress}
          />
          {expandSubHeader && <GroupsOperatorsHeadBar />}
          <Divider />
          <GroupsOperatorsList />
        </div>
      </OperatorsGroupPageContextProvider>
    </MiniDrawer>
  );
};

export default OperatorsGroupPage;
