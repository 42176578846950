import { List, ListItem, Popover } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { ListItemIconStyled, ListItemTextStyled } from "./styled";

interface PropsMenuList {
  open: boolean;
  anchor: HTMLDivElement | null;
  onClose: () => void;
  onClickHelp: () => void;
  onClickShop: () => void;
}

const MenuList: React.FunctionComponent<PropsMenuList> = ({
  open,
  anchor,
  onClose,
  onClickHelp,
  onClickShop,
}: PropsMenuList) => {
  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <List component="nav">
        <ListItem button onClick={onClickHelp}>
          <ListItemTextStyled primary="Central de ajuda" />
          <ListItemIconStyled>
            <HelpOutlineIcon htmlColor="#8B979F" />
          </ListItemIconStyled>
        </ListItem>
        <ListItem button onClick={onClickShop}>
          <ListItemTextStyled primary="Loja intelbras" />
          <ListItemIconStyled>
            <ShoppingCartIcon htmlColor="#8B979F" />
          </ListItemIconStyled>
        </ListItem>
      </List>
    </Popover>
  );
};

export default MenuList;
