const checkTime = (time: number): string => {
  let string_time = time.toString();
  if (time < 10) {
    string_time = "0" + time;
  }
  return string_time;
};

export const formatHours = (time: string): string => {
  if (time) {
    if (time.charAt(2) === ":") {
      const date: Date = new Date(
        1,
        1,
        1,
        parseInt(time.substr(0, 2)),
        parseInt(time.substr(3, time.length))
      );
      const hour = checkTime(date.getHours());
      const minutes = checkTime(date.getMinutes());
      return hour + ":" + minutes;
    } else if (time.charAt(1) === ":") {
      const date: Date = new Date(
        1,
        1,
        1,
        parseInt(time.substr(0, 1)),
        parseInt(time.substr(2, time.length))
      );
      const hour = checkTime(date.getHours());
      const minutes = checkTime(date.getMinutes());
      return hour + ":" + minutes;
    }
    return time;
  }
  return "";
};
