import { createStyles, IconButton, makeStyles } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { MouseEventHandler } from "react";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",

      "& .title__action": {
        marginLeft: "-30px",
      },
    },
    title: {
      fontWeight: 700,
      fontSize: "21px",
      lineHeight: "29px",
      marginBottom: "10px",
    },
    description: {
      fontSize: "15px",
      lineHeight: "20px",
      marginBottom: "30px",
    },
  })
);

type TitleSectionProps = {
  title: string;
  description: string;
  onClickBackButton?: MouseEventHandler;
};

const TitleSection: React.FunctionComponent<TitleSectionProps> = (
  props: TitleSectionProps
) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={"title__action"}>
        <IconButton onClick={props.onClickBackButton} size={"small"}>
          <ChevronLeft />
        </IconButton>
      </div>
      <div>
        <div className={classes.title}>{props.title}</div>
        <div className={classes.description}>{props.description}</div>
      </div>
    </div>
  );
};

export default TitleSection;
