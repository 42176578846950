import { Divider, makeStyles } from "@material-ui/core";
import { useContext } from "react";
import ConfirmCodeContext from "../../Context/ConfirmCodeContext";
import ConfirmCodeContent from "../ConfirmCodeContent";
import HeaderCodePage from "../HeaderCodePage";
import NotReceiveContent from "../NotReceiveContent";

const CodePageContainer: React.FunctionComponent = () => {
  const classes = useStyles();

  const { notReceived } = useContext(ConfirmCodeContext);

  return (
    <div className={classes.container}>
      <HeaderCodePage />
      <Divider />
      {notReceived ? <NotReceiveContent /> : <ConfirmCodeContent />}
    </div>
  );
};

export default CodePageContainer;

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "#FFF",
    borderRadius: "10px",
    maxWidth: "1000px",
  },
}));
