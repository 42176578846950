import { FormControlLabel, Switch } from "@material-ui/core";
import { useEffect, useState } from "react";

interface Props {
  command: {
    code: string;
  };
  value: boolean;
  onChange: (value: boolean) => void;
}

const BooleanInput: React.FunctionComponent<Props> = (props: Props) => {
  const [value, setValue] = useState<boolean>(false);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <FormControlLabel
      control={
        <Switch
          edge="end"
          checked={value}
          onChange={(e) => {
            props.onChange(e.target.checked);
          }}
        />
      }
      labelPlacement="end"
      label={
        props.command.code === "doorcontact_state"
          ? props.value
            ? "Sim"
            : "Não"
          : ""
      }
    />
  );
};

export default BooleanInput;
