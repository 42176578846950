import authHeader, { getHomeIDSelect } from "./UserService";
import api from "./Api";
import DeviceModel from "../Models/DeviceModel";
import { AxiosResponse } from "axios";
import {
  MigrateDevicePayload,
  MigrateDeviceResponse,
} from "../Models/MigrateDeviceModel";

export const getAllDevicesService = (): Promise<
  AxiosResponse<DeviceModel[]>
> => {
  return api.get<DeviceModel[]>(`/homes/${getHomeIDSelect()}/rooms/devices`, {
    headers: authHeader(),
  });
};

export const migrateDevices = (
  to_room_id: string,
  devices: string[],
  to_environment_id?: string
): Promise<AxiosResponse<MigrateDeviceResponse>> => {
  let payload: MigrateDevicePayload;

  if (to_environment_id) {
    payload = {
      to_room_id: +to_room_id,
      devices,
      to_environment_id: +to_environment_id,
    };
  } else {
    payload = {
      to_room_id: +to_room_id,
      devices,
    };
  }

  return api.post<MigrateDeviceResponse>(
    `/homes/${getHomeIDSelect()}/rooms/migrate-devices`,
    payload,
    {
      headers: authHeader(),
    }
  );
};
