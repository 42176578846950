import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  makeStyles,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import InvoiceModel from "../../../../Models/InvoiceModel";
import { getInvoicesPlan } from "../../../../Services/PlansService";
import { toREAL } from "../../../../Utils/CurrencyUtils";

interface Props {
  open: boolean;
  enterprise_id: string;
  close: (value: boolean) => void;
}

const DialogEnterpriseInvoices: React.FunctionComponent<Props> = ({
  open,
  enterprise_id,
  close,
}: Props) => {
  const classes = useStyles();
  const { showAlert } = useContext(GlobalContex);
  const [invoices, setInvoices] = useState<InvoiceModel[]>([]);
  const [isLoadingInvoices, setIsLoadingInvoices] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setIsLoadingInvoices(false);
      setInvoices([]);
    };
  }, []);

  const loadInvoiceData = async (enterprise_id: string) => {
    try {
      setIsLoadingInvoices(true);
      await getInvoicesPlan(enterprise_id).then((response) => {
        setInvoices(response.data);
      });
    } catch (error) {
      showAlert("Não foi possível carregar o histórico de faturas", "error");
      close(false);
    } finally {
      setIsLoadingInvoices(false);
    }
  };

  useEffect(() => {
    if (open) {
      loadInvoiceData(enterprise_id);
    }
  }, [open]);

  const invoiceIcon = (status: string) => {
    switch (status) {
      case "draft":
        return (
          <span className={classes.iconLabel + " " + classes.labelColorGray}>
            Gerando fatura...
          </span>
        );
      case "open":
        return (
          <div>
            <img src="assets/icons/open-payment-icon.svg" />{" "}
            <span className={classes.iconLabel + " " + classes.labelColorGray}>
              Aguardando pagamento
            </span>
          </div>
        );
      case "paid":
        return (
          <div>
            <img src="assets/icons/paid-payment-icon.svg" />{" "}
            <span className={classes.iconLabel + " " + classes.labelColorBlack}>
              Pago
            </span>
          </div>
        );
      case "void":
        return (
          <div>
            <img src="assets/icons/void-payment-icon.svg" />{" "}
            <span className={classes.iconLabel + " " + classes.labelColorBlack}>
              Fatura cancelada{" "}
            </span>
          </div>
        );
      case "uncollectible":
        return (
          <div>
            <img src="assets/icons/uncollectible-payment-icon.svg" />{" "}
            <span className={classes.iconLabel + " " + classes.labelColorBlack}>
              Não pago
            </span>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <Dialog
      onClose={() => {
        close(false);
      }}
      open={open}
      className={classes.dialog}
    >
      <div className={classes.container}>
        <div style={{ textAlign: "right", marginBottom: "30px" }}>
          <Button
            className={classes.btnCloseModal}
            variant="text"
            onClick={() => close(false)}
            startIcon={<img src="assets/icons/erro_linha.svg" />}
          >
            Fechar
          </Button>
        </div>
        {isLoadingInvoices ? (
          <div className={classes.loadingContainer}>
            <CircularProgress color="secondary" size={30} />
          </div>
        ) : (
          <div className={classes.invoicesContainer}>
            <div className={classes.title}>Faturas</div>
            <table className={classes.table}>
              <thead>
                <tr>
                  <td>Código</td>
                  <td>Valor</td>
                  <td>Status</td>
                  <td>Download</td>
                </tr>
              </thead>
              <tbody>
                {invoices.map((invoice, index) => (
                  <tr key={"invoice-item-" + index}>
                    <td>{invoice.number}</td>
                    <td>
                      {toREAL(invoice.total, {
                        fromCents: true,
                      })}
                    </td>
                    <td>{invoiceIcon(invoice.status)}</td>
                    <td>
                      <a href={invoice.invoice_pdf}>
                        <img src="assets/icons/download-icon.svg" />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default DialogEnterpriseInvoices;

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      "& .MuiDialog-paperWidthSm": {
        maxWidth: "100%",
      },
    },
    container: {
      width: "100%",
      maxHeight: "100%",
    },
    btnCloseModal: {
      color: "#8B979F",
      textTransform: "capitalize",
    },
    loadingContainer: {
      width: "200px",
      height: "200px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    invoicesContainer: {
      padding: "0px 50px 50px 50px",
    },
    title: {
      fontWeight: 600,
      fontSize: "21px",
      lineHeight: "29px",
      color: "#3E5055",
      marginBottom: "20px",
    },
    table: {
      "& thead tr": {
        borderBottom: "1px solid #D1DAE0",
      },
      "& thead tr td": {
        fontWeight: 600,
        fontSize: "15px",
        lineHeight: "20px",
        textAlign: "center",
        color: "#263238",
        minWidth: "200px",
        padding: "10px",
      },
      "& tbody tr": {
        borderBottom: "1px solid #D1DAE0",
      },
      "& tbody tr td": {
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "20px",
        textAlign: "center",
        color: "#263238",
        minWidth: "200px",
        padding: "5px",
      },
    },
    iconLabel: {
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "20px",
    },
    labelColorBlack: {
      color: "#263238",
    },
    labelColorGray: {
      color: "#BDBDBD",
    },
  })
);
