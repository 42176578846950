import { AxiosResponse } from "axios";
import {
  FormOperatorsGroupType,
  UserGroupDevicesRestriction,
  UserGroupOptionsModel,
  UserGroupPaginatedModel,
  UserGroupSelectedModel,
  UserGroupStubResponseModel,
} from "../Models/UserGroupsModel";
import api from "./Api";
import authHeader, { getHomeIDSelect } from "./UserService";

export const getAllUsersGroup = (
  pageNumber: number,
  pageSize: number,
  options?: UserGroupOptionsModel
): Promise<AxiosResponse<UserGroupPaginatedModel>> => {
  return api.get(
    `/homes/${getHomeIDSelect()}/user-groups?pageNumber=${pageNumber}&pageSize=${pageSize}${
      options?.filter ? '&filter=name=="*' + options.filter + '*"' : ""
    }${options?.sortBy ? "&sortBy=" + options.sortBy : ""}${
      options?.sortDir ? "&sortDir=" + options.sortDir : ""
    }`,
    {
      headers: authHeader(),
    }
  );
};

export const getUserGroup = (
  user_group_id: string
): Promise<AxiosResponse<UserGroupSelectedModel>> => {
  return api.get(`/homes/${getHomeIDSelect()}/user-groups/${user_group_id}`, {
    headers: authHeader(),
  });
};
export const getAllUsersGroupStub = (): Promise<
  AxiosResponse<UserGroupStubResponseModel>
> => {
  return api.get(`/homes/${getHomeIDSelect()}/user-groups/stub`, {
    headers: authHeader(),
  });
};

export const removeUserGroups = (
  userGroupsId: string
): Promise<AxiosResponse<boolean>> => {
  return api.delete(`/homes/${getHomeIDSelect()}/user-groups/${userGroupsId}`, {
    headers: authHeader(),
  });
};

export const createUserGroups = (
  group: FormOperatorsGroupType
): Promise<AxiosResponse<number>> => {
  return api.post(`/homes/${getHomeIDSelect()}/user-groups/`, group, {
    headers: authHeader(),
  });
};

export const editUserGroups = (
  group: FormOperatorsGroupType,
  user_group_id: string
): Promise<AxiosResponse<boolean>> => {
  return api.put(
    `/homes/${getHomeIDSelect()}/user-groups/${user_group_id}`,
    group,
    {
      headers: authHeader(),
    }
  );
};

export const devicesRestrictionEdit = (
  user_group_id: string,
  room_id: string
): Promise<AxiosResponse<UserGroupDevicesRestriction>> => {
  return api.get(
    `/homes/${getHomeIDSelect()}/user-groups/${user_group_id}/room/${room_id}/device-restriction`,
    { headers: authHeader() }
  );
};

export const getDevicesRestriction = (
  room_id: string
): Promise<AxiosResponse<UserGroupDevicesRestriction>> => {
  return api.get(
    `/homes/${getHomeIDSelect()}/user-groups/stub/room/${room_id}/device-restriction`,
    { headers: authHeader() }
  );
};
