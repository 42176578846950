import {
  makeStyles,
  createStyles,
  Button,
  CircularProgress,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { useContext, useState } from "react";
import PrivacyTermPageContext from "../../Context/PrivacyTermPageContext";
import IconButton from "@material-ui/core/IconButton";
import history from "../../../../Services/history";
import TextOfTerm from "../TextOfTerm";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "cener",
      backgroundColor: "#FFF",
      padding: "60px",
      minHeight: "550px",
      borderRadius: "10px",
      flexDirection: "column",
    },

    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "20px",
    },
    term: {
      backgroundColor: "#EBEEEE",
      height: "15rem",
      width: "100%",
      overflowY: "scroll",
      borderRadius: "10px",

      padding: "20px",
    },
    copy: {
      fontSize: "15px",
      lineHeight: "20px",
      color: "#00A335",
    },
    back: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "20px",
      color: "#8B979F",
    },
    continue: {
      background: "#00A335",
      borderRadius: "3px",
      textTransform: "none",
      padding: "10px 120px",
      "&:hover": {
        background: "#00A335",
      },
      marginTop: "20px",
    },
  })
);

const Term: React.FunctionComponent = () => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  const { acceptPrivacyTerm, isLoading } = useContext(PrivacyTermPageContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const backToLogin = () => {
    history.push("/login");
  };

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <img src="assets/imgs/intelbras-logo.png" width={150} />
        <div>
          <IconButton
            onClick={backToLogin}
            size="small"
            style={{
              marginRight: "10px",
            }}
          >
            <img src="assets/icons/icon_back.svg" />
          </IconButton>
          <span className={classes.back}>voltar</span>
        </div>
      </div>
      <TextOfTerm />
      <div>
        <Checkbox
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "primary checkbox" }}
          id={"checkbox-privacy-term"}
        />
        <span>Eu li e aceito os termos de condição de uso</span>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            color="primary"
            className={classes.continue}
            disabled={!checked}
            onClick={acceptPrivacyTerm}
            id={"btn-confirm-privacy-term"}
          >
            Continuar
            {isLoading && (
              <CircularProgress
                color="primary"
                size={20}
                style={{
                  marginLeft: "10px",
                }}
              />
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Term;
