import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    btnRemove: {
      width: "80px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      border: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
      "&:active": {
        "& #custom-edit-icon-container #custom-edit-icon": {
          background: "url('assets/icons/edit_icon_active.svg')",
          width: "36px",
          height: "36px",
        },
        "& #custom-edit-label": {
          color: "#3E5055",
        },
      },
      "&:hover": {
        "& #custom-edit-icon-container #custom-edit-icon": {
          background: "url('assets/icons/edit_icon_hover.svg')",
          width: "36px",
          height: "36px",
        },
        "& #custom-edit-label": {
          color: "#677884",
        },
      },
    },
    iconBtnContainer: {
      width: "35px",
      height: "35px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      marginBottom: "4px",
    },
    iconBtn: {
      width: "28px",
      height: "28px",
      background: "url('assets/icons/edit_icon.svg')",
    },
    iconLabel: {
      marginBottom: "auto",
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "20px",
      color: "#8B979F",
    },
  })
);

interface Props {
  id: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const CustomEditIcon: React.FunctionComponent<Props> = ({
  id,
  onClick,
}: Props) => {
  const classes = useStyles();
  return (
    <button
      id={id}
      type="button"
      onClick={onClick}
      className={classes.btnRemove}
    >
      <div className={classes.iconBtnContainer} id="custom-edit-icon-container">
        <div className={classes.iconBtn} id="custom-edit-icon"></div>
      </div>
      <div id={"custom-edit-label"} className={classes.iconLabel}>
        Editar
      </div>
    </button>
  );
};

export default CustomEditIcon;
