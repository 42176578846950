import { Grid, Divider, makeStyles, Typography } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

interface Props {
  label?: string;
  subCommand?: boolean;
  component: any;
  isOptional?: boolean;
  getOperator?: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    itemList: (props: any) => ({
      minHeight: props.subCommand ? "80px" : "95px",
      padding: "20px",
    }),
    commandInteger: {
      display: "flex",
      justifyContent: "center",
      alignItems: "start",
      flexDirection: "column",
    },
  })
);

const CommandRow: React.FunctionComponent<Props> = (props: Props) => {
  const classes = useStyles(props);

  return (
    <>
      {props.subCommand ? <Divider light /> : <Divider />}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.itemList}
      >
        <div className={classes.commandInteger}>
          {props.label && (
            <Grid item>
              <Typography
                variant={props.subCommand ? "subtitle2" : "subtitle1"}
              >
                {props.label}
              </Typography>
            </Grid>
          )}
        </div>
        <Grid item>{props.component}</Grid>
      </Grid>
    </>
  );
};

export default CommandRow;
