import { makeStyles, createStyles } from "@material-ui/core";
import { useContext } from "react";
import DeviceModel from "../../../../../../../Models/DeviceModel";
import NotificationConfigContext from "../../../../../Context/NotificationConfigContext";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { CustomIconButton } from "../../../../../../../Components/CustomButton/Icon";
import { NotDisturbSchedulesModel } from "../../../../../../../Models/NotificationConfigModel";
import GlobalContex from "../../../../../../../GlobalContext/GlobalContext";
import PlaceModel from "../../../../../../../Models/PlaceModel";
import RoomModel from "../../../../../../../Models/RoomModel";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      padding: "20px 0px 0px 0px",
    },
    heading: {
      fontSize: "15px",
      fontWeight: 600,
      color: "#3E5055",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "10px",
    },
    contiainer: {
      width: "500px",
      height: "52px",
      display: "flex",
      flexDirection: "row",
      backgroundColor: "#FFF",
      alignItems: "center",
      justifyContent: "space-between",
      border: "1px solid #D1DAE0",
      borderRadius: "5px",
    },
    img: {
      margin: "10px",
    },
    totalDevices: {
      color: "#BDBDBD",
      marginRight: "30px",
    },
    normalButton: {
      backgroundColor: "#EBEEEE",
      width: "40px",
      height: "40px",
      marginRight: "13px",
      fontSize: "18px",
    },
    selectedButton: {
      backgroundColor: "#00A335",
      width: "40px",
      height: "40px",
      marginRight: "13px",
      fontSize: "18px",
      color: "#FFFFFF",
    },
    details: {
      display: "flex",
      flexDirection: "column",
      padding: "34px",
    },
    summary: {
      border: "1px solid #D1DAE0",
    },
    selectOptionsDate: {
      display: "flex",
      flexDirection: "row",
    },
    title: {
      color: "#3E5055",
      marginTop: "50px",
      marginBottom: "20px",
      fontWeight: 600,
      fontSize: "15px",
    },
    subtitle: {
      fontWeight: 600,
      color: "#8B979F",
      fontSize: "13px",
    },
    deviceContainer: {
      display: "flex",
      padding: "0px 40px",
      alignItems: "center",
      justifyContent: "space-between",
    },
  })
);

interface Props {
  notification: NotDisturbSchedulesModel;
}

const SelectedDevicesToConfig: React.FunctionComponent<Props> = ({
  notification,
}: Props) => {
  const classes = useStyles();

  const { deleteConfigNotification } = useContext(NotificationConfigContext);
  const { places } = useContext(GlobalContex);

  const devices: DeviceModel[] = places
    .flatMap((place: PlaceModel) => place.rooms)
    .flatMap((room: RoomModel) => room.devices)
    .filter((device: DeviceModel) =>
      notification.device_ids?.includes(device.id)
    );

  const week = (day: number): string => {
    if (day === 0) {
      return "Dom";
    } else if (day === 1) {
      return "Seg";
    } else if (day === 2) {
      return "Ter";
    } else if (day === 3) {
      return "Qua";
    } else if (day === 4) {
      return "Qui";
    } else if (day === 5) {
      return "Sex";
    }
    return "Sab";
  };

  const getDaysOfWeekSeleted = (daysSelected: string): string => {
    let days = "";
    const daysSplit = daysSelected.split("");

    daysSplit.forEach((day: string, index: number) => {
      if (day !== "0") {
        days += week(index) + ", ";
      }
    });

    return days;
  };

  return (
    <div>
      <div className={classes.row}>
        <div className={classes.deviceContainer}>
          <div className={classes.contiainer}>
            {notification.device_ids.length !== 0 && (
              <div>
                {devices.map((device: DeviceModel, index: number) => {
                  if (index < 7)
                    return (
                      <img
                        width={30}
                        src={device.icon}
                        className={classes.img}
                      />
                    );
                })}
              </div>
            )}
            {notification.device_ids.length === 0 && <div></div>}
            {notification.device_ids.length === 0 && (
              <div className={classes.totalDevices}>
                Todos os dispositivos selecionados{" "}
                {/* {getDaysOfWeekSeleted(notification.loops)} */}
              </div>
            )}
            {notification.device_ids.length !== 0 && (
              <>
                <div className={classes.totalDevices}>
                  {notification.device_ids.length} dispositivos{" "}
                  {getDaysOfWeekSeleted(notification.loops)}
                </div>
              </>
            )}
          </div>
          <CustomIconButton
            id="delete-notification-config"
            disabled={false}
            width="35px"
            height="35px"
            onClick={(event: any) => {
              event.stopPropagation();
              deleteConfigNotification(notification.id || 0);
            }}
            icon={<DeleteOutlineIcon htmlColor="#EF847E" />}
            flexDirection="row"
            textFontSize="12px"
          >
            {"Remover"}
          </CustomIconButton>
        </div>
      </div>
    </div>
  );
};

export default SelectedDevicesToConfig;
