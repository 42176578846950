import { ReactNode, useCallback } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { blue } from "@material-ui/core/colors";
import { Button, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  planPriceInterval,
  Plans,
  Price,
} from "../../../../../../Models/Plans";
import { toREAL } from "../../../../../../Utils/CurrencyUtils";
import ItemIcon from "../../../ItemIcon";

export interface ChooseTypePaymentDialogProps {
  open: boolean;
  plan: Plans;
  userCurrentPriceId?: string;
  description?: string;
  children?: ReactNode;
  onClose: (value?: Price) => void;
}

const CloseButton = withStyles(() => ({
  root: {
    textTransform: "initial",
    color: "#8B979F",
  },
}))(Button);

const OutlinedButton = withStyles(() => ({
  root: {
    textTransform: "initial",
    border: "1px solid #BDBDBD",
    fontWeight: 600,
    fontSize: "14px",
    color: "#8B979F",

    ".Mui-disabled": {
      cursor: "not-allowed",
    },
  },
}))(Button);

const ContainedButton = withStyles(() => ({
  root: {
    textTransform: "initial",
    fontWeight: 600,
    fontSize: "14px",
    color: "#FFF",
    boxShadow: "none",
  },
}))(Button);

const useStyles = makeStyles({
  dialog: {
    "& .MuiDialog-paper": {
      padding: "20px",
      maxWidth: "650px",
      width: "600px",
    },
    "& .MuiDialogTitle-root": {
      paddingBottom: "5px",
      "& .MuiTypography-root": {
        fontWeight: "600",
      },
    },
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  buttonClose: {
    position: "absolute",
    right: 10,
    top: 5,
  },
  planBox: {
    border: "1px solid #D1DAE0",
    borderRadius: "5px",
    "& .planBox__header": {
      display: "flex",
      alignItems: "center",
      fontWeight: "600",
      padding: "12px",
      fontSize: "15px",

      "& .ItemIcon--root": {
        marginRight: "8px",
      },
    },
    "& .planBox__content": {
      display: "flex",
      background: "#F5F5F5",
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: "18px",
      color: "#8B979F",
      padding: "12px",

      "& table": {
        width: "100%",
      },
    },
  },
  btnGroup: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "12px",

    "& button + button": {
      marginLeft: "12px",
    },
  },
});

const ChooseTypePaymentDialog: React.FunctionComponent<
  ChooseTypePaymentDialogProps
> = (props: ChooseTypePaymentDialogProps) => {
  const classes = useStyles();
  const { onClose, open, plan, userCurrentPriceId } = props;

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (interval: planPriceInterval) => {
    const price = plan?.prices?.find((p) => p.interval == interval);
    onClose(price);
  };

  const formatPrice = useCallback(
    (interval: "month" | "year" | "day") => {
      if (!plan || !plan.prices) return;
      const translateInterval = { month: "mês", year: "ano", day: "dia" };
      const price = plan?.prices?.find((p) => p.interval === interval);
      const priceFormated = toREAL(price?.price, {
        fromCents: true,
        precision: 0,
      });
      return `${priceFormated}/${translateInterval[interval]}`;
    },
    [plan]
  );

  const isCurrentPricePlan = useCallback(
    (interval: "month" | "year" | "day") => {
      if (!plan || !plan.prices || !userCurrentPriceId) return false;
      return plan?.prices?.some(
        (price) =>
          price.interval === interval && price.id === userCurrentPriceId
      );
    },
    [plan, userCurrentPriceId]
  );

  return (
    <Dialog
      className={classes.dialog}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      open={open}
    >
      <CloseButton
        className={classes.buttonClose}
        onClick={handleClose}
        startIcon={<CloseIcon />}
      >
        Fechar
      </CloseButton>

      <DialogTitle id="dialog-title">Plano selecionado</DialogTitle>
      <DialogContent>
        {props.description && (
          <p style={{ fontSize: "14px" }}>{props.description}</p>
        )}
        <div className={classes.planBox}>
          <div className={"planBox__header"}>
            <ItemIcon size={44} color={plan.color_plan} />
            <span className={"planBox__header__title"}>Plano {plan.name}</span>
          </div>
          <div className={"planBox__content"}>
            <div style={{ width: "54px" }}></div>
            <table>
              <tbody>
                <tr>
                  <td>Quantidade de usuários</td>
                  <td>
                    <strong>{plan.max_user}</strong>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Quantidade de dispositivo</td>
                  <td>
                    <strong>{plan.max_device}</strong>
                  </td>
                  <td style={{ textAlign: "right" }}>Valor do plano</td>
                  <td style={{ textAlign: "right" }}>
                    <strong>{formatPrice("month")}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Quantidade de automações</td>
                  <td>
                    <strong>{plan.max_automation}</strong>
                  </td>
                  <td></td>
                  <td style={{ textAlign: "right" }}>
                    <strong>{formatPrice("year")}</strong>
                  </td>
                </tr>
                {plan?.prices?.length === 1 && (
                  <tr style={{ textAlign: "right" }}>
                    <td colSpan={4}>
                      <strong>{formatPrice("day")}</strong>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {props.children}

        {!plan.trial && (
          <div
            className={classes.btnGroup}
            style={{
              justifyContent:
                plan.prices?.length === 1 ? "end" : "space-between",
            }}
          >
            {plan?.prices?.some((p) => p.interval === "month") && (
              <OutlinedButton
                variant="outlined"
                disabled={isCurrentPricePlan("month")}
                style={
                  isCurrentPricePlan("month")
                    ? {}
                    : { borderColor: plan.color_plan, color: plan.color_plan }
                }
                onClick={() => handleListItemClick("month")}
              >
                Assinar {formatPrice("month")}
              </OutlinedButton>
            )}
            <CloseButton onClick={handleClose}>Cancelar</CloseButton>
            {plan?.prices?.some((p) => p.interval === "year") && (
              <ContainedButton
                variant="contained"
                style={
                  isCurrentPricePlan("year")
                    ? {}
                    : { backgroundColor: plan.color_plan }
                }
                disabled={isCurrentPricePlan("year")}
                onClick={() => handleListItemClick("year")}
              >
                Assinar {formatPrice("year")}
              </ContainedButton>
            )}
            {plan?.prices?.some((p) => p.interval === "day") && (
              <OutlinedButton
                variant="outlined"
                disabled={isCurrentPricePlan("day")}
                style={
                  isCurrentPricePlan("day")
                    ? {}
                    : { borderColor: plan.color_plan, color: plan.color_plan }
                }
                onClick={() => handleListItemClick("day")}
              >
                Assinar {formatPrice("day")}
              </OutlinedButton>
            )}
          </div>
        )}
        {plan.trial && (
          <div className={classes.btnGroup} style={{ justifyContent: "end" }}>
            <CloseButton onClick={handleClose}>Cancelar</CloseButton>
            <OutlinedButton
              variant="outlined"
              disabled={isCurrentPricePlan("day")}
              style={
                isCurrentPricePlan("day")
                  ? {}
                  : { borderColor: plan.color_plan, color: plan.color_plan }
              }
              onClick={() => handleListItemClick("day")}
            >
              Confirmar
            </OutlinedButton>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ChooseTypePaymentDialog;
