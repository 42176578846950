import { ReactNode, useEffect, useState } from "react";
import { getCurrentUser } from "../../Services/AuthService";
import { hasRole } from "../../Services/UserHomeInfoLocalService";

interface Props {
  children?: ReactNode;
  alternative?: ReactNode;
  permitedRoles: string[];
}

const WithRoleAuthorization: React.FunctionComponent<Props> = (
  props: Props
) => {
  const user = getCurrentUser();
  const [permited, setPermited] = useState<boolean>(false);

  useEffect(() => {
    setPermited(hasRole(props.permitedRoles));
  }, [user, props.permitedRoles]);

  return permited ? (
    <>{props.children && props.children}</>
  ) : (
    <>{props.alternative || <></>}</>
  );
};

export default WithRoleAuthorization;
