type EmptyStateProps = {
  msg: string;
};
const EmptyState: React.FunctionComponent<EmptyStateProps> = ({
  msg,
}: EmptyStateProps) => {
  return (
    <div
      style={{
        padding: "36px 10px",
        textAlign: "center",
        width: "100%",
        color: "#8B979F",
      }}
    >
      <em>{msg}</em>
    </div>
  );
};

export default EmptyState;
