import { ReactNode, useContext, useEffect, useState } from "react";
import GlobalContex from "../../../GlobalContext/GlobalContext";
import UserGroupsModel, {
  UserGroupOptionsModel,
} from "../../../Models/UserGroupsModel";
import Order from "../../../Types/Order";
import {
  getAllUsersGroup,
  removeUserGroups,
} from "../../../Services/UserGroupsService";
import OperatorsGroupPageContext from "./OperatorsGroupPageContext";
import { AxiosError } from "axios";

interface Props {
  children: ReactNode;
}

const OperatorsGroupPageProvider: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { children } = props;
  const { showAlert } = useContext(GlobalContex);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [operatorsGroupId, setOperatorsGroupId] = useState<string>("");
  const [operatorsGroupList, setOperatorsGroupList] = useState<
    UserGroupsModel[]
  >([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [totalUserGroups, setTotalUserGroups] = useState<number>(0);
  const [order, setOrder] = useState<Order>("asc");
  const [currentFilterValue, setCurrentFilterValue] = useState<string>("");
  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [isLoadingUserGroups, setIsLoadingUserGroups] = useState<boolean>(true);

  useEffect(() => {
    return () => {
      setOpenDialog(false);
      setOperatorsGroupList([]);
      setPage(0);
      setTotalUserGroups(0);
      setIsFiltering(false);
      setIsLoadingUserGroups(true);
    };
  }, []);

  const getFilteredUserGroups = async (value: string) => {
    setIsFiltering(true);
    try {
      const options: UserGroupOptionsModel = {
        filter: value,
        sortBy: "name",
        sortDir: order.toUpperCase(),
      };
      const response = await getAllUsersGroup(0, rowsPerPage, options);
      if (response.status === 200) {
        setOperatorsGroupList(response.data.content);
        setTotalUserGroups(response.data.totalElements);
        setPage(response.data.pageNumber);
      } else {
        showAlert(
          "Houve um problema ao carregar os grupos de operador!",
          "warning"
        );
        setIsFiltering(false);
      }
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      showAlert(err.message, "error");
      setIsFiltering(false);
    } finally {
      if (page === 0) {
        setIsFiltering(false);
      }
      setIsLoadingUserGroups(false);
    }
  };

  const getUserGroupsByFilterValue = (value: string) => {
    setCurrentFilterValue(value);
    getFilteredUserGroups(value);
  };

  const getAllUserGroups = async (filter?: string) => {
    setIsLoadingUserGroups(true);
    try {
      const options: UserGroupOptionsModel = {
        filter: filter,
        sortBy: "name",
        sortDir: order.toUpperCase(),
      };
      const response = await getAllUsersGroup(page, rowsPerPage, options);
      if (response.status === 200) {
        setOperatorsGroupList(response.data.content);
        setTotalUserGroups(response.data.totalElements);
        setPage(response.data.pageNumber);
      } else {
        showAlert(
          "Houve um problema ao carregar os grupos de operador!",
          "warning"
        );
      }
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      showAlert(err.message, "error");
    } finally {
      setIsLoadingUserGroups(false);
    }
  };

  useEffect(() => {
    if (isFiltering) {
      setIsFiltering(false);
    } else {
      getAllUserGroups(currentFilterValue);
    }
  }, [page, rowsPerPage, order]);

  function onChangeRowsPerPage(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    setRowsPerPage(Number(event.target.value));
  }

  function onChangePage() {
    return;
  }

  const handleCloseModalDelete = () => {
    setOpenDialog(false);
  };

  const handleOpenModalDelete = (id: string) => {
    setOpenDialog(true);
    setOperatorsGroupId(id);
  };

  const operatorsGroupDelete = (name: string) => {
    removeUserGroups(operatorsGroupId)
      .then(() => {
        if (operatorsGroupList.length === 1 && page > 0) {
          setPage(page - 1);
        } else {
          getAllUserGroups(currentFilterValue);
        }
        showAlert(
          "Grupo de operadores " + name + " removido com sucesso !",
          "success"
        );
      })
      .catch((err) => {
        showAlert(err.message, "error");
      })
      .finally(() => {
        setOpenDialog(false);
      });
  };

  return (
    <OperatorsGroupPageContext.Provider
      value={{
        rowsPerPage,
        page,
        setPage,
        setOrder,
        setRowsPerPage,
        openDialog,
        isLoadingUserGroups,
        setIsLoadingUserGroups,
        operatorsGroupList,
        totalUserGroups,
        order,
        handleCloseModalDelete,
        onChangeRowsPerPage,
        onChangePage,
        handleOpenModalDelete,
        operatorsGroupDelete,
        getUserGroupsByFilterValue,
      }}
    >
      <div
        style={{
          backgroundColor: "#FFF",
          width: "100%",
        }}
      >
        {children}
      </div>
    </OperatorsGroupPageContext.Provider>
  );
};

export default OperatorsGroupPageProvider;
