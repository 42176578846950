import styled from "styled-components";

export const CodefieldContainerStyled = styled.div`
  width: 100%;
`;

export const CodefieldLabel = styled.div`
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #3e5055;
`;

export const InputsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
`;

export const InputCode = styled.input`
  width: 45px;
  height: 45px;
  margin: 3px;
  text-align: center;
  border-radius: 10px;
  background-color: #ebeeee;
  border: 1px solid #ebeeee;
  &:focus {
    outline: none;
  }
`;

export const RandomizerButton = styled.strong`
  margin-left: 20px;
  color: #00a335;
  font-size: 15px;
  line-height: 20px;
  cursor: pointer;
`;
