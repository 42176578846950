import Icon from "@material-ui/core/Icon";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import { useContext } from "react";
import history from "../../Services/history";
import GlobalContex from "../../GlobalContext/GlobalContext";

const useStyles = makeStyles(() => {
  const backgroundColor = "rgba(0, 122, 57, 0.5)";
  return {
    root: {
      transition: "0.2s ease",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      color: "white",
      fontSize: "15px",
      fontWeight: 600,
      padding: "8px 16px",
      "&:hover": {
        background: "rgba(0, 122, 57, 0.5)",
        cursor: "pointer",
      },

      "&.Mui-selected": {
        backgroundColor,
        "&:hover": {
          backgroundColor,
        },
      },

      "& .MuiIcon-root": {
        width: "35px",
        height: "35px",
        "& img": {
          display: "flex",
          height: "inherit",
          width: "inherit",
          marginRight: "10px",
          backgroundColor: "#36B35F",
          padding: "6px",
          borderRadius: "5px",
        },
      },

      "& .title": {
        marginLeft: "10px",
        fontSize: "15px",
        fontWeight: 600,
      },
    },
  };
});

type MenuItemProps = {
  link: string;
  icon: string;
  text: string;
};

const MenuItem: React.FunctionComponent<MenuItemProps> = (
  props: MenuItemProps
) => {
  const classes = useStyles();

  const { pathnameUrl } = useContext(GlobalContex);

  const isActived = () => pathnameUrl === props.link;

  return (
    <ListItem
      button
      selected={isActived()}
      classes={classes}
      onClick={() => {
        history.push(props.link);
      }}
    >
      <Icon>
        <img src={props.icon} alt="" />
      </Icon>
      <span className={"title"}>{props.text}</span>
    </ListItem>
  );
};

export default MenuItem;
