import { Switch, TextField } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import ButtonOutlined from "../../../../../CustomButton/ButtonOutlined";
import InputOutlinedForm from "../../../../../CustomInput/InputOutlinedForm";
import AccordionPasswordDefinition from "../AccordionPasswordDefinition";
import DaysOfWeekPicker from "../../../../../DaysOfWeekPicker";
import Codefield from "../../../../../Codefield";
import { Container, ContentRow } from "./style";
import { saveDoorLockPasswordSchedule } from "../../../../../../Services/DeviceDoorLockService";
import GlobalContext from "../../../../../../GlobalContext/GlobalContext";
import { ErrorMessage } from "../../../../../../Utils/ErrorMessage";
import { DoorLockPasswordModel } from "../../../../../../Models/DoorLockPasswordModel";
import CustomDatePicker from "../../../../../CustomDatePicker";
import { isSameDay } from "date-fns";
import { removeTimeFromDate } from "../../../../../../Utils/DateUtils";
import { parseDateSeconds } from "../../../../../../Utils/Functions";

type AccordionPasswordScheduleProps = {
  deviceId: string;
  expanded: boolean;
  onChange: () => void;
};

const PasswordScheduleBox: React.FunctionComponent<
  AccordionPasswordScheduleProps
> = ({ deviceId, ...props }: AccordionPasswordScheduleProps) => {
  const PASSWORD_SIZE = 6;
  const initial_date = removeTimeFromDate(new Date());

  const { showAlert } = useContext(GlobalContext);

  const [passwordName, setPasswordname] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [initialDate, setInitialDate] = useState<Date>(initial_date);
  const [initialTime, setInitialTime] = useState<number>(0);
  const [endDate, setEndDate] = useState<Date>(initial_date);
  const [endTime, setEndTime] = useState<number>(parseDateSeconds("23:59"));
  const [endTimeError, setEndTimeError] = useState<boolean>(false);
  const [switchValue, setSwitchValue] = useState<boolean>(false);
  const [daysOfWeek, setDaysOfWeek] = useState<number>(0);
  const [initialRepetition, setInitialRepetition] = useState<number>(0);
  const [endRepetition, setEndRepetition] = useState<number>(
    parseDateSeconds("23:59")
  );
  const [endTimeRepetitionError, setEndTimeRepetitionError] =
    useState<boolean>(false);

  const saveAndCopyPassword = async () => {
    if (passwordName.trim() === "") {
      showAlert(
        "O nome da senha não pode ser vazio ou ter apenas espaço em branco!",
        "warning"
      );
      return;
    }

    if (password.length < PASSWORD_SIZE) {
      showAlert("A senha deve ter " + PASSWORD_SIZE + " dígitos!", "warning");
      return;
    }

    if (endTimeError) {
      showAlert(
        "O horário da data final não pode ser menor que o horário da data de início",
        "warning"
      );
      return;
    }

    if (endTimeRepetitionError) {
      showAlert(
        "O horário final da repetição não pode ser menor que o horário início da repetição",
        "warning"
      );
      return;
    }

    let objToSend: DoorLockPasswordModel = {
      name: passwordName,
      password: password,
      effective_time: new Date(initialDate).getTime() / 1000 + initialTime,
      invalid_time: new Date(endDate).getTime() / 1000 + endTime,
    };

    if (switchValue) {
      objToSend = {
        ...objToSend,
        schedule_list: [
          {
            working_day: daysOfWeek,
            effective_time: initialRepetition,
            invalid_time: endRepetition,
          },
        ],
      };
    }

    await saveDoorLockPasswordSchedule(deviceId, objToSend)
      .then(() => {
        showAlert("Senha salva com sucesso!", "success");
      })
      .catch((e) => {
        const msg = ErrorMessage(e, "Não foi possível gerar a senha.");
        showAlert(msg, "error");
      })
      .finally(() => {
        navigator.clipboard.writeText(password);
      });
  };

  const convertTimeToSeconds = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): number => {
    const split = e.target.value.split(":");
    return parseInt(split[0]) * 3600 + parseInt(split[1]) * 60;
  };

  const converToMinutes = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): number => {
    const split = e.target.value.split(":");
    return parseInt(split[0]) * 60 + parseInt(split[1]);
  };

  useEffect(() => {
    if (!isSameDay(initialDate, endDate)) {
      setEndTimeError(false);
      return;
    }
    setEndTimeError(initialTime >= endTime);
  }, [initialDate, endDate, initialTime, endTime]);

  useEffect(() => {
    setEndTimeRepetitionError(initialRepetition >= endRepetition);
  }, [initialRepetition, endRepetition]);

  return (
    <AccordionPasswordDefinition
      title={"Agendamento de senhas"}
      iconElement={
        <img
          src={"/assets/icons/icon-password-schedule.svg"}
          alt={"Ícone de agendamento de senha"}
        />
      }
      {...props}
    >
      <Container>
        <ContentRow marginBottom="25px">
          <InputOutlinedForm
            id={"schedule-password-name"}
            name={"name"}
            label={"Nome da senha"}
            value={passwordName}
            onChange={(e) => {
              setPasswordname(e.target.value);
            }}
          />
        </ContentRow>
        <ContentRow marginBottom="25px">
          <Codefield
            codeSize={PASSWORD_SIZE}
            callbackOnChange={(value) => {
              setPassword(value);
            }}
          />
        </ContentRow>
        <ContentRow marginBottom="25px">
          Você não poderá mais visualizar esta senha após salvar.
        </ContentRow>
        <ContentRow
          display={"flex"}
          justifyContent={"space-between"}
          marginBottom={"25px"}
        >
          <div style={{ width: "100%" }}>
            <CustomDatePicker
              label={"Data de início"}
              selected={initialDate}
              selectsStart
              onChange={(date) => {
                if (!date) {
                  return;
                }
                const new_date = removeTimeFromDate(date);
                if (date > endDate) {
                  setEndDate(new_date);
                }
                setInitialDate(new_date);
              }}
            />
          </div>
          <div>
            <div>Início</div>
            <TextField
              style={{ width: "90px" }}
              type="time"
              defaultValue={"00:00"}
              onChange={(e) => {
                const new_seconds = convertTimeToSeconds(e);
                setInitialTime(new_seconds);
              }}
            />
          </div>
        </ContentRow>
        <ContentRow
          display={"flex"}
          justifyContent={"space-between"}
          marginBottom={"25px"}
        >
          <div style={{ width: "100%" }}>
            <CustomDatePicker
              label={"Data final"}
              selected={endDate}
              selectsEnd
              minDate={initialDate}
              onChange={(date) => {
                if (!date) {
                  return;
                }
                const new_date = removeTimeFromDate(date);
                setEndDate(new_date);
              }}
            />
          </div>
          <div>
            <div>Fim</div>
            <TextField
              style={{ width: "90px" }}
              error={endTimeError}
              helperText={endTimeError ? "Hora inválida" : ""}
              type="time"
              defaultValue={"23:59"}
              onChange={(e) => {
                const new_seconds = convertTimeToSeconds(e);
                setEndTime(new_seconds);
              }}
            />
          </div>
        </ContentRow>
        <ContentRow marginBottom="25px">
          <Switch
            name="checkedA"
            inputProps={{ "aria-label": "secondary checkbox" }}
            onChange={(e, checked) => {
              setSwitchValue(checked);
            }}
          />
          Personalizar agendamento
        </ContentRow>
        {switchValue && (
          <ContentRow marginBottom={"35px"}>
            <ContentRow marginBottom={"25px"}>
              <div>Repetição</div>
              <DaysOfWeekPicker
                value={daysOfWeek}
                onChange={(value) => {
                  setDaysOfWeek(value as number);
                }}
              />
            </ContentRow>
            <ContentRow display={"flex"} justifyContent={"space-between"}>
              <div>
                <div>Horário início repetição</div>
                <TextField
                  type="time"
                  defaultValue={"00:00"}
                  onChange={(e) => {
                    const new_minutes = converToMinutes(e);
                    setInitialRepetition(new_minutes);
                  }}
                />
              </div>
              <div>
                <div>Horário final repetição</div>
                <TextField
                  type="time"
                  defaultValue={"23:59"}
                  error={endTimeRepetitionError}
                  helperText={endTimeRepetitionError ? "Hora inválida" : ""}
                  onChange={(e) => {
                    const new_minutes = converToMinutes(e);
                    setEndRepetition(new_minutes);
                  }}
                />
              </div>
            </ContentRow>
          </ContentRow>
        )}
        <ButtonOutlined
          color={"secondary"}
          startIcon={<img src={"/assets/icons/icon-copy.svg"} />}
          onClick={saveAndCopyPassword}
        >
          Copiar e Salvar
        </ButtonOutlined>
      </Container>
    </AccordionPasswordDefinition>
  );
};

export default PasswordScheduleBox;
