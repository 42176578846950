import React from "react";
import {
  Typography,
  IconButton,
  Dialog,
  Button,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import { useStyles } from "./CustomConfirmDialogStyle";
import CustomConfirmDialogProps from "./CustomConfirmDialogProps";
import styles from "./styles/CustomConfirmDialog.module.css";

const CustomConfirmDialog: React.FunctionComponent<
  CustomConfirmDialogProps
> = ({
  onClose,
  openDialog,
  onCloseFocus,
  onConfirmButton,
  onCancelButton,
  text,
  canUndo = false,
  roomName,
  isLoading = false,
  questionMark = true,
}) => {
  const classes = useStyles();

  function handleClose(
    event: React.ReactEventHandler<Record<string, unknown>>,
    reason: "backdropClick" | "escapeKeyDown"
  ) {
    if (isLoading) {
      return;
    }

    onCloseFocus(event, reason);
  }

  return (
    <Dialog
      id="dialog-confirm-action"
      onClose={handleClose}
      open={openDialog}
      className={styles.dialog}
    >
      <div className={styles.head}>
        <IconButton
          title={"Fechar"}
          id="close-confirm-dialog"
          onClick={onClose}
          className={styles.iconButton}
          disabled={isLoading}
        >
          <CloseIcon className={styles.closeIcon} />
        </IconButton>
      </div>
      <div className={classes.body}>
        <div className={classes.center}>
          <div className={styles.circle} style={{ borderRadius: "50%" }}>
            <NotificationImportantIcon />
          </div>
          <div className={classes.labelWarning}>Atenção</div>
        </div>
        <div className={classes.bodyText}>
          <Typography className={classes.typoAlert}>
            {text || "Confirmar"}
            <strong>
              <span style={{ color: "green" }}>{roomName}</span>
            </strong>{" "}
            {questionMark ? "?" : ""}
            {!canUndo && (
              <strong>{" Esta ação não poderá ser desfeita."}</strong>
            )}
          </Typography>
        </div>
        <div className={classes.footer}>
          <div className={classes.confirmWrapper}>
            {isLoading && <CircularProgress size={24} color="primary" />}
            <Button
              color="secondary"
              variant="contained"
              id="confirm-dialog-button"
              onClick={onConfirmButton}
              className={classes.confirmButton}
              disabled={isLoading}
            >
              {canUndo ? "Sim" : "Confirmar"}
            </Button>
          </div>
          <button
            type="button"
            className={styles.cancelButton}
            id="cancel-dialog-button"
            onClick={onCancelButton}
            disabled={isLoading}
          >
            {canUndo ? "Não" : "Cancelar"}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default CustomConfirmDialog;
