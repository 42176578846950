import { makeStyles } from "@material-ui/core";
import { useContext, useState } from "react";
import GlobalContext from "../../../../../GlobalContext/GlobalContext";
import DeviceModel from "../../../../../Models/DeviceModel";
import CustomDeviceBatteryIcon from "../../../../CustomDeviceBatteryIcon";
import DragSwitchDoor from "../../../../DragSwitchDoor";
import ListEnvironmentsFromDevices from "../../../../ListEnvironmentsFromDevices";
import DeviceAlarmsHistory from "../../../DeviceAlarmsHistory";
import DeviceLastAlarmDisplay from "../Components/DeviceLastAlarmDisplay";

interface Props {
  device: DeviceModel;
}

const Device_IFR_1001_2020_3000_7000_7001: React.FunctionComponent<Props> = ({
  device,
}: Props) => {
  const classes = useStyles();

  const {
    setshowAlarmsHistory,
    handleChangeCommand,
    goForwardDrawerApplication,
  } = useContext(GlobalContext);

  const [isProcessing, setisProcessing] = useState(false);

  const battery = device.status.find(
    (s) => s.code === "residual_electricity"
  ) || { code: "", value: 0, t: 0 };

  const switchStatus = device.status.find((s) => s.code === "open_close") || {
    code: "",
    value: 0,
    t: 0,
  };
  const toggleStatus = () => {
    handleChangeCommand(!!switchStatus.value, device.id, "open", () => {
      setisProcessing(false);
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.title}>{device.name}</div>
      {device.environments && device.environments.length > 0 && (
        <div className={classes.subtitle}>
          <ListEnvironmentsFromDevices environments={device.environments} />
        </div>
      )}
      <div className={classes.batteryContainer}>
        <CustomDeviceBatteryIcon value={battery.value as number} />
      </div>
      <div className={classes.switchContainer}>
        <DragSwitchDoor
          checked={!!switchStatus.value}
          online={device.online}
          disabled={false}
          switchOnClick={false}
          loading={isProcessing}
          labelLocking={"Travando..."}
          labelUnlocking={"Destravando..."}
          labelOpen={"Clique e arraste para destravar"}
          labelClosed={"Clique e arraste para travar"}
          labelOffline={"Dispositivo offline"}
          onChange={() => {
            setisProcessing(true);
            toggleStatus();
          }}
        />
      </div>
      <div className={classes.alarmContainer}>
        <DeviceLastAlarmDisplay
          device={device}
          onButtonClick={() => {
            goForwardDrawerApplication(<DeviceAlarmsHistory device={device} />);
            setshowAlarmsHistory(true);
          }}
        />
      </div>
    </div>
  );
};

export default Device_IFR_1001_2020_3000_7000_7001;

const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    padding: "20px",
  },
  title: {
    fontSize: "23px",
    lineHeight: "31px",
    color: "#3E5055",
    fontWeight: 600,
    textAlign: "center",
    padding: "15px 0px",
  },
  subtitle: {
    paddingBottom: "30px",
    display: "flex",
    justifyContent: "center",
  },
  batteryContainer: {
    padding: "50px 0px",
  },
  switchContainer: {
    minHeight: "200px",
    padding: "20px 0px",
    display: "flex",
    justifyContent: "center",
  },
  alarmContainer: {
    marginTop: "50px",
    display: "flex",
    justifyContent: "center",
  },
}));
