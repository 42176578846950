import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  Divider,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CustomItemDevice from "../../../../../Components/CustomItemDevice";
import DeviceModel from "../../../../../Models/DeviceModel";
import React, { useContext, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import clsx from "clsx";
import { favoriteDevice } from "../../../../../Services/RoomService";
import GlobalContex from "../../../../../GlobalContext/GlobalContext";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Typography from "@material-ui/core/Typography";
import EnvironmentModel from "../../../../../Models/EnvironmentModel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HomePageContext from "../../../Context/HomePageContext";

type Anchor = "top" | "left" | "bottom" | "right";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addShortcutDevice: {
      backgroundColor: "#EBEEEE",
      display: "flex",
      padding: "19px",
      margin: "5px",
      height: "190px",
      width: "190px",
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",
      justifyContent: "center",
    },
    shortcuts: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: "20px",
      maxWidth: "100%",
      overflow: "auto",
    },
    list: {
      width: 450,
      backgroundColor: "#FFF",
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
    fullList: {
      width: "auto",
    },
    row: {
      display: "flex",
      padding: "25px",
      justifyContent: "start",
      alignItems: "center",
      maxHeight: "90px",
    },
    margin: {
      margin: theme.spacing(1),
    },
  })
);

interface Props {
  devices: DeviceModel[];
  devicesFavorited: DeviceModel[];
}

const DevicesFavorited: React.FunctionComponent<Props> = ({
  devicesFavorited,
  devices,
}: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  const {
    environments,
    dashboardConfigurationDevices,
    setDashboardConfigurationDevices,
  } = useContext(HomePageContext);

  const { showAlert, room } = useContext(GlobalContex);

  const [expanded, setExpanded] = React.useState<string | false>("panel0");

  const handleChange =
    (panel: string, hasDevices: number | undefined) =>
    (event: React.ChangeEvent<unknown>, newExpanded: boolean) => {
      if (hasDevices) {
        setExpanded(newExpanded ? panel : false);
      }
    };

  const favorite = async (device: DeviceModel, isFavorited: boolean) => {
    try {
      const result = await favoriteDevice(isFavorited, device, room.room_id);
      if (result.data) {
        if (isFavorited) {
          setDashboardConfigurationDevices([
            ...dashboardConfigurationDevices,
            device,
          ]);
          showAlert("Dispositivo favoritado", "success");
        } else {
          setDashboardConfigurationDevices([
            ...dashboardConfigurationDevices.filter((d) => d.id !== device.id),
          ]);
          showAlert(
            "O dispositivo foi removido dos favoritos com sucesso",
            "success"
          );
        }
      } else {
        showAlert("Houve algum problema ao favoritar", "warning");
      }
    } catch (e: any) {
      showAlert(e.message, "error");
    }
  };

  const listOfDevicesToFAvorite = (devices: DeviceModel[]) => {
    return (
      <List
        style={{
          width: "100%",
        }}
      >
        {devices?.map((device, index) => (
          <ListItem
            button
            style={{
              width: "100%",
              backgroundColor: index % 2 === 0 ? "#F2F2F2" : "white",
            }}
            onClick={() => {
              if (
                !dashboardConfigurationDevices.some((d) => d.id === device.id)
              ) {
                favorite(device, true);
              }
            }}
            key={device.name}
          >
            <ListItemIcon>
              <img src={device.icon} width={40} />
            </ListItemIcon>
            <ListItemText primary={device.name} />
          </ListItem>
        ))}
      </List>
    );
  };

  const devicesFromEnvironment = (environment: EnvironmentModel) => {
    const devicesToRender: DeviceModel[] = [];

    devices?.map((device: any) => {
      const index = environment?.devices?.findIndex((dev: any) => {
        return dev.id === device.id;
      });
      if (index !== undefined && index > -1) {
        devicesToRender.push(device);
      }
    });

    return devicesToRender;
  };

  const allDevicesTab: EnvironmentModel[] = [
    {
      id: 0,
      name: "Todos os dispositivos",
      devices: devices,
    },
  ];

  const list = (anchor: Anchor) => {
    return (
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        })}
        onKeyDown={toggleDrawer(false)}
      >
        <div className={classes.row}>
          <IconButton
            aria-label="delete"
            className={classes.margin}
            size="small"
            onClick={toggleDrawer(false)}
          >
            <ArrowBackIosIcon fontSize="inherit" />
          </IconButton>
          Voltar
        </div>
        <Divider />

        {allDevicesTab
          .concat(environments)
          .map((environment: EnvironmentModel, i: number) => {
            return (
              <Accordion
                square
                expanded={expanded === `panel${i}`}
                onChange={handleChange(
                  `panel${i}`,
                  environment?.devices?.length
                )}
                key={i}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      style={{
                        padding: "20px 0px 0px 20px",
                        fontWeight: 600,
                        fontSize: "15px",
                        color: "#3E5055",
                      }}
                    >
                      {environment.name}
                    </Typography>
                    <Typography
                      style={{
                        paddingLeft: "25px",
                        fontWeight: 400,
                        fontSize: "13px",
                        color: "#8B979F",
                      }}
                    >
                      {environment.devices?.length} Dispositivos
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    width: "100%",
                    padding: "0px",
                  }}
                >
                  {environment?.devices && (
                    <>
                      {" "}
                      {listOfDevicesToFAvorite(
                        devicesFromEnvironment(environment)
                      )}{" "}
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })}
      </div>
    );
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpen(open);
    };

  return (
    <div>
      <div className={classes.shortcuts}>
        <div
          onClick={() => setOpen(true)}
          className={classes.addShortcutDevice}
        >
          <div
            style={{
              minWidth: "39px",
              minHeight: "39px",
              background: "#36B35F",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#FFF",
              marginBottom: "20px",
            }}
          >
            <AddIcon />
          </div>
          <p
            style={{
              color: "#6DCC9A",
              fontWeight: 600,
              textAlign: "center",
              marginBottom: "0px",
            }}
          >
            Adicionar atalho de dispositivo{" "}
          </p>
        </div>
        <div
          style={{
            width: "300px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          {devicesFavorited.map((deviceMap: DeviceModel, index: number) => {
            return devices.map((d: DeviceModel) => {
              if (deviceMap.id === d.id)
                return (
                  <CustomItemDevice
                    device={d}
                    isFavorited={true}
                    removeFavorite={() => favorite(d, false)}
                    key={"favorited-device-" + index}
                  />
                );
              return;
            });
          })}
        </div>
      </div>
      <div>
        <React.Fragment key={"right"}>
          <Drawer anchor={"right"} open={open} onClose={toggleDrawer(false)}>
            {list("right")}
          </Drawer>
        </React.Fragment>
      </div>
    </div>
  );
};

export default DevicesFavorited;
