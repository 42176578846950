import { UserLogin } from "../Models/UserLogin";

const getLocalRefreshToken = (): string => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  return user?.refreshToken;
};

const getLocalAccessToken = (): string => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  return user?.token;
};

const updateLocalAccessToken = (token: string): void => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  user.token = token;
  localStorage.setItem("user", JSON.stringify(user));
};

const getUser = (): UserLogin => {
  return JSON.parse(localStorage.getItem("user") || "{}");
};

const setUser = (user: UserLogin): void => {
  localStorage.setItem("user", JSON.stringify(user));
};

const removeUser = (): void => {
  localStorage.removeItem("user");
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  getUser,
  setUser,
  removeUser,
};

export default TokenService;
