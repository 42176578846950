import { styled } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const ButtonStyled = styled(Button)({
  textTransform: "none",
  fontWeight: 700,
  fontSize: "0.95rem",
  lineHeight: "1.75rem",
  padding: "8px 36px",
  height: "45px",

  "&.MuiButton-outlinedSecondary:hover": {
    backgroundColor: "transparent",
  },

  "&.MuiButton-sizeLarge": {
    padding: "12px 36px",
    lineHeight: "2rem",
    height: "55px",
  },

  "&.MuiButton-sizeSmall": {
    padding: "4px 24px",
    lineHeight: "1.5rem",
    height: "35px",
  },

  "&.MuiButton-text": {
    color: "#8B979F",
  },
});

export default ButtonStyled;
