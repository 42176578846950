import { useContext } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DeviceModel from "../../../../../Models/DeviceModel";
import GlobalContex from "../../../../../GlobalContext/GlobalContext";
import ListEnvironmentsFromDevices from "../../../../../Components/ListEnvironmentsFromDevices";
import { Divider } from "@material-ui/core";

interface ImgInterface {
  location: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    customTabPanel: {
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "space-between",
      height: "80vh",
      width: "100%",
    },
    imgSwitch: {
      width: "150px",
      height: "150px",
      cursor: "pointer",
      marginTop: "10px",
    },
  })
);

interface Props {
  device: DeviceModel;
}

const Device_EWS_201_E: React.FunctionComponent<Props> = (props: Props) => {
  const { handleChangeCommand } = useContext(GlobalContex);
  const classes = useStyles();

  const switchStatus1 = props.device.status.find(
    (s) => s.code === "switch_1"
  ) || { code: "", value: false, t: 0 };

  const toggleDeviceStatus = () => {
    handleChangeCommand(
      !switchStatus1.value,
      props.device.id,
      switchStatus1.code
    );
  };

  const imgsEws: ImgInterface[] = [
    { location: "assets/imgs/ewson.png" },
    { location: "assets/imgs/ewsoff.png" },
  ];

  return (
    <div className={classes.customTabPanel}>
      <div>
        <Typography variant="h6">{props.device.name}</Typography>
        {props.device.environments && (
          <ListEnvironmentsFromDevices
            environments={props.device.environments}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={toggleDeviceStatus}
      >
        <img
          src={switchStatus1.value ? imgsEws[0].location : imgsEws[1].location}
          className={classes.imgSwitch}
        />
        {switchStatus1.value && (
          <Typography style={{ fontWeight: "bold" }} variant="overline">
            Ligado
          </Typography>
        )}
        {!switchStatus1.value && (
          <Typography style={{ fontWeight: "bold" }} variant="overline">
            Desligado
          </Typography>
        )}
      </div>
      <Divider />
    </div>
  );
};

export default Device_EWS_201_E;
