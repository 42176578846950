import {
  CircularProgress,
  createStyles,
  Divider,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import EmptyState from "../../../../../Components/EmptyState";
import { Automations } from "../../../../../Models/Automation";
import HomePageContext from "../../../Context/HomePageContext";
import FavoritedAutomation from "./FavoritedAutomation";

const AutomationsFavorited: React.FunctionComponent = () => {
  const {
    toggleAutomationsFavoritedDrawer,
    dashboardConfiguration,
    scenes,
    isLoadingScenes,
    favoriteAutomationsIds,
    setFavoriteAutomationsIds,
  } = useContext(HomePageContext);

  const [favoritedScenes, setFavoritedScenes] = useState<Automations[]>([]);

  const classes = useStyles();

  useEffect(() => {
    const favorite_automations_id: string[] = [];
    dashboardConfiguration.favorite_automations.map((auto) => {
      if (auto.scene_id) {
        favorite_automations_id.push(auto.scene_id);
      }
    });
    setFavoriteAutomationsIds(favorite_automations_id);
  }, [dashboardConfiguration]);

  useEffect(() => {
    if (scenes.length > 0 && favoriteAutomationsIds.length > 0) {
      const favorited_scenes = scenes.filter((scene) => {
        if (scene.scene_id && favoriteAutomationsIds.includes(scene.scene_id)) {
          return scene;
        }
        return false;
      });
      setFavoritedScenes(favorited_scenes);
    }

    if (scenes.length > 0 && favoriteAutomationsIds.length === 0) {
      setFavoritedScenes([]);
    }
  }, [scenes, favoriteAutomationsIds]);

  return (
    <div style={{ height: "100%", maxHeight: "690px" }}>
      <div className={classes.containerAutomations}>
        <div className={classes.containerAutomationsTitle}>Automações</div>
        <Divider />
        <div className={classes.automationsList}>
          <div
            className={classes.btnAddAutomations}
            onClick={toggleAutomationsFavoritedDrawer(true)}
          >
            <IconButton className={classes.btnAddIcon}>
              <img src="assets/icons/btn-add-automations-dashboard.svg" />
            </IconButton>
            Adicionar atalho de automações
          </div>
          {isLoadingScenes ? (
            <div className={classes.containerLoading}>
              <CircularProgress
                size={30}
                color="secondary"
                style={{ marginLeft: "10px" }}
              />
            </div>
          ) : (
            <>
              {favoritedScenes.length > 0 ? (
                <div
                  style={{
                    marginTop: "10px",
                    overflow: "auto",
                    maxHeight: "490px",
                  }}
                >
                  {favoritedScenes.map((scene: any, index: number) => {
                    return (
                      <FavoritedAutomation
                        scene={scene}
                        key={"favorited-automation-item-" + index}
                      />
                    );
                  })}
                </div>
              ) : (
                <div
                  style={{
                    marginTop: "10px",
                    overflow: "auto",
                    maxHeight: "490px",
                  }}
                >
                  <EmptyState
                    title={"Nenhuma automação adicionada"}
                    subtitle={
                      <span>
                        Por favor, experimente clicar em{" "}
                        <strong style={{ color: "#00a335" }}>
                          Adicionar atalho de automação
                        </strong>
                        .
                      </span>
                    }
                    image={"assets/imgs/empty-state-imgs/empty-state-img-3.png"}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AutomationsFavorited;

const useStyles = makeStyles(() =>
  createStyles({
    containerAutomations: {
      backgroundColor: "#EBEEEE",
      border: "1px solid #EBEEEE",
      borderRadius: "25px",
      height: "100%",
    },
    containerAutomationsTitle: {
      color: "#3E5055",
      fontSize: "15px",
      fontWeight: 600,
      lineHeight: "20px",
      padding: "20px",
    },
    automationsList: {
      padding: "20px",
      height: "calc(100% - 61px)",
      maxHeight: "630px",
    },
    btnAddAutomations: {
      padding: "10px",
      backgroundColor: "#FFFFFF",
      borderRadius: "10px",
      fontSize: "15px",
      fontWeight: 600,
      lineHeight: "20px",
      color: "#6DCC9A",
      cursor: "pointer",
    },
    btnAddIcon: {
      marginRight: "15px",
    },
    containerLoading: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);
