import axios, { AxiosError } from "axios";
import { ReactNode, useContext, useEffect, useState } from "react";
import GlobalContex from "../../../GlobalContext/GlobalContext";
import { removeDevices } from "../../../Services/DevicesService";
import {
  getAllDevicesService,
  migrateDevices,
} from "../../../Services/AllDevicesService";
import AllDevicesContext from "./AllDevicesContext";
import DeviceModel from "../../../Models/DeviceModel";
import { getHomeIDSelect } from "../../../Services/UserService";

interface Props {
  children: ReactNode;
}

const AllDevicesProvider: React.FunctionComponent<Props> = (props: Props) => {
  const { children } = props;
  const {
    showAlert,
    devices,
    updateDevicesList,
    toggleWaitIndicator,
    setPathnameUrl,
    devicesToRelocate,
  } = useContext(GlobalContex);

  const [associatingDevices, setAssociatingDevices] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [relocate, setRelocate] = useState<boolean>(false);
  const [statusRequest, setStatusRequest] = useState<number>(0);
  const [removeDevice, setRemoveDevice] = useState<boolean>(false);
  const [idRoomSelected, setIdRoomSelected] = useState<string>("");
  const [devicesToAssociate, setDevicesToAssociate] = useState<string[]>([]);
  const [devicesToShow, setDevicesToShow] = useState<DeviceModel[]>([]);
  const [filterNameValue, setFilterNameValue] = useState<string>("");
  const [filterPlaceValue, setFilterPlaceValue] = useState<string>("");
  const [filterModelValue, setFilterModelValue] = useState<string>("");

  useEffect(() => {
    setPathnameUrl(window.location.pathname);
    toggleWaitIndicator("AllDevices:init", true);
    getAllDevices().then(() => {
      toggleWaitIndicator("AllDevices:init", false);
    });
    return () => {
      toggleWaitIndicator("AllDevices:init", false);

      setAssociatingDevices(false);
      setStatusRequest(0);
    };
  }, []);

  useEffect(() => {
    setDevicesToShow(
      devices.filter(
        (device: DeviceModel) =>
          device.name.toLowerCase().includes(filterNameValue.toLowerCase()) &&
          ((filterPlaceValue !== "" &&
            device.place_name &&
            device.place_name !== null &&
            device.place_name
              .toLowerCase()
              .includes(filterPlaceValue.toLowerCase())) ||
            filterPlaceValue === "") &&
          device.product_name
            .toLowerCase()
            .includes(filterModelValue.toLowerCase())
      )
    );
  }, [devices, filterNameValue, filterPlaceValue, filterModelValue]);

  const updateRelocateDevices = () => {
    getAllDevices();
  };

  const getAllDevices = async () => {
    await getAllDevicesService()
      .then((response) => {
        updateDevicesList(response.data);
      })
      .catch(() => {
        showAlert("Houve um problema ao carregar os dispositivos!", "error");
      });
  };

  const associateDevicesToRoom = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    setAssociatingDevices(true);
    try {
      event.preventDefault();
      const response = await migrateDevices(idRoomSelected, devicesToAssociate);
      setStatusRequest(response.status);

      if (response.status === 200) {
        getAllDevices();
      } else {
        showAlert(
          "Houve algum problema em realocar os dispositivos",
          "warning"
        );
      }
    } catch (e: any) {
      const err = e as AxiosError;
      const errorCode: number = err.response?.data?.status;
      showAlert(err.message, "error");
      setStatusRequest(errorCode);
    } finally {
      setAssociatingDevices(false);
    }
  };

  const resetForm = () => {
    setAssociatingDevices(false);
    setDevicesToAssociate([]);
    setStatusRequest(0);
    setOpenModal(false);
    setRelocate(false);
  };

  const confirmAssociation = () => {
    resetForm();
  };

  const changeRelocateStatus = (realocateStatus: boolean) => {
    setRelocate(realocateStatus);
    setDevicesToAssociate([]);
  };

  const changeRemoveDeviceStatus = (removeDeviceStatus: boolean) => {
    setRemoveDevice(removeDeviceStatus);
    setDevicesToAssociate([]);
  };

  const confirmRemoveDevices = async (closeFunction: () => void) => {
    try {
      const home_id = getHomeIDSelect() || "";
      const response = await removeDevices(devicesToRelocate, home_id);
      if (response.status === 200) {
        getAllDevices();
        showAlert("Dispositivo(s) removido(s) com sucesso!", "success");
      }
    } catch (err) {
      const error = err as Error | AxiosError;
      let msg = '"Não foi possível remover o dispositivo';
      msg = axios.isAxiosError(error)
        ? error.response?.data.errors[0]?.message || msg
        : error.message || msg;
      showAlert(msg, "error");
    } finally {
      closeFunction();
    }
  };

  return (
    <AllDevicesContext.Provider
      value={{
        devices,
        relocate,
        setRelocate,
        openModal,
        setOpenModal,
        idRoomSelected: idRoomSelected,
        setIdRoomSelected: setIdRoomSelected,
        devicesToAssociate,
        setDevicesToAssociate,
        associateDevicesToRoom: associateDevicesToRoom,
        associatingDevices,
        statusRequest,
        setStatusRequest,
        confirmAssociation,
        changeRelocateStatus,
        updateRelocateDevices,
        changeRemoveDeviceStatus,
        removeDevice,
        confirmRemoveDevices,
        devicesToShow,
        setDevicesToShow,
        filterNameValue,
        setFilterNameValue,
        filterPlaceValue,
        setFilterPlaceValue,
        filterModelValue,
        setFilterModelValue,
      }}
    >
      {children}
    </AllDevicesContext.Provider>
  );
};

export default AllDevicesProvider;
