import { getCommandLabel } from "../../../Utils/DeviceConfiguration";
import DeviceModel from "../../../Models/DeviceModel";
import Skeleton from "@material-ui/lab/Skeleton";

import * as S from "./styled";

export interface DeviceLog {
  code: string;
  value: string;
  event_time: number;
  event_id: number;
  status: string;
}

type CardLogItemProps = {
  device: DeviceModel;
  log: DeviceLog;
};

const getEventName = (e: number) => {
  if (e >= 0 && e < 10) {
    const codeName = [
      "Online",
      "Offline",
      "Ativação do dispositivo",
      "Redefinição do dispositivo",
      "Emissão de comando",
      "Atualização de firmware",
      "Relatório de ponto de dados",
      "Semáforo do dispositivo",
      "Reinicialização do dispositivo",
      "Informações de tempo",
    ];
    return codeName[e];
  }
  return "Evento inválido";
};

const CardLogItem: React.FunctionComponent<CardLogItemProps> = ({
  device,
  log,
}: CardLogItemProps) => {
  return (
    <S.CardEventLogContainer>
      <S.CardContentImage>
        <img src={device.icon} alt="Imagem do dispositivo" height={50} />
        <span>{device.name}</span>
      </S.CardContentImage>
      <S.CardContentMain>
        <div className="card-log-item-title">
          {getEventName(log.event_id - 1)}
        </div>
        <div className="card-log-item-subtitle">
          {log.code && getCommandLabel(device, log.code)} {log.code && ":"}{" "}
          {log.value && getCommandLabel(device, log.value, log.code)}
        </div>
        <div className="card-log-item-time">
          {new Date(log.event_time).toLocaleTimeString()}
        </div>
      </S.CardContentMain>
    </S.CardEventLogContainer>
  );
};

export const CardLogItemSkeleton: React.FunctionComponent = () => {
  return (
    <S.CardEventLogContainer>
      <S.CardContentImage>
        <Skeleton variant="rect" width={80} height={80} />
      </S.CardContentImage>
      <S.CardContentMain>
        <div className="card-log-item-title">
          <Skeleton variant="text" width={210} height={32} />
        </div>
        <div className="card-log-item-subtitle">
          <Skeleton variant="text" width={150} height={28} />
        </div>
        <div className="card-log-item-time">
          <Skeleton variant="text" width={100} height={24} />
        </div>
      </S.CardContentMain>
    </S.CardEventLogContainer>
  );
};

export default CardLogItem;
