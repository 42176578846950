import { Button, createStyles, makeStyles } from "@material-ui/core";
import { useContext } from "react";
import OperatorsGroupCreatePageContext from "../../../../Context/OperatorsGroupCreatePageContext";
import AddIcon from "@material-ui/icons/Add";
import EnvironmentRestrictModel from "../../../../../../Models/EnvironmentRestrictModel";

const useStyles = makeStyles(() =>
  createStyles({
    containerButtonAdd: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "25px",
    },
    buttomAdd: {
      borderRadius: "50%",
      width: "29px !important",
      height: "29px !important",
      minWidth: "0px",
      backgroundColor: "#EBEEEE",
    },
  })
);

const ButtonAdd: React.FunctionComponent = () => {
  const classes = useStyles();
  const {
    environments,
    selectRestrictEnvironment,
    restrictDevicesFromEnvironment,
    setSelectRestrictEnvironment,
  } = useContext(OperatorsGroupCreatePageContext);

  const addGroup = () => {
    const index = environments.findIndex(
      (env: EnvironmentRestrictModel) =>
        env.environment_id === selectRestrictEnvironment.environment_id
    );
    if (environments[index]) environments[index].restrict = true;
    restrictDevicesFromEnvironment(
      selectRestrictEnvironment.environment_id,
      true
    );
    setSelectRestrictEnvironment({} as EnvironmentRestrictModel);
  };

  return (
    <div className={classes.containerButtonAdd}>
      <Button onClick={addGroup} classes={{ root: classes.buttomAdd }}>
        <AddIcon color="secondary" />
      </Button>
      <p
        style={{
          margin: "0px",
          color: "#6DCC9A",
          fontSize: "15px",
          fontWeight: "bold",
        }}
      >
        Adicionar
      </p>
    </div>
  );
};

export default ButtonAdd;
