import clsx from "clsx";
import MenuItemModel, { ListItemModel } from "../../Models/MenuItemModel";
import Drawer from "@material-ui/core/Drawer";
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PlaceModel from "../../Models/PlaceModel";
import DrawerHeader from "./DrawerHeader";
import SearchInput from "./SearchInput";
import SubmenuItem from "./SubmenuItem";
import EmptyResultMessage from "./EmptyResultMessage";
import CollapsedItem from "./CollapsedItem";
import GlobalContex from "../../GlobalContext/GlobalContext";
import { makeStyles } from "@material-ui/core/styles";
import SideMenuContext from "./Context/SideMenuContext";
import { debounce } from "lodash";

const drawerWidth = 302;

const useStyles = makeStyles((theme) => {
  return {
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: "hidden",
      zIndex: 9999,
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
        zIndex: 9999,
      },
    },
  };
});

const CustomDrawerMainMenu: React.FunctionComponent = () => {
  const { places, openSideBar, setSideMenuExpanded, pathnameUrl } =
    useContext(GlobalContex);
  const classes = useStyles();

  const [search, setSearch] = useState<string>("");
  const [expandedAll, setExpandedAll] = useState<boolean>(false);
  const [filteredMenuItens, setFilteredMenuItem] = useState<MenuItemModel[]>(
    []
  );
  const [menuItens, setMenuItens] = useState<MenuItemModel[]>([
    {
      title: "Dispositivos",
      listItem: [
        {
          icon: "assets/icons/dispositivos.svg",
          text: "Todos os dispositivos",
          link: "/all-devices",
        },
      ],
    },
    {
      title: "Automações",
      listItem: [
        {
          icon: "assets/icons/automacoes.svg",
          text: "Gerenciador de automações",
          link: "/automation-dashboard",
        },
      ],
    },
    {
      title: "Usuários",
      listItem: [
        {
          icon: "assets/icons/userMenu_1.svg",
          text: "Todos os usuários",
          link: "/usuarios",
        },
        {
          icon: "assets/icons/userMenu_2.svg",
          text: "Grupo de operadores",
          link: "/operators",
        },
      ],
    },
  ]);

  useEffect(() => {
    setMenuItens([
      ...menuItens.filter((m) => m.title !== "Locais"),
      {
        title: "Locais",
        link: "/manage-place",
        listItem: places.map((place: PlaceModel) => {
          return {
            icon: "assets/icons/empreendimentos.svg",
            text: place.name,
            link: "/manage-room",
            place: place,
          } as ListItemModel;
        }),
      },
    ]);
  }, [places]);

  const updateExpandedMenuItens = (menuFiltered: MenuItemModel[]) => {
    const menuFlatTitles: string[] = [];
    menuFiltered.forEach((submenu) => {
      menuFlatTitles.push(submenu.title);
      submenu.listItem.forEach((item) => {
        menuFlatTitles.push(item.text);
      });
    });

    setSideMenuExpanded(menuFlatTitles);
  };

  useEffect(() => {
    if (search.trim() === "") {
      searchTerm.cancel();
      setFilteredMenuItem(menuItens);
      return;
    }
    searchTerm(search, menuItens);
  }, [menuItens, search]);

  const filterMenuByTermSearch = (
    term: string,
    menu: MenuItemModel[]
  ): MenuItemModel[] => {
    if (!term || term.trim() === "") return menu;

    const search = term.toUpperCase();
    const items = menu.filter((menuItem) => {
      return (
        menuItem.title.toUpperCase().includes(search) ||
        menuItem.listItem.some((item) => {
          return (
            item.text.toUpperCase().includes(search) ||
            item.place?.rooms.some((room) => {
              return room.name.toUpperCase().includes(search);
            })
          );
        })
      );
    });

    return items.map((item) => {
      return {
        ...item,
        listItem: item.listItem
          .map((subitem) => {
            if (!subitem.place) return subitem;
            return {
              ...subitem,
              place: {
                ...subitem.place,
                rooms: subitem.place?.rooms.filter((room) =>
                  room.name.toUpperCase().includes(search)
                ),
              },
            };
          })
          .filter(
            (item) =>
              item.text.toUpperCase().includes(search) ||
              (item.place?.rooms && item.place.rooms.length > 0)
          ),
      };
    });
  };

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value;
    setSearch(term);
    const isSearching = term.trim() !== "";
    if (!isSearching) {
      let findMenuItem: string[] = [];
      const menuItemSelected = menuItens.filter((menu) => {
        if (pathnameUrl === "/") {
          menu.listItem.forEach((item) => {
            const roomItem = item.place?.rooms.some((room) => {
              return room.name === localStorage.getItem("roomSelectName");
            });

            if (roomItem) {
              setSideMenuExpanded([item.place!.name]);
              findMenuItem = [item.place!.name];
            }
          });
        }
        return (
          menu.link === pathnameUrl ||
          menu.listItem.some(
            (item) =>
              item.link === pathnameUrl ||
              item.place?.rooms.some(
                (r) => r.name === localStorage.getItem("roomSelectName")
              )
          )
        );
      });
      setSideMenuExpanded([menuItemSelected[0].title, ...findMenuItem]);
    }
    setExpandedAll(isSearching);
  };

  const searchTerm = useMemo(
    () =>
      debounce(
        (search: string, menu: MenuItemModel[]) => {
          const menuFiltered = filterMenuByTermSearch(search, menu);
          setFilteredMenuItem(menuFiltered);

          if (search.trim() != "") {
            updateExpandedMenuItens(menuFiltered);
          }
        },
        500,
        { leading: true, trailing: false, maxWait: 500 }
      ),
    [search, menuItens]
  );

  const handleChangeCollapseSubmenu = useCallback(
    (submenuTitle: string, persist?: boolean) => {
      setExpandedAll(false);
      setSideMenuExpanded((state) => {
        if (!state.some((v) => submenuTitle === v)) {
          return [...state, submenuTitle];
        }
        if (state.some((v) => submenuTitle === v) && !persist) {
          return state.filter((v) => submenuTitle !== v);
        }
        return state;
      });
    },
    [setSideMenuExpanded]
  );

  const showEmptyResult = useCallback(() => {
    return (
      openSideBar && search.trim() !== "" && filteredMenuItens.length === 0
    );
  }, [search, openSideBar, filteredMenuItens]);

  return (
    <SideMenuContext.Provider
      value={{
        search,
        setSearch,
        expandedAll,
        setExpandedAll,
        handleChangeCollapseSubmenu,
      }}
    >
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: openSideBar,
          [classes.drawerClose]: !openSideBar,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: openSideBar,
            [classes.drawerClose]: !openSideBar,
          }),
        }}
        style={{
          zIndex: 1221,
        }}
      >
        <DrawerHeader />

        {openSideBar && (
          <SearchInput value={search} onChange={handleChangeSearch} />
        )}

        {openSideBar &&
          filteredMenuItens.map((item: MenuItemModel, index) => (
            <SubmenuItem
              title={item.title}
              link={item.link}
              items={item.listItem}
              key={index}
            />
          ))}

        {showEmptyResult() && <EmptyResultMessage />}

        {!openSideBar && (
          <>
            <CollapsedItem
              id="menu-item-devices"
              title="Todos os dispositivos"
              target="/all-devices"
              imagePath="assets/icons/dispositivos.svg"
            />
            <CollapsedItem
              id="menu-item-automations"
              title="Automações"
              target="/automation-dashboard"
              imagePath="assets/icons/automacoes.svg"
            />
            <CollapsedItem
              id="menu-item-users"
              title="Todos os usuários"
              target="/usuarios"
              imagePath="assets/icons/userMenu_1.svg"
            />
            <CollapsedItem
              id="menu-item-operators"
              title="Grupos de operadores"
              target="/operators"
              imagePath="assets/icons/userMenu_2.svg"
            />
            <CollapsedItem
              id="menu-item-places"
              title="Locais"
              target="/manage-place"
              activedBy={["/manage-place", "/", "/manage-room"]}
              imagePath="assets/icons/empreendimentos.svg"
            />
          </>
        )}
      </Drawer>
    </SideMenuContext.Provider>
  );
};

export default CustomDrawerMainMenu;
export { drawerWidth };
