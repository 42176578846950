import { HomeModel } from "../Models/HomeUserModel";

const USER_HOME_INFO = "userHomeInfo";

export enum UserRole {
  Admin = "Administrador",
  Owner = "Owner",
  Operator = "Operador",
}

export const setUserHomeInfoLocal = (userInfo: HomeModel): void => {
  localStorage.setItem(USER_HOME_INFO, JSON.stringify(userInfo));
};

export const getUserHomeInfoLocal = (): HomeModel | undefined => {
  const value = localStorage.getItem(USER_HOME_INFO);
  if (!value) return undefined;
  return JSON.parse(value);
};

export const clearUserHomeInfoLocal = (): void => {
  localStorage.removeItem(USER_HOME_INFO);
};

const getUserRole = (role: UserRole): boolean => {
  const value = getUserHomeInfoLocal();

  if (!value?.roles) {
    return false;
  }

  return value.roles.includes(role);
};

export const isUserAdmin = (): boolean => {
  return getUserRole(UserRole.Admin);
};

export const isUserOwner = (): boolean => {
  return getUserRole(UserRole.Owner);
};

export const isUserOperator = (): boolean => {
  return getUserRole(UserRole.Operator);
};

export const hasRole = (rolesToCheck: string[]): boolean => {
  const home = getUserHomeInfoLocal();
  if (!home) return false;

  return home.roles.some((role) =>
    rolesToCheck.some((roleCheck) => role === roleCheck)
  );
};
