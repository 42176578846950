import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemText,
  Paper,
} from "@material-ui/core";
import OperatorsGroupCreatePageContext from "../../Context/OperatorsGroupCreatePageContext";
import InputSearch from "../InputSearch";
import { cloneDeep, debounce } from "lodash";
import { UserGroupAutomations } from "../../../../Models/UserGroupsModel";
import EmptyState from "../EmptyState";

const useStyles = makeStyles((theme) => ({
  header: {
    margin: "12px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "end",

    "& .MuiFormControlLabel-label": {
      fontWeight: 600,
      fontSize: "15px",
    },

    "& .MuiCheckbox-root ": {
      padding: "0px 20px",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  containerSelectAutomation: {
    maxHeight: "300px",
    overflowY: "auto",
    "& .MuiListItemText-primary": {
      color: "#8B979F",
      fontWeight: "600",
      fontSize: "15px!important",
    },
    "& .MuiListItemText-secondary": {
      color: "#8B979F",
      fontSize: "15px!important",
    },
    "& .MuiCheckbox-colorSecondary:not(.Mui-checked)": {
      color: "#8B979F",
    },
  },
  itemAutomation: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 12px",
    borderRadius: "0",
  },
}));

const CustomAutomationSelect: React.FunctionComponent = () => {
  const classes = useStyles();
  const {
    setIsCheckedAllAutomations,
    isEdit,
    isCheckedAllAutomations,
    setIsCheckedAutomation,
    isCheckedAutomation,
    automationsList,
    automationsListStub,
  } = useContext(OperatorsGroupCreatePageContext);

  const [search, setSearch] = useState<string>("");
  const [filteredAutomations, setFilteredAutomations] = useState<
    UserGroupAutomations[]
  >([]);

  useEffect(() => {
    if (isEdit) {
      const selecteds: string[] = [];
      automationsList.map((o) => {
        if (o.selected) selecteds.push(o.automation_id);
      });
      setIsCheckedAutomation(selecteds);
    }
  }, [automationsList]);

  useEffect(() => {
    isCheckedAutomation.length === automationsListStub.length &&
    automationsListStub.length > 0
      ? setIsCheckedAllAutomations(true)
      : setIsCheckedAllAutomations(false);
  }, [isCheckedAutomation]);

  const handleSelectAll = () => {
    setIsCheckedAllAutomations(!isCheckedAllAutomations);
    const algo = automationsListStub?.map((al) => al.automation_id);
    setIsCheckedAutomation(algo);
    if (isCheckedAllAutomations) {
      setIsCheckedAutomation([]);
    }
  };

  const handleClick = (e: ChangeEvent<HTMLInputElement>, id: string) => {
    const { checked } = e.target;
    setIsCheckedAutomation([...isCheckedAutomation, id]);
    if (!checked) {
      setIsCheckedAutomation(isCheckedAutomation.filter((item) => item !== id));
    }

    if (
      isCheckedAutomation.filter((item) => item !== id).length !==
      automationsList.length
    )
      setIsCheckedAllAutomations(false);
  };

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    return setSearch(event.target.value);
  };

  useEffect(() => {
    searchTerm(search, automationsListStub);
  }, [automationsListStub, search]);

  const searchTerm = useCallback(
    debounce((search: string, Automations: UserGroupAutomations[]) => {
      const cloned = cloneDeep(Automations);
      let filtered = cloned;
      if (search.trim() != "") {
        const term = search.toUpperCase();
        filtered = cloned.filter((automation: { name: string }) =>
          automation.name.toUpperCase().includes(term)
        );
      }
      setFilteredAutomations(filtered);
    }, 300),
    []
  );

  const isResultSearchEmpty = useCallback(() => {
    return search.trim() != "" && filteredAutomations.length === 0;
  }, [search, filteredAutomations]);

  const isOddRow = (i: number) => {
    return i % 4 == 0 || i % 4 == 1;
  };

  return (
    <>
      <div className={classes.header}>
        <FormControlLabel
          control={
            <Checkbox
              key={"selectAlls"}
              checked={isCheckedAllAutomations}
              onChange={handleSelectAll}
              name="checkedB"
              color="secondary"
            />
          }
          label="Selecionar tudo"
        />

        <InputSearch
          id="filterAutomation"
          onChange={handleChangeSearch}
          placeholder="Pesquisar nome de automação"
        ></InputSearch>
      </div>

      <Divider style={{ marginBottom: 16 }} />

      <div className={classes.containerSelectAutomation}>
        <Grid container spacing={0}>
          {filteredAutomations?.map((item, i) => (
            <Grid
              item
              key={item.automation_id}
              xs={6}
              style={{ borderColor: "#D1DAE0" }}
            >
              <Paper
                className={classes.itemAutomation}
                elevation={0}
                style={{
                  height: "100%",
                  border: isOddRow(i) ? "none" : "1px solid #D1DAE0",
                  borderLeft: `${isOddRow(i) ? i % 2 : 1}px solid #D1DAE0`,
                  borderRight: `${
                    !isOddRow(i)
                      ? i % 2
                      : isOddRow(i) && filteredAutomations.length !== i
                      ? 0
                      : 1
                  }px solid #D1DAE0`,
                  backgroundColor:
                    i % 4 == 0 || i % 4 == 1 ? "#F2F2F2" : "#FFF",
                }}
              >
                <Checkbox
                  checked={isCheckedAutomation.includes(item.automation_id)}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleClick(event, item.automation_id);
                  }}
                  name="checkedB"
                  color="secondary"
                />

                <ListItem style={{ paddingLeft: 10 }}>
                  <ListItemText
                    primary={item.name}
                    secondary={item.actions.length + " dispositivos"}
                  />
                </ListItem>
              </Paper>
            </Grid>
          ))}
        </Grid>

        {(automationsListStub.length === 0 || isResultSearchEmpty()) && (
          <EmptyState
            msg={`Nenhuma automação ${
              automationsListStub.length === 0 ? "cadastrada" : "encontrada"
            }`}
          />
        )}
      </div>
    </>
  );
};

export default CustomAutomationSelect;
