import { IconProps } from "./IconProps";

const Icon: React.FunctionComponent<IconProps> = ({
  src,
  file_type = "svg",
  ...props
}: IconProps) => {
  const icon_file_link = "assets/icons/";
  return <img {...props} src={icon_file_link + src + "." + file_type} />;
};

export default Icon;
