import { makeStyles } from "@material-ui/core";
import DeviceModel from "../../../../../Models/DeviceModel";
import ListEnvironmentsFromDevices from "../../../../../Components/ListEnvironmentsFromDevices";
import { useEffect, useState } from "react";
import CustomDeviceBatteryIcon from "../../../../../Components/CustomDeviceBatteryIcon";

interface Props {
  device: DeviceModel;
}

interface Action {
  code: string;
  t: number;
  value: string | number | boolean;
}

const Device_ISM_1001: React.FunctionComponent<Props> = ({ device }: Props) => {
  const classes = useStyles();
  const [lastAction, setLastAction] = useState<Action | undefined>();

  const pir = device.status.find((s) => s.code === "pir") || {
    code: "",
    value: "",
    t: 0,
  };

  const battery = device.status.find(
    (s) => s.code === "battery_percentage"
  ) || { code: "", value: "", t: 0 };

  const getMostRecentAction = () => {
    if (pir.t >= battery.t) {
      setLastAction(pir);
      return;
    }

    if (battery.t > pir.t) {
      setLastAction(battery);
      return;
    }
  };

  useEffect(() => {
    getMostRecentAction();
  }, [pir.value, pir.t, battery.value, battery.t]);

  const actionLabel = (action: Action) => {
    if (action.code === "pir" && typeof action.value === "string") {
      if (action.value === "pir") {
        return "Movimento detectado";
      } else {
        return "Ninguém passou";
      }
    }

    if (
      typeof action.value === "number" &&
      action.code === "battery_percentage"
    ) {
      return "Bateria: " + action.value + "%";
    }

    return "Ação desconhecida!";
  };

  const showAction = () => {
    if (lastAction) {
      const eventDate = new Date(lastAction.t);
      return (
        <>
          {eventDate.getDate() +
            "." +
            (1 + eventDate.getMonth()) +
            "." +
            eventDate.getFullYear() +
            " " +
            eventDate.toLocaleTimeString() +
            " " +
            actionLabel(lastAction)}
        </>
      );
    }
    return "Nenhuma ação encontrada!";
  };

  return (
    <div className={classes.container}>
      <div className={classes.title}>{device.name}</div>
      {device.environments && device.environments.length > 0 && (
        <div className={classes.subtitle}>
          <ListEnvironmentsFromDevices environments={device.environments} />
        </div>
      )}
      <div
        className={classes.componentContainer}
        style={{
          backgroundColor: pir.value === "pir" ? "#EF847E" : "#36C0A9",
          height:
            device.environments && device.environments.length > 0
              ? "calc(100% - 140px)"
              : "calc(100% - 85px)",
        }}
      >
        <div className={classes.batteryContainer}>
          <CustomDeviceBatteryIcon
            value={battery.value as number}
            color={"#FFF"}
            isAlertActive={pir.value === "pir"}
            alertColorActive={"#FFF"}
            fontColor={"#FFF"}
          />
        </div>
        <div className={classes.containerImg}>
          <img
            src={
              pir.value === "pir"
                ? "assets/imgs/ism_1001_alert.png"
                : "assets/imgs/ism_1001_no_alert.png"
            }
            height={300}
          />
        </div>
        <div className={classes.lastActionLabel}>Última ação</div>
        <div className={classes.lastAction}>{showAction()}</div>
      </div>
    </div>
  );
};

export default Device_ISM_1001;

const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
  },
  title: {
    fontSize: "23px",
    lineHeight: "31px",
    color: "#3E5055",
    fontWeight: 600,
    textAlign: "center",
    padding: "35px 0px 20px 0px",
  },
  subtitle: {
    paddingBottom: "30px",
    display: "flex",
    justifyContent: "center",
  },
  componentContainer: {
    width: "100%",
    overflow: "auto",
  },
  batteryContainer: {
    padding: "50px 0px",
  },
  batteryLabel: {
    fontWeight: 600,
    fontSize: "21px",
    lineHeight: "29px",
    textAlign: "center",
    color: "#FFFFFF",
  },
  containerImg: {
    textAlign: "center",
  },
  lastActionLabel: {
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#FFFFFF",
    marginBottom: "5px",
  },
  lastAction: {
    fontWeight: 600,
    fontSize: "23px",
    lineHeight: "31px",
    textAlign: "center",
    color: "#FFFFFF",
  },
}));
