import { Divider, Popover } from "@material-ui/core";
import {
  ButtonSend,
  Container,
  Content,
  ContentText,
  Footer,
  Title,
} from "./styled";
import LaunchIcon from "@material-ui/icons/Launch";

interface ShopProps {
  open: boolean;
  anchor: HTMLDivElement | null;
  onClose: () => void;
}

const Shop: React.FunctionComponent<ShopProps> = ({
  open,
  anchor,
  onClose,
}: ShopProps) => {
  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Container>
        <Title>Loja de dispositivos</Title>
        <Divider />
        <Content>
          <ContentText>
            Conheça nossa linha de produtos para otimizar mais ainda a sua
            rotina com o IzyManager
          </ContentText>
          <iframe
            src="https://loja.intelbras.com.br/casa-inteligente?gclid=Cj0KCQjwyt-ZBhCNARIsAKH11774gJkHt99PlHxywdoWFy3kL5Vyg-DERUiNgLsF1vk-PJHI4AvCNX4aArN7EALw_wcB"
            width={"100%"}
            height={215}
          />
        </Content>
        <Footer>
          <ButtonSend
            target={"_blank"}
            href="https://loja.intelbras.com.br/casa-inteligente?gclid=Cj0KCQjwyt-ZBhCNARIsAKH11774gJkHt99PlHxywdoWFy3kL5Vyg-DERUiNgLsF1vk-PJHI4AvCNX4aArN7EALw_wcB"
          >
            <LaunchIcon
              style={{ transform: "rotate(270deg)", marginRight: "10px" }}
              fontSize="small"
            />
            Ver todos os produtos
          </ButtonSend>
        </Footer>
      </Container>
    </Popover>
  );
};

export default Shop;
