import { TextField } from "@material-ui/core";
import { parseDateSeconds, parseDateString } from "../../../Utils/Functions";

interface Props {
  command: any;
  marks?: any;
  value: any;
  onChange: any;
}

const TimerInput: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <TextField
      type="time"
      value={parseDateString(props.value)}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        step: 5,
      }}
      onBlur={(e) => {
        props.onChange(parseDateSeconds(e.target.value));
      }}
      onChange={(e) => {
        props.onChange(parseDateSeconds(e.target.value));
      }}
    />
  );
};

export default TimerInput;
