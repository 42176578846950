import { makeStyles, createStyles, Button } from "@material-ui/core";
import ReplyIcon from "@material-ui/icons/Reply";
import { useContext, useState } from "react";
import CustomConfirmDialog from "../../../../Components/CustomConfirmDialog";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import AllDevicesContext from "../../Context/AllDevicesContext";

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      padding: "10px 30px",
      backgroundColor: "#EBEEEE",
      borderRadius: "25px 25px 0px 0px",
    },
    button: {
      fontWeight: "normal",
      fontSize: "15px",
      color: "#8B979F",
      textTransform: "none",
      margin: "0px 10px",
      minWidth: "135px",
      minHeight: "50px",
    },
    buttonMove: {
      color: "#00A335",
      textTransform: "none",
      fontWeight: "bold",
    },
    buttonDelete: {
      color: "#00A335",
      textTransform: "none",
      fontWeight: "bold",
      minWidth: "130px",
    },
    icon: {
      webkitTransform: "scaleX(-1)",
      transform: "scaleX(-1)",
    },
  })
);

const Header: React.FunctionComponent = () => {
  const classes = useStyles();

  const {
    changeRelocateStatus,
    changeRemoveDeviceStatus,
    removeDevice,
    confirmRemoveDevices,
  } = useContext(AllDevicesContext);
  const { setOpenModalRelocate, devicesToRelocate, setDevicesToRelocate } =
    useContext(GlobalContex);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const moveDevices = () => {
    setOpenModalRelocate(true);
  };

  const clickCancelRelocate = () => {
    changeRelocateStatus(false);
    changeRemoveDeviceStatus(false);
    setDevicesToRelocate([]);
  };

  const closeModalDelete = () => {
    setOpenDialog(false);
  };

  const openModelDelete = () => {
    setOpenDialog(true);
  };

  return (
    <div className={classes.header}>
      <div
        style={{
          fontSize: "15px",
          fontWeight: 600,
          lineHeight: "20px",
          color: "#8B979F",
          marginBottom: "10px",
        }}
      >
        {removeDevice
          ? "Selecione os dispositivos que você deseja remover"
          : "Selecione os dispositivos que você deseja fazer a realocação:"}
      </div>
      {removeDevice ? (
        <Button
          className={classes.buttonDelete}
          variant="contained"
          disableElevation
          onClick={openModelDelete}
          disabled={devicesToRelocate.length === 0}
          style={{
            minHeight: "50px",
            backgroundColor: "#EF847E",
          }}
        >
          <strong style={{ color: "#FFF" }}>Remover</strong>
        </Button>
      ) : (
        <Button
          className={classes.buttonMove}
          variant="contained"
          disableElevation
          onClick={moveDevices}
          disabled={devicesToRelocate.length === 0}
          style={{
            minHeight: "50px",
            backgroundColor: "#00A335",
          }}
          startIcon={<ReplyIcon htmlColor={"#FFF"} className={classes.icon} />}
        >
          <strong style={{ color: "#FFF" }}>Realocar</strong>
        </Button>
      )}
      <Button
        className={classes.button}
        disableElevation
        onClick={clickCancelRelocate}
        style={{
          border: "1px solid #BDBDBD",
        }}
      >
        Cancelar
      </Button>
      <CustomConfirmDialog
        onClose={closeModalDelete}
        onCloseFocus={closeModalDelete}
        openDialog={openDialog}
        onConfirmButton={() => {
          confirmRemoveDevices(closeModalDelete);
        }}
        onCancelButton={closeModalDelete}
        text={
          devicesToRelocate.length === 1 ? (
            <span>
              Você realmente deseja remover o dispositivo{" "}
              <strong>{devicesToRelocate[0].name}</strong>? Ele será removido do
              empreendimento e das automações associadas a ele.
            </span>
          ) : (
            <span>
              Você realmente deseja remover {devicesToRelocate.length}{" "}
              dispositivos? Eles serão removidos do empreendimento e das
              automações associadas a ele.
            </span>
          )
        }
        questionMark={false}
      />
    </div>
  );
};

export default Header;
