import { Button } from "@material-ui/core";
import { useContext } from "react";
import OperatorsGroupCreatePageContext from "../../../Context/OperatorsGroupCreatePageContext";

const SaveOrCancelPermissions: React.FunctionComponent = () => {
  const { handleCloseAccessModalControl, saveRestrictions } = useContext(
    OperatorsGroupCreatePageContext
  );

  return (
    <div
      style={{
        bottom: "0",
        display: "flex",
        alignItems: "end",
        justifyContent: "flex-end",
        flex: 1,
      }}
    >
      <div
        style={{
          padding: "35px",
        }}
      >
        <Button
          variant="text"
          style={{
            color: "#8B979F",
            padding: "8px 45px",
            textTransform: "none",
          }}
          onClick={() => {
            handleCloseAccessModalControl();
          }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          style={{
            marginLeft: "10px",
            padding: "8px 45px",
            textTransform: "none",
          }}
          onClick={saveRestrictions}
        >
          Salvar
        </Button>
      </div>
    </div>
  );
};

export default SaveOrCancelPermissions;
