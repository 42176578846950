import {
  Typography,
  createStyles,
  makeStyles,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  CircularProgress,
} from "@material-ui/core";
import React, { useContext } from "react";
import CustomConfirmDialog from "../../../../Components/CustomConfirmDialog";
import CustomTable from "../../../../Components/CustomTable";
import CustomTableContainer from "../../../../Components/CustomTableContainer";
import { TablePaginationContainer } from "../../../../Components/CustomTablePagination/styles";
import CustomTableHeadRoom from "../../../ManageRoom/Components/CustomTableHeadRoom";
import UsersPageContext from "../../Context/UsersPageContext";
import WithRoleAuthorization from "../../../../Components/WithRoleAuthorization";
import EmptyState from "../../../../Components/EmptyState";
import CustomTab, { CustomTabPanel } from "../../../../Components/CustomTab";
import CustomDisableIcon from "../../../../Components/CustomIcons/CustomDisableIcon";
import CustomEditIcon from "../../../../Components/CustomIcons/CustomEditIcon";
import CustomReactivateIcon from "../../../../Components/CustomIcons/CustomReactivateIcon";
import CustomDeleteIcon from "../../../../Components/CustomIcons/CustomDeleteIcon";
import CustomBlockResource from "../../../../Components/CustomBlockResource";
import CustomIndicator from "../../../../Components/CustomIndicator";
import GlobalContex from "../../../../GlobalContext/GlobalContext";

const useStyles = makeStyles(() =>
  createStyles({
    row: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& > div": {
        paddingRight: "0.6rem",
      },
    },
    displayedRows: {
      color: "#888888",
      fontSize: "14px",
    },
    labelRows: {
      marginRight: "8px",
      color: "#AFAFAF",
      fontSize: "14px",
    },

    thead: {
      "& th:last-child": {
        borderRadius: "0 22em 1em 0",
      },
      "& tr:nth-child(2) td": {
        borderTop: "1px solid #e9e9e9",
        "&:first-child": {
          borderRadius: "5px 0 0 0",
        },
        "&:last-child": {
          borderRadius: "0 5px 0 0",
        },
      },
    },
  })
);

interface HeadCellRoom {
  id: string;
  label: string;
}

const UsersPageList: React.FunctionComponent = () => {
  const {
    usersActive,
    usersDeactive,
    openToEdit,
    toggleActiveUser,
    handleCloseModalDelete,
    openDialog,
    isActiveUser,
    activeUserContext,
    deactiveUserContext,
    order,
    setOrder,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalUsers,
    isLoadingUsers,
    tabValue,
    setTabValue,
    orderBy,
    setOrderBy,
    removeUser,
    actionType,
    useSelected,
    allUsers,
  } = useContext(UsersPageContext);
  const classes = useStyles();

  const { home } = useContext(GlobalContex);

  const handleChange = (newValue: number) => {
    setPage(0);
    setTabValue(newValue);
  };

  const headCellsUsers: HeadCellRoom[] = [
    { id: "name", label: "Nome" },
    { id: "user.username", label: "E-mail" },
    { id: "userType", label: "Perfil" },
  ];

  const optionalHeadCellUsers: HeadCellRoom[] = [
    { id: "userGroups", label: "Grupo" },
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  function responsiveHeight(isFilterOpen: boolean) {
    return !isFilterOpen ? "500px" : "calc(94vh - 239.9px)";
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const defaultLabelDisplayedRows = (
    from: number,
    to: number,
    count: number
  ) => {
    return (
      <span className={classes.displayedRows}>
        {from}-{to} {"de"} {count}
      </span>
    );
  };

  const renderLabelRows = () => {
    return <span className={classes.labelRows}>{"exibindo"}</span>;
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div
      style={{
        padding: "0px 5.5rem",
      }}
    >
      <CustomTab
        labels={["Usuários ativos", "Usuários desativados"]}
        ariaLabel={"menu em tabs para a página de espaço selecionado"}
        id="tab-selected-location"
        value={tabValue}
        key={"tab-selected-location"}
        changeTab={(value: number) => handleChange(value)}
      />

      <CustomTabPanel value={tabValue} index={0} padding="10px 0px">
        {isLoadingUsers ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "50px",
            }}
          >
            <CircularProgress color="secondary" size={96} />
          </div>
        ) : (
          <>
            {usersActive.length ? (
              <>
                <CustomTableContainer
                  maxHeight={responsiveHeight(false)}
                  padding={"0px"}
                >
                  <CustomTable>
                    <CustomTableHeadRoom
                      order={order}
                      orderBy={orderBy}
                      customHeaderItens={headCellsUsers}
                      customOptionalHeaderItens={optionalHeadCellUsers}
                      onRequestSort={handleRequestSort}
                    />

                    <TableBody classes={{ root: classes.thead }}>
                      {home && (
                        <tr>
                          <td colSpan={5}>
                            <CustomIndicator
                              icon="icon_exclamation"
                              tooltip="Você atingiu o limite de usuários do seu plano. Exclua usuários ou contrate um novo plano"
                              key={"indicator-users"}
                              title="Usuários"
                              maximumNumberOfItens={home.max_user}
                              totalItens={allUsers}
                            />
                          </td>
                        </tr>
                      )}
                      {usersActive.map((row, index) => {
                        return (
                          <TableRow
                            role="checkbox"
                            tabIndex={-1}
                            key={"active-user-" + index}
                            hover
                            style={{ height: "95px " }}
                          >
                            {row.block && (
                              <TableCell colSpan={4}>
                                <div
                                  style={{
                                    position: "relative",
                                    cursor: "pointer",
                                  }}
                                >
                                  <CustomBlockResource
                                    icon={"icon_padlock"}
                                    title={"Usuário bloqueado"}
                                    direction={"column"}
                                    small
                                  />
                                  <>
                                    <TableCell>
                                      <Typography
                                        style={{
                                          paddingLeft: "10px",
                                          color: "#8B979F",
                                        }}
                                      >
                                        {row.name || "Usuário sem nome"}
                                      </Typography>
                                    </TableCell>

                                    <TableCell>
                                      <Typography
                                        style={{
                                          paddingLeft: "10px",
                                          color: "#8B979F",
                                        }}
                                      >
                                        {row.email}
                                      </Typography>
                                    </TableCell>

                                    <TableCell>
                                      <Typography
                                        style={{
                                          paddingLeft: "10px",
                                          color: "#8B979F",
                                        }}
                                      >
                                        {row?.userType?.name}
                                      </Typography>
                                    </TableCell>

                                    <TableCell>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            paddingLeft: "10px",
                                            color: "#8B979F",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            width: "12rem",
                                            overflow: "hidden",
                                          }}
                                        >
                                          {row.userGroups.length === 0 && (
                                            <>Sem grupo</>
                                          )}

                                          {row.userGroups.length > 0 &&
                                            row.userGroups
                                              .map((item) => item.name)
                                              .join(", ")}
                                        </Typography>
                                      </div>
                                    </TableCell>
                                  </>{" "}
                                </div>
                              </TableCell>
                            )}

                            {!row.block && (
                              <>
                                <TableCell>
                                  <Typography
                                    style={{
                                      paddingLeft: "10px",
                                      color: "#8B979F",
                                    }}
                                  >
                                    {row.name || "Usuário sem nome"}
                                  </Typography>
                                </TableCell>

                                <TableCell>
                                  <Typography
                                    style={{
                                      paddingLeft: "10px",
                                      color: "#8B979F",
                                    }}
                                  >
                                    {row.email}
                                  </Typography>
                                </TableCell>

                                <TableCell>
                                  <Typography
                                    style={{
                                      paddingLeft: "10px",
                                      color: "#8B979F",
                                    }}
                                  >
                                    {row?.userType?.name}
                                  </Typography>
                                </TableCell>

                                <TableCell>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        paddingLeft: "10px",
                                        color: "#8B979F",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        width: "12rem",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {row.userGroups.length === 0 && (
                                        <>Sem grupo</>
                                      )}

                                      {row.userGroups.length > 0 &&
                                        row.userGroups
                                          .map((item) => item.name)
                                          .join(", ")}
                                    </Typography>
                                  </div>
                                </TableCell>
                              </>
                            )}

                            <WithRoleAuthorization
                              permitedRoles={["Owner", "Administrador"]}
                            >
                              <TableCell>
                                {!row.isOwner && (
                                  <div className={classes.row}>
                                    <CustomDeleteIcon
                                      id="fab-remove-user"
                                      onClick={() => {
                                        toggleActiveUser(
                                          row,
                                          true,
                                          "removeUser"
                                        );
                                      }}
                                    />
                                    <CustomDisableIcon
                                      disabled={row.block}
                                      id="fab-delete-contact"
                                      onClick={() => {
                                        toggleActiveUser(
                                          row,
                                          true,
                                          "activeOrInactive"
                                        );
                                      }}
                                    />

                                    <CustomEditIcon
                                      id="fab-edit-contact"
                                      onClick={() => {
                                        openToEdit(row);
                                      }}
                                    />
                                  </div>
                                )}
                              </TableCell>
                            </WithRoleAuthorization>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </CustomTable>
                </CustomTableContainer>
                <TablePaginationContainer>
                  <div className="footer-record-container">
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[5, 10, 15]}
                      count={totalUsers}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      labelRowsPerPage={renderLabelRows()}
                      nextIconButtonText={"Próxima página"}
                      backIconButtonText={"Página anterior"}
                      onPageChange={handleChangePage}
                      labelDisplayedRows={({ from, to, count }) =>
                        defaultLabelDisplayedRows(from, to, count)
                      }
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </TablePaginationContainer>
              </>
            ) : (
              <EmptyState
                title="Nenhum usuário encontrado"
                subtitle="Experimente criar um novo usuário clicando no botão"
                image={"assets/imgs/empty-state-imgs/empty-state-img-1.png"}
                buttonName=" + Novo Usuário"
              />
            )}
          </>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1} padding="10px 2px">
        {isLoadingUsers ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "50px",
            }}
          >
            <CircularProgress color="secondary" size={96} />
          </div>
        ) : (
          <>
            {usersDeactive.length ? (
              <>
                <CustomTableContainer
                  maxHeight={responsiveHeight(false)}
                  padding={"0px"}
                >
                  <CustomTable>
                    <CustomTableHeadRoom
                      order={order}
                      orderBy={orderBy}
                      customHeaderItens={headCellsUsers}
                      customOptionalHeaderItens={optionalHeadCellUsers}
                      onRequestSort={handleRequestSort}
                    />

                    <TableBody classes={{ root: classes.thead }}>
                      {home && (
                        <tr>
                          <td colSpan={5}>
                            <CustomIndicator
                              icon="icon_exclamation"
                              tooltip="Você atingiu o limite de usuários do seu plano. Exclua usuários ou contrate um novo plano"
                              key={"indicator-users"}
                              title="Usuários"
                              maximumNumberOfItens={home.max_user}
                              totalItens={allUsers}
                            />
                          </td>
                        </tr>
                      )}
                      {usersDeactive.map((row, index) => {
                        return (
                          <TableRow
                            role="checkbox"
                            tabIndex={-1}
                            key={"deactive-user-" + index}
                            hover
                            style={{
                              backgroundColor: index % 2 === 0 ? "fff" : "#fff",
                              width: "100%",
                              height: "95px ",
                            }}
                          >
                            {row.block && (
                              <TableCell colSpan={4}>
                                <div
                                  style={{
                                    position: "relative",
                                    cursor: "pointer",
                                  }}
                                >
                                  <CustomBlockResource
                                    icon={"icon_padlock"}
                                    title={"Usuário bloqueado"}
                                    direction={"column"}
                                    small
                                  />
                                  <>
                                    <TableCell>
                                      <Typography
                                        style={{
                                          paddingLeft: "10px",
                                          color: "#8B979F",
                                        }}
                                      >
                                        {row.name || "Usuário sem nome"}
                                      </Typography>
                                    </TableCell>

                                    <TableCell>
                                      <Typography
                                        style={{
                                          paddingLeft: "10px",
                                          color: "#8B979F",
                                        }}
                                      >
                                        {row.email}
                                      </Typography>
                                    </TableCell>

                                    <TableCell>
                                      <Typography
                                        style={{
                                          paddingLeft: "10px",
                                          color: "#8B979F",
                                        }}
                                      >
                                        {row?.userType?.name}
                                      </Typography>
                                    </TableCell>

                                    <TableCell>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            paddingLeft: "10px",
                                            color: "#8B979F",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            width: "12rem",
                                            overflow: "hidden",
                                          }}
                                        >
                                          {row.userGroups.length === 0 && (
                                            <>Sem grupo</>
                                          )}

                                          {row.userGroups.length > 0 &&
                                            row.userGroups
                                              .map((item: any) => item.name)
                                              .join(", ")}
                                        </Typography>
                                      </div>
                                    </TableCell>
                                  </>{" "}
                                </div>
                              </TableCell>
                            )}

                            {!row.block && (
                              <>
                                <TableCell>
                                  <Typography
                                    style={{
                                      paddingLeft: "10px",
                                      color: "#8B979F",
                                    }}
                                  >
                                    {row.name || "Usuário sem nome"}
                                  </Typography>
                                </TableCell>

                                <TableCell>
                                  <Typography
                                    style={{
                                      paddingLeft: "10px",
                                      color: "#8B979F",
                                    }}
                                  >
                                    {row.email}
                                  </Typography>
                                </TableCell>

                                <TableCell>
                                  <Typography
                                    style={{
                                      paddingLeft: "10px",
                                      color: "#8B979F",
                                    }}
                                  >
                                    {row?.userType?.name}
                                  </Typography>
                                </TableCell>

                                <TableCell>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        paddingLeft: "10px",
                                        color: "#8B979F",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        width: "12rem",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {row.userGroups.length === 0 && (
                                        <>Sem grupo</>
                                      )}

                                      {row.userGroups.length > 0 &&
                                        row.userGroups
                                          .map((item: any) => item.name)
                                          .join(", ")}
                                    </Typography>
                                  </div>
                                </TableCell>
                              </>
                            )}
                            <WithRoleAuthorization
                              permitedRoles={["Owner", "Administrador"]}
                            >
                              <TableCell
                                style={{ width: "10%", paddingLeft: "0px" }}
                                align="right"
                                key={"deactive-options-user" + index}
                              >
                                <div className={classes.row}>
                                  <CustomDeleteIcon
                                    id="fab-remove-user"
                                    onClick={() => {
                                      toggleActiveUser(row, true, "removeUser");
                                    }}
                                  />
                                  <CustomReactivateIcon
                                    disabled={row.block}
                                    id="fab-reactivate-contact"
                                    onClick={() => {
                                      toggleActiveUser(
                                        row,
                                        false,
                                        "activeOrInactive"
                                      );
                                    }}
                                  />

                                  <CustomEditIcon
                                    id="fab-edit-contact"
                                    onClick={() => {
                                      openToEdit(row);
                                    }}
                                  />
                                </div>
                              </TableCell>
                            </WithRoleAuthorization>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </CustomTable>
                </CustomTableContainer>
                <TablePaginationContainer>
                  <div className="footer-record-container">
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[5, 10, 15]}
                      count={totalUsers}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      labelRowsPerPage={renderLabelRows()}
                      nextIconButtonText={"Próxima página"}
                      backIconButtonText={"Página anterior"}
                      onPageChange={handleChangePage}
                      labelDisplayedRows={({ from, to, count }) =>
                        defaultLabelDisplayedRows(from, to, count)
                      }
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </TablePaginationContainer>
              </>
            ) : (
              <EmptyState
                title="Nenhum usuário encontrado"
                subtitle="Experimente criar um novo usuário clicando no botão"
                image={"assets/imgs/empty-state-imgs/empty-state-img-1.png"}
                buttonName=" + Novo Usuário"
              />
            )}
          </>
        )}
      </CustomTabPanel>
      <CustomConfirmDialog
        onClose={handleCloseModalDelete}
        onCloseFocus={handleCloseModalDelete}
        openDialog={openDialog}
        canUndo={true}
        onConfirmButton={
          actionType === "removeUser"
            ? removeUser
            : isActiveUser
            ? deactiveUserContext
            : activeUserContext
        }
        onCancelButton={handleCloseModalDelete}
        text={
          actionType === "removeUser"
            ? "Você deseja remover o usuário "
            : isActiveUser
            ? `Você deseja desativar o usuário `
            : `Você deseja ativar o usuário `
        }
        roomName={useSelected.name}
      />
    </div>
  );
};

export default UsersPageList;
