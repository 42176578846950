import { useContext, useEffect, useState } from "react";
import { makeStyles, createStyles, Checkbox } from "@material-ui/core";
import HomePageContext from "../../../Context/HomePageContext";
import { Automations } from "../../../../../Models/Automation";

interface Props {
  scene: Automations;
}

const AutomationListItem: React.FunctionComponent<Props> = ({
  scene,
}: Props) => {
  const classes = useStyles();

  const {
    dashboardConfiguration,
    filterValueAutomationList,
    automationsIdsToSave,
    setAutomationsIdsToSave,
    favoriteAutomationsIds,
  } = useContext(HomePageContext);

  const [checkBoxValue, setCheckBoxValue] = useState<boolean>(false);

  useEffect(() => {
    if (dashboardConfiguration.favorite_automations) {
      if (scene.scene_id && favoriteAutomationsIds.includes(scene.scene_id)) {
        setCheckBoxValue(true);
      }
    }
  }, [dashboardConfiguration]);

  const clickCheckAutomations = () => {
    const check = !checkBoxValue;
    setCheckBoxValue(check);
    if (scene.scene_id) {
      if (automationsIdsToSave.includes(scene.scene_id)) {
        setAutomationsIdsToSave(
          automationsIdsToSave.filter((id: string) => {
            return id !== scene.scene_id;
          })
        );
      } else {
        setAutomationsIdsToSave([...automationsIdsToSave, scene.scene_id]);
      }
    }
  };

  return (
    <div
      className={classes.automation}
      style={{
        display: scene.name
          .toLocaleLowerCase()
          .includes(filterValueAutomationList.toLowerCase())
          ? "flex"
          : "none",
      }}
      onClick={clickCheckAutomations}
    >
      <div>
        <div className={classes.listTitle}>{scene.name}</div>
        <div className={classes.listSubTitle}>
          {scene.actions.length > 1
            ? scene.actions.length + " Tarefas"
            : "1 Tarefa"}
        </div>
      </div>
      <div>
        <Checkbox value={scene.scene_id} checked={checkBoxValue} />
      </div>
    </div>
  );
};

export default AutomationListItem;

const useStyles = makeStyles(() =>
  createStyles({
    automation: {
      justifyContent: "space-between",
      backgroundColor: "#FFF",
      borderRadius: "10px",
      margin: "10px 0px",
      minHeight: "75px",
      alignItems: "center",
      padding: "0px 35px",
      cursor: "pointer",
    },
    listTitle: {
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "25px",
      color: "#3E5055",
    },
    listSubTitle: {
      fontSize: "13px",
      fontWeight: 600,
      lineHeight: "18px",
      color: "#8B979F",
    },
  })
);
