import { CircularProgress, createStyles, makeStyles } from "@material-ui/core";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { devicesCustomComponent } from "../../../DevicesConfigurations/CustomDevicesComponent";
import DeviceModel, { DeviceFunctionsModel } from "../../../Models/DeviceModel";
import StatusFunction from "../../../Models/StatusFunctionModel";
import CustomDefaultDevice from "../Devices/CustomDefaultDevice";
import { getFetchSpecifications } from "../../../Services/DevicesService";
import { filterSpecifications } from "../../../Utils/DeviceConfiguration";
import { ErrorMessage } from "../../../Utils/ErrorMessage";

const useStyles = makeStyles(() =>
  createStyles({
    containerLoading: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      alignContent: "center",
      padding: "60px",
      alignItems: "center",
    },
    deviceComponentContainer: {
      height: "100%",
      width: "100%",
      overflow: "auto",
    },
  })
);

interface Props {
  device: DeviceModel;
  status: StatusFunction[];
}

const DeviceDrawerComposite: React.FunctionComponent<Props> = ({
  device,
}: Props) => {
  const classes = useStyles();

  const [specificationsDevice, setSpecificationsDevice] =
    useState<DeviceFunctionsModel>({ category: "", functions: [], status: [] });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setLoading(false);
      setSpecificationsDevice({ category: "", functions: [], status: [] });
    };
  }, []);

  useEffect(() => {
    if (device.id) {
      setLoading(true);

      const fetchSpecifications = async () => {
        try {
          const { data } = await getFetchSpecifications(device.id);
          setSpecificationsDevice(filterSpecifications(device, data));
        } catch (error) {
          const e = error as AxiosError;
          if (e.isAxiosError && e.request.status !== 400) {
            console.error(
              ErrorMessage(e, "Falha ao carregar comandos do dispositivo")
            );
          }
        } finally {
          setLoading(false);
        }
      };

      fetchSpecifications();
    }
  }, [device]);

  if (loading) {
    return (
      <div className={classes.containerLoading}>
        <CircularProgress color="secondary" />
      </div>
    );
  }

  const CustomComponent = devicesCustomComponent[device.product_id];

  return (
    <div className={classes.deviceComponentContainer}>
      {CustomComponent ? (
        <CustomComponent
          status={device.status}
          device={device}
          specificationsDevice={specificationsDevice}
        />
      ) : (
        <CustomDefaultDevice
          device={device}
          loading={loading}
          specificationsDevice={specificationsDevice}
        />
      )}
    </div>
  );
};

export default DeviceDrawerComposite;
