import { useContext, useEffect, useState } from "react";
import {
  Button,
  makeStyles,
  TextField,
  Theme,
  Typography,
  Backdrop,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { login } from "../../Services/AuthService";
import { AxiosError } from "axios";
import history from "../../Services/history";
import GlobalContext from "../../GlobalContext/GlobalContext";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextOfTerm from "../PrivacyTermPage/Components/TextOfTerm";
import DrawerCreateAccount from "./Components/DrawerCreateAccount";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
  },
  containerLogin: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    alignContent: "center",
    alignItems: "center",
    justifyContent: "space-around",
    width: "35vw",
    minWidth: 500,
  },

  form: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    minWidth: "50%",
    justifyContent: "space-evenly",
    minHeight: "55vh",
  },
  containerContentLogin: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    flexDirection: "column",
    flexGrow: 1,
    padding: "100px 50px",
    color: "white",
    backgroundSize: "cover",
  },
  containerButtons: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    marginBottom: "5px",
  },
  textTitle: {
    fontWeight: 700,
    fontFamily: "Open Sans",
    color: "#3E5055",
  },
  textSubtitle: {
    color: "#8B979F",
  },
  textSubtitleModal: {
    color: "#8B979F",
    "&:hover": {
      cursor: "pointer",
    },
  },
  passwordLink: {
    color: "#1890ff",
    cursor: "pointer",
  },
  containerLegends: {
    display: "flex",
    flexDirection: "row",
    color: "white",
    justifyContent: "flex-start",
  },
  textContentTitle: {
    fontWeight: 600,
    fontFamily: "Open Sans",
    color: "#FFF",
    fontSize: "56px",
    marginTop: "40px",
  },
  textContentSubtitle: {
    fontSize: "28px",
    fontWeight: 600,
    marginRight: "64px",
    textTransform: "none",
    lineHeight: 1.5,
  },
  topContainerLogin: {
    height: "50px",
    width: "100%",
    paddingLeft: "30px",
  },
  bottomContainerLogin: {
    height: "50px",
  },
  formControl: {
    minWidth: 120,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const LoginPage: React.FunctionComponent = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [erroLogin, setErrorLogin] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [visibilityPassword, setVisibilityPassword] = useState<boolean>(false);
  const [rand, setRand] = useState<number>(0);
  const [showDrawerCreateAccount, setShowDraweCreaterAccount] = useState(false);
  const { isLogged, setIsLogged } = useContext(GlobalContext);

  const maxValue = 3;

  const backgroundImage: string[] = [
    "bg_login_01.jpg",
    "bg_login_02.jpg",
    "bg_login_03.jpg",
    "bg_login_04.jpg",
  ];

  useEffect(() => {
    setRand(Math.floor(Math.random() * maxValue) + 1);

    return () => {
      setErrorMessage("");
      setErrorLogin(false);
      setOpen(false);
    };
  }, []);

  const classes = useStyles();

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleCreateAccountClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    setShowDraweCreaterAccount(true);
  };

  const sendLogin = () => {
    if (email !== "" && password !== "") {
      setOpen(true);
      setErrorLogin(false);

      login(email, password, "")
        .then((response) => {
          localStorage.setItem("user", JSON.stringify(response.data));
          if (response.data.userInfo.privacy_term) {
            localStorage.setItem(
              "privacy_term",
              JSON.stringify(response.data.userInfo.privacy_term)
            );

            history.push("/select-home");
          } else {
            localStorage.setItem(
              "privacy_term",
              JSON.stringify(response.data.userInfo.privacy_term)
            );
            history.push("/privacy-term");
          }
          setIsLogged(!isLogged);
        })
        .catch((error: AxiosError) => {
          setErrorLogin(true);
          setErrorMessage(error.response?.data.errors[0].message);
          setIsLogged(false);
        })
        .finally(() => setOpen(false));
    } else {
      setErrorLogin(true);
      setErrorMessage("Preencha os campos");
    }
  };

  const sendEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      sendLogin();
    }
  };

  const recoverPassword = () => {
    history.push("recover-password");
  };

  const changeVisibilityPassword = () => {
    setVisibilityPassword(!visibilityPassword);
  };

  const openTermModal = () => {
    setOpenModal(true);
  };

  const closeTermModal = () => {
    setOpenModal(false);
  };

  return (
    <div className={classes.container}>
      <div
        className={classes.containerLogin}
        style={{ display: showDrawerCreateAccount ? "none" : "flex" }}
      >
        <div className={classes.topContainerLogin}></div>
        <div className={classes.form}>
          <img src="assets/imgs/intelbras-logo.png" height="32" />

          <Typography variant="h6" gutterBottom className={classes.textTitle}>
            Faça Login no Izy Manager
          </Typography>

          <Typography
            variant="caption"
            gutterBottom
            className={classes.textSubtitle}
          >
            Por favor, insira suas credenciais de acesso abaixo
          </Typography>

          <TextField
            id="input_emailLogin"
            label="E-mail"
            onChange={handleChangeEmail}
            name="email"
            variant="filled"
            style={{ width: "100%", height: "60px" }}
          />
          <TextField
            id="input_passwordLogin"
            label="Senha"
            type={visibilityPassword ? "text" : "password"}
            onChange={handleChangePassword}
            onKeyDown={(event) => {
              sendEnter(event);
            }}
            name="password"
            variant="filled"
            style={{ width: "100%", height: "60px" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={changeVisibilityPassword}>
                    {visibilityPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <div className={classes.containerButtons}>
            <div className={classes.passwordLink} onClick={recoverPassword}>
              Esqueceu sua senha?
            </div>
          </div>

          <Button
            onClick={sendLogin}
            variant="contained"
            size="large"
            color="secondary"
            style={{ width: "100%", height: "60px", fontSize: "15px" }}
            type="submit"
          >
            Fazer Login
          </Button>

          {erroLogin && (
            <Typography
              variant="overline"
              display="block"
              color="error"
              gutterBottom
            >
              {errorMessage}
            </Typography>
          )}
        </div>

        <Typography
          style={{
            color: "#3E5055",
            fontSize: "15px",
          }}
        >
          Você não tem conta?{" "}
          <a href="#" onClick={handleCreateAccountClick}>
            Criar conta
          </a>
        </Typography>

        <div className={classes.bottomContainerLogin}>
          <Typography
            variant="caption"
            gutterBottom
            className={classes.textSubtitleModal}
            onClick={openTermModal}
          >
            Termos de uso e políticas de privacidade
          </Typography>
        </div>
      </div>

      <div
        className={classes.containerContentLogin}
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(3, 3, 3, 0.9), rgba(255, 0, 150, 0.0)60%), url('assets/imgs/${backgroundImage[rand]}')`,
          minWidth: "65vw",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <Typography
          variant="h3"
          gutterBottom
          className={classes.textContentTitle}
        >
          Conecte-se para o futuro
        </Typography>
        <div className={classes.containerLegends}>
          <Typography
            variant="button"
            gutterBottom
            className={classes.textContentSubtitle}
          >
            Gerencie dispositivos e automações em escala com praticidade
          </Typography>
        </div>
      </div>

      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog open={openModal} onClose={closeTermModal}>
        <DialogContent>
          <TextOfTerm />
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              textTransform: "none",
              marginRight: "15px",
            }}
            onClick={closeTermModal}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <DrawerCreateAccount
        open={showDrawerCreateAccount}
        onClose={() => setShowDraweCreaterAccount(false)}
      />
    </div>
  );
};

export default LoginPage;
