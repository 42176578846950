import RecoverPasswordContext from "./RecoverPasswordContext";
import { ReactNode, useState } from "react";
import history from "../../../Services/history";

interface Props {
  children: ReactNode;
}

const RecoverPasswordProvider = (props: Props): JSX.Element => {
  const { children } = props;
  const [isEmailValid, setEmailValid] = useState<boolean>(false);
  const [isCodeValid, setCodeValid] = useState<boolean>(false);
  const [errorEmailValid, setErrorEmailValid] = useState<boolean>(false);
  const [errorCodeValid, setErrorCodeValid] = useState<boolean>(false);

  const btnBackChange = () => {
    if (!isEmailValid && !isCodeValid) {
      history.push("/login");
    }

    if (isEmailValid && !isCodeValid) {
      setEmailValid(false);
    }

    if (isEmailValid && isCodeValid) {
      setCodeValid(false);
    }
  };

  const changeErrorEmail = () => {
    setErrorEmailValid(false);
  };

  const validateEmail = async () => {
    try {
      //ADICIONAR ENDPOINT PARA VERIRICAR SE EMAIL É VALIDO AQUI
      setEmailValid(true);
    } catch (error) {
      setErrorEmailValid(true);
    }
  };

  const checkIfEmailIsValid = (email: string) => {
    if (email.length > 0) {
      validateEmail();
    } else {
      setErrorEmailValid(true);
    }
  };

  const changeErrorCode = () => {
    setErrorCodeValid(false);
  };

  const validateCode = async (): Promise<void> => {
    try {
      //ADICIONAR ENDPOINT PARA VERIRICAR SE O CODIGO ENVIADO POR EMAIL É VALIDO AQUI
      setCodeValid(true);
    } catch (error) {
      setErrorEmailValid(true);
    }
  };

  const checkIfCodeIsValid = (code: string) => {
    if (code.length > 0) {
      validateCode();
    } else {
      setErrorCodeValid(true);
    }
  };

  const saveNewPassword = (): void => {
    // SALVAR SENHA NA API AQUI
  };

  return (
    <RecoverPasswordContext.Provider
      value={{
        isEmailValid,
        isCodeValid,
        errorEmailValid,
        errorCodeValid,
        btnBackChange,
        changeErrorEmail,
        checkIfEmailIsValid,
        changeErrorCode,
        checkIfCodeIsValid,
        saveNewPassword,
      }}
    >
      {children}
    </RecoverPasswordContext.Provider>
  );
};

export default RecoverPasswordProvider;
