import Skeleton from "@material-ui/lab/Skeleton";
import { CardAlarmItemSkeleton } from "./CardAlarmItem";

type CardsLogsSkeletonProps = {
  elements?: number;
  [key: string]: any;
};

const CardsAlarmsSkeleton: React.FunctionComponent<CardsLogsSkeletonProps> = ({
  elements = 3,
  ...props
}: CardsLogsSkeletonProps) => {
  const items = [];
  for (let i = 0; i < Math.max(elements, 0); i++) {
    items.push(<CardAlarmItemSkeleton key={`card-log-skeleton-${i}`} />);
  }
  return (
    <div {...props}>
      <Skeleton variant="text" width={150} height={36} />
      {items}
    </div>
  );
};

export default CardsAlarmsSkeleton;
