import styled from "styled-components";

interface TablePaginationContainerProps {
  isDrawerOpen?: boolean;
  marginBottom?: string;
}

export const TablePaginationContainer = styled.div<TablePaginationContainerProps>`
  transition: 500ms left;
  display: flex;
  text-transform: none;
  padding: 0px;
  & > div:first-child {
    margin-bottom: ${(props) => props.marginBottom ?? ""};
  }
`;
