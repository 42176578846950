import Tooltip from "@material-ui/core/Tooltip";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { useContext } from "react";
import GlobalContex from "../../GlobalContext/GlobalContext";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 14,
    paddingBottom: 14,
    "&.Mui-selected": {
      backgroundColor: "#007A39",
    },
  },
}));

type CollapedItemProps = {
  id: string;
  title: string;
  target: string;
  imagePath: string;
  activedBy?: string[];
};

const CollapsedItem: React.FunctionComponent<CollapedItemProps> = ({
  id,
  title,
  target,
  imagePath,
  activedBy,
}: CollapedItemProps) => {
  const classes = useStyles();

  const { pathnameUrl } = useContext(GlobalContex);

  const isActived = () => {
    const isPageOpen = pathnameUrl === target;
    const isSubPagesOpen = activedBy?.includes(pathnameUrl);
    return isPageOpen || isSubPagesOpen;
  };

  return (
    <Tooltip title={title} placement="right" aria-label={`${title} tootltip`}>
      <ListItem
        id={id}
        classes={classes}
        button
        component={Link}
        to={target}
        selected={isActived()}
      >
        <ListItemIcon>
          <img
            alt=""
            style={{ marginLeft: "7px", height: "25px" }}
            src={imagePath}
          />
        </ListItemIcon>
      </ListItem>
    </Tooltip>
  );
};

export default CollapsedItem;
