import { AxiosError } from "axios";

type AppErrorMessage = {
  key: string;
  message: string;
};

type AppResponseError = {
  code: string;
  errors: AppErrorMessage[];
  status: number;
  timestamp: string;
};

export const ErrorMessage = (
  error: Error | AxiosError<AppResponseError | any> | unknown,
  defaultMessage: string
): string => {
  const msg = defaultMessage;
  if ((error as AxiosError).isAxiosError) {
    const axiosError: AxiosError<AppResponseError> = error as AxiosError;

    // Mensagem padrão quando erro de página não encontrada evitanto a mensagem default do navegador.
    if (axiosError.request.status === 404) return defaultMessage;

    // trata mensagem de retorno da aplicação, caso não venha conforme o esperado.
    try {
      return axiosError.response?.data.errors[0].message || defaultMessage;
    } catch (e) {
      return defaultMessage;
    }
  }
  return (error as Error).message || msg;
};
