import {
  CircularProgress,
  createStyles,
  InputAdornment,
  makeStyles,
  TextField,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import ListEnvironmentsFromDevices from "../../../../../Components/ListEnvironmentsFromDevices";
import DeviceModel from "../../../../../Models/DeviceModel";
import { getSubDevices } from "../../../../../Services/DevicesService";
import SearchIcon from "@material-ui/icons/Search";
import GlobalContex from "../../../../../GlobalContext/GlobalContext";
import SubDeviceListItem from "../Components/SubDeviceListItem";

interface Props {
  device: DeviceModel;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: "100%",
      padding: "0px 75px",
      overflow: "auto",
    },
    title: {
      fontSize: "23px",
      lineHeight: "31px",
      color: "#3E5055",
      fontWeight: 600,
      textAlign: "center",
      padding: "35px 0px 20px 0px",
    },
    subtitle: {
      paddingBottom: "30px",
      display: "flex",
      justifyContent: "center",
    },
    loadingContainer: {
      textAlign: "center",
    },
    containerContent: {
      height: "calc(100% - 145px)",
    },
    inputSearch: {
      width: "100%",
      marginBottom: "20px",
    },
    input: {
      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#6DCC9A",
      },
      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#00A335",
      },
    },
    containerFilterDevice: {
      display: "flex",
      justifyContent: "space-between",
    },
    nameFilterDevice: {
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "20px",
      color: "#8B979F",
    },
    onlineDevice: {
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "20px",
      textAlign: "right",
      color: "#6DCC9A",
    },
    listDevice: {
      minHeight: "150px",
      overflow: "auto",
      height: "calc(100% - 100px)",
      paddingTop: "10px",
    },
  })
);

const Device_ICA_1001: React.FunctionComponent<Props> = ({ device }: Props) => {
  const classes = useStyles();

  const { devices } = useContext(GlobalContex);

  const [subDevices, setSubdevices] = useState<DeviceModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [subDeviceFilterValue, setSubDeviceFilterValue] = useState<string>("");
  const [subDeviceToShow, setSubDeviceToShow] = useState<DeviceModel[]>([]);

  useEffect(() => {
    const subdevices = async () => {
      const response = await getSubDevices(device.id).finally(() =>
        setIsLoading(false)
      );

      const deviceSubDevice: DeviceModel[] = [];

      devices.forEach((d) => {
        response.data.forEach((subDevice) => {
          if (subDevice.id === d.id) {
            deviceSubDevice.push(d);
          }
        });
      });

      setSubdevices(deviceSubDevice);
      setSubDeviceToShow(deviceSubDevice);
    };
    subdevices();

    return () => {
      setSubdevices([]);
      setIsLoading(true);
      setSubDeviceFilterValue("");
      setSubDeviceToShow([]);
    };
  }, []);

  const updateFilterValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubDeviceFilterValue(event.target.value);
  };

  useEffect(() => {
    setSubDeviceToShow(
      subDevices.filter((device) =>
        device.name.toLowerCase().includes(subDeviceFilterValue.toLowerCase())
      )
    );
  }, [subDeviceFilterValue]);

  return (
    <div className={classes.container}>
      <div className={classes.title}>{device.name}</div>
      <div className={classes.subtitle}>
        {device.environments && (
          <ListEnvironmentsFromDevices environments={device.environments} />
        )}
      </div>
      {isLoading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <div className={classes.containerContent}>
          <TextField
            className={classes.inputSearch}
            value={subDeviceFilterValue}
            id="filter-subdevice"
            placeholder="Pesquisar subdispositivo"
            variant="outlined"
            size="small"
            onChange={updateFilterValue}
            InputProps={{
              className: classes.input,
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon htmlColor="#8B979F" />
                </InputAdornment>
              ),
            }}
          />
          <div className={classes.containerFilterDevice}>
            <h4 className={classes.nameFilterDevice}>
              Dispositivos adicionados
            </h4>
            <span className={classes.onlineDevice}>
              Online: {subDevices.filter((d) => d.online).length}
            </span>
          </div>
          <div className={classes.listDevice}>
            {subDeviceToShow.map((device) => (
              <SubDeviceListItem device={device} key={device.id} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Device_ICA_1001;
