import { DeviceFunctionsModel } from "../../Models/DeviceModel";
import StatusFunction from "../../Models/StatusFunctionModel";
import CustomSwitchCaseBoolean from "../CustomSwitchCaseBoolean";

interface Props {
  id: string;
  status: StatusFunction[];
  functionsDevice: DeviceFunctionsModel;
}

const CustomSwitchDevice: React.FunctionComponent<Props> = (props: Props) => {
  const codes = ["switch"];
  let component = <> </>;
  if (props.status && Array.isArray(props.status)) {
    const status = props.status.filter((s) => {
      const index = codes.findIndex((code) => s.code.includes(code));
      return index > -1 ? true : false;
    });

    if (status !== undefined && status.length > 0) {
      component = <CustomSwitchCaseBoolean status={status} id={props.id} />;
    }
  }

  return component;
};

export default CustomSwitchDevice;
