import { useStripe } from "@stripe/react-stripe-js";
import { useCallback, useEffect, useState } from "react";
import EmptyState from "../../../../Components/EmptyState";
import { planPriceInterval, Plans } from "../../../../Models/Plans";
import history from "../../../../Services/history";
import { getAllPans } from "../../../../Services/PlansService";
import { toREAL } from "../../../../Utils/CurrencyUtils";

interface PaymentDetail {
  currency?: string;
  amount?: number;
  interval?: planPriceInterval;
}

const mesMap: { [value in planPriceInterval]: string } = {
  day: "Diário",
  month: "Mensal",
  year: "Anual",
};

const ContentSuccessPaymentPage: React.FunctionComponent = () => {
  const stripe = useStripe();
  const [paymentDetail, setPaymentDetail] = useState<PaymentDetail>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [plans, setPlans] = useState<Plans[] | undefined>();

  useEffect(() => {
    async function getPlans() {
      const response = await getAllPans();
      setPlans(response.data);
    }
    getPlans();

    return () => {
      setPaymentDetail({});
      setLoading(true);
      setPlans(undefined);
    };
  }, []);

  const urlSearch = new URLSearchParams(window.location.search);
  const planId = urlSearch.get("plan");

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = urlSearch.get("payment_intent_client_secret");

    if (!clientSecret) {
      return;
    }

    const priceInterval = urlSearch.get("interval") as planPriceInterval;
    setPaymentDetail({ interval: priceInterval });

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      setPaymentDetail({
        interval: priceInterval,
        amount: paymentIntent ? paymentIntent.amount / 100 : 0,
        currency: paymentIntent?.currency,
      });
      setLoading(false);
    });
  }, [stripe]);

  const plan = useCallback(() => {
    if (!plans || !paymentDetail.interval) return;
    return plans.find((plan) => plan.id === planId);
  }, [planId, plans, paymentDetail]);

  return (
    <>
      {loading && <p>Carregando...</p>}
      {!loading && (
        <div
          style={{
            paddingTop: "80px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              fontWeight: 700,
              fontSize: "21px",
              lineHeight: "29px",
              color: "#3E5055",
            }}
          >
            Resumo da compra
          </div>
          <div
            style={{
              display: "flex",
              backgroundColor: "#FFF",
              boxShadow: "0px 0px 4px 4px rgba(0, 0, 0, 0.03)",
              borderRadius: "5px",
              marginTop: "45px",
            }}
          >
            <div
              style={{
                padding: "20px 70px 20px 20px",
                borderRight: "1px solid #EBEEEE",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src="/assets/icons/plan-sucess-payment.svg"
                style={{ marginRight: "25px" }}
              />{" "}
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "15px",
                  lineHeight: "20px",
                  color: "#3E5055",
                }}
              >
                Plano {plan()?.name}{" "}
                {paymentDetail?.interval && mesMap[paymentDetail.interval]}
              </div>
            </div>
            <div
              style={{
                padding: "20px 20px 20px 70px",
                borderLeft: "1px solid #EBEEEE",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  marginRight: "200px",
                  fontSize: "15px",
                  lineHeight: "20px",
                  color: "#3E5055",
                }}
              >
                Valor total
              </div>{" "}
              <strong style={{ fontSize: "21px", lineHeight: "29px" }}>
                {toREAL(paymentDetail?.amount)}
              </strong>
            </div>
          </div>
          <div
            style={{
              background: "#EBEEEE",
              borderRadius: "5px",
              padding: "20px 50px",
              maxWidth: "75%",
              marginTop: "25px",
              fontSize: "21px",
              lineHeight: "29px",
              color: "#3E5055",
              textAlign: "center",
            }}
          >
            <strong>Compra concluida!</strong> Assim que o pagamento for
            aprovado você estará habilitado para{" "}
            <strong
              style={{ color: "#00A335", cursor: "pointer" }}
              onClick={() => history.push("/select-home")}
            >
              acessar seu empreendimento
            </strong>{" "}
            no IzyManager!
          </div>
          <EmptyState
            title={"Agradeçemos pela sua confiança!"}
            subtitle={
              <span>
                Você sempre pode conferir seu plano atual indo em{" "}
                <strong
                  style={{ color: "#00A335", cursor: "pointer" }}
                  onClick={() => history.push("/select-home")}
                >
                  Empreendimento
                </strong>
              </span>
            }
            image={"/assets/imgs/empty-state-imgs/sucess-payment-img.png"}
          />
        </div>
      )}
    </>
  );
};

export default ContentSuccessPaymentPage;
