import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => {
  return {
    ItemIconImage: {
      backgroundColor: "#009B8B",
      color: "#FFF",
      width: 66,
      height: 66,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 10,
    },
  };
});

interface Props {
  size?: number;
  color?: string;
}

const ItemIcon: React.FunctionComponent<Props> = ({ size, color }: Props) => {
  const classes = useStyles();
  const s = size ? size : 66;
  const c = color ? color : "#009B8B";
  return (
    <div className={"ItemIcon--root"}>
      <div
        className={classes.ItemIconImage}
        style={{ width: s, height: s, backgroundColor: c }}
      >
        <img src="assets/icons/automacoes.svg" width={s * 0.7} />
      </div>
    </div>
  );
};

export default ItemIcon;
