import memoize, { MemoizedFn } from "memoize-one";
import { merge } from "lodash";
import { devicesCustomConfig } from "../DevicesConfigurations/CustomDevicesComponent";
import { DeviceFunctionsModel } from "../Models/DeviceModel";
import StatusFunction from "../Models/StatusFunctionModel";

export const getDeviceConfiguration: MemoizedFn<
  (this: any, device: { product_id: string }) => any
> = memoize(function (device: { product_id: string }) {
  let generic;
  try {
    generic = devicesCustomConfig["genericdeviceconfig"];
  } catch (error) {
    generic = {};
  }
  let custom;
  try {
    custom = devicesCustomConfig[device.product_id];
  } catch (error) {
    custom = {};
  }
  return merge(generic, custom);
});

export function shouldRenderCommand(
  device: { product_id: string },
  command: string,
  status: StatusFunction[],
  isAutomation = false
): boolean {
  try {
    const configuration = getDeviceConfiguration(device);
    const cmd = configuration.commands_metadata[command];

    if (cmd) {
      if (cmd.hide_automations && isAutomation) {
        return false;
      }
      if (cmd.hide_by_pid && isAutomation) {
        if (cmd.pid_to_hide.includes(device.product_id)) {
          return false;
        } else {
          return true;
        }
      }
      if (!cmd.hide_by_pid && isAutomation) {
        return true;
      }
      if (cmd.conditional_by_sate) {
        const conditions = cmd.conditional_by_sate.split("=="); //only accetps == (We should implemnet regex for more)
        return (
          status.find((s) => s.code === conditions[0])?.value === conditions[1]
        );
      }
    }
    return true;
  } catch (error) {
    return true;
  }
}
export function getCommandLabel(
  device: { product_id: string },
  command: string,
  parent?: string
): string {
  try {
    const configuration = getDeviceConfiguration(device);
    // let cmd = configuration.commands_metadata[parent ? parent : command];

    // if (parent && cmd) cmd = cmd.children_values[command];

    // if (cmd && cmd.label) {
    //   return cmd.label;
    // } else
    if (parent && configuration.generic_translations[`${parent}_${command}`]) {
      return configuration.generic_translations[`${parent}_${command}`];
    } else if (configuration.generic_translations[command]) {
      return configuration.generic_translations[command];
    } else {
      return command.replaceAll("_", " ");
    }
  } catch (error) {
    if (command != null) {
      return command.replaceAll("_", " ");
    }
    return " ";
  }
}

export function getCommandConfiguration(
  device: { product_id: string },
  command: string,
  parent?: string
): any {
  try {
    let cmd =
      getDeviceConfiguration(device).commands_metadata[
        parent ? parent : command
      ];

    if (parent) cmd = cmd.children_values[command];
    return cmd;
  } catch (error) {
    return {};
  }
}

export function filterSpecifications(
  device: { product_id: string },
  specifications: DeviceFunctionsModel
): DeviceFunctionsModel {
  try {
    const deviceConfiguration = getDeviceConfiguration(device);

    specifications.functions = specifications.functions.filter((fn: any) => {
      return deviceConfiguration.hide_commands.indexOf(fn.code) === -1;
    });

    return specifications;
  } catch (error) {
    return specifications;
  }
}
