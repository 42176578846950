import { useEffect, useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Divider, Switch } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { days } from "../../../../Utils/DaysSchedule";
import AutomationPageContext from "../../Context/AutomationPageContext";
import { getCommandLabel } from "../../../../Utils/DeviceConfiguration";
import WithRoleAuthorization from "../../../../Components/WithRoleAuthorization";
import {
  Automations,
  DisplayScheduleModel,
} from "../../../../Models/Automation";
import { formatHours } from "../../../../Utils/FormatHours";
import { ptBR, colorV2Value } from "../../../../Utils/Functions";
import CustomBlockResource from "../../../../Components/CustomBlockResource";

const useStyles = makeStyles(() => ({
  containerIcons: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    margin: "10px 0px",
  },
  root: {
    marginBottom: "10px",
  },
  taskRow: {
    minWidth: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "15px 0px",
  },
  summaryContent: {
    height: "70px",
    "& .MuiAccordionSummary-content": {
      maxWidth: "90%",
    },
  },
  contentTitle: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titles: {
    minWidth: "50px",
  },
  titleLabel: {
    fontSize: "15px",
    fontWeight: 700,
    lineHeight: "20px",
    color: "#3E5055",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  subTitleLabel: {
    fontSize: "13px",
    fontWeight: 600,
    lineHeight: "18px",
    color: "#8B979F",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  taskLabel: {
    fontSize: "13px",
    fontWeight: 700,
    lineHeight: "18px",
    color: "#3E5055",
  },
  taskRowLabel: {
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#8B979F",
    alignSelf: "center",
  },
  taskRowLabel2: {
    fontSize: "15px",
    fontWeight: 600,
    lineHeight: "20px",
    color: "#8B979F",
  },
  automationsIcons: {
    maxHeight: "35px",
    maxWidth: "35px",
    marginRight: "15px",
  },
  conditionType: {
    display: "flex",
    alignItems: "center",
  },
}));

interface PropsAutomation {
  automation: Automations;
}

interface DateValue {
  initial: string;
  value: string;
}

const CustomAutomation: React.FunctionComponent<PropsAutomation> = (
  props: PropsAutomation
) => {
  const classes = useStyles();
  const [toggleValue, setToggleValue] = useState<boolean>(
    props.automation.enabled
  );
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const {
    setToggleAutomation,
    setIsToggleOpen,
    setTextFieldAutomacao,
    setActions,
    setConditions,
    getDeviceName,
    getStatusConditionSub,
    setIsToEdit,
    setIsAutomation,
    setIsScene,
    automationId,
    setAutomationsToEdit,
    updateDashboardAutomations,
    setActionsTemp,
    setIsBlockedAutomation,
  } = useContext(AutomationPageContext);

  useEffect(() => {
    if (props.automation.automation_id === automationId) {
      setToggleValue(props.automation.enabled);
    }
  }, [updateDashboardAutomations]);

  const toggleAutomation = () => {
    if (!props.automation.automation_id) {
      return;
    }
    setToggleAutomation(props.automation.automation_id, !toggleValue);
    setToggleValue(!toggleValue);
  };

  const expandAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  const openDrawerToEdit = () => {
    setIsToggleOpen(true);
    setIsToEdit(true);
    setIsAutomation(true);
    setIsScene(false);
    setAutomationsToEdit(props?.automation);
    setTextFieldAutomacao(props?.automation?.name);
    setActions(props?.automation?.actions);
    setActionsTemp(props?.automation?.actions);
    setConditions(props?.automation?.conditions);
    setIsBlockedAutomation(props?.automation?.block);
  };

  return (
    <div className={classes.root}>
      <div
        style={{
          position: "relative",
        }}
      >
        {props.automation.block && (
          <CustomBlockResource
            icon={"icon_padlock"}
            title={"Automação bloqueada"}
            direction={"column"}
            onClick={(e: any) => {
              openDrawerToEdit();
              e.stopPropagation();
            }}
          />
        )}

        <Accordion
          expanded={isExpanded}
          onChange={expandAccordion}
          style={{
            margin: "0px",
            borderRadius: isExpanded ? "10px" : "10px 10px 0px 0px",
            boxShadow: isExpanded
              ? "0px 10px 20px 0px #00A6561A"
              : "0px 5px 10px rgba(62, 80, 85, 0.1)",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.summaryContent}
          >
            <div className={classes.contentTitle}>
              <div className={classes.titles}>
                <WithRoleAuthorization
                  permitedRoles={["Owner", "Administrador"]}
                  alternative={
                    <Typography className={classes.titleLabel}>
                      {props.automation.name}
                    </Typography>
                  }
                >
                  <Typography
                    className={classes.titleLabel}
                    onClick={(e) => {
                      openDrawerToEdit();
                      e.stopPropagation();
                    }}
                  >
                    {props.automation.name}
                  </Typography>
                </WithRoleAuthorization>

                <Typography className={classes.subTitleLabel}>
                  {props.automation.actions.length > 1
                    ? props.automation.actions.length + " Tarefas"
                    : "1 Tarefa"}
                </Typography>
              </div>
              <WithRoleAuthorization permitedRoles={["Owner", "Administrador"]}>
                <Switch
                  aria-label="Acknowledge"
                  checked={toggleValue}
                  onClick={(event) => {
                    event.stopPropagation();
                    toggleAutomation();
                  }}
                />
              </WithRoleAuthorization>
            </div>
          </AccordionSummary>
          <Divider />
          <AccordionDetails style={{ maxHeight: "250px", overflowY: "auto" }}>
            <div style={{ width: "100%" }}>
              <Typography className={classes.taskLabel}>Condição</Typography>
              <div>
                {props.automation.conditions.map(
                  (condition: any, index: number) =>
                    ({
                      "1": (
                        <div
                          className={classes.containerIcons}
                          key={"automation-condition-item-" + index}
                        >
                          <div className={classes.conditionType}>
                            <img
                              src="assets/icons/lamp (1) 1.svg"
                              className={classes.automationsIcons}
                              style={{ marginLeft: "5px" }}
                            />
                            <div className={classes.taskRowLabel}>
                              Quando o status do{" "}
                              {getDeviceName(condition.entity_id)} muda
                            </div>
                          </div>
                          <div className={classes.taskRowLabel}>
                            {getStatusConditionSub(condition)}
                          </div>
                        </div>
                      ),
                      "3": (
                        <div
                          className={classes.containerIcons}
                          key={"automation-condition-item-" + index}
                        >
                          <div className={classes.conditionType}>
                            <img
                              src="assets/icons/sun 2.svg"
                              className={classes.automationsIcons}
                              style={{ marginLeft: "5px" }}
                            />
                            <div className={classes.taskRowLabel}>
                              Quando o tempo muda
                            </div>
                          </div>
                          <div className={classes.taskRowLabel}>
                            {condition.display.code}{" "}
                            {condition.display.operator}{" "}
                            <span className={classes.taskRowLabel2}>
                              {condition.display.value}
                            </span>
                          </div>
                        </div>
                      ),
                      "6": (
                        <div
                          className={classes.containerIcons}
                          key={"automation-condition-item-" + index}
                        >
                          <div className={classes.conditionType}>
                            <img
                              src="assets/icons/clock (1) 1.svg"
                              className={classes.automationsIcons}
                              style={{ marginLeft: "5px" }}
                            />
                            <div className={classes.taskRowLabel}>Agenda</div>
                          </div>
                          <div
                            className={classes.taskRowLabel}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "end",
                            }}
                          >
                            <strong>
                              {formatHours(
                                (condition.display as DisplayScheduleModel).time
                              )}
                            </strong>
                            <span>
                              {condition.entity_type === 6 &&
                                days
                                  ?.map((d: DateValue, i: number) => {
                                    return (
                                      condition.display as DisplayScheduleModel
                                    ).loops[i] !== "0"
                                      ? d.value
                                      : "";
                                  })
                                  ?.filter((d) => d !== "")
                                  ?.join(", ")}
                            </span>
                          </div>
                        </div>
                      ),
                      "15": (
                        <div
                          className={classes.containerIcons}
                          key={"automation-condition-item-" + index}
                        >
                          <div>Quando a localização muda</div>
                          <div>
                            {condition.display.code}{" "}
                            {condition.display.operator}{" "}
                            {condition.display.value}
                          </div>
                        </div>
                      ),
                    }[String(condition.entity_type)])
                )}
              </div>

              <Typography className={classes.taskLabel}>Tarefa</Typography>

              <div style={{ marginTop: "15px" }}>
                {props?.automation?.actions.map(
                  (action: any, index: number) => {
                    const isActionDevice = action.action_executor === "dpIssue";
                    const isActionScene =
                      action.action_executor === "ruleTrigger";
                    const isActionAutomation =
                      action.action_executor === "ruleEnable" ||
                      action.action_executor === "ruleDisable";
                    const key = Object.keys(action?.executor_property ?? []);

                    if (isActionAutomation) {
                      return (
                        <div
                          className={classes.taskRow}
                          key={"automation-action-item-" + index}
                        >
                          <div style={{ display: "flex" }}>
                            <img
                              src="assets/icons/automation-temp-icon.svg"
                              className={classes.automationsIcons}
                            />
                            <Typography className={classes.taskRowLabel}>
                              {action.actionName}
                            </Typography>
                          </div>

                          <Typography
                            className={classes.taskRowLabel2}
                            style={{ alignSelf: "center" }}
                          >
                            {action.action_executor === "ruleEnable"
                              ? "Habilitar"
                              : "Desabilitar"}
                          </Typography>
                        </div>
                      );
                    } else if (isActionScene) {
                      return (
                        <div
                          className={classes.taskRow}
                          key={"automation-action-item-" + index}
                        >
                          <div style={{ display: "flex" }}>
                            <img
                              src="assets/icons/tap-2.svg"
                              className={classes.automationsIcons}
                              style={{ height: "30px", width: "30px" }}
                            />
                            <Typography className={classes.taskRowLabel}>
                              {action.actionName}
                            </Typography>
                          </div>

                          <Typography className={classes.taskRowLabel2}>
                            Executar
                          </Typography>
                        </div>
                      );
                    } else if (isActionDevice) {
                      return (
                        <div
                          className={classes.taskRow}
                          key={"automation-action-item-" + index}
                        >
                          <div style={{ display: "flex" }}>
                            <img
                              src={action.device.icon}
                              className={classes.automationsIcons}
                            />
                            <Typography className={classes.taskRowLabel}>
                              {action.actionName}
                            </Typography>
                          </div>

                          <Typography
                            component="div"
                            className={classes.taskRowLabel}
                            style={{
                              display:
                                key[0] === "colour_data_v2" ? "flex" : "",
                            }}
                          >
                            {getCommandLabel(action.device.productName, key[0])}
                            :
                            <span className={classes.taskRowLabel2}>
                              {action.executor_property[key[0]] === true ? (
                                " ON"
                              ) : action.executor_property[key[0]] === false ? (
                                " OFF"
                              ) : key[0] === "colour_data_v2" ? (
                                <div
                                  style={{
                                    border: "1px solid grey",
                                    marginLeft: "10px",
                                    backgroundColor: colorV2Value(
                                      action.executor_property[key[0]]
                                    ),
                                    height: "20px",
                                    width: "20px",
                                    borderRadius: "50%",
                                  }}
                                ></div>
                              ) : (
                                " " +
                                ptBR(
                                  action.executor_property[key[0]].toString()
                                )
                              )}
                            </span>
                          </Typography>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className={classes.taskRow}
                          key={"automation-action-item-" + index}
                        >
                          <Typography className={classes.taskRowLabel}>
                            Sem tarefas cadastradas
                          </Typography>
                        </div>
                      );
                    }
                  }
                )}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <div
          style={{
            height: isExpanded ? "0px" : "100px",
            backgroundColor: "#FFF",
            borderRadius: "0px 0px 10px 10px",
            padding: isExpanded ? "0px" : "15px",
            display: "flex",
            transition: "height .3s",
            overflow: "hidden",
          }}
        >
          <div style={{ maxWidth: "100px" }}>
            <Typography
              className={classes.taskLabel}
              style={{ textAlign: "center" }}
            >
              Condição
            </Typography>
            <div style={{ display: "flex", overflowY: "auto" }}>
              {props.automation.conditions.map(
                (condition: any, index: number) =>
                  ({
                    "1": (
                      <div
                        className={classes.containerIcons}
                        style={{ width: "auto" }}
                        key={"automation-condition-item-card-" + index}
                      >
                        <div className={classes.conditionType}>
                          <img
                            src="assets/icons/lamp (1) 1.svg"
                            className={classes.automationsIcons}
                            style={{ marginLeft: "5px" }}
                          />
                        </div>
                      </div>
                    ),
                    "3": (
                      <div
                        className={classes.containerIcons}
                        key={"automation-condition-item-card-" + index}
                      >
                        <div className={classes.conditionType}>
                          <img
                            src="assets/icons/sun 2.svg"
                            className={classes.automationsIcons}
                            style={{ marginLeft: "5px" }}
                          />
                        </div>
                      </div>
                    ),
                    "6": (
                      <div
                        className={classes.containerIcons}
                        key={"automation-condition-item-card-" + index}
                      >
                        <div className={classes.conditionType}>
                          <img
                            src="assets/icons/clock (1) 1.svg"
                            className={classes.automationsIcons}
                            style={{ marginLeft: "5px" }}
                          />
                        </div>
                      </div>
                    ),
                    "15": (
                      <div
                        className={classes.containerIcons}
                        key={"automation-condition-item-card-" + index}
                      ></div>
                    ),
                  }[String(condition.entity_type)])
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "50px",
            }}
          >
            <img src="assets/icons/arrow-automations.svg" />
          </div>
          <div style={{ width: "calc(100% - 120px)" }}>
            <Typography className={classes.taskLabel}>Tarefas</Typography>
            <div
              style={{ display: "flex", marginTop: "10px", overflowY: "auto" }}
            >
              {props?.automation?.actions.map((action: any, index: number) => {
                const isActionDevice = action.action_executor === "dpIssue";
                const isActionScene = action.action_executor === "ruleTrigger";
                const isActionAutomation =
                  action.action_executor === "ruleEnable" ||
                  action.action_executor === "ruleDisable";

                if (isActionAutomation) {
                  return (
                    <div key={"automation-action-item-card" + index}>
                      <div style={{ display: "flex" }}>
                        <img
                          src="assets/icons/automation-temp-icon.svg"
                          className={classes.automationsIcons}
                        />
                      </div>
                    </div>
                  );
                } else if (isActionScene) {
                  return (
                    <div key={"automation-action-item-card" + index}>
                      <img
                        src="assets/icons/tap-2.svg"
                        className={classes.automationsIcons}
                        style={{ height: "30px", width: "30px" }}
                      />
                    </div>
                  );
                } else if (isActionDevice) {
                  return (
                    <div key={"automation-action-item-card" + index}>
                      <div style={{ display: "flex" }}>
                        <img
                          src={action.device.icon}
                          className={classes.automationsIcons}
                        />
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div key={"automation-action-item-card" + index}>
                      <Typography className={classes.taskRowLabel}>
                        Sem tarefas cadastradas
                      </Typography>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomAutomation;
