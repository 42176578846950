import Tooltip from "@material-ui/core/Tooltip";
import { Indicator } from "./styles";

interface Props {
  maximumNumberOfItens: number;
  totalItens: number;
  title: string;
  icon: string;
  tooltip: string;
}

const CustomIndicator: React.FunctionComponent<Props> = ({
  maximumNumberOfItens,
  totalItens,
  title,
  icon,
  tooltip,
}: Props) => {
  const REACHED_MAXIMUM_NUMBER: boolean = totalItens > maximumNumberOfItens;

  const zIndex = Number.POSITIVE_INFINITY;

  return (
    <Indicator>
      {icon && REACHED_MAXIMUM_NUMBER && (
        <Tooltip
          title={tooltip}
          placement="top"
          arrow
          style={{
            zIndex: zIndex,
          }}
        >
          <img
            src={`assets/icons/${icon}.svg`}
            alt={title}
            style={{
              display: "inline",
            }}
          />
        </Tooltip>
      )}
      <span
        style={{
          margin: "0px 10px",
        }}
      >
        {title}
      </span>
      <span
        style={{
          fontWeight: REACHED_MAXIMUM_NUMBER ? "bold" : "normal",
        }}
      >
        {totalItens}
      </span>
      /{maximumNumberOfItens}
    </Indicator>
  );
};

export default CustomIndicator;
