import TextField from "@material-ui/core/TextField";
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { Button, IconButton, InputAdornment } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import PasswordStrengthIndicator from "../../../../Components/PasswordStrengthIndicator";

export interface FormAccountData {
  name: string;
  email: string;
  password: string;
  repeatPassword: string;
}

type FormAccountProps = {
  onSubmit: (
    event: FormEvent,
    data: Omit<FormAccountData, "repeatPassword">
  ) => Promise<boolean>;
};

const form: CSSProperties = {
  marginTop: "10px",
  marginBottom: "10px",
  width: "60%",
};

const button: CSSProperties = {
  textTransform: "none",
  fontSize: "15px",
  fontWeight: "bold",
  padding: "12px",
  marginTop: "10px",
};

const inputMargin: CSSProperties = {
  marginBottom: "8px",
};

const FormAccount: React.FunctionComponent<FormAccountProps> = ({
  onSubmit,
}: FormAccountProps) => {
  const { showAlert } = useContext(GlobalContex);

  const [formData, setFormData] = useState({
    email: "",
    name: "",
    password: "",
    repeatPassword: "",
  });
  const [isSubmmiting, setIsSubmmiting] = useState<boolean>(false);
  const [visibilityPassword, setVisibilityPassword] = useState<boolean>(false);
  const [visibilityPassword2, setVisibilityPassword2] =
    useState<boolean>(false);
  const [isPasswordStrong, setIsPasswordStrong] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setIsSubmmiting(false);
    };
  }, []);

  const handleChange = (
    attrName: string,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [attrName]: event.target.value });
  };

  const validate = () => {
    if (formData.name.trim() === "") {
      throw new Error("Informe o nome de usuário");
    }

    if (formData.email.trim() === "") {
      throw new Error("Informe um e-mail");
    }

    if (formData.password.trim() === "") {
      throw new Error("Informe uma senha");
    }

    if (formData.password != formData.repeatPassword) {
      throw new Error("Senha e confirmação de senha não confere");
    }

    if (!isPasswordStrong) {
      throw new Error("Informe uma senha forte");
    }
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    try {
      validate();
    } catch (e) {
      let message = "Formulário inválido";
      if (e instanceof Error) message = e.message;
      showAlert(message, "error");
      return false;
    }

    setIsSubmmiting(true);
    const submitted = await onSubmit(event, formData);
    setIsSubmmiting(false);

    return submitted;
  };

  return (
    <form onSubmit={handleSubmit} autoComplete="off" style={form}>
      <TextField
        required
        label="Nome de usuário"
        autoFocus
        variant="filled"
        id="txt-username"
        name="username"
        style={inputMargin}
        value={formData.name}
        fullWidth
        inputProps={{
          maxLength: 50,
        }}
        onChange={(event) => {
          handleChange("name", event);
        }}
      />

      <TextField
        required
        label="E-mail"
        variant="filled"
        type="email"
        id="txt-email"
        name="email"
        style={inputMargin}
        value={formData.email}
        fullWidth
        inputProps={{
          maxLength: 100,
        }}
        onChange={(event) => {
          handleChange("email", event);
        }}
      />

      <TextField
        required
        label="Senha"
        variant="filled"
        id="txt-user-password"
        name="password"
        style={inputMargin}
        value={formData.password}
        fullWidth
        inputProps={{
          maxLength: 50,
        }}
        type={visibilityPassword ? "text" : "password"}
        onChange={(event) => {
          handleChange("password", event);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setVisibilityPassword(!visibilityPassword)}
              >
                {visibilityPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <PasswordStrengthIndicator
        password={formData.password}
        onChangeScore={(score, validated) => {
          setIsPasswordStrong(validated);
        }}
      />

      <TextField
        required
        label="Repita a senha"
        variant="filled"
        id="txt-user-repeat-password"
        name="repeat-password"
        style={inputMargin}
        value={formData.repeatPassword}
        fullWidth
        inputProps={{
          maxLength: 50,
        }}
        type={visibilityPassword2 ? "text" : "password"}
        onChange={(event) => {
          handleChange("repeatPassword", event);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setVisibilityPassword2(!visibilityPassword2)}
              >
                {visibilityPassword2 ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Button
        id="btn-create-account"
        color="secondary"
        variant="contained"
        fullWidth={true}
        type="submit"
        style={button}
        disabled={isSubmmiting}
      >
        {isSubmmiting ? "Criando conta..." : "Confirmar"}
      </Button>
    </form>
  );
};

export default FormAccount;
