import {
  IconButton,
  InputAdornment,
  TextField,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";

interface Props {
  placeHolder: string;
  id: string;
  isFilterInFront: boolean;
  arrayToFilter?: any;
  propertyToFilter?: string;
  className?: string;
  setStateFuncion?: (value: any) => void;
  setStateLoading?: (value: boolean) => void;
  setLoadingFunction?: (value: string) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#6DCC9A",
      },
      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#00A335",
      },
    },
  })
);

const CustomFilter: React.FunctionComponent<Props> = ({
  placeHolder,
  id,
  isFilterInFront,
  arrayToFilter,
  propertyToFilter,
  className,
  setStateFuncion,
  setStateLoading,
  setLoadingFunction,
}: Props) => {
  const classes = useStyles();
  const [typingTimer, setTypingTimer] = useState<NodeJS.Timeout>(
    setTimeout(() => {
      return;
    }, 0)
  );

  const doneTyping = (value: string) => {
    if (
      isFilterInFront &&
      arrayToFilter &&
      propertyToFilter &&
      setStateFuncion
    ) {
      const itemsToShow = arrayToFilter.filter((item: any) =>
        item[propertyToFilter].toLowerCase().includes(value.toLowerCase())
      );
      setStateFuncion(itemsToShow);
    }

    if (!isFilterInFront && setLoadingFunction) {
      setLoadingFunction(value);
    }
  };

  const changeValueFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setStateLoading) {
      setStateLoading(true);
    }
    clearTimeout(typingTimer);
    setTypingTimer(
      setTimeout(() => {
        doneTyping(event.target.value);
      }, 1000)
    );
  };

  return (
    <TextField
      className={className ? className : ""}
      placeholder={placeHolder}
      id={id}
      onChange={changeValueFilter}
      InputProps={{
        className: classes.input,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      variant="outlined"
    />
  );
};

export default CustomFilter;
