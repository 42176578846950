import api from "./Api";
import authHeader from "./UserService";
import { getCurrentUser } from "./AuthService";
import { AxiosResponse } from "axios";
import {
  EnterpriseModel,
  EnterpriseResponseModel,
  RegisterEnterpriseModel,
} from "../Models/EnterpriseModel";

export const createNewEnterpriseStepOne = (
  formData: FormData
): Promise<AxiosResponse<EnterpriseResponseModel>> => {
  return api.post(`/homes/home/${getCurrentUser().userInfo.id}`, formData, {
    headers: authHeader(),
  });
};

export const createNewEnterpriseStepTwo = (
  home_id: string,
  objToSend: RegisterEnterpriseModel
): Promise<AxiosResponse<EnterpriseResponseModel>> => {
  return api.post(`/homes/${home_id}/payment-gateway`, objToSend, {
    headers: authHeader(),
  });
};

export const updateEnterpriseStepOne = (
  home_id: string,
  formData: FormData
): Promise<AxiosResponse<EnterpriseResponseModel>> => {
  return api.put(`/homes/${home_id}`, formData, {
    headers: authHeader(),
  });
};

export const updateEnterpriseStepTwo = (
  home_id: string,
  objToSend: RegisterEnterpriseModel
): Promise<AxiosResponse<EnterpriseResponseModel>> => {
  return api.put(`/homes/${home_id}/payment-gateway`, objToSend, {
    headers: authHeader(),
  });
};

export const removeImgEnterprise = (
  home_id: string
): Promise<AxiosResponse<boolean>> => {
  return api.delete(`/homes/${home_id}/image`, {
    headers: authHeader(),
  });
};

export const getEnterpriseById = (
  home_id: string
): Promise<AxiosResponse<EnterpriseModel>> => {
  return api.get(`/homes/${home_id}`, {
    headers: authHeader(),
  });
};
