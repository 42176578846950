import MiniDrawer from "../../Components/CustomMiniDrawer";
import ContentTrialPlanPage from "./components/Content";

const TrialPlanPage: React.FunctionComponent = () => {
  return (
    <MiniDrawer showSideMenu={false} withoutSideMenu={true} bgColor={"#F7F7F7"}>
      <ContentTrialPlanPage />
    </MiniDrawer>
  );
};

export default TrialPlanPage;
