import TokenService from "./TokenService";
import api from "./Api";
import {
  SyncUserModel,
  UserOptionsModel,
  UserPaginatedModel,
  UserToEdit,
  UserType,
} from "../Models/UserModel";
import { UserAccountModel } from "../Models/UserAccountModel";
import { AxiosResponse } from "axios";
import { HomeUserModel } from "../Models/HomeUserModel";

export default function authHeader():
  | { Authorization: string }
  | { Authorization?: undefined } {
  const user = TokenService.getUser();

  if (user && user.token) {
    return { Authorization: "Bearer " + TokenService.getLocalAccessToken() };
  } else {
    return {};
  }
}

export const getAllUsers = async (): Promise<
  AxiosResponse<UserPaginatedModel>
> => {
  return await api.get(`/homes/${getHomeIDSelect()}/users`, {
    headers: authHeader(),
  });
};

export const getUsersActive = async (
  pageNumber: number,
  pageSize: number,
  options?: UserOptionsModel
): Promise<AxiosResponse<UserPaginatedModel>> => {
  return await api.get(
    `/homes/${getHomeIDSelect()}/users?isActive=true&pageNumber=${pageNumber}&pageSize=${pageSize}${
      options?.filter ? '&filter=name=="*' + options.filter + '*"' : ""
    }${options?.sortBy ? "&sortBy=" + options.sortBy : ""}${
      options?.sortDir ? "&sortDir=" + options.sortDir : ""
    }`,
    {
      headers: authHeader(),
    }
  );
};

export const getUsersNonActive = async (
  pageNumber: number,
  pageSize: number,
  options?: UserOptionsModel
): Promise<AxiosResponse<UserPaginatedModel>> => {
  return await api.get(
    `/homes/${getHomeIDSelect()}/users?isActive=false&pageNumber=${pageNumber}&pageSize=${pageSize}${
      options?.filter ? '&filter=name=="*' + options.filter + '*"' : ""
    }${options?.sortBy ? "&sortBy=" + options.sortBy : ""}${
      options?.sortDir ? "&sortDir=" + options.sortDir : ""
    }`,
    {
      headers: authHeader(),
    }
  );
};

export const createUser = async (
  user: UserAccountModel,
  verificationCode: string
): Promise<AxiosResponse<string>> => {
  const params = {
    name: user.nick_name,
    email: user.username,
    country_code: user.country_code,
    verificationCode: verificationCode,
    password: user.password,
    userType: user.userType,
    userGroups: user.userGroups,
  };
  return await api.post(`/homes/${getHomeIDSelect()}/users`, params, {
    headers: authHeader(),
  });
};

export const createUserAccount = async (
  user: UserAccountModel,
  verificationCode: string
): Promise<AxiosResponse<string>> => {
  const params = {
    country_code: user.country_code,
    nick_name: user.nick_name,
    username: user.username,
    password: user.password,
    verificationCode: verificationCode,
  };
  return await api.post(`/users/user`, params);
};

export const synchronizeUser = async (
  user: SyncUserModel
): Promise<AxiosResponse<string>> => {
  return await api.post(`/homes/${getHomeIDSelect()}/users-sync`, user, {
    headers: authHeader(),
  });
};

export const upadateUser = async (
  user: UserToEdit,
  uid: string
): Promise<AxiosResponse<boolean>> => {
  return await api.put(`/homes/${getHomeIDSelect()}/users/${uid}`, user, {
    headers: authHeader(),
  });
};

export const deleteUser = async (
  uid: string
): Promise<AxiosResponse<boolean>> => {
  return await api.delete(`/homes/${getHomeIDSelect()}/users/${uid}`, {
    headers: authHeader(),
  });
};

export const activeUser = async (
  uid: string
): Promise<AxiosResponse<boolean>> => {
  return await api.put(`/homes/${getHomeIDSelect()}/users/${uid}/active`, {
    headers: authHeader(),
  });
};

export const deactiveUser = async (
  uid: string
): Promise<AxiosResponse<boolean>> => {
  return await api.put(`/homes/${getHomeIDSelect()}/users/${uid}/deactive`, {
    headers: authHeader(),
  });
};

export const getUserTypes = async (): Promise<AxiosResponse<UserType[]>> => {
  return await api.get(`/users/types`, { headers: authHeader() });
};

export const validateEmailUser = async (
  email: string
): Promise<AxiosResponse<boolean>> => {
  return await api.get(`users/email/${email}/in_use`, {
    headers: authHeader(),
  });
};

export const validateEmailUserIzyManager = async (
  email: string
): Promise<AxiosResponse<boolean>> => {
  return await api.get(
    `/homes/${getHomeIDSelect()}/users/email/${email}/in_use`,
    {
      headers: authHeader(),
    }
  );
};

export const getHomesById = async (
  uid: string
): Promise<AxiosResponse<HomeUserModel[]>> => {
  return await api.get(`users/${uid}/homes`, {
    headers: authHeader(),
  });
};

export const getHomeIDSelect = (): string | null => {
  return localStorage.getItem("homeID");
};

export const getHomeNameSelect = (): string => {
  return localStorage.getItem("homeSelectName") || "";
};

export const getRoomNameSelect = (): string => {
  return localStorage.getItem("roomSelectName") || "";
};

export const getPlaceNameSelect = (): string => {
  return localStorage.getItem("placeSelectName") || "";
};

export const verifyEmail = (email: string): Promise<AxiosResponse<boolean>> => {
  const params = {
    email: email,
  };
  return api.post(`/users/validate-email`, params);
};
