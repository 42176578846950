import { AxiosResponse } from "axios";
import {
  NotDisturbSchedulesModel,
  NotificationConfigModel,
} from "../Models/NotificationConfigModel";
import api from "./Api";
import { getCurrentUser } from "./AuthService";
import authHeader, { getHomeIDSelect } from "./UserService";

export const getNotificationConfig = (): Promise<
  AxiosResponse<NotificationConfigModel>
> => {
  return api.get(
    `/homes/${getHomeIDSelect()}/users/${
      getCurrentUser().userInfo.id
    }/configuration/notification`,
    {
      headers: authHeader(),
    }
  );
};

export const saveNotificationConfigService = (
  notificationConfig: NotificationConfigModel
): Promise<AxiosResponse<void>> => {
  return api.put(
    `/homes/${getHomeIDSelect()}/users/${
      getCurrentUser().userInfo.id
    }/configuration/notification`,
    notificationConfig,
    {
      headers: authHeader(),
    }
  );
};

export const saveNotificationService = (
  notificationConfig: NotDisturbSchedulesModel
): Promise<AxiosResponse<NotDisturbSchedulesModel>> => {
  return api.post(
    `/homes/${getHomeIDSelect()}/users/${
      getCurrentUser().userInfo.id
    }/configuration/notification/not_disturb`,
    notificationConfig,
    {
      headers: authHeader(),
    }
  );
};

export const removeNotificationService = (
  id: number
): Promise<AxiosResponse<void>> => {
  return api.delete(
    `/homes/${getHomeIDSelect()}/users/${
      getCurrentUser().userInfo.id
    }/configuration/notification/not_disturb/${id}`,
    {
      headers: authHeader(),
    }
  );
};
