import Drawer from "@material-ui/core/Drawer";
import React, { useContext, useEffect } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  IconButton,
  Divider,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import HomePageContext from "../../Context/HomePageContext";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SearchIcon from "@material-ui/icons/Search";
import AutomationListItem from "./AutomationListItem";

const AutomationsFavoritedDrawer: React.FunctionComponent = () => {
  const classes = useStyles();

  const {
    openAutomationsFavoritedDrawer,
    toggleAutomationsFavoritedDrawer,
    scenes,
    isLoadingScenes,
    filterValueAutomationList,
    setFilterValueAutomationList,
    setOpenAutomationsFavoritedDrawer,
    setAutomationsIdsToSave,
    saveAutomationList,
    favoriteAutomationsIds,
  } = useContext(HomePageContext);

  const changeValueFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValueAutomationList(event.target.value);
  };

  useEffect(() => {
    if (openAutomationsFavoritedDrawer) {
      setAutomationsIdsToSave(favoriteAutomationsIds);
    }
  }, [openAutomationsFavoritedDrawer]);

  return (
    <div>
      <React.Fragment key={"right"}>
        <Drawer
          anchor={"right"}
          open={openAutomationsFavoritedDrawer}
          onClose={toggleAutomationsFavoritedDrawer(false)}
        >
          <div className={classes.containerAutomationDrawer}>
            <div className={classes.btnBackContainer}>
              <IconButton
                aria-label="delete"
                className={classes.btnBackIcon}
                size="small"
                onClick={toggleAutomationsFavoritedDrawer(false)}
              >
                <ArrowBackIosIcon fontSize="inherit" />
              </IconButton>
              Voltar
            </div>
            <Divider />
            <div className={classes.containerHeader}>
              <div className={classes.headerTitle}>
                Adicionar atalhos de automações
              </div>
              <div>
                <OutlinedInput
                  margin="dense"
                  placeholder="Pesquisar por nome"
                  id="search-automations-for-name"
                  value={filterValueAutomationList}
                  onChange={changeValueFilter}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
            </div>
            <Divider />
            {isLoadingScenes ? (
              <div className={classes.containerLoading}>
                <CircularProgress
                  size={30}
                  color="secondary"
                  style={{ marginLeft: "10px" }}
                />
              </div>
            ) : (
              <>
                {scenes.length > 0 ? (
                  <>
                    <div className={classes.listAutomationsContainer}>
                      {scenes.map((s, index) => {
                        return (
                          <AutomationListItem
                            scene={s}
                            key={"automation-list-item-" + index}
                          />
                        );
                      })}
                    </div>
                    <div className={classes.btnContainer}>
                      <div
                        className={classes.btnCancel}
                        onClick={() => {
                          setOpenAutomationsFavoritedDrawer(false);
                        }}
                      >
                        Cancelar
                      </div>
                      <div
                        className={classes.btnConfirm}
                        onClick={saveAutomationList}
                      >
                        Confirmar
                      </div>
                    </div>
                  </>
                ) : (
                  <div style={{ marginTop: "25px", textAlign: "center" }}>
                    Não existe nenhuma automação que possa ser selecionada
                  </div>
                )}
              </>
            )}
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default AutomationsFavoritedDrawer;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerAutomationDrawer: {
      width: "700px",
      backgroundColor: "#FFF",
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
    btnBackContainer: {
      display: "flex",
      padding: "25px",
      justifyContent: "start",
      alignItems: "center",
      maxHeight: "75px",
    },
    btnBackIcon: {
      margin: theme.spacing(1),
    },
    containerHeader: {
      display: "flex",
      justifyContent: "space-between",
      maxHeight: "75px",
      minHeight: "75px",
      alignItems: "center",
      padding: "25px",
    },
    headerTitle: {
      fontSize: "15px",
      fontWeight: 600,
      lineHeight: "20px",
    },
    containerLoading: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "auto",
    },
    listAutomationsContainer: {
      backgroundColor: "#EBEEEE",
      padding: "15px 25px",
      height: "calc(100vh - 227px)",
      overflow: "auto",
    },
    automation: {
      justifyContent: "space-between",
      backgroundColor: "#FFF",
      borderRadius: "10px",
      margin: "10px 0px",
      minHeight: "75px",
      alignItems: "center",
      padding: "0px 35px",
    },
    btnContainer: {
      height: "75px",
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
    },
    btnConfirm: {
      height: "50px",
      width: "150px",
      backgroundColor: "#00A335",
      borderRadius: "3px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "15px",
      fontWeight: 700,
      lineHeight: "20px",
      color: "#FFF",
      marginRight: "25px",
      cursor: "pointer",
    },
    btnCancel: {
      height: "50px",
      width: "130px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "1px solid #BDBDBD",
      borderRadius: "3px",
      marginRight: "15px",
      fontSize: "15px",
      fontWeight: 600,
      lineHeight: "20px",
      color: "#8B979F",
      cursor: "pointer",
    },
  })
);
