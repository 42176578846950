import { RegisterEnterpriseModel } from "../../../../../../Models/EnterpriseModel";

interface Props {
  data: RegisterEnterpriseModel;
}

const EnterpriseDataView: React.FunctionComponent<Props> = ({
  data,
}: Props) => {
  const document = data?.cpf && data?.cpf.trim() != "" ? "cpf" : "cnpj";

  return (
    <>
      <p>
        <strong>{data?.responsible}</strong>
      </p>
      <p>E-mail: {data?.email}</p>
      <p>
        {document.toUpperCase()}: {data[document]}
      </p>
      <p>Telefone: {data?.telephone}</p>
      <p>
        {data?.address}, {data?.number}
      </p>
      <p>
        {data?.address2}
        {data?.address2 && data?.address2.trim() != "" ? ", " : ""}
        {data?.neighborhood}
      </p>
      <p>
        {data?.country}, {data?.state} {data?.postal_code}
      </p>
    </>
  );
};

export default EnterpriseDataView;
