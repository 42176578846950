import {
  createStyles,
  makeStyles,
  Theme,
  CardMedia,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { useContext, useState } from "react";
import GlobalContex from "../../../../../../GlobalContext/GlobalContext";
import { HomeUserModel } from "../../../../../../Models/HomeUserModel";
import history from "../../../../../../Services/history";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { setUserHomeInfoLocal } from "../../../../../../Services/UserHomeInfoLocalService";

interface Props {
  homeUser: HomeUserModel;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: "100%",
      height: "250px",
    },
    contentCard: {
      padding: "10px",
      minHeight: "150px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      "&:last-child": {
        paddingBottom: "10px",
      },
    },
    enterpriseName: {
      color: "#00A335",
      fontWeight: 400,
      fontSize: "21px",
      lineHeight: "29px",
      display: "flex",
      alignItems: "flex-start",
    },
    menuIconEnterprise: {
      padding: "0px",
      marginTop: "5px",
    },
    createdAt: {
      padding: theme.spacing(1),
      fontSize: "13px",
      color: "#8B979F",
      fontWeight: 400,
      textAlign: "end",
      lineHeight: "20px",
    },
    labelMenuItem: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      color: "#8B979F",
    },
  })
);

const EnterpriseActiveCard: React.FunctionComponent<Props> = ({
  homeUser,
}: Props) => {
  const classes = useStyles();

  const {
    selectHomeTrigger,
    setSelectHomeTrigger,
    setNameEnterpriseSelected,
    setIsHomeSelected,
    setHome,
  } = useContext(GlobalContex);

  const HomeWithoutImgage = "assets/imgs/enterpriseDefaultImage.png";

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const setHomeID = async (home: HomeUserModel) => {
    localStorage.setItem("homeID", home.home_id);
    localStorage.setItem("homeSelectName", home.name);
    setUserHomeInfoLocal(home.userHomeInfo);
    setHome(home.userHomeInfo);
    setSelectHomeTrigger(!selectHomeTrigger);
    setIsHomeSelected(true);
    history.push("/manage-place");
    setNameEnterpriseSelected(home.name);
  };

  return (
    <>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          setHomeID(homeUser);
        }}
      >
        <CardMedia
          component="img"
          className={classes.img}
          image={homeUser.image ? homeUser.image : HomeWithoutImgage}
        />
        <CardContent className={classes.contentCard}>
          <div className={classes.enterpriseName}>
            {homeUser.role === "Administrador" && (
              <IconButton
                id={"btn-menu " + homeUser.name}
                className={classes.menuIconEnterprise}
                onClick={(event) => {
                  event.stopPropagation();
                  openMenu(event);
                }}
              >
                <MoreVertIcon fontSize={"small"} htmlColor={"#8B979F"} />
              </IconButton>
            )}
            {homeUser.name}
          </div>
          <div className={classes.createdAt}>
            Criado em{" "}
            {new Date(homeUser.create_at).toLocaleDateString("pt-BR", {
              timeZone: "UTC",
            })}
          </div>
        </CardContent>
      </div>
      {homeUser.role === "Administrador" && (
        <Menu
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={closeMenu}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            id={"menu-option-edit"}
            onClick={(event) => {
              event.stopPropagation();
              history.push("/enterprise/edit/" + homeUser.home_id);
            }}
          >
            <div className={classes.labelMenuItem}>Editar empreendimento</div>
            <IconButton>
              <img width="15" alt="" src="assets/icons/edit (1) 2.svg" />
            </IconButton>
          </MenuItem>
        </Menu>
      )}
    </>
  );
};

export default EnterpriseActiveCard;
