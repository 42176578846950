import {
  createStyles,
  IconButton,
  makeStyles,
  Button,
} from "@material-ui/core";
import { useContext } from "react";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import DrawerProps from "../../../../Types/DrawerProps";
import OperatorsGroupPageContext from "../../Context/OperatorsGroupPageContext";
import WithRoleAuthorization from "../../../../Components/WithRoleAuthorization";
import CustomFilter from "../../../../Components/CustomFilter";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import history from "../../../../Services/history";

const useStyles = makeStyles(() =>
  createStyles({
    containerSearchBar: {
      display: "flex",
      flexDirection: "row",
      flexGrow: 1,
      alignContent: "center",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: "2.5rem",
    },
    filterInput: {
      width: "35rem",
    },
    button: {
      textTransform: "none",
      width: "250px",
      height: "55px",
      fontSize: "15px",
      fontWeight: 700,
      lineHeight: "20px",
    },
  })
);

const CustomSearch: React.FunctionComponent = () => {
  const { setExpandSubHeader } = useContext(GlobalContex);
  const { getUserGroupsByFilterValue, setIsLoadingUserGroups } = useContext(
    OperatorsGroupPageContext
  );

  const anchor: DrawerProps = "right";

  const classes = useStyles();

  const clickExpand = () => {
    setExpandSubHeader(false);
  };

  return (
    <div className={classes.containerSearchBar}>
      <CustomFilter
        placeHolder={"Procurar por nome de grupo"}
        id={"search-for-operators-group"}
        isFilterInFront={false}
        className={classes.filterInput}
        setStateLoading={setIsLoadingUserGroups}
        setLoadingFunction={getUserGroupsByFilterValue}
      />
      <div>
        <WithRoleAuthorization permitedRoles={["Owner", "Administrador"]}>
          <React.Fragment key={anchor}>
            <Button
              id="btn-new-operators-group"
              onClick={() => history.push("/operators/create")}
              className={classes.button}
              variant="contained"
              color="secondary"
            >
              <AddIcon /> Novo grupo
            </Button>
          </React.Fragment>
        </WithRoleAuthorization>
        <IconButton onClick={clickExpand} id="btn-toggle-searchbar">
          <img src="assets/icons/close-header-icon.svg" />
        </IconButton>
      </div>
    </div>
  );
};

export default CustomSearch;
