import styled from "styled-components";

export const Icon = styled.div``;

export const Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;

  color: #3e5055;

  ${Icon} ~ & {
    margin-left: 10px;
  }
`;
