import React, { useContext, useState } from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import DrawerProps from "../../../../Types/DrawerProps";
import TextField from "@material-ui/core/TextField";
import ManageRoomContext from "../../Context/ManageRoomContext";
import {
  Chip,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AddIcon from "@material-ui/icons/Add";
import WithRoleAuthorization from "../../../../Components/WithRoleAuthorization";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import UserGroupsModel from "../../../../Models/UserGroupsModel";
import DeleteIcon from "@material-ui/icons/Delete";
import history from "../../../../Services/history";
import CustomFilter from "../../../../Components/CustomFilter";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#FFF",
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "15px",
      paddingLeft: "2.5rem",
    },
    container2: {
      display: "flex",
      padding: "0px 94px",
      height: "90vh",
    },
    button: {
      textTransform: "none",
      padding: "10px 30px",
    },
    list: {
      width: "35vw",
    },
    fullList: {
      width: "auto",
    },
    row: {
      display: "flex",
      padding: "25px",
      justifyContent: "start",
      alignItems: "center",
    },
    paper: {
      backgroundColor: "#FFF !important",
    },
    margin: {
      margin: theme.spacing(1),
    },
    image: {
      display: "flex",
      justifyContent: "center",
      alignItems: "start",
      flexDirection: "column",
    },
    removeImgIcon: {
      height: "0px",
      width: "0px",
      position: "absolute",
      background: "#FFF",
      right: "20px",
      top: "0px",
      "&:hover": {
        background: "#FFF",
      },
    },
    containerButtonAdd: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "25px",
    },
    buttomAdd: {
      borderRadius: "50%",
      width: "29px !important",
      height: "29px !important",
      minWidth: "0px",
      backgroundColor: "#EBEEEE",
    },
    chip: {
      padding: "15px",
      marginRight: "10px",
      marginBottom: "10px",
      color: "#8B979F",
      fontSize: "15px",
    },
    containerLabelUserGroups: {
      margin: "30px 0px",
    },
    labelUserGroupTitle: {
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "25px",
      color: "#3E5055",
      marginBottom: "10px",
    },
    labelUserGroupSubtitle: {
      fontSize: "15px",
      fontWeight: 400,
      lineHeight: "20px",
      color: "#8B979F",
    },
    filterInput: {
      width: "35rem",
    },
  })
);

const Filters: React.FunctionComponent = () => {
  const {
    roomName,
    isSavingRoom,
    handleChangeRoomName,
    handleSubmit,
    toggleDrawer,
    selectedFile,
    state,
    handleChange,
    roomToEdit,
    userGroups,
    handleChangeUserGroups,
    selectUserGroup,
    setSelectUserGroup,
    removeImageRoom,
  } = useContext(ManageRoomContext);

  const {
    originalRoomList,
    setExpandSubHeader,
    setIsLoadingRooms,
    setRoomsToShow,
  } = useContext(GlobalContex);

  const classes = useStyles();

  const anchor: DrawerProps = "right";

  const icon = "assets/icons/Group_356.svg";
  const imageRoom = "assets/icons/Group 362.png";

  const [optionSelected, setOptionSelected] = useState<string>("none");
  const [option, setOption] = useState<UserGroupsModel>({} as UserGroupsModel);
  const [displayDeleteButton, setDisplayDeleteButton] =
    useState<string>("none");

  const clickExpand = () => {
    setExpandSubHeader(false);
  };

  const handleChangeGroup = (event: React.ChangeEvent<{ value: unknown }>) => {
    setOptionSelected(event.target.value as string);

    const opt = userGroups.find(
      (group) => group.user_group_id === event.target.value
    );

    setOption(opt ? opt : ({} as UserGroupsModel));
  };

  const addGroup = () => {
    handleChangeUserGroups(option, true);
    setOptionSelected("none");
  };

  const handleDelete = (ugSelected: UserGroupsModel) => {
    setSelectUserGroup([
      ...selectUserGroup.filter(
        (ug: UserGroupsModel) => ug.user_group_id !== ugSelected.user_group_id
      ),
    ]);
  };

  const goToOperatorsGroup = () => {
    history.push("/operators");
  };

  const select = () => {
    return (
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="operator-group-label">Grupo de operador</InputLabel>
        <Select
          id={"room-operator-group-select"}
          fullWidth
          labelId="operator-group-label"
          value={optionSelected}
          label="Grupo de operador"
          onChange={handleChangeGroup}
          disabled={
            !userGroups.filter((ug: UserGroupsModel) => {
              return !roomToEdit.userGroups?.some(
                (ug2: UserGroupsModel) => ug2.user_group_id === ug.user_group_id
              );
            }).length
          }
        >
          <MenuItem
            id={"room-operator-group-select-item-none"}
            key={"room-operator-group-select-item-none"}
            value={"none"}
          >
            <em>Nenhum</em>
          </MenuItem>
          {userGroups
            .filter((ug: UserGroupsModel) => {
              return !roomToEdit.userGroups?.some(
                (ug2: UserGroupsModel) => ug2.user_group_id === ug.user_group_id
              );
            })
            .map((usergroup: UserGroupsModel) => (
              <MenuItem
                id={
                  "room-operator-group-select-item-" + usergroup.user_group_id
                }
                key={usergroup.user_group_id}
                value={usergroup.user_group_id}
              >
                {usergroup.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  };

  const list = (anchor: DrawerProps) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <div className={classes.row}>
        <IconButton
          id={"btn-back-drawer"}
          aria-label="delete"
          className={classes.margin}
          size="small"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <ArrowBackIosIcon fontSize="inherit" />
        </IconButton>
        Voltar
      </div>
      <Divider />
      <div className={classes.container2}>
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <FormGroup style={{ marginTop: "40px" }}>
            <div className={classes.image}>
              <div
                style={{ position: "relative" }}
                onMouseEnter={() => setDisplayDeleteButton("inline-flex")}
                onMouseLeave={() => setDisplayDeleteButton("none")}
              >
                {selectedFile && (
                  <IconButton
                    id={"remove-img-room"}
                    className={classes.removeImgIcon}
                    onClick={() => {
                      removeImageRoom();
                    }}
                    style={{ display: displayDeleteButton }}
                  >
                    <CancelIcon htmlColor="#D72735" fontSize="large" />
                  </IconButton>
                )}
                <img
                  style={{
                    borderRadius: "50%",
                    marginRight: "25px",
                  }}
                  src={
                    typeof selectedFile === "string"
                      ? selectedFile === ""
                        ? imageRoom
                        : selectedFile
                      : URL.createObjectURL(selectedFile)
                  }
                  alt="Espaço"
                  width={110}
                  height={110}
                />
              </div>
              <Button
                id={"add-img-room"}
                style={{
                  marginTop: 17,
                  textTransform: "none",
                  color: "#00A335",
                  fontWeight: "bold",
                }}
                component="label"
                startIcon={<AddIcon />}
              >
                Add imagem
                <input
                  id="room-image"
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={handleChange}
                />
              </Button>
            </div>
          </FormGroup>
          <FormGroup style={{ marginTop: "40px" }}>
            <label htmlFor="room-name">
              <strong>Nome do espaço</strong>
            </label>
            <TextField
              label="Espaço"
              id="room-name"
              variant="filled"
              required
              inputProps={{ maxLength: 25 }}
              value={roomName}
              onChange={handleChangeRoomName}
            />
          </FormGroup>
          <div className={classes.containerLabelUserGroups}>
            <div className={classes.labelUserGroupTitle}>
              Adicionar a grupo de operadores*
            </div>
            <div className={classes.labelUserGroupSubtitle}>
              Você pode atribuir o local a um grupo de operadores. Você pode
              criar um grupo e gerenciá-lo na tela de{" "}
              <strong
                style={{ color: "#00A335", cursor: "pointer" }}
                onClick={goToOperatorsGroup}
              >
                Grupo de Operadores
              </strong>
            </div>
          </div>
          <div
            style={{
              marginTop: "25px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            {select()}
            <div className={classes.containerButtonAdd}>
              <Button
                id={"btn-add-operator-group"}
                disabled={optionSelected === "none"}
                onClick={addGroup}
                classes={{ root: classes.buttomAdd }}
              >
                <AddIcon
                  htmlColor={optionSelected === "none" ? "#8B979F" : "#6DCC9A"}
                />
              </Button>
              <p
                style={{
                  margin: "0px",
                  color: optionSelected === "none" ? "#8B979F" : "#6DCC9A",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
                Adicionar
              </p>
            </div>
          </div>
          <div
            style={{
              margin: "25px 0px",
            }}
          >
            {selectUserGroup?.map((group: UserGroupsModel) => {
              return (
                <Chip
                  key={group.user_group_id}
                  label={group.name}
                  className={classes.chip}
                  deleteIcon={<DeleteIcon />}
                  onDelete={() => {
                    handleDelete(group);
                    handleChangeUserGroups(group, false);
                  }}
                />
              );
            })}
          </div>
          <Button
            id={"btn-save-room"}
            type="submit"
            disabled={isSavingRoom}
            className={clsx(classes.button)}
            startIcon={
              isSavingRoom ? (
                <CircularProgress color="primary" size={20} />
              ) : null
            }
            variant="contained"
            color="secondary"
          >
            Salvar
          </Button>
        </form>
      </div>
    </div>
  );

  return (
    <div className={classes.container}>
      <CustomFilter
        placeHolder={"Procurar por espaços"}
        id={"search-for-rooms"}
        isFilterInFront={true}
        arrayToFilter={originalRoomList}
        propertyToFilter={"name"}
        className={classes.filterInput}
        setStateFuncion={setRoomsToShow}
        setStateLoading={setIsLoadingRooms}
      />
      <div>
        <WithRoleAuthorization permitedRoles={["Owner", "Administrador"]}>
          <React.Fragment key={anchor}>
            <Button
              id={"btn-new-room"}
              onClick={toggleDrawer(anchor, true)}
              className={classes.button}
              startIcon={<img src={icon} width={25} alt="Icone +" />}
              variant="contained"
              color="secondary"
            >
              Novo espaço
            </Button>
            <Drawer
              classes={{ paper: classes.paper }}
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        </WithRoleAuthorization>
        <IconButton onClick={clickExpand}>
          <img src="assets/icons/close-header-icon.svg" />
        </IconButton>
      </div>
    </div>
  );
};

export default Filters;
