import {
  ListItemIcon,
  ListItemText,
  ListItem,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import history from "../../../Services/history";

const useStyles = makeStyles(() =>
  createStyles({
    item: { border: "1px solid #D1DAE0", padding: "20px 40px" },
    icon: {
      borderRadius: "10px",
    },
    title: {
      color: "#3E5055",
      fontSize: "15px",
    },
    subtitle: {
      color: "#8B979F",
      fontSize: "13px",
      fontWeight: 400,
    },
  })
);

interface Props {
  src: string;
  activeSrc: string;
  title: string;
  subtitle: string;
  active: boolean;
  link: string;
}

const ConfigurationOptionItem: React.FunctionComponent<Props> = ({
  src,
  activeSrc,
  title,
  subtitle,
  active,
  link,
}: Props) => {
  const classes = useStyles();

  return (
    <ListItem
      button
      className={classes.item}
      onClick={() => {
        history.push(link);
      }}
    >
      <ListItemIcon>
        <img
          src={active ? activeSrc : src}
          width={35}
          className={classes.icon}
        />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            className={classes.title}
            style={{ fontWeight: active ? 700 : 600 }}
          >
            {title}
          </Typography>
        }
        secondary={
          <Typography className={classes.subtitle}>{subtitle}</Typography>
        }
      />
    </ListItem>
  );
};

export default ConfigurationOptionItem;
