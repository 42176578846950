import { ButtonProps } from "@material-ui/core";
import React from "react";
import ButtonGeneric from "../ButtonGeneric";

type ButtonTextProps = Omit<ButtonProps, "variant"> & {
  loading?: boolean;
  loadingCaption?: string;
};

const ButtonText: React.FunctionComponent<ButtonTextProps> = ({
  children,
  ...props
}) => (
  <ButtonGeneric variant={"text"} {...props}>
    {children}
  </ButtonGeneric>
);
export default ButtonText;
