import { Box } from "@material-ui/core";
import styled from "styled-components";

export const BoxStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 438px;
`;

export const Header = styled.div`
  padding: 20px 30px;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #3e5055;
`;

export const Body = styled.div`
  padding: 30px 20px;
  background-color: #007a39;
  color: #fff;
`;

export const BodyTitle = styled.span`
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
`;

export const BodyContent = styled.p`
  margin-top: 15px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
`;

export const BodyInfos = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Footer = styled.div`
  padding: 30px;
`;

export const FooterTitle = styled.p`
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #3e5055;
`;

export const ContainerFooterButton = styled.div`
  display: flex;
  padding: 20px;
  background-color: #ebeeee;
  border-radius: 10px;
  justify-content: space-between;
`;

export const FooterButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BtnSendHelp = styled.a`
  display: flex;
  padding: 10px 40px;
  background-color: #00a335;
  border-radius: 3px;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
  min-width: 240px;
  text-align: center;
  &:hover: {
    color: white;
  }
`;

export const SubtitleSendHelp = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #8b979f;
`;
