import { useContext, useEffect } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import EnvironmentsContext from "../../context/EnvironmentsContext";
import EnvironmentModel from "../../../../Models/EnvironmentModel";
import { Button, Divider, makeStyles, Typography } from "@material-ui/core";
import EnvironmentsOptions from "./EnvironmentsOptions";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import WithRoleAuthorization from "../../../../Components/WithRoleAuthorization";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  itemSelect: {
    backgroundColor: "#EBEEEE",
  },
  options: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  environment: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  containerNewAmbiente: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const EnvironmentsList: React.FunctionComponent = () => {
  const {
    environments,
    allDevices,
    setEnvironmentId,
    setRoomName,
    roomId,
    clickEnvironmentDevices,
    devicesEnvironment,
    setShowOptions,
    setHideOptionsAddDevice,
    roomName,
    setEnvName,
    setIsToggleOpen,
    setDevicesToAdd,
    setDevicesToRemove,
    selectedIndex,
    setSelectedIndex,
  } = useContext(EnvironmentsContext);

  const { setAllDevicesSelected } = useContext(GlobalContex);

  const classes = useStyles();

  useEffect(() => {
    const load = async () => {
      await devicesEnvironment(roomId);
    };
    load();
    setEnvName(roomName);
  }, [roomId]);

  const clickEnvironment = async (
    environment: EnvironmentModel,
    index: number
  ) => {
    if (index === 0 && selectedIndex !== 0) {
      devicesEnvironment(roomId);
      setAllDevicesSelected(true);
      setShowOptions(true);
      setEnvName(roomName);
      setHideOptionsAddDevice(false);
    }

    if (index !== 0 && index !== selectedIndex) {
      setEnvironmentId(environment.id);
      setEnvName(environment.name);
      await clickEnvironmentDevices(environment.id);
      setAllDevicesSelected(false);
      setShowOptions(true);
      setRoomName(environment.name);
    }

    setDevicesToAdd([]);
    setDevicesToRemove([]);

    setSelectedIndex(index);
  };

  const allDeviceDefault: EnvironmentModel[] = [
    {
      id: 0,
      devices: allDevices,
      name: "Dispositivos no espaço",
    },
  ];

  const listEnvironments = (lisEnvironments: EnvironmentModel[]) => {
    return (
      <List component="nav" disablePadding>
        {allDeviceDefault.map((environment: EnvironmentModel, i: number) => (
          <div key={"enviroment-all-devices-list-item" + i}>
            <ListItem
              style={{
                height: "82px",
                cursor: "pointer",
              }}
              key={i}
              className={selectedIndex === i ? classes.itemSelect : ""}
              onClick={() => {
                clickEnvironment(environment, i);
              }}
            >
              {selectedIndex === i && (
                <WithRoleAuthorization
                  permitedRoles={["Owner", "Administrador"]}
                >
                  <EnvironmentsOptions
                    environment={environment}
                    key={`EnvironmentsOptions-${i}`}
                  />
                </WithRoleAuthorization>
              )}
              <ListItemText
                key={`ListItemText-${i}`}
                primary={
                  <Typography
                    variant="body1"
                    style={{ color: "#3E5055", fontWeight: "bold" }}
                  >
                    {environment.name}
                  </Typography>
                }
                secondary={`${
                  environment?.devices?.length
                    ? environment?.devices?.length
                    : 0
                } dispositivos`}
              />
            </ListItem>
            <Divider />
            <div className={classes.containerNewAmbiente}>
              <p
                style={{
                  fontSize: "18px",
                  color: "#3E5055",
                  fontWeight: "bold",
                  padding: "15px 30px",
                  margin: "0px",
                }}
              >
                Ambientes
              </p>

              <WithRoleAuthorization permitedRoles={["Owner", "Administrador"]}>
                <Button
                  id="btn-add-new-environment"
                  color="secondary"
                  className={classes.button}
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setIsToggleOpen(true);
                    setRoomName("");
                  }}
                >
                  Criar
                </Button>
              </WithRoleAuthorization>
            </div>

            <Divider />
          </div>
        ))}

        {lisEnvironments.map((environment: EnvironmentModel, i: number) => {
          i += 1;
          return (
            <div key={"enviroment-list-item" + i}>
              <ListItem
                style={{
                  height: "82px",
                  cursor: "pointer",
                }}
                key={i}
                className={selectedIndex === i ? classes.itemSelect : ""}
                onClick={() => {
                  clickEnvironment(environment, i);
                }}
              >
                {selectedIndex === i && i > 0 && (
                  <WithRoleAuthorization
                    permitedRoles={["Owner", "Administrador"]}
                  >
                    <EnvironmentsOptions
                      environment={environment}
                      key={`EnvironmentsOptions-${i}`}
                    />
                  </WithRoleAuthorization>
                )}
                <ListItemText
                  key={`ListItemText-${i}`}
                  primary={
                    <Typography
                      variant="body1"
                      style={{ color: "#3E5055", fontWeight: "bold" }}
                    >
                      {environment.name}
                    </Typography>
                  }
                  secondary={`${
                    environment?.devices?.length
                      ? environment?.devices?.length
                      : 0
                  } dispositivos`}
                />
              </ListItem>
              <Divider />
            </div>
          );
        })}
      </List>
    );
  };

  return (
    <div className={classes.environment}>{listEnvironments(environments)}</div>
  );
};

export default EnvironmentsList;
