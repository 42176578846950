import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import CustomConfirmDialog from "../../../../Components/CustomConfirmDialog";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import { EnterpriseModel } from "../../../../Models/EnterpriseModel";
import { getEnterpriseById } from "../../../../Services/EnterpriseService";
import ItemIcon from "../ItemIcon";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Plans } from "../../../../Models/Plans";
import { toREAL } from "../../../../Utils/CurrencyUtils";
import history from "../../../../Services/history";
import EnterprisesConfigContext from "../../Context/EnterprisesConfigContext";
import { postReactiveCanceledSignaturePlan } from "../../../../Services/PlansService";
import { ErrorMessage } from "../../../../Utils/ErrorMessage";

interface Props {
  open: boolean;
  enterprise_id: string;
  close: (value: boolean) => void;
  openInvoice: (value: boolean) => void;
  openChangePlan: (value: boolean) => void;
  openChangePlanSuccess: (
    value: boolean,
    plan: Plans,
    enterpriseToChangePlan: EnterpriseModel
  ) => void;
}

const DialogMyPlan: React.FunctionComponent<Props> = ({
  open,
  enterprise_id,
  close,
  openInvoice,
  openChangePlan,
  openChangePlanSuccess,
}: Props) => {
  const classes = useStyles();
  const { showAlert } = useContext(GlobalContex);
  const { plans } = useContext(EnterprisesConfigContext);
  const [enterprise, setEnterprise] = useState<EnterpriseModel>(
    {} as EnterpriseModel
  );
  const [isLoadingEnterprise, setIsLoadingEnterprise] =
    useState<boolean>(false);
  const [closeSignature, setCloseSignature] = useState(false);
  const [hasAlreadyBeenCanceled, setHasAlreadyBeenCanceled] =
    useState<boolean>(false);

  const [reopenSignature, setReopenSignature] = useState(false);

  useEffect(() => {
    return () => {
      setIsLoadingEnterprise(false);
      setEnterprise({} as EnterpriseModel);
      setHasAlreadyBeenCanceled(false);
    };
  }, []);

  const loadEnterpriseById = async (enterprise_id: string) => {
    try {
      setIsLoadingEnterprise(true);
      await getEnterpriseById(enterprise_id).then((response) => {
        setEnterprise(response.data);
        setHasAlreadyBeenCanceled(!!response.data.cancel_at);
      });
    } catch (error) {
      showAlert(
        "Não foi possível carregar as informações do empreendimento!",
        "error"
      );
      close(false);
    } finally {
      setIsLoadingEnterprise(false);
    }
  };

  useEffect(() => {
    if (open) {
      loadEnterpriseById(enterprise_id);
    }
  }, [open]);

  const labelInterval = {
    year: "anual",
    month: "mensal",
    day: "diário",
  };

  const labelIntervalPrice = {
    year: "ano",
    month: "mês",
    day: "dia",
  };

  const changePlan = (plan: Plans) => {
    close(false);
    const canChangePlan =
      enterprise.plan.qtn_automation <= plan.max_automation &&
      enterprise.plan.qtn_device <= plan.max_device &&
      enterprise.plan.qtn_user <= plan.max_user;

    if (canChangePlan) {
      openChangePlanSuccess(true, plan, enterprise);
    } else openChangePlan(true);
  };

  return (
    <Dialog
      onClose={() => {
        close(false);
      }}
      open={open}
      className={classes.dialog}
    >
      <div className={classes.container}>
        <div style={{ textAlign: "right", marginBottom: "30px" }}>
          <Button
            className={classes.btnCloseModal}
            variant="text"
            onClick={() => close(false)}
            startIcon={<img src="assets/icons/erro_linha.svg" />}
          >
            Fechar
          </Button>
        </div>
        {isLoadingEnterprise ? (
          <div className={classes.loadingContainer}>
            <CircularProgress color="secondary" size={30} />
          </div>
        ) : (
          <>
            <div className={classes.myPlanContainer}>
              <div className={classes.titleContainer}>
                <div className={classes.title}>Meu Plano</div>
                <div>
                  <Tooltip
                    title="Esse valor será descontado da suas próximas faturas. Somente após zerar que um novo débito será descontado da forma de pagamento selecionada."
                    placement="top"
                    style={{ marginRight: "5px" }}
                  >
                    <img src="assets/icons/interrogation_mark_1_.svg" />
                  </Tooltip>
                  Meu crédito atual <strong>R$ {enterprise.balance}</strong>{" "}
                </div>
              </div>
              <div className={classes.info}>
                <ItemIcon
                  width="80px"
                  height="80px"
                  color={enterprise.plan?.color_plan}
                  className={classes.imgPlan}
                />
                <div className={classes.infoContent}>
                  <div className={classes.planNameLabel}>
                    Plano {enterprise.plan?.name}{" "}
                    {labelInterval[enterprise.plan?.price.interval]}
                  </div>
                  <div className={classes.infoContentLabelContainer}>
                    <div>
                      <div className={classes.infoContentLabel}>
                        Quantidade de usuários
                      </div>
                      <div className={classes.infoContentLabel}>
                        Quantidade de dispositivos
                      </div>
                      <div className={classes.infoContentLabel}>
                        Quantidade de automações
                      </div>
                    </div>
                    <div className={classes.margin20}>
                      <div className={classes.infoContentLabel}>
                        <strong>{enterprise.plan?.qtn_user}</strong> /
                        {enterprise.plan?.max_user}
                      </div>
                      <div className={classes.infoContentLabel}>
                        <strong>{enterprise.plan?.qtn_device}</strong> /
                        {enterprise.plan?.max_device}
                      </div>
                      <div className={classes.infoContentLabel}>
                        <strong>{enterprise.plan?.qtn_automation}</strong> /
                        {enterprise.plan?.max_automation}
                      </div>
                    </div>
                    <div className={classes.margin50}>
                      <div className={classes.infoContentLabel}>
                        Valor do plano{" "}
                        <strong className={classes.margin20}>
                          R$ {enterprise.plan?.price.price}/
                          {labelIntervalPrice[enterprise.plan?.price.interval]}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.payments}>
                <div>
                  <Button
                    className={classes.btnHistory}
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      openInvoice(true);
                    }}
                  >
                    Verifique o histórico de faturamento
                  </Button>
                  {!enterprise.days_until_cancel && (
                    <Button
                      className={classes.btnEnd}
                      variant="contained"
                      onClick={() => setCloseSignature(true)}
                      disabled={hasAlreadyBeenCanceled}
                    >
                      Encerrar assinatura
                    </Button>
                  )}
                  {enterprise.days_until_cancel &&
                    enterprise.days_until_cancel > 0 && (
                      <Button
                        style={{ textTransform: "capitalize" }}
                        color="secondary"
                        variant="contained"
                        onClick={() => setReopenSignature(true)}
                      >
                        Retomar assinatura
                      </Button>
                    )}
                </div>
              </div>
              {enterprise.days_until_cancel && (
                <p
                  className={"m-0"}
                  style={{
                    marginTop: "10px",
                    color: "#EF847E",
                  }}
                >
                  {enterprise.days_until_cancel} dia
                  {enterprise.days_until_cancel > 1 ? "s" : ""} para encerrar a
                  renovação do plano
                </p>
              )}
            </div>
            <div className={classes.otherPlansContainer}>
              <div className={classes.title2}>
                Outros planos
                {plans.length === 0 && (
                  <CircularProgress color="secondary" size={30} />
                )}
              </div>

              {plans.map((plan: Plans, index) => {
                if (plan.trial) {
                  return;
                }
                return (
                  <Accordion key={"plan-item-" + index}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <ItemIcon
                        width="40px"
                        height="40px"
                        color={plan?.color_plan}
                        className={classes.imgPlan2}
                      />
                      <Typography>{plan?.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className={classes.accordionContiner}>
                        <div className={classes.infoContentLabelContainer}>
                          <div>
                            <div className={classes.infoContentLabel}>
                              Quantidade de usuários
                            </div>
                            <div className={classes.infoContentLabel}>
                              Quantidade de dispositivos
                            </div>
                            <div className={classes.infoContentLabel}>
                              Quantidade de automações
                            </div>
                          </div>
                          <div className={classes.margin20}>
                            <div className={classes.infoContentLabel}>
                              <strong> {plan?.max_user}</strong>
                            </div>
                            <div className={classes.infoContentLabel}>
                              <strong>{plan?.max_device}</strong>
                            </div>
                            <div className={classes.infoContentLabel}>
                              <strong>{plan?.max_automation}</strong>
                            </div>
                          </div>
                          <div className={classes.margin50}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                              }}
                            >
                              <div className={classes.infoContentLabel}>
                                Valor do plano:
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  flexDirection: "column",
                                  alignItems: "end",
                                  marginLeft: "14px",
                                }}
                                className={classes.infoContentLabel}
                              >
                                {plan.prices.map((p, index) => (
                                  <strong key={"price-item-" + index}>
                                    {" "}
                                    {toREAL(p.price)}{" "}
                                  </strong>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                        {enterprise.status !== "CANCELED" && (
                          <div className={classes.planContainer}>
                            <Button
                              style={{ textTransform: "none" }}
                              onClick={() => changePlan(plan)}
                            >
                              Mudar para esse plano
                            </Button>
                          </div>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>
          </>
        )}
      </div>

      <CustomConfirmDialog
        openDialog={closeSignature}
        text={
          "Você realmente deseja encerrar assinatura? Ao fazer isso você perderá o acesso ao empreendimento e tudo que foi cadastrado nele"
        }
        onConfirmButton={() => {
          setCloseSignature(false);
          history.push({
            pathname: "/cancelar-assinatura",
            state: {
              enterprise_id,
            },
          });
        }}
        onCancelButton={() => setCloseSignature(false)}
        onClose={() => setCloseSignature(false)}
        onCloseFocus={() => setCloseSignature(false)}
      />

      <CustomConfirmDialog
        openDialog={reopenSignature}
        text={
          "Você deseja retomar a assinatura do plano? Ao confirmar essa ação o plano continuará sendo debitado com a forma de pagamento selecionada"
        }
        onConfirmButton={async () => {
          setReopenSignature(false);
          try {
            await postReactiveCanceledSignaturePlan(enterprise.id);
            showAlert("Assinatura reativada com sucesso!", "success");
            loadEnterpriseById(enterprise.id);
          } catch (e) {
            const msg = ErrorMessage(e, "Não foi possível reativar assinatura");
            showAlert(msg, "error");
          }
        }}
        onCancelButton={() => setReopenSignature(false)}
        onClose={() => setReopenSignature(false)}
        onCloseFocus={() => setReopenSignature(false)}
      />
    </Dialog>
  );
};

export default DialogMyPlan;

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      "& .MuiDialog-paperWidthSm": {
        maxWidth: "100%",
      },
    },
    container: {
      width: "100%",
      maxHeight: "100%",
    },
    btnCloseModal: {
      color: "#8B979F",
      textTransform: "capitalize",
    },
    loadingContainer: {
      width: "200px",
      height: "200px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    myPlanContainer: {
      padding: "0px 50px 30px 50px",
    },
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "15px",
    },
    title: {
      fontWeight: 600,
      fontSize: "21px",
      lineHeight: "29px",
      color: "#3E5055",
    },
    info: {
      border: "1px solid #D1DAE0",
      borderRadius: "3px",
      marginBottom: "10px",
      padding: "15px",
      display: "flex",
    },
    imgPlan: {
      width: "50px",
      height: "50px",
    },
    imgPlan2: {
      width: "30px",
      height: "30px",
    },
    infoContent: {
      width: "100%",
    },
    planNameLabel: {
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "20px",
      color: "#677884",
    },
    infoContentLabelContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    infoContentLabel: {
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: "18px",
      color: "#8B979F",
    },
    margin20: {
      marginLeft: "20px",
    },
    margin50: {
      marginLeft: "50px",
    },
    payments: {
      border: "1px solid #D1DAE0",
      borderRadius: "3px",
      padding: "15px",
    },
    btnHistory: {
      textTransform: "initial",
      marginRight: "10px",
    },
    btnEnd: {
      textTransform: "initial",
      background: "#EF847E",
      borderRadius: "3px",
      color: "#FFF",
      boxShadow: "none",
      "&:hover": {
        background: "#EF847E",
        boxShadow: "none",
      },
    },
    otherPlansContainer: {
      padding: "50px",
      backgroundColor: "#EBEEEE",
    },
    title2: {
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "25px",
      color: "#3E5055",
      display: "flex",
      alignItems: "center",
      marginBottom: "20px",
    },
    accordionContiner: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
    planContainer: {
      marginTop: "30px",
      display: "flex",
      justifyContent: "end",
    },
  })
);
