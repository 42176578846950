import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getCommandLabel } from "../../../../../../Utils/DeviceConfiguration";
import WithRoleAuthorization from "../../../../../../Components/WithRoleAuthorization";
import { ptBR, colorV2Value } from "../../../../../../Utils/Functions";
import { SceneModel } from "../../../../../../Models/SceneModel";
import {
  makeStyles,
  Divider,
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { useContext } from "react";
import GlobalContext from "../../../../../../GlobalContext/GlobalContext";
import { executeScene } from "../../../../../../Services/AutomationService";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: "10px",
  },
  summaryContent: {
    "& .MuiAccordionSummary-content": {
      maxWidth: "90%",
    },
  },
  contentTitle: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titles: {
    minWidth: "50px",
  },
  titleLabel: {
    fontSize: "15px",
    fontWeight: 700,
    lineHeight: "20px",
    color: "#3E5055",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  subTitleLabel: {
    fontSize: "13px",
    fontWeight: 600,
    lineHeight: "18px",
    color: "#8B979F",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  clickToExecute: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    cursor: "pointer",
    fontSize: "12px",
    alignItems: "center",
    backgroundColor: "whitesmoke",
    borderRadius: "30px",
    minWidth: "185px",
  },
  clickToExecuteLabel: {
    margin: "auto",
    marginRight: "10px",
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#8B979F",
  },
  clickToExecuteContainer: {
    display: "flex",
    alignItems: "center",
  },
  clickToExecuteLabel2: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "19px",
    color: "#8B979F",
    margin: "initial",
    cursor: "pointer",
  },
  taskLabel: {
    fontSize: "13px",
    fontWeight: 700,
    lineHeight: "18px",
    color: "#3E5055",
  },
  taskRow: {
    minWidth: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "15px 0px",
  },
  taskRowLabel: {
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#8B979F",
    alignSelf: "center",
  },
  taskRowLabel2: {
    fontSize: "15px",
    fontWeight: 600,
    lineHeight: "20px",
    color: "#8B979F",
  },
  automationsIcons: {
    maxHeight: "35px",
    maxWidth: "35px",
    marginRight: "15px",
  },
}));

interface Props {
  scene: SceneModel;
}

const FavoritedAutomation: React.FunctionComponent<Props> = ({
  scene,
}: Props) => {
  const classes = useStyles();

  const { showAlert } = useContext(GlobalContext);

  const activeScene = async (scene: any) => {
    const response = await executeScene(scene.id);

    if (response.status === 200) {
      showAlert(
        'Comando de "' + scene.name + '" executado com sucesso',
        "success"
      );
    } else {
      showAlert("Houve um problema", "warning");
    }
  };

  return (
    <div className={classes.root}>
      <Accordion style={{ borderRadius: "10px", boxShadow: "none" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.summaryContent}
        >
          <div className={classes.contentTitle}>
            <div className={classes.titles}>
              <Typography className={classes.titleLabel}>
                {scene.name}
              </Typography>

              <Typography className={classes.subTitleLabel}>
                {scene.actions.length > 1
                  ? scene.actions.length + " Tarefas"
                  : "1 Tarefa"}
              </Typography>
            </div>

            <WithRoleAuthorization permitedRoles={["Owner", "Administrador"]}>
              <div
                className={classes.clickToExecute}
                onClick={(e) => {
                  activeScene(scene);
                  e.stopPropagation();
                }}
              >
                <IconButton color="primary">
                  <img src="assets/icons/tap-2.svg" />
                </IconButton>
                <p className={classes.clickToExecuteLabel}>
                  Clique para executar
                </p>
              </div>
            </WithRoleAuthorization>
          </div>
        </AccordionSummary>
        <Divider />
        <AccordionDetails style={{ maxHeight: "250px", overflowY: "auto" }}>
          <div style={{ width: "100%" }}>
            <Typography className={classes.taskLabel}>Condição</Typography>

            <div className={classes.clickToExecuteContainer}>
              <IconButton
                onClick={(e) => {
                  activeScene(scene);
                  e.stopPropagation();
                }}
              >
                <img src="assets/icons/tap-2.svg" />
              </IconButton>
              <p
                className={classes.clickToExecuteLabel2}
                onClick={(e) => {
                  activeScene(scene);
                  e.stopPropagation();
                }}
              >
                Clique para executar
              </p>
            </div>

            <Typography className={classes.taskLabel}>Tarefa</Typography>

            <div style={{ marginTop: "15px" }}>
              {scene.actions.map((action: any, index: number) => {
                const isActionDevice =
                  action.action_executor === "dpIssue" ? true : false;
                const isActionScene =
                  action.action_executor === "ruleTrigger" ? true : false;
                const isActionAutomation =
                  action.action_executor === "ruleEnable" ||
                  action.action_executor === "ruleDisable"
                    ? true
                    : false;
                const key = Object.keys(action.executor_property);

                if (isActionAutomation) {
                  return (
                    <div
                      className={classes.taskRow}
                      key={"favorited-automation-action-item-" + index}
                    >
                      <div style={{ display: "flex" }}>
                        <img
                          src="assets/icons/automation-temp-icon.svg"
                          className={classes.automationsIcons}
                        />
                        <Typography className={classes.taskRowLabel}>
                          {action.actionName}
                        </Typography>
                      </div>

                      <Typography
                        className={classes.taskRowLabel2}
                        style={{ alignSelf: "center" }}
                      >
                        {action.action_executor === "ruleEnable"
                          ? "Habilitar"
                          : "Desabilitar"}
                      </Typography>
                    </div>
                  );
                } else if (isActionScene) {
                  return (
                    <div
                      className={classes.taskRow}
                      key={"favorited-automation-action-item-" + index}
                    >
                      <Typography className={classes.taskRowLabel}>
                        {action.actionName}
                      </Typography>

                      <Typography className={classes.taskRowLabel2}>
                        Trigger
                      </Typography>
                    </div>
                  );
                } else if (isActionDevice) {
                  return (
                    <div
                      className={classes.taskRow}
                      key={"favorited-automation-action-item-" + index}
                    >
                      <div style={{ display: "flex" }}>
                        <img
                          src={action.device.icon}
                          className={classes.automationsIcons}
                        />
                        <Typography className={classes.taskRowLabel}>
                          {action.actionName}
                        </Typography>
                      </div>

                      <Typography
                        className={classes.taskRowLabel}
                        style={{
                          display: key[0] === "colour_data_v2" ? "flex" : "",
                        }}
                      >
                        {getCommandLabel(action.device.productName, key[0])}:
                        <span className={classes.taskRowLabel2}>
                          {action.executor_property[key[0]] === true ? (
                            " ON"
                          ) : action.executor_property[key[0]] === false ? (
                            " OFF"
                          ) : key[0] === "colour_data_v2" ? (
                            <div
                              style={{
                                border: "1px solid grey",
                                marginLeft: "10px",
                                backgroundColor: colorV2Value(
                                  action.executor_property[key[0]]
                                ),
                                height: "20px",
                                width: "20px",
                                borderRadius: "50%",
                              }}
                            ></div>
                          ) : (
                            " " +
                            ptBR(action.executor_property[key[0]].toString())
                          )}
                        </span>
                      </Typography>
                    </div>
                  );
                } else {
                  return (
                    <div
                      className={classes.taskRow}
                      key={"favorited-automation-action-item-" + index}
                    >
                      <Typography className={classes.taskRowLabel}>
                        Sem tarefas cadastradas
                      </Typography>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FavoritedAutomation;
