import { useState, useEffect, useContext } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { getFetchSpecifications } from "../../../../Services/DevicesService";
import Checkbox from "@material-ui/core/Checkbox";
import AllDevicesContext from "../../Context/AllDevicesContext";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Typography } from "@material-ui/core";
import CustomSwitchDevice from "../../../../Components/CustomSwitchDevice";
import DeviceModel, {
  DeviceFunctionsModel,
} from "../../../../Models/DeviceModel";
import DeviceDrawerComposite from "../../../../Components/CustomDevices/DeviceDrawerComposite";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import DevicesToRelocate from "../../../../Models/DevicesToRelocate";
import CustomBlockResource from "../../../../Components/CustomBlockResource";
import { ErrorMessage } from "../../../../Utils/ErrorMessage";
import { AxiosError } from "axios";

interface Props {
  device: DeviceModel;
  selectedProps?: boolean;
  isNotAssocied?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    paper_item: {
      display: "flex",
      padding: "19px",
      height: "190px",
      borderRadius: "20px",
      alignContent: "space-between",
      flexDirection: "column",
    },
    paper_content: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    paperContentName: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      flex: 1,
    },
    deviceName: {
      margin: "0px",
      color: "#3E5055",
      fontWeight: "bold",
      fontSize: "15px",
    },
    deviceNameRoomName: {
      margin: "0px",
      color: "#8B979F",
      fontWeight: "normal",
      fontSize: "13px",
    },
    offline: {
      fontSize: "12px",
      color: "#8B979F",
    },
    switch: {
      color: "#8B979F",
      fontSize: "12px !important",
    },
  })
);

interface TextProps {
  text: string;
}

const FiledText = ({ text }: TextProps) => {
  const classes = useStyles();
  return <Typography className={classes.switch}>{text}</Typography>;
};

const Device: React.FunctionComponent<Props> = ({ device }: Props) => {
  const { relocate, devices, removeDevice } = useContext(AllDevicesContext);
  const {
    devicesToRelocate,
    setDevicesToRelocate,
    toggleOpenDrawerApplication,
  } = useContext(GlobalContex);

  const [checked, setChecked] = useState<boolean | undefined>(false);
  const [deviceId, setDeviceId] = useState<DevicesToRelocate>(
    {} as DevicesToRelocate
  );

  const classes = useStyles();

  const [specificationsDevice, setSpecificationsDevice] =
    useState<DeviceFunctionsModel>({ category: "", functions: [], status: [] });

  useEffect(() => {
    return () => {
      setSpecificationsDevice({ category: "", functions: [], status: [] });
    };
  }, []);

  const handleChange = (check: boolean) => {
    setChecked(check);
    if (check) {
      setDevicesToRelocate([...devicesToRelocate, deviceId]);
    } else {
      setDevicesToRelocate([
        ...devicesToRelocate.filter(
          (device: DevicesToRelocate) => device.id !== deviceId.id
        ),
      ]);
    }
  };

  useEffect(() => {
    if (device.id) {
      const fetchSpecifications = async () => {
        try {
          const { data } = await getFetchSpecifications(device.id);
          setSpecificationsDevice(data);
        } catch (error) {
          const e = error as AxiosError;
          if (e.isAxiosError && e.request.status !== 400) {
            console.error(
              ErrorMessage(e, "Falha ao carregar comandos do dispositivo")
            );
          }
        }
      };

      fetchSpecifications();
      setDeviceId(device);
    }
  }, [device.id]);

  useEffect(() => {
    setChecked(false);
  }, [devices, relocate, removeDevice]);

  const checkClickDevice = () => {
    if (relocate) {
      const check = checked;
      handleChange(!check);
    }
  };

  const cardDeviceClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if ((event.target as HTMLDivElement).id === device.id || relocate) {
      return;
    }
    toggleOpenDrawerApplication(
      <DeviceDrawerComposite status={device.status} device={device} />
    );
  };

  return (
    <div
      style={{
        position: "relative",
        cursor: "pointer",
      }}
      onClick={(event) => {
        cardDeviceClick(event);
        if (relocate && (!device.block || removeDevice)) {
          checkClickDevice();
        }
      }}
    >
      {device.block && (
        <CustomBlockResource
          icon={"icon_padlock"}
          title={"Dispositivo bloqueado"}
          direction={"column"}
        />
      )}
      <Paper
        style={{
          backgroundColor: device.online ? "#FFF" : "#F2F2F2",
          filter: device.online ? "" : "grayscale(80%)",
          cursor: "pointer",
          position: "relative",
        }}
        className={classes.paper_item}
      >
        <div className={classes.paper_content}>
          <img
            style={{
              opacity: device.online ? "1" : "0.7",
              filter: device.online ? "" : "grayscale(80%)",
            }}
            src={device.icon}
            height="58"
            alt="Ícone do dispositivo"
          />
          {!device.online && !relocate && (
            <p className={classes.offline}>Off</p>
          )}
          {!relocate && device.online && (
            <FormControlLabel
              control={
                <CustomSwitchDevice
                  key={`switch-${device.id}`}
                  id={device.id}
                  functionsDevice={specificationsDevice}
                  status={device.status}
                />
              }
              labelPlacement="bottom"
              label={
                device?.online ? (
                  <FiledText text={"Ligado"} />
                ) : (
                  <FiledText text="Desligado" />
                )
              }
            />
          )}
          {relocate && (!device.block || removeDevice) && (
            <Checkbox
              key={`checkbox-${device.id}`}
              checked={checked}
              inputProps={{ "aria-label": "primary checkbox" }}
              style={{ zIndex: 2 }}
            />
          )}
        </div>

        <div className={classes.paperContentName}>
          <p className={classes.deviceName}> {device.name} </p>
          <p className={classes.deviceNameRoomName}>{device.place_name}</p>
        </div>
      </Paper>
    </div>
  );
};

export default Device;
