import styles from "./style.module.css";
import CheckIcon from "@material-ui/icons/Check";
import { PasswordStrengthRules } from "./index";

type StrengthMeterProps = {
  rules: PasswordStrengthRules;
  minLength: number;
};

const StrengthMeter: React.FunctionComponent<StrengthMeterProps> = ({
  rules,
  minLength,
}: StrengthMeterProps) => {
  return (
    <>
      <ul className={styles.List}>
        <li>
          <CheckIcon
            fontSize={"small"}
            className={styles.List__item__icon}
            color={rules.hasMinLength ? "secondary" : "inherit"}
          />{" "}
          Mínimo de {minLength} caracteres
        </li>
        <li>
          <CheckIcon
            fontSize={"small"}
            className={styles.List__item__icon}
            color={rules.hasLowcaseLetter ? "secondary" : "inherit"}
          />{" "}
          Ao menos uma letra minúscula
        </li>
        <li>
          <CheckIcon
            fontSize={"small"}
            className={styles.List__item__icon}
            color={rules.hasUppercaseLetter ? "secondary" : "inherit"}
          />{" "}
          Ao menos uma letra maiúscula
        </li>
        <li>
          <CheckIcon
            fontSize={"small"}
            className={styles.List__item__icon}
            color={rules.hasNumber ? "secondary" : "inherit"}
          />{" "}
          Ao menos um número
        </li>
        <li>
          <CheckIcon
            fontSize={"small"}
            className={styles.List__item__icon}
            color={rules.hasSpecialChar ? "secondary" : "inherit"}
          />{" "}
          Ao menos um símbolo #?!@$%&*-
        </li>
      </ul>
    </>
  );
};
export default StrengthMeter;
