import styled from "styled-components";

export const Container = styled.div`
  width: 450px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #3e5055;
  padding: 20px 30px;
`;

export const Content = styled.div`
  padding: 20px 30px 0px 30px;
  height: 300px;
  overflow: auto;
`;

export const ContentText = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #8b979f;
  margin-bottom: 20px;
`;

export const Footer = styled.div`
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonSend = styled.a`
  display: flex;
  padding: 10px 40px;
  background-color: #00a335;
  border-radius: 3px;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
  min-width: 240px;
  text-align: center;
  text-decoration: none;
  &:hover: {
    color: white;
  }
`;
