import { createContext } from "react";

type SideMenuContextProps = {
  search: string;
  setSearch: (value: string) => void;
  expandedAll: boolean;
  setExpandedAll: (value: boolean) => void;
  handleChangeCollapseSubmenu: (
    submenuTitle: string,
    persist?: boolean
  ) => void;
};

const SideMenuContext = createContext({} as SideMenuContextProps);

export default SideMenuContext;
