import { makeStyles } from "@material-ui/core";
import BatteryAlertIcon from "@material-ui/icons/BatteryAlert";
import Battery20Icon from "@material-ui/icons/Battery20";
import Battery30Icon from "@material-ui/icons/Battery30";
import Battery50Icon from "@material-ui/icons/Battery50";
import Battery60Icon from "@material-ui/icons/Battery60";
import Battery80Icon from "@material-ui/icons/Battery80";
import Battery90Icon from "@material-ui/icons/Battery90";
import BatteryFullIcon from "@material-ui/icons/BatteryFull";
import BatteryUnknownIcon from "@material-ui/icons/BatteryUnknown";

interface Props {
  value: number;
  fontSize?: "inherit" | "large" | "medium" | "small" | "default";
  color?: string;
  isAlertActive?: boolean;
  alertColor?: string;
  alertColorActive?: string;
  fontColor?: string;
}

const CustomDeviceBatteryIcon: React.FunctionComponent<Props> = ({
  value,
  fontSize = "large",
  color = "#6DCC9A",
  isAlertActive = false,
  alertColor = "#EF847E",
  alertColorActive = "#EF847E",
  fontColor = "#3E5055",
}: Props) => {
  const classes = useStyles();

  const batteryIcon = (value: number) => {
    if (value < 0) {
      return <BatteryUnknownIcon fontSize={fontSize} htmlColor={color} />;
    }
    if (value < 20) {
      return (
        <BatteryAlertIcon
          fontSize={fontSize}
          htmlColor={isAlertActive ? alertColorActive : alertColor}
        />
      );
    }
    if (value < 30) {
      return <Battery20Icon fontSize={fontSize} htmlColor={color} />;
    }
    if (value < 50) {
      return <Battery30Icon fontSize={fontSize} htmlColor={color} />;
    }
    if (value < 60) {
      return <Battery50Icon fontSize={fontSize} htmlColor={color} />;
    }
    if (value < 80) {
      return <Battery60Icon fontSize={fontSize} htmlColor={color} />;
    }
    if (value < 90) {
      return <Battery80Icon fontSize={fontSize} htmlColor={color} />;
    }
    if (value < 100) {
      return <Battery90Icon fontSize={fontSize} htmlColor={color} />;
    }
    return <BatteryFullIcon fontSize={fontSize} htmlColor={color} />;
  };

  return (
    <div className={classes.batteryContainer}>
      {batteryIcon(value)}
      <div className={classes.batteryLabel} style={{ color: fontColor }}>
        {value} %
      </div>
    </div>
  );
};

export default CustomDeviceBatteryIcon;

const useStyles = makeStyles(() => ({
  batteryContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  batteryLabel: {
    fontWeight: 600,
    fontSize: "21px",
    lineHeight: "29px",
    textAlign: "center",
  },
}));
