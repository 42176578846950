import { useContext, useState, useEffect } from "react";
import NotificationConfigContext from "../../../../../Context/NotificationConfigContext";
import MuiDialogContent from "@material-ui/core/DialogContent";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import { Dialog, Grid, IconButton, TextField } from "@material-ui/core";
import Places from "./Places";
import SelectionDevicestoAssociate from "./SelectionDevicestoAssociate";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { days } from "../../../../../../../Utils/DaysSchedule";
export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      minWidth: "70%",
      height: "80%",
    },
    normalButton: {
      backgroundColor: "#EBEEEE",
      width: "20px",
      height: "20px",
      marginRight: "13px",
      fontSize: "18px",
    },
    selectedButton: {
      backgroundColor: "#00A335",
      width: "20px",
      height: "20px",
      marginRight: "13px",
      fontSize: "18px",
      color: "#FFFFFF",
    },
    title: {
      fontWeight: 700,
      fontSize: "15px",
      lineHeight: "20px",
      color: "#3E5055",
    },
    actions: {
      padding: "16px 80px",
      border: "none !important",
    },
    buttonCancel: {
      textTransform: "none",
      color: "#8B979F",
      padding: "7px 35px",

      "&.MuiButton-root": {
        border: "1px #BDBDBD solid",
      },
    },
    buttonSave: {
      textTransform: "none",
      padding: "7px 45px",
    },
  })
);

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const SelectDevicesModal: React.FunctionComponent = () => {
  const {
    openModal,
    closeModalNotification,
    devicesSelectedToConfigNotification,
    resetForm,
    saveNotificationConfig,
    repetitionSchedule,
    changeRepeat,
    setTimeSchedule,
    setTimeScheduleUntil,
    selectedDateLocalInitial,
    handleDateChangeLocalInitial,
    selectedDateLocalFinal,
    handleDateChangeLocalFinal,
  } = useContext(NotificationConfigContext);

  useEffect(() => {
    setTimeSchedule("00:00");
    setTimeScheduleUntil("00:00");
  }, []);

  const [, setIndex] = useState<number>(0);

  function handleInitialDate(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    handleDateChangeLocalInitial(e.target.value);
    setTimeSchedule(e.target.value);
  }

  function handleFinalDate(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    handleDateChangeLocalFinal(e.target.value);
    setTimeScheduleUntil(e.target.value);
  }
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      onClose={closeModalNotification}
      open={openModal}
      classes={{ paper: classes.dialog }}
    >
      <DialogContent dividers>
        <Grid container>
          <Grid item xs={3}>
            <p className={classes.title}>Locais</p>
            <Places />
          </Grid>
          <Grid item xs={9}>
            <p className={classes.title}>Dispositivos selecionados</p>
            <SelectionDevicestoAssociate />
            <div
              style={{
                border: "1px solid #D1DAE0",
                borderRadius: "5px",
                padding: "10px",
                display: "flex",
                flex: 1,
                justifyContent: "spaace-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                Horário de:
                <TextField
                  style={{
                    margin: "0px 10px",
                  }}
                  type="time"
                  value={selectedDateLocalInitial}
                  onChange={handleInitialDate}
                />
                Até mesmo dia
                <TextField
                  style={{
                    margin: "0px 10px",
                  }}
                  type="time"
                  value={selectedDateLocalFinal}
                  onChange={handleFinalDate}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {days.map((day, i) => (
                  <IconButton
                    key={"day-button-" + i}
                    onClick={() => {
                      setIndex(i);
                      changeRepeat(1, i);
                    }}
                    className={
                      repetitionSchedule[i] === 1
                        ? classes.selectedButton
                        : classes.normalButton
                    }
                  >
                    {day.initial}
                  </IconButton>
                ))}
              </div>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            closeModalNotification();
            resetForm();
          }}
          className={classes.buttonCancel}
        >
          Cancelar
        </Button>
        <Button
          color="secondary"
          variant="contained"
          className={classes.buttonSave}
          autoFocus
          onClick={() =>
            saveNotificationConfig(
              devicesSelectedToConfigNotification.map((device) => device.id)
            )
          }
          disabled={devicesSelectedToConfigNotification.length === 0}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default SelectDevicesModal;
