import React, { useState } from "react";
import PasswordUniqueBox from "../PasswordUniqueBox";
import PasswordTempBox from "../PasswordTempBox";
import PasswordScheduleBox from "../PasswordScheduleBox";

import * as S from "./styled";

type PasswordDefinitionsContainerProps = {
  deviceId: string;
};

const PasswordDefinitionsContainer: React.FunctionComponent<
  PasswordDefinitionsContainerProps
> = ({ deviceId }) => {
  const [expanded, setExpanded] = useState<string>();

  const changeExpanded = (name: string) => {
    expanded === name ? setExpanded("") : setExpanded(name);
  };

  return (
    <S.Container>
      <PasswordUniqueBox
        deviceId={deviceId}
        expanded={expanded === "password-uniq"}
        onChange={() => {
          changeExpanded("password-uniq");
        }}
      />

      <PasswordTempBox
        deviceId={deviceId}
        expanded={expanded === "password-temp"}
        onChange={() => {
          changeExpanded("password-temp");
        }}
      />

      <PasswordScheduleBox
        deviceId={deviceId}
        expanded={expanded === "password-schedule"}
        onChange={() => {
          changeExpanded("password-schedule");
        }}
      />
    </S.Container>
  );
};

export default PasswordDefinitionsContainer;
