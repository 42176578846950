import MiniDrawer from "../../Components/CustomMiniDrawer";
import ManageRoomProvider from "./Context/ManageRoomProvider";
import Rooms from "./Components/Rooms";
import {
  getHomeNameSelect,
  getPlaceNameSelect,
} from "../../Services/UserService";
import SubHeader from "../../Components/SubHeader";
import history from "../../Services/history";

const ManageRoom: React.FunctionComponent = () => {
  const backPress = () => {
    history.push("manage-place");
  };

  return (
    <MiniDrawer
      breadcrumb={[
        { name: getHomeNameSelect(), to: "/manage-place" },
        { name: getPlaceNameSelect(), to: "/manage-room" },
      ]}
    >
      <ManageRoomProvider>
        <SubHeader
          text={"Espaços"}
          subText={
            "Os espaços servem para dividir um local, crie um novo ou selecione para visualizar os ambientes e dispositivos dentro"
          }
          backPress={backPress}
        />
        <Rooms />
      </ManageRoomProvider>
    </MiniDrawer>
  );
};

export default ManageRoom;
