import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

const diasSemana = [
  "Domingo",
  "Segunda-feira",
  "Terça-feira",
  "Quarta-feira",
  "Quinta-feira",
  "Sexta-feira",
  "Sábado",
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Button = styled.button`
  background-color: #ebeeee;
  border: none;
  width: 70%;
  padding: 10px;
  border-radius: 3px;
`;

const Label = styled.label`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  /* Cinza tipografia */
  color: #3e5055;
`;

interface PropsComponent {
  label?: string;
  minDate?: Date;
  startDate?: Date;
  endDate?: Date;
  selected?: Date;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  onChange?: (
    date: Date | null,
    event: React.SyntheticEvent<any, Event> | undefined
  ) => void;
}

const CustomDatePicker: React.FunctionComponent<PropsComponent> = ({
  label = "Dia",
  onChange,
  ...props
}: PropsComponent) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Container>
      <Label>{label}</Label>
      <Button
        className="example-custom-input"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {props.selected &&
          diasSemana[props.selected.getDay()] +
            ", " +
            format(props.selected, "dd") +
            " de " +
            format(props.selected, "MMMM", { locale: ptBR })}
      </Button>
      {isOpen && (
        <DatePicker
          onChange={(date, e) => {
            setIsOpen(!isOpen);
            if (onChange) {
              onChange(date, e);
            }
          }}
          inline
          {...props}
        />
      )}
    </Container>
  );
};

export default CustomDatePicker;
