import { useContext } from "react";
import PlaceList from "../PlaceList";
import SubHeader from "../../../../Components/SubHeader";
import Filters from "../../Components/Filters";
import history from "../../../../Services/history";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import { Divider } from "@material-ui/core";
import CustomDrawerCreatePlace from "../CustomDrawerCreatePlace/indext";

const TogglePlaceListOrRegister: React.FunctionComponent = () => {
  const { expandSubHeader, setIsHomeSelected } = useContext(GlobalContex);

  const backPress = () => {
    setIsHomeSelected(false);
    history.push("select-home");
  };

  return (
    <>
      <SubHeader
        text={"Locais"}
        subText={
          "Crie um novo local ou selecione um para navegar pelos espaços"
        }
        backPress={backPress}
      />
      <div
        style={{
          padding: "0px 3rem",
        }}
      >
        {expandSubHeader && <Filters />}
        <Divider />
      </div>
      <PlaceList />
      <CustomDrawerCreatePlace />
    </>
  );
};

export default TogglePlaceListOrRegister;
