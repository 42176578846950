import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { withStyles } from "@material-ui/core/styles";
import { useContext } from "react";
import GlobalContex from "../../GlobalContext/GlobalContext";
import { ListItemModel } from "../../Models/MenuItemModel";
import MenuItem from "./MenuItem";
import PlaceMenuItem from "./PlaceMenuItem";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import clsx from "clsx";
import { Link } from "@material-ui/core";
import history from "../../Services/history";
import SideMenuContext from "./Context/SideMenuContext";

const AccordionStyled = withStyles({
  root: {
    border: "1px solid",
    borderColor: "#36B35F",
    borderRight: 0,
    borderLeft: 0,
    width: "100%",
    margin: 0,
    boxShadow: "none",
    backgroundColor: "#00a335",
    "&.active": {
      backgroundColor: "#19ac49",
    },
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:last-child)": {
      borderRadius: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: 0,
    },
    "& .MuiAccordionDetails-root": {
      display: "flex",
      flexDirection: "column",
      gap: "2px",
      padding: 0,
    },
    "& .MuiAccordionSummary-content": {
      margin: "0px",
      minHeight: "48px",
    },
    "& .MuiAccordionSummary-content > .title": {
      width: "100%",
      fontSize: "13px",
      color: "#68ECA7",
      textDecoration: "none",
      paddingLeft: "16px",
      lineHeight: "48px",
      "&:hover": {
        textDecoration: "none",
        color: "#68ECA7",
      },
    },
  },
  expanded: {},
  rounded: {},
})(Accordion);

const AccordionSummaryStyled = withStyles({
  root: {
    paddingLeft: "0px",
    minHeight: 48,
    "&$expanded": {
      minHeight: 48,
    },
  },
  content: {
    "&$expanded": {
      margin: 0,
    },
  },
  expanded: {},
})(AccordionSummary);

type SubmenuItemProps = {
  title: string;
  link?: string;
  items: ListItemModel[];
};

let counter = 0;

const SubmenuItem: React.FunctionComponent<SubmenuItemProps> = (
  props: SubmenuItemProps
) => {
  counter = +1;
  const id = counter;

  const { pathnameUrl, sideMenuExpanded } = useContext(GlobalContex);

  const { expandedAll, handleChangeCollapseSubmenu } =
    useContext(SideMenuContext);

  const isActived = () => {
    return props.items.some((item) => item.link === pathnameUrl);
  };

  const isExpanded = () => {
    return sideMenuExpanded.some((item) => item === props.title || expandedAll);
  };

  const navigateToLink = (link: string) => {
    history.push(link);
  };

  return (
    <AccordionStyled
      square
      expanded={isExpanded()}
      onChange={() => handleChangeCollapseSubmenu(props.title)}
      className={clsx({ active: isActived() })}
      id={`submenu-${id}`}
    >
      <AccordionSummaryStyled
        expandIcon={<ExpandMoreIcon htmlColor="#68ECA7" />}
        id={`submenu-summary-${id}`}
      >
        <Link
          className={"title"}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            if (props.link) {
              navigateToLink(props.link);
            }
          }}
          style={{ cursor: props.link ? "pointer" : "initial" }}
        >
          {props.title.toUpperCase()}
        </Link>
      </AccordionSummaryStyled>
      <AccordionDetails>
        {props.items.map((subitem: ListItemModel) => {
          return subitem.place && subitem.place.rooms.length >= 0 ? (
            <PlaceMenuItem
              link={subitem.link}
              icon={subitem.icon}
              text={subitem.text}
              place={subitem.place}
            />
          ) : (
            <MenuItem
              link={subitem.link}
              icon={subitem.icon}
              text={subitem.text}
            />
          );
        })}
      </AccordionDetails>
    </AccordionStyled>
  );
};

export default SubmenuItem;
