import ScheduleRoundedIcon from "@material-ui/icons/ScheduleRounded";
import InfiniteScroll from "react-infinite-scroll-component";
import DeviceModel from "../../../Models/DeviceModel";
import { useEffect, useState } from "react";
import { LogModel, LogsModel } from "../../../Models/LogsModel";
import { getLogDevices } from "../../../Services/DevicesService";
import { ErrorMessage } from "../../../Utils/ErrorMessage";

import CardsLogs from "./CardsLogs";
import CardsLogsSkeleton from "./CardsLogsSkeleton";

import * as S from "./styled";

type Props = {
  device: DeviceModel;
};

const EventHistoryContainer: React.FunctionComponent<Props> = ({
  device,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState<LogModel[]>([]);
  const [loadingMoreLogs, setLoadingMoreLogs] = useState<boolean>(false);
  const [deviceLogsInfo, setDeviceLogsInfo] = useState<LogsModel>();

  useEffect(() => {
    fetchDeviceLogs();
  }, []);

  const fetchDeviceLogs = () => {
    setLoading(true);
    return getLogDevices(device.id)
      .then((response) => {
        setDeviceLogsInfo(response.data);
        setLogs(response.data.logs);
        setLoadingMoreLogs(response.data.has_next);
      })
      .catch((e) => {
        const msg = ErrorMessage(
          e,
          "Não foi possível carregar o histórico do dispositivo"
        );
        console.log(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchMoreLogs = async () => {
    if (!deviceLogsInfo) return;
    try {
      const response = await getLogDevices(device.id, {
        row_key: deviceLogsInfo.next_row_key,
      });
      setDeviceLogsInfo(response.data);
      setLogs([...logs, ...response.data.logs]);
      setLoadingMoreLogs(response.data.has_next);
      return;
    } catch (error) {
      setLoadingMoreLogs(false);
      return;
    }
  };

  return (
    <S.EventsContainer>
      <S.EventsContainerTitle>
        <ScheduleRoundedIcon fontSize="small" />
        <h2>Histórico</h2>
      </S.EventsContainerTitle>

      <S.EventsContainerBody>
        {loading && logs.length === 0 && (
          <CardsLogsSkeleton style={{ padding: "10px 20px" }} />
        )}

        <InfiniteScroll
          dataLength={logs.length}
          next={fetchMoreLogs}
          hasMore={loadingMoreLogs}
          height={"100%"}
          loader={<CardsLogsSkeleton />}
        >
          {logs?.length > 0 && <CardsLogs logs={logs} device={device} />}
          {!loading && logs?.length === 0 && (
            <p>O dispositivo não possui log</p>
          )}
        </InfiniteScroll>
      </S.EventsContainerBody>
    </S.EventsContainer>
  );
};

export default EventHistoryContainer;
