import { makeStyles, createStyles } from "@material-ui/core/styles";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DeviceModel from "../../../../../../Models/DeviceModel";
import GlobalContex from "../../../../../../GlobalContext/GlobalContext";
import { useContext } from "react";

const useStyles = makeStyles(() =>
  createStyles({
    controlTVBtnTopContainer: {
      display: "flex",
      justifyContent: "space-between",
      paddingRight: "15px",
      paddingLeft: "15px",
    },
    controlTVBtnMiddleContainer: {
      height: "150px",
      marginTop: "30px",
      display: "flex",
      justifyContent: "space-between",
      paddingRight: "15px",
      paddingLeft: "15px",
    },
    controlTVBtnMiddleLine: {
      height: "50px",
      display: "flex",
      justifyContent: "space-between",
    },
    controlTVBtnMiddleItem: {
      height: "50px",
      display: "flex",
    },
    controlTVBtnMiddleItemTop: {
      alignItems: "start",
    },
    controlTVBtnMiddleItemLeft: {
      alignItems: "center",
    },
    controlTVBtnMiddleItemCenter: {
      alignItems: "center",
      fontSize: "23px",
      color: "#3E5055",
    },
    controlTVBtnMiddleItemRight: {
      alignItems: "center",
    },
    controlTVBtnMiddleItemBottom: {
      alignItems: "end",
    },
    controlTVBtnBottomContainer: {
      height: "260px",
      marginTop: "34px",
    },
    controlTVBtnBottomLine: {
      display: "flex",
      justifyContent: "space-between",
      paddingRight: "50px",
      paddingLeft: "50px",
      marginBottom: "20px",
    },
    controlTVBtnStyle: {
      background: "#FFFFFF",
      border: "1px solid #D1DAE0",
      boxShadow: "1px 1px 10px 4px #00000014",
      borderRadius: "50px",
    },
    controlTVBtnStyleTwo: {
      color: "#D1DAE0",
      background: "#F7F7F7",
    },
    controlTVBtnStyleThree: {
      color: "#BDBDBD",
      background: "#F7F7F7",
    },
    controlTVBtn: {
      height: "50px",
      width: "50px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    controlTVBtnMedium: {
      height: "150px",
      width: "50px",
    },
    controlTVBtnLarge: {
      height: "150px",
      width: "150px",
      borderRadius: "75px",
    },
    controlTVBtnMore: {
      height: "50px",
      width: "50px",
      display: "flex",
      alignItems: "end",
      justifyContent: "center",
      cursor: "pointer",
    },
    controlTVBtnMoreLabel: {
      lineHeight: "0.5",
      textAlign: "center",
      color: "#3E5055",
    },
    controlTVLabelTopContainer: {
      marginTop: "8px",
      height: "18px",
      display: "flex",
      justifyContent: "space-between",
      paddingRight: "20px",
      color: "#3E5055",
    },
    controlTVBtnMediumElements: {
      height: "50px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    controlTVBtnMediumElementsLabel: {
      color: "#3E5055",
    },
    controlTVBtnMediumIcons: {
      cursor: "pointer",
    },
  })
);

interface Props {
  device: DeviceModel;
}

const TV_Control: React.FunctionComponent<Props> = ({ device }: Props) => {
  const classes = useStyles();
  const backIcon = "assets/icons/back.svg";
  const { handleChangeCommand } = useContext(GlobalContex);

  function doCommand(code: string, value: string) {
    handleChangeCommand(code, device.id, value);
  }

  return (
    <>
      <div className={classes.controlTVBtnTopContainer}>
        <div
          className={classes.controlTVBtn + " " + classes.controlTVBtnStyle}
          onClick={() => doCommand("Power", "Power")}
        >
          <PowerSettingsNewIcon />
        </div>
        <div
          className={
            classes.controlTVBtn +
            " " +
            classes.controlTVBtnStyle +
            " " +
            classes.controlTVBtnStyleTwo
          }
          onClick={() => doCommand("Back", "Back")}
        >
          <img src={backIcon} alt="" />
        </div>
        <div
          className={
            classes.controlTVBtn +
            " " +
            classes.controlTVBtnStyle +
            " " +
            classes.controlTVBtnStyleTwo
          }
          onClick={() => doCommand("Home", "Home")}
        >
          <HomeIcon />
        </div>
        <div
          className={
            classes.controlTVBtn +
            " " +
            classes.controlTVBtnStyle +
            " " +
            classes.controlTVBtnStyleTwo
          }
          onClick={() => doCommand("Menu", "Menu")}
        >
          <MenuIcon />
        </div>
      </div>
      <div className={classes.controlTVLabelTopContainer}>
        <div>Ligar/Desligar</div>
        <div>Menu</div>
      </div>
      <div className={classes.controlTVBtnMiddleContainer}>
        <div
          className={
            classes.controlTVBtnMedium + " " + classes.controlTVBtnStyle
          }
        >
          <div
            className={
              classes.controlTVBtnMediumElements +
              " " +
              classes.controlTVBtnMediumIcons
            }
            onClick={() => doCommand("Volume+", "Volume+")}
          >
            <AddIcon />
          </div>
          <div
            className={
              classes.controlTVBtnMediumElements +
              " " +
              classes.controlTVBtnMediumElementsLabel
            }
          >
            Vol
          </div>
          <div
            className={
              classes.controlTVBtnMediumElements +
              " " +
              classes.controlTVBtnMediumIcons
            }
            onClick={() => doCommand("Volume-", "Volume-")}
          >
            <RemoveIcon />
          </div>
        </div>
        <div
          className={
            classes.controlTVBtnLarge + " " + classes.controlTVBtnStyle
          }
        >
          <div className={classes.controlTVBtnMiddleLine}>
            <div className={classes.controlTVBtnMiddleItem}></div>
            <div
              className={
                classes.controlTVBtnMiddleItemTop +
                " " +
                classes.controlTVBtnMiddleItem +
                " " +
                classes.controlTVBtnMediumIcons
              }
              onClick={() => doCommand("Up", "Up")}
            >
              <ArrowDropUpIcon />
            </div>
            <div className={classes.controlTVBtnMiddleItem}></div>
          </div>
          <div className={classes.controlTVBtnMiddleLine}>
            <div
              className={
                classes.controlTVBtnMiddleItemLeft +
                " " +
                classes.controlTVBtnMiddleItem +
                " " +
                classes.controlTVBtnMediumIcons
              }
              onClick={() => doCommand("Left", "Left")}
            >
              <ArrowLeftIcon />
            </div>
            <div
              className={
                classes.controlTVBtnMiddleItemCenter +
                " " +
                classes.controlTVBtnMiddleItem +
                " " +
                classes.controlTVBtnMediumIcons
              }
              onClick={() => doCommand("OK", "OK")}
            >
              OK
            </div>
            <div
              className={
                classes.controlTVBtnMiddleItemRight +
                " " +
                classes.controlTVBtnMiddleItem +
                " " +
                classes.controlTVBtnMediumIcons
              }
              onClick={() => doCommand("Right", "Right")}
            >
              <ArrowRightIcon />
            </div>
          </div>
          <div className={classes.controlTVBtnMiddleLine}>
            <div className={classes.controlTVBtnMiddleItem}></div>
            <div
              className={
                classes.controlTVBtnMiddleItemBottom +
                " " +
                classes.controlTVBtnMiddleItem +
                " " +
                classes.controlTVBtnMediumIcons
              }
              onClick={() => doCommand("Down", "Down")}
            >
              <ArrowDropDownIcon />
            </div>
            <div className={classes.controlTVBtnMiddleItem}></div>
          </div>
        </div>
        <div
          className={
            classes.controlTVBtnMedium + " " + classes.controlTVBtnStyle
          }
        >
          <div
            className={
              classes.controlTVBtnMediumElements +
              " " +
              classes.controlTVBtnMediumIcons
            }
            onClick={() => doCommand("Channel+", "Channel+")}
          >
            <AddIcon />
          </div>
          <div
            className={
              classes.controlTVBtnMediumElements +
              " " +
              classes.controlTVBtnMediumElementsLabel
            }
          >
            Canal
          </div>
          <div
            className={
              classes.controlTVBtnMediumElements +
              " " +
              classes.controlTVBtnMediumIcons
            }
            onClick={() => doCommand("Channel-", "Channel-")}
          >
            <RemoveIcon />
          </div>
        </div>
      </div>
      <div className={classes.controlTVBtnBottomContainer}>
        <div className={classes.controlTVBtnBottomLine}>
          <div
            className={classes.controlTVBtn + " " + classes.controlTVBtnStyle}
            onClick={() => doCommand("1", "1")}
          >
            1
          </div>
          <div
            className={classes.controlTVBtn + " " + classes.controlTVBtnStyle}
            onClick={() => doCommand("2", "2")}
          >
            2
          </div>
          <div
            className={classes.controlTVBtn + " " + classes.controlTVBtnStyle}
            onClick={() => doCommand("3", "3")}
          >
            3
          </div>
        </div>
        <div className={classes.controlTVBtnBottomLine}>
          <div
            className={classes.controlTVBtn + " " + classes.controlTVBtnStyle}
            onClick={() => doCommand("4", "4")}
          >
            4
          </div>
          <div
            className={classes.controlTVBtn + " " + classes.controlTVBtnStyle}
            onClick={() => doCommand("5", "5")}
          >
            5
          </div>
          <div
            className={classes.controlTVBtn + " " + classes.controlTVBtnStyle}
            onClick={() => doCommand("6", "6")}
          >
            6
          </div>
        </div>
        <div className={classes.controlTVBtnBottomLine}>
          <div
            className={classes.controlTVBtn + " " + classes.controlTVBtnStyle}
            onClick={() => doCommand("7", "7")}
          >
            7
          </div>
          <div
            className={classes.controlTVBtn + " " + classes.controlTVBtnStyle}
            onClick={() => doCommand("8", "8")}
          >
            8
          </div>
          <div
            className={classes.controlTVBtn + " " + classes.controlTVBtnStyle}
            onClick={() => doCommand("9", "9")}
          >
            9
          </div>
        </div>
        <div className={classes.controlTVBtnBottomLine}>
          <div
            className={
              classes.controlTVBtn +
              " " +
              classes.controlTVBtnStyle +
              " " +
              classes.controlTVBtnStyleThree
            }
            onClick={() => doCommand("-/--", "-/--")}
          >
            -/--
          </div>
          <div
            className={classes.controlTVBtn + " " + classes.controlTVBtnStyle}
            onClick={() => doCommand("0", "0")}
          >
            0
          </div>
          <div
            className={classes.controlTVBtn}
            style={{ cursor: "initial" }}
          ></div>
          {/* <div className={classes.controlTVBtnMore + ' ' + classes.controlTVBtnStyle}>
          <div className={classes.controlTVBtnMoreLabel} onClick={() => setMore(true)}>Mais <br /> <MoreHorizIcon  style={{color: "#3E5055"}}/></div>
        </div> */}
        </div>
      </div>
    </>
  );
};
export default TV_Control;
