import MiniDrawer from "../../Components/CustomMiniDrawer";
import FormCancelSignature from "./Components/FormCancelSignature";
import CancelSignatureProvider from "./Context/CancelSignatureProvider";

const CancelSignaturePage: React.FunctionComponent = () => {
  return (
    <MiniDrawer
      withoutSideMenu={true}
      showSideMenu={false}
      breadcrumb={[{ name: "Cancelar Assinatura", to: "cancelar-assinatura" }]}
    >
      <CancelSignatureProvider>
        <FormCancelSignature />
      </CancelSignatureProvider>
    </MiniDrawer>
  );
};

export default CancelSignaturePage;
