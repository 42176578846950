import * as React from "react";
import * as S from "./styles";

type CustomTableContainerProps = {
  children: React.ReactNode;
  maxHeight?: string;
  padding?: string;
};

const CustomTableContainer: React.FunctionComponent<
  CustomTableContainerProps
> = ({ children, maxHeight, padding }: CustomTableContainerProps) => {
  return (
    <S.TableContainer $maxHeight={maxHeight} $padding={padding}>
      {children}
    </S.TableContainer>
  );
};

export default CustomTableContainer;
