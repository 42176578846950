import { Avatar } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useState } from "react";
import HelpCenter from "../HelpCenter";
import MenuList from "../MenuList";
import Shop from "../Shop";

const MenuButtom: React.FunctionComponent = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [openMenuList, setOpenMenuList] = useState<boolean>(false);
  const [openHelp, setOpenHelp] = useState<boolean>(false);
  const [openShop, setOpenShop] = useState<boolean>(false);

  const handleOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
    setOpenMenuList(true);
  };

  const handleClose = () => {
    setOpenMenuList(false);
    setOpenHelp(false);
    setOpenShop(false);
    setAnchorEl(null);
  };

  const clickOpenHelp = () => {
    setOpenMenuList(false);
    setOpenHelp(true);
  };

  const clickOpenShop = () => {
    setOpenMenuList(false);
    setOpenShop(true);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Avatar
        style={{
          backgroundColor: open ? "#00a335" : "#EBEEEE",
          cursor: "pointer",
          marginLeft: "15px",
        }}
        onClick={handleOpen}
      >
        <MoreVertIcon htmlColor={open ? "FFFFFF" : "#8B979F"} />
      </Avatar>
      <MenuList
        open={openMenuList}
        anchor={anchorEl}
        onClose={handleClose}
        onClickHelp={clickOpenHelp}
        onClickShop={clickOpenShop}
      />
      <HelpCenter open={openHelp} anchor={anchorEl} onClose={handleClose} />
      <Shop open={openShop} anchor={anchorEl} onClose={handleClose} />
    </div>
  );
};

export default MenuButtom;
