import { ButtonProps, CircularProgress } from "@material-ui/core";
import React from "react";
import ButtonStyled from "../ButtonStyled";

export type ButtonGenericProps = ButtonProps & {
  loading?: boolean;
  loadingCaption?: string;
};

const ButtonGeneric: React.FunctionComponent<ButtonGenericProps> = ({
  children,
  loading,
  loadingCaption,
  ...props
}) => {
  const icon = loading ? (
    <CircularProgress color={"inherit"} size={20} />
  ) : (
    props.startIcon
  );

  return (
    <ButtonStyled {...props} startIcon={icon} disabled={loading}>
      {loading && loadingCaption && loadingCaption}
      {!(loading && loadingCaption) && children}
    </ButtonStyled>
  );
};

export default ButtonGeneric;
