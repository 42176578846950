import { makeStyles, createStyles } from "@material-ui/core/styles";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

const useStyles = makeStyles(() =>
  createStyles({
    controlTVBtnLine: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "17px",
      height: "50px",
    },
    controlTVBtnStyle: {
      background: "#FFFFFF",
      border: "1px solid #D1DAE0",
      boxShadow: "1px 1px 10px 4px #00000014",
      height: "50px",
      fontSize: "13px",
      color: "#3E5055",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    controlTVBtnStyleSmall: {
      height: "50px",
      width: "50px",
      borderRadius: "50px",
      display: "flex",
      alignItems: "end",
      justifyContent: "center",
    },
    controlTVBtnStyleMedium: {
      width: "101px",
      borderRadius: "25px",
      lineHeight: "18px",
    },
    controlTVBtnStyleSmallContainer: {
      width: "101px",
      justifyContent: "center",
      display: "flex",
    },
    controlTVBtnStyleSmallLabel: {
      lineHeight: "0.5",
      textAlign: "center",
    },
  })
);

interface Props {
  setMore: (arg0: boolean) => void;
}

const TV_ControlMore: React.FunctionComponent<Props> = ({ setMore }: Props) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.controlTVBtnLine}>
        <div
          className={
            classes.controlTVBtnStyle + " " + classes.controlTVBtnStyleMedium
          }
        >
          sleep
        </div>
        <div
          className={
            classes.controlTVBtnStyle + " " + classes.controlTVBtnStyleMedium
          }
        >
          mudo
        </div>
        <div
          className={
            classes.controlTVBtnStyle + " " + classes.controlTVBtnStyleMedium
          }
        >
          entrada
        </div>
      </div>

      <div className={classes.controlTVBtnLine}>
        <div
          className={
            classes.controlTVBtnStyle + " " + classes.controlTVBtnStyleMedium
          }
        >
          sair
        </div>
        <div
          className={
            classes.controlTVBtnStyle + " " + classes.controlTVBtnStyleMedium
          }
        >
          rebobinar
        </div>
        <div
          className={
            classes.controlTVBtnStyle + " " + classes.controlTVBtnStyleMedium
          }
        >
          play
        </div>
      </div>

      <div className={classes.controlTVBtnLine}>
        <div
          className={
            classes.controlTVBtnStyle + " " + classes.controlTVBtnStyleMedium
          }
        >
          fast_foward
        </div>
        <div
          className={
            classes.controlTVBtnStyle + " " + classes.controlTVBtnStyleMedium
          }
        >
          gravação
        </div>
        <div
          className={
            classes.controlTVBtnStyle + " " + classes.controlTVBtnStyleMedium
          }
        >
          parar
        </div>
      </div>

      <div className={classes.controlTVBtnLine}>
        <div
          className={
            classes.controlTVBtnStyle + " " + classes.controlTVBtnStyleMedium
          }
        >
          pausa
        </div>
        <div
          className={
            classes.controlTVBtnStyle + " " + classes.controlTVBtnStyleMedium
          }
        >
          verde
        </div>
        <div
          className={
            classes.controlTVBtnStyle + " " + classes.controlTVBtnStyleMedium
          }
        >
          azul
        </div>
      </div>

      <div className={classes.controlTVBtnLine}>
        <div
          className={
            classes.controlTVBtnStyle + " " + classes.controlTVBtnStyleMedium
          }
        >
          amarelo
        </div>
        <div
          className={
            classes.controlTVBtnStyle + " " + classes.controlTVBtnStyleMedium
          }
        >
          3D
        </div>
        <div
          className={
            classes.controlTVBtnStyle + " " + classes.controlTVBtnStyleMedium
          }
        >
          favorito
        </div>
      </div>

      <div className={classes.controlTVBtnLine}>
        <div
          className={
            classes.controlTVBtnStyle + " " + classes.controlTVBtnStyleMedium
          }
        >
          imagem
        </div>
        <div
          className={
            classes.controlTVBtnStyle + " " + classes.controlTVBtnStyleMedium
          }
        >
          net
        </div>
        <div
          className={
            classes.controlTVBtnStyle + " " + classes.controlTVBtnStyleMedium
          }
        >
          som
        </div>
      </div>

      <div className={classes.controlTVBtnLine}>
        <div
          className={
            classes.controlTVBtnStyle + " " + classes.controlTVBtnStyleMedium
          }
        >
          guias
        </div>
        <div
          className={
            classes.controlTVBtnStyle + " " + classes.controlTVBtnStyleMedium
          }
        >
          USB
        </div>
        <div
          className={
            classes.controlTVBtnStyle + " " + classes.controlTVBtnStyleMedium
          }
        >
          Opções
        </div>
      </div>

      <div className={classes.controlTVBtnLine}>
        <div
          className={
            classes.controlTVBtnStyle + " " + classes.controlTVBtnStyleMedium
          }
        >
          mixer
        </div>
        <div
          className={
            classes.controlTVBtnStyle + " " + classes.controlTVBtnStyleMedium
          }
        >
          lista de canais
        </div>
        <div className={classes.controlTVBtnStyleSmallContainer}>
          <div
            className={
              classes.controlTVBtnStyle + " " + classes.controlTVBtnStyleSmall
            }
            onClick={() => setMore(false)}
          >
            <div className={classes.controlTVBtnStyleSmallLabel}>
              Voltar
              <br />
              <MoreHorizIcon style={{ color: "#8B979F" }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TV_ControlMore;
