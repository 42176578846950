import Icon from "@material-ui/core/Icon";
import history from "../../Services/history";
import { styled, withStyles } from "@material-ui/core/styles";
import React, { useContext, useState } from "react";
import GlobalContex from "../../GlobalContext/GlobalContext";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PlaceModel from "../../Models/PlaceModel";
import RoomMenuItem from "./RoomMenuItem";
import List from "@material-ui/core/List";
import SideMenuContext from "./Context/SideMenuContext";

const LinkStyled = styled(Link)({
  width: "100%",
  color: "#FFF",
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    textDecoration: "none",
    color: "#FFF",
  },
  "& .MuiIcon-root": {
    width: "35px",
    height: "35px",
  },
  "& .MuiIcon-root > img": {
    height: "inherit",
    width: "inherit",
    marginRight: "10px",
    backgroundColor: "#36B35F",
    padding: "6px",
    borderRadius: "5px",
  },
});

const AccordionStyled = withStyles({
  root: {
    border: "1px solid",
    borderColor: "#36B35F",
    borderRight: 0,
    borderLeft: 0,
    backgroundColor: "#19ac49",
    width: "100%",
    margin: 0,
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:last-child)": {
      borderRadius: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: 0,
    },
    "& .MuiAccordionDetails-root": {
      display: "flex",
      flexDirection: "column",
      gap: "1px",

      "& .MuiList-padding": {
        padding: 0,
      },
      "& .MuiListItem-root:last-child": {
        borderBottom: "none",
      },
    },
    "& .MuiAccordionSummary-content": {
      flexDirection: "column",
      "&.Mui-expanded": {
        margin: "12px 0",
      },
    },
    "& .MuiAccordionSummary-content > .title": {
      marginLeft: 10,
      color: "#FFF",
      fontSize: 15,
    },
    "& .MuiAccordionSummary-content > .subtitle": {
      fontSize: "12px",
      color: "#68ECA7",
      marginTop: "4px",
    },
  },
  expanded: {},
})(Accordion);

type PlaceMenuItemProps = {
  link: string;
  icon: string;
  text: string;
  place: PlaceModel;
};

let counter = 0;

const PlaceMenuItem: React.FunctionComponent<PlaceMenuItemProps> = (
  props: PlaceMenuItemProps
) => {
  counter += 1;

  const { setPlaceSelected, pathnameUrl, setPathnameUrl, sideMenuExpanded } =
    useContext(GlobalContex);
  const { expandedAll, handleChangeCollapseSubmenu } =
    useContext(SideMenuContext);

  const [subExpanded, setSubExpanded] = useState<string | false>(false);

  const handleChangeSubExpanded =
    (panel: string) =>
    (
      event: React.ChangeEvent<Record<string, unknown>>,
      isExpanded: boolean
    ) => {
      setSubExpanded(isExpanded ? panel : false);
      handleChangeCollapseSubmenu(props.text);
    };

  const expandIcon =
    props.place.rooms.length > 0 ? (
      <ExpandMoreIcon htmlColor="#68ECA7" />
    ) : undefined;

  const navigateToManagePlace = (place: PlaceModel) => {
    setPlaceSelected(place);
    setPathnameUrl("/manage-room");
    localStorage.setItem("placeSelectName", place.name);
    localStorage.setItem("placeSelectedID", place.place_id);
    history.push("/manage-room");
  };

  const isActivePlace = () => {
    if (pathnameUrl === props.link) {
      const placeID = localStorage.getItem("placeSelectedID");
      if (placeID) {
        if (parseInt(placeID) === parseInt(props.place.place_id)) {
          return true;
        }
      }
    }
    return false;
  };

  const isExpanded = () => {
    return (
      sideMenuExpanded.some((item) => item === props.text) ||
      subExpanded === props.text ||
      expandedAll
    );
  };

  return (
    <AccordionStyled
      square
      id={`place-menuitem-${counter}`}
      expanded={isExpanded()}
      onChange={handleChangeSubExpanded(props.text)}
      style={{ backgroundColor: isActivePlace() ? "#007A39" : "" }}
    >
      <AccordionSummary
        expandIcon={expandIcon}
        id={`place-menuitem-summary-${counter}`}
        style={{ margin: "12px 0px" }}
      >
        <LinkStyled
          href="/manage-room"
          onClick={(
            event: React.MouseEvent<HTMLAnchorElement> &
              React.MouseEvent<HTMLSpanElement>
          ) => {
            event.preventDefault();
            event.stopPropagation();
            navigateToManagePlace(props.place);
          }}
        >
          <Icon>
            <img src="assets/icons/empreendimentos.svg" alt="" />
          </Icon>
          <span className={"title"} style={{ marginLeft: "10px" }}>
            {props.text}
          </span>
        </LinkStyled>
        <span className={"subtitle"}>
          {props.place!.rooms.length} Espaço
          {props.place!.rooms.length > 1 ? "s" : ""}
        </span>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {props.place!.rooms.map((room, i) => {
            return (
              <RoomMenuItem
                key={"roomItem-" + i}
                room={room}
                place={props.place}
              />
            );
          })}
        </List>
      </AccordionDetails>
    </AccordionStyled>
  );
};

export default PlaceMenuItem;
