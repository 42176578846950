import { createTheme, Theme } from "@material-ui/core/styles";
import { grey, green } from "@material-ui/core/colors";

const theme: Theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          color: "#3E5055",
        },
      },
    },
    MuiLink: {
      root: {
        "&:hover": {
          color: green[700],
        },
      },
    },
  },
  palette: {
    type: "light",
    primary: {
      main: "#FFF",
      dark: "#00a335",
      light: "#00a335",
    },
    secondary: {
      main: "#00a335",
      dark: "#00a335",
      light: "#00a335",
      contrastText: grey[100],
    },
  },
});

export default theme;
