import { TextField } from "@material-ui/core";
import { useState } from "react";

interface Props {
  command: any;
  value: any;
  onChange: any;
}

const RawInput: React.FunctionComponent<Props> = (props: Props) => {
  const [hasChanged, setChanged] = useState<boolean>(false);
  return (
    <TextField
      value={props.value}
      variant="outlined"
      size="small"
      onChange={(e) => {
        setChanged(true);
        props.onChange(e.target.value, false);
      }}
      onBlur={(value) => {
        if (hasChanged) {
          props.onChange(value.target.value, true);
        }
      }}
    />
  );
};

export default RawInput;
