import React, { useContext } from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import GlobalContex from "../../GlobalContext/GlobalContext";

const CustomSnackbar: React.FunctionComponent = () => {
  const { message, setSnackAlert, severity, snackAlert } =
    useContext(GlobalContex);

  function onCloseSnack() {
    setSnackAlert(false);
  }

  function onCloseAlert() {
    setSnackAlert(false);
  }

  return (
    <Snackbar
      autoHideDuration={3000}
      onClose={onCloseSnack}
      open={snackAlert}
      disableWindowBlurListener={true}
    >
      <Alert variant="filled" onClose={onCloseAlert} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
