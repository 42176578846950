import { useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import OperatorsGroupCreatePageContext from "../../Context/OperatorsGroupCreatePageContext";
import styles from "../../../../Components/CustomConfirmDialog/styles/CustomConfirmDialog.module.css";
import EnvironmentsAccessControl from "./EnvironmentsAccessControl";
import { Container, makeStyles } from "@material-ui/core";
import SaveOrCancelPermissions from "./SaveOrCancelPermissions";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "60vw", minHeight: "80vh", backgroundColor: "#FFF" },
}));

const CustomAccessControlModal: React.FunctionComponent = () => {
  const { openAccessModalControl, handleCloseAccessModalControl } = useContext(
    OperatorsGroupCreatePageContext
  );

  const classes = useStyles();
  return (
    <Dialog
      onClose={handleCloseAccessModalControl}
      open={openAccessModalControl}
      className={styles.dialog}
      classes={{ paper: classes.paper }}
    >
      <div
        className={styles.head}
        style={{
          backgroundColor: "#FFF",
          width: "100%",
        }}
      >
        <IconButton
          title={"Fechar"}
          onClick={handleCloseAccessModalControl}
          className={styles.iconButton}
        >
          <CloseIcon className={styles.closeIcon} />
        </IconButton>
      </div>

      <Container>
        <EnvironmentsAccessControl />
      </Container>

      <SaveOrCancelPermissions />
    </Dialog>
  );
};

export default CustomAccessControlModal;
