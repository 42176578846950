import MiniDrawer from "../../Components/CustomMiniDrawer";
import EnterprisesConfigProvider from "./Context/EnterprisesConfigProvider";
import ContainerEnterprisesConfig from "./Components/ContainerEnterprisesConfig";

const EnterprisesConfigPage: React.FunctionComponent = () => {
  return (
    <MiniDrawer
      breadcrumb={[{ name: "Meus Empreendimentos", to: "" }]}
      showSideMenu={false}
    >
      <EnterprisesConfigProvider>
        <ContainerEnterprisesConfig />
      </EnterprisesConfigProvider>
    </MiniDrawer>
  );
};

export default EnterprisesConfigPage;
