import { AxiosResponse } from "axios";
import DeviceModel, { DeviceFunctionsModel } from "../Models/DeviceModel";
import DevicesToRelocate from "../Models/DevicesToRelocate";
import { LogsModel } from "../Models/LogsModel";
import api from "./Api";
import authHeader, { getHomeIDSelect } from "./UserService";

export const getFetchSpecifications = (
  idDevice: string
): Promise<AxiosResponse<DeviceFunctionsModel>> => {
  return api.get(`devices/${idDevice}/functions`, { headers: authHeader() });
};

export const setCommandDevice = (
  comands: { commands: any[] },
  idDevice: string
): Promise<AxiosResponse<boolean>> => {
  return api.post(
    `/homes/${getHomeIDSelect()}/devices/${idDevice}/commands`,
    comands,
    {
      headers: authHeader(),
    }
  );
};

export const purgeCache = (): Promise<AxiosResponse<string>> => {
  return api.get(`/devices/${getHomeIDSelect()}/updateCache`, {
    headers: authHeader(),
  });
};

export const cacheInfo = (): Promise<AxiosResponse<string>> => {
  return api.get(`/devices/${getHomeIDSelect()}/lastUpdateCacheTime`, {
    headers: authHeader(),
  });
};

export const getSubDevices = (
  id: string
): Promise<AxiosResponse<DeviceModel[]>> => {
  return api.get(`/devices/${id}/sub-devices`, {
    headers: authHeader(),
  });
};

type LogDeviceParamsType = {
  row_key?: string;
  size?: number;
  codes?: string;
};

export const getLogDevices = (
  id: string,
  params?: LogDeviceParamsType
): Promise<AxiosResponse<LogsModel>> => {
  const config = {
    headers: authHeader(),
    params: {
      codes: params?.codes,
      start_row_key: params?.row_key,
      size: params?.size || 20,
    },
  };
  return api.get(`/devices/${id}/logs`, config);
};

export const editDevice = (
  device_id: string,
  name: string,
  home_id: string
): Promise<AxiosResponse<boolean>> => {
  const params = {
    name: name,
  };
  return api.put(`/homes/${home_id}/devices/${device_id}`, params, {
    headers: authHeader(),
  });
};

export const removeDevices = (
  devicesToRemove: DevicesToRelocate[],
  home_id: string
): Promise<AxiosResponse<boolean>> => {
  return api.delete(`/homes/${home_id}/devices`, {
    headers: authHeader(),
    data: devicesToRemove,
  });
};
