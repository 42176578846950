import { makeStyles, createStyles } from "@material-ui/core";
import history from "../../../../Services/history";
import { Success } from "./styles";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
    },
    info: {
      color: "#00A335",
      fontWeight: 700,
      cursor: "pointer",
    },
  })
);

const SuccessMessage: React.FunctionComponent = () => {
  const classes = useStyles();
  const goToEnterprise = () => {
    history.push("/select-home");
  };
  return (
    <Success>
      <p
        style={{
          textAlign: "center",
          margin: "0px",
        }}
      >
        <strong>Plano alterado!</strong> Você já pode
        <span className={classes.info} onClick={goToEnterprise}>
          {" "}
          acessar seu empreendimento
        </span>{" "}
        no IzyManager!{" "}
      </p>
    </Success>
  );
};

export default SuccessMessage;
