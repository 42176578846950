import { makeStyles } from "@material-ui/core";
import DeviceModel from "../../../../../Models/DeviceModel";
import ListEnvironmentsFromDevices from "../../../../../Components/ListEnvironmentsFromDevices";
import { useEffect, useState } from "react";
import CustomDeviceBatteryIcon from "../../../../../Components/CustomDeviceBatteryIcon";

interface Props {
  device: DeviceModel;
}

interface Action {
  code: string;
  t: number;
  value: string | number | boolean;
}

const Device_ISA_1001: React.FunctionComponent<Props> = ({ device }: Props) => {
  const classes = useStyles();
  const [lastAction, setLastAction] = useState<Action | undefined>();

  const door_contact = device.status.find(
    (s) => s.code === "doorcontact_state"
  ) || { code: "", value: false, t: 0 };

  const temper_alarm = device.status.find((s) => s.code === "temper_alarm") || {
    code: "",
    value: false,
    t: 0,
  };

  const battery = device.status.find(
    (s) => s.code === "battery_percentage"
  ) || { code: "", value: 0, t: 0 };

  const getMostRecentAction = () => {
    if (door_contact.t >= battery.t && door_contact.t >= temper_alarm.t) {
      setLastAction(door_contact);
      return;
    }

    if (temper_alarm.t > door_contact.t && temper_alarm.t >= battery.t) {
      setLastAction(temper_alarm);
      return;
    }

    if (battery.t > door_contact.t && battery.t > temper_alarm.t) {
      setLastAction(battery);
      return;
    }
  };

  useEffect(() => {
    getMostRecentAction();
  }, [
    door_contact.value,
    door_contact.t,
    battery.value,
    battery.t,
    temper_alarm.value,
    temper_alarm.t,
  ]);

  const actionLabel = (action: Action) => {
    if (
      action.code === "doorcontact_state" &&
      typeof action.value === "boolean"
    ) {
      if (action.value) {
        return "Aberto";
      } else {
        return "Fechado";
      }
    }

    if (action.code === "temper_alarm" && typeof action.value === "boolean") {
      if (action.value) {
        return "Sensor violado!";
      } else {
        return "Não há alarme de violação";
      }
    }

    if (
      action.code === "battery_percentage" &&
      typeof action.value === "number"
    ) {
      return "Bateria: " + action.value + "%";
    }

    return "Ação desconhecida!";
  };

  const showAction = () => {
    if (lastAction) {
      const eventDate = new Date(lastAction.t);
      return (
        <>
          {eventDate.getDate() +
            "." +
            (1 + eventDate.getMonth()) +
            "." +
            eventDate.getFullYear() +
            " " +
            eventDate.toLocaleTimeString() +
            " " +
            actionLabel(lastAction)}
        </>
      );
    }
    return "Nenhuma ação encontrada!";
  };

  return (
    <div className={classes.container}>
      <div className={classes.title}>{device.name}</div>
      {device.environments && device.environments.length > 0 && (
        <div className={classes.subtitle}>
          <ListEnvironmentsFromDevices environments={device.environments} />
        </div>
      )}
      <div
        className={classes.componentContainer}
        style={{
          height:
            device.environments && device.environments.length > 0
              ? "calc(100% - 140px)"
              : "calc(100% - 85px)",
        }}
      >
        <div className={classes.batteryContainer}>
          <CustomDeviceBatteryIcon value={battery.value as number} />
        </div>
        <div className={classes.containerImg}>
          <img
            src={
              door_contact.value
                ? "assets/imgs/isa_1001_open.png"
                : "assets/imgs/isa_1001_close.png"
            }
            height={300}
          />
        </div>
        <div className={classes.lastActionLabel}>Última ação</div>
        <div className={classes.lastAction}>{showAction()}</div>
      </div>
    </div>
  );
};

export default Device_ISA_1001;

const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
  },
  title: {
    fontSize: "23px",
    lineHeight: "31px",
    color: "#3E5055",
    fontWeight: 600,
    textAlign: "center",
    padding: "35px 0px 20px 0px",
  },
  subtitle: {
    paddingBottom: "30px",
    display: "flex",
    justifyContent: "center",
  },
  componentContainer: {
    width: "100%",
    overflow: "auto",
  },
  batteryContainer: {
    padding: "50px 0px",
  },
  batteryLabel: {
    fontWeight: 600,
    fontSize: "21px",
    lineHeight: "29px",
    textAlign: "center",
    color: "#3E5055",
  },
  containerImg: {
    textAlign: "center",
    marginBottom: "50px",
  },
  lastActionLabel: {
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#8B979F",
    marginBottom: "5px",
  },
  lastAction: {
    fontWeight: 600,
    fontSize: "23px",
    lineHeight: "31px",
    textAlign: "center",
    color: "#8B979F",
  },
}));
