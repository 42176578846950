import {
  IconButton,
  makeStyles,
  createStyles,
  Button,
  TextField,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import AddIcon from "@material-ui/icons/Add";
import { useState } from "react";

interface Props {
  selectedImage: File | string;
  handleChangeImage: (value: React.ChangeEvent<HTMLInputElement>) => void;
  removeImageEnterprise: () => void;
  enterpriseName: string;
  setEnterpriseName: (value: string) => void;
}

const CreateEnterpriseComponent: React.FunctionComponent<Props> = ({
  selectedImage,
  handleChangeImage,
  removeImageEnterprise,
  enterpriseName,
  setEnterpriseName,
}: Props) => {
  const classes = useStyles();
  const enterpriseDefaultImage = "assets/imgs/enterpriseDefaultImage.png";

  const [displayDeleteButton, setDisplayDeleteButton] =
    useState<string>("none");

  const handleChangeEnterpriseName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEnterpriseName(event.target.value);
  };

  return (
    <div className={classes.componentContent}>
      <div className={classes.contentTitle}>Informações do empreendimento</div>
      <div style={{ display: "flex" }}>
        <div
          style={{ position: "relative" }}
          onMouseEnter={() => setDisplayDeleteButton("inline-flex")}
          onMouseLeave={() => setDisplayDeleteButton("none")}
        >
          {selectedImage && (
            <IconButton
              id={"remove-img-enterprise"}
              className={classes.removeImgIcon}
              onClick={() => {
                removeImageEnterprise();
              }}
              style={{ display: displayDeleteButton }}
            >
              <CancelIcon htmlColor="#D72735" fontSize="large" />
            </IconButton>
          )}
          <img
            className={classes.img}
            src={
              typeof selectedImage === "string"
                ? selectedImage === ""
                  ? enterpriseDefaultImage
                  : selectedImage
                : URL.createObjectURL(selectedImage)
            }
            style={{ border: selectedImage ? "2px solid #00A335" : "" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            minWidth: "500px",
          }}
        >
          <Button
            id={"add-img-enterprise"}
            variant="outlined"
            color="secondary"
            component="label"
            startIcon={
              selectedImage ? (
                <img src="/assets/icons/change-image-enterprise.svg" />
              ) : (
                <AddIcon />
              )
            }
            className={classes.addBtn}
          >
            {selectedImage ? "Alterar Imagem" : "Adicionar Imagem"}
            <input
              id="enterprise-image"
              type="file"
              accept="image/*"
              hidden
              onChange={handleChangeImage}
            />
          </Button>
          <TextField
            value={enterpriseName}
            id="enterprise-name"
            label="Empreendimento"
            variant="filled"
            fullWidth
            required
            inputProps={{
              maxLength: 25,
            }}
            onChange={handleChangeEnterpriseName}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateEnterpriseComponent;

const useStyles = makeStyles(() =>
  createStyles({
    componentContent: {
      width: "100%",
      padding: "15px 50px",
      background: "#ffffff",
      boxShadow: "0px 0px 4px 4px rgba(0, 0, 0, 0.03)",
      borderRadius: "5px",
      marginBottom: "20px",
    },
    contentTitle: {
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "20px",
      color: "#8B979F",
      marginBottom: "15px",
    },
    removeImgIcon: {
      height: "0px",
      width: "0px",
      position: "absolute",
      background: "#FFF",
      right: "20px",
      top: "0px",
      "&:hover": {
        background: "#FFF",
      },
    },
    img: {
      borderRadius: "75px",
      height: "150px",
      width: "150px",
      marginRight: "25px",
    },
    addBtn: {
      height: "40px",
      width: "200px",
      textTransform: "capitalize",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      marginBottom: "10px",
    },
  })
);
