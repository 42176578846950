import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import { ChangeEvent } from "react";

const useStyles = makeStyles(() => ({
  search: {
    minWidth: "280px",
    "& .MuiInputBase-root": {
      borderRadius: 3,
    },
    "& .MuiInputBase-input": {
      padding: 10,
    },
  },
  input: {
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#6DCC9A",
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00A335",
    },
    "& .MuiSvgIcon-root": {
      color: "#8B979F",
    },
  },
}));

type InputSearchProps = {
  id: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
};

const InputSearch: React.FunctionComponent<InputSearchProps> = (
  props: InputSearchProps
) => {
  const classes = useStyles();

  return (
    <div className="input-search">
      <TextField
        className={classes.search}
        type="search"
        id={props.id}
        name={props.id}
        placeholder={props.placeholder || "Pesquisar"}
        onChange={props.onChange}
        InputProps={{
          className: classes.input,
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
    </div>
  );
};

export default InputSearch;
