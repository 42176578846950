import { makeStyles } from "@material-ui/core/styles";
import { useContext } from "react";
import GlobalContex from "../../GlobalContext/GlobalContext";
import { getCommandLabel } from "../../Utils/DeviceConfiguration";
import InfiniteScroll from "react-infinite-scroll-component";
import { colorV2Value } from "../../Utils/Functions";
import {
  AutomationNotificationModel,
  NotificationModel,
} from "../../Models/NotificationModel";

const CustomCardNotifications: React.FunctionComponent = () => {
  const classes = useStyles();

  const {
    notifications,
    hasNextNotification,
    setHasNextNotification,
    getMoreNotifications,
  } = useContext(GlobalContex);

  const getMonthName = (m: number) => {
    if (m >= 0 && m < 12) {
      const monthsName = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];
      return monthsName[m];
    }
    return "Mês Inválido";
  };

  let day = 0;
  let month = 0;
  let year = 0;

  const checkDate = (dayNot: number, monthNot: number, yearNot: number) => {
    if (day === dayNot && month === monthNot && year === yearNot) {
      return false;
    } else {
      day = dayNot;
      month = monthNot;
      year = yearNot;
      return true;
    }
  };

  const notificationDeviceItemCard = (
    notificationData: NotificationModel,
    notificationType: string,
    notificationDate: Date,
    index: number
  ) => {
    return (
      <div
        key={"notification-item-" + index}
        className={classes.cardEventContainer}
      >
        {checkDate(
          notificationDate.getDate(),
          notificationDate.getMonth() + 1,
          notificationDate.getFullYear()
        ) && (
          <div>
            <span className={classes.dayLabel}>
              {notificationDate.getDate()}{" "}
            </span>
            <span className={classes.dateLabel}>
              {getMonthName(notificationDate.getMonth())}{" "}
              {notificationDate.getFullYear()}
            </span>
          </div>
        )}
        <div className={classes.cardEventoNotification}>
          <div className={classes.containerImgDeviceNotification}>
            <img
              src={notificationData.device.icon}
              alt="Imagem do dispositivo"
              height={50}
            />
            <div className={classes.labelDeviceName}>
              {notificationData.device.name}
            </div>
          </div>
          <div>
            {notificationType === "DEVICE_STATUS_REPORT" ? (
              <>
                {notificationData.device.status.map((status, index) => {
                  if (status.t === notificationData.event_time) {
                    return (
                      <div
                        className={classes.subtitleCardNotifications}
                        key={"notification-item-" + index}
                      >
                        {getCommandLabel(notificationData.device, status.code)}:{" "}
                        {status.code === "colour_data_v2" ? (
                          <div
                            style={{
                              border: "1px solid grey",
                              marginLeft: "10px",
                              backgroundColor: colorV2Value(status.value),
                              height: "20px",
                              width: "20px",
                              borderRadius: "50%",
                            }}
                          ></div>
                        ) : (
                          <>
                            {getCommandLabel(
                              notificationData.device,
                              status.value.toString(),
                              status.code
                            )}
                          </>
                        )}
                      </div>
                    );
                  }
                })}
              </>
            ) : (
              <div className={classes.titleCardNotifications}>
                {notificationType === "DEVICE_ONLINE" && (
                  <span>
                    O dispositivo {notificationData.device.name} foi ligado na
                    energia
                  </span>
                )}
                {notificationType === "DEVICE_OFFLINE" && (
                  <span>
                    O dispositivo {notificationData.device.name} foi desligado
                    da energia
                  </span>
                )}
              </div>
            )}
            <div className={classes.placeRoomLabel}>
              {notificationData.device.place_name}{" "}
              {notificationData.device.place_name &&
              notificationData.device.room_name
                ? ">"
                : ""}{" "}
              {notificationData.device.room_name}
            </div>
            <div className={classes.hourLabel}>
              {notificationDate.toLocaleTimeString()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const notificationAutomationCard = (
    notificationData: AutomationNotificationModel,
    notificationDate: Date,
    index: number
  ) => {
    const failAutomations = notificationData.scene_automation.actions.find(
      (item) => item.execStatus === 0
    );

    if (!!failAutomations) {
      return (
        <div
          key={"notification-item-" + index}
          className={classes.cardEventContainer}
        >
          {checkDate(
            notificationDate.getDate(),
            notificationDate.getMonth() + 1,
            notificationDate.getFullYear()
          ) && (
            <div>
              <span className={classes.dayLabel}>
                {notificationDate.getDate()}{" "}
              </span>
              <span className={classes.dateLabel}>
                {getMonthName(notificationDate.getMonth())}{" "}
                {notificationDate.getFullYear()}
              </span>
            </div>
          )}
          <div className={classes.cardEventoNotification}>
            <img
              src={"assets/imgs/warningNotification.svg"}
              alt="Imagem do dispositivo"
              height={50}
            />
            <div>
              <div className={classes.subtitleCardNotificationsAutomations}>
                A automação{" "}
                <strong>{notificationData.scene_automation.name}</strong>{" "}
                <span style={{ color: "#ff8080" }}>falhou</span> ao executar
              </div>
              <div className={classes.hourLabel}>
                {notificationDate.toLocaleTimeString()}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const loadMoreOldNotifications = () => {
    setHasNextNotification(false);
    getMoreNotifications();
  };

  return (
    <div className={classes.containerCardNotifications}>
      <InfiniteScroll
        style={{ padding: "25px", height: "100%" }}
        dataLength={notifications.length}
        next={loadMoreOldNotifications}
        hasMore={hasNextNotification}
        loader={<h4></h4>}
        height={"100%"}
      >
        {notifications.map((e, index) => {
          const notificationData = e;
          const notificationType = notificationData.event_type;
          const notificationDate = new Date(notificationData.event_time);

          if (notificationType === "EXECUTE_SCENE") {
            return (
              <div key={"notification-item-" + index}>
                {notificationAutomationCard(
                  notificationData as AutomationNotificationModel,
                  notificationDate,
                  index
                )}
              </div>
            );
          }

          return (
            <div key={"notification-item-" + index}>
              {notificationDeviceItemCard(
                notificationData as NotificationModel,
                notificationType,
                notificationDate,
                index
              )}
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

export default CustomCardNotifications;

const useStyles = makeStyles(() => ({
  containerCardNotifications: {
    backgroundColor: "#EBEEEE",
    height: "100%",
    "& .infinite-scroll-component__outerdiv": {
      height: "100%",
    },
  },
  cardEventoNotification: {
    backgroundColor: "#FFF",
    boxShadow: "3px 3px 10px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "10px",
    padding: "10px 15px",
    display: "flex",
    flexDirection: "row",
    gap: "30px",
  },
  cardEventContainer: {
    marginBottom: "20px",
  },
  dayLabel: {
    fontSize: "23px",
    fontWeight: 600,
    lineHeight: "31px",
    color: "#3E5055",
  },
  dateLabel: {
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#BDBDBD",
  },
  hourLabel: {
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#BDBDBD",
  },
  placeRoomLabel: {
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#BDBDBD",
    marginTop: "10px",
  },
  titleCardNotifications: {
    fontWeight: 700,
    fontSize: "15px",
    lineHeight: "20px",
    color: "#3E5055",
  },
  subtitleCardNotifications: {
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "20px",
    color: "#8B979F",
    maxWidth: "245px",
    overflowWrap: "break-word",
    display: "flex",
  },

  subtitleCardNotificationsAutomations: {
    color: "#8B979F;",
    fontSize: "15px",
    fontWeight: 500,
    lineHeight: "20px",
    maxWidth: "100%",
  },

  containerImgDeviceNotification: {
    textAlign: "center",
    minWidth: "60px",
    maxWidth: "60px",
  },
  labelDeviceName: {
    fontSize: "12px",
    color: "#BDBDBD",
  },
}));
