import { createStyles, Divider, makeStyles } from "@material-ui/core";
import CustomPaper from "../Paper";
import SubHeader from "../../../../Components/SubHeader";
import history from "../../../../Services/history";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Filters from "../Filters";
import CustomRelocateModal from "../../../../Components/CustomRelocateModal";
import { useContext } from "react";
import AllDevicesContext from "../../Context/AllDevicesContext";
import GlobalContex from "../../../../GlobalContext/GlobalContext";

const useStyles = makeStyles(() =>
  createStyles({
    body: {
      backgroundColor: "#FFF",
      width: "100%",
      overflow: "auto",
      display: "flex",
      flexFlow: "column",
    },
    subHeaderContainer: {
      flex: 0,
    },
    contentContainer: {
      flex: 1,
      overflow: "auto",
    },
  })
);

const Container: React.FunctionComponent = () => {
  const classes = useStyles();

  const { updateRelocateDevices, confirmAssociation } =
    useContext(AllDevicesContext);

  const { expandSubHeader } = useContext(GlobalContex);

  const backPress = () => {
    history.push("manage-place");
  };

  return (
    <>
      <div className={classes.body}>
        <div className={classes.subHeaderContainer}>
          <SubHeader
            text={"Todos os dispositivos"}
            subText={
              <span>
                Os dispositivos podem ser realocados para outros espaços
                clicando no ícone{" "}
                <span
                  style={{ display: "inline-flex", verticalAlign: "middle" }}
                >
                  <MoreVertIcon fontSize={"small"} />
                </span>{" "}
                e selecionando a opção &quot;Realocar múltiplos
                dispositivos&quot; ou de forma individual
              </span>
            }
            backPress={backPress}
          />
          {expandSubHeader && <Filters />}
          <Divider />
        </div>
        <div className={classes.contentContainer}>
          <CustomPaper />
        </div>
        <CustomRelocateModal
          updateRelocateDevicesFunction={updateRelocateDevices}
          confirmRelocateDevicesFunction={confirmAssociation}
        />
      </div>
    </>
  );
};

export default Container;
