import { makeStyles, Typography, Grid, Divider } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useState, useContext } from "react";
import EmailIcon from "@material-ui/icons/Email";
import RecoverPasswordContext from "../../Context/RecoverPasswordContext";
import CustomTab, { CustomTabPanel } from "../../../../Components/CustomTab";

const RecoverPasswordIzySmart: React.FunctionComponent = () => {
  const classes = useStyles();
  const { btnBackChange } = useContext(RecoverPasswordContext);

  const btnBackClick = () => {
    btnBackChange();
  };

  const [valueTab, setValueTab] = useState<number>(0);

  const handleChangeTab = (newValueTab: number) => {
    setValueTab(newValueTab);
  };

  return (
    <>
      <div className={classes.btnBackContainer}>
        <div className={classes.btnBack} onClick={btnBackClick}>
          <ArrowBackIosIcon className={classes.btnBackIcon} fontSize="small" />
          <span className={classes.btnBackLabel}>Voltar</span>
        </div>
      </div>
      <Divider />
      <Grid container className={classes.container}>
        <Grid item xs={5} className={classes.contentContainer}>
          <div className={classes.content}>
            <Typography
              variant="h3"
              gutterBottom
              className={classes.contentTitle}
            >
              Recuperar senha
            </Typography>
            <Typography
              variant="subtitle1"
              gutterBottom
              className={classes.contentSubtitle}
            >
              Realize a recuperação de senha através do aplicativo{" "}
              <strong style={{ color: "#00A335" }}>Izy Manager</strong>
            </Typography>

            <CustomTab
              backgroundColor="#fafafa"
              centered={true}
              variant={"standard"}
              labels={["Android", "iOS"]}
              ariaLabel={"menu em tabs para a página de recuperação de senha"}
              id="tab-recovery-password"
              value={valueTab}
              key={"tab-recovery-password"}
              changeTab={(valueTab: number) => handleChangeTab(valueTab)}
            />
            <CustomTabPanel
              value={valueTab}
              index={0}
              backgroundColor={"#fafafa"}
              centered
            >
              <img src="assets/imgs/qr-code-android.png" alt="QRCode Android" />
            </CustomTabPanel>
            <CustomTabPanel
              value={valueTab}
              index={1}
              backgroundColor={"#fafafa"}
              centered
            >
              <img src="assets/imgs/qr-code-IOS.png" alt="QRCode iOS" />
            </CustomTabPanel>

            <div className={classes.subTitleImage}>
              Aponte a câmera do celular para ser redirecionado para o
              aplicativo de download{" "}
            </div>

            <div className={classes.labelBottom}>
              <div className={classes.iconLabelBottom}>
                <img
                  src="assets/icons/arrow-down-download-icon.svg"
                  alt="download icon"
                />
              </div>
              Baixe o App
            </div>
            <div className={classes.labelBottom}>
              <div className={classes.iconLabelBottom}>
                <img src="assets/icons/cursor-icon.svg" alt="cursor icon" />
              </div>
              Clique em &quot;Fazer login com uma conta existente&quot;
            </div>
            <div className={classes.labelBottom}>
              <div className={classes.iconLabelBottom}>
                <img
                  src="assets/icons/question-mark-icon.svg"
                  alt="question mark icon"
                />
              </div>
              Clique em &quot;Esqueceu a senha&quot;
            </div>
            <div className={classes.labelBottom}>
              <div className={classes.iconLabelBottom}>
                <EmailIcon htmlColor="#FFF" fontSize="small" />
              </div>
              Insira o e-mail ou número da conta
            </div>
            <div className={classes.labelBottom}>
              <div className={classes.iconLabelBottom}>
                <img src="assets/icons/asterisk-icon.svg" alt="asterisk icon" />
              </div>
              Insira o código de autenticação e crie uma nova senha
            </div>
          </div>
        </Grid>

        <Grid item xs={7} className={classes.containerBackground}>
          <img src={"assets/imgs/celularMockup1.png"} />
        </Grid>
      </Grid>
    </>
  );
};

export default RecoverPasswordIzySmart;

const useStyles = makeStyles(() => ({
  btnBackContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "75px",
    color: "#8B979F",
  },
  btnBack: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
  },
  btnBackIcon: {
    margin: "0px 10px 0px 20px",
  },
  btnBackLabel: {
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "20px",
  },
  container: {
    position: "absolute",
    width: "100%",
    top: "76px",
    bottom: "0px",
  },
  contentContainer: {
    display: "flex",
  },
  content: {
    margin: "auto",
    width: "80%",
  },
  contentTitle: {
    fontSize: "50px",
    fontWeight: 700,
    lineHeight: "68px",
    textAlign: "center",
    color: "#3E5055",
  },
  contentSubtitle: {
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "20px",
    textAlign: "center",
    color: "#3E5055",
    marginBottom: "15px",
  },
  subTitleImage: {
    marginBottom: "35px",
    color: "#8B979F",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "20px",
    textAlign: "center",
  },
  labelBottom: {
    marginTop: "35px",
    marginBottom: "35px",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "25px",
    textAlign: "left",
    color: "#3E5055",
    display: "flex",
    alignItems: "center",
  },
  iconLabelBottom: {
    width: "30px",
    height: "30px",
    backgroundColor: "#6ECCE0",
    marginRight: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "25px",
  },
  containerBackground: {
    display: "flex",
    alignItems: "center",
    "& img": {
      maxWidth: "100%",
    },
  },
}));
