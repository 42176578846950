import MiniDrawer from "../../Components/CustomMiniDrawer";
import { getHomeNameSelect } from "../../Services/UserService";
import Container from "./Components/Container";
import AllDevicesProvider from "./Context/AllDevicesProvider";

const AllDevices: React.FunctionComponent = () => {
  return (
    <MiniDrawer
      breadcrumb={[
        { name: getHomeNameSelect(), to: "manage-place" },
        { name: "Todos os dispositivos", to: "" },
      ]}
    >
      <AllDevicesProvider>
        <Container />
      </AllDevicesProvider>
    </MiniDrawer>
  );
};

export default AllDevices;
