import { makeStyles, IconButton, Menu, MenuItem } from "@material-ui/core";
import { useState, useContext } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ManageRoomContext from "../../Context/ManageRoomContext";
import DrawerProps from "../../../../Types/DrawerProps";
import RoomModel from "../../../../Models/RoomModel";
import Icon from "../../../../Components/Icon";

interface Props {
  room: RoomModel;
}

const RoomGridMenu: React.FunctionComponent<Props> = (props: Props) => {
  const room = props.room;
  const classes = useStyles();
  const { handleOpenModalDelete, setRoomName, openToggleDrawerToEdit } =
    useContext(ManageRoomContext);

  const anchor: DrawerProps = "right";

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const menuClickItem = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const menuCloseItem = () => {
    setAnchorEl(null);
  };

  const clickEditMenu = () => {
    openToggleDrawerToEdit(anchor, true, room, true);
    menuCloseItem();
  };

  const clickDeleteMenu = () => {
    setRoomName(room.name);
    handleOpenModalDelete(room.room_id);
    menuCloseItem();
  };

  return (
    <>
      <IconButton
        id={"room-item-menu-" + room.room_id}
        name={"room-item-menu"}
        className={classes.roomGridMenuIcon}
        onClick={menuClickItem}
      >
        <MoreVertIcon fontSize={"small"} htmlColor={"#8B979F"} />
      </IconButton>
      <Menu
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={menuCloseItem}
      >
        <MenuItem onClick={clickEditMenu} className={classes.menuItem}>
          <div
            className={classes.menuItemLabel}
            style={{ marginRight: "17px " }}
          >
            Editar Espaço
          </div>
          <Icon
            src={"edit (1) 2"}
            style={{ marginRight: "2px", marginLeft: "3px" }}
          />
        </MenuItem>
        <MenuItem
          onClick={clickDeleteMenu}
          className={classes.menuItem + " " + classes.menuItemLabelRemove}
        >
          <div className={classes.menuItemLabel}>Remover Espaço</div>
          <DeleteOutlineIcon fontSize={"small"} />
        </MenuItem>
      </Menu>
    </>
  );
};

export default RoomGridMenu;

const useStyles = makeStyles(() => ({
  roomGridMenuIcon: {
    position: "absolute",
    right: "0px",
    top: "5px",
    borderRadius: "5px",
    padding: "0px",
    "&:hover": {
      border: "1px solid #D1DAE0",
      boxShadow: "0px 4px 4px 0px #0000000D",
    },
  },
  menuItem: {
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "right",
    color: "#8B979F",
    justifyContent: "end",
  },
  menuItemLabel: {
    marginRight: "15px",
  },
  menuItemLabelRemove: {
    color: "#EF847E",
  },
}));
