import DeviceModel from "../../../Models/DeviceModel";
import Skeleton from "@material-ui/lab/Skeleton";

import * as S from "./styled";
import { getCommandLabel } from "../../../Utils/DeviceConfiguration";
import { LogModel } from "../../../Models/LogsModel";

type CardLogItemProps = {
  device: DeviceModel;
  log: LogModel;
};

const CardAlarmItem: React.FunctionComponent<CardLogItemProps> = ({
  device,
  log,
}: CardLogItemProps) => {
  const image = () => {
    // const hasImage =
    //   log.media_info &&
    //   log.media_info.length > 0 &&
    //   (log.media_info[0].file_url || log.media_info[0].media_url);
    //
    // if (hasImage) {
    //   const src = log.media_info[0].file_url || log.media_info[0].media_url;
    //   return <img src={src} alt="Ícone alerta" height={45} />;
    // }
    return <S.Circle>!!!</S.Circle>;
  };

  return (
    <S.CardAlarmContainer>
      <S.CardContentImage>{image()}</S.CardContentImage>
      <S.CardContentMain>
        <div className="card-log-item-title">
          {getCommandLabel(device, log.value, log.code)}
        </div>
        <div className="card-log-item-time">
          {new Date(log.event_time).toLocaleTimeString()}
        </div>
      </S.CardContentMain>
    </S.CardAlarmContainer>
  );
};

export const CardAlarmItemSkeleton: React.FunctionComponent = () => {
  return (
    <S.CardAlarmContainer>
      <S.CardContentImage>
        <Skeleton variant="circle" width={40} height={40} />
      </S.CardContentImage>
      <S.CardContentMain>
        <div className="card-log-item-title">
          <Skeleton variant="text" width={210} height={28} />
        </div>
        <div className="card-log-item-time">
          <Skeleton variant="text" width={100} height={22} />
        </div>
      </S.CardContentMain>
    </S.CardAlarmContainer>
  );
};

export default CardAlarmItem;
