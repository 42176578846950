import { FormControl, MenuItem, Select } from "@material-ui/core";
import { useState } from "react";

interface Option {
  key: string;
  value: string;
}

interface Props {
  value: string;
  onChange: (value: any) => void;
}

const ConditionType: React.FunctionComponent<Props> = (props: Props) => {
  const options: Option[] = [
    { key: "<", value: "Menor que" },
    { key: "==", value: "Igual" },
    { key: ">", value: "Maior que" },
  ];

  const [value, setValue] = useState<string>(props.value);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    props.onChange(event.target.value);
    setValue(event.target.value as string);
  };

  return (
    <FormControl variant="outlined">
      <Select
        style={{
          width: "200px",
        }}
        value={value}
        onChange={handleChange}
      >
        {options.map((option: Option) => {
          return (
            <MenuItem value={option.key} key={option.key}>
              {option.value}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default ConditionType;
