import {
  createStyles,
  makeStyles,
  Button,
  IconButton,
} from "@material-ui/core";
import { useContext } from "react";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import DrawerProps from "../../../../Types/DrawerProps";
import AutomationPageContext from "../../Context/AutomationPageContext";
import WithRoleAuthorization from "../../../../Components/WithRoleAuthorization";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import CustomFilter from "../../../../Components/CustomFilter";

const useStyles = makeStyles(() =>
  createStyles({
    containerSearchBar: {
      display: "flex",
      flexDirection: "row",
      flexGrow: 1,
      alignContent: "center",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: "2.5rem",
    },
    filterInput: {
      width: "35rem",
    },
    button: {
      textTransform: "none",
      width: "250px",
      height: "55px",
      fontSize: "15px",
      fontWeight: 700,
      lineHeight: "20px",
    },
  })
);

const CustomSearch: React.FunctionComponent = () => {
  const {
    setIsToggleOpen,
    setIsToEdit,
    resetForm,
    allAutomations,
    setAutomationsToShow,
    setIsLoadingAutomations,
    setIsBlockedAutomation,
  } = useContext(AutomationPageContext);

  const { setExpandSubHeader } = useContext(GlobalContex);

  const anchor: DrawerProps = "right";

  const classes = useStyles();

  const clickExpand = () => {
    setExpandSubHeader(false);
  };

  return (
    <div className={classes.containerSearchBar}>
      <CustomFilter
        placeHolder={"Procurar por automações"}
        id={"search-for-automations"}
        isFilterInFront={true}
        arrayToFilter={allAutomations}
        propertyToFilter={"name"}
        className={classes.filterInput}
        setStateFuncion={setAutomationsToShow}
        setStateLoading={setIsLoadingAutomations}
      />
      <div>
        <WithRoleAuthorization permitedRoles={["Owner", "Administrador"]}>
          <React.Fragment key={anchor}>
            <Button
              onClick={() => {
                setIsBlockedAutomation(false);
                setIsToggleOpen(true);
                setIsToEdit(false);
                resetForm();
              }}
              className={classes.button}
              variant="contained"
              color="secondary"
            >
              <AddIcon /> Criar nova automação
            </Button>
          </React.Fragment>
        </WithRoleAuthorization>
        <IconButton onClick={clickExpand}>
          <img src="assets/icons/close-header-icon.svg" />
        </IconButton>
      </div>
    </div>
  );
};

export default CustomSearch;
