import { makeStyles, Grid, CircularProgress } from "@material-ui/core";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import { useContext } from "react";
import RoomGridItem from "../RoomGridItem";
import RoomModel from "../../../../Models/RoomModel";
import EmptyState from "../../../../Components/EmptyState";

const RoomGrid: React.FunctionComponent = () => {
  const classes = useStyles();
  const { isLoadingRooms, roomsToShow } = useContext(GlobalContex);

  return (
    <Grid container spacing={2} className={classes.roomGridContainer}>
      {isLoadingRooms ? (
        <Grid
          item
          xs={12}
          style={{
            textAlign: "center",
          }}
        >
          <CircularProgress size={50} color="secondary" />
        </Grid>
      ) : (
        <>
          {roomsToShow.length > 0 ? (
            roomsToShow.map((room: RoomModel) => {
              return <RoomGridItem key={room.room_id} room={room} />;
            })
          ) : (
            <EmptyState
              title={"Nenhum espaço adicionado"}
              subtitle={
                <span>
                  Você pode adicionar um novo espaço clicando no botão{" "}
                  <strong style={{ color: "#00A335" }}>+Novo espaço</strong>
                </span>
              }
              image={"assets/imgs/empty-state-imgs/empty-state-img-4.png"}
            />
          )}
        </>
      )}
    </Grid>
  );
};

export default RoomGrid;

const useStyles = makeStyles(() => ({
  roomGridContainer: {
    marginTop: "45px",
    maxHeight: "570px",
    overflow: "auto",
  },
  roomGridItem: {
    height: "125px",
    backgroundColor: "#EBEEEE",
    border: "1px solid #D1DAE0",
    boxSizing: "border-box",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.08)",
    borderRadius: "5px",
    padding: "8px 15px",
    display: "flex",
    position: "relative",
    cursor: "pointer",
  },
}));
