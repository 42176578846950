import React, { useContext, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SearchIcon from "@material-ui/icons/Search";
import DeviceModel from "../../../../../Models/DeviceModel";
import AutomationPageContext from "../../../Context/AutomationPageContext";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { IconButton, InputAdornment, OutlinedInput } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      backgroundColor: "#FFF !important",
      maxHeight: "100vh",
    },
    fullList: {
      width: "auto",
    },
    container: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "10px 123px",
    },
    row: {
      display: "flex",
      padding: "25px",
      justifyContent: "start",
      alignItems: "center",
    },
    automationRow: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
    },
    container2: {
      display: "flex",
      padding: "10px 0px",
      height: "90vh",
    },
    drawerPaper: {
      backgroundColor: "#FFF !important",
      width: "500px",
    },
    paper: {
      background: "blue",
      width: "700px",
    },
    root: {
      width: "100%",
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    button: {
      "&:hover": {
        backgroundColor: theme.palette.error.main,
        color: "#FFF",
      },
    },
  })
);

interface PropsShowDevices {
  showDevices: DeviceModel[];
}

const ShowDevices: React.FunctionComponent<PropsShowDevices> = (
  props: PropsShowDevices
) => {
  const classes = useStyles();
  const {
    goFoward,
    setDevice,
    profundidade,
    setShowDeviceComponent,
    setShowDevices,
    setShowOptions,
  } = useContext(AutomationPageContext);

  const [filterValue, setFilterValue] = useState<string>("");

  const changeValueFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
  };

  return (
    <div
      style={{
        backgroundColor: "#FFF",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          margin: "0px 63px",
        }}
      >
        <div
          style={{
            color: "#3E5055",
            fontSize: "18px",
            marginBottom: "0px",
          }}
        >
          <p
            style={{
              color: "#3E5055",
              fontSize: "15px",
              marginBottom: "0px",
            }}
          >
            Todos os dispositivos
          </p>
          <p
            style={{
              color: "#8B979F",
              fontSize: "13px",
              marginTop: "0px",
            }}
          >
            {props.showDevices?.length}
          </p>
          <OutlinedInput
            margin="dense"
            placeholder="Filtrar por nome do dispositivo"
            id="search-for-devices"
            value={filterValue}
            onChange={changeValueFilter}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          <div
            style={{
              width: "100%",
              backgroundColor: "#E8F3F7",
              borderRadius: "10px",
              color: "#8B979F",
              fontSize: "13px",
              lineHeight: "15px",
              marginTop: "10px",
              padding: "5px",
            }}
          >
            <img
              src="/assets/icons/warning.svg"
              alt="Warning icon"
              style={{ height: "15px", width: "15px", marginRight: "3px" }}
            />
            Para dispositivos que utilizam infravermelho (<b>IzyConnect</b>),
            execute através do aplicativo{" "}
            <b style={{ color: "#00A335" }}>IzyManager</b>
          </div>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
          >
            {props.showDevices &&
              props.showDevices
                .filter((device: DeviceModel) =>
                  device.name.toLowerCase().includes(filterValue.toLowerCase())
                )
                .map((device: DeviceModel, index) => {
                  if (
                    device.category !== "infrared_ac" &&
                    device.category !== "infrared_tv"
                  ) {
                    return (
                      <ListItem
                        key={"device-list-item-" + index}
                        button
                        onClick={() => {
                          setDevice(device);
                          goFoward(profundidade);
                          setShowDeviceComponent(true);
                          setShowDevices(false);
                          setShowOptions(false);
                        }}
                        style={{
                          backgroundColor: "#EBEEEE",
                          border: "1px solid #D1DAE0",
                          boxSizing: "border-box",
                          margin: "3px 0px",
                          borderRadius: "5px",
                          height: "76px",
                          paddingLeft: "28px",
                        }}
                      >
                        <ListItemIcon>
                          <img src={device.icon} width={40} alt={device.name} />
                        </ListItemIcon>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <ListItemText primary={device.name} />
                          <ListItemText
                            secondary={device.room_name ? device.room_name : ""}
                          />
                        </div>
                      </ListItem>
                    );
                  }
                })}
          </List>
        </div>
      </div>
    </div>
  );
};
export default ShowDevices;
