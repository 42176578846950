import { Grid } from "@material-ui/core";
import { FormEvent, useContext, useEffect, useState } from "react";
import EnterpriseContext from "../../context/EnterpriseContext";
import { CpfOrCnpj } from "../../../../Models/EnterpriseModel";
import CreateEnterpriseComponent from "../../../../Components/CustomFormEnterprise/CreateEnterpriseComponent";
import ButtonWithLoading from "../ButtonWithLoading";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import RegisterEnterpriseComponent from "../../../../Components/CustomFormEnterprise/RegisterEnterpriseComponent";

const buttonContainerStyle: CSSProperties = {
  display: "flex",
  marginBottom: "20px",
  flexDirection: "row",
  justifyContent: "right",
  alignItems: "center",
};

const Step1: React.FunctionComponent = () => {
  const {
    selectedImage,
    enterpriseName,
    radioButtonValue,
    registerEnterprise,
    registerEnterpriseInvalidValues,
    repeatEmailValue,
    enterpriseId,
    isEnterpriseRegistered,
    saving,
    savedEnterpriseName,
    handleChangeImage,
    removeImageEnterprise,
    setEnterpriseName,
    handleChange,
    handleFocus,
    nextStep,
    createEnterpriseStepTwo,
    updateEnterprise,
    saveEnterpriseBasic,
  } = useContext(EnterpriseContext);

  const [savingEnterprise, setSavingEnterprise] = useState(false);

  useEffect(() => {
    return () => {
      setSavingEnterprise(false);
    };
  }, []);

  const handleCreateEnterpriseClick = async () => {
    setSavingEnterprise(true);
    try {
      await saveEnterpriseBasic();
    } finally {
      setSavingEnterprise(false);
    }
  };

  const handleSaveEnterpriseDetailClick = async (
    e: FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    if (isEnterpriseRegistered) {
      const result = await updateEnterprise();
      if (result === true) {
        nextStep();
      }
    } else {
      if (savedEnterpriseName !== enterpriseName) {
        saveEnterpriseBasic();
      }
      createEnterpriseStepTwo(enterpriseId);
    }
  };

  const [validated, setValidated] = useState(false);
  useEffect(() => {
    const hasInvalidValue =
      !enterpriseId ||
      registerEnterprise[radioButtonValue as CpfOrCnpj]?.trim() === "" ||
      registerEnterprise?.responsible.trim() === "" ||
      registerEnterprise?.email.trim() === "" ||
      registerEnterprise?.telephone.trim() === "" ||
      registerEnterprise?.postal_code.trim() === "" ||
      registerEnterprise?.state.trim() === "" ||
      registerEnterprise?.neighborhood.trim() === "" ||
      registerEnterprise?.city.trim() === "" ||
      registerEnterprise?.address.trim() === "" ||
      registerEnterprise?.number.trim() === "" ||
      repeatEmailValue.trim() === "" ||
      registerEnterpriseInvalidValues.cnpj ||
      registerEnterpriseInvalidValues.cpf ||
      registerEnterpriseInvalidValues.email ||
      registerEnterpriseInvalidValues.postal_code ||
      registerEnterpriseInvalidValues.repeat_email;

    setValidated(!hasInvalidValue);
  }, [
    enterpriseName,
    registerEnterprise,
    radioButtonValue,
    repeatEmailValue,
    registerEnterpriseInvalidValues,
  ]);

  return (
    <Grid item xs={12}>
      <CreateEnterpriseComponent
        selectedImage={selectedImage}
        handleChangeImage={handleChangeImage}
        removeImageEnterprise={removeImageEnterprise}
        enterpriseName={enterpriseName}
        setEnterpriseName={setEnterpriseName}
      />
      {!enterpriseId && (
        <div style={buttonContainerStyle}>
          <ButtonWithLoading
            text={"Criar empreendimento"}
            loading={savingEnterprise}
            disabled={enterpriseName.trim() === ""}
            textLoading={"Criando..."}
            onClick={handleCreateEnterpriseClick}
          />
        </div>
      )}
      {enterpriseId !== "" && (
        <form onSubmit={handleSaveEnterpriseDetailClick}>
          <RegisterEnterpriseComponent
            radioButtonValue={radioButtonValue}
            handleChange={handleChange}
            registerEnterprise={registerEnterprise}
            handleFocus={handleFocus}
            registerEnterpriseInvalidValues={registerEnterpriseInvalidValues}
            repeatEmailValue={repeatEmailValue}
          />
          <div style={buttonContainerStyle}>
            <ButtonWithLoading
              text={"Próxima"}
              loading={saving}
              disabled={!validated || enterpriseName.trim() === ""}
              buttonProps={{
                type: "submit",
              }}
              textLoading={"Salvando..."}
            />
          </div>
        </form>
      )}
    </Grid>
  );
};

export default Step1;
