import { createStyles, makeStyles, Switch } from "@material-ui/core";

interface Props {
  text: string;
  checked: boolean;
  changeStatus: () => void;
  subtitle?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      border: "1px solid #D1DAE0",
      padding: "20px 40px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "10px",
      borderRadius: "5px",
    },
    text: {
      color: "#8B979F",
      fontSize: "15px",
      fontWeight: 600,
    },
    subtitle: {
      fontSize: "13px",
      color: "#8B979F",
      fontWeight: 400,
    },
  })
);

const NotificationToggle: React.FunctionComponent<Props> = ({
  text,
  checked,
  changeStatus,
  subtitle,
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <span className={classes.text}>
        <div>{text}</div>
        {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
      </span>
      <Switch
        checked={checked}
        onChange={() => changeStatus()}
        color="secondary"
      />
    </div>
  );
};

export default NotificationToggle;
