import DeviceModel from "../../../Models/DeviceModel";
import CardAlarmItem from "./CardAlarmItem";

interface Props {
  logs: LogModel[];
  device: DeviceModel;
}

import * as S from "./styled";
import { LogModel } from "../../../Models/LogsModel";

const CardsAlarms: React.FunctionComponent<Props> = ({
  device,
  logs,
}: Props) => {
  const getMonthName = (m: number) => {
    if (m >= 0 && m < 12) {
      const monthsName = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];
      return monthsName[m];
    }
    return "Mês Inválido";
  };

  let currentDate = new Date(0);

  const showDate = (eventDate: Date) => {
    if (
      eventDate.getDate() === currentDate.getDate() &&
      eventDate.getMonth() === currentDate.getMonth() &&
      eventDate.getFullYear() === currentDate.getFullYear()
    ) {
      return false;
    }
    currentDate = eventDate;
    return true;
  };

  return (
    <>
      {logs.map((log: LogModel, index: number) => {
        const eventDate = new Date(log.event_time);

        return (
          <S.CardAlarmWrapper key={`logs-date-${index}`}>
            {showDate(eventDate) && (
              <div className="event-log-date">
                <span>{eventDate.getDate()}</span>{" "}
                {getMonthName(eventDate.getMonth())} {eventDate.getFullYear()}
              </div>
            )}

            <CardAlarmItem device={device} log={log} />
          </S.CardAlarmWrapper>
        );
      })}
    </>
  );
};

export default CardsAlarms;
