import history from "../../Services/history";
import { useContext } from "react";
import RoomModel from "../../Models/RoomModel";
import GlobalContex from "../../GlobalContext/GlobalContext";
import { makeStyles } from "@material-ui/core/styles";
import { ListItem } from "@material-ui/core";
import PlaceModel from "../../Models/PlaceModel";

const useStyles = makeStyles(() => {
  const backgroundColor = "#007A39";
  return {
    root: {
      transition: "0.2s ease",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      color: "white",
      fontSize: "15px",
      fontWeight: 400,
      padding: "8px 16px",
      backgroundColor: "#00a335",
      "&:hover": {
        backgroundColor,
      },
      "&.Mui-selected": {
        backgroundColor,
        "&:hover": {
          backgroundColor,
        },
      },
      "&.MuiListItem-divider": {
        borderBottom: "1px solid #36B35F",
      },
    },
  };
});

type RoomMenuItemProps = {
  room: RoomModel;
  place: PlaceModel;
};

const RoomMenuItem: React.FunctionComponent<RoomMenuItemProps> = (
  props: RoomMenuItemProps
) => {
  const classes = useStyles();

  const { pathnameUrl, setPathnameUrl, setRoomIdR, setPlaceSelected } =
    useContext(GlobalContex);

  const navigateToManageRoom = (room: RoomModel) => {
    localStorage.setItem("placeSelectName", room.place.name);
    localStorage.setItem("roomSelectName", room.name);
    setRoomIdR(room);
    setPathnameUrl("/");
    history.push("/");
  };

  const isSelected = () => {
    if (
      pathnameUrl === "/" &&
      localStorage.getItem("roomSelectName") === props.room.name
    ) {
      return true;
    }
    return false;
  };

  return (
    <ListItem
      button
      key={props.room.room_id}
      classes={classes}
      selected={isSelected()}
      divider
      onClick={() => {
        setPlaceSelected(props.place);
        localStorage.setItem("placeSelectName", props.place.name);
        localStorage.setItem("placeSelectedID", props.place.place_id);
        navigateToManageRoom(props.room);
      }}
    >
      <span
        style={{
          marginLeft: "10px",
        }}
      >
        {props.room.name}
      </span>
    </ListItem>
  );
};

export default RoomMenuItem;
