import EnvironmentModel from "../../Models/EnvironmentModel";

interface Props {
  environments: EnvironmentModel[] | undefined;
}

const ListEnvironmentsFromDevices: React.FunctionComponent<Props> = ({
  environments,
}: Props) => {
  const listNames: string | undefined = environments
    ?.filter((s: EnvironmentModel, index: number) => index > 0)
    .map((env: EnvironmentModel) => env.name)
    .join(`, `);

  return (
    <div
      style={{
        display: `flex`,
        flexDirection: `row`,
        justifyContent: `space-between`,
        height: `25px`,
        alignItems: `left`,
      }}
    >
      <div
        style={{
          color: "#8B979F",
          fontSize: "13px",
          display: `flex`,
          justifyContent: `center`,
          alignItems: `center`,
        }}
      >
        {environments && environments[0] ? environments[0].name : " "}
      </div>
      {environments && environments?.length > 1 && (
        <div
          title={listNames}
          style={{
            color: `#8B979F`,
            display: `flex`,
            backgroundColor: `#f5f6f6`,
            borderRadius: `50%`,
            width: `25px`,
            justifyContent: `center`,
            alignItems: `center`,
          }}
        >
          +{environments && environments.length - 1}
        </div>
      )}
    </div>
  );
};

export default ListEnvironmentsFromDevices;
