import RecoverPasswordProvider from "./Context/RecoverPasswordProvider";
// import RecoverPasswordContainer from "./Components/RecoverPasswordContainer";
import RecoverPasswordIzySmart from "./Components/RecoverPasswordIzySmart";

const RecoverPassword: React.FunctionComponent = () => {
  return (
    <RecoverPasswordProvider>
      {/* <RecoverPasswordContainer /> */}
      <RecoverPasswordIzySmart />
    </RecoverPasswordProvider>
  );
};

export default RecoverPassword;
