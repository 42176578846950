import { useCallback, useContext, useEffect, useState } from "react";
import EmptyState from "../../../../Components/EmptyState";
import { planPriceInterval, Plans } from "../../../../Models/Plans";
import history from "../../../../Services/history";
import { getAllPans } from "../../../../Services/PlansService";
import globalContext from "../../../../GlobalContext/GlobalContext";
import ItemIcon from "../../../EnterpriseCreatePage/components/ItemIcon";

const intervalMap: {
  [value in planPriceInterval]: { singular: string; plural: string };
} = {
  day: { singular: "dia", plural: "dias" },
  month: { singular: "mês", plural: "meses" },
  year: { singular: "ano", plural: "anos" },
};

const ContentTrialPlanPage: React.FunctionComponent = () => {
  const { showAlert } = useContext(globalContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [plan, setPlan] = useState<Plans>();

  const urlSearch = new URLSearchParams(window.location.search);
  const planId = urlSearch.get("plan");

  useEffect(() => {
    getAllPans()
      .then(({ data }) => {
        const selected = data.find((p) => p.id == planId);
        if (!selected) {
          history.push("/");
          return;
        }
        setPlan(selected);
      })
      .catch(() => {
        showAlert("Não foi possível carregar dados do plano", "error");
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      setLoading(false);
      setPlan(undefined);
    };
  }, []);

  const trialPeriod = useCallback(() => {
    const interval = intervalMap[plan!.prices[0].interval];
    const count = plan!.prices[0].interval_count;
    const intervalText = count > 1 ? interval.plural : interval.singular;
    return `${count} ${intervalText}`;
  }, [plan]);

  return (
    <>
      {loading && <p>Carregando...</p>}
      {!loading && (
        <div
          style={{
            paddingTop: "80px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              fontWeight: 700,
              fontSize: "21px",
              lineHeight: "29px",
              color: "#3E5055",
            }}
          >
            Você selecionou o Plano:
          </div>
          <div
            style={{
              display: "flex",
              backgroundColor: "#FFF",
              boxShadow: "0px 0px 4px 4px rgba(0, 0, 0, 0.03)",
              borderRadius: "5px",
              marginTop: "45px",
            }}
          >
            <div
              style={{
                padding: "20px 70px 20px 20px",
                borderRight: "1px solid #EBEEEE",
                display: "flex",
                alignItems: "center",
                minWidth: "400px",
              }}
            >
              <ItemIcon color={plan?.color_plan} />
              <div
                style={{
                  marginLeft: "12px",
                  fontWeight: 600,
                  fontSize: "15px",
                  lineHeight: "20px",
                  color: "#3E5055",
                }}
              >
                Plano {plan?.name}{" "}
              </div>
            </div>
          </div>
          <div
            style={{
              background: "#EBEEEE",
              borderRadius: "5px",
              padding: "20px 50px",
              maxWidth: "75%",
              marginTop: "25px",
              fontSize: "21px",
              lineHeight: "29px",
              color: "#3E5055",
            }}
          >
            <p className={"m-0"}>
              <strong>Obrigado por experimentar o IzyManager!</strong>
            </p>
            <p>Você tem {trialPeriod()} de acessso. </p>
            <p>
              <strong
                style={{ color: "#00A335", cursor: "pointer" }}
                onClick={() => history.push("/select-home")}
              >
                Acessar seu empreendimento
              </strong>{" "}
              no IzyManager!
            </p>
          </div>
          <EmptyState
            title={"Agradeçemos pela sua confiança!"}
            subtitle={
              <span>
                Você sempre pode conferir seu plano atual indo em{" "}
                <strong
                  style={{ color: "#00A335", cursor: "pointer" }}
                  onClick={() => history.push("/select-home")}
                >
                  Empreendimento
                </strong>
              </span>
            }
            image={"/assets/imgs/empty-state-imgs/sucess-payment-img.png"}
          />
        </div>
      )}
    </>
  );
};

export default ContentTrialPlanPage;
