import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useContext } from "react";
import GlobalContext from "../../../GlobalContext/GlobalContext";
import ButtonText from "../../CustomButton/ButtonText";

const useStyles = makeStyles(() =>
  createStyles({
    headerDrawerApplicationContainer: {
      height: "75px",
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      padding: "0px 25px",

      "& .MuiButton-sizeSmall": {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  })
);

const DrawerHeader: React.FunctionComponent = () => {
  const classes = useStyles();
  const {
    drawerApplicationHistory,
    toggleCloseDrawerApplication,
    goBackDrawerApplication,
  } = useContext(GlobalContext);
  return (
    <div className={classes.headerDrawerApplicationContainer}>
      <div>
        {drawerApplicationHistory.length > 1 && (
          <ButtonText
            startIcon={
              <img src="assets/icons/icon-back-drawer-application.svg" />
            }
            onClick={() => goBackDrawerApplication()}
            size={"small"}
            style={{ fontWeight: "normal" }}
          >
            Voltar
          </ButtonText>
        )}
      </div>

      <ButtonText
        startIcon={<img src="assets/icons/icon-close-drawer-application.svg" />}
        onClick={() => toggleCloseDrawerApplication()}
        size={"small"}
        style={{ fontWeight: "normal" }}
      >
        Sair
      </ButtonText>
    </div>
  );
};

export default DrawerHeader;
