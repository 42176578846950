import {
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import GlobalContex from "../../../GlobalContext/GlobalContext";
import ChangeToNewPlanContext from "./ChangeToNewPlanContext";
import { useLocation } from "react-router-dom";
import { RegisterEnterpriseModel } from "../../../Models/EnterpriseModel";
import { Plans, PreviewPlanResponse, Price } from "../../../Models/Plans";
import { newPricePlanChanged } from "../../../Services/PlansService";

interface LocationRedirect {
  enterpriseInfo: RegisterEnterpriseModel & { id: string };
  price: Price;
  plan: Plans;
}

const ChangeToNewPlanProvider: React.FunctionComponent = (
  props: PropsWithChildren<ReactNode>
) => {
  const { children } = props;

  const { showAlert } = useContext(GlobalContex);

  const dataRedirect = useLocation<LocationRedirect>();
  const { plan, price, enterpriseInfo } = dataRedirect.state;

  const [newPlanCost, setNewPlanCost] = useState<PreviewPlanResponse>();

  useEffect(() => {
    newPricePlanChanged(enterpriseInfo.id, price.id)
      .then((response) => {
        setNewPlanCost(response.data);
      })
      .catch(() => {
        showAlert("Não foi possível carregar dados do pagamento", "error");
      });

    return () => {
      setNewPlanCost(undefined);
    };
  }, []);

  const newPrice = 0;

  return (
    <ChangeToNewPlanContext.Provider
      value={{
        plan,
        price,
        enterprise: enterpriseInfo,
        newPrice,
        newPlanCost,
      }}
    >
      {children}
    </ChangeToNewPlanContext.Provider>
  );
};

export default ChangeToNewPlanProvider;
