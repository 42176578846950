import { useContext } from "react";
import DeviceModel from "../../../../Models/DeviceModel";
import EnvironmentsContext from "../../context/EnvironmentsContext";
import SubDevice from "../SubDevice";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Button, CircularProgress } from "@material-ui/core";
import DevicesAssociate from "../DevicesAssociate";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import ReplyIcon from "@material-ui/icons/Reply";
import EmptyState from "../../../../Components/EmptyState";
import CustomRelocateModal from "../../../../Components/CustomRelocateModal";

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontWeight: "bold",
    fontSize: "18px",
  },
  subTitle: {
    fontWeight: "normal",
    fontSize: "15px",
    color: "#8B979F",
    textTransform: "none",
    margin: "0px 10px",
    minWidth: "135px",
    minHeight: "50px",
  },
  textBody: {
    fontWeight: "normal",
    fontSize: "12px",
    color: "#8B979F",
    textTransform: "none",
  },
  icon: {
    webkitTransform: "scaleX(-1)",
    transform: "scaleX(-1)",
  },
  moveButton: {
    color: "#00A335",
    textTransform: "none",
    fontWeight: "bold",
  },
  loadingEnv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100px",
  },
}));

const SubDevicesList: React.FunctionComponent = () => {
  const classes = useStyles();
  const {
    isDevicesAssociate,
    updateEnvironment,
    hideOptionsAddDevice,
    setHideOptionsAddDevice,
    setHideOptionsRemoveDevice,
    setShowOptions,
    devicesAssociated,
    setIsDevicesAssociate,
    setDevicesToAdd,
    setDevicesToRemove,
    selectDevices,
    setSelectDevices,
    setDevicesRealocar,
    setRenderBtnDevices,
    setHideOptions,
    filterValue,
    confirmAssociation,
    updateRelocateDevices,
    loadingEnvironments,
    originalDevicesAssociated,
    originalDevicesNotAssociated,
    setDevicesAssociated,
    setDevicesNotAssociated,
  } = useContext(EnvironmentsContext);

  const {
    allDevicesSelected,
    devices,
    setOpenModalRelocate,
    devicesToRelocate,
    setDevicesToRelocate,
  } = useContext(GlobalContex);

  const handleClickClose = () => {
    setSelectDevices(false);
    setDevicesRealocar([]);
    setRenderBtnDevices(false);
    setHideOptions(false);
    setDevicesToRelocate([]);
  };

  const handleOpenModal = () => {
    setOpenModalRelocate(true);
  };

  const list = (allDevices: DeviceModel[]) => {
    if (loadingEnvironments) {
      return (
        <div className={classes.loadingEnv}>
          <CircularProgress color="secondary" />
        </div>
      );
    }
    return allDevices.length ? (
      <Grid
        container
        direction="row"
        spacing={4}
        justifyContent="flex-start"
        style={{
          padding: "20px",
        }}
      >
        {allDevices?.map((device: DeviceModel, index: number) => {
          if (device !== undefined) {
            if (device.name !== undefined) {
              return (
                <Grid
                  item
                  zeroMinWidth
                  xs={3}
                  style={{
                    paddingBottom: "20px",
                  }}
                  key={"subdevice-item-" + index}
                >
                  <SubDevice device={device} />
                </Grid>
              );
            }
          }
          return false;
        })}
      </Grid>
    ) : (
      <EmptyState
        title="Nenhum dispositivo encontrado"
        subtitle={
          <span
            style={{
              fontWeight: 400,
              fontSize: "15px",
              lineHeight: "20px",
              color: "#8B979F",
              display: "inline-block",
              textAlign: "center",
            }}
          >
            Por favor, experimente <strong>realocar um dispositivo</strong> para
            esse espaço que ele deve ser exibido aqui ou confira se a busca está
            correta.
          </span>
        }
        image={"assets/imgs/empty-state-imgs/empty-state-img-1.png"}
      />
    );
  };

  return (
    <>
      <div
        className={classes.header}
        style={{
          padding: selectDevices || hideOptionsAddDevice ? "20px" : "0px",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "start",
          }}
        >
          {selectDevices && (
            <div>
              <div
                style={{
                  fontSize: "15px",
                  fontWeight: 600,
                  lineHeight: "20px",
                  color: "#8B979F",
                  marginBottom: "10px",
                }}
              >
                Selecione os dispositivos que você deseja fazer a realocação:
              </div>
              {
                <Button
                  className={classes.moveButton}
                  variant="contained"
                  disableElevation
                  onClick={handleOpenModal}
                  disabled={devicesToRelocate.length === 0}
                  style={{
                    minHeight: "50px",
                    backgroundColor: "#00A335",
                  }}
                  startIcon={
                    <ReplyIcon htmlColor={"#FFF"} className={classes.icon} />
                  }
                >
                  <strong style={{ color: "#FFF" }}>Realocar</strong>
                </Button>
              }
              <Button
                className={classes.subTitle}
                disableElevation
                onClick={handleClickClose}
                style={{
                  border: "1px solid #BDBDBD",
                }}
              >
                Cancelar
              </Button>
            </div>
          )}
          {hideOptionsAddDevice && (
            <Button
              className={classes.subTitle}
              style={{
                backgroundColor: "#00A335",
              }}
              onClick={updateEnvironment}
            >
              <strong style={{ color: "#FFF" }}>Salvar</strong>
            </Button>
          )}

          {hideOptionsAddDevice && (
            <Button
              onClick={() => {
                setHideOptionsAddDevice(false);
                setHideOptionsRemoveDevice(false);
                setShowOptions(true);
                setIsDevicesAssociate(false);
                setDevicesToAdd([]);
                setDevicesToRemove([]);
                setHideOptions(false);
                setDevicesAssociated(originalDevicesAssociated);
                setDevicesNotAssociated(originalDevicesNotAssociated);
              }}
              className={classes.subTitle}
              style={{ border: "1px solid #BDBDBD" }}
            >
              Cancelar
            </Button>
          )}
        </div>
      </div>

      {isDevicesAssociate ? (
        <DevicesAssociate />
      ) : allDevicesSelected ? (
        list(
          devices.filter((device: any) => {
            if (device.name.toLowerCase().includes(filterValue.toLowerCase())) {
              return device;
            }
          })
        )
      ) : (
        list(
          devicesAssociated.filter((device: DeviceModel) => {
            const index = devices.findIndex(
              (dev: any) =>
                dev.id === device.id &&
                device.name.toLowerCase().includes(filterValue.toLowerCase())
            );
            if (index !== undefined && index > -1) {
              return devices[index];
            }
            return false;
          })
        )
      )}
      <CustomRelocateModal
        updateRelocateDevicesFunction={updateRelocateDevices}
        confirmRelocateDevicesFunction={confirmAssociation}
      />
    </>
  );
};

export default SubDevicesList;
