import MiniDrawer from "../../Components/CustomMiniDrawer";
import { getHomeNameSelect } from "../../Services/UserService";
import { Divider } from "@material-ui/core";
import SubHeader from "../../Components/SubHeader";
import history from "../../Services/history";
import FormOperatorsGroup, {
  SubmitFunc,
} from "./Components/FormOperatorsGroup";
import OperatorsGroupCreatePageContextProvider from "./Context/OperatorsGroupCreatePageContextProvider";
import { createUserGroups } from "../../Services/UserGroupsService";
import { useContext, useRef } from "react";
import GlobalContex from "../../GlobalContext/GlobalContext";
import { FormOperatorsGroupType } from "../../Models/UserGroupsModel";

const OperatorsGroupCreatePage: React.FunctionComponent = () => {
  const { showAlert } = useContext(GlobalContex);

  const navigateToOperators = () => {
    history.push("/operators");
  };

  const formRef = useRef<{ resetName: () => void; nameGroupFocus: () => void }>(
    null
  );

  const initialValue: FormOperatorsGroupType = {
    user_group_id: "",
    name: "",
    users: [],
    automations: [],
    rooms: {
      place_selecteds: [],
      rooms_selecteds: [],
    },
  };

  const onSubmit: SubmitFunc = async (form) => {
    try {
      const response = await createUserGroups(form);
      if (response.status === 200) {
        showAlert(
          "Grupo de operadores " + form.name + " criado com sucesso!",
          "success"
        );
        return true;
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.errors[0]?.message.replace(
          "UserGroup",
          "grupo de usuário"
        ) || "Erro ao tentar editar grupo de operadores!";
      showAlert(errorMessage, "error");
    }
    return false;
  };

  const onSuccess = (duplicate: boolean) => {
    if (duplicate) {
      formRef?.current?.resetName();
      formRef?.current?.nameGroupFocus();
    } else {
      navigateToOperators();
    }
  };

  return (
    <OperatorsGroupCreatePageContextProvider>
      <MiniDrawer
        breadcrumb={[
          { name: getHomeNameSelect(), to: "manage-place" },
          { name: "Grupo de operadores", to: "/operators" },
          { name: `Novo grupo de operadores`, to: "" },
        ]}
      >
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <SubHeader
            text={`Novo grupo de operadores`}
            subText={""}
            backPress={navigateToOperators}
          />
          <Divider style={{ margin: "20px 0" }} />

          <FormOperatorsGroup
            ref={formRef}
            initialValue={initialValue}
            onSubmit={onSubmit}
            onSuccess={onSuccess}
            onCancel={() => {
              navigateToOperators();
            }}
          />
        </div>
      </MiniDrawer>
    </OperatorsGroupCreatePageContextProvider>
  );
};

export default OperatorsGroupCreatePage;
