import { FunctionComponent } from "react";
import DeviceModel, { DeviceFunctionsModel } from "../../Models/DeviceModel";

//Importar Telas Customizadas dos Dispositivos
import EWS_101_I_Custom from "../../Components/CustomDevices/Devices/EWS_101_I";
import EWS_201_E_Custom from "../../Components/CustomDevices/Devices/EWS_201_E";
import EWS_211_Custom from "../../Components/CustomDevices/Devices/EWS_211";
import EWS_222_Custom from "../../Components/CustomDevices/Devices/EWS_222";
import EWS_409_Custom from "../../Components/CustomDevices/Devices/EWS_409";
import IDF_620_Custom from "../../Components/CustomDevices/Devices/IDF_620";
import IDG_620_Custom from "../../Components/CustomDevices/Devices/IDG_620";
import IFR_1001_2020_3000_7000_7001_Custom from "../../Components/CustomDevices/Devices/IFR_1001_2020_3000_7000_7001";
import ISA_1001_Custom from "../../Components/CustomDevices/Devices/ISA_1001";
import ICA_1001_Custom from "../../Components/CustomDevices/Devices/ICA_1001";
import ISM_1001_Custom from "../../Components/CustomDevices/Devices/ISM_1001";
import IZY_Connect_Custom from "../../Components/CustomDevices/Devices/IZY_Connect";
import TV_Custom from "../../Components/CustomDevices/Devices/TV";
import Air_Conditioner_Custom from "../../Components/CustomDevices/Devices/Air_Conditioner";

//Importar Arquivos de Configuração dos Dispositivos
import EWS_101_I_Config from "../EWS 101 I.json";
import EWS_201_E_Config from "../EWS 201 E.json";
import EWS_211_Config from "../EWS 211.json";
import EWS_222_Config from "../EWS 222.json";
import EWS_301_Config from "../EWS 301.json";
import EWS_400_Config from "../EWS 400.json";
import EWS_407_Config from "../EWS 407.json";
import EWS_409_Config from "../EWS 409.json";
import EWS_410_Config from "../EWS 410.json";
import EWS_440_Config from "../EWS 440.json";
import EWS_1001_Config from "../EWS 1001.json";
import EWS_1002_Config from "../EWS 1002.json";
import EWS_1003_Config from "../EWS 1003.json";
import IAC_110_Config from "../IAC 110.json";
import ICA_1001_Config from "../ICA 1001.json";
import IDF_620_Config from "../IDF 620.json";
import IDG_620_Config from "../IDG 620.json";
import IFR_1001_Config from "../IFR 1001.json";
import IFR_2020_Config from "../IFR 2020.json";
import IFR_3001_IFR_3001_Config from "../IFR 3000 IFR 3001.json";
import IFR_7000_IFR_7001_Config from "../IFR 7000 IFR 7001.json";
import IGD_110_Config from "../IGD 110.json";
import ISA_1001_Config from "../ISA 1001.json";
import ISI_1001_Config from "../ISI 1001.json";
import ISM_1001_Config from "../ISM 1001.json";
import IZY_Connect_Config from "../IZY Connect.json";
import Generic_Config from "../GENERIC.json";
import StatusFunction from "../../Models/StatusFunctionModel";

interface Props {
  device: DeviceModel;
  status: StatusFunction[];
  specificationsDevice: DeviceFunctionsModel;
}

interface CustomDevicesComponent {
  [index: string]: FunctionComponent<Props>;
}

const EWS_407_Custom = EWS_409_Custom;
const EWS_410_Custom = EWS_409_Custom;

export const devicesCustomComponent: CustomDevicesComponent = {
  tllx9jhsauzv4iiz: EWS_101_I_Custom,
  "17zko7j4wayb7mz9": EWS_201_E_Custom,
  pulbj5kwemgzkc4u: EWS_211_Custom,
  w6fh0vb46hyrcb9h: EWS_222_Custom,
  l8aupqc1jh8ylivd: EWS_407_Custom,
  lzevtzhz902xzphg: EWS_407_Custom,
  s4kdf8w3mww5l6g3: EWS_409_Custom,
  "3ydcov9daqzhnrxv": EWS_409_Custom,
  q2nl0usa6jeegnko: EWS_410_Custom,
  tgfkxkkbcwbz1pmo: EWS_410_Custom,
  puyasotiuv6zrrr1: EWS_410_Custom,
  "3kuiblb6": IDF_620_Custom,
  dcweoxja: IDG_620_Custom,
  hnypn4ub: IDG_620_Custom,
  gfj5esbz: IFR_1001_2020_3000_7000_7001_Custom,
  apzqffsv: IFR_1001_2020_3000_7000_7001_Custom,
  xjxt08px: IFR_1001_2020_3000_7000_7001_Custom,
  snr3p274: IFR_1001_2020_3000_7000_7001_Custom,
  "7i1wr0emdqoixukc": ICA_1001_Custom,
  nmj3laim: ISA_1001_Custom,
  zgaafwtr: ISM_1001_Custom,
  egcrtwzanba4yclz: IZY_Connect_Custom,
  "000000177x": TV_Custom,
  "000000177t": Air_Conditioner_Custom,
};

interface DevicesConfig {
  [key: string]: {
    [key: string]:
      | string[]
      | { [key: string]: string }
      | {
          [key: string]: {
            [key: string]:
              | boolean
              | string
              | string[]
              | {
                  [key: string]: string | string[];
                };
          };
        };
  };
}

export const devicesCustomConfig: DevicesConfig = {
  tllx9jhsauzv4iiz: EWS_101_I_Config,
  "17zko7j4wayb7mz9": EWS_201_E_Config,
  pulbj5kwemgzkc4u: EWS_211_Config,
  w6fh0vb46hyrcb9h: EWS_222_Config,
  bx1pfv7wkxel7jqz: EWS_301_Config,
  yftzkqkho5lofbjv: EWS_301_Config,
  "71zbcefbvhrmffc0": EWS_400_Config,
  itxs0dglifofngx3: EWS_400_Config,
  "4ohlva37whbkzojj": EWS_400_Config,
  l8aupqc1jh8ylivd: EWS_407_Config,
  lzevtzhz902xzphg: EWS_407_Config,
  s4kdf8w3mww5l6g3: EWS_409_Config,
  "3ydcov9daqzhnrxv": EWS_409_Config,
  q2nl0usa6jeegnko: EWS_410_Config,
  tgfkxkkbcwbz1pmo: EWS_410_Config,
  puyasotiuv6zrrr1: EWS_410_Config,
  "89y4c38rilv0gydo": EWS_440_Config,
  bb84rfmnnfxwoep7: EWS_1001_Config,
  msudsbddvbydhfho: EWS_1001_Config,
  "3qsejm0vksf14nk8": EWS_1002_Config,
  yta0a8nwtyrti6tz: EWS_1002_Config,
  lss9syprrkjy96ol: EWS_1003_Config,
  u4yzejqgqwrichb0: IAC_110_Config,
  "7i1wr0emdqoixukc": ICA_1001_Config,
  "3kuiblb6": IDF_620_Config,
  dcweoxja: IDG_620_Config,
  hnypn4ub: IDG_620_Config,
  gfj5esbz: IFR_1001_Config,
  apzqffsv: IFR_2020_Config,
  xjxt08px: IFR_3001_IFR_3001_Config,
  snr3p274: IFR_7000_IFR_7001_Config,
  maifetueqfmtpnmp: IGD_110_Config,
  nmj3laim: ISA_1001_Config,
  z3kehv6a: ISI_1001_Config,
  zgaafwtr: ISM_1001_Config,
  egcrtwzanba4yclz: IZY_Connect_Config,
  genericdeviceconfig: Generic_Config,
};

export const DevicesWithSubDevices = ["egcrtwzanba4yclz", "7i1wr0emdqoixukc"];
