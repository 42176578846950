import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect, useState } from "react";

import CardsAlarmsSkeleton from "./CardsAlarmsSkeleton";

import * as S from "./styled";
import CardsAlarms from "./CardsAlarms";
import NotificationsIcon from "@material-ui/icons/Notifications";
import DeviceModel from "../../../Models/DeviceModel";
import { getLogDevices } from "../../../Services/DevicesService";
import { ErrorMessage } from "../../../Utils/ErrorMessage";
import { LogModel } from "../../../Models/LogsModel";

type Props = {
  device: DeviceModel;
};

const AlarmHistoryContainer: React.FunctionComponent<Props> = ({
  device,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [alarmsLogs, setAlarmsLogs] = useState<LogModel[]>([]);
  const [nextRowKey, setNextRowKey] = useState<string>();

  const hasMore = () => {
    return !!nextRowKey;
  };

  useEffect(() => {
    fetchDeviceAlarms(nextRowKey);
  }, []);

  const fetchDeviceAlarms = (row_key: string | undefined) => {
    setLoading(true);
    getLogDevices(device.id, { codes: "hijack,alarm_lock", row_key })
      .then((response) => {
        setNextRowKey(response.data.next_row_key);
        setAlarmsLogs([...alarmsLogs, ...response.data.logs]);
      })
      .catch((e) => {
        const msg = ErrorMessage(
          e,
          "Não foi possível carregar o histórico do dispositivo"
        );
        console.log(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchMoreDeviceAlarms = () => {
    fetchDeviceAlarms(nextRowKey);
  };

  return (
    <S.AlarmsContainer>
      <S.AlarmsContainerTitle>
        <NotificationsIcon fontSize="small" htmlColor="#3E5055" />
        <h2>Alarmes</h2>
      </S.AlarmsContainerTitle>

      <S.AlarmsContainerBody>
        {loading && alarmsLogs.length === 0 && (
          <CardsAlarmsSkeleton style={{ padding: "10px 20px" }} />
        )}

        <InfiniteScroll
          dataLength={alarmsLogs.length}
          next={fetchMoreDeviceAlarms}
          hasMore={hasMore()}
          height={"100%"}
          loader={<CardsAlarmsSkeleton />}
        >
          {alarmsLogs?.length > 0 && (
            <CardsAlarms logs={alarmsLogs} device={device} />
          )}
          {!loading && alarmsLogs?.length === 0 && (
            <p>O dispositivo não possui registros de alarme.</p>
          )}
        </InfiniteScroll>
      </S.AlarmsContainerBody>
    </S.AlarmsContainer>
  );
};

export default AlarmHistoryContainer;
