import { IconButton, makeStyles } from "@material-ui/core";
import { useContext } from "react";
import GlobalContex from "../../GlobalContext/GlobalContext";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    padding: "1.5rem 3rem 0rem 3rem",
    marginTop: "1px",
  },
  header: {
    fontWeight: 700,
    color: "#3E5055",
    fontSize: "32px",
    margin: "0px",
  },
  subHeader: {
    fontWeight: 600,
    color: "#677884",
    fontSize: "15px",
    marginTop: "10px",
    marginBottom: "20px",
    paddingBottom: "5px",
  },
  subContainer: {
    display: "flex",
    alignItems: "start",
    flexDirection: "column",
    flexGrow: 1,
  },
  buttomBack: {
    paddingTop: ".2rem",
  },
  subContainerMinimized: {
    display: "flex",
    justifyContent: "space-between",
    flexGrow: 1,
  },
}));

interface Props {
  text: string;
  subText: string | React.ReactNode;
  backPress: () => void;
}

const SubHeader = ({ text, subText, backPress }: Props): JSX.Element => {
  const classes = useStyles();

  const { expandSubHeader, setExpandSubHeader } = useContext(GlobalContex);

  const clickExpand = () => {
    setExpandSubHeader(true);
  };

  return (
    <div
      className={classes.container}
      style={{
        minHeight: subText === "" ? "75px" : expandSubHeader ? "120px" : "75px",
      }}
    >
      <div className={classes.buttomBack}>
        <IconButton onClick={backPress}>
          <img src="/assets/icons/icon_return.svg" />
        </IconButton>
      </div>

      <div
        className={classes.subContainer}
        style={{ display: expandSubHeader ? "flex" : "none" }}
      >
        <h1 className={classes.header}>{text}</h1>
        {subText !== "" && <h5 className={classes.subHeader}>{subText}</h5>}
      </div>

      <div
        className={classes.subContainerMinimized}
        style={{ display: expandSubHeader ? "none" : "flex" }}
      >
        <h1 className={classes.header}>{text}</h1>
        <IconButton onClick={clickExpand}>
          <img src="assets/icons/open-header-icon.svg" />
        </IconButton>
      </div>
    </div>
  );
};

export default SubHeader;
