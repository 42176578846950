import React, { PropsWithChildren, ReactNode } from "react";
import styled from "styled-components";

type BoxProps = {
  stepNumber?: number;
  title?: string;
  actions?: ReactNode;
  hideHeader?: boolean;
};

const BoxStyled = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  margin-bottom: 18px;

  & .box__header {
    display: flex;
    flex-direction: row;
    padding: 16px 24px;

    & .box__header__title {
      display: flex;

      align-items: center;
      justify-content: center;

      > h3 {
        margin: 0;
        padding-left: 17px;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;

        color: #3e5055;
      }
    }
  }

  & .box__body {
    display: flex;
    flex-direction: column;
    padding: 16px 24px;

    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;

      margin-bottom: 8px;
    }
  }

  & .box__header + .box__body {
    padding-top: 0px;
  }
`;

const StepNumberStyled = styled.div`
  background: #6dcc9a;
  color: white;

  width: 34px;
  height: 34px;
  border-radius: 50%;

  font-weight: 600;
  font-size: 23px;
  line-height: 34px;
  text-align: center;
`;

const Box: React.FunctionComponent<
  PropsWithChildren<BoxProps> & React.HTMLAttributes<HTMLDivElement>
> = (
  props: PropsWithChildren<BoxProps> & React.HTMLAttributes<HTMLDivElement>
) => {
  return (
    <BoxStyled>
      {!props.hideHeader && (
        <div className={"box__header"}>
          <div className={"box__header__title"}>
            <StepNumberStyled className={"step"}>
              {props.stepNumber}
            </StepNumberStyled>
            <h3>{props.title}</h3>
          </div>
          <div className={"box__header__actions"}>{props.actions}</div>
        </div>
      )}
      <div className={"box__body"}>{props.children}</div>
    </BoxStyled>
  );
};

Box.defaultProps = {
  hideHeader: false,
} as BoxProps;

export default Box;
