import { ReactNode, useContext, useEffect, useState } from "react";
import GlobalContex from "../../../GlobalContext/GlobalContext";
import { UserPaginated } from "../../../Models/UserModel";
import {
  getAllUsersGroupStub,
  devicesRestrictionEdit,
  getDevicesRestriction,
} from "../../../Services/UserGroupsService";
import {
  getUsersActive,
  getUsersNonActive,
} from "../../../Services/UserService";
import OperatorsGroupCreatePageContext from "./OperatorsGroupCreatePageContext";
import DeviceToRestrictModel from "../../../Models/DeviceToRestrict";
import EnvironmentRestrictModel from "../../../Models/EnvironmentRestrictModel";
import { RoomsSelectedModel } from "../../../Models/RoomsSelectedModel";
import { SubmitFunc } from "../Components/FormOperatorsGroup";
import {
  FormOperatorsGroupType,
  GroupUserUsersModel,
  UserGroupAutomations,
  UserGroupDevicesRestriction,
} from "../../../Models/UserGroupsModel";
import { AxiosResponse } from "axios";

interface Props {
  children: ReactNode;
}

const OperatorsGroupCreatePageProvider: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { children } = props;
  const { showAlert, toggleWaitIndicator, setPathnameUrl } =
    useContext(GlobalContex);

  const [operatorsGroupId, setOperatorsGroupId] = useState<string>("");
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [operatorsGroupListEdit, setOperatorsGroupListEdit] = useState<
    GroupUserUsersModel[]
  >([]);
  const [isCheckAllRestrictModal, setIsCheckAllRestrictModal] =
    useState<boolean>(false);
  const [isCheckAllPlaces, setIsCheckAllPlaces] = useState<boolean>(false);
  const [isCheck, setIsCheck] = useState<string[]>([]);
  const [isCheckGrupo, setIsCheckGrupo] = useState<string[]>([]);
  const [isCheckRoom, setIsCheckRoom] = useState<string[]>([]);
  const [roomsSelected, setRoomsSelected] = useState<RoomsSelectedModel[]>([]);
  const [openAccessModalControl, setOpenAccessModalControl] = useState(false);
  const [selectRestrictEnvironment, setSelectRestrictEnvironment] =
    useState<EnvironmentRestrictModel>({} as EnvironmentRestrictModel);
  const [environments, setEnvironments] = useState<EnvironmentRestrictModel[]>(
    []
  );
  const [selectRoom, setSelectRoom] = useState<string>("");
  const [nameOperatorsGroupCreate, setNameOperatorsGroupCreate] =
    useState<string>("");
  const [toggleListOperatorsGroup, setToggleListOperatorsGroup] =
    useState<boolean>(false);
  const [automationsListStub, setAutomationsListStub] = useState<
    UserGroupAutomations[]
  >([]);
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);
  const [automationsList, setAutomationsList] = useState<
    UserGroupAutomations[]
  >([]);
  const [isCheckedAllAutomations, setIsCheckedAllAutomations] =
    useState<boolean>(false);
  const [isCheckedAutomation, setIsCheckedAutomation] = useState<string[]>([]);
  const [usersActive, setUserActive] = useState<UserPaginated[]>([]);
  const [userDeactive, setUserDeactive] = useState<UserPaginated[]>([]);
  const [allDevices, setAllDevices] = useState<DeviceToRestrictModel[]>([]);
  const [allDevicesWithoutEnvironment, setAllDevicesWithoutEnvironment] =
    useState<DeviceToRestrictModel[]>([]);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const selectAllDevices = () => {
    allDevices.forEach((device) => {
      device.restrict = true;
    });

    allDevicesWithoutEnvironment.forEach((device) => {
      device.restrict = true;
    });
  };

  const unselectAllDevices = () => {
    allDevices.forEach((device) => {
      if (
        !environments.find(
          (environment) =>
            environment.environment_id === device.environment_id &&
            environment.restrict
        )
      ) {
        device.restrict = false;
      }
    });

    allDevicesWithoutEnvironment.forEach((device) => {
      device.restrict = false;
    });
  };

  useEffect(() => {
    setPathnameUrl(window.location.pathname);
    const getAllData = async () => {
      toggleWaitIndicator("OperatorsGroupCreatePageContextProvider:init", true);
      Promise.all([
        getAllUsersGroupStub().then((stub) => {
          setAutomationsListStub(stub.data.automations);
          setRoomsSelected(stub.data.rooms.rooms_selecteds);
        }),
        getUsersActive(0, -1).then((responseUsersActive) => {
          setUserActive(responseUsersActive.data.content);
        }),
        getUsersNonActive(0, -1).then((responseUsersNonActive) => {
          setUserDeactive(responseUsersNonActive.data.content);
        }),
      ]).finally(() => {
        toggleWaitIndicator(
          "OperatorsGroupCreatePageContextProvider:init",
          false
        );
      });
    };

    getAllData();

    return () => {
      toggleWaitIndicator(
        "OperatorsGroupCreatePageContextProvider:init",
        false
      );
      toggleWaitIndicator(
        "OperatorsGroupCreatePageContextProvider:openEdit",
        false
      );

      setRoomsSelected([]);
      setOpenAccessModalControl(false);
      setEnvironments([]);
      setAutomationsListStub([]);
      setUserActive([]);
      setUserDeactive([]);
      setAllDevices([]);
      setAllDevicesWithoutEnvironment([]);
      setIsSubmit(false);
    };
  }, []);

  const toggleListOperatorsGroupFunction = () => {
    setToggleListOperatorsGroup(!toggleListOperatorsGroup);
  };

  const toggleOpenRegister = () => {
    setIsEdit(false);
    setOperatorsGroupListEdit([]);
  };

  const devicesToRestrict = async (room: string) => {
    try {
      let response: AxiosResponse<UserGroupDevicesRestriction>;
      const index = roomsSelected.findIndex(
        (es: RoomsSelectedModel) => es.room_id === room
      );
      if (index > -1) {
        const temp_room = roomsSelected[index].deviceRestriction;
        if (!temp_room) {
          if (!isEdit) {
            response = await getDevicesRestriction(room);
          } else {
            response = await devicesRestrictionEdit(operatorsGroupId, room);
          }
          const myArrayFiltered = response.data.all_devices_in_room.filter(
            (el: DeviceToRestrictModel) => {
              return !response.data.all_devices_in_room.some(
                (f: DeviceToRestrictModel) => {
                  return f.device_id === el.device_id;
                }
              );
            }
          );
          setAllDevices(response.data.all_devices_in_room);
          setEnvironments(response.data.environments);
          setAllDevicesWithoutEnvironment(myArrayFiltered);
        } else {
          setEnvironments(
            temp_room.environments.map((el) => {
              return Object.assign({}, el);
            })
          );
          setAllDevices(
            temp_room.all_devices_in_room.map((el) => {
              return Object.assign({}, el);
            })
          );
          setAllDevicesWithoutEnvironment(
            temp_room.all_devices_in_environment.map((el) => {
              return Object.assign({}, el);
            })
          );
        }
      }
    } finally {
      setOpenAccessModalControl(true);
    }
  };

  const saveRestrictions = () => {
    const index = roomsSelected.findIndex(
      (es: RoomsSelectedModel) => es.room_id === selectRoom
    );
    if (index > -1) {
      roomsSelected[index].deviceRestriction = {
        environments: environments.map((el) => {
          return Object.assign({}, el);
        }),
        all_devices_in_environment: allDevicesWithoutEnvironment.map((el) => {
          return Object.assign({}, el);
        }),
        all_devices_in_room: allDevices.map((el) => {
          return Object.assign({}, el);
        }),
      };
    }
    setOpenAccessModalControl(false);
  };

  const handleOpenAccessModalControl = (selectedRoom: string) => {
    setSelectRoom(selectedRoom);
    devicesToRestrict(selectedRoom);
  };

  const handleCloseAccessModalControl = () => {
    const index = roomsSelected.findIndex(
      (es: RoomsSelectedModel) => es.room_id === selectRoom
    );
    if (index > -1) {
      const temp_room = roomsSelected[index].deviceRestriction;
      if (temp_room) {
        setEnvironments(
          temp_room.environments.map((el) => {
            return Object.assign({}, el);
          })
        );
        setAllDevices(
          temp_room.all_devices_in_room.map((el) => {
            return Object.assign({}, el);
          })
        );
        setAllDevicesWithoutEnvironment(
          temp_room.all_devices_in_environment.map((el) => {
            return Object.assign({}, el);
          })
        );
      } else {
        setEnvironments([]);
        setAllDevices([]);
        setAllDevicesWithoutEnvironment([]);
      }
    }
    setOpenAccessModalControl(false);
  };

  const restrictDevicesFromEnvironment = (
    environmentId: number,
    restrict: boolean
  ) => {
    allDevices.forEach((element, index) => {
      if (element.environment_id === environmentId) {
        if (!isCheckAllRestrictModal || restrict)
          allDevices[index].restrict = restrict;
      }
    });
  };

  const handleChangeSelectEnvironment = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectRestrictEnvironment(
      event.target.value as EnvironmentRestrictModel
    );
  };

  const selectAutomationsList = () => {
    const automations = [...automationsListStub];
    automations.forEach((automation) => {
      automation.selected = isCheckedAutomation.some(
        (id) => id === automation.automation_id
      );
    });
    return automations;
  };

  const setInitialValue = async (data: FormOperatorsGroupType) => {
    if (data.user_group_id) {
      setOperatorsGroupId(data.user_group_id);
      setIsEdit(true);
    }
    setNameOperatorsGroupCreate(data.name);
    setOperatorsGroupListEdit(data.users);
    setAutomationsList(data.automations);

    setIsCheckGrupo([]);
    const selectedPlaces: string[] = [];
    data.rooms.place_selecteds
      .filter((place) => place.selected)
      .forEach((place) => {
        selectedPlaces.push(place.place_id.toString());
      });
    setIsCheckGrupo(selectedPlaces);

    /*
    TODO: aplicar seleção aos espaços SEM LOCAL (existente no app mobile).
    Atualmente não há nem a listagem desses espaços
    */
    setIsCheckRoom([]);
    const selectedRooms: string[] = [];
    data.rooms.rooms_selecteds
      .filter((room) => room.selected)
      .forEach((selectedRoom) => selectedRooms.push(selectedRoom.room_id));
    setIsCheckRoom(selectedRooms);
  };

  const validateForm = () => {
    if (nameOperatorsGroupCreate.trim() === "") {
      throw new Error("Digite um nome para o grupo!");
    }
  };

  const saveOperatorsGroup = async (
    submit: SubmitFunc,
    successCallback: () => void
  ) => {
    setIsSubmit(true);
    const userSends: any[] = [];

    isCheck.map((m: any) => {
      userSends.push({ id: m });
    });

    const automationsSends = selectAutomationsList();
    let place_selecteds: any[] = [];
    let rooms_selecteds: RoomsSelectedModel[] = [];
    const response = await getAllUsersGroupStub();
    place_selecteds = response.data["rooms"]["place_selecteds"];
    rooms_selecteds = roomsSelected;

    isCheckGrupo.forEach(async function (item) {
      place_selecteds.forEach(function (place) {
        if (item === place.place_id) {
          place.selected = true;
        }
      });
    });

    isCheckRoom.forEach(async function (item) {
      rooms_selecteds.forEach(function (room) {
        if (item === room.room_id) {
          room.selected = true;
        }
      });
    });

    const ObjSend = {
      id: "",
      name: nameOperatorsGroupCreate.trim(),
      users: userSends,
      automations: automationsSends,
      rooms: {
        place_selecteds: place_selecteds,
        rooms_selecteds: rooms_selecteds,
      },
    };

    try {
      validateForm();
    } catch (e) {
      setIsSubmit(false);
      let message = "Dados inválidos";
      if (e instanceof Error) message = e.message;
      showAlert(message, "warning");
      return;
    }

    if (await submit(ObjSend)) {
      if (successCallback) successCallback();
    }
    setIsSubmit(false);
  };

  const resetFormCreateGroup = () => {
    setNameOperatorsGroupCreate("");
    toggleOpenRegister();
    toggleListOperatorsGroupFunction();
    setIsCheckedAllAutomations(false);
    setIsCheckedAutomation([]);
    setIsCheckAll(false);
    setIsCheck([]);
    setIsCheckGrupo([]);
    setIsCheckRoom([]);
    setIsCheckAllPlaces(false);
    setEnvironments([]);
    setAllDevices([]);
    setAllDevicesWithoutEnvironment([]);
    setRoomsSelected([]);
  };

  return (
    <OperatorsGroupCreatePageContext.Provider
      value={{
        setInitialValue,
        isCheckAllPlaces,
        setIsCheckAllPlaces,
        setEnvironments,
        operatorsGroupListEdit,
        isEdit,
        isCheckedAllAutomations,
        setIsCheckedAllAutomations,
        isCheckedAutomation,
        setIsCheckedAutomation,
        unselectAllDevices,
        selectAllDevices,
        setNameOperatorsGroupCreate,
        nameOperatorsGroupCreate,
        automationsList,
        automationsListStub,
        saveOperatorsGroup,
        openAccessModalControl,
        handleOpenAccessModalControl,
        handleCloseAccessModalControl,
        selectRestrictEnvironment,
        setSelectRestrictEnvironment,
        handleChangeSelectEnvironment,
        environments,
        usersActive,
        isCheckAll,
        setIsCheckAll,
        restrictDevicesFromEnvironment,
        isCheckAllRestrictModal,
        setIsCheckAllRestrictModal,
        isCheck,
        isCheckGrupo,
        isCheckRoom: isCheckRoom,
        setIsCheck,
        setIsCheckGrupo,
        setIsCheckRoom: setIsCheckRoom,
        resetFormCreateGroup,
        userDeactive,
        allDevices,
        setAllDevices,
        allDevicesWithoutEnvironment,
        setAllDevicesWithoutEnvironment,
        saveRestrictions,
        isSubmit,
      }}
    >
      <div
        style={{
          backgroundColor: "#FFF",
          width: "100%",
        }}
      >
        {children}
      </div>
    </OperatorsGroupCreatePageContext.Provider>
  );
};

export default OperatorsGroupCreatePageProvider;
