import { useContext, useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import DeviceModel, { DeviceFunctionsModel } from "../../Models/DeviceModel";
import { getFetchSpecifications } from "../../Services/DevicesService";
import CustomSwitchDevice from "../CustomSwitchDevice";
import DeviceDrawerComposite from "../CustomDevices/DeviceDrawerComposite";
import ListEnvironmentsFromDevices from "../ListEnvironmentsFromDevices";
import GlobalContex from "../../GlobalContext/GlobalContext";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { ErrorMessage } from "../../Utils/ErrorMessage";
import { AxiosError } from "axios";

interface Props {
  device: DeviceModel;
  isFavorited?: boolean;
  removeFavorite?: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    paper_item: {
      display: "flex",
      padding: "19px",
      margin: "5px",
      height: "190px",
      minWidth: "190px",
      borderRadius: "20px",
      alignContent: "space-between",
      flexDirection: "column",
      cursor: "pointer",
    },
    paper_content: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    paper_content_name: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      paddingTop: "15px",
    },
    paper_content_item: {
      display: "flex",
    },
  })
);

const CustomItemDevice: React.FunctionComponent<Props> = (props: Props) => {
  const [specificationsDevice, setSpecificationsDevice] =
    useState<DeviceFunctionsModel>({ category: "", functions: [], status: [] });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const { toggleOpenDrawerApplication } = useContext(GlobalContex);

  useEffect(() => {
    return () => {
      setSpecificationsDevice({ category: "", functions: [], status: [] });
    };
  }, []);

  useEffect(() => {
    if (props.device.id) {
      const fetchSpecifications = async () => {
        try {
          const { data } = await getFetchSpecifications(props.device.id);
          setSpecificationsDevice(data);
        } catch (error) {
          const e = error as AxiosError;
          if (e.isAxiosError && e.request.status !== 400) {
            console.error(
              ErrorMessage(e, "Falha ao carregar comandos do dispositivo")
            );
          }
        }
      };
      fetchSpecifications();
    }
  }, [props.device.id]);

  const classes = useStyles();

  const cardDeviceClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if ((event.target as HTMLElement).id === props.device.id) {
      return;
    }
    toggleOpenDrawerApplication(
      <DeviceDrawerComposite
        status={props.device.status}
        device={props.device}
      />
    );
  };

  return (
    <Paper
      elevation={1}
      className={classes.paper_item}
      style={{
        backgroundColor: props.device.online ? "#FFF" : "#f5f6f6",
        filter: props.device.online ? "" : "grayscale(80%)",
        opacity: props.device.online ? "" : "0.5",
        boxShadow: props.isFavorited ? "0px 10px 20px 0px #00A6561A" : "",
        border: props.isFavorited ? "1px solid #D1DAE0" : "",
      }}
      onClick={(event) => {
        if (!open) {
          cardDeviceClick(event);
        }
      }}
    >
      <div className={classes.paper_content}>
        <img src={props.device.icon} height="58" alt="Ícone do dispositivo" />
        {!props.device.online && <p>off</p>}
        {props.device.online && (
          <CustomSwitchDevice
            id={props.device.id}
            functionsDevice={specificationsDevice}
            status={props.device.status}
          />
        )}
      </div>
      <div className={classes.paper_content_name}>
        <div className={classes.paper_content_item}>
          <span style={{ color: "#3E5055", cursor: "pointer" }}>
            <b>{props.device.name}</b>
          </span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {props.device.environments && (
          <ListEnvironmentsFromDevices
            environments={props.device.environments}
          />
        )}
        <div
          style={{
            display: props.isFavorited ? "block" : "none",
          }}
        >
          <IconButton
            size="small"
            aria-describedby={id}
            color="primary"
            style={{
              backgroundColor: "#EBEEEE",
            }}
            onClick={handleClick}
          >
            <MoreVertIcon
              style={{
                color: "#3E5055",
              }}
            />
          </IconButton>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Button
              onClick={props.removeFavorite}
              endIcon={<DeleteOutlineIcon />}
              style={{
                textTransform: "none",
              }}
            >
              Remover atalho
            </Button>
          </Popover>
        </div>
      </div>
    </Paper>
  );
};

export default CustomItemDevice;
