import {
  createStyles,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import StarIcon from "@material-ui/icons/Star";
import AutomationPageContext from "../../../Context/AutomationPageContext";

const CustomDrawerAutomationMenu: React.FunctionComponent = () => {
  const classes = useStyles();

  const { setOpenModalFavoritedAutomation } = useContext(AutomationPageContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const menuClickItem = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const menuCloseItem = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={menuClickItem}>
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={menuCloseItem}
      >
        <MenuItem
          onClick={() => {
            setOpenModalFavoritedAutomation(true);
            menuCloseItem();
          }}
          className={classes.menuItem}
        >
          <div className={classes.menuItemLabel}>Criar atalho no dashboard</div>
          <StarIcon fontSize="small" />
        </MenuItem>
      </Menu>
    </>
  );
};

export default CustomDrawerAutomationMenu;

const useStyles = makeStyles(() =>
  createStyles({
    menuItem: {
      fontSize: "12px",
      lineHeight: "16px",
      textAlign: "right",
      color: "#8B979F",
      justifyContent: "end",
    },
    menuItemLabel: {
      marginRight: "5px",
    },
  })
);
