import { createStyles, makeStyles, IconButton } from "@material-ui/core";
import { HomeUserModel } from "../../../../../../Models/HomeUserModel";
import history from "../../../../../../Services/history";

interface Props {
  homeUser: HomeUserModel;
  showStatusCard: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    labelStatusEnterprise: {
      color: "#FFF",
      borderRadius: "5px 5px 0px 0px",
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "18px",
      minHeight: "35px",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "4px 0px",
      paddingLeft: "20px",
      textAlign: "center",
      height: "45px",
    },
    statusText: {
      width: "100%",
    },
  })
);

const EnterpriseStatus: React.FunctionComponent<Props> = ({
  homeUser,
  showStatusCard,
}: Props) => {
  const classes = useStyles();
  const imgArrow = "assets/icons/enterprise-status-arrow.svg";
  const imgAlert = "assets/icons/enterprise-alert-icon.svg";

  if (homeUser.status === "PENDING_REGISTRATION" || homeUser.status === null) {
    return (
      <div
        className={classes.labelStatusEnterprise}
        onClick={(event) => {
          event.stopPropagation();
          localStorage.setItem("redirectStep", "0");
          localStorage.setItem("redirectEnterprise", homeUser.home_id);
          history.push("/enterprise/create");
        }}
        style={{
          backgroundColor: "#6DCC9A",
          cursor: "pointer",
          display: showStatusCard,
        }}
      >
        <div className={classes.statusText}>
          Clique aqui para finalizar cadastro
        </div>
        <IconButton>
          <img src={imgArrow} />
        </IconButton>
      </div>
    );
  }

  if (homeUser.status === "PENDING_SUBSCRIBE") {
    return (
      <div
        className={classes.labelStatusEnterprise}
        onClick={(event) => {
          event.stopPropagation();
          localStorage.setItem("redirectStep", "1");
          localStorage.setItem("redirectEnterprise", homeUser.home_id);
          history.push("/enterprise/create");
        }}
        style={{
          backgroundColor: "#6DCC9A",
          cursor: "pointer",
          display: showStatusCard,
        }}
      >
        <div className={classes.statusText}>Escolha seu plano</div>
        <IconButton>
          <img src={imgArrow} />
        </IconButton>
      </div>
    );
  }

  if (homeUser.status === "CREATED") {
    return (
      <div
        className={classes.labelStatusEnterprise}
        style={{
          backgroundColor: "#6D99CC",
          cursor: "pointer",
          display: showStatusCard,
        }}
        onClick={(event) => {
          event.stopPropagation();
          localStorage.setItem("redirectStep", "2");
          localStorage.setItem("redirectEnterprise", homeUser.home_id);
          history.push("/enterprise/create");
        }}
      >
        <div className={classes.statusText}>Aguardando pagamento</div>
        <IconButton>
          <img src={imgArrow} />
        </IconButton>
      </div>
    );
  }

  if (homeUser.status === "ABORTED" || homeUser.status === "PAYMENT_FAILED") {
    return (
      <div
        className={classes.labelStatusEnterprise}
        onClick={(event) => {
          event.stopPropagation();
          localStorage.setItem("redirectStep", "1");
          localStorage.setItem("redirectEnterprise", homeUser.home_id);
          history.push("/enterprise/create");
        }}
        style={{
          backgroundColor: "#EF847E",
          cursor: "pointer",
          display: "flex",
        }}
      >
        <div className={classes.statusText}>
          <IconButton>
            <img src={imgAlert} />
          </IconButton>
          Falha no pagamento
        </div>
        <IconButton>
          <img src={imgArrow} />
        </IconButton>
      </div>
    );
  }

  if (homeUser.status === "EXPIRING") {
    return (
      <div
        className={classes.labelStatusEnterprise}
        onClick={(event) => {
          event.stopPropagation();
          localStorage.setItem("redirectStep", "1");
          localStorage.setItem("redirectEnterprise", homeUser.home_id);
          history.push("/enterprise/create");
        }}
        style={{
          backgroundColor: "#EF847E",
          cursor: "pointer",
          display: showStatusCard,
        }}
      >
        <div className={classes.statusText}>
          Plano expirado, renove seu plano
        </div>
        <IconButton>
          <img src={imgArrow} />
        </IconButton>
      </div>
    );
  }

  if (homeUser.status === "TRIAL") {
    return (
      <div
        className={classes.labelStatusEnterprise}
        style={{ backgroundColor: "#6ECCE0", display: "flex" }}
      >
        <div className={classes.statusText}>
          Trial - {homeUser.userHomeInfo.trial_days}{" "}
          {homeUser.userHomeInfo.trial_days === 1
            ? "Dia restante"
            : "Dias restantes"}
        </div>
      </div>
    );
  }

  if (homeUser.status === "TRIAL_EXPIRED") {
    return (
      <div
        className={classes.labelStatusEnterprise}
        onClick={(event) => {
          event.stopPropagation();
          localStorage.setItem("redirectStep", "1");
          localStorage.setItem("redirectEnterprise", homeUser.home_id);
          history.push("/enterprise/create");
        }}
        style={{
          backgroundColor: "#EF847E",
          cursor: "pointer",
          display: showStatusCard,
        }}
      >
        <div className={classes.statusText}>
          Trial - período expirado, escolha seu plano{" "}
        </div>
        <IconButton>
          <img src={imgArrow} />
        </IconButton>
      </div>
    );
  }

  if (
    homeUser.status === "INSUFFICIENT" ||
    (homeUser.status === "COMPLETED" &&
      (homeUser.userHomeInfo.exceeded_automation ||
        homeUser.userHomeInfo.exceeded_device ||
        homeUser.userHomeInfo.exceeded_user))
  ) {
    return (
      <div
        className={classes.labelStatusEnterprise}
        style={{
          backgroundColor: "#EAB42A",
          paddingRight: "20px",
          display: "flex",
        }}
      >
        <IconButton>
          <img src={imgAlert} />
        </IconButton>
        <div className={classes.statusText} style={{ textAlign: "left" }}>
          Empreendimento acima do limite do plano
        </div>
      </div>
    );
  }

  if (homeUser.status === "CANCELED") {
    return (
      <div
        className={classes.labelStatusEnterprise}
        onClick={(event) => {
          event.stopPropagation();
          localStorage.setItem("redirectStep", "2");
          localStorage.setItem("redirectEnterprise", homeUser.home_id);
          history.push("/enterprise/create");
        }}
        style={{
          backgroundColor: "#EF847E",
          cursor: "pointer",
          display: showStatusCard,
        }}
      >
        <div className={classes.statusText}>
          Assinatura cancelada, faça a renovação
        </div>
        <IconButton>
          <img src={imgArrow} />
        </IconButton>
      </div>
    );
  }

  return <></>;
};

export default EnterpriseStatus;
