import * as S from "../../styled";
import React, { useContext, useEffect, useState } from "react";

import TabPanel, { TabPanelProps } from "../TabPanel";
import ButtonFilled from "../../../../CustomButton/ButtonFilled";
import AddIcon from "@material-ui/icons/Add";
import GlobalContext from "../../../../../GlobalContext/GlobalContext";
import PasswordDefinitionsContainer from "../../../Devices/IFR_1001_2020_3000_7000_7001/Components/PasswordBoxesContainer";
import { getDoorLockPasswords } from "../../../../../Services/DeviceDoorLockService";
import { DoorLockPasswordModel } from "../../../../../Models/DoorLockPasswordModel";
import { CircularProgress } from "@material-ui/core";

const Loading: React.FunctionComponent = () => {
  return (
    <S.CenteredContentContainer>
      <CircularProgress />
      Carregando...
    </S.CenteredContentContainer>
  );
};

const EmptyPassword: React.FunctionComponent = () => {
  return (
    <S.CenteredContentContainer>
      <img src={"/assets/icons/icon_passwords_empty.svg"} />
      <p>Nenhuma senha dinâmica criada</p>
    </S.CenteredContentContainer>
  );
};

type ErrorRequestProps = {
  error: string;
  retry: (e: React.MouseEvent) => void;
};

const ErrorRequest: React.FunctionComponent<ErrorRequestProps> = ({
  error,
  retry,
}) => {
  return (
    <S.CenteredContentContainer>
      <p>{error}</p>
      <a href={"#"} onClick={retry}>
        Tente novamente.
      </a>
    </S.CenteredContentContainer>
  );
};

const TabPasswords: React.FunctionComponent<
  TabPanelProps & { deviceId: string }
> = ({ index, value, deviceId }) => {
  const { goForwardDrawerApplication } = useContext(GlobalContext);

  const [passwordList, setPasswordList] = useState<DoorLockPasswordModel[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();

  const fetchPasswordList = () => {
    setLoading(true);
    setError(undefined);
    getDoorLockPasswords(deviceId)
      .then((response) => {
        setPasswordList(response.data);
      })
      .catch(() => {
        setError("Não foi possível carregar os dados.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const retry = (e: React.MouseEvent) => {
    e.preventDefault();
    fetchPasswordList();
  };

  useEffect(() => {
    fetchPasswordList();
  }, []);

  return (
    <TabPanel value={value} index={index}>
      <S.PainelContent className={"empty"}>
        {loading && <Loading />}
        {!loading && !error && passwordList.length === 0 && <EmptyPassword />}
        {!loading && error && <ErrorRequest error={error} retry={retry} />}
        {passwordList.map((item) => (
          <>
            <p>{item.name}</p>
            <p>{item.effective_time}</p>
            <p>{item.invalid_time}</p>
          </>
        ))}
      </S.PainelContent>
      <S.PainelFooter style={{ backgroundColor: "#FFF", padding: 30 }}>
        <ButtonFilled
          size={"large"}
          color={"secondary"}
          startIcon={<AddIcon />}
          onClick={() => {
            goForwardDrawerApplication(
              <PasswordDefinitionsContainer deviceId={deviceId} />,
              { tabDevicesOrOpen: 1, tabFamilyOrDynamic: 1 }
            );
          }}
        >
          Adicionar senha
        </ButtonFilled>
      </S.PainelFooter>
    </TabPanel>
  );
};

export default TabPasswords;
