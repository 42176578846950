import { FunctionComponent } from "react";
import { Route, Redirect } from "react-router";
import SelectHomePage from "../../Pages/SelectHomePage";
import { getPrivacyTerWasAccepted } from "../../Services/AuthService";
import history from "../../Services/history";
import { getHomeIDSelect } from "../../Services/UserService";

interface Props {
  path: string;
  component: FunctionComponent<unknown>;
  exact?: boolean;
  needEnterprise?: boolean;
}

const ProtectedRoute: React.FunctionComponent<Props> = ({
  needEnterprise = true,
  component: Component,
  path,
  exact,
}: Props) => {
  const isAuthenticated = localStorage.getItem("user");

  const goSelectHome = () => {
    if (path === "/select-home") {
      return <SelectHomePage />;
    } else {
      history.push("/select-home");
    }
  };

  return (
    <Route
      path={path}
      exact={exact}
      render={() =>
        isAuthenticated && getPrivacyTerWasAccepted() ? (
          <>
            {needEnterprise ? (
              <>
                {Number(getHomeIDSelect()) > 0 ? (
                  <Component />
                ) : (
                  <>{goSelectHome()}</>
                )}
              </>
            ) : (
              <Component />
            )}
          </>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default ProtectedRoute;
