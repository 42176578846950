import {
  createStyles,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import GlobalContext from "../../../GlobalContext/GlobalContext";
import ReplyIcon from "@material-ui/icons/Reply";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ScheduleRoundedIcon from "@material-ui/icons/ScheduleRounded";
import Settings from "@material-ui/icons/Settings";
import DeviceModel, { DeviceFunctionsModel } from "../../../Models/DeviceModel";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CustomConfirmDialog from "../../CustomConfirmDialog";
import WithRoleAuthorization from "../../WithRoleAuthorization";
import { UserRole } from "../../../Services/UserHomeInfoLocalService";
import DeviceEventHistory from "../../CustomDevices/DeviceEventHistory";
import DeviceConfigurations from "../../CustomDevices/DeviceConfigurations";

interface Props {
  device: DeviceModel;
  specificationsDevice: DeviceFunctionsModel;
}

const DeviceMenu: React.FunctionComponent<Props> = ({
  device,
  specificationsDevice,
}: Props) => {
  const classes = useStyles();
  const {
    setOpenModalRelocate,
    setDevicesToRelocate,
    confirmRemoveDevice,
    goForwardDrawerApplication,
  } = useContext(GlobalContext);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const menuClickItem = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const menuCloseItem = () => {
    setAnchorEl(null);
  };

  const closeModalDelete = () => {
    setOpenDialog(false);
  };

  const openModelDelete = () => {
    setOpenDialog(true);
  };

  return (
    <>
      <IconButton onClick={menuClickItem}>
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={menuCloseItem}
      >
        {!device.block && (
          <div>
            <MenuItem
              onClick={() => {
                menuCloseItem();
                goForwardDrawerApplication(
                  <DeviceConfigurations
                    device={device}
                    specificationsDevice={specificationsDevice}
                  />
                );
              }}
              className={classes.menuItem}
            >
              <div className={classes.menuItemLabel}>Configurações</div>
              <Settings fontSize="small" />
            </MenuItem>
            <MenuItem
              onClick={() => {
                menuCloseItem();
                goForwardDrawerApplication(
                  <DeviceEventHistory device={device} />
                );
              }}
              className={classes.menuItem}
            >
              <div className={classes.menuItemLabel}>Histórico</div>
              <ScheduleRoundedIcon fontSize="small" />
            </MenuItem>
            <WithRoleAuthorization
              permitedRoles={[UserRole.Owner, UserRole.Admin]}
            >
              <MenuItem
                onClick={() => {
                  setDevicesToRelocate([device]);
                  menuCloseItem();
                  setOpenModalRelocate(true);
                }}
                className={classes.menuItem}
              >
                <div className={classes.menuItemLabel}>
                  Realocar dispositivo
                </div>
                <ReplyIcon className={classes.icon} />
              </MenuItem>
            </WithRoleAuthorization>
          </div>
        )}
        <WithRoleAuthorization permitedRoles={[UserRole.Owner, UserRole.Admin]}>
          <MenuItem
            onClick={() => {
              setDevicesToRelocate([device]);
              menuCloseItem();
              openModelDelete();
            }}
            className={classes.menuItem}
          >
            <div className={classes.menuItemLabel} style={{ color: "#EF847E" }}>
              Excluir dispositivo
            </div>
            <DeleteOutlineIcon
              style={{ color: "#EF847E" }}
              className={classes.icon}
            />
          </MenuItem>
        </WithRoleAuthorization>
      </Menu>
      <CustomConfirmDialog
        onClose={closeModalDelete}
        onCloseFocus={closeModalDelete}
        openDialog={openDialog}
        onConfirmButton={(event) => {
          confirmRemoveDevice(event, closeModalDelete);
        }}
        onCancelButton={closeModalDelete}
        text={
          <span>
            Você realmente deseja remover o dispositivo{" "}
            <strong>{device.name}</strong>? Ele será removido do empreendimento
            e das automações associadas a ele.
          </span>
        }
        questionMark={false}
      />
    </>
  );
};

export default DeviceMenu;

const useStyles = makeStyles(() =>
  createStyles({
    menuItem: {
      fontSize: "12px",
      lineHeight: "16px",
      textAlign: "right",
      color: "#8B979F",
      justifyContent: "end",
    },
    menuItemLabel: {
      marginRight: "15px",
    },
    icon: {
      webkitTransform: "scaleX(-1)",
      transform: "scaleX(-1)",
    },
  })
);
