import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Divider,
  Radio,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useContext, useEffect, useState } from "react";
import DeviceModel, {
  DeviceFunctionsModel,
} from "../../../../../Models/DeviceModel";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import {
  getCommandConfiguration,
  getCommandLabel,
  shouldRenderCommand,
} from "../../../../../Utils/DeviceConfiguration";
import IntegerInput from "../../../../../Components/Commands/IntegerInput/IntegerInput";
import CommandRow from "./CommandRow";
import EnumInput from "../../../../../Components/Commands/IntegerInput/EnumInput";
import TimerInput from "../../../../../Components/Commands/IntegerInput/TimerInput";
import BooleanInput from "../../../../../Components/Commands/IntegerInput/BooleanInput";
import RawInput from "../../../../../Components/Commands/IntegerInput/RawInput";
import JsonInput from "../../../../../Components/Commands/IntegerInput/JsonInput";
import StringInput from "../../../../../Components/Commands/IntegerInput/StringInput";
import GlobalContex from "../../../../../GlobalContext/GlobalContext";
import Emitter from "../../../../../Utils/Emitter";
import ConditionType from "./ConditionType";
import StatusFunction from "../../../../../Models/StatusFunctionModel";

interface Props {
  device: DeviceModel;
  status?: StatusFunction[];
  processChangeCommand?: any;
  isSelectable?: boolean;
  isOptional?: boolean;
  selectRowChange?: any;
  switchMode?: any;
  hasCustomScreen?: boolean;
  loading?: boolean;
  specificationsDevice?: DeviceFunctionsModel;
  isAutomationForm?: boolean;
  showConditionType: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#FFF",
    },
    blockOverlay: {
      position: "absolute",
      height: "100%",
      width: "100%",
      top: "200px",
      zIndex: 10,
    },
    emptyDiv: {
      textAlign: "center",
    },
    emptyImage: {
      opacity: 0.3,
    },
    accordionHeader: {
      height: "90px",
    },
    accordionContainer: {
      boxShadow: "none",
      marginBottom: "5px",
      borderTop: "0px",
      width: "100%",
    },
  })
);

const ComandContainer = (props: any) => {
  return (
    <>
      <Divider />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {props.children}
      </div>
    </>
  );
};

const SelectRow = (props: any) => {
  return (
    (props.isSelectable && (
      <Checkbox
        checked={props.selectedCommands.indexOf(props.fn.code) > -1}
        onChange={(e: any) => {
          const selected = e.target.checked;
          const cmds = [...props.selectedCommands];
          if (selected) {
            cmds.push(props.fn.code);
            props.setSelectedCommands(cmds);
          } else {
            const index = cmds.indexOf(props.fn.code);
            if (index > -1) {
              cmds.splice(index, 1);
            }
            props.setSelectedCommands(cmds);
          }
          if (props.selectRowChange)
            props.selectRowChange(
              props.fn.code,
              selected,
              props.value || props.value === 0 ? props.value : false
            );
        }}
      />
    )) ||
    (props.isOptional && (
      <Radio
        value={props.fn.code}
        checked={props.fn.code === props.selectedValue}
        onChange={(e: any) => {
          props.handleRadioChange(e);
          const selected = e.target.value === props.fn.code;
          let cmds = [...props.selectedCommands];

          if (selected) {
            cmds = [props.fn.code];
            props.setSelectedCommands(cmds);
          }
          if (props.selectRowChange) {
            props.selectRowChange(
              props.fn.code,
              selected,
              props.value,
              props.operatorCondition
            );
          }
        }}
      />
    )) || <></>
  );
};

const getProcessCommand = (
  props: any,
  defaultHandler: any,
  selectedCommands: string[]
) => {
  let proccess = defaultHandler;
  if (props.processChangeCommand) proccess = props.processChangeCommand;

  if (props.isSelectable) {
    return (commandJson: any, device_id: any, code: any) => {
      if (selectedCommands.indexOf(code) > -1) {
        proccess(commandJson, device_id, code);
      }
    };
  } else {
    return proccess;
  }
};

const CustomDefaultDevice: React.FunctionComponent<Props> = (props: Props) => {
  const classes = useStyles();
  const { handleChangeCommand } = useContext(GlobalContex);

  const [selectedCommands, setSelectedCommands] = useState<string[]>([]);

  const [selectedValue, setSelectedValue] = useState("");
  const [isStatusOnlyDevice, setStatusOnlyDevice] = useState(false);
  const [commandLinesSpecifications, setCommandLinesSpecifications] = useState<
    any[]
  >([]);
  const handleRadioChange = (event: any) => {
    setSelectedValue(event.target.value);
  };

  const processChangeCommand = getProcessCommand(
    props,
    handleChangeCommand,
    selectedCommands
  );
  const [statusDevice, setStatusDevice] = useState<
    StatusFunction[] | undefined
  >(props.status);
  const [operatorCondition, setOperatorCondition] = useState<string>("==");

  useEffect(() => {
    if (!props.isSelectable) {
      Emitter.on("UPDATE_STATUS_DEVICE_" + props.device.id, someFunction);
      return () => {
        Emitter.off("UPDATE_STATUS_DEVICE_" + props.device.id, someFunction);
      };
    }
  });

  useEffect(() => {
    if (
      props?.specificationsDevice?.functions &&
      props?.specificationsDevice?.functions?.length == 0
    ) {
      setCommandLinesSpecifications(props?.specificationsDevice?.status);
      setStatusOnlyDevice(true);
    } else if (
      props?.specificationsDevice?.functions &&
      props?.specificationsDevice?.functions?.length > 0
    ) {
      setCommandLinesSpecifications(props?.specificationsDevice?.functions);
      setStatusOnlyDevice(false);
    } else {
      setCommandLinesSpecifications([]);
      setStatusOnlyDevice(false);
    }
  }, [props.specificationsDevice]);

  const someFunction = (data: any) => {
    setStatusDevice((statusDevice: any) => {
      if (!statusDevice) statusDevice = [];
      return [
        {
          code: data.status[0].code,
          value: data.status[0].value,
        },
        ...statusDevice.filter(
          (device: any) => device.code !== data.status[0].code
        ),
      ];
    });
  };

  const updateStatusDevice = (code: any, value: any) => {
    const updateStatus: any = {};
    updateStatus[code] = value;
    setStatusDevice(
      statusDevice?.map((status: any) => {
        if (status.code === code) {
          status.value = value;
        }
        return status;
      })
    );
  };

  const disabled = isStatusOnlyDevice && !props.isOptional;
  return (
    <>
      <div>
        {props.hasCustomScreen && (
          <div style={{ width: "100%", textAlign: "right" }}>
            <Button onClick={props.switchMode}>- Funcionalidades</Button>
          </div>
        )}
      </div>
      <div className={classes.container}>
        <Typography variant={"h6"}>{props.device.name}</Typography>
        <Typography variant={"button"}>{props.device.product_name}</Typography>
      </div>
      {disabled && <div className={classes.blockOverlay}></div>}
      <List style={{ width: "100%", backgroundColor: "white" }}>
        {props.loading && <></>}
        {!props.loading && commandLinesSpecifications.length === 0 && (
          <div key={"empty"} className={classes.emptyDiv}>
            <img
              src={"/assets/imgs/noConnection.svg"}
              alt="No commands available"
              className={classes.emptyImage}
            />
            <h2>
              Este dispositivo não possui comandos ou informações de estado
            </h2>
          </div>
        )}

        {commandLinesSpecifications &&
          commandLinesSpecifications.map((fn: any, i: number) => {
            let status: any = {};

            if (statusDevice !== null)
              status = statusDevice?.find((s: any) => s.code === fn.code);

            if (!status) status = {};
            if (
              shouldRenderCommand(
                props.device,
                fn.code,
                statusDevice ? statusDevice : [],
                props.isAutomationForm
              )
            ) {
              const cmd_config = getCommandConfiguration(props.device, fn.code);
              switch (fn?.type) {
                case "Json":
                  return (
                    <ComandContainer key={i}>
                      <SelectRow
                        {...props}
                        fn={fn}
                        selectedCommands={selectedCommands}
                        setSelectedCommands={setSelectedCommands}
                        value={status.value}
                        selectedValue={selectedValue}
                        handleRadioChange={handleRadioChange}
                      />
                      <Accordion className={classes.accordionContainer}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          className={classes.accordionHeader}
                        >
                          <Typography variant="subtitle1">
                            {getCommandLabel(props.device, fn.code)}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <JsonInput
                            command={fn}
                            value={status.value}
                            parentProps={props}
                            onSubmit={(commandJson: any) => {
                              processChangeCommand(
                                commandJson,
                                props.device.id,
                                fn.code
                              );
                              updateStatusDevice(fn.code, commandJson);
                            }}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </ComandContainer>
                  );
                case "STRING":
                  return (
                    <ComandContainer key={i}>
                      <SelectRow
                        {...props}
                        fn={fn}
                        selectedCommands={selectedCommands}
                        setSelectedCommands={setSelectedCommands}
                        value={status.value}
                        selectedValue={selectedValue}
                        handleRadioChange={handleRadioChange}
                      />
                      <CommandRow
                        label={getCommandLabel(props.device, fn?.code)}
                        component={
                          <StringInput
                            command={fn}
                            value={status.value}
                            onSubmit={(value: any) => {
                              processChangeCommand(
                                value,
                                props.device.id,
                                fn?.code
                              );
                              updateStatusDevice(fn.code, value);
                            }}
                          />
                        }
                      />
                    </ComandContainer>
                  );
                case "Raw":
                  return (
                    <ComandContainer key={i}>
                      <SelectRow
                        {...props}
                        fn={fn}
                        selectedCommands={selectedCommands}
                        setSelectedCommands={setSelectedCommands}
                        value={status.value}
                        selectedValue={selectedValue}
                        handleRadioChange={handleRadioChange}
                      />
                      <CommandRow
                        label={getCommandLabel(props.device, fn?.code)}
                        component={
                          <>
                            <RawInput
                              command={fn}
                              value={status.value}
                              onChange={(value: any, submited: boolean) => {
                                if (submited) {
                                  processChangeCommand(
                                    value,
                                    props.device.id,
                                    fn?.code
                                  );
                                }
                                updateStatusDevice(fn.code, value);
                              }}
                            />
                          </>
                        }
                      />
                    </ComandContainer>
                  );
                case "Enum":
                  return (
                    <ComandContainer key={i}>
                      <SelectRow
                        {...props}
                        fn={fn}
                        selectedCommands={selectedCommands}
                        setSelectedCommands={setSelectedCommands}
                        value={status.value}
                        selectedValue={selectedValue}
                        handleRadioChange={handleRadioChange}
                      />
                      <CommandRow
                        label={getCommandLabel(props.device, fn?.code)}
                        component={
                          <EnumInput
                            values={
                              props.isAutomationForm && cmd_config
                                ? cmd_config.automation_values
                                : null
                            }
                            command={fn}
                            value={status.value}
                            device={props.device}
                            onChange={(value: any) => {
                              processChangeCommand(
                                value,
                                props.device.id,
                                fn?.code
                              );
                              updateStatusDevice(fn.code, value);
                            }}
                          />
                        }
                      />
                    </ComandContainer>
                  );
                case "Boolean":
                  return (
                    <ComandContainer key={i}>
                      <SelectRow
                        {...props}
                        fn={fn}
                        selectedCommands={selectedCommands}
                        setSelectedCommands={setSelectedCommands}
                        value={status.value}
                        selectedValue={selectedValue}
                        handleRadioChange={handleRadioChange}
                      />

                      <CommandRow
                        label={getCommandLabel(props.device, fn?.code)}
                        component={
                          <BooleanInput
                            command={fn}
                            value={status.value}
                            onChange={(value: boolean) => {
                              processChangeCommand(
                                value,
                                props.device.id,
                                fn?.code
                              );
                              updateStatusDevice(fn.code, value);
                            }}
                          />
                        }
                      />
                    </ComandContainer>
                  );
                case "Integer":
                  if (
                    (JSON.parse(fn?.values).unit === "s" ||
                      fn?.code.includes("countdown")) &&
                    status != undefined
                  ) {
                    return (
                      <ComandContainer key={i}>
                        <SelectRow
                          {...props}
                          fn={fn}
                          selectedCommands={selectedCommands}
                          setSelectedCommands={setSelectedCommands}
                          value={status.value}
                          selectedValue={selectedValue}
                          handleRadioChange={handleRadioChange}
                        />
                        <CommandRow
                          label={getCommandLabel(props.device, fn?.code)}
                          component={
                            <TimerInput
                              command={fn}
                              value={status.value}
                              onChange={(value: any) => {
                                processChangeCommand(
                                  value ? value : 0,
                                  props.device.id,
                                  fn?.code
                                );
                                updateStatusDevice(fn.code, value ? value : 0);
                              }}
                            />
                          }
                        />
                      </ComandContainer>
                    );
                  } else {
                    return (
                      <ComandContainer key={i}>
                        <SelectRow
                          {...props}
                          isOptional={props.isOptional}
                          fn={fn}
                          selectedCommands={selectedCommands}
                          setSelectedCommands={setSelectedCommands}
                          value={status.value}
                          selectedValue={selectedValue}
                          handleRadioChange={handleRadioChange}
                          operatorCondition={operatorCondition}
                        />

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "15px 0px",
                          }}
                        >
                          <p>{getCommandLabel(props.device, fn?.code)}</p>
                          {props.showConditionType && (
                            <ConditionType
                              onChange={(value: any) => {
                                setOperatorCondition(value);
                                if (fn?.code === selectedValue) {
                                  processChangeCommand(
                                    status.value,
                                    props.device.id,
                                    fn?.code,
                                    value
                                  );
                                }
                              }}
                              value={operatorCondition}
                            />
                          )}
                        </div>
                        <CommandRow
                          isOptional={true}
                          label={""}
                          component={
                            <>
                              <IntegerInput
                                command={fn}
                                value={status.value}
                                onChange={(value: any, submited: boolean) => {
                                  if (submited) {
                                    processChangeCommand(
                                      value,
                                      props.device.id,
                                      fn?.code,
                                      operatorCondition
                                    );
                                  }
                                  updateStatusDevice(fn.code, value);
                                }}
                              />
                            </>
                          }
                        />
                      </ComandContainer>
                    );
                  }
              }
            }
            return <></>;
          })}

        <Divider />
      </List>
    </>
  );
};

export default CustomDefaultDevice;
