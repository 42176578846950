import { TextField } from "@material-ui/core";
import styled from "styled-components";

type ContainerProps = {
  width?: string | undefined;
};

export const InputContainerStyled = styled.div<ContainerProps>`
  width: ${(props) => (props.width ? props.width : "100%")};
`;

type InputStyledProps = {
  backgroundcolor?: string | undefined;
  borderradius?: string | undefined;
  labelcolor?: string | undefined;
  inputcolor?: string | undefined;
  bordercolorfocus?: string | undefined;
  bordercolorhover?: string | undefined;
  bordercolor?: string | undefined;
};

export const InputStyled = styled(TextField)<InputStyledProps>`
  background-color: ${(props) =>
    props.backgroundcolor ? props.backgroundcolor : ""};
  border-radius: ${(props) => (props.borderradius ? props.borderradius : "")};
  & label {
    color: ${(props) => (props.labelcolor ? props.labelcolor : "")};
    &.MuiFormLabel-root.Mui-focused {
      color: ${(props) => (props.labelcolor ? props.labelcolor : "")};
    }
  }
  ,
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) =>
      props.bordercolorhover ? props.bordercolorhover : ""};
  }
  ,
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) =>
      props.bordercolorfocus ? props.bordercolorfocus : ""};
  }
  ,
  & div {
    & input {
      color: ${(props) => (props.inputcolor ? props.inputcolor : "")};
    }
    ,
    & fieldset {
      border-color: ${(props) => (props.bordercolor ? props.bordercolor : "")};
    }
  }
`;
