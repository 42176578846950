import { makeStyles } from "@material-ui/core";

const NotReceiveContent: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <div className={classes.title}>
        Se você não recebeu o código no seu e-mail verifique se:
      </div>
      <ol className={classes.text}>
        <li>O endereço de e-mail está correto.</li>
        <li>O e-mail não está na pasta de lixo eletrônico.</li>
        <li>
          Se você não conseguir encontrar o e-mail, ele pode estar bloqueado
          pelo seu firewall. Use um servidor de e-mail com melhor
          compatibilidade.
        </li>
        <li>
          Se mesmo assim você não conseguir obter o código, fale com o
          atendimento ao cliente (<strong>suporte@intelbras.com.br</strong>) e
          informe o nome da sua conta e o horário específico da ocorrência.
        </li>
      </ol>
    </div>
  );
};

export default NotReceiveContent;

const useStyles = makeStyles(() => ({
  content: {
    padding: "150px",
  },
  title: {
    fontWeight: 600,
    fontSize: "28px",
    lineHeight: "38px",
    color: "#3E5055",
    marginBottom: "45px",
  },
  text: {
    fontWeight: 400,
    fontSize: "21px",
    lineHeight: "29px",
    color: "#677884",
  },
}));
