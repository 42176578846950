import {
  Typography,
  createStyles,
  makeStyles,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  CircularProgress,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import CustomConfirmDialog from "../../../../Components/CustomConfirmDialog";
import CustomTable from "../../../../Components/CustomTable";
import CustomTableContainer from "../../../../Components/CustomTableContainer";
import CustomTableHeadRoom from "../../../ManageRoom/Components/CustomTableHeadRoom";
import ManagePlaceContext from "../../Context/ManagePlaceContext";
import PlaceModel from "../../../../Models/PlaceModel";
import WithRoleAuthorization from "../../../../Components/WithRoleAuthorization";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import EmptyState from "../../../../Components/EmptyState";
import history from "../../../../Services/history";
import RoomModel from "../../../../Models/RoomModel";
import CustomDeleteIcon from "../../../../Components/CustomIcons/CustomDeleteIcon";
import CustomEditIcon from "../../../../Components/CustomIcons/CustomEditIcon";
import {
  isUserAdmin,
  isUserOwner,
} from "../../../../Services/UserHomeInfoLocalService";

const useStyles = makeStyles(() =>
  createStyles({
    fab: {
      primary: "red",
      color: "white",
    },
    dialog: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    dialogContent: {
      height: "3000pc !important",
      backgroundColor: "#f4f6f8",
    },
    acoes: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    acoesClick: {
      display: "flex",
      flexDirection: "column",
    },
    row: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      "& > div": {
        paddingRight: "0.6rem",
      },
    },
    root: {
      "& .MuiTablePagination-displayedRows": {
        color: "#888888",
        fontSize: "14px",
        paddingTop: "5px",
      },
      "& .MuiIconButton-root": {
        background: "#D6DBE0",
        color: "#888888",
        padding: "3px",
        marginRight: "10px",
      },
      "& .MuiSelect-select.MuiSelect-select": {
        color: "#888888",
        fontSize: "14px",
      },
      "& .MuiTablePagination-selectRoot": {
        marginLeft: 0,
        marginRight: "50px",
      },
      "& .MuiToolbar-gutters": {
        paddingLeft: 0,
      },
    },
    displayedRows: {
      color: "#888888",
      fontSize: "14px",
    },
    labelRows: {
      marginRight: "8px",
      color: "#AFAFAF",
      fontSize: "14px",
    },
    arrow: {
      fontSize: "24px",
    },
    tablePaginationContainer: {
      "& .MuiTablePagination-spacer": {
        display: "none",
      },
      "& .MuiToolbar-gutters": {
        paddingLeft: 0,
      },
    },
  })
);

interface HeadCellRoom {
  id: string;
  label: string;
}

const PlaceList: React.FunctionComponent = () => {
  const classes = useStyles();

  const { places, setPlaceSelected, setPathnameUrl } = useContext(GlobalContex);
  const {
    placeList,
    openDialog,
    isLoadingPlaces,
    totalPlaces,
    page,
    rowsPerPage,
    order,
    isLoadingGlobalPlaces,
    loadingDelete,
    placeToDelete,
    handleOpenModalDelete,
    handleCloseModalDelete,
    setPage,
    setRowsPerPage,
    setOrder,
    togglePlaceDrawer,
    removePlace,
  } = useContext(ManagePlaceContext);

  const [orderBy, setOrderBy] = useState<keyof PlaceModel>("name");

  const headCellsPlace: HeadCellRoom[] = [{ id: "name", label: "Locais" }];

  const optionalHeadCellPlace: HeadCellRoom[] = [
    { id: "rooms", label: "Espaços" },
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  function responsiveHeight(isFilterOpen: boolean) {
    return !isFilterOpen ? "calc(94vh - 190px)" : "calc(94vh - 239.9px)";
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const defaultLabelDisplayedRows = (
    from: number,
    to: number,
    count: number
  ) => {
    return (
      <span className={classes.displayedRows}>
        {from}-{to} {"de"} {count}
      </span>
    );
  };

  const renderLabelRows = () => {
    return <span className={classes.labelRows}>{"Exibindo"}</span>;
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const compareClick = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    place: PlaceModel
  ) => {
    const placeFind = places.find((p) => p.place_id === place.place_id);
    setPlaceSelected(placeFind!);
    setPathnameUrl("/manage-room");
    localStorage.setItem("placeSelectName", place.name);
    localStorage.setItem("placeSelectedID", place.place_id);
    history.push("/manage-room");
  };

  return (
    <div
      style={{
        backgroundColor: "white",
      }}
    >
      {isLoadingPlaces || isLoadingGlobalPlaces ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <CircularProgress color="secondary" size={96} />
        </div>
      ) : (
        <>
          {placeList.length ? (
            <>
              <CustomTableContainer maxHeight={responsiveHeight(false)}>
                <CustomTable>
                  <CustomTableHeadRoom
                    order={order}
                    orderBy={orderBy}
                    customHeaderItens={headCellsPlace}
                    customOptionalHeaderItens={optionalHeadCellPlace}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {placeList.map((place: PlaceModel, index: number) => {
                      return (
                        <TableRow
                          onClick={(event) => {
                            compareClick(event, place);
                          }}
                          role="checkbox"
                          tabIndex={-1}
                          key={"place-list-item-" + index}
                          hover
                          style={{
                            width: "100%",
                            cursor: "pointer",
                          }}
                        >
                          <TableCell style={{ width: "30%" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                style={{
                                  paddingLeft: "10px",
                                  color: "#8B979F",
                                }}
                              >
                                {place.name}
                              </Typography>
                            </div>
                          </TableCell>
                          <TableCell
                            align="left"
                            colSpan={!isUserAdmin() && !isUserOwner() ? 2 : 0}
                          >
                            <Typography
                              style={{ paddingLeft: "10px", color: "#8B979F" }}
                            >
                              {place.rooms.map(
                                (item: RoomModel, indexItem: number) => {
                                  if (indexItem + 1 === place.rooms.length) {
                                    return (
                                      <span
                                        key={
                                          "label-room-" +
                                          indexItem +
                                          "-list-" +
                                          index
                                        }
                                      >
                                        {item.name}.{" "}
                                      </span>
                                    );
                                  }
                                  return (
                                    <span
                                      key={
                                        "label-room-" +
                                        indexItem +
                                        "-list-" +
                                        index
                                      }
                                    >
                                      {item.name},{" "}
                                    </span>
                                  );
                                }
                              )}
                            </Typography>
                          </TableCell>
                          <WithRoleAuthorization
                            permitedRoles={["Owner", "Administrador"]}
                          >
                            <TableCell
                              style={{ width: "10%", paddingLeft: "0px" }}
                              align="right"
                            >
                              <div className={classes.row}>
                                <CustomDeleteIcon
                                  id="fab-delete-contact"
                                  onClick={(
                                    event: React.MouseEvent<HTMLElement>
                                  ) => {
                                    event.stopPropagation();
                                    handleOpenModalDelete(place);
                                  }}
                                />
                                <CustomEditIcon
                                  id="fab-edit-contact"
                                  onClick={(
                                    event: React.MouseEvent<HTMLElement>
                                  ) => {
                                    event.stopPropagation();
                                    togglePlaceDrawer(true, place);
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ width: "3%", paddingRight: "30px" }}
                              align="right"
                            >
                              <img src="assets/icons/icon_expand_more.svg" />
                            </TableCell>
                          </WithRoleAuthorization>
                        </TableRow>
                      );
                    })}
                    <TableRow>
                      <TablePagination
                        className={classes.tablePaginationContainer}
                        rowsPerPageOptions={[5, 10, 15]}
                        count={totalPlaces}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        labelRowsPerPage={renderLabelRows()}
                        nextIconButtonText={"Próxima página"}
                        backIconButtonText={"Página anterior"}
                        onPageChange={handleChangePage}
                        labelDisplayedRows={({ from, to, count }) =>
                          defaultLabelDisplayedRows(from, to, count)
                        }
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        style={{
                          textTransform: "none",
                          padding: "0px",
                          backgroundColor: "#EBEEEE",
                          paddingLeft: "3rem",
                        }}
                      />
                    </TableRow>
                  </TableBody>
                </CustomTable>
              </CustomTableContainer>
            </>
          ) : (
            <EmptyState
              title="Não há locais disponíveis"
              subtitle="Experimente criar um novo local clicando no botão "
              image={"assets/imgs/empty-state-imgs/empty-state-img-2.png"}
              buttonName="+ Novo Local"
              goBackTo="/select-home"
            />
          )}
          <CustomConfirmDialog
            onClose={handleCloseModalDelete}
            onCloseFocus={handleCloseModalDelete}
            openDialog={openDialog}
            onConfirmButton={() => removePlace(placeToDelete)}
            onCancelButton={handleCloseModalDelete}
            text={`Você realmente deseja remover o local `}
            roomName={placeToDelete ? placeToDelete.name : ""}
            isLoading={loadingDelete}
          />
        </>
      )}
    </div>
  );
};

export default PlaceList;
