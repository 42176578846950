import { makeStyles, createStyles, CircularProgress } from "@material-ui/core";
import { useContext } from "react";
import EnterprisesConfigContext from "../../Context/EnterprisesConfigContext";
import EnterpriseListItem from "../EnterpriseListItem";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: "100%",
      width: "100%",
      borderTop: "1px solid #D1DAE0",
      borderLeft: "1px solid #D1DAE0",
      padding: "30px 60px",
    },
    title: {
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "25px",
      color: "#3E5055",
      marginBottom: "25px",
    },
    listContainer: {
      overflow: "auto",
      height: "calc(100% - 50px)",
    },
  })
);

const EnterprisesConfigList: React.FunctionComponent = () => {
  const classes = useStyles();
  const { isLoadingEnterpriseData, enterprises } = useContext(
    EnterprisesConfigContext
  );

  return (
    <div className={classes.container}>
      <div className={classes.title}>Todos empreendimentos</div>
      {isLoadingEnterpriseData ? (
        <CircularProgress color="secondary" size={30} />
      ) : (
        <div className={classes.listContainer}>
          {enterprises.length === 0 && (
            <div>Nenhum empreendimento cadastrado!</div>
          )}
          {enterprises.map((enterprise, index) => (
            <EnterpriseListItem
              enterprise={enterprise}
              key={"enterprise-list-item-" + index}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default EnterprisesConfigList;
