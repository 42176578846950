import {
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import GlobalContex from "../../../GlobalContext/GlobalContext";
import {
  RegisterEnterpriseInvalidValuesModel,
  RegisterEnterpriseModel,
} from "../../../Models/EnterpriseModel";
import { getAddressByCep } from "../../../Services/CepService";
import history from "../../../Services/history";
import {
  getEnterpriseById,
  removeImgEnterprise,
  updateEnterpriseStepOne,
  updateEnterpriseStepTwo,
} from "../../../Services/EnterpriseService";
import { ErrorMessage } from "../../../Utils/ErrorMessage";
import EnterpriseEditPageContext from "./EnterpriseEditPageContext";

const EnterpriseEditPageProvider: React.FunctionComponent = (
  props: PropsWithChildren<ReactNode>
) => {
  const { children } = props;
  const { showAlert } = useContext(GlobalContex);
  const { id } = useParams<{ id: string }>();

  const [selectedImage, setSelectedImage] = useState<File | string>("");
  const [enterpriseName, setEnterpriseName] = useState<string>("");
  const [radioButtonValue, setRadioButtonValue] = useState<string>("cpf");
  const [repeatEmailValue, setRepeatEmailValue] = useState<string>("");
  const emptyRegisterEnterprise: RegisterEnterpriseModel = {
    cpf: "",
    cnpj: "",
    responsible: "",
    email: "",
    telephone: "",
    country: "Brasil",
    postal_code: "",
    state: "",
    neighborhood: "",
    city: "",
    address: "",
    number: "",
    address2: "",
  };
  const [registerEnterprise, setRegisterEnterprise] =
    useState<RegisterEnterpriseModel>(emptyRegisterEnterprise);
  const emptyregisterEnterpriseInvalidValues: RegisterEnterpriseInvalidValuesModel =
    {
      cpf: false,
      cnpj: false,
      email: false,
      repeat_email: false,
      postal_code: false,
    };
  const [registerEnterpriseInvalidValues, setRegisterEnterpriseInvalidValues] =
    useState<RegisterEnterpriseInvalidValuesModel>(
      emptyregisterEnterpriseInvalidValues
    );

  const loadData = async (id: string) => {
    try {
      const response = await getEnterpriseById(id);
      if (response.status === 200) {
        if (response.data.cnpj) {
          setRadioButtonValue("cnpj");
        }
        setEnterpriseName(response.data.homeName ? response.data.homeName : "");
        setSelectedImage(response.data.image ? response.data.image : "");
        const enterpriseToEdit: RegisterEnterpriseModel = {
          cpf: response.data.cpf ? response.data.cpf : "",
          cnpj: response.data.cnpj ? response.data.cnpj : "",
          responsible: response.data.name ? response.data.name : "",
          email: response.data.email ? response.data.email : "",
          telephone: response.data.phone ? response.data.phone : "",
          country: "Brasil",
          postal_code: response.data.postal_code
            ? response.data.postal_code
            : "",
          state: response.data.state ? response.data.state : "",
          neighborhood: response.data.neighborhood
            ? response.data.neighborhood
            : "",
          city: response.data.city ? response.data.city : "",
          address: response.data.address ? response.data.address : "",
          number: response.data.number ? response.data.number : "",
          address2: response.data.address2 ? response.data.address2 : "",
        };
        setRegisterEnterprise(enterpriseToEdit);
      }
    } catch (error) {
      showAlert(
        "Houve um problema ao carregar as informações do Empreendimento!",
        "error"
      );
    }
  };

  useEffect(() => {
    loadData(id);

    return () => {
      setSelectedImage("");
      setEnterpriseName("");
      setRadioButtonValue("cpf");
      setRepeatEmailValue("");
      setRegisterEnterprise(emptyRegisterEnterprise);
      setRegisterEnterpriseInvalidValues(emptyregisterEnterpriseInvalidValues);
    };
  }, []);

  const fileTypes: string[] = [
    "image/apng",
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/pjpeg",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
    "image/x-icon",
  ];

  function validFileType(file: string) {
    return fileTypes.includes(file);
  }

  const handleChangeImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      showAlert("Tipo de arquivo inválido", "warning");
      setSelectedImage("");
      return;
    }
    if (e.target.files[0]) {
      if (e.target.files[0].size > 1048576) {
        showAlert("A imagem não pode ser maior que 1MB", "warning");
        setSelectedImage("");
        return;
      }
      if (!validFileType(e.target.files[0].type)) {
        showAlert("Tipo de arquivo inválido", "warning");
        setSelectedImage("");
        return;
      }
    } else {
      return;
    }
    setSelectedImage(e.target.files[0]);
  };

  const removeImageEnterprise = async () => {
    if (typeof selectedImage === "string" && selectedImage !== "") {
      await removeImgEnterprise(id)
        .then((response) => {
          if (response.data) {
            setSelectedImage("");
            showAlert(
              "Imagem do empreendimento removida com sucesso!",
              "success"
            );
          }
        })
        .catch((error) => {
          const message = ErrorMessage(
            error,
            "Houve um problema ao remover imagem do empreendimento!"
          );
          showAlert(message, "error");
        });
    } else {
      setSelectedImage("");
    }
  };

  const getCepInfo = async (value: string) => {
    await getAddressByCep(value).then((response) => {
      if (response.data.erro) {
        setRegisterEnterpriseInvalidValues({
          ...registerEnterpriseInvalidValues,
          postal_code: true,
        });
        setRegisterEnterprise({
          ...registerEnterprise,
          postal_code: value,
          state: "",
          neighborhood: "",
          city: "",
          address: "",
        });
      } else {
        setRegisterEnterpriseInvalidValues({
          ...registerEnterpriseInvalidValues,
          postal_code: false,
        });
        setRegisterEnterprise({
          ...registerEnterprise,
          postal_code: value,
          state: response.data.uf,
          neighborhood: response.data.bairro,
          city: response.data.localidade,
          address: response.data.logradouro,
        });
      }
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    if (target.name === "repeat_email") {
      setRepeatEmailValue(event.target.value);
      return;
    }

    if (target.name === "postal_code") {
      setRegisterEnterprise({
        ...registerEnterprise,
        [target.name]: target.value,
      });

      if (!target.value.includes("_")) {
        getCepInfo(target.value);
      }

      return;
    }

    if (
      target.name === "radio_button_cpf" ||
      target.name === "radio_button_cnpj"
    ) {
      setRegisterEnterprise({ ...registerEnterprise, cpf: "", cnpj: "" });
      setRegisterEnterpriseInvalidValues({
        ...registerEnterpriseInvalidValues,
        cpf: false,
        cnpj: false,
      });
      setRadioButtonValue(target.value);
      return;
    }

    if (
      target.name === "cpf" ||
      target.name === "cnpj" ||
      target.name === "telephone" ||
      target.name === "number"
    ) {
      if (event.target.validity.valid) {
        setRegisterEnterprise({
          ...registerEnterprise,
          [target.name]: target.value,
        });
      }
      return;
    }
    setRegisterEnterprise({
      ...registerEnterprise,
      [target.name]: target.value,
    });
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const target = event.target;

    if (target.name === "email") {
      if (target.value === "") {
        setRegisterEnterpriseInvalidValues({
          ...registerEnterpriseInvalidValues,
          [target.name]: false,
        });
        return;
      }
      if (target.validity.valid) {
        setRegisterEnterpriseInvalidValues({
          ...registerEnterpriseInvalidValues,
          [target.name]: false,
        });
      } else {
        setRegisterEnterpriseInvalidValues({
          ...registerEnterpriseInvalidValues,
          [target.name]: true,
        });
      }
      return;
    }

    if (target.name === "repeat_email") {
      if (
        registerEnterprise.email.length > 0 &&
        repeatEmailValue.length > 0 &&
        registerEnterprise.email !== repeatEmailValue
      ) {
        setRegisterEnterpriseInvalidValues({
          ...registerEnterpriseInvalidValues,
          [target.name]: true,
        });
      } else {
        setRegisterEnterpriseInvalidValues({
          ...registerEnterpriseInvalidValues,
          [target.name]: false,
        });
      }
      return;
    }

    if (target.name === "postal_code") {
      if (target.value.length === 8) {
        setRegisterEnterpriseInvalidValues({
          ...registerEnterpriseInvalidValues,
          [target.name]: false,
        });
      } else {
        if (target.value === "") {
          setRegisterEnterpriseInvalidValues({
            ...registerEnterpriseInvalidValues,
            [target.name]: false,
          });
          return;
        }
        setRegisterEnterpriseInvalidValues({
          ...registerEnterpriseInvalidValues,
          [target.name]: true,
        });
      }
      return;
    }

    if (target.name === "cpf" || target.name === "cnpj") {
      const charSize = { cpf: 14, cnpj: 18 };
      if (target.value === "") {
        setRegisterEnterpriseInvalidValues({
          ...registerEnterpriseInvalidValues,
          [target.name]: false,
        });
        return;
      }

      if (target.value.includes("_")) {
        setRegisterEnterpriseInvalidValues({
          ...registerEnterpriseInvalidValues,
          [target.name]: true,
        });
        return;
      }

      if (target.value.length === charSize[target.name]) {
        setRegisterEnterpriseInvalidValues({
          ...registerEnterpriseInvalidValues,
          [target.name]: false,
        });
      } else {
        setRegisterEnterpriseInvalidValues({
          ...registerEnterpriseInvalidValues,
          [target.name]: true,
        });
      }
      return;
    }
  };

  const updateEnterprise = async () => {
    try {
      const formData = createObjToSendStepOne();
      const objToSend = createObjToSendStepTwo();
      const responseOne = await updateEnterpriseStepOne(id, formData);
      const responseTwo = await updateEnterpriseStepTwo(id, objToSend);
      if (responseOne.status === 200 && responseTwo.status === 200) {
        showAlert(
          "As informações do empreendimento foram atualizadas!",
          "success"
        );
        history.push("/select-home");
      }
    } catch (error) {
      showAlert(
        "Houve um problema ao atualizar as informações do empreendimento!",
        "error"
      );
    }
  };

  const createObjToSendStepOne = () => {
    const formData = new FormData();
    if (typeof selectedImage !== "string") {
      formData.append("image", selectedImage, selectedImage.name);
    }
    formData.append("name", enterpriseName);
    return formData;
  };

  const createObjToSendStepTwo = () => {
    const data = {} as RegisterEnterpriseModel;
    for (const key in registerEnterprise) {
      const input = registerEnterprise[key as keyof RegisterEnterpriseModel];
      if (input && input.trim() !== "") {
        data[key as keyof RegisterEnterpriseModel] = input;
      }
    }
    return data;
  };

  return (
    <EnterpriseEditPageContext.Provider
      value={{
        selectedImage,
        enterpriseName,
        radioButtonValue,
        registerEnterprise,
        registerEnterpriseInvalidValues,
        repeatEmailValue,
        handleChangeImage,
        removeImageEnterprise,
        setEnterpriseName,
        handleChange,
        handleFocus,
        getCepInfo,
        updateEnterprise,
      }}
    >
      {children}
    </EnterpriseEditPageContext.Provider>
  );
};

export default EnterpriseEditPageProvider;
