import React, { useContext, useState, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import DrawerProps from "../../Types/DrawerProps";
import GlobalContex from "../../GlobalContext/GlobalContext";
import { Divider, IconButton } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SettingsIcon from "@material-ui/icons/Settings";
import history from "../../Services/history";
import CustomCardNotifications from "../CustomCardNotifications";
import { getUserNotifications } from "../../Services/NotificationService";
import CustomTab, { CustomTabPanel } from "../CustomTab";
import { getHomeIDSelect } from "../../Services/UserService";
import { NotificationModel } from "../../Models/NotificationModel";

const useStyles = makeStyles((theme: Theme) => ({
  drawerNotificationContainer: {
    width: "35vw",
    backgroundColor: "#FFF !important",
    height: "100%",
    overflow: "hidden",
  },
  row: {
    display: "flex",
    padding: "25px",
    justifyContent: "start",
    alignItems: "center",
    maxHeight: "90px",
  },
  margin: {
    margin: theme.spacing(1),
  },
  labelTabNotification: {
    textTransform: "capitalize",
  },
  containerTabs: {
    maxHeight: "50px",
    display: "flex",
    justifyContent: "space-between",
  },
  confBtn: {
    marginRight: "30px",
    color: "#8B979F",
    cursor: "pointer",
  },
  tab: {
    "&.MuiTabPanel-root": {
      padding: "0px",
      height: "calc(100% - 140px)",
    },
  },
}));

const CustomDrawerNotification: React.FunctionComponent = () => {
  const classes = useStyles();
  const {
    openNotificationDrawer,
    toggleNotificationDrawer,
    setNotifications,
    setHasNextNotification,
    setNextRowKeyNotificiation,
    selectHomeTrigger,
  } = useContext(GlobalContex);

  const anchor: DrawerProps = "right";
  const [value, setValue] = useState<number>(0);

  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  const getOldNotifications = async () => {
    try {
      const response = await getUserNotifications();

      setHasNextNotification(response.data.has_next);
      if (response.data.has_next) {
        setNextRowKeyNotificiation(response.data.next_row_key);
      }

      const oldNotificationsArrayParsed: NotificationModel[] = [];
      const oldNotificationsArray = response.data.data;
      oldNotificationsArray.forEach((element) => {
        oldNotificationsArrayParsed.push(JSON.parse(element.message));
      });

      setNotifications(oldNotificationsArrayParsed);
    } catch (error) {
      setNotifications([]);
    }
  };

  useEffect(() => {
    if (getHomeIDSelect()) {
      getOldNotifications();
    }
  }, [selectHomeTrigger]);

  return (
    <React.Fragment key={anchor}>
      <Drawer
        anchor={anchor}
        open={openNotificationDrawer}
        onClose={() => {
          toggleNotificationDrawer(false);
        }}
      >
        <div className={classes.drawerNotificationContainer}>
          <div className={classes.row}>
            <IconButton
              aria-label="delete"
              className={classes.margin}
              size="small"
              onClick={() => {
                toggleNotificationDrawer(false);
              }}
            >
              <ArrowBackIosIcon fontSize="inherit" />
            </IconButton>
            Voltar
          </div>
          <Divider />
          <CustomTab
            labels={["Alarme"]}
            ariaLabel={"menu em tabs para a página de notificações"}
            id="tab-notifications"
            value={value}
            key={"tab-notifications"}
            changeTab={(value: number) => handleChange(value)}
          >
            <IconButton
              onClick={() => {
                toggleNotificationDrawer(false);
                history.push("notification-config");
              }}
            >
              <SettingsIcon htmlColor={"#8B979F"} />
            </IconButton>
            <span
              style={{
                fontSize: "14px",
                fontWeight: 700,
                color: "#8B979F",
                paddingRight: "20px",
              }}
            >
              Ajustes
            </span>
          </CustomTab>
          <CustomTabPanel
            value={value}
            index={0}
            height="calc(100% - 145px)"
            padding="0px"
          >
            <CustomCardNotifications />
          </CustomTabPanel>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default CustomDrawerNotification;
