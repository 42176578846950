import MiniDrawer from "../../Components/CustomMiniDrawer";
import PrincipalContainer from "./Components/PrincipalContainer";
import ChangeToNewPlanSuccessProvider from "./Context/ChangeToNewPlanSuccessProvider";

const ChangeToNewPlanSuccess: React.FunctionComponent = () => {
  return (
    <MiniDrawer showSideMenu={false} withoutSideMenu={true} bgColor={"#F7F7F7"}>
      <ChangeToNewPlanSuccessProvider>
        <PrincipalContainer />
      </ChangeToNewPlanSuccessProvider>
    </MiniDrawer>
  );
};

export default ChangeToNewPlanSuccess;
