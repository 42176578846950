import styled from "styled-components";

export const DaysOfWeekContainer = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
`;

export const DayContainer = styled.div`
  background-color: #ebeeee;
  color: #8b979f;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-size: 18px;
  font-weight: 600;

  width: 40px;
  height: 40px;

  cursor: pointer;

  &:focus,
  &:hover {
    outline: none;
    background: #ebeeeeaa;
  }

  &[aria-checked="true"] {
    background-color: #00a335;
    color: #ffffff;

    &:focus,
    &:hover {
      background-color: #00a335cc;
    }
  }

  & + & {
    margin-left: 12px;
  }
`;
