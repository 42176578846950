import {
  ListItemText,
  Paper,
  List,
  ListItem,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { useContext } from "react";
import GlobalContex from "../../../../../../../../GlobalContext/GlobalContext";
import PlaceModel from "../../../../../../../../Models/PlaceModel";
import NotificationConfigContext from "../../../../../../Context/NotificationConfigContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "auto",
    },
    paper: {
      width: "200px",
      height: "100%",
      overflow: "auto",
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
  })
);

const Places: React.FunctionComponent = () => {
  const classes = useStyles();
  const { places } = useContext(GlobalContex);
  const { setPlaceSelectedIndex, placeSelectedIndex } = useContext(
    NotificationConfigContext
  );

  const customList = () => (
    <Paper className={classes.paper}>
      <List component="div" role="list">
        {places.map((value: PlaceModel, index: number) => {
          return (
            <ListItem
              style={{
                backgroundColor:
                  index === placeSelectedIndex ? "#F2F2F2" : "white",
              }}
              key={index}
              role="listitem"
              button
              onClick={() => setPlaceSelectedIndex(index)}
            >
              <ListItemText primary={`${value.name}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return customList();
};

export default Places;
