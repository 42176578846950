import {
  Button,
  Container,
  createStyles,
  Divider,
  IconButton,
  Link,
  makeStyles,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "../../../EnterpriseCreatePage/components/Step3/components/Box";
import EnterpriseDataView from "../../../EnterpriseCreatePage/components/Step3/components/EnterpriseDataView";
import { useContext, useState } from "react";
import ChangeToNewPlanContext from "../../Context/ChangeToNewPlanContext";
import ItemIcon from "../../../EnterpriseCreatePage/components/ItemIcon";
import { toREAL } from "../../../../Utils/CurrencyUtils";
import { planPriceInterval } from "../../../../Models/Plans";
import Checkbox from "@material-ui/core/Checkbox";
import StripeTerms from "../../../../Components/StripeTermsDialog";
import { postPlanForPayment } from "../../../../Services/PlansService";
import globalContext from "../../../../GlobalContext/GlobalContext";
import history from "../../../../Services/history";
import { ChevronLeft } from "@material-ui/icons";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      paddingTop: 10,
      display: "flex",
    },
    btnBack: {
      marginLeft: "-30px",
    },
    resume: {
      display: "flex",

      "& .resume__item": {
        fontSize: 15,
        display: "flex",
        alignItems: "center",
      },

      "& .ItemIcon--root": {
        marginRight: 12,

        "& .MuiSvgIcon-root": {
          width: "2em",
          height: "2em",
        },
      },

      "& .resume__payment": {
        flexGrow: 2,

        "& .payment_method > p, & .payment_total > p": {
          display: "flex",
          justifyContent: "space-between",
        },
      },

      "& .resume__hint": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "33%",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "20px",
        padding: "12px",
      },

      "& .resume__divider": {
        width: 1,
        display: "flex",
        borderLeft: "1px solid #D1DAE0",
        margin: "0 30px",
      },
    },
    title: {
      fontWeight: 700,
      fontSize: "21px",
      lineHeight: "29px",
      marginBottom: "10px",
    },
    description: {
      fontSize: "15px",
      lineHeight: "20px",
      marginBottom: "30px",
    },
    cost: {
      color: "#3E5055",
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "20px",
    },
    total: {
      color: "#D72735",
      fontWeight: 700,
      fontSize: "21px",
      lineHeight: "29px",
    },
    horizontalDivider: {
      border: 0,
      borderTop: "1px solid",
      width: "calc(100% + 48px)",
      margin: "16px -24px",
      color: "#EBEEEE",
    },
    button: {
      textTransform: "none",
      fontSize: "15px",
      fontWeight: "bold",
      padding: "12px",
      marginTop: "10px",
    },
  })
);

const mesMap: { [value in planPriceInterval]: string } = {
  day: "Diário",
  month: "Mensal",
  year: "Anual",
};

const ChangePlanContainer: React.FunctionComponent = () => {
  const classes = useStyles();

  const { plan, price, enterprise, newPlanCost } = useContext(
    ChangeToNewPlanContext
  );

  const { showAlert } = useContext(globalContext);

  const [checkeRulesAndTerms, setCheckeRulesAndTerms] =
    useState<boolean>(false);
  const [openModalRulesAndTerms, setOpenModalRulesAndTerms] =
    useState<boolean>(false);

  const confirmClick = () => {
    postPlanForPayment(enterprise.id, price.id)
      .then((res) => {
        if (res.data) {
          history.push({
            pathname: "/change-to-new-plan-success",
            state: {
              price,
              plan,
              enterprise,
            },
          });
        } else {
          showAlert("Não foi possível alterar o Plano", "error");
        }
      })
      .catch(() =>
        showAlert("Falha ao se comunicar com API de pagamento", "error")
      );
  };

  return (
    <Container style={{ marginTop: 30 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={classes.root}>
            <div className={classes.btnBack}>
              <IconButton
                onClick={() => history.push("/my-enterprises")}
                size={"small"}
              >
                <ChevronLeft />
              </IconButton>
            </div>
            <div>
              <div className={classes.title}>
                Insira seus dados de pagamento
              </div>
              <div className={classes.description}>
                Preencha os campos abaixo com suas informações pessoais para
                efetuar o pagamento. Nós garantimos sigilo de todos seus dados.
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={8}>
          <Box stepNumber={1} title="Informações fiscais">
            <div style={{ marginLeft: 42 }}>
              <EnterpriseDataView data={enterprise} />
            </div>
          </Box>
          <Box stepNumber={2} title="Resumo da compra">
            <div className={classes.resume}>
              <ItemIcon color={plan.color_plan} />
              <div className={"resume__payment"}>
                <p>
                  <strong>
                    {plan.name} {mesMap[price.interval]}
                  </strong>
                </p>
                <div className={"payment_method"}>
                  {/*
                  <p>
                    Cartão de crédito parcelas <strong>5 x 630 sem juros</strong>
                  </p>
                  */}
                </div>
                <div className={"payment_total"}>
                  <p>
                    Valor da compra{" "}
                    <span className={classes.total}>{toREAL(price.price)}</span>
                  </p>
                </div>
              </div>
              <div className={"resume__divider"}></div>
              <div className={"resume__hint"}>
                Ao finalizar o pedido, você receberá uma mensagem para o e-mail
                informado no campo de “Informações Pessoais”.
              </div>
            </div>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box hideHeader>
            <div className={classes.resume} style={{ flexDirection: "column" }}>
              <div className={"resume__item"}>
                <ItemIcon color={plan.color_plan} />
                <strong>
                  {plan.name} {mesMap[price.interval]}
                </strong>
              </div>
              <hr className={classes.horizontalDivider} />
              <div className={"resume__payment"}>
                <div className={"payment_method"}>
                  {/*
                <p>
                  Cartão de crédito parcelas <strong>5 x 630 sem juros</strong>
                </p>
                */}
                </div>
                <div className={"payment_total"}>
                  <p>
                    Custo até agora:{" "}
                    <span className={classes.cost}>
                      {toREAL(newPlanCost?.costUntilNow.amount, {
                        fromCents: true,
                      })}
                    </span>
                  </p>
                </div>
                <div className={"payment_total"}>
                  <p>
                    Custo do novo plano:{" "}
                    <span className={classes.cost}>
                      {toREAL(newPlanCost?.newCost.amount, {
                        fromCents: true,
                      })}
                    </span>
                  </p>
                </div>
                <Divider />
                <div className={"payment_total"}>
                  <p>
                    Valor da compra{" "}
                    <span className={classes.total}>
                      {toREAL(newPlanCost?.total, {
                        fromCents: true,
                      })}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </Box>
          <div>
            <p className={"m-0"}>
              <strong>RENOVAÇÃO AUTOMÁTICA:</strong>
            </p>
            <p>
              Sua assinatura será renovada automaticamente de forma{" "}
              {mesMap[price.interval].toLowerCase()} com um pagamento de{" "}
              <strong>{toREAL(price?.price)}</strong>. Você pode cancelar sua
              assinatura a qualquer momento na seção <strong>Meu Perfil</strong>
              .
            </p>
            <Checkbox
              checked={checkeRulesAndTerms}
              onChange={(event) => setCheckeRulesAndTerms(event.target.checked)}
              inputProps={{ "aria-label": "primary checkbox" }}
              id={"checkbox-term"}
            />
            <span>
              Eu li e aceito os{" "}
              <strong>
                <Link
                  href="#"
                  color="secondary"
                  onClick={(e) => {
                    setOpenModalRulesAndTerms(true);
                    e.preventDefault();
                  }}
                >
                  termos de uso
                </Link>{" "}
              </strong>
              do stripe
            </span>
          </div>

          <Button
            id="btn-finish"
            color="secondary"
            disabled={!checkeRulesAndTerms}
            variant="contained"
            fullWidth={true}
            className={classes.button}
            onClick={confirmClick}
          >
            Efetuar compra
          </Button>
        </Grid>
        <StripeTerms
          open={openModalRulesAndTerms}
          onClose={() => setOpenModalRulesAndTerms(false)}
        />
      </Grid>
    </Container>
  );
};

export default ChangePlanContainer;
