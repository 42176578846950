import EventEmitter from "eventemitter3";

const eventEmitter = new EventEmitter();
const Emitter = {
  on: (event: any, fn: any): EventEmitter<string | symbol, any> =>
    eventEmitter.on(event, fn),
  once: (event: any, fn: any): EventEmitter<string | symbol, any> =>
    eventEmitter.once(event, fn),
  off: (event: any, fn: any): EventEmitter<string | symbol, any> =>
    eventEmitter.off(event, fn),
  emit: (event: any, payload: any): boolean =>
    eventEmitter.emit(event, payload),
};
Object.freeze(Emitter);
export default Emitter;
