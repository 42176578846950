import { useContext, useState } from "react";
import { addMinutes, format } from "date-fns";
import { AutorenewOutlined } from "@material-ui/icons";

import { ErrorMessage } from "../../../../../../Utils/ErrorMessage";
import {
  generateStringOfHex,
  generateStringOfNumbers,
} from "../../../../../../Utils/Functions";
import { saveDoorLockPasswordTemp } from "../../../../../../Services/DeviceDoorLockService";
import GlobalContex from "../../../../../../GlobalContext/GlobalContext";

import { PasswordSlotsViewer } from "../../../../../../Components/PasswordSlotsViewer";
import ButtonOutlined from "../../../../../CustomButton/ButtonOutlined";
import AccordionPasswordDefinition from "../AccordionPasswordDefinition";
import { DataPasswordType } from "../../../../../../Models/DoorLockPasswordModel";

type PasswordTempBoxProps = {
  deviceId: string;
  passwordLenght?: number;
  expanded: boolean;
  onChange: () => void;
};

const expireInMinutes = 20;

const PasswordTempBox: React.FunctionComponent<PasswordTempBoxProps> = ({
  deviceId,
  passwordLenght = 6,
  ...props
}: PasswordTempBoxProps) => {
  const [dataPassword, setDataPassword] = useState<DataPasswordType>();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const { showAlert } = useContext(GlobalContex);

  const generatePassword = () => {
    setDataPassword({
      name: "Senha de uso temporário - " + generateStringOfHex(6),
      password: generateStringOfNumbers(6),
      expireAt: addMinutes(new Date(), expireInMinutes).getTime(),
    });
    setVisible(true);
  };

  const copyPasswordToClipboard = async () => {
    if (!dataPassword) return;
    await navigator.clipboard.writeText(dataPassword.password);
  };

  const savePassword = () => {
    if (!dataPassword) throw Error("Senha não foi gerada");
    setLoading(true);
    setVisible(true);
    return saveDoorLockPasswordTemp(deviceId, dataPassword)
      .then(() => {
        showAlert("Senha salva no dispositivo", "success");
        setVisible(false);
        setDataPassword(undefined);
      })
      .catch((e) => {
        const msg = ErrorMessage(e, "Não foi possível gerar a senha.");
        showAlert(msg, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const initialContent = () => (
    <>
      <PasswordSlotsViewer
        password={dataPassword?.password || ""}
        length={passwordLenght}
        visible={visible}
      />
      <p>A senha é válida por {expireInMinutes} minutos</p>
      <ButtonOutlined
        color={"secondary"}
        startIcon={<AutorenewOutlined />}
        onClick={generatePassword}
        loading={loading}
        loadingCaption={"Gerando senha..."}
      >
        Gerar senha
      </ButtonOutlined>
    </>
  );

  const generatedPasswordContent = () => (
    <>
      <p className={"m-0"} style={{ color: "#3E5055" }}>
        <strong>Nome da senha:</strong> {dataPassword?.name}
      </p>
      <PasswordSlotsViewer
        password={dataPassword?.password || ""}
        length={passwordLenght}
        visible={visible}
      />
      <p className={"mb-0"}>
        A senha expira em{" "}
        {format(new Date(dataPassword!.expireAt!), "dd/MM/yyyy 'às' HH:mm")}
      </p>
      <p className={"mt-0"}>
        Você não poderá mais visualizar esta senha após salvar.
      </p>
      <ButtonOutlined
        color={"secondary"}
        startIcon={<img src={"/assets/icons/icon-copy.svg"} />}
        onClick={() => {
          savePassword();
          copyPasswordToClipboard();
        }}
        loading={loading}
        loadingCaption={"Salvando senha..."}
      >
        Copiar e salvar
      </ButtonOutlined>
    </>
  );

  return (
    <AccordionPasswordDefinition
      title={"Senhas de uso temporário"}
      iconElement={
        <img
          src={"/assets/icons/icon-password-temp.svg"}
          alt={"Ícone de senha temporária"}
        />
      }
      {...props}
    >
      {dataPassword && generatedPasswordContent()}
      {!dataPassword && initialContent()}
    </AccordionPasswordDefinition>
  );
};

export default PasswordTempBox;
