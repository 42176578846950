import { makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: "100%",
      textAlign: "center",
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      flexDirection: "column",
    },
    containerLabel: {
      maxWidth: "80%",
    },
    title: {
      fontWeight: 700,
      fontSize: "23px",
      lineHeight: "31px",
      color: "#3E5055",
      marginBottom: "10px",
    },
    subTitle: {
      fontWeight: 700,
      fontSize: "15px",
      lineHeight: "20px",
      color: "#8B979F",
    },
  })
);

const CustomOfflineDeviceComponent: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <img src={"/assets/imgs/device-offline.png"} alt="device offline" />
      <div className={classes.containerLabel}>
        <div className={classes.title}>Dispositivo desconectado</div>
        <div className={classes.subTitle}>
          Por favor, cheque a conexão com o dispositivo, ou verifique se ele
          está funcionando corretamente.
        </div>
      </div>
    </div>
  );
};

export default CustomOfflineDeviceComponent;
