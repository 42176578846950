import styled from "styled-components";
import MuiTableContainer from "@material-ui/core/TableContainer";

type TableContainerProps = {
  $maxHeight?: string;
  $padding?: string;
};

export const TableContainer = styled(MuiTableContainer)<TableContainerProps>`
  padding:  ${({ $padding }) => $padding || "1rem 2.5rem 0rem 2.5rem;"} 
  width: 100%;
  max-height: ${({ $maxHeight }) => $maxHeight || "unset"};
`;
