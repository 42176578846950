import {
  createStyles,
  makeStyles,
  Theme,
  CardMedia,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { useState } from "react";
import { HomeUserModel } from "../../../../../../Models/HomeUserModel";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EnterpriseBlockAlert from "./EnterpriseBlockAlert";
import history from "../../../../../../Services/history";

interface Props {
  homeUser: HomeUserModel;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: "100%",
      height: "250px",
      opacity: "0.5",
    },
    contentCard: {
      padding: "10px",
      minHeight: "150px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      "&:last-child": {
        paddingBottom: "10px",
      },
    },
    enterpriseName: {
      color: "#8B979F",
      fontWeight: 400,
      fontSize: "21px",
      lineHeight: "29px",
      display: "flex",
      alignItems: "flex-start",
    },
    menuIconEnterprise: {
      padding: "0px",
      marginTop: "5px",
    },
    createdAt: {
      padding: theme.spacing(1),
      fontSize: "13px",
      color: "#8B979F",
      fontWeight: 400,
      textAlign: "end",
      lineHeight: "20px",
    },
    labelMenuItem: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      color: "#8B979F",
    },
  })
);

const EnterpriseBlockedCard: React.FunctionComponent<Props> = ({
  homeUser,
}: Props) => {
  const classes = useStyles();

  const HomeWithoutImgage = "assets/imgs/enterpriseDefaultImage.png";

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ position: "relative" }}>
      <CardMedia
        component="img"
        className={classes.img}
        image={homeUser.image ? homeUser.image : HomeWithoutImgage}
      />
      {homeUser.status === "EXPIRING" && <EnterpriseBlockAlert />}
      <CardContent className={classes.contentCard}>
        <div className={classes.enterpriseName}>
          {homeUser.role === "Administrador" && (
            <IconButton
              id={"btn-menu " + homeUser.name}
              className={classes.menuIconEnterprise}
              onClick={(event) => {
                event.stopPropagation();
                openMenu(event);
              }}
            >
              <MoreVertIcon fontSize={"small"} htmlColor={"#8B979F"} />
            </IconButton>
          )}
          {homeUser.name}
        </div>
        <div className={classes.createdAt}>
          Criado em{" "}
          {new Date(homeUser.create_at).toLocaleDateString("pt-BR", {
            timeZone: "UTC",
          })}
        </div>
      </CardContent>
      {homeUser.role === "Administrador" && (
        <Menu
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={closeMenu}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            id={"menu-option-edit"}
            onClick={(event) => {
              event.stopPropagation();
              history.push("/enterprise/edit/" + homeUser.home_id);
            }}
          >
            <div className={classes.labelMenuItem}>Editar empreendimento</div>
            <IconButton>
              <img width="15" alt="" src="assets/icons/edit (1) 2.svg" />
            </IconButton>
          </MenuItem>
        </Menu>
      )}
    </div>
  );
};

export default EnterpriseBlockedCard;
