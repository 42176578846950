import Drawer from "@material-ui/core/Drawer";
import React, { FormEvent, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import FormAccount from "../FormAccount";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import { UserAccountModel } from "../../../../Models/UserAccountModel";
import CryptoJS from "crypto-js/core";
import { Button } from "@material-ui/core";
import { verifyEmail } from "../../../../Services/UserService";
import { ChevronLeft } from "@material-ui/icons";
import history from "../../../../Services/history";
import { ErrorMessage } from "../../../../Utils/ErrorMessage";

const useStyles = () => ({
  root: {
    color: "#3E5055",
    "& .MuiDrawer-paper": {
      color: "#3E5055",
      backgroundColor: "#FFF !important",
      width: "700px",
      display: "flex",
      justifyContent: "space-between",
    },
  },
});

const titleStyle: CSSProperties = {
  fontSize: 25,
  fontWeight: 700,
  margin: "2rem 0 1.2rem 0",
};

const textStyle: CSSProperties = {
  color: "#8B979F",
  fontSize: 15,
};

const textDarkStyle: CSSProperties = {
  color: "#3E5055",
};

const textSmallStyle: CSSProperties = {
  fontSize: 13,
};

const navStyle: CSSProperties = {
  padding: "1rem 1rem",
  borderBottom: "1px solid #D1DAE0",
  display: "flex",
  alignItems: "center",
};

const contentStyle: CSSProperties = {
  padding: "2rem 0 1rem 0",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const footerStyle: CSSProperties = {
  padding: "1rem 0 1rem 0",
  textAlign: "center",
};

const backButtonStyle: CSSProperties = {
  color: "#8B979F",
  textTransform: "none",
};

interface DrawerCreateAccountProps {
  open: boolean;
  classes: any;
  onClose?: () => void;
}

function DrawerCreateAccount(props: DrawerCreateAccountProps) {
  const { showAlert } = useContext(GlobalContex);

  const handleSubmitForm = async (
    event: FormEvent,
    data: { name: string; email: string; password: string }
  ) => {
    event.preventDefault();

    const userAccount: UserAccountModel = {
      country_code: 55,
      nick_name: data.name,
      username: data.email,
      password: CryptoJS.MD5(data.password).toString(),
    };

    await verifyEmail(data.email)
      .then((response) => {
        if (response.data) {
          history.push("/confirm-code", {
            userAccount: userAccount,
            redirectTo: "/login",
          });
        }
      })
      .catch((error) => {
        const message = ErrorMessage(
          error,
          "Houve um problema no processo criação de um novo usuário! Contate o administrador do sistema."
        );
        showAlert(message, "error");
      });

    return false;
  };

  const handleBackButton = (
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => {
    e.preventDefault();
    if (props.onClose) props.onClose();
  };

  return (
    <Drawer
      open={props.open}
      transitionDuration={{
        enter: 700,
        exit: 700,
      }}
      classes={props.classes}
      anchor="right"
      onClose={props.onClose}
    >
      <nav style={navStyle}>
        <Button
          id="btn-back-login"
          onClick={handleBackButton}
          startIcon={<ChevronLeft />}
          style={backButtonStyle}
        >
          Voltar
        </Button>
      </nav>

      <div style={contentStyle}>
        <img src="assets/imgs/intelbras-logo.png" alt="Intelbras" height={32} />
        <Typography style={titleStyle}>Criar conta</Typography>
        <Typography style={{ ...textStyle, marginBottom: "16px" }}>
          Preencha as informações de registro
        </Typography>

        <FormAccount onSubmit={handleSubmitForm} />
      </div>

      <div style={contentStyle}>
        <Typography style={{ ...textStyle, ...textDarkStyle }}>
          Já tem uma conta?{" "}
          <a href="#" id="link-login" onClick={handleBackButton}>
            Faça login
          </a>
        </Typography>
      </div>

      <div style={footerStyle}>
        <Typography style={{ ...textStyle, ...textSmallStyle }}>
          Termos de uso e políticas de privacidade
        </Typography>
      </div>
    </Drawer>
  );
}

export default withStyles(useStyles)(DrawerCreateAccount);
