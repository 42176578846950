import { useState, useContext, useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import DeviceModel, {
  DeviceFunctionsModel,
} from "../../../../Models/DeviceModel";
import EnvironmentsContext from "../../context/EnvironmentsContext";
import CustomSwitchDevice from "../../../../Components/CustomSwitchDevice";
import { getFetchSpecifications } from "../../../../Services/DevicesService";
import { Checkbox } from "@material-ui/core";
import ListEnvironmentsFromDevices from "../../../../Components/ListEnvironmentsFromDevices";
import DeviceDrawerComposite from "../../../../Components/CustomDevices/DeviceDrawerComposite";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import DevicesToRelocate from "../../../../Models/DevicesToRelocate";
import { ErrorMessage } from "../../../../Utils/ErrorMessage";
import { AxiosError } from "axios";

interface Props {
  device: DeviceModel;
  selectedProps?: boolean;
  isNotAssocied?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    paper_item: {
      display: "flex",
      padding: "19px",
      height: "190px",
      borderRadius: "20px",
      alignContent: "space-between",
      flexDirection: "column",
    },
    paper_content: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    paper_content_name: {
      display: "flex",
      justifyContent: "flex-start",
      flexDirection: "row",
      height: "100%",
    },
    paper_content_item: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "flex-end",
    },
  })
);

const SubDevice: React.FunctionComponent<Props> = ({
  device,
  selectedProps,
  isNotAssocied,
}: Props) => {
  const {
    hideOptionsAddDevice,
    setDevicesToAdd,
    setDevicesToRemove,
    devicesToAdd,
    devicesToRemove,
    getEnvironmentsList,
    envNameToolbar,
    selectDevices,
    hideOptions,
    statusRequest,
    devicesNotAssociated,
    devicesAssociated,
    setDevicesNotAssociated,
    setDevicesAssociated,
  } = useContext(EnvironmentsContext);

  const {
    toggleOpenDrawerApplication,
    devicesToRelocate,
    setDevicesToRelocate,
  } = useContext(GlobalContex);

  const classes = useStyles();

  const [checked, setChecked] = useState<boolean>(false);

  const [specificationsDevice, setSpecificationsDevice] =
    useState<DeviceFunctionsModel>({ category: "", functions: [], status: [] });

  useEffect(() => {
    return () => {
      setSpecificationsDevice({ category: "", functions: [], status: [] });
    };
  }, []);

  useEffect(() => {
    setChecked(false);
  }, [hideOptions, statusRequest]);

  useEffect(() => {
    if (device.id) {
      const fetchSpecifications = async () => {
        try {
          const { data } = await getFetchSpecifications(device.id);
          setSpecificationsDevice(data);
        } catch (error) {
          const e = error as AxiosError;
          if (e.isAxiosError && e.request.status !== 400) {
            console.error(
              ErrorMessage(e, "Falha ao carregar comandos do dispositivo")
            );
          }
        }
      };
      fetchSpecifications();
    }
  }, [device.id]);

  const handleChange = (check: boolean) => {
    setChecked(check);
    if (check) {
      setDevicesToRelocate([...devicesToRelocate, device]);
    } else {
      setDevicesToRelocate([
        ...devicesToRelocate.filter(
          (deviceFilter: DevicesToRelocate) => deviceFilter.id !== device.id
        ),
      ]);
    }
  };

  const checkClickDevice = () => {
    if (selectDevices) {
      const check = checked;
      handleChange(!check);
    }

    if (hideOptionsAddDevice) {
      if (selectedProps) {
        setDevicesToRemove([...devicesToRemove, device]);
        setDevicesAssociated(
          devicesAssociated.filter((d) => d.id !== device.id)
        );
        setDevicesNotAssociated([
          ...devicesNotAssociated.filter((d) => d.id !== device.id),
          device,
        ]);
      } else {
        setDevicesToAdd([
          ...devicesToAdd.filter((d) => d.id !== device.id),
          device,
        ]);
        setDevicesAssociated([...devicesAssociated, device]);
        setDevicesNotAssociated(
          devicesNotAssociated.filter((d) => d.id !== device.id)
        );
      }
    }
  };

  const cardDeviceClick = (event: any) => {
    if (
      event.target.id === device.id ||
      selectDevices ||
      hideOptionsAddDevice
    ) {
      return;
    }
    toggleOpenDrawerApplication(
      <DeviceDrawerComposite status={device.status} device={device} />
    );
  };

  return (
    <Paper
      style={{
        backgroundColor: device.online ? "#FFF" : "#f5f6f6",
        filter: device.online ? "" : "grayscale(80%)",
        opacity: device.online ? "" : "0.5",
        cursor: "pointer",
      }}
      className={classes.paper_item}
      onClick={(event) => {
        checkClickDevice();
        cardDeviceClick(event);
      }}
    >
      <div className={classes.paper_content}>
        <img
          style={{
            opacity: device.online ? "1" : "0.7",
            filter: device.online ? "" : "grayscale(80%)",
          }}
          src={device.icon}
          height="58"
        />
        <div>
          {selectDevices && (
            <Checkbox key={`checkbox-${device.id}`} checked={checked} />
          )}

          {!device.online && !selectDevices && <p>off</p>}
          {device.online && !hideOptionsAddDevice && !selectDevices && (
            <CustomSwitchDevice
              id={device.id}
              functionsDevice={specificationsDevice}
              status={device.status}
            />
          )}
        </div>
        {hideOptionsAddDevice && (
          <img
            src={
              selectedProps ? "assets/icons/minus.svg" : "assets/icons/more.svg"
            }
            height="25"
          />
        )}
      </div>

      <div className={classes.paper_content_name}>
        <div className={classes.paper_content_item}>
          <span style={{ color: "#3E5055" }}>
            <b>{device.name}</b>
          </span>
          <span style={{ color: "#8B979F" }}>
            {isNotAssocied ? (
              envNameToolbar
            ) : (
              <ListEnvironmentsFromDevices
                environments={getEnvironmentsList(device.id)}
              />
            )}
          </span>
        </div>
      </div>
    </Paper>
  );
};

export default SubDevice;
