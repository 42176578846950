import MiniDrawer from "../../Components/CustomMiniDrawer";
import { getHomeNameSelect } from "../../Services/UserService";
import { Divider } from "@material-ui/core";
import SubHeader from "../../Components/SubHeader";
import history from "../../Services/history";
import FormOperatorsGroup from "../OperatorsGroupCreatePage/Components/FormOperatorsGroup";
import OperatorsGroupCreatePageContextProvider from "../OperatorsGroupCreatePage/Context/OperatorsGroupCreatePageContextProvider";
import { useParams } from "react-router-dom";
import { editUserGroups, getUserGroup } from "../../Services/UserGroupsService";
import GlobalContex from "../../GlobalContext/GlobalContext";
import { useContext, useEffect, useState } from "react";
import { FormOperatorsGroupType } from "../../Models/UserGroupsModel";

const OperatorsGroupEditPage: React.FunctionComponent = () => {
  const { showAlert, toggleWaitIndicator } = useContext(GlobalContex);

  const navigateToOperators = () => {
    history.push("/operators");
  };

  const { id } = useParams<{ id: string }>();

  const [formData, setFormData] = useState<FormOperatorsGroupType>();

  useEffect(() => {
    loadData();

    return () => {
      setFormData(undefined);
    };
  }, []);

  const loadData = () => {
    toggleWaitIndicator("OperatorsGroupEditPage:openEdit", true);
    getUserGroup(id)
      .then((response) => {
        setFormData(response.data);
      })
      .catch(() => {
        console.error(`Can't load Operators Group id ${id}`);
        showAlert(
          "Não foi possível carregar os dados do Grupo de Operadores",
          "error"
        );
        navigateToOperators();
      })
      .finally(() => {
        toggleWaitIndicator("OperatorsGroupEditPage:openEdit", false);
      });
  };

  const onSubmit = async (form: FormOperatorsGroupType): Promise<boolean> => {
    try {
      const response = await editUserGroups(form, id!);

      if (response.status === 200) {
        showAlert(
          "Grupo de operadores " + form.name + " editado com sucesso!",
          "success"
        );
        return true;
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.errors[0]?.message.replace(
          "UserGroup",
          "grupo de usuário"
        ) || "Erro ao tentar editar grupo de operadores!";
      showAlert(errorMessage, "error");
    }
    return false;
  };

  const onSuccess = () => {
    navigateToOperators();
  };

  return (
    <OperatorsGroupCreatePageContextProvider>
      <MiniDrawer
        breadcrumb={[
          { name: getHomeNameSelect(), to: "manage-place" },
          { name: "Grupo de operadores", to: "/operators" },
          { name: `Edição grupo de operadores`, to: "" },
        ]}
      >
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <SubHeader
            text={`Edição grupo de operadores`}
            subText={""}
            backPress={navigateToOperators}
          />
          <Divider style={{ margin: "20px 0" }} />
          {formData && (
            <FormOperatorsGroup
              initialValue={formData}
              onSubmit={(data) => onSubmit(data)}
              onSuccess={onSuccess}
              onCancel={() => navigateToOperators()}
            />
          )}
          {!formData && <p>Carregando...</p>}
        </div>
      </MiniDrawer>
    </OperatorsGroupCreatePageContextProvider>
  );
};

export default OperatorsGroupEditPage;
