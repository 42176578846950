import { makeStyles, Divider } from "@material-ui/core";
import Filters from "../../Components/Filters";
import RoomGrid from "../../Components/RoomGrid";
import CustomConfirmDialog from "../../../../Components/CustomConfirmDialog";
import { useContext, useEffect, useState } from "react";
import ManageRoomContext from "../../Context/ManageRoomContext";
import RoomModal from "../../Components/RoomModal";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import RoomModel from "../../../../Models/RoomModel";
import { AxiosError } from "axios";
import { getAllPlace } from "../../../../Services/PlaceService";
import { PlaceOptionsModel } from "../../../../Models/PlaceModel";

const Rooms: React.FunctionComponent = () => {
  const classes = useStyles();
  const {
    handleCloseModalDelete,
    openDialog,
    roomDelete,
    roomName,
    roomId,
    setOpenDialog,
    setRoomName,
  } = useContext(ManageRoomContext);

  const {
    placeSelected,
    setRooms,
    setOriginalRoomList,
    originalRoomList,
    expandSubHeader,
    setPlaces,
    showAlert,
  } = useContext(GlobalContex);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setOpenDialog(false);
      setRoomName("");
    };
  }, []);

  const roomDeleteConfirm = async () => {
    setIsLoading(true);
    let deleteRoomConfirm;
    try {
      deleteRoomConfirm = await roomDelete();
    } catch (e: any) {
      const err = e as AxiosError;

      showAlert(err.message, "error");
      setOpenDialog(false);
      setRoomName("");
      setIsLoading(false);
      return;
    }

    if (deleteRoomConfirm) {
      const getLocalPlaceID = localStorage.getItem("placeSelectedID");
      const getLocalPlaceName = localStorage.getItem("placeSelectName");

      if (
        placeSelected.place_id === "0" &&
        getLocalPlaceID &&
        getLocalPlaceName
      ) {
        const options: PlaceOptionsModel = {
          sortBy: "name",
          sortDir: "ASC",
        };
        const responsePlace = await getAllPlace(0, -1, options);
        if (responsePlace.status === 200) {
          setPlaces(responsePlace.data.content);
          const roomsAfterRemove = originalRoomList.filter(
            (room: RoomModel) => room.room_id !== roomId
          );
          setRooms(roomsAfterRemove);
          setOriginalRoomList(roomsAfterRemove);
        }
      } else {
        const options: PlaceOptionsModel = {
          sortBy: "name",
          sortDir: "ASC",
        };
        const responsePlace = await getAllPlace(0, -1, options);
        if (responsePlace.status === 200) {
          setPlaces(responsePlace.data.content);
          placeSelected.rooms = placeSelected.rooms.filter(
            (room: RoomModel) => room.room_id !== roomId
          );
          setRooms(placeSelected.rooms);
          setOriginalRoomList(placeSelected.rooms);
        }
      }

      setIsLoading(false);
      showAlert(`Espaço ${roomName} removido com sucesso`, "success");
      setOpenDialog(false);
      setRoomName("");
    } else {
      showAlert("Não foi possível remover o espaço!", "error");
      setOpenDialog(false);
      setRoomName("");
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.roomsMainContainer}>
      <div className={classes.roomsContentContainer}>
        <div className={classes.roomsContent}>
          {expandSubHeader && <Filters />}
          <Divider />
          <RoomGrid />
          <CustomConfirmDialog
            onClose={handleCloseModalDelete}
            onCloseFocus={handleCloseModalDelete}
            openDialog={openDialog}
            onConfirmButton={roomDeleteConfirm}
            onCancelButton={handleCloseModalDelete}
            text={`Você realmente deseja remover o espaço `}
            roomName={roomName}
            isLoading={isLoading}
          />
          <RoomModal />
        </div>
      </div>
    </div>
  );
};

export default Rooms;

const useStyles = makeStyles(() => ({
  roomsMainContainer: {
    position: "relative",
  },
  roomsContentContainer: {
    position: "absolute",
    left: "0px",
    right: "0px",
    top: "0px",
    bottom: "0px",
  },
  roomsContent: {
    margin: "auto",
    padding: "0 3rem",
    paddingBottom: "45px",
    backgroundColor: "#FFF",
  },
}));
