import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    containerEnterpriseAlert: {
      position: "absolute",
      top: "0px",
      width: "100%",
      height: "250px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    enterpriseAlertTitle: {
      fontWeight: 700,
      fontSize: "21px",
      lineHeight: "29px",
      textAlign: "center",
      color: "#3E5055",
    },
    enterpriseAlertLabel: {
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "20px",
      textAlign: "center",
      color: "#3E5055",
    },
  })
);

const EnterpriseBlockAlert: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.containerEnterpriseAlert}>
      <img src="assets/icons/enterprise-unavailable.svg" />
      <div className={classes.enterpriseAlertTitle}>
        Empreendimento indisponível
      </div>
      <div className={classes.enterpriseAlertLabel}>
        Não foi possível efetivar o pagamento, por favor cheque os dados de
        pagamento e tente novamente
      </div>
    </div>
  );
};

export default EnterpriseBlockAlert;
