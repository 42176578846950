import styled from "styled-components";

export const Success = styled.div`
  left: 20.94%;
  right: 20.99%;
  top: 33.77%;
  bottom: 56.68%;
  background: #ebeeee;
  border-radius: 5px;
  width: 1115px;
  height: 103px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
`;
