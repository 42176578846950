import { makeStyles, createStyles } from "@material-ui/core/styles";
import DeviceModel from "../../../../../Models/DeviceModel";
import { Button } from "@material-ui/core";
import EWS409_COLOUR from "../Components/EWS409_COLOUR";
import EWS409_WHITE from "../Components/EWS409_WHITE";
import { useContext } from "react";
import GlobalContex from "../../../../../GlobalContext/GlobalContext";
import ListEnvironmentsFromDevices from "../../../../../Components/ListEnvironmentsFromDevices";
import StatusFunction from "../../../../../Models/StatusFunctionModel";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      padding: "60px",
      alignItems: "center",
      height: "100%",
      overflow: "auto",
    },
    button: {
      padding: 10,
    },
    title: {
      fontSize: "23px",
      color: "#3E5055",
      fontWeight: 600,
      marginBottom: "0px",
    },
    subtitle: {
      fontSize: "15px",
      color: "#8B979F",
    },
    buttons: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      width: "100%",
    },
  })
);

interface Props {
  device: DeviceModel;
  status: StatusFunction[];
}

const Device_EWS_409: React.FunctionComponent<Props> = ({
  device,
  status,
}: Props) => {
  const { handleChangeCommand } = useContext(GlobalContex);

  const classes = useStyles();

  const switchColourMode = (value: string) => {
    handleChangeCommand(value, device.id, "work_mode");
  };

  const workMode = status.find((s) => s.code === "work_mode") || {
    code: "",
    value: "",
  };

  const workModeColor: boolean = workMode.value === "colour";

  return (
    <div className={classes.container}>
      <p className={classes.title}>{device.name}</p>
      <div className={classes.subtitle}>
        {device.environments && (
          <ListEnvironmentsFromDevices environments={device.environments} />
        )}
      </div>
      <div className={classes.buttons}>
        <Button
          className={classes.button}
          variant="contained"
          onClick={() => {
            switchColourMode("white");
          }}
          color={workModeColor ? "primary" : "secondary"}
          style={{
            borderRadius: "25px 0px 0px 25px",
            textTransform: "none",
            paddingLeft: "30px",
          }}
        >
          Branco
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          onClick={() => {
            switchColourMode("colour");
          }}
          color={!workModeColor ? "primary" : "secondary"}
          style={{
            borderRadius: "0px 25px 25px 0%",
            textTransform: "none",
            paddingRight: "30px",
          }}
        >
          Colorido
        </Button>
      </div>
      {!workModeColor && <EWS409_WHITE device={device} status={status} />}
      {workModeColor && <EWS409_COLOUR device={device} status={status} />}
    </div>
  );
};

export default Device_EWS_409;
